define("happyfox-bi/components/multi-axis-chart/component", ["exports", "happyfox-bi/components/base-chart/component", "@amcharts/amcharts4/core", "@amcharts/amcharts4/charts", "happyfox-bi/constants"], function (_exports, _component, am4core, am4charts, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    useSeriesNameAsTooltipLabel: true,
    isYAxisLeftCurrencyType: function isYAxisLeftCurrencyType() {
      var _this = this;

      var _this$get = this.get('config'),
          yAxisLeft = _this$get.yAxisLeft;

      return yAxisLeft.every(function (axis) {
        return _this.isFieldCurrencyType(axis.key);
      });
    },
    isYAxisRightCurrencyType: function isYAxisRightCurrencyType() {
      var _this2 = this;

      var _this$get2 = this.get('config'),
          yAxisRight = _this$get2.yAxisRight;

      return yAxisRight.every(function (axis) {
        return _this2.isFieldCurrencyType(axis.key);
      });
    },
    isYAxisLeftTimeType: function isYAxisLeftTimeType() {
      var _this3 = this;

      var _this$get3 = this.get('config'),
          yAxisLeft = _this$get3.yAxisLeft;

      return yAxisLeft.every(function (axis) {
        return _this3.isFieldTimeType(axis.key);
      });
    },
    isYAxisRightTimeType: function isYAxisRightTimeType() {
      var _this4 = this;

      var _this$get4 = this.get('config'),
          yAxisRight = _this$get4.yAxisRight;

      return yAxisRight.every(function (axis) {
        return _this4.isFieldTimeType(axis.key);
      });
    },
    isYAxisLeftDuration: function isYAxisLeftDuration() {
      var _this5 = this;

      var _this$get5 = this.get('config'),
          yAxisLeft = _this$get5.yAxisLeft;

      return yAxisLeft.every(function (axis) {
        return _this5.canFormatTimeAsDuration(axis.key);
      });
    },
    isYAxisRightDuration: function isYAxisRightDuration() {
      var _this6 = this;

      var _this$get6 = this.get('config'),
          yAxisRight = _this$get6.yAxisRight;

      return yAxisRight.every(function (axis) {
        return _this6.canFormatTimeAsDuration(axis.key);
      });
    },
    didInsertElement: function didInsertElement() {
      var _this7 = this;

      this._super.apply(this, arguments);

      var chartContainer = this.get('chartContainer');
      var chart = am4core.create(chartContainer, am4charts.XYChart);
      chart.colors.startIndex = 2;
      chart.colors.step = 3; // Create axes

      var isDateXAxis = this.isXAxisDateType();
      var xAxis = chart.xAxes.push(isDateXAxis ? new am4charts.DateAxis() : new am4charts.CategoryAxis());
      var data = this.get('data');

      if (isDateXAxis) {
        this.prepareDateAxis(xAxis);
        chart.data = this.convertAndGetDateXAxisData(data, this.get('config.xAxis.key'));
      } else {
        xAxis.dataFields.category = this.get('config.xAxis.key');
        chart.data = data;
      }

      var xAxisTitle = this.get('config.xAxis.label');

      if (Ember.isPresent(xAxisTitle)) {
        xAxis.title.text = xAxisTitle;
      }

      xAxis.renderer.minGridDistance = 30;
      xAxis.renderer.grid.template.location = 0;
      xAxis.renderer.cellStartLocation = 0.2;
      xAxis.renderer.cellEndLocation = 0.8;
      xAxis.renderer.grid.template.disabled = true;
      var label = xAxis.renderer.labels.template;
      label.truncate = true;
      label.maxWidth = 120;
      label.tooltipText = "{category}";
      label.rotation = 315;
      label.verticalCenter = "top";
      label.horizontalCenter = "right";
      var showValueLabels = this.get('showValueLabels');
      var yAxisExtraMax = this.get('yAxisExtraMax');
      var isYAxisLeftTimeType = this.isYAxisLeftTimeType();
      var isYAxisLeftDuration = this.isYAxisLeftDuration();
      var yAxisLeft = isYAxisLeftDuration ? chart.yAxes.push(new am4charts.DurationAxis()) : chart.yAxes.push(new am4charts.ValueAxis());
      this.setYAxisLeftRanges(yAxisLeft);
      var yAxisLeftSeries = this.get('config.yAxisLeft');

      if (Ember.isPresent(yAxisLeftSeries)) {
        var yAxisLeftField = this.get('config.yAxisLeft')[0];
        var yAxisLeftLabel = yAxisLeftField.label;
        this.setAxisTitle(yAxisLeft, yAxisLeftLabel);
        yAxisLeft.renderer.grid.template.disabled = true; // Create multiple column series for y-Axis Left

        yAxisLeftSeries.forEach(function (series) {
          var seriesLabel = series.label;

          var columnSeries = _this7.createColumnSeries(chart, _this7.get('config.xAxis.key'));

          columnSeries.name = seriesLabel;
          columnSeries.dataFields.valueY = series.key;

          _this7.setSeriesTooltipText(columnSeries);

          columnSeries.yAxis = yAxisLeft;

          if (showValueLabels) {
            yAxisLeft.extraMax = yAxisExtraMax;
            var yAxisLeftValueLabel = columnSeries.bullets.push(new am4charts.LabelBullet());
            yAxisLeftValueLabel.label.verticalCenter = 'top';
            yAxisLeftValueLabel.label.text = '{valueY}';
            yAxisLeftValueLabel.label.truncate = false;
            yAxisLeftValueLabel.label.dy = -15;
            yAxisLeftValueLabel.label.fontSize = 14;
          }
        });

        if (this.isYAxisLeftCurrencyType()) {
          var _yAxisLeftField = this.get('config.yAxisLeft')[0]; // Why `formatAxisLabelsByCurrency` instead of generic `setElementNumberFormatAsCurrency(axis)` method in base chart?
          // See method!

          this.formatAxisLabelsByCurrency(yAxisLeft, _yAxisLeftField.key);
        }

        if (isYAxisLeftDuration) {
          this.setupDurationFormatter(yAxisLeft, yAxisLeftField.key);
        } else if (isYAxisLeftTimeType) {
          this.setUnitsInAxisLabels(yAxisLeft, this.getTimeFieldBaseUnit(yAxisLeftField.key));
        }
      } // Create Multiple series for Y-Axis Right


      var isYAxisRightTimeType = this.isYAxisRightTimeType();
      var isYAxisRightDuration = this.isYAxisRightDuration();
      var yAxisRight = isYAxisRightDuration ? chart.yAxes.push(new am4charts.DurationAxis()) : chart.yAxes.push(new am4charts.ValueAxis());
      this.setYAxisRightRanges(yAxisRight);
      var yAxisRightSeries = this.get('config.yAxisRight');

      if (Ember.isPresent(yAxisRightSeries)) {
        var yAxisRightField = this.get('config.yAxisRight')[0];
        var yAxisRightLabel = yAxisRightField.label;
        this.setAxisTitle(yAxisRight, yAxisRightLabel);
        yAxisRight.renderer.grid.template.disabled = true;
        yAxisRight.renderer.opposite = true;
        yAxisRightSeries.forEach(function (series) {
          var seriesLabel = series.label; // Create line series

          var lineSeries = _this7.createLineSeries(chart, _this7.get('config.xAxis.key'));

          lineSeries.name = seriesLabel;
          lineSeries.yAxis = yAxisRight;
          lineSeries.dataFields.valueY = series.key;

          _this7.setSeriesTooltipText(lineSeries);

          if (showValueLabels) {
            yAxisRight.extraMax = yAxisExtraMax;
            var axisValueLabel = lineSeries.bullets.push(new am4charts.LabelBullet());
            axisValueLabel.label.verticalCenter = 'top';
            axisValueLabel.label.text = '{valueY}';
            axisValueLabel.label.truncate = false;
            axisValueLabel.label.dy = -15;
            axisValueLabel.label.fontSize = 14;
          }
        });

        if (this.isYAxisRightCurrencyType()) {
          var yAxisRightKeys = yAxisRightSeries.mapBy('key');

          for (var i = 0; i < yAxisRightKeys.length; i++) {
            if (this.isFieldCurrencyType(yAxisRightKeys[i])) {
              // Why `formatAxisLabelsByCurrency` instead of generic `setElementNumberFormatAsCurrency(axis)` method in base chart?
              // See method!
              this.formatAxisLabelsByCurrency(yAxisRight, yAxisRightKeys[i]);
            }
          }
        }

        if (isYAxisRightTimeType) {
          var _yAxisRightKeys = yAxisRightSeries.mapBy('key');

          for (var _i = 0; _i < _yAxisRightKeys.length; _i++) {
            if (this.canFormatTimeAsDuration(_yAxisRightKeys[_i])) {
              this.setupDurationFormatter(yAxisRight, _yAxisRightKeys[_i]);
            } else if (this.isFieldTimeType(_yAxisRightKeys[_i])) {
              this.setUnitsInAxisLabels(yAxisRight, this.getTimeFieldBaseUnit(_yAxisRightKeys[_i]));
            }
          }
        }
      }

      if (this.get('canManageBenchmarks')) {
        chart.events.on("inited", function () {
          var _this7$get = _this7.get('widgetModel.settings'),
              benchmarks = _this7$get.benchmarks;

          benchmarks.forEach(function (benchmark) {
            if (benchmark.yAxis == 'left') {
              _this7.createBenchmark(yAxisLeft, benchmark);
            } else {
              _this7.createBenchmark(yAxisRight, benchmark);
            }
          });
        });
      }

      chart.cursor = new am4charts.XYCursor();
      this.addExportOption(chart);
      this.addAnnotationsOption(chart);
      var isLegendHidden = this.getConfigValue('hideLegend', false);

      if (!isLegendHidden) {
        this.createLegend(chart);
      }

      this.chart = chart;
    },
    createColumnSeries: function createColumnSeries(chart, seriesField) {
      var series = chart.series.push(new am4charts.ColumnSeries());
      var isDateXAxis = this.isXAxisDateType();

      if (isDateXAxis) {
        series.dataFields.dateX = seriesField;
      } else {
        series.dataFields.categoryX = seriesField;
      }

      return series;
    },
    createLineSeries: function createLineSeries(chart, seriesField) {
      var series = chart.series.push(new am4charts.LineSeries());
      var isDateXAxis = this.isXAxisDateType();

      if (isDateXAxis) {
        series.dataFields.dateX = seriesField;
      } else {
        series.dataFields.categoryX = seriesField;
      }

      series.strokeWidth = 3;
      var bullet = series.bullets.push(new am4charts.Bullet()); // bullet.tooltipText = "[#fff font-size: 15px]{name} in {categoryX}:\n[/][#fff font-size: 20px]{valueY}[/] [#fff]{additional}[/]"

      var circle = bullet.createChild(am4core.Circle);
      circle.radius = 3;
      circle.strokeWidth = 2;

      if (!this.get('config.connectLinesOverEmptyData')) {
        series.connect = false;
      }

      return series;
    },

    /* 	
      This method, `formatAxisLabelsByCurrency`, exists for a special case involving charts with multiple value axes.	
      	
      Case:	
      ------	
      In general, the numberFormatter (whether it is currency or any other number formatting) gets applied on the root level value axis element from which each value axis is created.	
      Different number formatters (currency, %, rounding-off, etc.) on different value axes is not possible since each number formatter applied on a value axis will override the formatter of root axis element.	
      Due to this, all value axes will now use the (same)formatter from the last axis. Read more -> https://github.com/amcharts/amcharts4/issues/221	
      Solution:	
      ---------	
      Manually format axis elements like labels and axis tooltip text to the format you want 	
      instead of `setElementNumberFormatAsCurrency` which does this automatically for charts with single value axis.	
    */
    formatAxisLabelsByCurrency: function formatAxisLabelsByCurrency(axis, currencyFieldKey) {
      var _this8 = this;

      var currencyField = this.get('widgetModel.settings.fields').findBy('name', currencyFieldKey);
      axis.renderer.labels.template.adapter.add("text", function (label, target) {
        var dataItem = target.dataItem;

        if (dataItem) {
          var dataValue = dataItem.value;

          var formattedLabel = _this8.formatValueByCurrencyField(dataValue, currencyField);

          return "".concat(formattedLabel);
        }

        return label;
      });
      axis.adapter.add("getTooltipText", function (text) {
        return "".concat(currencyField.currencySign).concat(text);
      });
    },
    setYAxisLeftRanges: function setYAxisLeftRanges(valueAxis) {
      var yAxisConfig = this.get('config.yAxisLeft');
      var yAxisMin = this.get('config.yAxisLeftMin');
      var yAxisMax = this.get('config.yAxisLeftMax');

      if (Ember.isPresent(yAxisConfig)) {
        if (Ember.isPresent(yAxisMin) || Ember.isPresent(yAxisMax)) {
          // Follows user defined min & max;
          valueAxis.strictMinMax = true;
        }

        if (Ember.isPresent(yAxisMin)) {
          valueAxis.min = yAxisMin;
        }

        if (Ember.isPresent(yAxisMax)) {
          valueAxis.max = yAxisMax;
        }
      }
    },
    setYAxisRightRanges: function setYAxisRightRanges(valueAxis) {
      var yAxisConfig = this.get('config.yAxisRight');
      var yAxisMin = this.get('config.yAxisRightMin');
      var yAxisMax = this.get('config.yAxisRightMax');

      if (Ember.isPresent(yAxisConfig)) {
        if (Ember.isPresent(yAxisMin) || Ember.isPresent(yAxisMax)) {
          // Follows user defined min & max;
          valueAxis.strictMinMax = true;
        }

        if (Ember.isPresent(yAxisMin)) {
          valueAxis.min = yAxisMin;
        }

        if (Ember.isPresent(yAxisMax)) {
          valueAxis.max = yAxisMax;
        }
      }
    },
    replaceNullInRowsAndColumns: function replaceNullInRowsAndColumns() {
      var xAxisKey = this.get('config.xAxis.key');
      var excludeNullValues = this.get('config.excludeNullValues');
      var processedData = [];
      this.get('data').forEach(function (item) {
        if (item[xAxisKey] === null) {
          if (!excludeNullValues) {
            item[xAxisKey] = _constants.NO_VALUE_SET;
            processedData.push(item);
          }
        } else {
          processedData.push(item);
        }
      });
      this.set('data', processedData);
    }
  });

  _exports.default = _default;
});