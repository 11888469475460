define("happyfox-bi/services/data", ["exports", "happyfox-bi/utilities/object", "happyfox-bi/utilities/url"], function (_exports, _object, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    requestService: Ember.inject.service('request'),
    cache: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('cache', Ember.Object.create({
        dataSets: Ember.A(),
        dataSetFields: Ember.A()
      }));
    },
    fetchAccountInfo: function fetchAccountInfo() {
      return this.get('requestService').get('/account/').then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    getAllRecords: function getAllRecords(modelName) {
      var _this = this;

      var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        offset: 0,
        limit: 50
      };
      var fetchedRecords = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : Ember.A();
      return this.get('store').query(modelName, (0, _object.underscoreObject)(params) || {}).then(function (records) {
        fetchedRecords.addObjects(records);

        if (records.meta.next) {
          params.offset = records.meta.offset + records.meta.limit;
          return _this.getAllRecords(modelName, params, fetchedRecords);
        } else {
          return fetchedRecords;
        }
      });
    },
    getAllResults: function getAllResults(url, modelName) {
      var _this2 = this;

      var params = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
        offset: 0,
        limit: 50
      };
      var fetchedRecords = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : Ember.A();
      var store = this.get('store');
      return this.get('requestService').get(url, params).then(function (records) {
        var results = records.results.map(function (data) {
          return store.push(store.normalize(modelName, data));
        });
        fetchedRecords.addObjects(results);

        if (records.meta.next) {
          params.offset = records.meta.offset + records.meta.limit;
          return _this2.getAllResults(url, modelName, params, fetchedRecords);
        } else {
          return fetchedRecords;
        }
      });
    },
    fetchPaginatedRecords: function fetchPaginatedRecords(url) {
      var _this3 = this;

      var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        offset: 0,
        limit: 50
      };
      var fetchedRecords = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : Ember.A();
      return this.get('requestService').get(url, params).then(function (records) {
        fetchedRecords.addObjects(records.results);

        if (records.meta.next) {
          params.offset = records.meta.offset + records.meta.limit;
          return _this3.fetchPaginatedRecords(url, params, fetchedRecords);
        } else {
          return (0, _object.camelizeObject)(fetchedRecords);
        }
      });
    },
    getAllReports: function getAllReports() {
      return this.getAllRecords('report');
    },
    fetchReports: function fetchReports(params) {
      return this.store.query('report', (0, _object.underscoreObject)(params));
    },
    getProfileDetails: function getProfileDetails() {
      return this.get('requestService').get('/profile/');
    },
    getSampleVisualizations: function getSampleVisualizations(params) {
      return this.fetchPaginatedRecords('/sample-visualizations/', params);
    },
    getSampleVisualizationPreview: function getSampleVisualizationPreview(visualizationId, data) {
      return this.get('requestService').post("/sample-visualizations/".concat(visualizationId, "/preview/"), data).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    createSampleVisualization: function createSampleVisualization(data) {
      return this.get('requestService').post('/sample-visualizations/', data).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    patchSampleVisualization: function patchSampleVisualization(id, data) {
      return this.get('requestService').patch("/sample-visualizations/".concat(id, "/"), data).then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    deleteSampleVisualization: function deleteSampleVisualization(id) {
      return this.get('requestService').delete("/sample-visualizations/".concat(id, "/"));
    },
    peekAllReports: function peekAllReports() {
      return this.get('store').peekAll('report');
    },
    findAllReports: function findAllReports() {
      return this.get('requestService').get("/reports-meta/").then(function (response) {
        return (0, _object.camelizeObject)(response);
      });
    },
    peekAllPagesFromReports: function peekAllPagesFromReports() {
      var allPages = Ember.A();
      this.peekAllReports().map(function (report) {
        return allPages.addObjects(report.get('pages'));
      });
      return allPages;
    },
    peekReport: function peekReport(id) {
      return this.get('store').peekRecord('report', id);
    },
    peekPage: function peekPage(id) {
      return this.get('store').peekRecord('page', id);
    },
    getPage: function getPage(reportId, pageId) {
      // TODO: Check if this can be done using path for type in adapters
      var store = this.get('store');
      return this.get('requestService').get("reports/".concat(reportId, "/pages/").concat(pageId, "/")).then(function (page) {
        return store.push(store.normalize('page', page));
      });
    },
    getVisualization: function getVisualization(id) {
      return this.get('store').findRecord('visualization', id, {
        reload: true
      });
    },
    peekVisualization: function peekVisualization(id) {
      return this.get('store').peekRecord('visualization', id);
    },
    peekDataSource: function peekDataSource(id) {
      return this.get('store').peekRecord('data-source', id);
    },
    getDataSource: function getDataSource(id) {
      return this.get('store').findRecord('data-source', id);
    },
    getAllDataSources: function getAllDataSources() {
      var _this4 = this;

      var getFromCache = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var dataSourcesCache = this.get('cache.dataSources');

      if (getFromCache && dataSourcesCache && Ember.isPresent(dataSourcesCache)) {
        return Ember.RSVP.resolve(dataSourcesCache);
      } else {
        return this.getAllRecords('data-source').then(function (data) {
          _this4.set('cache.dataSources', data);

          return data;
        });
      }
    },
    peekAllDataSources: function peekAllDataSources() {
      return this.get('store').peekAll('data-source');
    },
    peekDataSetsForDataSource: function peekDataSetsForDataSource(dataSourceId) {
      return this.get('store').peekAll('data-set').filterBy('dataSource', Number(dataSourceId));
    },
    peekDataSet: function peekDataSet(id) {
      return this.get('store').peekRecord('data-set', id);
    },
    getDataSet: function getDataSet(dataSourceId, dataSetId) {
      var store = this.get('store');
      return this.get('requestService').get("data-sources/".concat(dataSourceId, "/datasets/").concat(dataSetId, "/")).then(function (dataSet) {
        return store.push(store.normalize('data-set', dataSet));
      });
    },
    getDataSets: function getDataSets(dataSourceId) {
      var _this5 = this;

      var getFromCache = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var dataSetCache = this.get('cache.dataSets').findBy('dataSourceId', dataSourceId);

      if (getFromCache && dataSetCache && Ember.isPresent(dataSetCache.get('data'))) {
        return Ember.RSVP.resolve(dataSetCache.get('data'));
      } else {
        return this.getAllResults("data-sources/".concat(dataSourceId, "/datasets/"), 'data-set').then(function (data) {
          var existingCache = _this5.get('cache.dataSets').findBy('dataSourceId', dataSourceId);

          if (existingCache) {
            existingCache.set('data', data);
          } else {
            _this5.get('cache.dataSets').addObject(Ember.Object.create({
              dataSourceId: dataSourceId,
              data: data
            }));
          }

          return data;
        });
      }
    },
    getDataSetFields: function getDataSetFields(dataSourceId, dataSetId) {
      var _this6 = this;

      var getFromCache = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      dataSourceId = Number(dataSourceId);
      dataSetId = Number(dataSetId);
      var dataSetFieldsCache = this.get('cache.dataSetFields').findBy('dataSetId', dataSetId);

      if (getFromCache && dataSetFieldsCache && Ember.isPresent(dataSetFieldsCache.get('data'))) {
        return Ember.RSVP.resolve(dataSetFieldsCache.get('data'));
      } else {
        return this.getAllResults("data-sources/".concat(dataSourceId, "/datasets/").concat(dataSetId, "/dataset-fields/"), 'data-set-field').then(function (data) {
          var existingCache = _this6.get('cache.dataSetFields').findBy('dataSetId', dataSetId);

          if (existingCache) {
            existingCache.set('data', data);
          } else {
            _this6.get('cache.dataSetFields').addObject(Ember.Object.create({
              dataSetId: dataSetId,
              data: data
            }));
          }

          return data;
        });
      }
    },
    peekDataSetField: function peekDataSetField(id) {
      return this.get('store').peekRecord('data-set-field', id);
    },
    getVisualizationData: function getVisualizationData(data) {
      return this.get('requestService').post('/reports-data/', data);
    },
    getVisualizationDrillDownData: function getVisualizationDrillDownData(data) {
      return this.get('requestService').post('/reports-data/', data);
    },
    staffLogin: function staffLogin(username, password) {
      var data = {
        email: username,
        password: password
      };
      return this.get('requestService').post('/login/', data);
    },
    staffLogout: function staffLogout() {
      return this.get('requestService').post('/logout/');
    },
    createReport: function createReport(data) {
      var _this7 = this;

      return this.get('requestService').post('/reports/', data).then(function (report) {
        var store = _this7.get('store');

        return store.push(store.normalize('report', report));
      });
    },
    createPage: function createPage(reportId, name) {
      return this.get('requestService').post("/reports/".concat(reportId, "/pages/"), {
        name: name
      });
    },
    getReport: function getReport(id) {
      return this.get('store').findRecord('report', id);
    },
    createVisualizationModel: function createVisualizationModel() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return this.get('store').createRecord('visualization', data);
    },
    getFieldOptions: function getFieldOptions(field, visualizationId, query) {
      var data;

      if (field.get('type') === 'dataset_field') {
        data = {
          'field_id': field.get('datasetField')
        };
      } else {
        data = {
          'visualization_id': visualizationId,
          'field_name': field.get('name')
        };
      }

      if (query) {
        data.query = query;
      }

      return this.get('requestService').post('/field-options/', data);
    },
    newGetFieldOptions: function newGetFieldOptions(field_id, query) {
      var data = {
        'field_id': field_id
      };

      if (query) {
        data.query = query;
      }

      return this.get('requestService').post('/field-options/', data);
    },
    autoCompleteSearch: function autoCompleteSearch(searchParam, term, url) {
      var params = {};
      params[searchParam] = term;
      var paramsString = (0, _url.getQueryString)(params);

      if (url.indexOf('?') >= 0) {
        url = "".concat(url, "&").concat(paramsString);
      } else {
        url = "".concat(url, "?").concat(paramsString);
      }

      return this.get('requestService').get(url);
    },
    sendEmailForForgotPassword: function sendEmailForForgotPassword(email) {
      return this.get('requestService').post('/account/forgot-password/', {
        email: email
      });
    },
    validateRegistarionToken: function validateRegistarionToken(token) {
      return this.get('requestService').post('/account/validate-registration-token/', {
        token: token
      });
    },
    validateForgotPasswordToken: function validateForgotPasswordToken(token) {
      return this.get('requestService').post('/account/validate-forgot-password-token/', {
        token: token
      });
    },
    resetPassword: function resetPassword(data) {
      return this.get('requestService').post('/account/reset-password/', data);
    },
    registerUser: function registerUser(data) {
      return this.get('requestService').post('/account/finish-registration/', data);
    },
    exportRawData: function exportRawData(visualizationId) {
      return this.get('requestService').downloadFile("/visualizations/".concat(visualizationId, "/export/"));
    },
    getRawData: function getRawData(visualizationId, filters) {
      var payload = {
        visualizationId: visualizationId,
        rawData: true
      };

      if (filters) {
        payload.rawDataFilters = filters;
      }

      return this.getVisualizationData(payload);
    },
    getPreviewRawData: function getPreviewRawData(visualizationSettings) {
      var payload = {
        settings: visualizationSettings,
        rawData: true
      };
      return this.getVisualizationData(payload);
    },
    updateProfile: function updateProfile(data) {
      return this.get('requestService').patch('/profile/', data);
    },
    changeProfileDateRangePreferences: function changeProfileDateRangePreferences(data) {
      return this.get('requestService').post('/profile/date-preferences/', data);
    },
    changeVisualizationPreferences: function changeVisualizationPreferences(visualizationId, data) {
      return this.get('requestService').patch("/visualizations/".concat(visualizationId, "/preferences/"), data).then(function (data) {
        return (0, _object.camelizeObject)(data);
      });
    },
    inviteUser: function inviteUser(data) {
      return this.get('requestService').post('/account/invite/', data);
    },
    patchPage: function patchPage(reportId, pageId, data) {
      var store = this.get('store'); // Right now page endpoint is a nested resource endpoint, so we need to provide both report id &  page id

      return this.get('requestService').patch("/reports/".concat(reportId, "/pages/").concat(pageId, "/"), data).then(function (page) {
        return store.push(store.normalize('page', page));
      });
    },
    deletePage: function deletePage(reportId, pageId) {
      // Right now page endpoint is a nested resource endpoint, so we need to provide both report id &  page id
      return this.get('requestService').delete("/reports/".concat(reportId, "/pages/").concat(pageId, "/"));
    },
    getAllUsers: function getAllUsers() {
      return this.getAllRecords('user');
    },
    getAllClients: function getAllClients() {
      return this.getAllRecords('client');
    },
    peekAllUsers: function peekAllUsers() {
      return this.get('store').peekAll('user');
    },
    peekAllClients: function peekAllClients() {
      return this.get('store').peekAll('client');
    },
    peekAllActiveUsers: function peekAllActiveUsers() {
      var users = this.peekAllUsers();
      return users.filterBy('isActive');
    },
    peekAllActiveClients: function peekAllActiveClients() {
      var clients = this.peekAllClients();
      return clients.filterBy('isActive');
    },
    peekUser: function peekUser(id) {
      return this.store.peekRecord('user', id);
    },
    peekClient: function peekClient(id) {
      return this.store.peekRecord('client', id);
    },
    fetchUsers: function fetchUsers(params) {
      return this.store.query('user', (0, _object.underscoreObject)(params));
    },
    fetchClients: function fetchClients(params) {
      return this.store.query('client', (0, _object.underscoreObject)(params));
    },
    deactivateUser: function deactivateUser(id) {
      return this.get('requestService').post("/users/".concat(id, "/deactivate/"));
    },
    deactivateClient: function deactivateClient(id) {
      return this.get('requestService').post("/clients/".concat(id, "/deactivate/"));
    },
    activateUser: function activateUser(id) {
      return this.get('requestService').post("/users/".concat(id, "/reactivate/"));
    },
    activateClient: function activateClient(id) {
      return this.get('requestService').post("/clients/".concat(id, "/reactivate/"));
    },
    createDataSource: function createDataSource(data) {
      var _this8 = this;

      return this.get('requestService').post('/data-sources/', data).then(function (dataSource) {
        var store = _this8.get('store');

        return store.push(store.normalize('data-source', dataSource));
      });
    },
    createDataSet: function createDataSet(dataSourceId, data) {
      var _this9 = this;

      return this.get('requestService').post("/data-sources/".concat(dataSourceId, "/datasets/"), data).then(function (dataSet) {
        var store = _this9.get('store');

        return store.push(store.normalize('data-set', dataSet));
      });
    },
    importData: function importData(data) {
      return this.get('requestService').sendFormData('/import-data/', data, 'POST', ['file']);
    },
    autoDetectFields: function autoDetectFields(data) {
      return this.get('requestService').sendFormData('/import-data/detect-fields/', data, 'POST', ['file']).then(function (response) {
        response.fields = (0, _object.camelizeObject)(response.fields);
        return response;
      });
    },
    getDataSetSampleData: function getDataSetSampleData(dataSetId, extraFieldIds) {
      extraFieldIds = extraFieldIds ? extraFieldIds : [];
      return this.get('requestService').post("/datasets/".concat(dataSetId, "/sample-data/"), {
        extraFields: extraFieldIds
      });
    },
    getSampleDataMergedCount: function getSampleDataMergedCount(primaryDataSetId, primaryDataSetFieldId, secondaryDataSetId, secondaryDataSetFieldId, joinType) {
      var data = {
        primaryDataset: primaryDataSetId,
        secondaryDataset: secondaryDataSetId,
        primaryDatasetField: primaryDataSetFieldId,
        secondaryDatasetField: secondaryDataSetFieldId,
        joinType: joinType
      };
      return this.get('requestService').post('/datasets/merge/test/', data).then(function (data) {
        return (0, _object.camelizeObject)(data);
      });
    },
    getUserApiKey: function getUserApiKey() {
      return this.get('requestService').get('/user-api-key/').then(function (data) {
        return (0, _object.camelizeObject)(data);
      });
    },
    // Apps
    getApp: function getApp(name) {
      var url = "integrations/".concat(name, "/");
      return this.requestService.get(url).then(function (app) {
        return (0, _object.camelizeObject)(app);
      });
    },
    saveAsReportTemplate: function saveAsReportTemplate(data) {
      return this.get('requestService').post('/report-templates/', data);
    },
    patchReportTemplate: function patchReportTemplate(id, data) {
      return this.get('requestService').patch("/report-templates/".concat(id, "/"), data);
    },
    deleteReportTemplate: function deleteReportTemplate(id) {
      return this.get('requestService').delete("/report-templates/".concat(id, "/"));
    },
    fetchAllReportTemplates: function fetchAllReportTemplates() {
      return this.fetchPaginatedRecords('/report-templates/');
    },
    fetchReportTemplates: function fetchReportTemplates(params) {
      var _this10 = this;

      return this.get('requestService').get('/report-templates/', params).then(function (response) {
        var modifiedResponse = {};
        modifiedResponse.meta = _this10.modifyListMetaInfo(response.meta);
        modifiedResponse.results = response.results;
        return (0, _object.camelizeObject)(modifiedResponse);
      });
    },
    extractPageNumber: function extractPageNumber(url) {
      /* eslint-disable no-useless-escape */
      var match = /.*?[\?&]offset=(\d+).*?/.exec(url);
      /* eslint-enable no-useless-escape */

      if (match) {
        return Number(match[1]).valueOf();
      }

      return 0;
    },
    modifyListMetaInfo: function modifyListMetaInfo(metaInfo) {
      var modifiedMetaInfo = Object.assign({}, metaInfo);

      if (metaInfo.next) {
        modifiedMetaInfo.next = this.extractPageNumber(metaInfo.next);
      }

      if (metaInfo.previous) {
        modifiedMetaInfo.previous = this.extractPageNumber(metaInfo.previous);
      }

      return modifiedMetaInfo;
    },
    getReportSubscriptions: function getReportSubscriptions(params) {
      var _this11 = this;

      return this.get('requestService').get('/report-subscriptions/', params).then(function (response) {
        var modifiedResponse = {};
        modifiedResponse.meta = _this11.modifyListMetaInfo(response.meta);
        modifiedResponse.results = response.results;
        return (0, _object.camelizeObject)(modifiedResponse);
      });
    },
    createReportSubscription: function createReportSubscription(data) {
      return this.get('requestService').post('/report-subscriptions/', data);
    },
    patchReportSubscription: function patchReportSubscription(id, data) {
      return this.get('requestService').patch("/report-subscriptions/".concat(id, "/"), data);
    },
    deleteReportSubscription: function deleteReportSubscription(id) {
      return this.get('requestService').delete("/report-subscriptions/".concat(id, "/"));
    },
    getBusinessHours: function getBusinessHours(params) {
      var _this12 = this;

      return this.get('requestService').get('/business-hours/', params).then(function (response) {
        var modifiedResponse = {};
        modifiedResponse.meta = _this12.modifyListMetaInfo(response.meta);
        modifiedResponse.results = response.results;
        return (0, _object.camelizeObject)(modifiedResponse);
      });
    },
    createBusinessHours: function createBusinessHours(data) {
      return this.get('requestService').post('/business-hours/', data);
    },
    patchBusinessHours: function patchBusinessHours(id, data) {
      return this.get('requestService').patch("/business-hours/".concat(id, "/"), data);
    },
    deleteBusinessHours: function deleteBusinessHours(id) {
      return this.get('requestService').delete("/business-hours/".concat(id, "/"));
    },
    getBusinessHoursByDataSource: function getBusinessHoursByDataSource(dataSourceId, params) {
      return this.get('requestService').get("data-sources/".concat(dataSourceId, "/business-hours/"), params).then(function (data) {
        return (0, _object.camelizeObject)(data);
      });
    },
    getDependantVisualizationsForBusinessHours: function getDependantVisualizationsForBusinessHours(id) {
      var dataSourceIds = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

      if (dataSourceIds.length) {
        return this.get('requestService').post("/business-hours/".concat(id, "/dependants/"), {
          dataSources: dataSourceIds
        });
      }

      return this.get('requestService').get("/business-hours/".concat(id, "/dependants/"));
    },
    internalLogin: function internalLogin(data) {
      return this.get('requestService').post('/internal-login/', data);
    },
    switchAccount: function switchAccount(data) {
      return this.get('requestService').post('/change-account/', data);
    },
    bulkUpdateUsers: function bulkUpdateUsers(data) {
      return this.get('requestService').post('/users/actions/mass-update/', data);
    },
    bulkUpdateClients: function bulkUpdateClients(data) {
      return this.get('requestService').post('/clients/actions/mass-update/', data);
    },
    bulkUpdateReports: function bulkUpdateReports(data) {
      return this.requestService.post('/reports/actions/mass-update/', data);
    },
    getReportSubscriptionDependants: function getReportSubscriptionDependants(id, data) {
      return this.requestService.post("/reports/".concat(id, "/report-subscription-dependants/"), data);
    },
    changeDataSetFieldFormat: function changeDataSetFieldFormat(dataSourceId, dataSetId, dataSetField, payload) {
      var _this13 = this;

      var url = "data-sources/".concat(dataSourceId, "/datasets/").concat(dataSetId, "/dataset-fields/").concat(dataSetField, "/");
      var action = 'actions/change-format';
      url = "".concat(url).concat(action, "/");
      return this.get('requestService').post(url, payload).then(function (response) {
        return _this13.store.push(_this13.store.normalize('data-set-field', response));
      });
    }
  });

  _exports.default = _default;
});