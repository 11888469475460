define("happyfox-bi/application/top-bar/theme-selection/theme-selection-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7zdAGW5k",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[30,[36,4],[\"theme-selection_item_chart\",[30,[36,3],[[30,[36,2],[[35,1,[\"app\"]],\"light\"],null],\"mod-light-mode\"],null],[35,1,[\"charts\"]]],null]],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"theme-selection_item_content\"],[12],[1,[30,[36,5],[[35,1,[\"name\"]]],null]],[13],[2,\"\\n\"],[6,[37,3],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"u-push-right\"],[12],[1,[30,[36,0],[\"check\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"inline-svg\",\"theme\",\"eq\",\"if\",\"concat-strings\",\"t\",\"isSelected\"]}",
    "meta": {
      "moduleName": "happyfox-bi/application/top-bar/theme-selection/theme-selection-item/template.hbs"
    }
  });

  _exports.default = _default;
});