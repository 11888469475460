define("happyfox-bi/components/data-table/data-bar-cell/component", ["exports", "happyfox-bi/utilities/string"], function (_exports, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    valueInPercent: null,
    init: function init() {
      this._super.apply(this, arguments);

      var column = this.column,
          value = this.value;
      var columnProperties = column.extra;
      var dataBar = columnProperties.dataBar;

      if (dataBar) {
        var valueInPercent;
        var minimumValue = dataBar.minimumValue,
            maximumValue = dataBar.maximumValue;

        if (value <= minimumValue) {
          valueInPercent = 0;
        } else if (value >= maximumValue) {
          valueInPercent = 100;
        } else {
          valueInPercent = (value - minimumValue) / (maximumValue - minimumValue) * 100;
        }

        this.set('valueInPercent', valueInPercent);
      }
    },
    displayValue: Ember.computed('column.extra.columnField', 'rawValue', {
      get: function get() {
        var extra = this.column.extra,
            rawValue = this.rawValue;
        var isValuePresent = Ember.isPresent(rawValue);

        if (isValuePresent && extra.format) {
          return extra.format(rawValue, extra.columnField);
        } else if (!isValuePresent) {
          return '~';
        }

        return rawValue;
      }
    }),
    dataBar: Ember.computed.reads('column.extra.dataBar'),
    showOnlyBars: Ember.computed.reads('dataBar.showOnlyBars'),
    dataBarStyle: Ember.computed('dataBar', 'rawValue', {
      get: function get() {
        var dataBar = this.dataBar,
            valueInPercent = this.valueInPercent,
            rawValue = this.rawValue;
        return Ember.isPresent(rawValue) ? (0, _string.getSafeHtml)("background-color: ".concat(dataBar.barColor, ";width: ").concat(valueInPercent, "%;border-left: 2px solid ").concat(dataBar.barColor, ";")) : '';
      }
    })
  });

  _exports.default = _default;
});