define("happyfox-bi/initializers/modify-basic-dropdown", ["exports", "ember-basic-dropdown/components/basic-dropdown", "ember-basic-dropdown/components/basic-dropdown-content"], function (_exports, _basicDropdown, _basicDropdownContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'modify-basic-dropdown',
    initialize: function initialize() {
      _basicDropdown.default.reopen({
        handleKeydown: function handleKeydown(e) {
          if (e.keyCode === 13) {
            // Prevent opening dropdown on Enter
            return;
          }

          this._super(e);
        }
      });

      _basicDropdownContent.default.reopen({
        // Fix https://github.com/cibernox/ember-basic-dropdown/issues/540
        animationEnabled: function animationEnabled() {
          if (this._animationEnabled !== 'undefined') {
            return this._animationEnabled;
          }
        }
      });
    }
  };
  _exports.default = _default;
});