define("happyfox-bi/visualizations/new/components/display-settings/gauge-chart/band-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nw33QcPf",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"u-flex\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"field mod-name-field\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"field_label\"],[12],[1,[30,[36,0],[\"name\"],null]],[13],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"class\",\"value\",\"input\"],[\"field_value\",[35,2,[\"name\"]],[30,[36,1],[[32,0],\"onBandNameChange\"],null]]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"field mod-number-field\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"field_label\"],[12],[1,[30,[36,0],[\"min\"],null]],[13],[2,\"\\n    \"],[10,\"input\"],[14,0,\"field_value\"],[15,2,[34,2,[\"min\"]]],[15,\"oninput\",[30,[36,1],[[32,0],\"selectedBandMinChange\"],null]],[14,4,\"number\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"field mod-number-field\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"field_label\"],[12],[1,[30,[36,0],[\"max\"],null]],[13],[2,\"\\n    \"],[10,\"input\"],[14,0,\"field_value\"],[15,2,[34,2,[\"max\"]]],[15,\"oninput\",[30,[36,1],[[32,0],\"selectedBandMaxChange\"],null]],[14,4,\"number\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"field\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"field_label\"],[12],[1,[30,[36,0],[\"color\"],null]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"u-flex field_value\"],[12],[2,\"\\n      \"],[1,[30,[36,4],null,[[\"color\",\"includeHexInitial\",\"onChange\"],[[35,2,[\"color\"]],true,[30,[36,1],[[32,0],\"onBandColorChange\"],null]]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[11,\"div\"],[24,0,\"band-item_remove_svg icon-btn\"],[24,\"role\",\"button\"],[4,[38,1],[[32,0],\"removeBand\"],null],[12],[2,\"\\n    \"],[1,[30,[36,5],[\"trash\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"selectedBand\",\"input\",\"color-picker\",\"inline-svg\"]}",
    "meta": {
      "moduleName": "happyfox-bi/visualizations/new/components/display-settings/gauge-chart/band-item/template.hbs"
    }
  });

  _exports.default = _default;
});