define("happyfox-bi/visualization-examples/mock-data/page-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PAGE_3_VISUALIZATION_DATA = _exports.PAGE_3 = void 0;
  var PAGE_3 = {
    id: 3,
    name: 'EMEA: Product Report',
    order: 3,
    report: 1,
    created_by: 1,
    created_at: '2019-06-17T08:08:33Z',
    updated_at: '2019-06-18T11:20:30Z',
    visualizations: [{
      id: 6,
      name: '',
      page: 3,
      display_type: 'data_tile',
      display_settings: {
        size: 'square-s',
        label: 'product',
        value: 'tickets'
      },
      preferences: {
        filters: {
          match_all: []
        },
        date_range: 'last_month'
      },
      settings: {
        fields: [{
          'name': 'product',
          'type': 'dataset_field',
          'dataset': 1,
          'dataset_field': 12,
          'data_type': 'text',
          'is_editable': false
        }, {
          'name': 'tickets',
          'type': 'aggregation',
          'aggregation_type': 'count',
          'dataset': 1,
          'dataset_field': 14,
          'data_type': 'decimal',
          'is_editable': false
        }],
        query: {
          rows: ['product'],
          columns: [],
          values: ['tickets'],
          filters: {
            match_all: [{
              field: 'product',
              predicate: 'is',
              value: ['Product 1']
            }]
          },
          sort_by: []
        }
      }
    }, {
      id: 12,
      name: 'MoM Ticket Count per Product',
      page: 3,
      display_type: 'grouped_bar_chart',
      display_settings: {
        size: 'square-m',
        x_axis: {
          key: 'month',
          label: null
        },
        y_axis: {
          label: 'TICKETS COUNT',
          group_by: 'product',
          value: 'value'
        }
      },
      preferences: {
        filters: {
          match_all: []
        },
        date_range: 'last_month'
      },
      settings: {
        fields: [{
          'name': 'month',
          'type': 'dataset_field',
          'dataset': 1,
          'dataset_field': 12,
          'data_type': 'text',
          'is_editable': false
        }, {
          'name': 'product',
          'type': 'dataset_field',
          'dataset': 1,
          'dataset_field': 13,
          'data_type': 'text',
          'is_editable': false
        }, {
          'name': 'value',
          'type': 'aggregation',
          'aggregation_type': 'count',
          'dataset': 1,
          'dataset_field': 14,
          'data_type': 'decimal',
          'is_editable': false
        }],
        query: {
          rows: ['month'],
          columns: ['product'],
          values: ['value'],
          filters: {
            match_all: []
          },
          sort_by: []
        }
      }
    }],
    layout: {
      "items": [{
        x: 8,
        y: 15,
        width: 8,
        height: 8,
        visualization_id: 12
      }, {
        x: 8,
        y: 0,
        width: 4,
        height: 4,
        visualization_id: 6
      }]
    }
  };
  _exports.PAGE_3 = PAGE_3;
  var PAGE_3_VISUALIZATION_DATA = [{
    id: 6,
    data: [{
      'product': 'ACME 651',
      'tickets': 3812
    }]
  }, {
    id: 12,
    data: [{
      'month': 'August',
      'product': 'Acme Super 65',
      'value': 1243
    }, {
      'month': 'August',
      'product': 'Acme Super Sport',
      'value': 2563
    }, {
      'month': 'August',
      'product': 'Acme Sport Pace',
      'value': 999
    }, {
      'month': 'August',
      'product': 'Acme Super 45',
      'value': 100
    }, {
      'month': 'September',
      'product': 'Acme Super 65',
      'value': 2345
    }, {
      'month': 'September',
      'product': 'Acme Super Sport',
      'value': 1876
    }, {
      'month': 'September',
      'product': 'Acme Sport Pace',
      'value': 324
    }, {
      'month': 'September',
      'product': 'Acme Super 45',
      'value': 1003
    }, {
      'month': 'October',
      'product': 'Acme Super 65',
      'value': 3009
    }, {
      'month': 'October',
      'product': 'Acme Super Sport',
      'value': 555
    }, {
      'month': 'October',
      'product': 'Acme Sport Pace',
      'value': 209
    }, {
      'month': 'October',
      'product': 'Acme Super 45',
      'value': 2000
    }]
  }];
  _exports.PAGE_3_VISUALIZATION_DATA = PAGE_3_VISUALIZATION_DATA;
});