define("happyfox-bi/application/top-bar/component", ["exports", "happyfox-bi/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Component.extend({
    classNames: ['app-top-bar', 'top-bar'],
    // class .top-bar is used in popover position/dimension calculation
    classNameBindings: ['isDesktopApp'],
    attributeBindings: ['data-pop-over-selector'],
    dataService: Ember.inject.service('data'),
    profileService: Ember.inject.service('profile'),
    profile: Ember.computed.reads('profileService.profile'),
    featuresService: Ember.inject.service('features'),
    isAppsEnabled: Ember.computed.reads('featuresService.manageApps'),
    isDatasourcesEnabled: Ember.computed.reads('featuresService.manageDataSources'),
    isReportTemplatesEnabled: Ember.computed.reads('featuresService.createReportTemplates'),
    isReportSubscriptionsEnabled: Ember.computed.reads('featuresService.reportSubscriptions'),
    applicationService: Ember.inject.service('application'),
    fullscreenService: Ember.inject.service('fullscreen'),
    isCurrentlyFullscreen: Ember.computed.reads('fullscreenService.isCurrentlyFullscreen'),
    isDesktopApp: _constants.IS_DESKTOP_APP,
    isThemeSelectionOpen: false,
    username: null,
    canShowMySettings: false,
    canShowAccountSwitcherModal: false,
    currentTheme: Ember.computed('profile.themePreferences', {
      get: function get() {
        var _this$get = this.get('profile'),
            themePreferences = _this$get.themePreferences;

        return themePreferences;
      }
    }),
    canShowModuleSwitcher: Ember.computed('isReportTemplatesEnabled', 'isReportSubscriptionsEnabled', 'isDatasourcesEnabled', 'isAppsEnabled', {
      get: function get() {
        var isReportTemplatesEnabled = this.isReportTemplatesEnabled,
            isReportSubscriptionsEnabled = this.isReportSubscriptionsEnabled,
            isDatasourcesEnabled = this.isDatasourcesEnabled,
            isAppsEnabled = this.isAppsEnabled;
        return isReportTemplatesEnabled || isReportSubscriptionsEnabled || isDatasourcesEnabled || isAppsEnabled;
      }
    }),
    canShowAccountSwitcher: Ember.computed('profile.userAccounts.[]', {
      get: function get() {
        var profile = this.profile;
        return profile && profile.userAccounts.length > 1;
      }
    }),
    actions: {
      toggleFullscreen: function toggleFullscreen() {
        var _Ember$$ = Ember.$('.js-application-content'),
            _Ember$$2 = _slicedToArray(_Ember$$, 1),
            element = _Ember$$2[0];

        this.get('fullscreenService').toggleFullscreen(element);
        this.get('applicationService').closeSidePane();
      },
      changeTheme: function changeTheme(theme) {
        var _this = this;

        var app = theme.app,
            charts = theme.charts;
        var requestData = {
          "theme_preferences": {
            app: app,
            charts: charts
          }
        };
        this.dataService.updateProfile(requestData).then(function () {
          _this.onRefresh();
        }).finally(function () {
          _this.set('isThemeSelectionOpen', false);
        });
      },
      toggleMySettings: function toggleMySettings() {
        this.toggleProperty('canShowMySettings');
      },
      toggleAccountSwitcher: function toggleAccountSwitcher() {
        this.toggleProperty('canShowAccountSwitcherModal');
      },
      logoutStaff: function logoutStaff() {
        this.logout();
      },
      reload: function reload() {
        if (this.get('isDesktopApp')) {
          window.location.reload();
        }
      }
    }
  });

  _exports.default = _default;
});