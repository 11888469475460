define("happyfox-bi/initializers/modify-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'modify-component',
    initialize: function initialize() {
      Ember.Component.reopen({
        // attributeBindings: ['data-test-id', 'data-pop-over', 'aria-label'],
        init: function init() {
          this._super.apply(this, arguments);

          if (this.get('tagName') !== '') {
            // If it's not a tag-less component
            var attributeBindings = Ember.isArray(this.get('attributeBindings')) ? this.get('attributeBindings').slice() : [];
            attributeBindings.addObjects(['data-test-id', 'data-pop-over', 'aria-label']);
            this.set('attributeBindings', attributeBindings);
          }
        }
      });
    }
  };
  _exports.default = _default;
});