define("happyfox-bi/page-screenshot/controller", ["exports", "happyfox-bi/mixins/date-range-picker"], function (_exports, _dateRangePicker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_dateRangePicker.default, {
    queryParams: [{
      dateRange: 'date_range'
    }],
    dateRange: null,
    dataService: Ember.inject.service('data'),
    applicationService: Ember.inject.service('application'),
    hasVisualizations: Ember.computed.bool('model.visualizations.length'),
    isStaticGrid: false,
    reportId: null,
    showDateRangePicker: Ember.computed('model.visualizations.@each.settings', {
      get: function get() {
        return this.get('model.visualizations').mapBy('settings').mapBy('query').isAny('dateRangeField');
      }
    }),
    visualizationsPositions: Ember.computed('model.layout.items.@each.visualizationId', {
      get: function get() {
        var layouts = this.get('model.layout.items');
        var visualizations = this.get('model.visualizations');
        var visualizationsPositions = Ember.Object.create();
        visualizations.forEach(function (visualization) {
          visualizationsPositions[visualization.id] = layouts.find(function (layout) {
            return layout.visualizationId.toString() == visualization.id;
          });
        });
        return visualizationsPositions;
      }
    }),
    actions: {
      getVisualizationData: function getVisualizationData(data) {
        data.dateRange = this.dateRange;
        return this.get('dataService').getVisualizationData(data);
      }
    }
  });

  _exports.default = _default;
});