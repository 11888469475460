define("happyfox-bi/form-field-models/dynamic-date-field", ["exports", "happyfox-bi/form-field-models/date-field", "happyfox-bi/utilities/date"], function (_exports, _dateField, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dateField.default.extend({
    isRelative: true,
    // takes a number if it is relative
    isDynamicDate: false,
    isDirty: Ember.computed('isRelative', 'value', '_initialValue', {
      get: function get() {
        var value = this.get('value');
        var initialValue = this.get('_initialValue');

        if (this.get('isRelative')) {
          // to avoid parsing null. parseInt(null) will give NaN
          return !_.isEqual(Ember.isPresent(initialValue) ? parseInt(initialValue) : initialValue, Ember.isPresent(value) ? parseInt(value) : value);
        } else {
          return !_.isEqual(initialValue, value);
        }
      }
    }),
    validate: function validate() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this._super().then(function () {
          // date check
          var value = _this.get('value');

          var isRelative = _this.get('isRelative');

          if (isRelative) {
            if (isNaN(value)) {
              _this.addError(_this.get('intlService').lookup('validation.invalid-number-message'));

              reject();
            }
          } else {
            // https://github.com/moment/moment/issues/1407
            if (!Ember.isBlank(value) && !moment((0, _date.createDate)(value)).isValid()) {
              _this.addError(_this.get('intlService').lookup('validation.invalid-date-message'));

              reject();
            }
          }

          if (isRelative) {
            _this.set('_cleanValue', Ember.isPresent(value) ? Number(value) : null);
          } else {
            _this.set('_cleanValue', value ? (0, _date.convertDateToApiFormat)(value) : null);
          }

          resolve();
        }).catch(function () {
          reject();
        });
      });
    }
  });

  _exports.default = _default;
});