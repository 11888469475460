define("happyfox-bi/page-screenshot/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    dataService: Ember.inject.service('data'),
    applicationService: Ember.inject.service('application'),
    beforeModel: function beforeModel() {
      this.get('applicationService').set('isIndependentWebPage', true);
    },
    model: function model(params) {
      var _this = this;

      var reportId = params.report_id,
          pageId = params.page_id;
      this.set('reportId', reportId);
      return this.get('dataService').getPage(reportId, pageId).catch(function () {
        _this.transitionTo('error');
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      var reportId = this.get('reportId');
      controller.setProperties({
        'isStaticGrid': true,
        'reportId': reportId,
        dateRangePreferences: {
          'dateRange': controller.dateRange
        }
      });
    }
  });

  _exports.default = _default;
});