define("happyfox-bi/components/data-tile/component", ["exports", "happyfox-bi/components/base-chart/component", "happyfox-bi/utilities/time"], function (_exports, _component, _time) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var DEFAULT_COMPARE_METRIC = 'higher_is_better';

  var _default = _component.default.extend({
    classNames: ['chart', 'data-tile'],
    classNameBindings: ['isChartComparison:mod-comparison'],
    labelPosition: Ember.computed.reads('config.labelPosition'),
    heading: Ember.computed.reads('config.staticLabel'),
    data: null,
    comparisonData: null,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.isChartComparison) {
        var compareDateRangesList = this.get('compareDateRangesList');
        var comparisonData = Ember.A();
        compareDateRangesList.forEach(function (compareField) {
          var compareDateRangeDatum = Ember.Object.create();
          compareDateRangeDatum.set('dateRangeLabel', _this.getCompareSeriesName(compareField));

          if (compareField.data.length) {
            compareDateRangeDatum.setProperties({
              label: _this.getCompareDataTileLabel(compareField.data),
              value: _this.getCompareDataTileValue(compareField.data)
            });
            compareDateRangeDatum.set('displayValue', _this.getDisplayValue(compareDateRangeDatum.value));
          }

          comparisonData.addObject(compareDateRangeDatum);
        });
        this.set('comparisonData', comparisonData);
      }
    },
    sortDataByDateAndTimestampFields: function sortDataByDateAndTimestampFields() {
      /* Overriding sortDataByDateAndTimestampFields() from Base chart as we dont want any sort by date/timestamp fields for data tile  */
    },
    getCompareDataTileLabel: function getCompareDataTileLabel(compareData) {
      var _compareData = _slicedToArray(compareData, 1),
          comparisonTileData = _compareData[0];

      return comparisonTileData[this.get('config.label')];
    },
    getCompareDataTileValue: function getCompareDataTileValue(compareData) {
      var _compareData2 = _slicedToArray(compareData, 1),
          comparisonTileData = _compareData2[0];

      var comparisonTileValue = comparisonTileData[this.get('config.value')];
      return comparisonTileValue;
    },
    firstRecord: Ember.computed('data.[]', {
      get: function get() {
        return this.get('data')[0];
      }
    }),
    value: Ember.computed('firstRecord', 'config.value', {
      get: function get() {
        var valueKey = this.get('config.value');
        var value = this.get("firstRecord.".concat(valueKey));
        return value;
      }
    }),
    displayValue: Ember.computed('value', {
      get: function get() {
        var value = this.value;
        return this.getDisplayValue(value);
      }
    }),
    label: Ember.computed('firstRecord', 'config.label', {
      get: function get() {
        var labelKey = this.get('config.label');
        return this.get("firstRecord.".concat(labelKey));
      }
    }),
    focusType: Ember.computed('config.focus', {
      get: function get() {
        return this.get('config.focus') ? this.get('config.focus') : 'value';
      }
    }),
    percentageChange: Ember.computed('value', 'config.value', 'comparisonData.[]', {
      get: function get() {
        var compareDateRangeDatum = Ember.isPresent(this.comparisonData) ? this.comparisonData[0] : null;

        if (compareDateRangeDatum && Ember.isPresent(compareDateRangeDatum.value)) {
          var percentageChangeInValue = (this.value - compareDateRangeDatum.value) / compareDateRangeDatum.value * 100;
          return Math.abs(_.round(percentageChangeInValue, 1));
        }
      }
    }),
    isValueHigher: Ember.computed('value', 'comparisonData', {
      get: function get() {
        var compareDateRangeDatum = Ember.isPresent(this.comparisonData) ? this.comparisonData[0] : null;

        if (compareDateRangeDatum) {
          return this.value > compareDateRangeDatum.value;
        }
      }
    }),
    compareMetric: Ember.computed('config.compareMetric', {
      get: function get() {
        var _this$get = this.get('config'),
            compareMetric = _this$get.compareMetric;

        return Ember.isPresent(compareMetric) ? compareMetric : DEFAULT_COMPARE_METRIC;
      }
    }),
    isPositiveChange: Ember.computed('isValueHigher', 'compareMetric', 'percentageChange', {
      get: function get() {
        var isValueHigher = this.isValueHigher,
            compareMetric = this.compareMetric,
            percentageChange = this.percentageChange; // Showing `0` as positive color

        return percentageChange == 0 || compareMetric == 'higher_is_better' && isValueHigher || compareMetric == 'lower_is_better' && !isValueHigher;
      }
    }),
    getDisplayValue: function getDisplayValue(value) {
      if (!Ember.isPresent(value)) return;
      var isValueNumeric = typeof value === 'number';
      var visualization = this.get('widgetModel');
      var visualizationFields = visualization.get('settings.fields');
      var valueKey = this.config.value;
      var valueField = visualizationFields.findBy('name', valueKey);
      var isCurrencyField = this.isFieldCurrencyType(valueKey) || Ember.isPresent(valueField.currencySign);

      if (isCurrencyField) {
        return this.formatValueByCurrencyField(value, valueField);
      }

      var isTimeField = valueField.dataType === 'time';
      var canFormatTimeAsDuration = this.canFormatTimeAsDuration(valueKey);

      if (canFormatTimeAsDuration) {
        /* Showing only largest 3 units for spacing */
        var options = {
          largest: 3
        };
        return (0, _time.humanizeDuration)(value, this.getTimeFieldBaseUnit(valueKey), options);
      }

      if (isTimeField) {
        return "".concat(value, " ").concat(this.getTimeFieldBaseUnit(valueKey));
      }

      return isValueNumeric ? this.intlService.formatNumber(value) : value;
    }
  });

  _exports.default = _default;
});