define("happyfox-bi/apps/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    router: Ember.inject.service(),
    accountInfoService: Ember.inject.service('account-info'),
    beforeModel: function beforeModel() {
      var default_app_page = 'happyfox'; // Bugfix: When an BI account doesn't have 'happyfox' linked. Remove this workaround once we have a landing page for apps.

      if (this.accountInfoService.isAircallEnabled) {
        default_app_page = 'aircall';
      } else if (this.accountInfoService.isSalesforceEnabled) {
        default_app_page = 'salesforce';
      } else if (this.accountInfoService.isWrikeEnabled) {
        default_app_page = 'wrike';
      }

      this.router.transitionTo('apps.details', default_app_page);
    }
  });

  _exports.default = _default;
});