define("happyfox-bi/data-sources/components/create-data-set/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BTHMi2kS",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,8],null,[[\"onClose\"],[[30,[36,1],[[32,0],\"toggleCreateDataSet\"],null]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"modal_header_title\"],[12],[1,[30,[36,0],[\"create-data-set\"],null]],[13],[2,\"\\n  \"],[11,\"div\"],[24,\"role\",\"button\"],[24,0,\"modal_close\"],[4,[38,1],[[32,0],\"toggleCreateDataSet\"],null],[12],[1,[30,[36,2],[\"modal-close\"],null]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"modal_body mod-modal-large\"],[12],[2,\"\\n    \"],[10,\"section\"],[14,0,\"field\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"field_label\"],[12],[1,[30,[36,0],[\"name\"],null]],[13],[2,\"\\n      \"],[1,[30,[36,4],null,[[\"value\",\"class\"],[[35,3],\"field_value mod-half-width\"]]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"footer\"],[14,0,\"invite-user-modal_footer u-vertically-centered-container\"],[12],[2,\"\\n      \"],[1,[30,[36,7],null,[[\"class\",\"text\",\"inProgress\",\"inProgressText\",\"disabled\",\"onClick\"],[\"hf-primary-action\",[30,[36,0],[\"create\"],null],[35,6],[30,[36,0],[\"creating\"],null],[35,5],[30,[36,1],[[32,0],\"createDataSet\"],null]]]]],[2,\"\\n      \"],[11,\"a\"],[24,0,\"secondary-action u-push-right\"],[24,\"role\",\"button\"],[4,[38,1],[[32,0],\"toggleCreateDataSet\"],null],[12],[1,[30,[36,0],[\"cancel\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"inline-svg\",\"displayName\",\"input\",\"isCreateDisabled\",\"createInProgress\",\"async-button\",\"modal-dialog\"]}",
    "meta": {
      "moduleName": "happyfox-bi/data-sources/components/create-data-set/template.hbs"
    }
  });

  _exports.default = _default;
});