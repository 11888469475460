define("happyfox-bi/data-sources/details/data-sets/details/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    dataService: Ember.inject.service('data'),
    model: function model(params) {
      var dataSetId = params.data_set_id;
      var dataSet = this.get('dataService').peekDataSet(dataSetId);

      if (dataSet) {
        return dataSet;
      } else {
        var dataSourceId = this.paramsFor('data-sources.details').id;
        return this.get('dataService').getDataSet(dataSourceId, params.data_set_id);
      }
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.initiate();
      controller.set('canShowFormatForm', false);
      controller.set('fieldToFormat', null);
    }
  });

  _exports.default = _default;
});