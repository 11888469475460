define("happyfox-bi/internal-login/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    profileService: Ember.inject.service('profile'),
    beforeModel: function beforeModel() {
      if (this.get('profileService.isUserAuthenticated')) {
        this.transitionTo('reports');
      }
    }
  });

  _exports.default = _default;
});