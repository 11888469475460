define("happyfox-bi/components/collapsible-panels/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    panelActionsService: Ember.inject.service('panel-actions'),
    classNames: ['panels'],
    group: null
  });

  _exports.default = _default;
});