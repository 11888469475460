define("happyfox-bi/application/top-bar/theme-selection/theme-selection-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['theme-selection_item'],
    classNameBindings: ['isSelected'],
    theme: null,
    selectedTheme: null,
    isSelected: Ember.computed('theme', 'selectedTheme', {
      get: function get() {
        return this.get('theme') === this.get('selectedTheme');
      }
    }),
    click: function click() {
      var theme = this.get('theme');
      this.selectTheme(theme);
    }
  });

  _exports.default = _default;
});