define("happyfox-bi/form-field-models/phone-field", ["exports", "happyfox-bi/form-field-models/base-field", "happyfox-bi/utilities/form-models", "happyfox-bi/mixins/validation"], function (_exports, _baseField, _formModels, _validation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TYPE_CHOICES = [{
    label: 'phone-type.main',
    value: 'main'
  }, {
    label: 'phone-type.mobile',
    value: 'mobile'
  }, {
    label: 'phone-type.work',
    value: 'work'
  }, {
    label: 'phone-type.home',
    value: 'home'
  }, {
    label: 'phone-type.other',
    value: 'other'
  }];

  var _default = _baseField.default.extend(_validation.default, {
    isPhone: true,
    typeField: null,
    _initialType: null,
    accountInfoService: Ember.inject.service('account-info'),
    init: function init() {
      var _this = this;

      if (!this.get('value')) {
        this.set('value', this.get('accountInfoService').getCountryCode());
      }

      this._super.apply(this, arguments);

      var typeChoices = TYPE_CHOICES.slice().map(function (choice) {
        var newChoice = Ember.Object.create(choice);
        newChoice.label = _this.get('intlService').lookup(choice.label);
        return newChoice;
      });
      var type = this.get('type');
      var typeValue = type ? typeChoices.findBy('value', type) : typeChoices[0];
      var typeField = Ember.Object.create({
        key: 'type',
        isChoice: true,
        isMandatory: true,
        label: this.get('intlService').lookup('type'),
        choices: typeChoices,
        choiceLabelKey: 'label',
        value: typeValue
      });
      this.set('typeField', (0, _formModels.createField)(this, typeField));
      this.set('_initialType', typeValue);
    },
    isEmpty: Ember.computed('value', {
      get: function get() {
        var value = this.get('value');
        return Ember.isPresent(value) ? value === this.get('accountInfoService').getCountryCode() : true;
      }
    }),
    isDirty: Ember.computed('value', '_initialValue', 'typeField.value.value', '_initialType', {
      get: function get() {
        return !_.isEqual(this.get('_initialValue'), this.get('value')) || !_.isEqual(this.get('_initialType'), this.get('typeField.value'));
      }
    }),
    updateInitialValue: function updateInitialValue() {
      this._super();

      this.set('_initialType', this.get('typeField.value'));
    },
    validate: function validate() {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this2._super().then(function () {
          // character limit check
          var value = _this2.get('value');

          _this2.set('_cleanValue', value);

          resolve();
        }).catch(function () {
          reject();
        });
      });
    }
  });

  _exports.default = _default;
});