define("happyfox-bi/components/form-field-input/multi-choice/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s8m/O7+7",
    "block": "{\"symbols\":[\"option\",\"isSelected\",\"&default\"],\"statements\":[[6,[37,10],null,[[\"class\",\"options\",\"labelProperty\",\"valueProperty\",\"selection\",\"onChange\",\"data-test-id\"],[[30,[36,2],[[30,[36,9],[\"form-field-input\",[30,[36,4],[[35,0,[\"isInline\"]],\"hf-mod-inline\"],null]],null],\" \",[30,[36,4],[[35,8],\"is-error\"],null],\" \"],null],[35,0,[\"options\"]],[35,0,[\"labelProperty\"]],[35,0,[\"valueProperty\"]],[35,7],[30,[36,6],[[32,0],\"updateSelection\"],null],[35,5]]],[[\"default\"],[{\"statements\":[[6,[37,4],[[27,[32,3]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,3,[[32,1],[32,2]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"li\"],[14,0,\"hf-multi-choice-option\"],[12],[2,\"\\n      \"],[10,\"label\"],[15,\"data-test-id\",[31,[\"field-\",[30,[36,1],[[32,1],[35,0,[\"labelProperty\"]]],null]]]],[15,\"title\",[30,[36,1],[[32,1],[35,0,[\"labelProperty\"]]],null]],[12],[2,\"\\n        \"],[1,[30,[36,3],null,[[\"type\",\"checked\",\"data-test-id\"],[\"checkbox\",[32,2],[30,[36,2],[\"field-\",[30,[36,1],[[32,1],[35,0,[\"valueProperty\"]]],null],\"-checkbox\"],null]]]]],[2,\"\\n        \"],[1,[30,[36,1],[[32,1],[35,0,[\"labelProperty\"]]],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1,2]}]]],[2,\"\\n\"],[19,\"components/form-field-input/partials/error-message\",[]],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"field\",\"get\",\"concat\",\"input\",\"if\",\"fieldDataTestId\",\"action\",\"selectedValues\",\"hasErrors\",\"concat-strings\",\"multiselect-checkboxes\"]}",
    "meta": {
      "moduleName": "happyfox-bi/components/form-field-input/multi-choice/template.hbs"
    }
  });

  _exports.default = _default;
});