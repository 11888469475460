define("happyfox-bi/register/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    dataService: Ember.inject.service('data'),
    token: null,
    isValidatingToken: null,
    isValidToken: false,
    email: null,
    firstName: null,
    lastName: null,
    password: null,
    confirmPassword: null,
    passwordsMismatched: false,
    isSubmitInProgress: false,
    isRegistrationSuccess: false,
    error: null,
    passwordErrors: null,
    hasErrors: Ember.computed.or('error', 'passwordsMismatched'),
    showingPasswordPolicy: Ember.computed('isValidToken', 'isRegistrationSuccess', {
      get: function get() {
        return this.isValidToken && !this.isRegistrationSuccess;
      }
    }),
    validateToken: function validateToken() {
      var _this = this;

      var token = this.get('token');
      this.setProperties({
        isValidToken: false,
        isValidatingToken: true,
        email: null,
        error: null
      });
      return this.get('dataService').validateRegistarionToken(token).then(function (userDetails) {
        _this.setProperties({
          'isValidToken': true,
          'email': userDetails.email
        });
      }).catch(function (_ref) {
        var errors = _ref.errors;

        _this.setProperties({
          error: errors.error,
          isValidToken: false
        });
      }).finally(function () {
        _this.set('isValidatingToken', false);
      });
    },
    actions: {
      registerUser: function registerUser() {
        var _this2 = this;

        var password = this.get('password');
        var confirmPassword = this.get('confirmPassword');
        var passwordsMatched = password === confirmPassword;
        this.set('passwordsMismatched', !passwordsMatched);
        this.set('passwordErrors', null);

        if (passwordsMatched) {
          this.setProperties({
            isRegistrationSuccess: false,
            isSubmitInProgress: true,
            error: null
          });
          var data = {
            'first_name': this.get('firstName'),
            'last_name': this.get('lastName'),
            'token': this.get('token'),
            password: password,
            confirmPassword: confirmPassword
          };
          this.get('dataService').registerUser(data).then(function () {
            _this2.set('isRegistrationSuccess', true);
          }).catch(function (_ref2) {
            var errors = _ref2.errors;

            _this2.set('error', errors.error);

            if (errors.password) {
              _this2.set('passwordErrors', errors.password);
            }
          }).finally(function () {
            _this2.set('isSubmitInProgress', false);
          });
        }
      },
      goToLogin: function goToLogin() {
        this.transitionToRoute('login');
      }
    }
  });

  _exports.default = _default;
});