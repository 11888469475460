define("happyfox-bi/form-field-models/time-field", ["exports", "happyfox-bi/form-field-models/base-field", "happyfox-bi/utilities/time"], function (_exports, _baseField, _time) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var HOUR_OPTIONS = [];

  for (var i = 1; i <= 12; i++) {
    HOUR_OPTIONS.addObject(Ember.Object.create({
      value: i,
      label: "00".concat(i).slice(-2)
    }));
  }

  var MINUTE_OPTIONS = [];

  for (var _i = 0; _i < 60; _i++) {
    MINUTE_OPTIONS.addObject(Ember.Object.create({
      value: _i,
      label: "00".concat(_i).slice(-2)
    }));
  }

  var _default = _baseField.default.extend({
    isTime: true,
    hourField: null,
    minuteField: null,
    meridiem: '',
    isAM: Ember.computed.equal('meridiem', 'AM'),
    isPM: Ember.computed.equal('meridiem', 'PM'),
    init: function init() {
      this._super.apply(this, arguments);

      var intlService = this.get('intlService');
      var hourOptions = HOUR_OPTIONS;
      var minuteOptions = MINUTE_OPTIONS;
      this.setProperties({
        hourField: Ember.Object.create({
          key: 'hour',
          isChoice: true,
          choiceLabelKey: 'label',
          choiceValueKey: 'value',
          choices: hourOptions,
          value: null,
          placeholder: intlService.lookup('manage.hours'),
          validate: function validate() {// Dummy method. No specific validation for hour
          }
        }),
        minuteField: Ember.Object.create({
          key: 'minute',
          choiceLabelKey: 'label',
          choiceValueKey: 'value',
          isChoice: true,
          choices: minuteOptions,
          value: null,
          placeholder: intlService.lookup('manage.minutes'),
          validate: function validate() {// Dummy method. No specific validation for minute
          }
        })
      });
      this.set('meridiem', 'AM');
    },
    value: Ember.computed('hourField.value', 'minuteField.value', 'meridiem', {
      get: function get() {
        var hourValue = this.get('hourField.value');
        var meridiemValue = this.get('meridiem');

        if (Ember.isPresent(hourValue)) {
          // Replacing hour in the selected date.
          hourValue = (0, _time.convert12HourTo24HourFormat)(hourValue.get('value'), meridiemValue);
        }

        var minuteLabel = this.get('minuteField.value.label');

        if (Ember.isPresent(hourValue) && Ember.isPresent(minuteLabel)) {
          var hourLabel = "00".concat(hourValue).slice(-2);
          return "".concat(hourLabel, ":").concat(minuteLabel); // Using labels since server is expecting data in the hh:mm format
        } else {
          return '';
        }
      },
      set: function set(key, value) {
        var _this = this;

        // Setter of computed property gets called before init: https://github.com/emberjs/ember.js/issues/15584
        Ember.run.next(function () {
          // because otherwise value getter is not getting called when default values of hour and minute are set
          if (Ember.isPresent(value)) {
            var _value$split = value.split(':'),
                _value$split2 = _slicedToArray(_value$split, 2),
                hourValue = _value$split2[0],
                minuteValue = _value$split2[1];

            hourValue = Number(hourValue);
            minuteValue = Number(minuteValue);
            var convertedHourValue = (0, _time.convert24HourTo12HourFormat)(hourValue);
            var hourOption = HOUR_OPTIONS.findBy('value', convertedHourValue.hour);
            var minuteOption = MINUTE_OPTIONS.findBy('value', minuteValue);

            _this.setProperties({
              'hourField.value': hourOption,
              'minuteField.value': minuteOption,
              meridiem: convertedHourValue.meridiem
            });
          } else {
            _this.setProperties({
              'hourField.value': null,
              'minuteField.value': null,
              'meridiem': 'AM'
            });
          }
        });
        return value;
      }
    })
  });

  _exports.default = _default;
});