define("happyfox-bi/apps/details/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    dataService: Ember.inject.service('data'),
    model: function model(params) {
      this.appName = params.name;
      return this.dataService.getApp(params.name);
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      if (!model.name) {
        model = {
          name: this.appName
        };
      }

      controller.setProperties({
        model: model
      });
    },
    afterModel: function afterModel(model, transition) {
      if (transition.to && transition.to.queryParams && transition.to.queryParams.error) {
        model.error = transition.to.queryParams.error;
      }

      transition.pageTitleInfo = {
        name: model.name
      };
    }
  });

  _exports.default = _default;
});