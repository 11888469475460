define("happyfox-bi/visualization-examples/mock-data/page-7", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PAGE_7_VISUALIZATION_DATA = _exports.PAGE_7 = void 0;
  var PAGE_7 = {
    id: 7,
    name: 'DELTA - Ticket Volume Distribution',
    order: 7,
    report: 1,
    created_by: 1,
    created_at: '2019-06-17T08:08:33Z',
    updated_at: '2019-06-18T11:20:30Z',
    visualizations: [{
      id: 28,
      name: 'TICKETS BY CATGEORY',
      page: 7,
      display_type: 'doughnut_chart',
      display_settings: {
        size: 'square-m',
        category: 'category',
        value: 'tickets',
        colors: ['#deeaf9', '#767bdf', '#bf5941', '#304155']
      },
      preferences: {
        filters: {
          match_all: []
        },
        date_range: 'last_month'
      },
      settings: {
        fields: [{
          'name': 'category',
          'type': 'dataset_field',
          'dataset': 1,
          'dataset_field': 12,
          'data_type': 'text',
          'is_editable': false
        }, {
          'name': 'tickets',
          'type': 'aggregation',
          'aggregation_type': 'count',
          'dataset': 1,
          'dataset_field': 14,
          'data_type': 'decimal',
          'is_editable': false
        }],
        query: {
          rows: ['category'],
          columns: [],
          values: ['tickets'],
          filters: {
            match_all: []
          },
          sort_by: []
        }
      }
    }, {
      id: 29,
      name: 'TICKETS BY LIFESPAN',
      page: 7,
      display_type: 'bar_chart',
      display_settings: {
        size: 'square-m',
        x_axis: {
          key: 'lifeSpan',
          label: 'LIFE SPAN'
        },
        y_axis: {
          key: 'tickets',
          label: 'TICKETS COUNT'
        },
        color: '#4B8CDE'
      },
      preferences: {
        filters: {
          match_all: []
        },
        date_range: 'last_month'
      },
      settings: {
        fields: [{
          'name': 'lifeSpan',
          'type': 'dataset_field',
          'dataset': 1,
          'dataset_field': 12,
          'data_type': 'text',
          'is_editable': false
        }, {
          'name': 'tickets',
          'type': 'aggregation',
          'aggregation_type': 'count',
          'dataset': 1,
          'dataset_field': 14,
          'data_type': 'decimal',
          'is_editable': false
        }],
        query: {
          rows: [],
          columns: [],
          values: [],
          filters: {
            match_all: []
          },
          sort_by: []
        }
      }
    }],
    layout: {
      'items': [{
        'visualization_id': 28,
        'width': 8,
        'height': 8,
        'x': 0,
        'y': 8
      }, {
        'visualization_id': 29,
        'width': 8,
        'height': 8,
        'x': 8,
        'y': 8
      }]
    }
  };
  _exports.PAGE_7 = PAGE_7;
  var PAGE_7_VISUALIZATION_DATA = [{
    id: 28,
    data: [{
      'category': 'Baggage',
      'tickets': 2500
    }, {
      'category': 'Upgrades',
      'tickets': 4000
    }, {
      'category': 'Seats',
      'tickets': 3245
    }, {
      'category': 'eCommerce',
      'tickets': 1900
    }]
  }, {
    id: 29,
    data: [{
      'lifeSpan': 'Less than 30 Days',
      'tickets': 543
    }, {
      'lifeSpan': '30 - 60 Days',
      'tickets': 200
    }, {
      'lifeSpan': '60 - 90 Days',
      'tickets': 324
    }, {
      'lifeSpan': null,
      'tickets': 90
    }]
  }];
  _exports.PAGE_7_VISUALIZATION_DATA = PAGE_7_VISUALIZATION_DATA;
});