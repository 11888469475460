define("happyfox-bi/utilities/editor", ["exports", "happyfox-bi/config/environment", "happyfox-bi/utilities/text-area"], function (_exports, _environment, _textArea) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getEditorInstanceById = getEditorInstanceById;
  _exports.getEditorTextContent = getEditorTextContent;
  _exports.insertHtmlAtCaretPosition = insertHtmlAtCaretPosition;
  _exports.isContentEmpty = isContentEmpty;
  _exports.replaceBlockquoteStyles = replaceBlockquoteStyles;

  function isContentEmpty(editorInstance) {
    try {
      var snapshot = editorInstance.getSnapshot(); // temporarily create a dom and assign the editor's html content to it. From that we can get the plain text content.

      var dom = document.createElement('DIV');
      dom.innerHTML = snapshot;
      var plainText = dom.textContent || dom.innerText;
      return Ember.isEmpty(editorInstance.getData()) || Ember.isEmpty(plainText.trim());
    } catch (exception) {
      Ember.Logger.log('Error while verifying content is empty or not in RTE');
    }
  }

  function getEditorTextContent(editorInstance) {
    if (editorInstance) {
      return editorInstance.element.getText();
    }
  } // TO DO: Install CK Editor when required 


  function getEditorInstanceById(instanceId) {
    /* eslint-disable no-undef */
    return CKEDITOR.instances[instanceId];
    /* eslint-enable no-undef */
  }

  function insertHtmlAtCaretPosition(newHtml, elementId) {
    if (_environment.default.environment === 'test') {
      (0, _textArea.insertTextAtCaretPosition)(newHtml, elementId);
    } else {
      var instanceId = elementId;
      var editorInstance = getEditorInstanceById(instanceId);

      if (editorInstance) {
        editorInstance.insertHtml(newHtml);
      }
    }
  }

  function replaceBlockquoteStyles(html) {
    // Blockquote addon is not including the inline styles by default and hence the below code.
    var searchRegex = /<blockquote>/g;
    return html.replace(searchRegex, '<blockquote style="font-style:italic;font-family:Georgia,Times,Times New Roman,serif;padding:2px 8px 2px 20px;display:block;margin-top:10px;margin-bottom:10px;border-left:3px solid #ccc;">');
  }
});