define("happyfox-bi/visualization-examples/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HGdMwb5p",
    "block": "{\"symbols\":[\"visualization\"],\"statements\":[[10,\"section\"],[14,0,\"page content_outlet_main mod-visualization-examples\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"page_top-bar\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"page_top-bar_title\"],[12],[1,[30,[36,7],[\"visualization-examples\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"section\"],[14,0,\"page_content\"],[12],[2,\"\\n\"],[6,[37,9],null,[[\"class\",\"options\"],[\"mod-16-columns\",[30,[36,8],null,[[\"animate\",\"minWidth\",\"cellHeight\",\"width\",\"handleClass\"],[true,960,40,16,\"widget-tile_header\"]]]]],[[\"default\"],[{\"statements\":[[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,3],null,[[\"options\"],[[32,1,[\"layoutOptions\"]]]],[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"div\"],[14,0,\"widget-tile mod-absolute\"],[15,5,[32,1,[\"widgetModel\",\"style\"]]],[12],[2,\"\\n            \"],[10,\"div\"],[15,0,[31,[\"widget-tile_header \",[30,[36,1],[[30,[36,0],[[32,1,[\"type\"]],\"data_tile\"],null],\"is-data-tile\"],null]]]],[12],[1,[32,1,[\"widgetModel\",\"name\"]]],[13],[2,\"\\n            \"],[1,[30,[36,2],[[32,1,[\"type\"]]],[[\"widgetModel\",\"chartData\"],[[32,1,[\"widgetModel\"]],[32,1,[\"widgetData\"]]]]]],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"component\",\"grid-item\",\"visualizations\",\"-track-array\",\"each\",\"t\",\"hash\",\"grid-stack\"]}",
    "meta": {
      "moduleName": "happyfox-bi/visualization-examples/template.hbs"
    }
  });

  _exports.default = _default;
});