define("happyfox-bi/utilities/monitor-async-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = monitiorAsyncAction;

  function monitiorAsyncAction(context, promise) {
    context._loading = true;
    return promise.finally(function () {
      return context._loading = false;
    });
  }
});