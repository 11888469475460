define("happyfox-bi/apps/details/aircall/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r9oyswNs",
    "block": "{\"symbols\":[\"account\"],\"statements\":[[10,\"div\"],[14,0,\"manage-app-form\"],[12],[2,\"\\n  \"],[10,\"p\"],[14,0,\"manage-app-form_heading\"],[12],[1,[30,[36,0],[\"aircall.linked-accounts\"],null]],[13],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2,[\"accounts\"]]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],null,[[\"account\"],[[32,1]]]]],[2,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"app-manage-section_box mod-small\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"aircall.no-account-added\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[11,\"button\"],[24,0,\"hf-primary-action manage-app-form_add-account\"],[4,[38,5],[[32,0],\"addAircallAccount\"],null],[12],[1,[30,[36,0],[\"aircall.add-account\"],null]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"apps/details/aircall/account-info\",\"app\",\"-track-array\",\"each\",\"action\"]}",
    "meta": {
      "moduleName": "happyfox-bi/apps/details/aircall/template.hbs"
    }
  });

  _exports.default = _default;
});