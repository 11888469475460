define("happyfox-bi/visualizations/new/components/merge-datasets/component", ["exports", "happyfox-bi/utilities/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    joinsList: null,
    showJoinsModal: false,
    primaryDataSet: null,
    secondaryDataSet: null,
    dataSetOptions: null,
    init: function init() {
      this._super.apply(this, arguments);

      var joinsList = this.get('joinsList');

      if (Ember.isPresent(joinsList)) {
        this.set('joinsList', (0, _object.cloneArray)(joinsList));
      }

      var secondaryDataSet = this.get('secondaryDataSet');

      if (Ember.isEmpty(joinsList) || Ember.isPresent(joinsList) && secondaryDataSet && Number(secondaryDataSet.get('id')) !== joinsList.get('firstObject.secondaryDataSet')) {
        this.set('joinsList', Ember.A([Ember.Object.create({
          primaryDataSet: this.get('primaryDataSet.id'),
          primaryDataSetField: null,
          secondaryDataSource: this.get('secondaryDataSet.dataSource'),
          secondaryDataSet: this.get('secondaryDataSet.id'),
          secondaryDataSetField: null,
          joinType: 'inner_join'
        })]));
      }
    },
    isSaveDisabled: Ember.computed('joinsList.@each.{primaryDataSet,primaryDataSetField,secondaryDataSet,secondaryDataSetField,joinType}', {
      get: function get() {
        var joinsList = this.get('joinsList');
        var allValuesPresent = joinsList.every(function (joinData) {
          return Ember.isPresent(joinData.primaryDataSet) && Ember.isPresent(joinData.primaryDataSetField) && Ember.isPresent(joinData.secondaryDataSource) && Ember.isPresent(joinData.secondaryDataSet) && Ember.isPresent(joinData.secondaryDataSetField) && Ember.isPresent(joinData.joinType);
        });
        return !joinsList.length || !allValuesPresent;
      }
    }),
    actions: {
      closeModal: function closeModal() {
        this.closeMergeDataSetsModal();
      },
      save: function save() {
        var joinsList = this.get('joinsList');
        this.onJoinsSettingsChange(joinsList);
      }
    }
  });

  _exports.default = _default;
});