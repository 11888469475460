define("happyfox-bi/apps/details/salesforce/component", ["exports", "happyfox-bi/mixins/manage-apps"], function (_exports, _manageApps) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_manageApps.default, {
    intlService: Ember.inject.service('intl'),
    app: null,
    account: null,
    isSyncSuspended: Ember.computed.equal('account.syncInfo.syncStatus', 'suspended'),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.app && this.app.accounts.length) {
        this.set('account', this.app.accounts[0]);
      }

      this.renderForm(this.getFieldsInfo());
    },
    getFieldsInfo: function getFieldsInfo() {
      var consumerKeyField = Ember.Object.create({
        label: this.intlService.lookup('apps.form-fields.consumer-key'),
        key: 'consumerKey',
        isText: true,
        isMandatory: true
      });
      var consumerSecretField = Ember.Object.create({
        label: this.intlService.lookup('apps.form-fields.consumer-secret'),
        key: 'consumerSecret',
        isPassword: true,
        isMandatory: true
      });
      var sandboxToggleField = Ember.Object.create({
        label: this.intlService.lookup('apps.form-fields.sandbox-account'),
        key: 'isSandbox',
        isToggle: true,
        isMandatory: false
      });

      if (this.account) {
        consumerKeyField.set('value', this.account.consumerKey);
        sandboxToggleField.set('value', this.account.isSandbox);
      }

      return [consumerKeyField, consumerSecretField, sandboxToggleField];
    },
    actions: {
      reset: function reset() {
        var _this = this;

        this.get('fields').forEach(function (field) {
          if (_this.account) {
            field.set('value', _this.account[field.key]);
          } else {
            field.set('value', '');
          }
        });
      },
      reauthorize: function reauthorize() {
        window.location = this.account.redirectUrl;
      },
      saveSettings: function saveSettings() {
        var _this2 = this;

        var application = this.get('app.name');
        this.set('saveInProgress', true);
        var data = {};
        this.get('fields').forEach(function (field) {
          data[field.get('key')] = field.value;
        });
        var requestPromise = null;

        if (this.account) {
          requestPromise = this.requestService.patch("integrations/salesforce/accounts/".concat(this.account.id, "/"), data);
        } else {
          requestPromise = this.requestService.post("integrations/".concat(application, "/accounts/"), data);
        }

        requestPromise.then(function (response) {
          if (response.redirect_url) {
            window.location = response.redirect_url;
          }
        }).catch(function (_ref) {
          var errors = _ref.errors;

          _this2.set('nonFieldErrors', errors.nonFieldErrors);
        }).finally(function () {
          _this2.set('saveInProgress', false);
        });
      }
    }
  });

  _exports.default = _default;
});