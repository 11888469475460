define("happyfox-bi/data-sources/components/create-data-source/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SOURCE_TYPES = [{
    name: 'Custom',
    key: 'custom'
  }];

  var _default = Ember.Component.extend({
    dataService: Ember.inject.service('data'),
    sourceTypeOptions: SOURCE_TYPES,
    name: null,
    sourceType: null,
    createInProgress: false,
    init: function init() {
      this._super.apply(this, arguments);

      var customSource = this.get('sourceTypeOptions').findBy('key', 'custom');
      this.set('sourceType', customSource);
    },
    isCreateDisabled: Ember.computed('name', 'sourceType', {
      get: function get() {
        var name = this.get('name');
        var sourceType = this.get('sourceType');
        return Ember.isEmpty(name) || Ember.isEmpty(sourceType);
      }
    }),
    actions: {
      onSourceTypeChange: function onSourceTypeChange(sourceTypeOption) {
        this.set('sourceType', sourceTypeOption);
      },
      toggleCreateDataSource: function toggleCreateDataSource() {
        this.toggleCreateDataSource();
      },
      createDataSource: function createDataSource() {
        var _this = this;

        if (!this.get('isCreateDisabled')) {
          var name = this.get('name');
          var sourceType = this.get('sourceType').key;
          this.set('createInProgress', true);
          this.get('dataService').createDataSource({
            name: name,
            sourceType: sourceType
          }).then(function () {
            _this.toggleCreateDataSource();

            if (_this.onDataSourceCreation) {
              _this.onDataSourceCreation();
            }
          }).catch(function () {}).finally(function () {
            _this.set('createInProgress', false);
          });
        }
      }
    }
  });

  _exports.default = _default;
});