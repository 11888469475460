define("happyfox-bi/report-subscriptions/components/subscribe-to-report/form-field-model/days-field", ["exports", "happyfox-bi/form-field-models/array-field"], function (_exports, _arrayField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _arrayField.default.extend({
    activeFieldsOfDays: null,
    createChildFields: function createChildFields() {
      this._super.apply(this, arguments);

      var activeFieldsOfDays = Ember.A();
      this.get('childFields').forEach(function (dayField) {
        var activeField = dayField.get('childFields').findBy('key', 'active');
        activeFieldsOfDays.pushObject(activeField);
      });
      this.set('activeFieldsOfDays', activeFieldsOfDays);
      this.validate();
    },
    isAnyDayActive: Ember.computed('activeFieldsOfDays.@each.value', {
      get: function get() {
        return this.get('activeFieldsOfDays').isAny('value');
      }
    }),
    onActiveChangeOfDay: Ember.observer('activeFieldsOfDays.@each.value', function () {
      this.validate();
    }),
    hasValueIfMandatory: Ember.computed('childFields.@each.hasValueIfMandatory', 'isAnyDayActive', {
      get: function get() {
        return this.get('isAnyDayActive') && this.get('childFields').isEvery('hasValueIfMandatory');
      }
    }),
    reset: function reset() {
      this.set('errors', Ember.A());
      this.get('childFields').forEach(function (child) {
        child.reset();
      });
    },
    validate: function validate() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this._super().then(function () {
          if (!_this.get('isAnyDayActive')) {
            _this.addError(_this.get('intlService').lookup('mandatory-running-days-validation'));

            reject();
          }

          resolve();
        }).catch(function () {
          if (!_this.get('isAnyDayActive')) {
            _this.addError(_this.get('intlService').lookup('mandatory-running-days-validation'));
          }

          reject();
        });
      });
    }
  });

  _exports.default = _default;
});