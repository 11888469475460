define("happyfox-bi/components/data-table/cell/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    displayValue: Ember.computed('column.extra.columnField', 'rawValue', {
      get: function get() {
        var extra = this.column.extra,
            rawValue = this.rawValue;
        var isValuePresent = Ember.isPresent(rawValue);

        if (isValuePresent && extra.format) {
          return extra.format(rawValue, extra.columnField);
        } else if (!isValuePresent) {
          return '~';
        }

        return rawValue;
      }
    })
  });

  _exports.default = _default;
});