define("happyfox-bi/components/hf-pop-over", ["exports", "@happyfoxinc/hf-pop-over/components/hf-pop-over"], function (_exports, _hfPopOver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _hfPopOver.default;
    }
  });
});