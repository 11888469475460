define("happyfox-bi/components/base-line-chart/component", ["exports", "happyfox-bi/components/base-chart/component", "@amcharts/amcharts4/core", "@amcharts/amcharts4/charts"], function (_exports, _component, am4core, am4charts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    fillGradient: false,
    createValueLabels: function createValueLabels(series) {
      var valueLabel = series.bullets.push(new am4charts.LabelBullet());
      valueLabel.label.text = '{valueY}';
      valueLabel.label.truncate = false;
      valueLabel.label.dy = -15;
      valueLabel.label.fontSize = 14;
    },
    setAreaConfiguration: function setAreaConfiguration(series) {
      series.fillOpacity = 1;

      if (this.get('fillGradient')) {
        var fillModifier = new am4core.LinearGradientModifier(); // Modifier properties applies on top of the gradient & its fill color

        fillModifier.opacities = [1, 0.2];
        fillModifier.gradient.rotation = 90;
        series.segments.template.fillModifier = fillModifier;
      }
    }
  });

  _exports.default = _default;
});