define("happyfox-bi/components/segmentation-chart/component", ["exports", "happyfox-bi/components/base-chart/component", "@amcharts/amcharts4/core", "@amcharts/amcharts4/charts", "happyfox-bi/constants", "happyfox-bi/utilities/math"], function (_exports, _component, am4core, am4charts, _constants, _math) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TOP_LEFT_COLOR = '#7f66dc';
  var BOTTOM_LEFT_COLOR = '#67b7dc';
  var BOTTOM_RIGHT_COLOR = '#ddaf66';
  var TOP_RIGHT_COLOR = '#dc6688';

  var _default = _component.default.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.createChart();
    },
    // returns { min: 20, max: 40, separationValue: 30 }
    getAxisRangeValues: function getAxisRangeValues(axisKey, segmentationType, segmentationValue) {
      var data = this.get('data');
      var axisValues = data.mapBy(axisKey).compact();
      axisValues.sort(function (a, b) {
        return a - b;
      });
      var separationValue = segmentationValue;

      if (segmentationType === 'average') {
        separationValue = (0, _math.getAverage)(axisValues);
      } else if (segmentationType === 'percentile') {
        var percentileValue = segmentationValue;
        separationValue = (0, _math.getPercentile)(axisValues, percentileValue);
      }

      return {
        separationValue: separationValue,
        min: axisValues[0],
        max: axisValues[axisValues.length - 1]
      };
    },
    createChart: function createChart() {
      var _this = this;

      var chartContainer = this.get('chartContainer');
      var chart = am4core.create(chartContainer, am4charts.XYChart);
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.behavior = 'zoomXY';

      var _this$get = this.get('config'),
          xAxisKey = _this$get.xAxis.key,
          yAxisKey = _this$get.yAxis.key; // Create axes


      var xAxisLabel = this.get('config.xAxis.label');
      var xAxisSegmentationType = this.get('config.xAxis.segmentationType');
      var xAxisSegmentationValue = this.get('config.xAxis.segmentationValue');
      var xAxisDetails = this.getAxisRangeValues(xAxisKey, xAxisSegmentationType, xAxisSegmentationValue);
      var xAxisMin = xAxisDetails.min,
          xAxisMax = xAxisDetails.max,
          xAxisSeparationValue = xAxisDetails.separationValue;
      var isXAxisTimeType = this.isFieldTimeType(xAxisKey);
      var isDurationXAxis = this.canFormatTimeAsDuration(xAxisKey);
      var xAxis = isDurationXAxis ? chart.xAxes.push(new am4charts.DurationAxis()) : chart.xAxes.push(new am4charts.ValueAxis());
      xAxis.min = xAxisMin;
      xAxis.max = xAxisMax;
      xAxis.extraMin = 0.05;
      xAxis.extraMax = 0.05;
      xAxis.keepSelection = true;
      xAxis.renderer.grid.template.above = true;
      xAxis.title.text = xAxisLabel;
      var isXAxisCurrencyType = this.isFieldCurrencyType(xAxisKey);

      if (isXAxisCurrencyType) {
        this.formatAxisLabelsByCurrency(xAxis, xAxisKey);
      }

      if (isDurationXAxis) {
        this.setupDurationFormatter(xAxis, xAxisKey);
      } else if (isXAxisTimeType) {
        this.setUnitsInAxisLabels(xAxis, this.getTimeFieldBaseUnit(xAxisKey));
      }

      var yAxisLabel = this.get('config.yAxis.label');
      var yAxisSegmentationType = this.get('config.yAxis.segmentationType');
      var yAxisSegmentationValue = this.get('config.yAxis.segmentationValue');
      var isYAxisTimeType = this.isFieldTimeType(yAxisKey);
      var isDurationYAxis = this.canFormatTimeAsDuration(yAxisKey);
      var yAxisDetails = this.getAxisRangeValues(yAxisKey, yAxisSegmentationType, yAxisSegmentationValue);
      var yAxisMin = yAxisDetails.min,
          yAxisMax = yAxisDetails.max,
          yAxisSeparationValue = yAxisDetails.separationValue;
      var yAxis = isDurationYAxis ? chart.yAxes.push(new am4charts.DurationAxis()) : chart.yAxes.push(new am4charts.ValueAxis());
      yAxis.min = yAxisMin;
      yAxis.max = yAxisMax;
      yAxis.extraMin = 0.05;
      yAxis.extraMax = 0.05;
      yAxis.keepSelection = true;
      yAxis.renderer.grid.template.above = true;
      yAxis.title.text = yAxisLabel;
      var isYAxisCurrencyType = this.isFieldCurrencyType(yAxisKey);

      if (isYAxisCurrencyType) {
        this.formatAxisLabelsByCurrency(yAxis, yAxisKey);
      }

      if (isDurationYAxis) {
        this.setupDurationFormatter(yAxis, yAxisKey);
      } else if (isYAxisTimeType) {
        this.setUnitsInAxisLabels(yAxis, this.getTimeFieldBaseUnit(yAxisKey));
      }

      chart.events.on('inited', function () {
        // Create color series
        // top left
        var quadrantOneColor = _this.getConfigValue('quadrants.topLeft.color', TOP_LEFT_COLOR);

        var topLeftFillSeries = chart.series.push(new am4charts.LineSeries());
        topLeftFillSeries.dataFields.valueX = 'ax';
        topLeftFillSeries.dataFields.valueY = 'ay';
        topLeftFillSeries.strokeOpacity = 0;
        topLeftFillSeries.fillOpacity = 0.5;
        topLeftFillSeries.ignoreMinMax = true;
        topLeftFillSeries.fill = am4core.color(quadrantOneColor);
        topLeftFillSeries.data = [{
          'ax': xAxis.min,
          'ay': yAxisSeparationValue
        }, {
          'ax': xAxisSeparationValue,
          'ay': yAxisSeparationValue
        }, {
          'ax': xAxisSeparationValue,
          'ay': yAxis.max
        }, {
          'ax': xAxis.min,
          'ay': yAxis.max
        }]; // bottom left

        var quadrantTwoColor = _this.getConfigValue('quadrants.bottomLeft.color', BOTTOM_LEFT_COLOR);

        var bottomLeftFillSeries = chart.series.push(new am4charts.LineSeries());
        bottomLeftFillSeries.dataFields.valueX = 'ax';
        bottomLeftFillSeries.dataFields.valueY = 'ay';
        bottomLeftFillSeries.strokeOpacity = 0;
        bottomLeftFillSeries.ignoreMinMax = true;
        bottomLeftFillSeries.fill = am4core.color(quadrantTwoColor);
        bottomLeftFillSeries.fillOpacity = 0.5;
        bottomLeftFillSeries.data = [{
          'ax': xAxis.min,
          'ay': yAxis.min
        }, {
          'ax': xAxisSeparationValue,
          'ay': yAxis.min
        }, {
          'ax': xAxisSeparationValue,
          'ay': yAxisSeparationValue
        }, {
          'ax': xAxis.min,
          'ay': yAxisSeparationValue
        }]; // bottom right

        var quadrantThreeColor = _this.getConfigValue('quadrants.bottomRight.color', BOTTOM_RIGHT_COLOR);

        var bottomRightFillSeries = chart.series.push(new am4charts.LineSeries());
        bottomRightFillSeries.dataFields.valueX = 'ax';
        bottomRightFillSeries.dataFields.valueY = 'ay';
        bottomRightFillSeries.strokeOpacity = 0;
        bottomRightFillSeries.fill = am4core.color(quadrantThreeColor);
        bottomRightFillSeries.ignoreMinMax = true;
        bottomRightFillSeries.fillOpacity = 0.5;
        bottomRightFillSeries.data = [{
          'ax': xAxisSeparationValue,
          'ay': yAxis.min
        }, {
          'ax': xAxis.max,
          'ay': yAxis.min
        }, {
          'ax': xAxis.max,
          'ay': yAxisSeparationValue
        }, {
          'ax': xAxisSeparationValue,
          'ay': yAxisSeparationValue
        }]; // top right

        var quadrantFourColor = _this.getConfigValue('quadrants.topRight.color', TOP_RIGHT_COLOR);

        var topRightFillSeries = chart.series.push(new am4charts.LineSeries());
        topRightFillSeries.dataFields.valueX = 'ax';
        topRightFillSeries.dataFields.valueY = 'ay';
        topRightFillSeries.strokeOpacity = 0;
        topRightFillSeries.fill = am4core.color(quadrantFourColor);
        topRightFillSeries.ignoreMinMax = true;
        topRightFillSeries.fillOpacity = 0.5;
        topRightFillSeries.data = [{
          'ax': xAxisSeparationValue,
          'ay': yAxisSeparationValue
        }, {
          'ax': xAxis.max,
          'ay': yAxisSeparationValue
        }, {
          'ax': xAxis.max,
          'ay': yAxis.max
        }, {
          'ax': xAxisSeparationValue,
          'ay': yAxis.max
        }];
      }); // bubble series

      var categoryKey = this.get('config.category.key');
      var categoryLabel = this.get('config.category.label');
      var series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueX = xAxisKey;
      series.dataFields.valueY = yAxisKey;
      series.strokeOpacity = 0;
      var bullet = series.bullets.push(new am4core.Circle());
      bullet.fill = am4core.color('#000000');
      bullet.strokeOpacity = 0;
      bullet.strokeWidth = 2;
      bullet.fillOpacity = 0.5;
      bullet.stroke = am4core.color('#ffffff');
      bullet.hiddenState.properties.opacity = 0;
      bullet.radius = 5;
      bullet.tooltipText = "".concat(categoryLabel, ": {").concat(categoryKey, "}\n").concat(xAxisLabel, ": {valueX.value}\n").concat(yAxisLabel, ": {valueY.value}");
      bullet.adapter.add('tooltipText', function (text, target) {
        var tooltipDataItem = target.tooltipDataItem;
        var xAxisValue, yAxisValue;

        if (tooltipDataItem) {
          if (isXAxisCurrencyType) {
            var currencyField = _this.get('widgetModel.settings.fields').findBy('name', xAxisKey);

            xAxisValue = _this.formatValueByCurrencyField(tooltipDataItem.valueX, currencyField);
          } else if (isXAxisTimeType) {
            xAxisValue = _this.formatValueByTime(tooltipDataItem.valueX, xAxisKey);
          } else {
            xAxisValue = tooltipDataItem.valueX;
          }

          if (isYAxisCurrencyType) {
            var _currencyField = _this.get('widgetModel.settings.fields').findBy('name', yAxisKey);

            yAxisValue = _this.formatValueByCurrencyField(tooltipDataItem.valueY, _currencyField);
          } else if (isYAxisTimeType) {
            yAxisValue = _this.formatValueByTime(tooltipDataItem.valueY, yAxisKey);
          } else {
            yAxisValue = tooltipDataItem.valueY;
          }

          return "".concat(categoryLabel, ": {").concat(categoryKey, "}\n").concat(xAxisLabel, ": ").concat(xAxisValue, "\n").concat(yAxisLabel, ": ").concat(yAxisValue);
        }

        return text;
      });
      var valueLabel = series.bullets.push(new am4charts.LabelBullet());
      valueLabel.label.text = "{".concat(categoryKey, "}");
      valueLabel.label.horizontalCenter = 'left';
      valueLabel.label.dx = 10;
      valueLabel.label.hideOversized = false;
      valueLabel.label.truncate = false;
      series.data = this.get('data');
      var isLegendHidden = this.getConfigValue('hideLegend', false);

      if (!isLegendHidden) {
        this.createLegend(chart);
      }

      this.addExportOption(chart);
      this.addAnnotationsOption(chart);
      chart.align = 'center';
      chart.valign = 'middle';
      this.chart = chart;
      return chart;
    },
    replaceNullInRowsAndColumns: function replaceNullInRowsAndColumns() {
      var categoryKey = this.get('config.category.key');
      var excludeNullValues = this.get('config.excludeNullValues');
      var processedData = [];
      this.get('data').forEach(function (item) {
        if (item[categoryKey] === null) {
          if (!excludeNullValues) {
            item[categoryKey] = _constants.NO_VALUE_SET;
            processedData.push(item);
          }
        } else {
          processedData.push(item);
        }
      });
      this.set('data', processedData);
    },
    createLegend: function createLegend(chart) {
      var legend = new am4charts.Legend();
      legend.parent = chart.chartContainer;
      legend.data = [{
        'name': this.getConfigValue('quadrants.bottomLeft.name', 'Bottom Left'),
        'fill': this.getConfigValue('quadrants.bottomLeft.color', BOTTOM_LEFT_COLOR)
      }, {
        'name': this.getConfigValue('quadrants.topLeft.name', 'Top Left'),
        'fill': this.getConfigValue('quadrants.topLeft.color', TOP_LEFT_COLOR)
      }, {
        'name': this.getConfigValue('quadrants.bottomRight.name', 'Bottom Right'),
        'fill': this.getConfigValue('quadrants.bottomRight.color', BOTTOM_RIGHT_COLOR)
      }, {
        'name': this.getConfigValue('quadrants.topRight.name', 'Top Right'),
        'fill': this.getConfigValue('quadrants.topRight.color', TOP_RIGHT_COLOR)
      }];
      legend.itemContainers.template.clickable = false;
      legend.itemContainers.template.focusable = false;
      var markerTemplate = legend.markers.template;
      markerTemplate.fillOpacity = 0.5;
    },

    /* 
      This method, `formatAxisLabelsByCurrency`, exists for a special case involving charts with multiple value axes.
      
      Case:
      ------
      In general, the numberFormatter (whether it is currency or any other number formatting) gets applied on the root level value axis element from which each value axis is created.
      Different number formatters (currency, %, rounding-off, etc.) on different value axes is not possible since each number formatter applied on a value axis will override the formatter of root axis element.
      Due to this, all value axes will now use the (same)formatter from the last axis. Read more -> https://github.com/amcharts/amcharts4/issues/221
       Solution:
      ---------
      Manually format axis elements like labels and axis tooltip text to the format you want 
      instead of `setElementNumberFormatAsCurrency` which does this automatically for charts with single value axis.
    */
    formatAxisLabelsByCurrency: function formatAxisLabelsByCurrency(axis, currencyFieldKey) {
      var _this2 = this;

      var currencyField = this.get('widgetModel.settings.fields').findBy('name', currencyFieldKey);
      axis.renderer.labels.template.adapter.add("text", function (label, target) {
        var dataItem = target.dataItem;

        if (dataItem) {
          var dataValue = dataItem.value;

          var formattedLabel = _this2.formatValueByCurrencyField(dataValue, currencyField);

          return "".concat(formattedLabel);
        }

        return label;
      });
      axis.adapter.add("getTooltipText", function (text) {
        return "".concat(currencyField.currencySign).concat(text);
      });
    }
  });

  _exports.default = _default;
});