define("happyfox-bi/components/gantt-chart/component", ["exports", "happyfox-bi/components/base-chart/component", "@amcharts/amcharts4/charts", "@amcharts/amcharts4/core", "happyfox-bi/constants"], function (_exports, _component, am4charts, am4core, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MAX_LIGHTEN_VALUE = 0.8;
  var LIGHTEN_STEP_VALUE = 0.2;

  var _default = _component.default.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.createChart();
    },
    createChart: function createChart() {
      var _this = this;

      var chartContainer = this.get('chartContainer');
      var chart = am4core.create(chartContainer, am4charts.XYChart);
      var data = this.getTransformedData(this.data);

      var _this$get = this.get('config'),
          category = _this$get.category,
          task = _this$get.task,
          start = _this$get.start,
          end = _this$get.end;

      var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = category;
      categoryAxis.renderer.grid.template.location = 0; //Date axis handling

      var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      chart.data = this.convertAndGetDateXAxisData(data);
      this.prepareDateAxis(dateAxis);
      var columnSeries = chart.series.push(new am4charts.ColumnSeries());
      columnSeries.columns.template.height = am4core.percent(70);
      var axisFormat = this.getXAxisDateFormat(dateAxis);
      columnSeries.columns.template.tooltipText = "[bold]{task}: [bold]{openDateX.formatDate('".concat(axisFormat, "')}[/] - [bold]{dateX.formatDate('").concat(axisFormat, "')}[/]");
      columnSeries.dataFields.openDateX = start;
      columnSeries.dataFields.dateX = end;
      columnSeries.dataFields.categoryY = category;
      columnSeries.dataFields.task = task;
      chart.events.on('inited', function () {
        var colorSet = new am4core.ColorSet();
        var categoriesList = _this.config.categoriesList; // Since there is a single series, we need to color task bars of each category

        categoriesList.forEach(function (category) {
          var categoryDataItem = categoryAxis.dataItemsByCategory.getKey(category);
          var seriesId = columnSeries.dataItem.component.uid; // categoryAxisDataItem has seriesItems info as {series-uid: seriesItems}

          var seriesDataItems = categoryDataItem.seriesDataItems[seriesId];
          var categoryColor = colorSet.next();
          var lightenValue = 0;
          seriesDataItems.forEach(function (item) {
            // lighten method provides different shades of color 
            // This is for each tasks under a category
            item.column.fill = categoryColor.lighten(lightenValue);
            item.column.stroke = categoryColor.lighten(lightenValue);

            if (lightenValue < MAX_LIGHTEN_VALUE) {
              lightenValue += LIGHTEN_STEP_VALUE;
            } else {
              lightenValue = 0;
            }
          });
        });
      });
      this.addExportOption(chart);
      this.addAnnotationsOption(chart);
      chart.align = 'center';
      chart.valign = 'middle';
      this.chart = chart;
    },
    convertAndGetDateXAxisData: function convertAndGetDateXAxisData(data) {
      var _this$get2 = this.get('config'),
          start = _this$get2.start,
          end = _this$get2.end;

      data.forEach(function (item) {
        var startDateString = item[start];
        var endDateString = item[end];

        if (startDateString) {
          item.hfFeDateinUserTz = moment(startDateString); // Moment has user timezone set. So this moment object will be the right timestamp

          item[startDateString] = new Date(startDateString); // This date object is not right, but needed for display purposes in UI.
        }

        if (endDateString) {
          item.hfFeDateinUserTz = moment(endDateString);
          item[endDateString] = new Date(endDateString);
        }
      });
      return data;
    },
    updateConfig: function updateConfig() {
      var config = Object.assign({}, this.get('config'));
      var category = config.category;
      var categoriesList = Ember.A();
      this.get('data').forEach(function (datum) {
        var columnValue = datum[category];

        if (Ember.isPresent(columnValue) && typeof columnValue === 'boolean') {
          columnValue = columnValue.toString();
        }

        categoriesList.addObject(columnValue);
      });
      config.categoriesList = categoriesList;
      this.set('config', config);
    },
    getTransformedData: function getTransformedData(data) {
      var transformedData = Ember.A();
      var _this$config = this.config,
          start = _this$config.start,
          end = _this$config.end;
      data.forEach(function (datum) {
        if (Ember.isPresent(datum[start]) && Ember.isPresent(datum[end])) {
          transformedData.pushObject(datum);
        }
      });
      return transformedData;
    },
    replaceNullInRowsAndColumns: function replaceNullInRowsAndColumns() {
      var category = this.config.category;
      var excludeNullValues = this.get('config.excludeNullValues');
      var processedData = [];
      this.get('data').forEach(function (item) {
        if (item[category] === null) {
          if (!excludeNullValues) {
            item[category] = _constants.NO_VALUE_SET;
            processedData.push(item);
          }
        } else {
          processedData.push(item);
        }
      });
      this.set('data', processedData);
    },
    sortDataByDateAndTimestampFields: function sortDataByDateAndTimestampFields(data, fields) {
      var startFieldName = this.get('config.start');
      var startField = fields.findBy('name', startFieldName); // Modifying date and timestamp Fields to contain `start` for reusing `sortDataByDateAndTimestampFields` from base chart

      if (startField) {
        fields = Ember.A().addObject(startField);

        this._super(data, fields);
      } else {
        var visualization = this.get('widgetModel');
        var visualizationFields = visualization.get('settings.fields');
        startField = visualizationFields.findBy('name', startFieldName);

        if (startField && startField.dataType == 'date') {
          data.sort(function (a, b) {
            return moment(a[startFieldName]) - moment(b[startFieldName]);
          });
        }
      }
    }
  });

  _exports.default = _default;
});