define("happyfox-bi/components/date-range-picker/component", ["exports", "@happyfoxinc/hf-date-range-picker/components/hf-date-range-picker", "happyfox-bi/constants", "happyfox-bi/reports/details/pages/details/utilities"], function (_exports, _hfDateRangePicker, _constants, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EXCLUDE_TODAY_FROM_DATE_RANGE = false;

  var _default = _hfDateRangePicker.default.extend({
    intlService: Ember.inject.service('intl'),
    format: _constants.INPUT_DATE_FORMAT,
    apiDateFormat: _constants.API_DATE_FORMAT,
    defaultDateFormat: _constants.DEFAULT_DATE_FORMAT,
    defaultDateRanges: (0, _utilities.getDateRanges)({
      excludeToday: EXCLUDE_TODAY_FROM_DATE_RANGE
    }),
    dateRangesList: null,
    classNames: 'u-position-relative',
    init: function init() {
      this._super.apply(this, arguments);

      this.setDateRangesList();
    },
    setDateRangesList: function setDateRangesList() {
      this.set('dateRangesList', this.formatDateRanges(this.dateRangesList));
    },
    formatDateRanges: function formatDateRanges(dateRanges) {
      var _this = this;

      return dateRanges.map(function (range) {
        return {
          id: range.id,
          value: range.value,
          label: _this.intlService.lookup(range.label)
        };
      });
    }
  });

  _exports.default = _default;
});