define("happyfox-bi/visualization-examples/mock-data/map-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.REGION_LEVEL_DATA = _exports.COUNTRY_LEVEL_DATA = void 0;
  var COUNTRY_LEVEL_DATA = [{
    "country": "Tuvalu",
    "value": 888
  }, {
    "country": "Bouvet Island",
    "value": 229
  }, {
    "country": "Gibraltar",
    "value": 954
  }, {
    "country": "Glorioso Islands",
    "value": 90
  }, {
    "country": "Juan De Nova Island",
    "value": 873
  }, {
    "country": "Jarvis Island",
    "value": 44
  }, {
    "country": "Baker Island",
    "value": 332
  }, {
    "country": "Howland Island",
    "value": 939
  }, {
    "country": "Johnston Atoll",
    "value": 330
  }, {
    "country": "Midway Islands",
    "value": 803
  }, {
    "country": "Wake Island",
    "value": 364
  }, {
    "country": "Bonair, Saint Eustachius and Saba",
    "value": 99
  }, {
    "country": "Netherlands",
    "value": 926
  }, {
    "country": "Zimbabwe",
    "value": 29
  }, {
    "country": "Zambia",
    "value": 658
  }, {
    "country": "South Africa",
    "value": 795
  }, {
    "country": "Yemen",
    "value": 758
  }, {
    "country": "Samoa",
    "value": 252
  }, {
    "country": "Wallis and Futuna",
    "value": 286
  }, {
    "country": "Palestinian Territories",
    "value": 548
  }, {
    "country": "Vanuatu",
    "value": 212
  }, {
    "country": "Vietnam",
    "value": 543
  }, {
    "country": "US Virgin Islands",
    "value": 110
  }, {
    "country": "British Virgin Islands",
    "value": 187
  }, {
    "country": "Venezuela",
    "value": 813
  }, {
    "country": "Saint Vincent and the Grenadines",
    "value": 885
  }, {
    "country": "Vatican City",
    "value": 54
  }, {
    "country": "Uzbekistan",
    "value": 820
  }, {
    "country": "United States",
    "value": 73
  }, {
    "country": "Uruguay",
    "value": 68
  }, {
    "country": "Ukraine",
    "value": 711
  }, {
    "country": "Uganda",
    "value": 932
  }, {
    "country": "Tanzania",
    "value": 227
  }, {
    "country": "Taiwan",
    "value": 384
  }, {
    "country": "Turkey",
    "value": 23
  }, {
    "country": "Tunisia",
    "value": 299
  }, {
    "country": "Trinidad and Tobago",
    "value": 702
  }, {
    "country": "Tonga",
    "value": 480
  }, {
    "country": "Timor-Leste",
    "value": 306
  }, {
    "country": "Turkmenistan",
    "value": 444
  }, {
    "country": "Tokelau",
    "value": 540
  }, {
    "country": "Tajikistan",
    "value": 416
  }, {
    "country": "Thailand",
    "value": 573
  }, {
    "country": "Togo",
    "value": 710
  }, {
    "country": "Chad",
    "value": 524
  }, {
    "country": "Turks and Caicos Islands",
    "value": 760
  }, {
    "country": "Syria",
    "value": 770
  }, {
    "country": "Seychelles",
    "value": 811
  }, {
    "country": "Sint Maarten",
    "value": 674
  }, {
    "country": "eSwatini",
    "value": 198
  }, {
    "country": "Sweden",
    "value": 381
  }, {
    "country": "Slovenia",
    "value": 11
  }, {
    "country": "Slovakia",
    "value": 194
  }, {
    "country": "Suriname",
    "value": 61
  }, {
    "country": "Sao Tome and Principe",
    "value": 728
  }, {
    "country": "Serbia",
    "value": 589
  }, {
    "country": "Saint Pierre and Miquelon",
    "value": 581
  }, {
    "country": "Somalia",
    "value": 436
  }, {
    "country": "San Marino",
    "value": 522
  }, {
    "country": "El Salvador",
    "value": 144
  }, {
    "country": "Sierra Leone",
    "value": 288
  }, {
    "country": "Solomon Islands",
    "value": 921
  }, {
    "country": "Saint Helena",
    "value": 554
  }, {
    "country": "South Georgia and South Sandwich Islands",
    "value": 527
  }, {
    "country": "Singapore",
    "value": 122
  }, {
    "country": "Senegal",
    "value": 166
  }, {
    "country": "South Sudan",
    "value": 721
  }, {
    "country": "Sudan",
    "value": 324
  }, {
    "country": "Saudi Arabia",
    "value": 539
  }, {
    "country": "Western Sahara",
    "value": 116
  }, {
    "country": "Rwanda",
    "value": 673
  }, {
    "country": "Russia",
    "value": 75
  }, {
    "country": "Romania",
    "value": 37
  }, {
    "country": "Reunion",
    "value": 126
  }, {
    "country": "Qatar",
    "value": 472
  }, {
    "country": "French Polynesia",
    "value": 100
  }, {
    "country": "Paraguay",
    "value": 214
  }, {
    "country": "Portugal",
    "value": 212
  }, {
    "country": "North Korea",
    "value": 155
  }, {
    "country": "Puerto Rico",
    "value": 244
  }, {
    "country": "Poland",
    "value": 358
  }, {
    "country": "Papua New Guinea",
    "value": 618
  }, {
    "country": "Palau",
    "value": 885
  }, {
    "country": "Philippines",
    "value": 751
  }, {
    "country": "Peru",
    "value": 48
  }, {
    "country": "Pitcairn Islands",
    "value": 46
  }, {
    "country": "Panama",
    "value": 80
  }, {
    "country": "Pakistan",
    "value": 358
  }, {
    "country": "Oman",
    "value": 356
  }, {
    "country": "New Zealand",
    "value": 167
  }, {
    "country": "Svalbard and Jan Mayen",
    "value": 4
  }, {
    "country": "Nauru",
    "value": 92
  }, {
    "country": "Nepal",
    "value": 980
  }, {
    "country": "Norway",
    "value": 905
  }, {
    "country": "Niue",
    "value": 732
  }, {
    "country": "Nicaragua",
    "value": 126
  }, {
    "country": "Nigeria",
    "value": 198
  }, {
    "country": "Norfolk Island",
    "value": 664
  }, {
    "country": "Niger",
    "value": 670
  }, {
    "country": "New Caledonia",
    "value": 604
  }, {
    "country": "Namibia",
    "value": 360
  }, {
    "country": "Mayotte",
    "value": 553
  }, {
    "country": "Malaysia",
    "value": 76
  }, {
    "country": "Malawi",
    "value": 897
  }, {
    "country": "Mauritius",
    "value": 124
  }, {
    "country": "Martinique",
    "value": 283
  }, {
    "country": "Montserrat",
    "value": 168
  }, {
    "country": "Mauritania",
    "value": 236
  }, {
    "country": "Mozambique",
    "value": 605
  }, {
    "country": "Northern Mariana Islands",
    "value": 880
  }, {
    "country": "Mongolia",
    "value": 440
  }, {
    "country": "Montenegro",
    "value": 94
  }, {
    "country": "Myanmar",
    "value": 435
  }, {
    "country": "Malta",
    "value": 781
  }, {
    "country": "Mali",
    "value": 625
  }, {
    "country": "Macedonia",
    "value": 124
  }, {
    "country": "Marshall Islands",
    "value": 166
  }, {
    "country": "Mexico",
    "value": 442
  }, {
    "country": "Maldives",
    "value": 829
  }, {
    "country": "Madagascar",
    "value": 393
  }, {
    "country": "Moldova",
    "value": 973
  }, {
    "country": "Monaco",
    "value": 59
  }, {
    "country": "Morocco",
    "value": 896
  }, {
    "country": "Saint Martin",
    "value": 739
  }, {
    "country": "Macau",
    "value": 941
  }, {
    "country": "Latvia",
    "value": 484
  }, {
    "country": "Luxembourg",
    "value": 952
  }, {
    "country": "Lithuania",
    "value": 968
  }, {
    "country": "Lesotho",
    "value": 950
  }, {
    "country": "Sri Lanka",
    "value": 30
  }, {
    "country": "Liechtenstein",
    "value": 736
  }, {
    "country": "Saint Lucia",
    "value": 241
  }, {
    "country": "Libya",
    "value": 70
  }, {
    "country": "Liberia",
    "value": 275
  }, {
    "country": "Lebanon",
    "value": 279
  }, {
    "country": "Lao People's Democratic Republic",
    "value": 779
  }, {
    "country": "Kuwait",
    "value": 743
  }, {
    "country": "Kosovo",
    "value": 522
  }, {
    "country": "South Korea",
    "value": 908
  }, {
    "country": "Saint Kitts and Nevis",
    "value": 326
  }, {
    "country": "Kiribati",
    "value": 568
  }, {
    "country": "Cambodia",
    "value": 905
  }, {
    "country": "Kyrgyzstan",
    "value": 250
  }, {
    "country": "Kenya",
    "value": 47
  }, {
    "country": "Kazakhstan",
    "value": 94
  }, {
    "country": "Japan",
    "value": 390
  }, {
    "country": "Jordan",
    "value": 194
  }, {
    "country": "Jersey",
    "value": 223
  }, {
    "country": "Jamaica",
    "value": 563
  }, {
    "country": "Italy",
    "value": 535
  }, {
    "country": "Israel",
    "value": 75
  }, {
    "country": "Iceland",
    "value": 284
  }, {
    "country": "Iraq",
    "value": 541
  }, {
    "country": "Iran",
    "value": 454
  }, {
    "country": "Ireland",
    "value": 513
  }, {
    "country": "British Indian Ocean Territory",
    "value": 604
  }, {
    "country": "India",
    "value": 401
  }, {
    "country": "Isle of Man",
    "value": 834
  }, {
    "country": "Indonesia",
    "value": 772
  }, {
    "country": "Hungary",
    "value": 669
  }, {
    "country": "Haiti",
    "value": 648
  }, {
    "country": "Croatia",
    "value": 830
  }, {
    "country": "Honduras",
    "value": 599
  }, {
    "country": "Heard Island and McDonald Islands",
    "value": 113
  }, {
    "country": "Hong Kong",
    "value": 702
  }, {
    "country": "Guyana",
    "value": 979
  }, {
    "country": "Guam",
    "value": 585
  }, {
    "country": "French Guiana",
    "value": 847
  }, {
    "country": "Guatemala",
    "value": 235
  }, {
    "country": "Greenland",
    "value": 512
  }, {
    "country": "Grenada",
    "value": 492
  }, {
    "country": "Greece",
    "value": 636
  }, {
    "country": "Equatorial Guinea",
    "value": 35
  }, {
    "country": "Guinea-Bissau",
    "value": 389
  }, {
    "country": "Gambia",
    "value": 617
  }, {
    "country": "Guadeloupe",
    "value": 804
  }, {
    "country": "Guinea",
    "value": 803
  }, {
    "country": "Ghana",
    "value": 609
  }, {
    "country": "Guernsey",
    "value": 522
  }, {
    "country": "Georgia",
    "value": 397
  }, {
    "country": "Gabon",
    "value": 52
  }, {
    "country": "France",
    "value": 714
  }, {
    "country": "Federated States of Micronesia",
    "value": 255
  }, {
    "country": "Faroe Islands",
    "value": 885
  }, {
    "country": "Falkland Islands",
    "value": 679
  }, {
    "country": "Fiji",
    "value": 237
  }, {
    "country": "Finland",
    "value": 474
  }, {
    "country": "Ethiopia",
    "value": 556
  }, {
    "country": "Estonia",
    "value": 691
  }, {
    "country": "Spain",
    "value": 265
  }, {
    "country": "Eritrea",
    "value": 619
  }, {
    "country": "United Kingdom",
    "value": 562
  }, {
    "country": "Egypt",
    "value": 143
  }, {
    "country": "Ecuador",
    "value": 717
  }, {
    "country": "Algeria",
    "value": 940
  }, {
    "country": "Dominican Republic",
    "value": 187
  }, {
    "country": "Denmark",
    "value": 11
  }, {
    "country": "Dominica",
    "value": 188
  }, {
    "country": "Djibouti",
    "value": 286
  }, {
    "country": "Germany",
    "value": 581
  }, {
    "country": "Czechia",
    "value": 89
  }, {
    "country": "Cyprus",
    "value": 780
  }, {
    "country": "Cayman Islands",
    "value": 761
  }, {
    "country": "Christmas Island",
    "value": 533
  }, {
    "country": "Curaçao",
    "value": 156
  }, {
    "country": "Cuba",
    "value": 449
  }, {
    "country": "Costa Rica",
    "value": 876
  }, {
    "country": "Cape Verde",
    "value": 38
  }, {
    "country": "Comoros",
    "value": 34
  }, {
    "country": "Colombia",
    "value": 114
  }, {
    "country": "Cook Islands",
    "value": 279
  }, {
    "country": "Republic of Congo",
    "value": 140
  }, {
    "country": "Democratic Republic of Congo",
    "value": 343
  }, {
    "country": "Cameroon",
    "value": 303
  }, {
    "country": "Côte d'Ivoire",
    "value": 87
  }, {
    "country": "China",
    "value": 294
  }, {
    "country": "Chile",
    "value": 367
  }, {
    "country": "Switzerland",
    "value": 195
  }, {
    "country": "Cocos (Keeling) Islands",
    "value": 841
  }, {
    "country": "Canada",
    "value": 610
  }, {
    "country": "Central African Republic",
    "value": 918
  }, {
    "country": "Belgium",
    "value": 237
  }, {
    "country": "Botswana",
    "value": 328
  }, {
    "country": "Bhutan",
    "value": 546
  }, {
    "country": "Brunei",
    "value": 49
  }, {
    "country": "Barbados",
    "value": 612
  }, {
    "country": "Brazil",
    "value": 574
  }, {
    "country": "Bolivia",
    "value": 306
  }, {
    "country": "Bermuda",
    "value": 186
  }, {
    "country": "Belize",
    "value": 188
  }, {
    "country": "Belarus",
    "value": 917
  }, {
    "country": "Saint Barthelemy",
    "value": 556
  }, {
    "country": "Bahamas",
    "value": 888
  }, {
    "country": "Bahrain",
    "value": 996
  }, {
    "country": "Bosnia and Herzegovina",
    "value": 908
  }, {
    "country": "Bulgaria",
    "value": 104
  }, {
    "country": "Bangladesh",
    "value": 491
  }, {
    "country": "Burkina Faso",
    "value": 472
  }, {
    "country": "Benin",
    "value": 825
  }, {
    "country": "Burundi",
    "value": 991
  }, {
    "country": "Azerbaijan",
    "value": 421
  }, {
    "country": "Austria",
    "value": 96
  }, {
    "country": "Australia",
    "value": 635
  }, {
    "country": "French Southern and Antarctic Lands",
    "value": 339
  }, {
    "country": "Antarctica",
    "value": 142
  }, {
    "country": "American Samoa",
    "value": 610
  }, {
    "country": "Armenia",
    "value": 424
  }, {
    "country": "Argentina",
    "value": 346
  }, {
    "country": "United Arab Emirates",
    "value": 104
  }, {
    "country": "Andorra",
    "value": 855
  }, {
    "country": "Aland Islands",
    "value": 910
  }, {
    "country": "Albania",
    "value": 195
  }, {
    "country": "Anguilla",
    "value": 858
  }, {
    "country": "Angola",
    "value": 491
  }, {
    "country": "Afghanistan",
    "value": 360
  }, {
    "country": "Antigua and Barbuda",
    "value": 528
  }, {
    "country": "Aruba",
    "value": 51
  }];
  _exports.COUNTRY_LEVEL_DATA = COUNTRY_LEVEL_DATA;
  var REGION_LEVEL_DATA = [{
    "region": "Europe",
    "value": 435
  }, {
    "region": "Middle East",
    "value": 213
  }, {
    "region": "South America",
    "value": 546
  }, {
    "region": "Oceania",
    "value": 11
  }, {
    "region": "North America",
    "value": 876
  }, {
    "region": "Asia",
    "value": 342
  }, {
    "region": "Caribbean",
    "value": 435
  }, {
    "region": "Greenland",
    "value": 347
  }, {
    "region": "Central America",
    "value": 763
  }, {
    "region": "Africa",
    "value": 634
  }];
  _exports.REGION_LEVEL_DATA = REGION_LEVEL_DATA;
});