define("happyfox-bi/helpers/concat-strings", ["exports", "happyfox-bi/utilities/string"], function (_exports, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (params) {
    var nonEmptyStrings = params.filter(function (param) {
      return !Ember.isBlank(param); // removes "", null, undefined
    });
    return (0, _string.getSafeHtml)(nonEmptyStrings.join(' '));
  });

  _exports.default = _default;
});