define("happyfox-bi/visualizations/new/components/display-settings/component", ["exports", "happyfox-bi/visualizations/constant"], function (_exports, _constant) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    visualizationDisplaySettings: null,
    displayType: null,
    rows: null,
    columns: null,
    values: null,
    pivotTableIncludePercentageOption: null,
    isChartAxisType: Ember.computed('displayType', {
      get: function get() {
        var displayType = this.get('displayType.id');
        return Ember.isPresent(displayType) && _constant.WIDGETS_WITH_AXIS.includes(displayType);
      }
    }),
    canConfigureLegend: Ember.computed('displayType', {
      get: function get() {
        var displayType = this.get('displayType.id');
        return Ember.isPresent(displayType) && _constant.WIDGETS_WITH_LEGENDS.includes(displayType);
      }
    }),
    canConfigureValueLabels: Ember.computed('displayType', {
      get: function get() {
        var displayType = this.get('displayType.id');
        return Ember.isPresent(displayType) && _constant.WIDGETS_WITH_VALUE_LABELS.includes(displayType);
      }
    }),
    displaySettingsComponent: Ember.computed('displayType', {
      get: function get() {
        var displayType = this.get('displayType.id');

        if (Ember.isPresent(displayType)) {
          return "visualizations/new/components/display-settings/".concat(displayType.dasherize());
        }
      }
    }),
    isChartLineSeries: Ember.computed('displayType', {
      get: function get() {
        var displayType = this.get('displayType.id');

        if (Ember.isPresent(displayType)) {
          return _constant.WIDGETS_WITH_LINE_SERIES.includes(displayType);
        }
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.setDisplaySettingsCopy();
    },
    setDisplaySettingsCopy: function setDisplaySettingsCopy() {
      this.set('displaySettingsCopy', _.cloneDeep(this.get('visualizationDisplaySettings')));
    },
    actions: {
      onUpdateDisplaySettings: function onUpdateDisplaySettings() {
        var hasDisplaySettingsChanged = !_.isEqual(this.get('displaySettingsCopy'), this.get('visualizationDisplaySettings'));

        if (hasDisplaySettingsChanged) {
          this.setDisplaySettingsCopy();
          this.onPreviewUpdate();
        }
      },
      // FIXME: Currently, 'includePercentages' has different formats in displaySettings and  query payloads
      // Hence needed separate action to handle it
      onUpdatePivotTableIncludePercentage: function onUpdatePivotTableIncludePercentage(percentageOption) {
        this.onUpdatePivotTableIncludePercentage(percentageOption);
        this.onPreviewUpdate();
      }
    }
  });

  _exports.default = _default;
});