define("happyfox-bi/page-screenshot/grid-layout/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bgiIGZG9",
    "block": "{\"symbols\":[\"visualization\"],\"statements\":[[6,[37,11],null,[[\"id\",\"class\",\"options\"],[\"page-visualizations-grid\",\"mod-16-columns\",[30,[36,10],null,[[\"animate\",\"minWidth\",\"cellHeight\",\"width\",\"staticGrid\",\"handleClass\",\"acceptWidgets\"],[true,960,40,16,[35,9],\"widget-tile_header\",true]]]]],[[\"default\"],[{\"statements\":[[6,[37,8],[[30,[36,7],[[30,[36,7],[[35,6]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,5],null,[[\"id\",\"options\"],[[32,1,[\"id\"]],[30,[36,4],[[35,3],[32,1,[\"id\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,2],null,[[\"class\",\"widgetModel\",\"visualizationsToResize\",\"getVisualizationData\",\"onDataLoad\"],[\"mod-absolute\",[32,1],[35,1],[30,[36,0],[[32,0],\"getVisualizationData\"],null],[30,[36,0],[[32,0],\"onDataLoad\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"visualizationsToResize\",\"page-screenshot/widget-tile\",\"visualizationsPositions\",\"get\",\"grid-item\",\"visualizations\",\"-track-array\",\"each\",\"isStaticGrid\",\"hash\",\"grid-stack\"]}",
    "meta": {
      "moduleName": "happyfox-bi/page-screenshot/grid-layout/template.hbs"
    }
  });

  _exports.default = _default;
});