define("happyfox-bi/visualizations/new/components/display-settings/funnel-chart/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gu45bLSQ",
    "block": "{\"symbols\":[\"type\",\"type\"],\"statements\":[[19,\"visualizations/new/components/display-settings/partials/legend\",[]],[2,\"\\n\"],[19,\"visualizations/new/components/display-settings/partials/labels\",[]],[2,\"\\n\"],[10,\"div\"],[14,0,\"field\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"field_label\"],[12],[1,[30,[36,0],[\"value-format\"],null]],[13],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n\"],[6,[37,4],null,[[\"tagName\",\"class\",\"dropdownClass\",\"options\",\"selected\",\"onChange\",\"searchEnabled\",\"searchField\",\"renderInPlace\"],[\"div\",\"mod-dropdown mod-local-autocomplete field_value\",\"mod-push-dropdown-up\",[35,3],[35,2],[30,[36,1],[[32,0],\"onValueFormatChange\"],null],true,\"name\",true]],[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[32,2,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"field\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"field_label\"],[12],[1,[30,[36,0],[\"orientation\"],null]],[13],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n\"],[6,[37,4],null,[[\"tagName\",\"class\",\"dropdownClass\",\"options\",\"selected\",\"onChange\",\"searchEnabled\",\"searchField\",\"renderInPlace\"],[\"div\",\"mod-dropdown mod-local-autocomplete field_value\",\"mod-push-dropdown-up\",[35,6],[35,5],[30,[36,1],[[32,0],\"onOrientationChange\"],null],true,\"name\",true]],[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[32,1,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":true,\"upvars\":[\"t\",\"action\",\"selectedValueFormat\",\"valueFormatOptions\",\"power-select\",\"selectedOrientation\",\"orientationOptions\"]}",
    "meta": {
      "moduleName": "happyfox-bi/visualizations/new/components/display-settings/funnel-chart/template.hbs"
    }
  });

  _exports.default = _default;
});