define("happyfox-bi/manage/reports/clients-access-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3HJCKr1O",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"clients\",\"length\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"data-table-new/table-pop-over\",[],[[\"@values\"],[[32,0,[\"clients\"]]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  ~\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "happyfox-bi/manage/reports/clients-access-cell/template.hbs"
    }
  });

  _exports.default = _default;
});