define("happyfox-bi/components/toggle-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mgQd9iUD",
    "block": "{\"symbols\":[],\"statements\":[[10,\"label\"],[15,0,[31,[\"toggle_button \",[30,[36,1],[[35,2],\"is-checked\"],null],\" \",[30,[36,1],[[35,0],\"is-disabled\"],null]]]],[15,\"aria-label\",[34,3]],[12],[2,\"\\n  \"],[1,[30,[36,5],null,[[\"type\",\"class\",\"checked\",\"change\"],[\"checkbox\",\"toggle_checkbox\",[35,2],[30,[36,4],[[32,0],\"onChange\"],[[\"value\"],[\"target.checked\"]]]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"isDisabled\",\"if\",\"isChecked\",\"aria-label\",\"action\",\"input\"]}",
    "meta": {
      "moduleName": "happyfox-bi/components/toggle-input/template.hbs"
    }
  });

  _exports.default = _default;
});