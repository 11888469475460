define("happyfox-bi/visualizations/components/sort-options/option/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SORT_ORDER_OPTIONS = ['ascending', 'descending'];

  var _default = Ember.Component.extend({
    classNames: ['sort-options_option'],
    sortOption: null,
    sortOrderOptions: SORT_ORDER_OPTIONS,
    actions: {
      delete: function _delete() {
        this.removeSortOption(this.get('sortOption'));
      },
      onOrderChange: function onOrderChange(value) {
        this.get('sortOption').set('order', value);
        this.onOptionOrderChange();
      }
    }
  });

  _exports.default = _default;
});