define("happyfox-bi/components/form-field-input/number/component", ["exports", "happyfox-bi/components/form-field-input/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    keyDown: function keyDown(e) {
      if (e.keyCode === 69) {
        // keycode 69 is letter e
        e.preventDefault();
      }

      if (!this.get('field.allowDecimal')) {
        if (e.keyCode === 190) {
          // keycode 190 is '.'
          e.preventDefault();
        }
      }
    },
    actions: {
      onFocusOut: function onFocusOut() {
        this.get('field').validate();
      }
    }
  });

  _exports.default = _default;
});