define("happyfox-bi/visualizations/new/components/benchmark-panel/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gDm3+8R4",
    "block": "{\"symbols\":[\"benchmark\",\"index\"],\"statements\":[[6,[37,7],[[35,4,[\"length\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,3],null,[[\"displayType\",\"benchmarkAggregationFields\",\"benchmark\",\"onUpdateBenchmarkItem\",\"onRemoveBenchmarkItem\"],[[35,2],[35,1],[32,1],[30,[36,0],[[32,0],\"updateBenchmark\",[32,2]],null],[30,[36,0],[[32,0],\"removeBenchmark\"],null]]]]],[2,\"\\n\"]],\"parameters\":[1,2]}]]]],\"parameters\":[]}]]],[1,[30,[36,3],null,[[\"isAdd\",\"displayType\",\"benchmark\",\"benchmarkAggregationFields\",\"onAddBenchmarkItem\"],[true,[35,2],[35,8],[35,1],[30,[36,0],[[32,0],\"addBenchmark\"],null]]]]]],\"hasEval\":false,\"upvars\":[\"action\",\"benchmarkAggregationFields\",\"displayType\",\"visualizations/new/components/benchmark-panel/benchmark-item\",\"benchmarks\",\"-track-array\",\"each\",\"if\",\"benchmark\"]}",
    "meta": {
      "moduleName": "happyfox-bi/visualizations/new/components/benchmark-panel/template.hbs"
    }
  });

  _exports.default = _default;
});