define("happyfox-bi/visualizations/new/create-edit-route", ["exports", "happyfox-bi/mixins/data-set"], function (_exports, _dataSet) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_dataSet.default, {
    dataService: Ember.inject.service('data'),
    applicationService: Ember.inject.service('application'),
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          'currentVisualizationDataView': 'pivot-table',
          'visualizationDataViewModel': null,
          'previewData': null,
          'visualizationData': null,
          'showMergeDataSetsModal': false,
          'pageLevelFields': null,
          'reportLevelFields': null,
          // FIXME: when revisiting create/edit controller any unsaved changes to page/report opt-in fields gets persisted
          'optedInReportFields': null,
          'optedInPageFields': null
        });
        this.get('applicationService').openSidePane();
      }
    }
  });

  _exports.default = _default;
});