define("happyfox-bi/reports/details/pages/details/components/sample-visualization-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DESCRIPTION_CHAR_LIMIT = 70;
  var AVAILABLE_VISUALIZATION_ICONS = ['data_tile', 'data_table', 'pivot_table', 'doughnut_chart', 'bar_chart', 'stacked_bar_chart', 'grouped_bar_chart', 'area_chart', 'stacked_area_chart', 'multi_area_chart', 'line_chart', 'multi_line_chart', 'multi_axis_chart', 'map_chart'];

  var _default = Ember.Component.extend({
    classNames: ['sample-visualization_item'],
    featuresService: Ember.inject.service('features'),
    dataService: Ember.inject.service('data'),
    visualization: null,
    options: null,
    canShowDeleteConfirmation: false,
    canShowEditModal: false,
    isDeleteInProgress: false,
    isCreateVisualizationDisabled: false,
    canManageSampleVisualizations: Ember.computed('featuresService', 'visualization.account', {
      get: function get() {
        var _this$get = this.get('featuresService'),
            createGlobalSampleVisualizations = _this$get.createGlobalSampleVisualizations,
            createSampleVisualizations = _this$get.createSampleVisualizations;

        var _this$get2 = this.get('visualization'),
            account = _this$get2.account;

        if (account && createSampleVisualizations) {
          return true;
        }

        if (!account && createGlobalSampleVisualizations) {
          return true;
        }

        return false;
      }
    }),
    visualizationIcon: Ember.computed('visualization.displayType', {
      get: function get() {
        var displayType = this.get('visualization.displayType');

        if (displayType && AVAILABLE_VISUALIZATION_ICONS.includes(displayType)) {
          return displayType.dasherize();
        } else {
          return 'chart';
        }
      }
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var self = this;
      var gridstackItem = this.$('.grid-stack-item');
      gridstackItem.draggable({
        disabled: self.isCreateVisualizationDisabled,
        revert: 'invalid',
        scroll: false,
        appendTo: '.js-visualizations-droppable',
        cursorAt: {
          left: 5
        },
        helper: 'clone',
        stop: function stop() {
          self.forceSetOriginalDimensions(gridstackItem);
        }
      });
      this.forceSetOriginalDimensions(gridstackItem);
    },
    isTruncated: Ember.computed('visualization.description', {
      get: function get() {
        var descriptionText = this.get('visualization.description');

        if (descriptionText.length > DESCRIPTION_CHAR_LIMIT) {
          return true;
        }

        return false;
      },
      set: function set(key, value) {
        return value;
      }
    }),
    truncatedDescription: Ember.computed('', {
      get: function get() {
        var descriptionText = this.get('visualization.description');

        if (descriptionText.length > DESCRIPTION_CHAR_LIMIT) {
          return "".concat(descriptionText.substring(0, DESCRIPTION_CHAR_LIMIT), "...");
        }

        return descriptionText;
      }
    }),
    click: function click() {
      this.toggleProperty('isTruncated');
    },
    forceSetOriginalDimensions: function forceSetOriginalDimensions(gridstackItem) {
      var options = this.get('options'); // Refer https://github.com/gridstack/gridstack.js/issues/413
      // FIXME: Above issue has been fixed in gridstack latest version. Need to Update it.

      var nodeData = gridstackItem.data('_gridstack_node') || {};
      nodeData.width = options.width;
      nodeData.height = options.height;
      gridstackItem.data("_gridstack_node", nodeData);
    },
    actions: {
      toggleDeleteConfirmation: function toggleDeleteConfirmation() {
        this.toggleProperty('canShowDeleteConfirmation');
      },
      openEditModal: function openEditModal() {
        this.set('canShowEditModal', true);
      },
      closeEditModal: function closeEditModal(visualization) {
        this.set('canShowEditModal', false);

        if (visualization) {
          this.onEditSampleVisualization(visualization);
        }
      },
      deleteSampleVisualization: function deleteSampleVisualization() {
        var _this = this;

        this.set('isDeleteInProgress', true);
        var visualizationId = this.get('visualization.id');
        this.dataService.deleteSampleVisualization(visualizationId).then(function () {
          _this.onDeleteSampleVisualization();
        }).finally(function () {
          _this.set('isDeleteInProgress', false);
        });
      }
    }
  });

  _exports.default = _default;
});