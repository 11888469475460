define("happyfox-bi/visualizations/components/display-fields-list/edit-field/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    featuresService: Ember.inject.service('features'),
    autoFocus: true,
    actions: {
      toggleEdit: function toggleEdit(popOver) {
        popOver.closePopOver();
      },
      initForm: function initForm() {
        this.set('fieldName', this.get('field.name'));
      },
      updateName: function updateName(popOver) {
        this.get('field').set('name', this.get('fieldName'));
        this.send('toggleEdit', popOver);
        this.onNameChange();
      }
    }
  });

  _exports.default = _default;
});