define("happyfox-bi/visualizations/new/components/benchmark-panel/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['benchmark'],
    benchmarks: null,
    displayType: null,
    displaySettings: null,
    aggregationFields: null,
    benchmarkAggregationFields: Ember.computed('aggregationFields.@each.{name,displayName}', 'displayType', {
      get: function get() {
        var aggregationFields = Ember.A();
        var displaySettings = this.displaySettings,
            displayType = this.displayType; // For multi axis chart, we map the value fields with display settings for axis type.

        if (displayType.id == 'multi_axis_chart' && displaySettings) {
          var yAxisLeftFieldKeys = (displaySettings.yAxisLeft || []).map(function (field) {
            return field.key;
          });
          var yAxisFields = Ember.A();
          this.aggregationFields.forEach(function (valueField) {
            if (yAxisLeftFieldKeys.includes(valueField.name)) {
              yAxisFields.pushObject(Ember.Object.create({
                name: valueField.name,
                displayName: valueField.displayName || valueField.name,
                yAxis: 'left'
              }));
            }
          });
          var yAxisRightFieldKeys = (displaySettings.yAxisRight || []).map(function (field) {
            return field.key;
          });
          this.aggregationFields.forEach(function (valueField) {
            if (yAxisRightFieldKeys.includes(valueField.name)) {
              yAxisFields.pushObject(Ember.Object.create({
                name: valueField.name,
                displayName: valueField.displayName || valueField.name,
                yAxis: 'right'
              }));
            }
          });
          aggregationFields = yAxisFields;
        } else {
          aggregationFields = this.aggregationFields.map(function (field) {
            return Ember.Object.create({
              name: field.name,
              displayName: field.displayName || field.name
            });
          });
        }

        return aggregationFields;
      }
    }),
    cloneBenchmarks: function cloneBenchmarks() {
      return this.benchmarks.slice();
    },
    actions: {
      addBenchmark: function addBenchmark(benchmark) {
        var benchmarks = this.cloneBenchmarks();
        benchmarks.push(benchmark);
        this.applyBenchmarks(benchmarks);
      },
      updateBenchmark: function updateBenchmark(oldItemIndex, newItem) {
        var benchmarks = this.cloneBenchmarks();
        benchmarks.splice(oldItemIndex, 1, newItem);
        this.applyBenchmarks(benchmarks);
      },
      removeBenchmark: function removeBenchmark(benchmark) {
        var benchmarks = this.cloneBenchmarks();
        benchmarks.splice(benchmarks.indexOf(benchmark), 1);
        this.applyBenchmarks(benchmarks);
      }
    }
  });

  _exports.default = _default;
});