define("happyfox-bi/visualizations/new/components/merge-datasets/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XyXckG7l",
    "block": "{\"symbols\":[\"joinData\"],\"statements\":[[6,[37,12],null,[[\"onClose\"],[[30,[36,3],[[32,0],\"closeModal\"],null]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"div\"],[24,\"role\",\"button\"],[24,0,\"modal_close\"],[4,[38,3],[[32,0],\"closeModal\"],null],[12],[1,[30,[36,4],[\"modal-close\"],null]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"modal_header_title\"],[12],[1,[30,[36,5],[\"merge-datasets\"],null]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"modal_header_info\"],[12],[1,[30,[36,5],[\"merge-datasets-info\"],null]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"modal_body mod-full-width\"],[12],[2,\"\\n\"],[6,[37,8],[[30,[36,7],[[30,[36,7],[[35,6]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,2],null,[[\"joinSettings\",\"primaryDataSet\",\"dataSetOptions\"],[[32,1],[35,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[10,\"div\"],[14,0,\"invite-user-modal_footer\"],[12],[2,\"\\n      \"],[1,[30,[36,11],null,[[\"class\",\"text\",\"inProgress\",\"inProgressText\",\"disabled\",\"onClick\"],[\"hf-primary-action\",[30,[36,5],[\"save\"],null],[35,10],[30,[36,5],[\"saving\"],null],[35,9],[30,[36,3],[[32,0],\"save\"],null]]]]],[2,\"\\n      \"],[11,\"a\"],[24,0,\"secondary-action\"],[24,\"role\",\"button\"],[4,[38,3],[[32,0],\"closeModal\"],null],[12],[1,[30,[36,5],[\"cancel\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"dataSetOptions\",\"primaryDataSet\",\"visualizations/new/components/merge-datasets/join-settings\",\"action\",\"inline-svg\",\"t\",\"joinsList\",\"-track-array\",\"each\",\"isSaveDisabled\",\"saveInProgress\",\"async-button\",\"modal-dialog\"]}",
    "meta": {
      "moduleName": "happyfox-bi/visualizations/new/components/merge-datasets/template.hbs"
    }
  });

  _exports.default = _default;
});