define("happyfox-bi/initializers/modify-power-select-multiple", ["exports", "ember-power-select/components/power-select-multiple"], function (_exports, _powerSelectMultiple) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Overriding Happyfox-web add-on's initializer to not handle comma event 
  // for making highlighted option as selected for allowing user to search/add fields with comma
  // Refer ^ https://github.com/happyfoxinc/happyfox-web-addons/blob/master/addon/initializers/modify-power-select-multiple.js
  var _default = {
    name: 'modify-power-select-multiple',
    initialize: function initialize() {
      _powerSelectMultiple.default.reopen({
        actions: {
          handleKeydown: function handleKeydown(select, e) {
            var action = this.get('onKeydown');

            if (action && action(select, e) === false) {
              e.stopPropagation();
              return false;
            }

            var selected = Ember.A(this.get('selected') || []);

            if (e.keyCode === 13 && select.isOpen) {
              e.preventDefault(); // To prevent default actions like form submit on enter in new ticket form

              e.stopPropagation();

              if (select.highlighted !== undefined) {
                if (selected.indexOf(select.highlighted) === -1) {
                  select.actions.choose(select.highlighted, e);
                } else {
                  select.actions.close(e);
                }
              } else {
                select.actions.close(e);
              }
            }
          }
        }
      });
    }
  };
  _exports.default = _default;
});