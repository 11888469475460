define("happyfox-bi/apps/details/form-content/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intlService: Ember.inject.service('intl'),
    saveInProgress: false,
    actions: {
      onFocusOut: function onFocusOut(field) {
        if (!field.value && field.isMandatory) {
          field.set('errors', [this.intlService.lookup('apps.form-fields.mandatory-error-message')]);
        } else {
          field.set('errors', []);
        }
      },
      reset: function reset() {
        this.reset();
      },
      saveSettings: function saveSettings() {
        this.saveSettings();
      }
    }
  });

  _exports.default = _default;
});