define("happyfox-bi/report-templates/components/table-cell/name/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isGlobalTemplate: Ember.computed.reads('row.content.isGlobal')
  });

  _exports.default = _default;
});