define("happyfox-bi/form-field-models/date-time-field", ["exports", "happyfox-bi/form-field-models/base-field", "happyfox-bi/utilities/date", "happyfox-bi/utilities/time"], function (_exports, _baseField, _date, _time) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseField.default.extend({
    isDateTime: true,
    dateValue: null,
    hourField: null,
    minuteField: null,
    minDateTime: null,
    // Expects a normal JS DateTime
    selectDefaultTimeInitially: false,
    selectDefaultTimeOnDateSelect: false,
    isFutureDateForceSelected: false,
    // Needed if minDateTime is specified and value given is in past time.
    meridiem: '',
    timeFieldLabel: 'time',
    isAM: Ember.computed.equal('meridiem', 'AM'),
    isPM: Ember.computed.equal('meridiem', 'PM'),
    init: function init() {
      this._super.apply(this, arguments);

      var intlService = this.get('intlService');
      var hourOptions = [];
      var minuteOptions = [];

      for (var i = 1; i <= 12; i++) {
        hourOptions.addObject(Ember.Object.create({
          value: i,
          label: "00".concat(i).slice(-2)
        }));
      }

      for (var _i = 0; _i < 60; _i++) {
        minuteOptions.addObject(Ember.Object.create({
          value: _i,
          label: "00".concat(_i).slice(-2)
        }));
      }

      var selectDefaultTimeInitially = this.get('selectDefaultTimeInitially') || this.get('isMandatory');
      var hourFieldPlaceholder = intlService.lookup('manage.hours');
      var minuteFieldPlaceholder = intlService.lookup('mins-caps');
      this.setProperties({
        hourField: Ember.Object.create({
          key: 'hour',
          isChoice: true,
          choiceLabelKey: 'label',
          choiceValueKey: 'value',
          choices: hourOptions,
          value: selectDefaultTimeInitially ? hourOptions[10] : null,
          placeholder: hourFieldPlaceholder,
          ariaLabel: hourFieldPlaceholder,
          validate: function validate() {// Dummy method. No specific validation for hour
          }
        }),
        minuteField: Ember.Object.create({
          key: 'minute',
          choiceLabelKey: 'label',
          choiceValueKey: 'value',
          isChoice: true,
          choices: minuteOptions,
          value: selectDefaultTimeInitially ? minuteOptions.get('lastObject') : null,
          placeholder: minuteFieldPlaceholder,
          ariaLabel: minuteFieldPlaceholder,
          validate: function validate() {// Dummy method. No specific validation for minute
          }
        })
      });

      if (selectDefaultTimeInitially) {
        this.set('meridiem', 'PM');
      }
    },
    value: Ember.computed('dateValue', 'hourField.value', 'minuteField.value', 'meridiem', {
      get: function get() {
        var value = null;
        var dateValue = this.get('dateValue');

        if (Ember.isPresent(dateValue)) {
          if (typeof dateValue === 'string') {
            dateValue = (0, _date.createDate)(dateValue);
          }

          value = (0, _time.convertDateToMoment)(dateValue);
          var hour = this.get('hourField.value');
          var meridiem = this.get('meridiem');

          if (Ember.isPresent(hour)) {
            // Replacing hour in the selected date.
            value = value.hour((0, _time.convert12HourTo24HourFormat)(hour.get('value'), meridiem));
          }

          var minute = this.get('minuteField.value');

          if (Ember.isPresent(minute)) {
            // Replacing minute
            value = value.minute(minute.get('value'));
          }
        }

        return value;
      },
      set: function set(key, value) {
        // moment(undefined) is returning today 👀
        value = Ember.isPresent(value) ? moment(value) : null;

        if (Ember.isPresent(value) && value.isValid()) {
          var minDateTime = this.get('minDateTime');
          var isDateTimeValue = (value.creationData().format || '').includes('T');
          var valueToCompare = isDateTimeValue ? value : value.clone().hour(23).minute(59);
          /**
           * If both date and time are selected, then compare them with min date and time. If only date is selected, then
           * moment will set the default time as 00:00:00 in which case, it will be considered as overdue. In order to avoid this,
           * if only date is selected, compare it with EOD
           */

          if (Ember.isPresent(minDateTime) && moment(valueToCompare).isBefore(moment(minDateTime), 'minute')) {
            value = moment(minDateTime).hour(23).minute(59); // setting minDateTime EOD.

            this.set('isFutureDateForceSelected', true); // Needed for edit case.
          }

          if (isDateTimeValue) {
            var convertedTime = (0, _time.convert24HourTo12HourFormat)(value.hour());
            var hourOption = this.get('hourField.choices').findBy('value', convertedTime.hour);
            var minuteOption = this.get('minuteField.choices').findBy('value', value.minute());
            this.setProperties({
              'dateValue': value.format(),
              'hourField.value': hourOption,
              'minuteField.value': minuteOption,
              'meridiem': convertedTime.meridiem
            });
          } else {
            // Don't set time if user has set only date
            this.set('dateValue', value.format());
          }
        } else {
          this.setProperties({
            'dateValue': null,
            'hourField.value': null,
            'minuteField.value': null,
            'meridiem': ''
          });
        }

        return value;
      }
    }),
    isDirty: Ember.computed('value', '_initialValue', 'isFutureDateForceSelected', {
      get: function get() {
        var value = this.get('value');
        var initialValue = this.get('_initialValue');

        if (Ember.isPresent(value) || Ember.isPresent(initialValue)) {
          return !moment(value).isSame(initialValue, 'minute') || this.get('isFutureDateForceSelected');
        }

        return false;
      }
    }),
    validate: function validate() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this._super().then(function () {
          _this.resetErrors(); // When time options are selected without selecting the date, throw error.


          if (Ember.isBlank(_this.get('dateValue')) && (Ember.isPresent(_this.get('hourField.value')) || Ember.isPresent(_this.get('minuteField.value')) || Ember.isPresent(_this.get('meridiem')))) {
            _this.addError(_this.get('intlService').lookup('validation.select-date'));

            reject();
          }

          var value = _this.get('value');

          if (Ember.isPresent(value)) {
            var minDateTime = _this.get('minDateTime');

            var isDateTimeValue = Ember.isPresent(_this.get('hourField.value'));

            if (isDateTimeValue && Ember.isPresent(minDateTime) && moment(value).isBefore(moment(minDateTime), 'minute')) {
              _this.addError(_this.get('intlService').lookup('validation.select-time-in-future'));

              reject();
            }
          }

          _this.set('_cleanValue', Ember.isPresent(value) ? value : null);

          resolve();
        }).catch(function () {
          reject();
        });
      });
    }
  });

  _exports.default = _default;
});