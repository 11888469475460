define("happyfox-bi/manage/reports/edit-report-pane/user-option/component", ["exports", "happyfox-bi/components/form-field-input/multi-choice-dropdown/option/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNameBindings: ['isDisabled:is-disabled'],
    canShowUserHelpText: false,
    isDisabled: Ember.computed('option.viewAllReports', {
      get: function get() {
        return this.option.viewAllReports;
      }
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.element.addEventListener('mouseenter', this.handleMouseEnter.bind(this));
      this.element.addEventListener('mouseleave', this.handleMouseLeave.bind(this));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.element.removeEventListener('mouseenter', this.handleMouseEnter);
      this.element.removeEventListener('mouseleave', this.handleMouseLeave);
    },
    click: function click() {
      if (!this.isDisabled) {
        this.toggleProperty('option.isSelected');
        this.onOptionSelection(this.get('option'));
      }
    },
    handleMouseEnter: function handleMouseEnter() {
      if (this.isDisabled) {
        this.set('canShowUserHelpText', true);
      }
    },
    handleMouseLeave: function handleMouseLeave() {
      if (this.isDisabled) {
        this.set('canShowUserHelpText', false);
      }
    }
  });

  _exports.default = _default;
});