define("happyfox-bi/components/form/non-field-errors/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    errors: null
  });

  _exports.default = _default;
});