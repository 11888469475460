define("happyfox-bi/components/table-bulk-selection-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KN851lkg",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,5],[[30,[36,4],[[35,3,[\"rows\"]],[35,2]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"bulk-selection-item_check-box\"],[12],[2,\"\\n    \"],[1,[30,[36,1],null,[[\"checked\"],[[35,0]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[18,1,null]],\"hasEval\":false,\"upvars\":[\"isAllSelected\",\"check-box\",\"canShowCheckbox\",\"table\",\"and\",\"if\"]}",
    "meta": {
      "moduleName": "happyfox-bi/components/table-bulk-selection-header/template.hbs"
    }
  });

  _exports.default = _default;
});