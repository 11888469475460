define("happyfox-bi/components/edit-page-modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    dataService: Ember.inject.service('data'),
    page: null,
    fields: null,
    filterFields: null,
    filters: null,
    prefetchedFiltersValueOptions: null,
    canShowDeleteConfirmation: false,
    isSaveDisabled: Ember.computed.equal('page.name', ''),
    init: function init() {
      this._super.apply(this, arguments);

      var fields = this.get('page.settings.fields');
      fields = fields ? fields.slice() : [];
      var filters = this.get('page.settings.filters.matchAll');
      this.setProperties({
        fields: fields,
        filterFields: fields.filterBy('isEditable').slice(),
        filters: filters ? filters.slice() : [],
        prefetchedFiltersValueOptions: Ember.Object.create()
      });
    },
    canDeletePage: Ember.computed('page.report', {
      get: function get() {
        var reportId = this.get('page.report');
        var report = this.get('dataService').peekReport(reportId);
        var pages = report.get('pages');
        return pages.length > 1;
      }
    }),
    actions: {
      closeEditPageModal: function closeEditPageModal() {
        var refresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

        if (this.get('page').get('hasDirtyAttributes')) {
          this.get('page').rollbackAttributes();
        }

        this.closeModalAction(refresh);
      },
      savePage: function savePage() {
        var _this = this;

        this.set('saveInProgress', true);
        var reportId = this.get('page.report');
        var pageId = this.get('page.id');
        var name = this.get('page.name');
        var settings = {
          fields: this.get('fields'),
          filters: {
            matchAll: this.get('filters')
          }
        };
        var data = {
          name: name,
          settings: settings
        };
        this.get('dataService').patchPage(reportId, pageId, data).then(function () {
          // Updating page details from the existing reports in store to reflect in side pane.
          var report = _this.get('dataService').peekReport(reportId);

          var page = report.get('pages').findBy('id', Number(pageId));
          page.set('name', name); // Change argument passed to true when there are changes to page which needs page refresh

          _this.send('closeEditPageModal', true);
        }).finally(function () {
          _this.set('saveInProgress', false);
        });
      },
      toggleDeleteConfirmation: function toggleDeleteConfirmation() {
        this.toggleProperty('canShowDeleteConfirmation');
      },
      deletePage: function deletePage() {
        var _this2 = this;

        this.set('isDeleteInProgress', true);
        var reportId = this.get('page.report');
        var pageId = this.get('page.id');
        this.get('dataService').deletePage(reportId, pageId).then(function () {
          // to reload reports and pages in side pane.
          _this2.get('dataService').getAllReports().then(function () {
            _this2.goToReportDetailAction(reportId);
          }).finally(function () {
            _this2.set('isDeleteInProgress', false);
          });
        }).catch(function () {
          _this2.set('isDeleteInProgress', false);
        });
      },
      applyFilters: function applyFilters(filters) {
        var _this3 = this;

        this.set('filters', filters);
        var unUsedFields = this.get('fields').filter(function (field) {
          var filter = _this3.get('filters').findBy('field', field.get('name'));

          return !filter && !field.get('isEditable');
        });
        this.get('fields').removeObjects(unUsedFields);
      }
    }
  });

  _exports.default = _default;
});