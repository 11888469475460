define("happyfox-bi/data-sources/components/create-data-set/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    dataService: Ember.inject.service('data'),
    dataSource: null,
    displayName: null,
    createInProgress: false,
    name: Ember.computed('displayName', {
      get: function get() {
        var displayName = this.get('displayName');
        return Ember.isPresent(displayName) ? Ember.String.camelize(displayName) : null;
      }
    }),
    isCreateDisabled: Ember.computed('displayName', {
      get: function get() {
        var displayName = this.get('displayName');
        return Ember.isEmpty(displayName);
      }
    }),
    actions: {
      toggleCreateDataSet: function toggleCreateDataSet() {
        this.toggleCreateDataSet();
      },
      createDataSet: function createDataSet() {
        var _this = this;

        if (!this.get('isCreateDisabled')) {
          var name = this.get('name');
          var displayName = this.get('displayName');
          var dataSourceId = this.get('dataSource.id');
          var requestData = {
            name: name,
            displayName: displayName,
            datasetType: 'custom',
            settings: {
              allowCsvImport: true
            }
          };
          this.set('createInProgress', true);
          this.get('dataService').createDataSet(dataSourceId, requestData).then(function () {
            _this.toggleCreateDataSet();

            if (_this.onDataSetCreation) {
              _this.onDataSetCreation();
            }
          }).catch(function () {}).finally(function () {
            _this.set('createInProgress', false);
          });
        }
      }
    }
  });

  _exports.default = _default;
});