define("happyfox-bi/components/add-visualization-field/bucket-values-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    bucketValue: null,
    isDefault: false,
    canShowAdd: Ember.computed('bucketValue', 'bucketValuesList.[]', {
      get: function get() {
        return this.bucketValuesList.indexOf(this.bucketValue) == this.bucketValuesList.length - 1;
      }
    }),
    canShowDelete: Ember.computed('bucketValuesList.[]', {
      get: function get() {
        return this.bucketValuesList.length > 1;
      }
    }),
    actions: {
      onDelete: function onDelete() {
        this.onDelete(this.bucketValue);
      },
      onAdd: function onAdd() {
        this.onAdd(this.bucketValue);
      }
    }
  });

  _exports.default = _default;
});