define("happyfox-bi/visualizations/new/components/display-settings/multi-axis-chart/component", ["exports", "happyfox-bi/visualizations/new/components/display-settings/base-chart/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    actions: {
      onYAxisLeftMinValueChange: function onYAxisLeftMinValueChange(value) {
        if (Ember.isPresent(value)) {
          this.set('settings.yAxisLeftMin', Number(value));
        } else {
          this.set('settings.yAxisLeftMin', null);
        }

        this.send('onUpdateDisplaySettings');
      },
      onYAxisLeftMaxValueChange: function onYAxisLeftMaxValueChange(value) {
        if (Ember.isPresent(value)) {
          this.set('settings.yAxisLeftMax', Number(value));
        } else {
          this.set('settings.yAxisLeftMax', null);
        }

        this.send('onUpdateDisplaySettings');
      },
      onYAxisRightMinValueChange: function onYAxisRightMinValueChange(value) {
        if (Ember.isPresent(value)) {
          this.set('settings.yAxisRightMin', Number(value));
        } else {
          this.set('settings.yAxisRightMin', null);
        }

        this.send('onUpdateDisplaySettings');
      },
      onYAxisRightMaxValueChange: function onYAxisRightMaxValueChange(value) {
        if (Ember.isPresent(value)) {
          this.set('settings.yAxisRightMax', Number(value));
        } else {
          this.set('settings.yAxisRightMax', null);
        }

        this.send('onUpdateDisplaySettings');
      }
    }
  });

  _exports.default = _default;
});