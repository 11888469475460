define("happyfox-bi/visualization-examples/controller", ["exports", "happyfox-bi/utilities/object", "happyfox-bi/visualization-examples/mock-data/page-1", "happyfox-bi/visualization-examples/mock-data/page-2", "happyfox-bi/visualization-examples/mock-data/page-3", "happyfox-bi/visualization-examples/mock-data/page-4", "happyfox-bi/visualization-examples/mock-data/page-5", "happyfox-bi/visualization-examples/mock-data/page-7", "happyfox-bi/visualization-examples/mock-data/page-9", "happyfox-bi/visualization-examples/mock-data/page-15", "happyfox-bi/visualization-examples/mock-data/page-16", "happyfox-bi/visualization-examples/mock-data/page-17"], function (_exports, _object, _page, _page2, _page3, _page4, _page5, _page6, _page7, _page8, _page9, _page10) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    visualizations: Ember.computed('', {
      get: function get() {
        var position, visualization, layoutOptions; // Data Tile 1

        position = "grid-row-start:15;grid-row-end:22;grid-column-start:9;grid-column-end:17;";
        visualization = Object.assign({
          style: position
        }, _page9.PAGE_16.visualizations.findBy('id', 50));
        visualization.name = 'DATA TILE';
        layoutOptions = {
          width: 4,
          height: 4,
          x: 0,
          y: 0
        };
        var dataTile1 = {
          type: 'data_tile',
          widgetModel: visualization,
          widgetData: _page9.PAGE_16_VISUALIZATION_DATA.findBy('id', 50).data,
          layoutOptions: layoutOptions
        }; // Data Tile 2

        position = "grid-row-start:15;grid-row-end:22;grid-column-start:9;grid-column-end:17;";
        visualization = Object.assign({
          style: position
        }, _page9.PAGE_16.visualizations.findBy('id', 51));
        visualization.name = 'DATA TILE';
        layoutOptions = {
          width: 4,
          height: 4,
          x: 4,
          y: 0
        };
        var dataTile2 = {
          type: 'data_tile',
          widgetModel: visualization,
          widgetData: _page9.PAGE_16_VISUALIZATION_DATA.findBy('id', 51).data,
          layoutOptions: layoutOptions
        }; // Data Tile 3

        position = "grid-row-start:15;grid-row-end:22;grid-column-start:9;grid-column-end:17;";
        visualization = Object.assign({
          style: position
        }, _page9.PAGE_16.visualizations.findBy('id', 52));
        visualization.name = 'DATA TILE';
        layoutOptions = {
          width: 4,
          height: 4,
          x: 0,
          y: 8
        };
        var dataTile3 = {
          type: 'data_tile',
          widgetModel: visualization,
          widgetData: _page9.PAGE_16_VISUALIZATION_DATA.findBy('id', 52).data,
          layoutOptions: layoutOptions
        }; // Data Tile 4

        position = "grid-row-start:15;grid-row-end:22;grid-column-start:9;grid-column-end:17;";
        visualization = Object.assign({
          style: position
        }, _page3.PAGE_3.visualizations.findBy('id', 6));
        visualization.name = 'DATA TILE';
        layoutOptions = {
          width: 4,
          height: 4,
          x: 4,
          y: 8
        };
        var dataTile4 = {
          type: 'data_tile',
          widgetModel: visualization,
          widgetData: _page3.PAGE_3_VISUALIZATION_DATA.findBy('id', 6).data,
          layoutOptions: layoutOptions
        }; // Data Table

        position = "grid-row-start:22;grid-row-end:29;grid-column-start:1;grid-column-end:9;";
        visualization = Object.assign({
          style: position
        }, _page7.PAGE_9.visualizations.findBy('id', 35));
        visualization.name = 'DATA TABLE';
        layoutOptions = {
          width: 8,
          height: 8,
          x: 8,
          y: 0
        };
        var table = {
          type: 'data-table',
          widgetModel: visualization,
          widgetData: _page7.PAGE_9_VISUALIZATION_DATA.findBy('id', 35).data,
          layoutOptions: layoutOptions
        }; // Pivot Table

        position = "grid-row-start:29;grid-row-end:36;grid-column-start:1;grid-column-end:17;";
        visualization = Object.assign({
          style: position
        }, _page.PAGE_1.visualizations.findBy('id', 1));
        visualization.name = 'PIVOT TABLE';
        layoutOptions = {
          width: 8,
          height: 8,
          x: 0,
          y: 16
        };
        var pivotTable = {
          type: 'pivot-table',
          widgetModel: visualization,
          widgetData: _page.PAGE_1_VISUALIZATION_DATA.findBy('id', 1).data,
          layoutOptions: layoutOptions
        }; // Doughnut chart

        position = "grid-row-start:15;grid-row-end:22;grid-column-start:9;grid-column-end:17;";
        visualization = Object.assign({
          style: position
        }, _page6.PAGE_7.visualizations.findBy('id', 28));
        visualization.name = 'DOUGHNUT CHART';
        layoutOptions = {
          width: 8,
          height: 8,
          x: 8,
          y: 16
        };
        var doughnutChart = {
          type: 'doughnut-chart',
          widgetModel: visualization,
          widgetData: _page6.PAGE_7_VISUALIZATION_DATA.findBy('id', 28).data,
          layoutOptions: layoutOptions
        }; // Bar chart

        position = "grid-row-start:15;grid-row-end:22;grid-column-start:1;grid-column-end:9;";
        visualization = Object.assign({
          style: position
        }, _page6.PAGE_7.visualizations.findBy('id', 29));
        visualization.name = 'BAR CHART';
        layoutOptions = {
          width: 8,
          height: 8,
          x: 0,
          y: 24
        };
        var barChart = {
          type: 'bar-chart',
          widgetModel: visualization,
          widgetData: _page6.PAGE_7_VISUALIZATION_DATA.findBy('id', 29).data,
          layoutOptions: layoutOptions
        }; // Stacked bar chart

        position = "grid-row-start:8;grid-row-end:15;grid-column-start:1;grid-column-end:9;";
        visualization = Object.assign({
          style: position
        }, _page3.PAGE_3.visualizations.findBy('id', 12));
        visualization.name = 'STACKED BAR CHART';
        layoutOptions = {
          width: 8,
          height: 8,
          x: 8,
          y: 24
        };
        var stackedBarChart = {
          type: 'stacked-bar-chart',
          widgetModel: visualization,
          widgetData: _page3.PAGE_3_VISUALIZATION_DATA.findBy('id', 12).data,
          layoutOptions: layoutOptions
        }; // Grouped bar chart

        position = "grid-row-start:8;grid-row-end:15;grid-column-start:9;grid-column-end:17;";
        visualization = Object.assign({
          style: position
        }, _page3.PAGE_3.visualizations.findBy('id', 12));
        visualization.name = 'GROUPED BAR CHART';
        layoutOptions = {
          width: 8,
          height: 8,
          x: 0,
          y: 32
        };
        var groupedBarChart = {
          type: 'grouped-bar-chart',
          widgetModel: visualization,
          widgetData: _page3.PAGE_3_VISUALIZATION_DATA.findBy('id', 12).data,
          layoutOptions: layoutOptions
        }; // Area chart

        position = "grid-row-start:1;grid-row-end:8;grid-column-start:9;grid-column-end:17;";
        visualization = Object.assign({
          style: position
        }, _page8.PAGE_15.visualizations.findBy('id', 45));
        visualization.name = 'AREA CHART';
        layoutOptions = {
          width: 8,
          height: 8,
          x: 8,
          y: 32
        };
        var areaChart = {
          type: 'area-chart',
          widgetModel: visualization,
          widgetData: _page8.PAGE_15_VISUALIZATION_DATA.findBy('id', 45).data,
          layoutOptions: layoutOptions
        }; // Stacked Area chart

        position = "grid-row-start:1;grid-row-end:8;grid-column-start:9;grid-column-end:17;";
        visualization = Object.assign({
          style: position
        }, _page8.PAGE_15.visualizations.findBy('id', 44));
        visualization.name = 'STACKED AREA CHART';
        layoutOptions = {
          width: 8,
          height: 8,
          x: 0,
          y: 40
        };
        var stackedAreaChart = {
          type: 'stacked-area-chart',
          widgetModel: visualization,
          widgetData: _page8.PAGE_15_VISUALIZATION_DATA.findBy('id', 44).data,
          layoutOptions: layoutOptions
        }; // Multi Area chart

        position = "grid-row-start:1;grid-row-end:8;grid-column-start:9;grid-column-end:17;";
        visualization = Object.assign({
          style: position
        }, _page9.PAGE_16.visualizations.findBy('id', 53));
        visualization.name = 'MULTI AREA CHART';
        layoutOptions = {
          width: 8,
          height: 8,
          x: 8,
          y: 40
        };
        var multiAreaChart = {
          type: 'multi-area-chart',
          widgetModel: visualization,
          widgetData: _page9.PAGE_16_VISUALIZATION_DATA.findBy('id', 53).data,
          layoutOptions: layoutOptions
        }; // Line chart

        position = "grid-row-start:1;grid-row-end:8;grid-column-start:9;grid-column-end:17;";
        visualization = Object.assign({
          style: position
        }, _page4.PAGE_4.visualizations.findBy('id', 1111));
        visualization.name = 'LINE CHART';
        layoutOptions = {
          width: 8,
          height: 8,
          x: 0,
          y: 48
        };
        var lineChart = {
          type: 'line-chart',
          widgetModel: visualization,
          widgetData: _page4.PAGE_4_VISUALIZATION_DATA.findBy('id', 1111).data,
          layoutOptions: layoutOptions
        }; // Multi Line Chart

        position = "grid-row-start:22;grid-row-end:29;grid-column-start:9;grid-column-end:17;";
        visualization = Object.assign({
          style: position
        }, _page5.PAGE_5.visualizations.findBy('id', 24));
        visualization.name = 'MULTI LINE CHART';
        layoutOptions = {
          width: 8,
          height: 8,
          x: 8,
          y: 48
        };
        var multiLineChart = {
          type: 'multi-line-chart',
          widgetModel: visualization,
          widgetData: _page5.PAGE_5_VISUALIZATION_DATA.findBy('id', 24).data,
          layoutOptions: layoutOptions
        }; // Multi Axis chart

        position = "grid-row-start:1;grid-row-end:8;grid-column-start:9;grid-column-end:17;";
        visualization = Object.assign({
          style: position
        }, _page8.PAGE_15.visualizations.findBy('id', 43));
        visualization.name = 'MULTI AXIS CHART';
        layoutOptions = {
          width: 16,
          height: 8,
          x: 0,
          y: 56
        };
        var multiAxisChart = {
          type: 'multi-axis-chart',
          widgetModel: visualization,
          widgetData: _page8.PAGE_15_VISUALIZATION_DATA.findBy('id', 43).data,
          layoutOptions: layoutOptions
        }; // Map chart

        position = "grid-row-start:1;grid-row-end:8;grid-column-start:1;grid-column-end:9;";
        visualization = Object.assign({
          style: position
        }, _page2.PAGE_2.visualizations.findBy('id', 3));
        visualization.name = 'MAP CHART';
        layoutOptions = {
          width: 8,
          height: 8,
          x: 0,
          y: 64
        };
        var mapChart = {
          type: 'map-chart',
          widgetModel: visualization,
          widgetData: _page2.PAGE_2_VISUALIZATION_DATA.findBy('id', 3).data,
          layoutOptions: layoutOptions
        }; // Map chart by region

        position = "grid-row-start:1;grid-row-end:8;grid-column-start:9;grid-column-end:17;";
        visualization = Object.assign({
          style: position
        }, _page2.PAGE_2.visualizations.findBy('id', 4));
        visualization.name = 'MAP CHART BY REGION';
        layoutOptions = {
          width: 8,
          height: 8,
          x: 8,
          y: 64
        };
        var mapChartByRegion = {
          type: 'map-chart',
          widgetModel: visualization,
          widgetData: _page2.PAGE_2_VISUALIZATION_DATA.findBy('id', 4).data,
          layoutOptions: layoutOptions
        }; // Life cycle chart

        position = "grid-row-start:1;grid-row-end:8;grid-column-start:9;grid-column-end:17;";
        visualization = Object.assign({
          style: position
        }, _page10.PAGE_17.visualizations.findBy('id', 57));
        visualization.name = 'LIFE CYCLE CHART';
        layoutOptions = {
          width: 16,
          height: 8,
          x: 8,
          y: 72
        };
        var lifeCycleChart = {
          type: 'life-cycle-chart',
          widgetModel: visualization,
          widgetData: _page10.PAGE_17_VISUALIZATION_DATA.findBy('id', 57).data,
          layoutOptions: layoutOptions
        }; // Segmentation chart

        position = "grid-row-start:1;grid-row-end:8;grid-column-start:9;grid-column-end:17;";
        visualization = Object.assign({
          style: position
        }, _page10.PAGE_17.visualizations.findBy('id', 63));
        visualization.name = 'SEGMENTATION CHART';
        layoutOptions = {
          width: 16,
          height: 8,
          x: 0,
          y: 80
        };
        var segmentationChart = {
          type: 'segmentation-chart',
          widgetModel: visualization,
          widgetData: _page10.PAGE_17_VISUALIZATION_DATA.findBy('id', 63).data,
          layoutOptions: layoutOptions
        }; // Funnel chart

        position = "grid-row-start:1;grid-row-end:8;grid-column-start:9;grid-column-end:17;";
        visualization = Object.assign({
          style: position
        }, _page10.PAGE_17.visualizations.findBy('id', 62));
        visualization.name = 'FUNNEL CHART';
        layoutOptions = {
          width: 16,
          height: 8,
          x: 8,
          y: 88
        };
        var funnelChart = {
          type: 'funnel-chart',
          widgetModel: visualization,
          widgetData: _page10.PAGE_17_VISUALIZATION_DATA.findBy('id', 62).data,
          layoutOptions: layoutOptions
        };
        return [dataTile1, dataTile2, dataTile3, dataTile4, table, pivotTable, doughnutChart, barChart, stackedBarChart, groupedBarChart, areaChart, stackedAreaChart, multiAreaChart, lineChart, multiLineChart, multiAxisChart, mapChart, mapChartByRegion, lifeCycleChart, segmentationChart, funnelChart].map(function (chart) {
          return (0, _object.camelizeObject)(chart);
        });
      }
    })
  });

  _exports.default = _default;
});