define("happyfox-bi/visualizations/new/components/filter-field-selection/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showAddField: false,
    selectedField: null,
    settingsFields: null,
    autoOpen: false,
    popOver: null,
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.get('selectedField') && this.get('autoOpen')) {
        this.set('showAddField', true);
      }
    },
    actions: {
      toggleAddField: function toggleAddField() {
        this.toggleProperty('showAddField');

        if (this.get('popOver') && !this.get('selectedField')) {
          this.get('popOver').closePopOver();
        }
      },
      fetchDataSetFields: function fetchDataSetFields() {
        return this.fetchDataSetFields();
      },
      removeField: function removeField() {
        this.set('selectedField', null);
        this.onFieldSelection(null);

        if (this.get('popOver')) {
          this.get('popOver').closePopOver();
        }
      },
      onFieldSelection: function onFieldSelection(field) {
        this.set('selectedField', field);
        this.onFieldSelection(field);
        this.toggleProperty('showAddField');
      }
    }
  });

  _exports.default = _default;
});