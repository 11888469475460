define("happyfox-bi/components/table-bulk-selection-row/component", ["exports", "ember-light-table/components/lt-row", "happyfox-bi/mixins/bulk-selection-item"], function (_exports, _ltRow, _bulkSelectionItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ltRow.default.extend(_bulkSelectionItem.default, {
    'data-test-id': Ember.computed('row.content.id', {
      get: function get() {
        return "table-row-id-".concat(this.get('row.content.id'));
      }
    }),
    isAnySelected: Ember.computed.notEmpty('table.selectedRows'),
    observeCanShow: Ember.observer('canShow', function () {
      if (this.get('row.content')) {
        this.get('row').set('canShow', this.get('canShow'));
      }
    }).on('init')
  });

  _exports.default = _default;
});