define("happyfox-bi/visualizations/new/components/display-settings/empty-state/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oFRHV/zx",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"no-visualization-settings\"],null]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "happyfox-bi/visualizations/new/components/display-settings/empty-state/template.hbs"
    }
  });

  _exports.default = _default;
});