define("happyfox-bi/components/form-field-input/local-multi-choice-dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vC4+utCa",
    "block": "{\"symbols\":[\"value\"],\"statements\":[[6,[37,10],null,[[\"options\",\"selected\",\"class\",\"placeholder\",\"onChange\",\"onClose\",\"renderInPlace\",\"disabled\",\"closeOnSelect\",\"searchField\",\"data-test-id\",\"dropdownClass\"],[[35,0,[\"choices\"]],[35,0,[\"value\"]],[30,[36,9],[[35,8],[35,7],[30,[36,5],[[35,6],\"is-error\"],null],[30,[36,5],[[35,0,[\"isHighlighted\"]],\"hf-is-highlighted\"],null],[30,[36,5],[[35,0,[\"isDisabled\"]],\"is-disabled\"],null]],null],[35,0,[\"placeholder\"]],[30,[36,4],[[32,0],\"valueChanged\"],null],[30,[36,4],[[32,0],\"onClose\"],null],true,[35,0,[\"isDisabled\"]],[35,0,[\"closeOnSelect\"]],[35,0,[\"choiceLabelKey\"]],[35,3],[35,2]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,1],[[32,1],[35,0,[\"choiceLabelKey\"]]],null]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[19,\"components/form-field-input/partials/error-message\",[]],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"field\",\"get\",\"dropdownClass\",\"fieldDataTestId\",\"action\",\"if\",\"hasErrors\",\"jsTestClass\",\"selectClass\",\"concat-strings\",\"auto-complete/local/multiple\"]}",
    "meta": {
      "moduleName": "happyfox-bi/components/form-field-input/local-multi-choice-dropdown/template.hbs"
    }
  });

  _exports.default = _default;
});