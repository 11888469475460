define("happyfox-bi/form-field-models/text-field", ["exports", "happyfox-bi/form-field-models/base-field", "happyfox-bi/constants"], function (_exports, _baseField, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseField.default.extend({
    value: '',
    _initialValue: '',
    isText: true,
    minCharacterLimit: null,
    maxCharacterLimit: null,
    isUrl: false,
    autoFocus: false,
    init: function init() {
      this._super();

      var minCharacterLimit = this.get('minCharacterLimit');
      var maxCharacterLimit = this.get('maxCharacterLimit');

      if (minCharacterLimit && maxCharacterLimit && minCharacterLimit > maxCharacterLimit) {
        (false && !(this.get('intlService').lookup('validation.invalid-text-configuration')) && Ember.assert(false, this.get('intlService').lookup('validation.invalid-text-configuration')));
      }
    },
    validate: function validate() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this._super().then(function () {
          // character limit check
          var value = _this.get('value');

          if (Ember.isPresent(value)) {
            var minCharacterLimit = _this.get('minCharacterLimit');

            var maxCharacterLimit = _this.get('maxCharacterLimit');

            if (minCharacterLimit && value.length < minCharacterLimit) {
              if (maxCharacterLimit) {
                _this.addError(_this.get('intlService').lookupWithParameters('validation.text-{min}-{max}-characters-limit', {
                  min: minCharacterLimit,
                  max: maxCharacterLimit
                }));
              } else {
                _this.addError(_this.get('intlService').lookupWithParameters('validation.text-{min}-characters-limit', {
                  min: minCharacterLimit
                }));
              }

              reject();
            }

            if (maxCharacterLimit && value.length > maxCharacterLimit) {
              if (minCharacterLimit) {
                _this.addError(_this.get('intlService').lookupWithParameters('validation.text-{min}-{max}-characters-limit', {
                  min: minCharacterLimit,
                  max: maxCharacterLimit
                }));
              } else {
                _this.addError(_this.get('intlService').lookupWithParameters('validation.text-{max}-characters-limit', {
                  max: maxCharacterLimit
                }));
              }

              reject();
            }

            if (_this.get('isUrl') && !_constants.URL_REGEX.test(value)) {
              _this.addError(_this.get('intlService').lookupWithParameters('validation.invalid-url'));

              reject();
            }
          }

          _this.set('_cleanValue', value);

          resolve();
        }).catch(function () {
          reject();
        });
      });
    },
    clear: function clear() {
      this.setProperties({
        value: '',
        _initialValue: ''
      });
    }
  });

  _exports.default = _default;
});