define("happyfox-bi/visualizations/new/components/table-formatting/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V31OVfCc",
    "block": "{\"symbols\":[\"format\"],\"statements\":[[10,\"div\"],[14,0,\"field_label\"],[12],[1,[30,[36,6],[\"data-bars\"],null]],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"table-format\"],[12],[2,\"\\n\"],[6,[37,8],[[30,[36,7],[[30,[36,7],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,5],null,[[\"format\",\"onEditFormat\",\"onRemoveFormat\"],[[32,1],[30,[36,0],[[32,0],\"editFormat\"],null],[30,[36,0],[[32,0],\"removeFormat\"],null]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"],[11,\"div\"],[24,0,\"new-create-edit-fields-selection_label_add icon-btn\"],[24,\"role\",\"button\"],[4,[38,0],[[32,0],\"openTableFormatModal\"],null],[12],[2,\"\\n  \"],[1,[30,[36,9],[\"plus\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,11],[[35,10]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,4],null,[[\"allFormats\",\"format\",\"fieldOptions\",\"onCloseModal\",\"onAddFormat\",\"onUpdateFormat\"],[[35,3],[35,2],[35,1],[30,[36,0],[[32,0],\"closeTableFormatModal\"],null],[30,[36,0],[[32,0],\"addFormat\"],null],[30,[36,0],[[32,0],\"updateFormat\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"numericFields\",\"currentFormat\",\"formats\",\"visualizations/new/components/table-formatting/format-options-modal\",\"visualizations/new/components/table-formatting/format-item\",\"t\",\"-track-array\",\"each\",\"inline-svg\",\"canShowTableFormatModal\",\"if\"]}",
    "meta": {
      "moduleName": "happyfox-bi/visualizations/new/components/table-formatting/template.hbs"
    }
  });

  _exports.default = _default;
});