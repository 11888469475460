define("happyfox-bi/components/module-switcher/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MAX_DATA_SOURCES_TO_DISPLAY = 6;

  var _default = Ember.Component.extend({
    featuresService: Ember.inject.service('features'),
    dataService: Ember.inject.service('data'),
    accountInfoService: Ember.inject.service('account-info'),
    isDesktopApp: false,
    isAppsEnabled: Ember.computed.reads('featuresService.manageApps'),
    isDatasourcesEnabled: Ember.computed.reads('featuresService.manageDataSources'),
    isReportTemplatesEnabled: Ember.computed.reads('featuresService.createReportTemplates'),
    isReportSubscriptionsEnabled: Ember.computed.reads('featuresService.reportSubscriptions'),
    isBusinessHoursEnabled: Ember.computed.reads('featuresService.businessHours'),
    manageReports: Ember.computed.reads('featuresService.manageReports'),
    manageUsers: Ember.computed.reads('featuresService.manageUsers'),
    datasources: null,
    init: function init() {
      this._super();

      if (this.isDatasourcesEnabled) {
        this.fetchDataSources();
      }
    },
    showAllDatasourcesLink: Ember.computed('datasources.[]', {
      get: function get() {
        return this.datasources && this.datasources.length > MAX_DATA_SOURCES_TO_DISPLAY;
      }
    }),
    datasourcesToDisplay: Ember.computed('showAllDatasourcesLink', {
      get: function get() {
        var showAllDatasourcesLink = this.showAllDatasourcesLink;
        var datasources = this.datasources || [];

        if (showAllDatasourcesLink) {
          return datasources.slice(0, MAX_DATA_SOURCES_TO_DISPLAY - 1);
        }

        return datasources;
      }
    }),
    canShowManage: Ember.computed('manageReports', 'manageUsers', {
      get: function get() {
        var manageReports = this.manageReports,
            manageUsers = this.manageUsers;
        return manageReports || manageUsers;
      }
    }),
    fetchDataSources: function fetchDataSources() {
      var _this = this;

      var dataService = this.dataService;
      dataService.getAllDataSources(true).then(function (datasources) {
        _this.set('datasources', datasources);
      });
    },
    actions: {
      onOpenPopOver: function onOpenPopOver() {
        if (this.isDatasourcesEnabled) {
          this.set('datasources', this.dataService.peekAllDataSources());
        }
      }
    }
  });

  _exports.default = _default;
});