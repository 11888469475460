define("happyfox-bi/components/loading-small/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tCl4A1g3",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"action_loader\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"ball-triangle-loader\"],[[\"class\"],[\"u-loader-small\"]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"inline-svg\"]}",
    "meta": {
      "moduleName": "happyfox-bi/components/loading-small/template.hbs"
    }
  });

  _exports.default = _default;
});