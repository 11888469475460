define("happyfox-bi/report-subscriptions/components/subscribe-to-report/component", ["exports", "happyfox-bi/utilities/form-models", "happyfox-bi/report-subscriptions/components/subscribe-to-report/subscription-form-model"], function (_exports, _formModels, _subscriptionFormModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    dataService: Ember.inject.service('data'),
    profileService: Ember.inject.service('profile'),
    toastMessageService: Ember.inject.service('toast-message'),
    subscription: null,
    // incoming subscription for edit
    currentReportId: null,
    currentPageId: null,
    reportsList: null,
    subscriptionForm: null,
    recipientsList: null,
    isLoading: false,
    inProgress: false,
    isDeleteInProgress: false,
    canShowDeleteConfirmation: false,
    canShowSaveConfirmation: false,
    linkToReportSubscriptionsList: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.fetchRecipients();
    },
    fetchRecipients: function fetchRecipients() {
      var _this = this;

      var dataService = this.dataService;
      var recipientsList = Ember.A();
      this.set('isLoading', true);
      var promises = {
        users: dataService.getAllUsers(),
        clients: dataService.getAllClients()
      };
      Ember.RSVP.hash(promises).then(function (data) {
        var userOptions = data.users.filterBy('isActive');

        if (userOptions.length) {
          recipientsList.addObjects(userOptions);
        }

        var clientOptions = data.clients.filterBy('isActive');

        if (clientOptions.length) {
          recipientsList.addObjects(clientOptions);
        }

        _this.set('recipientsList', recipientsList);

        _this.renderForm();
      }).finally(function () {
        _this.set('isLoading', false);
      });
    },
    renderForm: function renderForm() {
      var reportsList = this.reportsList,
          recipientsList = this.recipientsList,
          subscription = this.subscription,
          isAdd = this.isAdd,
          currentReportId = this.currentReportId,
          currentPageId = this.currentPageId;
      var dataSource = isAdd ? null : Ember.Object.create(subscription);
      var subscriptionForm = (0, _formModels.createObjectInContainer)(this, _subscriptionFormModel.default, {
        dataSource: dataSource,
        isAdd: isAdd,
        reportsList: reportsList,
        recipientsList: recipientsList,
        currentReportId: currentReportId,
        currentPageId: currentPageId
      });
      this.set('subscriptionForm', subscriptionForm);
    },
    isAdd: Ember.computed('subscription', {
      get: function get() {
        var currentSubscription = this.subscription;
        return !Ember.isPresent(currentSubscription);
      }
    }),
    actions: {
      closePane: function closePane() {
        this.onCloseSidePane();
      },
      onReportChange: function onReportChange() {
        var form = this.subscriptionForm;
        form.updatePagesList();
      },
      onFrequencyChange: function onFrequencyChange() {
        var form = this.subscriptionForm;
        var frequencyField = form.model.schedule.model.frequency;
        form.createDaysToRun(frequencyField.value.id, false);
      },
      toggleSaveConfirmation: function toggleSaveConfirmation() {
        this.toggleProperty('canShowSaveConfirmation');
      },
      createSubscription: function createSubscription() {
        var _this2 = this;

        var toastMessageService = this.toastMessageService,
            dataService = this.dataService,
            subscriptionForm = this.subscriptionForm;
        subscriptionForm.submit().then(function (validatedPayload) {
          _this2.set('inProgress', true);

          return dataService.createReportSubscription(validatedPayload).then(function () {
            // Recipients who doesn't have access to the report will be given access, so fetching the reports.
            _this2.get('dataService').getAllReports();

            toastMessageService.showMessage({
              type: 'success',
              text: "report-subscription-create-success"
            });

            _this2.onCloseSidePane(true);
          }).catch(function (errorResponse) {
            var status = errorResponse.status;

            if (status == 422) {
              subscriptionForm._populateErrors(errorResponse);

              subscriptionForm.scrollToError();
            } else {
              toastMessageService.showMessage({
                type: 'failure',
                text: "report-subscription-create-failed"
              });
            }
          }).finally(function () {
            _this2.set('inProgress', false);
          });
        });
      },
      updateSubscription: function updateSubscription() {
        var _this3 = this;

        var toastMessageService = this.toastMessageService,
            dataService = this.dataService,
            subscription = this.subscription,
            subscriptionForm = this.subscriptionForm;
        var subscriptionId = subscription.id;
        subscriptionForm.submit().then(function (validatedPayload) {
          _this3.set('inProgress', true);

          return dataService.patchReportSubscription(subscriptionId, validatedPayload).then(function () {
            // Recipients who doesn't have access to the report will be given access, so fetching the reports.
            _this3.get('dataService').getAllReports();

            toastMessageService.showMessage({
              type: 'success',
              text: "report-subscription-update-success"
            });

            _this3.onCloseSidePane(true);
          }).catch(function (errorResponse) {
            var status = errorResponse.status;

            if (status == 422) {
              subscriptionForm._populateErrors(errorResponse);

              subscriptionForm.scrollToError();
            } else {
              toastMessageService.showMessage({
                type: 'failure',
                text: "report-subscription-update-failed"
              });
            }
          }).finally(function () {
            _this3.set('inProgress', false);
          });
        });
      },
      toggleDeleteConfirmation: function toggleDeleteConfirmation() {
        this.toggleProperty('canShowDeleteConfirmation');
      },
      deleteSubscription: function deleteSubscription() {
        var _this4 = this;

        var subscriptionId = this.subscription.id;
        var toastMessageService = this.toastMessageService,
            dataService = this.dataService;
        this.set('isDeleteInProgress', true);
        dataService.deleteReportSubscription(subscriptionId).then(function () {
          toastMessageService.showMessage({
            type: 'success',
            text: "report-subscription-delete-success"
          });

          _this4.onCloseSidePane(true);
        }).catch(function () {
          toastMessageService.showMessage({
            type: 'failure',
            text: "report-subscription-delete-failed"
          });
        }).finally(function () {
          _this4.set('isDeleteInProgress', false);
        });
      }
    }
  });

  _exports.default = _default;
});