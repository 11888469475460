define("happyfox-bi/login/user-credentials/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ovuIgSio",
    "block": "{\"symbols\":[],\"statements\":[[10,\"form\"],[14,0,\"login-content\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"login-content_branding\"],[12],[2,\"\\n    \"],[10,\"img\"],[14,\"src\",\"/assets/images/happyfox.png\"],[14,0,\"login-content_branding_img\"],[15,\"alt\",[30,[36,1],[\"happyfox.title\"],null]],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"login-content_company-name\"],[12],[1,[30,[36,1],[\"login-happyfox-bi\"],[[\"htmlSafe\"],[true]]]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"login-content_text\"],[12],[1,[30,[36,1],[\"username-email\"],null]],[13],[2,\"\\n  \"],[1,[30,[36,3],null,[[\"type\",\"value\",\"placeholder\",\"class\"],[\"text\",[35,2],[30,[36,1],[\"username-email-placeholder\"],null],\"login-content_username\"]]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"login-content_text\"],[12],[1,[30,[36,1],[\"password\"],null]],[13],[2,\"\\n  \"],[1,[30,[36,3],null,[[\"type\",\"value\",\"placeholder\",\"class\"],[\"password\",[35,4],[30,[36,1],[\"password-placeholder\"],null],\"login-content_password\"]]]],[2,\"\\n\"],[6,[37,6],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"login-content_error\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[1,[30,[36,10],null,[[\"class\",\"text\",\"inProgress\",\"inProgressText\",\"disabled\",\"onClick\"],[\"login-content_button hf-primary-action\",[30,[36,1],[\"login\"],null],[35,9],[30,[36,1],[\"logging-in\"],null],[35,8],[30,[36,7],[[32,0],\"login\"],null]]]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"login-content_bottom-link\"],[12],[2,\"\\n    \"],[11,\"a\"],[24,0,\"hf-primary-action\"],[24,\"role\",\"button\"],[4,[38,7],[[32,0],\"goToResetPassword\"],null],[12],[1,[30,[36,1],[\"forgot-password\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"errorMessage\",\"t\",\"email\",\"input\",\"password\",\"showError\",\"if\",\"action\",\"disableSubmit\",\"isLoginInProgress\",\"async-button\"]}",
    "meta": {
      "moduleName": "happyfox-bi/login/user-credentials/template.hbs"
    }
  });

  _exports.default = _default;
});