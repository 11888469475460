define("happyfox-bi/visualizations/new/components/display-settings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+UKBRcxX",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,8],[[35,7]],[[\"class\",\"isChartAxisType\",\"isChartLineSeries\",\"canConfigureLegend\",\"canConfigureValueLabels\",\"settings\",\"onUpdateDisplaySettings\",\"onUpdatePivotTableIncludePercentage\",\"pivotTableIncludePercentageOption\"],[\"display-settings\",[35,6],[35,5],[35,4],[35,3],[35,2],[30,[36,1],[[32,0],\"onUpdateDisplaySettings\"],null],[30,[36,1],[[32,0],\"onUpdatePivotTableIncludePercentage\"],null],[35,0]]]]]],\"hasEval\":false,\"upvars\":[\"pivotTableIncludePercentageOption\",\"action\",\"visualizationDisplaySettings\",\"canConfigureValueLabels\",\"canConfigureLegend\",\"isChartLineSeries\",\"isChartAxisType\",\"displaySettingsComponent\",\"component\"]}",
    "meta": {
      "moduleName": "happyfox-bi/visualizations/new/components/display-settings/template.hbs"
    }
  });

  _exports.default = _default;
});