define("happyfox-bi/manage/reports/edit-report-pane/user-option/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FvEbiE9S",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[12],[2,\"\\n  \"],[1,[30,[36,5],null,[[\"type\",\"class\",\"checked\",\"disabled\",\"data-test-id\",\"aria-label\"],[\"checkbox\",\"mod-no-outline\",[35,2,[\"isSelected\"]],[35,4],\"entity-dropdown-option-check-box\",[30,[36,3],[[35,2],[35,1]],null]]]]],[2,\"\\n  \"],[1,[30,[36,3],[[35,2],[35,1]],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,7],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"user-option-help-text\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"view-all-reports-tooltip-text\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"choiceLabelKey\",\"option\",\"get\",\"isDisabled\",\"input\",\"canShowUserHelpText\",\"if\"]}",
    "meta": {
      "moduleName": "happyfox-bi/manage/reports/edit-report-pane/user-option/template.hbs"
    }
  });

  _exports.default = _default;
});