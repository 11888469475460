define("happyfox-bi/apps/details/wrike/component", ["exports", "happyfox-bi/mixins/manage-apps"], function (_exports, _manageApps) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_manageApps.default, {
    toastMessageService: Ember.inject.service('toast-message'),
    app: null,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.app.error) {
        this.get('toastMessageService').showMessage({
          type: 'failure',
          text: "wrike.".concat(this.app.error)
        });
      }
    },
    actions: {
      addAccount: function addAccount() {
        window.location = window.location.origin + '/integrations/wrike/initiate-oauth/';
      }
    }
  });

  _exports.default = _default;
});