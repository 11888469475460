define("happyfox-bi/components/nav-tabs/component", ["exports", "@happyfoxinc/hf-nav-tabs/components/hf-nav-tabs"], function (_exports, _hfNavTabs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _hfNavTabs.default;
    }
  });
});