define("happyfox-bi/internal-login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PBRcFiRk",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"bootApp\"],[[30,[36,0],[[32,0],\"bootApp\"],null]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"internal-login/user-details\"]}",
    "meta": {
      "moduleName": "happyfox-bi/internal-login/template.hbs"
    }
  });

  _exports.default = _default;
});