define("happyfox-bi/visualizations/components/top-n-filter/constant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TOP_N_FILTER_TYPES = void 0;
  var TOP_N_FILTER_TYPES = [{
    id: 'top',
    'name': 'Top'
  }, {
    id: 'bottom',
    'name': 'Bottom'
  }];
  _exports.TOP_N_FILTER_TYPES = TOP_N_FILTER_TYPES;
});