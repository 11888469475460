define("happyfox-bi/services/account-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DEFAULT_VISUALIZATIONS_LIMIT = 25;

  var _default = Ember.Service.extend({
    accountInfo: null,
    planFeatures: Ember.computed.reads('accountInfo.planFeatures'),
    isSalesforceEnabled: Ember.computed.reads('planFeatures.salesforce'),
    isAircallEnabled: Ember.computed.reads('planFeatures.aircall'),
    isWrikeEnabled: Ember.computed.reads('planFeatures.wrike'),
    isVisualizationsLimitReached: Ember.computed('accountInfo.{visualizationsCount,limits.visualizations}', {
      get: function get() {
        var _this$accountInfo = this.accountInfo,
            visualizationsCount = _this$accountInfo.visualizationsCount,
            maxVisualizations = _this$accountInfo.limits.visualizations;
        var visualizationsLimit = Ember.isPresent(maxVisualizations) ? maxVisualizations : DEFAULT_VISUALIZATIONS_LIMIT; // For accounts with no restrictions, 'unlimited' will be the limit value.

        if (visualizationsLimit === 'unlimited') {
          return false;
        }

        return visualizationsCount >= visualizationsLimit;
      }
    })
  });

  _exports.default = _default;
});