define("happyfox-bi/page-screenshot/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9Ooa0xEV",
    "block": "{\"symbols\":[],\"statements\":[[10,\"section\"],[14,0,\"page content_outlet_main\"],[12],[2,\"\\n  \"],[10,\"header\"],[14,0,\"page_top-bar\"],[14,1,\"page-top-bar\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"page_top-bar_title\"],[12],[2,\"\\n      \"],[1,[35,5,[\"name\"]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"section\"],[14,0,\"u-vertically-centered-container u-push-right\"],[12],[2,\"\\n\"],[6,[37,7],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,4],null,[[\"direction\",\"alwaysShowCalendars\",\"autoApply\",\"startDate\",\"endDate\",\"dateRange\",\"maxDate\",\"data-test-id\"],[\"left\",true,true,[35,3],[35,2],[35,1],[35,0],\"page-date-range-picker\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"section\"],[14,0,\"page_content\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"page_content_main\"],[12],[2,\"\\n      \"],[1,[30,[36,11],null,[[\"visualizations\",\"visualizationsPositions\",\"getVisualizationData\",\"isStaticGrid\"],[[35,5,[\"visualizations\"]],[35,10],[30,[36,9],[[32,0],\"getVisualizationData\"],null],[35,8]]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"maxDate\",\"dateRange\",\"endDate\",\"startDate\",\"date-range-picker\",\"model\",\"showDateRangePicker\",\"if\",\"isStaticGrid\",\"action\",\"visualizationsPositions\",\"page-screenshot/grid-layout\"]}",
    "meta": {
      "moduleName": "happyfox-bi/page-screenshot/template.hbs"
    }
  });

  _exports.default = _default;
});