define("happyfox-bi/visualizations/new/components/fields-selection/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import { equal } from '@ember/object/computed';
  var MAX_LIMIT = 20;

  var _default = Ember.Component.extend({
    classNames: ['new-create-edit-fields-selection'],
    intlService: Ember.inject.service('intl'),
    displayType: null,
    rows: null,
    columns: null,
    values: null,
    showRows: false,
    showColumns: false,
    showValues: false,
    rowsFieldLabel: null,
    rowsFieldKey: null,
    columnsFieldLabel: null,
    columnsFieldKey: null,
    valuesFieldLabel: null,
    valuesFieldKey: null,
    rowsLimit: 0,
    columnsLimit: 0,
    valuesLimit: 0,
    showAddRowField: false,
    showAddColumnField: false,
    showAddValueField: false,
    settingsFields: null,
    // isDataTile: equal('displayType.id', 'data_tile'),
    // isTable: equal('displayType.id', 'data_table'),
    // isPivotTable: equal('displayType.id', 'pivot_table'),
    // isBarChart: equal('displayType.id', 'bar_chart'),
    // isLineChart: equal('displayType.id', 'line_chart'),
    // isAreaChart: equal('displayType.id', 'area_chart'),
    // isDoughnutChart: equal('displayType.id', 'doughnut_chart'),
    // isGroupedBarChart: equal('displayType.id', 'grouped_bar_chart'),
    // isStackedBarChart: equal('displayType.id', 'stacked_bar_chart'),
    // isStackedAreaChart: equal('displayType.id', 'stacked_area_chart'),
    // isMultiLineChart: equal('displayType.id', 'multi_line_chart'),
    // isMultiAreaChart: equal('displayType.id', 'multi_area_chart'),
    // isMapChart: equal('displayType.id', 'map_chart'),
    // isMultiAxisChart: equal('displayType.id', 'multi_axis_chart'),
    // isLifeCycleChart: equal('displayType.id', 'life_cycle_chart'),
    canAddRow: Ember.computed('rows.length', 'rowsLimit', {
      get: function get() {
        return this.get('rows.length') < this.get('rowsLimit');
      }
    }),
    canAddColumn: Ember.computed('columns.length', 'columnsLimit', {
      get: function get() {
        return this.get('columns.length') < this.get('columnsLimit');
      }
    }),
    canAddValue: Ember.computed('values.length', 'valuesLimit', {
      get: function get() {
        return this.get('values.length') < this.get('valuesLimit');
      }
    }),
    onDisplayTypeChange: Ember.observer('displayType.id', function () {
      var displayType = this.get('displayType.id');
      var intlService = this.get('intlService');

      switch (displayType) {
        case 'data_tile':
          this.setProperties({
            showRows: true,
            showColumns: false,
            showValues: true,
            rowsFieldLabel: 'Label',
            rowsFieldKey: 'label',
            rowsFieldHelpText: intlService.lookup('data-tile-label-help-text'),
            columnsFieldLabel: null,
            valuesFieldLabel: 'Value',
            valuesFieldKey: 'value',
            valuesFieldHelpText: intlService.lookup('data-tile-value-help-text'),
            rowsLimit: 1,
            columnsLimit: 0,
            valuesLimit: 1
          });
          break;

        case 'pivot_table':
          this.setProperties({
            showRows: true,
            showColumns: true,
            showValues: true,
            rowsFieldLabel: 'Rows',
            rowsFieldKey: 'rows',
            columnsFieldLabel: 'Columns',
            columnsFieldKey: 'columns',
            valuesFieldLabel: 'Values',
            valuesFieldKey: 'values',
            rowsLimit: 2,
            columnsLimit: 2,
            valuesLimit: MAX_LIMIT
          });
          break;

        case 'bar_chart':
        case 'line_chart':
        case 'area_chart':
        case 'pareto_chart':
          this.setProperties({
            showRows: true,
            showColumns: false,
            showValues: true,
            rowsFieldLabel: 'X-Axis',
            rowsFieldKey: 'x-axis',
            columnsFieldLabel: null,
            valuesFieldLabel: 'Y-Axis',
            valuesFieldKey: 'y-axis',
            rowsLimit: 1,
            columnsLimit: null,
            valuesLimit: 1
          });
          break;

        case 'grouped_bar_chart':
        case 'stacked_bar_chart':
        case 'stacked_area_chart':
        case 'multi_line_chart':
        case 'multi_area_chart':
        case 'hundred_percent_stacked_bar_chart':
          this.setProperties({
            showRows: true,
            showColumns: true,
            showValues: true,
            rowsFieldLabel: 'X-Axis',
            rowsFieldKey: 'x-axis',
            columnsFieldLabel: 'Group By',
            columnsFieldKey: 'group-by',
            valuesFieldLabel: 'Y-Axis',
            valuesFieldKey: 'y-axis',
            rowsLimit: 1,
            columnsLimit: 1,
            valuesLimit: MAX_LIMIT
          });
          break;

        case 'map_chart':
        case 'map_chart_usa':
          this.setProperties({
            showRows: true,
            showColumns: false,
            showValues: true,
            rowsFieldLabel: 'Region',
            rowsFieldKey: 'region',
            rowsFieldHelpText: intlService.lookup('map-chart-region-help-text'),
            columnsFieldLabel: null,
            valuesFieldLabel: 'Value',
            valuesFieldKey: 'values',
            valuesFieldHelpText: intlService.lookup('map-chart-value-help-text'),
            rowsLimit: 1,
            columnsLimit: null,
            valuesLimit: 1
          });
          break;

        case 'multi_axis_chart':
          this.setProperties({
            showRows: true,
            showColumns: false,
            showValues: true,
            rowsFieldLabel: 'X-Axis',
            rowsFieldKey: 'x-axis',
            columnsFieldLabel: null,
            valuesFieldLabel: 'Y-Axis Left & Y-Axis Right',
            valuesFieldKey: 'y-axis-left',
            rowsLimit: 1,
            columnsLimit: null,
            valuesLimit: MAX_LIMIT
          });
          break;

        case 'segmentation_chart':
          this.setProperties({
            showRows: true,
            showColumns: false,
            showValues: true,
            rowsFieldLabel: 'Field',
            rowsFieldKey: 'field',
            rowsFieldHelpText: intlService.lookup('segmentation-chart-field-help-text'),
            columnsFieldLabel: null,
            valuesFieldLabel: 'X-Axis & Y-Axis',
            valuesFieldKey: 'x-axis',
            valuesFieldHelpText: intlService.lookup('segmentation-chart-value-help-text'),
            rowsLimit: 1,
            columnsLimit: null,
            valuesLimit: 2
          });
          break;

        case 'funnel_chart':
          this.setProperties({
            showRows: true,
            showColumns: false,
            showValues: true,
            rowsFieldLabel: 'Field',
            rowsFieldKey: 'field',
            rowsFieldHelpText: intlService.lookup('funnel-chart-field-help-text'),
            columnsFieldLabel: null,
            valuesFieldLabel: 'Value',
            valuesFieldKey: 'value',
            valuesFieldHelpText: intlService.lookup('funnel-chart-value-help-text'),
            rowsLimit: 1,
            columnsLimit: null,
            valuesLimit: 1
          });
          break;

        case 'life_cycle_chart':
          this.setProperties({
            showRows: true,
            showColumns: true,
            showValues: true,
            rowsFieldLabel: 'From Field',
            rowsFieldKey: 'from',
            rowsFieldHelpText: intlService.lookup('life-cycle-chart-from-field-help-text'),
            columnsFieldLabel: 'To Field',
            columnsFieldKey: 'to',
            columnsFieldHelpText: intlService.lookup('life-cycle-chart-to-field-help-text'),
            valuesFieldLabel: 'Value',
            valuesFieldKey: 'values',
            valuesFieldHelpText: intlService.lookup('life-cycle-chart-value-help-text'),
            rowsLimit: 1,
            columnsLimit: 1,
            valuesLimit: 1
          });
          break;

        case 'word_cloud':
          this.setProperties({
            showRows: true,
            showColumns: false,
            showValues: true,
            rowsFieldLabel: 'Field',
            rowsFieldKey: 'field',
            rowsFieldHelpText: intlService.lookup('word-cloud-field-help-text'),
            columnsFieldLabel: null,
            valuesFieldLabel: 'Value',
            valuesFieldKey: 'value',
            valuesFieldHelpText: intlService.lookup('word-cloud-value-help-text'),
            rowsLimit: 1,
            columnsLimit: null,
            valuesLimit: 1
          });
          break;

        case 'heat_map':
          this.setProperties({
            showRows: true,
            showColumns: true,
            showValues: true,
            rowsFieldLabel: 'X-Axis',
            rowsFieldKey: 'x-axis',
            columnsFieldLabel: 'Y-Axis',
            columnsFieldKey: 'y-axis',
            valuesFieldLabel: 'Value',
            valuesFieldKey: 'value',
            rowsLimit: 1,
            columnsLimit: 1,
            valuesLimit: 1
          });
          break;

        case 'tree_map':
          this.setProperties({
            showRows: true,
            showColumns: false,
            showValues: true,
            rowsFieldLabel: 'Group',
            rowsFieldKey: 'group',
            columnsFieldLabel: null,
            valuesFieldLabel: 'Value',
            valuesFieldKey: 'value',
            rowsLimit: 3,
            columnsLimit: null,
            valuesLimit: 1
          });
          break;

        case 'gauge_chart':
          this.setProperties({
            showRows: false,
            showColumns: false,
            showValues: true,
            rowsFieldLabel: null,
            rowsFieldKey: '',
            columnsFieldLabel: null,
            valuesFieldLabel: 'Target',
            valuesFieldKey: 'target',
            rowsLimit: null,
            columnsLimit: null,
            valuesLimit: 1
          });
          break;
      }
    }).on('init'),
    actions: {
      removeRowField: function removeRowField(field) {
        var rows = this.get('rows').slice();
        rows.removeObject(field);
        this.onRowsChange(rows);
      },
      removeColumnField: function removeColumnField(field) {
        var columns = this.get('columns').slice();
        columns.removeObject(field);
        this.onColumnsChange(columns);
      },
      removeValueField: function removeValueField(field) {
        var values = this.get('values').slice();
        values.removeObject(field);
        this.onValuesChange(values);
      },
      addRowField: function addRowField(field) {
        var rows = this.get('rows').slice();
        rows.addObject(field);
        this.toggleProperty('showAddRowField');
        this.onRowsChange(rows);
      },
      addColumnField: function addColumnField(field) {
        var columns = this.get('columns').slice();
        columns.addObject(field);
        this.toggleProperty('showAddColumnField');
        this.onColumnsChange(columns);
      },
      addValueField: function addValueField(field) {
        var values = this.get('values').slice();
        values.addObject(field);
        this.toggleProperty('showAddValueField');
        this.onValuesChange(values);
      },
      toggleAddRowField: function toggleAddRowField() {
        this.toggleProperty('showAddRowField');
      },
      toggleAddColumnField: function toggleAddColumnField() {
        this.toggleProperty('showAddColumnField');
      },
      toggleAddValueField: function toggleAddValueField() {
        this.toggleProperty('showAddValueField');
      },
      fetchDataSetFields: function fetchDataSetFields() {
        return this.fetchDataSetFields();
      },
      editRowField: function editRowField(newField, field) {
        var rows = this.get('rows').slice();
        var indexOfOldField = rows.indexOf(field);
        rows.removeObject(field);
        rows.insertAt(indexOfOldField, newField);
        this.onRowsChange(rows);
      },
      editColumnField: function editColumnField(newField, field) {
        var columns = this.get('columns').slice();
        var indexOfOldField = columns.indexOf(field);
        columns.removeObject(field);
        columns.insertAt(indexOfOldField, newField);
        this.onColumnsChange(columns);
      },
      editValueField: function editValueField(newField, field) {
        var values = this.get('values').slice();
        var indexOfOldField = values.indexOf(field);
        values.removeObject(field);
        values.insertAt(indexOfOldField, newField);
        this.onValuesChange(values);
      }
    }
  });

  _exports.default = _default;
});