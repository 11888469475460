define("happyfox-bi/visualizations/new/components/display-settings/gauge-chart/component", ["exports", "happyfox-bi/visualizations/new/components/display-settings/base-chart/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['gauge-bands'],
    gaugeBands: null,
    settings: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.initializeGaugeBands();
    },
    initializeGaugeBands: function initializeGaugeBands() {
      var settings = this.settings;
      var gaugeBands = (settings.bands || Ember.A()).slice();
      this.set('gaugeBands', gaugeBands);
    },
    updateBandSettings: function updateBandSettings(gaugeBands) {
      var settings = this.settings;
      settings.set('bands', gaugeBands.slice());
      this.onUpdateDisplaySettings();
    },
    actions: {
      addBand: function addBand() {
        var gaugeBands = this.gaugeBands;
        var defaultBand = Ember.Object.create({
          name: '',
          min: null,
          max: null,
          color: '#ed4134' // critical

        });
        gaugeBands.pushObject(defaultBand);
      },
      updateBand: function updateBand(oldItem, newItem) {
        var gaugeBands = this.gaugeBands; // Not removing and inserting, as gaugeBands list gets re-rendered
        // due to which user interaction (like focus) on band-item component gets lost

        Object.keys(oldItem).forEach(function (key) {
          oldItem[key] = newItem[key];
        });
        this.updateBandSettings(gaugeBands);
      },
      removeBand: function removeBand(bandItem) {
        var gaugeBands = this.gaugeBands;
        gaugeBands.removeObject(bandItem);
        this.updateBandSettings(gaugeBands);
      },
      onGaugeMinChange: function onGaugeMinChange(value) {
        var settings = this.settings;

        if (Ember.isPresent(value)) {
          settings.set('min', Number(value));
        } else {
          settings.set('min', null);
        }

        this.onUpdateDisplaySettings();
      },
      onGaugeMaxChange: function onGaugeMaxChange(value) {
        var settings = this.settings;

        if (Ember.isPresent(value)) {
          settings.set('max', Number(value));
        } else {
          settings.set('max', null);
        }

        this.onUpdateDisplaySettings();
      }
    }
  });

  _exports.default = _default;
});