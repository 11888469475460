define("happyfox-bi/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hy6t6sls",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,9],[[35,13,[\"message\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"toast-message\",[],[[\"@autoHideDelay\"],[5000]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"section\"],[14,0,\"content js-application-content\"],[12],[2,\"\\n\"],[6,[37,9],[[35,14,[\"isIndependentWebPage\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,12],[[30,[36,11],null,null]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,9],[[35,8]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,5],null,[[\"data-pop-over-selector\",\"logout\",\"isSidePaneOpen\",\"canShowAccountSwitcherModal\",\"canShowMySettings\",\"onRefresh\"],[\"app-top-bar\",[30,[36,0],[[32,0],\"logout\"],null],[35,1],[35,4],[35,3],[30,[36,0],[[32,0],\"refresh\"],null]]]]],[2,\"\\n\"],[6,[37,7],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,2],null,[[\"isOpen\",\"goToReportDetailAction\"],[[35,1],[30,[36,0],[[32,0],\"goToReportDetailAction\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"    \"],[10,\"section\"],[15,0,[30,[36,10],[\"content_outlet\",[30,[36,9],[[35,6],\"mod-hide-side-pane\"],null],[30,[36,9],[[35,1],\"mod-active-side-pane\"],null],[30,[36,7],[[35,8],\"mod-not-authenticated\"],null]],null]],[12],[2,\"\\n      \"],[1,[30,[36,12],[[30,[36,11],null,null]],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"isSidePaneOpen\",\"application/side-pane\",\"canShowMySettings\",\"canShowAccountSwitcherModal\",\"application/top-bar\",\"hideSidePane\",\"unless\",\"isUserAuthenticated\",\"if\",\"concat-strings\",\"-outlet\",\"component\",\"toastMessageService\",\"applicationService\"]}",
    "meta": {
      "moduleName": "happyfox-bi/application/template.hbs"
    }
  });

  _exports.default = _default;
});