define("happyfox-bi/visualization-examples/mock-data/page-2", ["exports", "happyfox-bi/visualization-examples/mock-data/map-data"], function (_exports, _mapData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PAGE_2_VISUALIZATION_DATA = _exports.PAGE_2 = void 0;
  var PAGE_2 = {
    id: 2,
    name: 'Product Symptom by Countries & Region',
    order: 2,
    report: 1,
    created_by: 1,
    created_at: '2019-06-17T08:08:33Z',
    updated_at: '2019-06-18T11:20:30Z',
    visualizations: [{
      id: 3,
      name: 'SYMPTOM ACROSS COUNTRIES',
      page: 2,
      display_type: 'map_chart',
      display_settings: {
        size: 'square-m',
        region: 'country',
        use_iso: false,
        value: 'value',
        excluded_regions: ['Antarctica']
      },
      preferences: {
        filters: {
          match_all: []
        },
        date_range: 'last_month'
      },
      settings: {
        fields: [{
          'name': 'country',
          'type': 'dataset_field',
          'dataset': 1,
          'dataset_field': 12,
          'data_type': 'text',
          'is_editable': false
        }, {
          'name': 'value',
          'type': 'aggregation',
          'aggregation_type': 'count',
          'dataset': 1,
          'dataset_field': 14,
          'data_type': 'decimal',
          'is_editable': false
        }],
        query: {
          rows: ['country'],
          columns: [],
          values: ['value'],
          filters: {
            match_all: []
          },
          sort_by: []
        }
      }
    }, {
      id: 4,
      name: 'SYMPTOM ACROSS REGIONS',
      page: 2,
      display_type: 'map_chart',
      display_settings: {
        size: 'square-m',
        region: 'region',
        value: 'value',
        use_iso: false,
        group_into_regions: true,
        excluded_regions: ['Antarctica']
      },
      preferences: {
        filters: {
          match_all: []
        },
        date_range: 'last_month'
      },
      settings: {
        fields: [{
          'name': 'region',
          'type': 'dataset_field',
          'dataset': 1,
          'dataset_field': 12,
          'data_type': 'text',
          'is_editable': false
        }, {
          'name': 'value',
          'type': 'aggregation',
          'aggregation_type': 'count',
          'dataset': 1,
          'dataset_field': 14,
          'data_type': 'decimal',
          'is_editable': false
        }],
        query: {
          rows: ['region'],
          columns: [],
          values: ['value'],
          filters: {
            match_all: []
          },
          sort_by: []
        }
      }
    }],
    layout: {
      'items': [{
        'visualization_id': 3,
        'width': 8,
        'height': 8,
        'x': 0,
        'y': 0
      }, {
        'visualization_id': 4,
        'width': 8,
        'height': 8,
        'x': 8,
        'y': 8
      }]
    }
  };
  _exports.PAGE_2 = PAGE_2;
  var PAGE_2_VISUALIZATION_DATA = [{
    id: 3,
    data: _mapData.COUNTRY_LEVEL_DATA
  }, {
    id: 4,
    data: _mapData.REGION_LEVEL_DATA
  }];
  /* USA map data */
  // id: 3,
  // data: [
  //   { id: 'US-AL', value: 110 }, { id: 'US-AZ', value: 120 }, { id: 'US-AR', value: 130 }, { id: 'US-CA', value: 140 }, { id: 'US-CO', value: 150 },
  //   { id: 'US-CT', value: 210 }, { id: 'US-DE', value: 220 }, { id: 'US-FL', value: 230 }, { id: 'US-GA', value: 240 },
  //   { id: 'US-ID', value: 310 }, { id: 'US-IL', value: 320 }, { id: 'US-IN', value: 330 }, { id: 'US-IA', value: 340 }, { id: 'US-KS', value: 350 },
  //   { id: 'US-KY', value: 410 }, { id: 'US-LA', value: 420 }, { id: 'US-ME', value: 430 }, { id: 'US-MD', value: 440 }, { id: 'US-MA', value: 450 },
  //   { id: 'US-MI', value: 510 }, { id: 'US-MN', value: 520 }, { id: 'US-MS', value: 530 }, { id: 'US-MO', value: 540 }, { id: 'US-MT', value: 550 },
  //   { id: 'US-NE', value: 610 }, { id: 'US-NV', value: 620 }, { id: 'US-NH', value: 630 }, { id: 'US-NJ', value: 640 }, { id: 'US-NM', value: 650 },
  //   { id: 'US-NY', value: 710 }, { id: 'US-NC', value: 720 }, { id: 'US-ND', value: 730 }, { id: 'US-OH', value: 740 }, { id: 'US-OK', value: 750 },
  //   { id: 'US-OR', value: 810 }, { id: 'US-PA', value: 820 }, { id: 'US-RI', value: 830 }, { id: 'US-SC', value: 840 }, { id: 'US-SD', value: 850 },
  //   { id: 'US-TN', value: 910 }, { id: 'US-TX', value: 920 }, { id: 'US-UT', value: 930 }, { id: 'US-VT', value: 940 }, { id: 'US-VA', value: 950 },
  //   { id: 'US-WA', value: 1010 }, { id: 'US-WV', value: 1020 }, { id: 'US-WI', value: 1030 }, { id: 'US-WY', value: 1040 }
  // ]
  // }, {
  // id: 4,
  // data: [
  //   { id: 'US-WEST', value: 400 },
  //   { id: 'US-MID-WEST', value: 300 },
  //   { id: 'US-SOUTH', value: 100 },
  //   { id: 'US-NORTH-EAST', value: 200 }
  // ]
  // }

  _exports.PAGE_2_VISUALIZATION_DATA = PAGE_2_VISUALIZATION_DATA;
});