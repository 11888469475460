define("happyfox-bi/components/slide-in/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QfB4kpoD",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[30,[36,7],[[35,6]],null]],[2,\"\\n\"],[10,\"div\"],[12],[2,\"\\n\"],[6,[37,9],[[35,8]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"section\"],[15,0,[31,[\"u-content-overlay \",[34,5],\" \",[30,[36,1],[[35,0],\"overlay-fade-out\",\"overlay-fade-in\"],null]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"aside\"],[15,0,[31,[\"u-slide-in \",[34,5],\" \",[30,[36,1],[[35,0],\"is-hidden\"],null],\" \",[30,[36,1],[[35,10],\"mod-mini\"],null]]]],[12],[2,\"\\n\"],[6,[37,9],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"div\"],[24,\"role\",\"button\"],[24,0,\"form-header_close\"],[24,\"data-test-id\",\"slide-in-close\"],[4,[38,3],[[32,0],\"close\"],null],[12],[2,\"\\n        \"],[1,[30,[36,4],[\"modal-close\"],[[\"class\"],[\"mod-close-svg\"]]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],[[35,11]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"div\"],[24,\"role\",\"button\"],[16,0,[31,[\"slide-out-button \",[30,[36,1],[[35,0],\"is-hidden tooltipped tooltipped-left\"],null]]]],[16,\"aria-label\",[30,[36,1],[[35,0],[35,2]],null]],[4,[38,3],[[32,0],\"toggleVisibility\"],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[18,1,[[30,[36,3],[[32,0],\"toggleVisibility\"],null],[30,[36,3],[[32,0],\"close\"],null]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[1,[30,[36,7],[[35,12]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"isHidden\",\"if\",\"tooltipText\",\"action\",\"inline-svg\",\"modifierClass\",\"_wormholeHeadNode\",\"unbound\",\"hideOverlay\",\"unless\",\"isMini\",\"canHide\",\"_wormholeTailNode\"]}",
    "meta": {
      "moduleName": "happyfox-bi/components/slide-in/template.hbs"
    }
  });

  _exports.default = _default;
});