define("happyfox-bi/page-screenshot/widget-tile/component", ["exports", "happyfox-bi/visualizations/constant", "happyfox-bi/components/base-chart/constants"], function (_exports, _constant, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AUTO_DATE_FORMAT_OPTION = {
    'id': 'auto',
    'name': 'Auto'
  };

  var _default = Ember.Component.extend({
    dataService: Ember.inject.service('data'),
    featuresService: Ember.inject.service('features'),
    profileService: Ember.inject.service('profile'),
    tagName: 'section',
    classNames: ['widget-tile'],
    widgetModel: null,
    widgetData: null,
    isLoadingData: false,
    isDataTile: Ember.computed.equal('widgetModel.displayType', 'data_tile'),
    // dateRangePreferences: reads('profileService.profile.dateRangePreferences'),
    xAxisFieldName: Ember.computed.reads('widgetModel.displaySettings.xAxis.key'),
    isTextTile: Ember.computed.equal('widgetModel.displayType', 'text_tile'),
    isRequestFailure: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.loadData(true);
    },
    // Options shown on widget tile when x axis field is either timestamp or date
    dateFormatOptions: Ember.computed('widgetModel.settings.query.dateRangeField', {
      get: function get() {
        var isXAxisDateTimeType = this.isXAxisDateTimeType;
        var options = Ember.A();

        if (isXAxisDateTimeType) {
          // Show format options of type Timestamp
          options = _constant.TIMESTAMP_FORMAT_OPTIONS.filter(function (option) {
            return _constants.TIMESTAMP_BASED_FIELD_FORMATS.includes(option.id);
          });
        } else {
          // Show format options of type Date
          options = _constant.DATE_FORMAT_OPTIONS.filter(function (option) {
            return _constants.DATE_BASED_FIELD_FORMATS.includes(option.id);
          });
        }

        if (this.get('widgetModel.settings.query.dateRangeField')) {
          options.unshift(AUTO_DATE_FORMAT_OPTION);
        }

        return options;
      }
    }),
    isXAxisDateTimeType: Ember.computed('widgetModel.settings.fields.@each.name', 'xAxisFieldName', {
      get: function get() {
        var fieldName = this.get('xAxisFieldName');

        if (fieldName) {
          var field = this.get('widgetModel.settings.fields').findBy('name', fieldName);

          if (field) {
            var fieldType = field.get('dataType');

            if (fieldType === 'timestamp') {
              var format = field.get('format');
              return _constants.TIMESTAMP_BASED_FIELD_FORMATS.includes(format);
            }
          }
        }

        return false;
      }
    }),
    isXAxisDateType: Ember.computed('widgetModel.settings.fields.@each.name', 'xAxisFieldName', {
      get: function get() {
        var fieldName = this.get('xAxisFieldName');

        if (fieldName) {
          var field = this.get('widgetModel.settings.fields').findBy('name', fieldName);

          if (field) {
            var fieldType = field.get('dataType');

            if (fieldType === 'date') {
              var format = field.get('format');
              return _constants.DATE_BASED_FIELD_FORMATS.includes(format);
            }
          }
        }

        return false;
      }
    }),
    selectedDateFormat: Ember.computed('widgetModel.preferences.dateFormat', 'seekedDateRange.{fromDate,toDate}', {
      get: function get() {
        var seekedDateRange = this.seekedDateRange,
            dateFormatOptions = this.dateFormatOptions,
            visualization = this.widgetModel;
        var hasDateRangeField = visualization.get('settings.query.dateRangeField');
        var selectedDateFormat = hasDateRangeField ? AUTO_DATE_FORMAT_OPTION : dateFormatOptions.findBy('id', 'date');

        if (seekedDateRange && (seekedDateRange.fromDate || seekedDateRange.toDate)) {
          return selectedDateFormat;
        } else {
          var preferences = visualization.get('preferences');

          if (preferences) {
            var format = preferences.get('dateFormat');

            if (format && format !== 'auto') {
              return dateFormatOptions.findBy('id', format);
            }
          }

          return selectedDateFormat;
        }
      },
      set: function set(key, value) {
        return value;
      }
    }),
    filterFields: Ember.computed('widgetModel.settings.fields.[]', 'widgetModel.settings.fields.@each.{isEditable,dataset}', {
      get: function get() {
        return this.get('widgetModel.settings.fields').filterBy('isEditable').rejectBy('type', 'dataset');
      }
    }),
    viewWidgetComponent: Ember.computed('widgetModel.displayType', {
      get: function get() {
        var widgetType = this.get('widgetModel.displayType');

        if (Ember.isPresent(widgetType)) {
          return "components/".concat(widgetType.dasherize());
        }
      }
    }),
    hasEditableFilterFields: Ember.computed('filterFields.@each.isEditable', {
      get: function get() {
        return this.get('filterFields').isAny('isEditable');
      }
    }),
    isResizeInProgress: Ember.computed('visualizationsToResize.[]', {
      get: function get() {
        var visualizationId = this.get('widgetModel').id;
        return this.get('visualizationsToResize').includes(visualizationId);
      }
    }),
    showValueLabels: Ember.computed('widgetModel.{preferences.showValueLabels,displaySettings.showValueLabels}', {
      get: function get() {
        var widgetDisplaySettings = this.get('widgetModel.displaySettings');
        var widgetPreferences = this.get('widgetModel.preferences');

        if (widgetPreferences && Ember.isPresent(widgetPreferences.get('showValueLabels'))) {
          return widgetPreferences.get('showValueLabels');
        }

        return widgetDisplaySettings.showValueLabels;
      }
    }),
    canToggleValueLabels: Ember.computed('widgetModel.displayType', {
      get: function get() {
        var widgetType = this.get('widgetModel.displayType');
        return _constant.WIDGETS_WITH_VALUE_LABELS.includes(widgetType);
      }
    }),
    loadData: function loadData() {
      var _this = this;

      var onInit = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      if (this.isTextTile) {
        return;
      }

      var widgetId = this.get('widgetModel.id');
      var requestData = {
        'visualization_id': widgetId
      };
      var isXAxisDateAxis = this.isXAxisDateType || this.isXAxisDateTimeType;

      if (onInit && !this.get('isPreview') && isXAxisDateAxis && !this.get('widgetModel.preferences.dateFormat')) {
        requestData.dateFormat = 'auto';
      }

      this.getVisualizationDataHelper(requestData).then(function (data) {
        _this.set('widgetData', data);

        var visualizationId = _this.get('widgetModel.id');

        if (Ember.isPresent(data)) {
          _this.onDataLoad(visualizationId);
        }
      });
    },
    getVisualizationDataHelper: function getVisualizationDataHelper(data) {
      var _this2 = this;

      this.set('isLoadingData', true);
      return this.getVisualizationData(data).then(function (response) {
        _this2.set('isRequestFailure', false);

        return response;
      }).catch(function () {
        _this2.set('isRequestFailure', true);
      }).finally(function () {
        _this2.set('isLoadingData', false);
      });
    }
  });

  _exports.default = _default;
});