define("happyfox-bi/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SLIDE_IN_DELAY_DURATION = _exports.NUMBER_TYPE_FIELDS = _exports.NO_VALUE_SET = _exports.IS_DESKTOP_APP = _exports.INPUT_DATE_FORMAT = _exports.DURATION_FORMAT_LONG = _exports.DEFAULT_TIME_FORMAT = _exports.DEFAULT_DATE_FORMAT = _exports.CURRENCY_UNIT_CODE_MAP = _exports.AUTOCOMPLETE_MIN_CHARACTER_LENGTH = _exports.API_DATE_FORMAT = void 0;
  var API_DATE_FORMAT = 'YYYY-MM-DD';
  _exports.API_DATE_FORMAT = API_DATE_FORMAT;
  var INPUT_DATE_FORMAT = 'MM-DD-YYYY';
  _exports.INPUT_DATE_FORMAT = INPUT_DATE_FORMAT;
  var DEFAULT_DATE_FORMAT = 'MMM D, YYYY';
  _exports.DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  var DEFAULT_TIME_FORMAT = 'MMM D, YYYY, h:mm A';
  _exports.DEFAULT_TIME_FORMAT = DEFAULT_TIME_FORMAT;
  var DURATION_FORMAT_LONG = "y [years], M [months], d [days], h [hrs], m [min], s [sec]";
  _exports.DURATION_FORMAT_LONG = DURATION_FORMAT_LONG;
  var AUTOCOMPLETE_MIN_CHARACTER_LENGTH = 2;
  _exports.AUTOCOMPLETE_MIN_CHARACTER_LENGTH = AUTOCOMPLETE_MIN_CHARACTER_LENGTH;
  var NO_VALUE_SET = 'NA';
  _exports.NO_VALUE_SET = NO_VALUE_SET;
  var userAgent = navigator.userAgent.toLowerCase();
  var IS_DESKTOP_APP = userAgent.indexOf(' electron/') > -1;
  _exports.IS_DESKTOP_APP = IS_DESKTOP_APP;
  var SLIDE_IN_DELAY_DURATION = 200;
  _exports.SLIDE_IN_DELAY_DURATION = SLIDE_IN_DELAY_DURATION;
  var CURRENCY_UNIT_CODE_MAP = [{
    'code': 'USD',
    'symbol': '$',
    'unit': 'dollars'
  }, {
    'code': 'EUR',
    'symbol': '€',
    'unit': 'euros'
  }, {
    'code': 'GBP',
    'symbol': '£',
    'unit': 'pounds'
  }, {
    'code': 'INR',
    'symbol': '₹',
    'unit': 'rupees'
  }];
  _exports.CURRENCY_UNIT_CODE_MAP = CURRENCY_UNIT_CODE_MAP;
  var NUMBER_TYPE_FIELDS = ['currency', 'decimal', 'int'];
  _exports.NUMBER_TYPE_FIELDS = NUMBER_TYPE_FIELDS;
});