define("happyfox-bi/visualizations/new/components/display-settings/pivot-table/component", ["exports", "happyfox-bi/visualizations/new/components/display-settings/base-chart/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var INCLUDE_PERCENTAGES_OPTIONS = [{
    id: null,
    name: '------'
  }, {
    id: 'by_total',
    name: 'By Total'
  }, {
    id: 'by_row',
    name: 'By Row'
  }, {
    id: 'by_column',
    name: 'By Column'
  }];

  var _default = _component.default.extend({
    settings: null,
    selectedPercentageOption: null,
    includePercentagesOptions: INCLUDE_PERCENTAGES_OPTIONS,
    pivotTableIncludePercentageOption: null,
    init: function init() {
      this._super.apply(this, arguments);

      var settings = this.get('settings');
      var includePercentagesOptions = this.get('includePercentagesOptions');

      if (!settings.get('showPercentages')) {
        this.set('selectedPercentageOption', includePercentagesOptions.get('firstObject'));
      } else {
        var percentageOption = includePercentagesOptions.findBy('id', this.get('pivotTableIncludePercentageOption.id'));
        this.set('selectedPercentageOption', Ember.isPresent(percentageOption) ? percentageOption : includePercentagesOptions.get('firstObject'));
      }
    },
    actions: {
      onPercentageTypeChange: function onPercentageTypeChange(percentageOption) {
        var settings = this.get('settings');
        settings.set('showPercentages', Ember.isPresent(percentageOption.id));
        this.set('selectedPercentageOption', percentageOption);
        this.onUpdatePivotTableIncludePercentage(percentageOption);
      },
      onShowTotalsChange: function onShowTotalsChange(isChecked) {
        this.set('settings.showTotals', isChecked);
        this.onUpdateDisplaySettings();
      },
      onShowAveragesChange: function onShowAveragesChange(isChecked) {
        this.set('settings.showAverages', isChecked);
        this.onUpdateDisplaySettings();
      }
    }
  });

  _exports.default = _default;
});