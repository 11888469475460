define("happyfox-bi/visualizations/components/derived-field-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    featuresService: Ember.inject.service('features'),
    actions: {
      editField: function editField() {
        this.onUpdatingDerivedField();
      }
    }
  });

  _exports.default = _default;
});