define("happyfox-bi/components/widget-tile/raw-data/link/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    value: null,
    linkText: Ember.computed.reads('value.value'),
    link: Ember.computed.reads('value.link')
  });

  _exports.default = _default;
});