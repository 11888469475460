define("happyfox-bi/components/my-settings/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TIMEZONES = void 0;
  var TIMEZONES = [{
    "name": "-11:00 - Midway Island, Samoa",
    "offset": -660
  }, {
    "name": "-10:00 - Hawaii",
    "offset": -600
  }, {
    "name": "-09:00 - Alaska",
    "offset": -540
  }, {
    "name": "-08:00 - Pacific Time (US / Canada)",
    "offset": -480
  }, {
    "name": "-07:00 - Mountain Time (US / Canada)",
    "offset": -420
  }, {
    "name": "-06:00 - Central Time (US / Canada)",
    "offset": -360
  }, {
    "name": "-05:00 - Eastern Time (US / Canada)",
    "offset": -300
  }, {
    "name": "-04:00 - Atlantic Time (Canada),",
    "offset": -240
  }, {
    "name": "-03:00 - Buenos Aires, Greenland",
    "offset": -180
  }, {
    "name": "-02:30 - Labrador, Newfoundland",
    "offset": -150
  }, {
    "name": "-02:00 - South Georgia, Uruguay",
    "offset": -120
  }, {
    "name": "-01:00 - Cape Verde, Azores",
    "offset": -60
  }, {
    "name": "+00:00 - UTC, London, Lisbon, Dublin",
    "offset": 0
  }, {
    "name": "+01:00 - Berlin, Madrid, Paris, Rome",
    "offset": 60
  }, {
    "name": "+02:00 - Athens, Cairo, Jerusalem",
    "offset": 120
  }, {
    "name": "+03:00 - Moscow, Kuwait, Baghdad",
    "offset": 180
  }, {
    "name": "+03:30 - Tehran",
    "offset": 210
  }, {
    "name": "+04:00 - Abu Dhabi, Muscat",
    "offset": 240
  }, {
    "name": "+04:30 - Kabul",
    "offset": 270
  }, {
    "name": "+05:00 - Islamabad, Tashkent",
    "offset": 300
  }, {
    "name": "+05:30 - Chennai, Mumbai, New Delhi",
    "offset": 330
  }, {
    "name": "+05:45 - Kathmandu",
    "offset": 345
  }, {
    "name": "+06:00 - Dhaka",
    "offset": 360
  }, {
    "name": "+06:30 - Rangoon",
    "offset": 390
  }, {
    "name": "+07:00 - Bangkok, Jakarta",
    "offset": 420
  }, {
    "name": "+08:00 - Hong Kong, Singapore",
    "offset": 480
  }, {
    "name": "+09:00 - Tokyo, Seoul, Osaka",
    "offset": 540
  }, {
    "name": "+09:30 - Adelaide, Darwin",
    "offset": 570
  }, {
    "name": "+10:00 - Brisbane, Canberra, Sydney",
    "offset": 600
  }, {
    "name": "+11:00 - Solomon Is., Magadan",
    "offset": 660
  }, {
    "name": "+12:00 - Auckland, Fiji, Wellington",
    "offset": 720
  }, {
    "name": "+13:00 - Nuku alofa",
    "offset": 780
  }];
  _exports.TIMEZONES = TIMEZONES;
});