define("happyfox-bi/mixins/manage-apps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    intlService: Ember.inject.service('intl'),
    requestService: Ember.inject.service('request'),
    renderForm: function renderForm(fields) {
      var _this = this;

      fields.forEach(function (field) {
        if (_this.app.get(field.key)) {
          field.set('value', _this.app.get(field.key));
        }
      });
      this.set('fields', fields);
    },
    actions: {
      saveSettings: function saveSettings() {
        var _this2 = this;

        var application = this.get('app.name');
        this.set('saveInProgress', true);
        var data = {};
        this.get('fields').forEach(function (field) {
          data[field.get('key')] = field.value;
        });
        this.requestService.patch("integrations/".concat(application, "/"), data).then(function (response) {
          if (response.redirect_url) {
            window.location = response.redirect_url;
          } else {
            _this2.toastMessageService.showMessage({
              type: 'success',
              text: 'apps.save-settings-success-message'
            });
          }
        }).catch(function (_ref) {
          var errors = _ref.errors;

          _this2.set('nonFieldErrors', errors);
        }).finally(function () {
          _this2.set('saveInProgress', false);
        });
      }
    }
  });

  _exports.default = _default;
});