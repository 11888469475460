define("happyfox-bi/utilities/string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getCookieValue = getCookieValue;
  _exports.getSafeHtml = getSafeHtml;

  /* eslint-disable no-useless-escape */
  var DOM_PURIFY_CONFIG = {
    ALLOWED_URI_REGEXP: new RegExp('^(?:(?:(?:f|ht)tps?|mailto|tel|callto|cid|xmpp|file|iwl):|[^a-z]|[a-z+.\-]+(?:[^a-z+.\-:]|$))'),
    FORBID_ATTR: ['class']
  };
  /* eslint-enable no-useless-escape */

  function getCookieValue(cookieName) {
    var match = document.cookie.match(new RegExp("".concat(cookieName, "=([^;]+)")));
    return Ember.isBlank(match) ? '' : match[1];
  }

  function getSafeHtml(html) {
    var config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    /**
     * @private
     * The default configurations for DOM Purify can be overwritten here. You can pass the config object
     * like "{ ADD_TAGS: ['iframe'] }" to include iframe tag in dom purify sanitization process.
     * By default we remove the "class" attributes from the HTML [Bugfix: 12949] and if we want to
     * override, we can pass "{ ADD_ATTR: ['class'] }" which will remove the it from the FORBID_ATTR.
     */
    var DOMPurifyConfig = {};

    _.merge(DOMPurifyConfig, config, DOM_PURIFY_CONFIG);

    var requiredAttributes = DOMPurifyConfig.ADD_ATTR || [];

    if (Ember.isPresent(requiredAttributes)) {
      for (var i = DOMPurifyConfig.FORBID_ATTR.length - 1; i >= 0; i--) {
        if (requiredAttributes.includes(DOMPurifyConfig.FORBID_ATTR[i])) {
          DOMPurifyConfig.FORBID_ATTR.removeAt(i);
        }
      }
    }

    return Ember.String.htmlSafe(DOMPurify.sanitize(html, DOMPurifyConfig));
  }
});