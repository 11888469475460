define("happyfox-bi/services/fullscreen", ["exports", "happyfox-bi/utilities/fullscreen"], function (_exports, _fullscreen) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    isCurrentlyFullscreen: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.setupFullscreenChangeEvent();
    },
    setupFullscreenChangeEvent: function setupFullscreenChangeEvent() {
      var _this = this;

      var eventNames = ['onfullscreenchange', 'onwebkitfullscreenchange', 'onmozfullscreenchange', 'MSFullscreenChange'];
      eventNames.forEach(function (eventName) {
        document[eventName] = function () {
          if ((0, _fullscreen.getFullscreenElement)()) {
            _this.set('isCurrentlyFullscreen', true);
          } else {
            _this.set('isCurrentlyFullscreen', false);
          }
        };
      });
    },
    toggleFullscreen: function toggleFullscreen(selector) {
      if (this.get('isCurrentlyFullscreen')) {
        (0, _fullscreen.exitFullscreen)();
      } else {
        (0, _fullscreen.requestFullscreen)(selector);
      }
    }
  });

  _exports.default = _default;
});