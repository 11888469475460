define("happyfox-bi/visualization-examples/mock-data/page-5", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PAGE_5_VISUALIZATION_DATA = _exports.PAGE_5 = void 0;
  var PAGE_5 = {
    id: 5,
    name: 'EMEA: RMA Report',
    order: 5,
    report: 1,
    created_by: 1,
    created_at: '2019-06-17T08:08:33Z',
    updated_at: '2019-06-18T11:20:30Z',
    visualizations: [{
      id: 24,
      name: 'RMA comparision for products',
      page: 5,
      display_type: 'multi_line_chart',
      display_settings: {
        size: 'square-m',
        x_axis: {
          key: 'date',
          label: null
        },
        y_axis: {
          label: 'Tickets count',
          group_by: 'product',
          value: 'tickets'
        }
      },
      preferences: {
        filters: {
          match_all: []
        },
        date_range: 'last_month'
      },
      settings: {
        fields: [{
          'name': 'date',
          'type': 'dataset_field',
          'dataset': 1,
          'dataset_field': 12,
          'data_type': 'timestamp',
          'format': 'date',
          'is_editable': false
        }, {
          'name': 'product',
          'type': 'dataset_field',
          'dataset': 1,
          'dataset_field': 13,
          'data_type': 'text',
          'is_editable': false
        }, {
          'name': 'tickets',
          'type': 'aggregation',
          'aggregation_type': 'count',
          'dataset': 1,
          'dataset_field': 14,
          'data_type': 'decimal',
          'is_editable': false
        }],
        query: {
          rows: [],
          columns: [],
          values: [],
          filters: {
            match_all: []
          },
          sort_by: []
        }
      }
    }],
    layout: {
      'items': [{
        'visualization_id': 24,
        'width': 8,
        'height': 8,
        'x': 8,
        'y': 8
      }]
    }
  };
  _exports.PAGE_5 = PAGE_5;
  var PAGE_5_VISUALIZATION_DATA = [{
    id: 24,
    data: [{
      'date': '2018-11-01T00:00:00Z',
      'product': 'Acme Super 45',
      'tickets': 300
    }, {
      'date': '2018-11-01T00:00:00Z',
      'product': 'Acme Super 65',
      'tickets': 500
    }, {
      'date': '2018-11-02T00:00:00Z',
      'product': 'Acme Super 45',
      'tickets': 400
    }, {
      'date': '2018-11-02T00:00:00Z',
      'product': 'Acme Super 65',
      'tickets': 800
    }, {
      'date': '2018-11-03T00:00:00Z',
      'product': 'Acme Super 45',
      'tickets': 500
    }, {
      'date': '2018-11-03T00:00:00Z',
      'product': 'Acme Super 65',
      'tickets': 200
    }, {
      'date': '2018-11-04T00:00:00Z',
      'product': 'Acme Super 45',
      'tickets': 250
    }, {
      'date': '2018-11-04T00:00:00Z',
      'product': 'Acme Super 65',
      'tickets': 450
    }, {
      'date': '2018-11-05T00:00:00Z',
      'product': 'Acme Super 45',
      'tickets': 360
    }, {
      'date': '2018-11-05T00:00:00Z',
      'product': 'Acme Super 65',
      'tickets': 220
    }, {
      'date': '2018-11-06T00:00:00Z',
      'product': 'Acme Super 45',
      'tickets': 230
    }, {
      'date': '2018-11-06T00:00:00Z',
      'product': 'Acme Super 65',
      'tickets': 310
    }, {
      'date': '2018-11-07T00:00:00Z',
      'product': null,
      'tickets': 600
    }, {
      'date': '2018-11-07T00:00:00Z',
      'product': 'Acme Super 65',
      'tickets': 600
    }, {
      'date': '2018-11-08T00:00:00Z',
      'product': 'Acme Super 45',
      'tickets': 380
    }, {
      'date': '2018-11-08T00:00:00Z',
      'product': 'Acme Super 65',
      'tickets': 260
    }, {
      'date': '2018-11-09T00:00:00Z',
      'product': 'Acme Super 45',
      'tickets': 520
    }, {
      'date': '2018-11-09T00:00:00Z',
      'product': 'Acme Super 65',
      'tickets': 690
    }, {
      'date': '2018-11-10T00:00:00Z',
      'product': 'Acme Super 45',
      'tickets': 390
    }, {
      'date': '2018-11-10T00:00:00Z',
      'product': 'Acme Super 65',
      'tickets': 590
    }]
  }];
  _exports.PAGE_5_VISUALIZATION_DATA = PAGE_5_VISUALIZATION_DATA;
});