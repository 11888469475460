define("happyfox-bi/form-field-models/number-field", ["exports", "happyfox-bi/form-field-models/base-field"], function (_exports, _baseField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseField.default.extend({
    value: '',
    _initialValue: '',
    isNumber: true,
    minValue: null,
    maxValue: null,
    allowDecimal: false,
    unitLabel: null,
    init: function init() {
      this._super();

      var minValue = this.get('minValue');
      var maxValue = this.get('maxValue');

      if (minValue && maxValue && minValue > maxValue) {
        (false && !(this.get('intlService').lookup('validation.invalid-number-configuration')) && Ember.assert(false, this.get('intlService').lookup('validation.invalid-number-configuration')));
      }
    },
    isDirty: Ember.computed('value', '_initialValue', {
      get: function get() {
        var value = this.get('value');
        var initialValue = this.get('_initialValue'); // to avoid parsing null. parseInt(null) give NaN

        return !_.isEqual(Ember.isPresent(initialValue) ? parseInt(initialValue) : initialValue, Ember.isPresent(value) ? parseInt(value) : value);
      }
    }),
    validate: function validate() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this._super().then(function () {
          // number check
          var value = _this.get('value');

          if (Ember.isPresent(value)) {
            var minValue = _this.get('minValue');

            var maxValue = _this.get('maxValue'); // HACK: intl is not skipping the value if it is `0` hence converting to string.


            minValue = minValue === 0 ? minValue.toString() : minValue;
            maxValue = maxValue === 0 ? maxValue.toString() : maxValue;

            if (isNaN(value)) {
              _this.addError(_this.get('intlService').lookup('validation.invalid-number-message'));

              reject();
            }

            if (Ember.isPresent(minValue) && value < minValue) {
              if (Ember.isPresent(maxValue)) {
                _this.addError(_this.get('intlService').lookupWithParameters('validation.number-{min}-{max}-value-limit', {
                  min: minValue,
                  max: maxValue
                }));
              } else {
                _this.addError(_this.get('intlService').lookupWithParameters('validation.number-{min}-value-limit', {
                  min: minValue
                }));
              }

              reject();
            }

            if (Ember.isPresent(maxValue) && value > maxValue) {
              if (Ember.isPresent(minValue)) {
                _this.addError(_this.get('intlService').lookupWithParameters('validation.number-{min}-{max}-value-limit', {
                  min: minValue,
                  max: maxValue
                }));
              } else {
                _this.addError(_this.get('intlService').lookupWithParameters('validation.number-{max}-value-limit', {
                  max: maxValue
                }));
              }

              reject();
            }

            if (!_this.get('allowDecimal') && value % 1 !== 0) {
              _this.addError(_this.get('intlService').lookup('validation.decimal-not-allowed'));

              reject();
            }
          }

          _this.set('_cleanValue', Ember.isPresent(value) ? value : null);

          resolve();
        }).catch(function () {
          reject();
        });
      });
    },
    clear: function clear() {
      this.setProperties({
        value: '',
        _initialValue: ''
      });
    }
  });

  _exports.default = _default;
});