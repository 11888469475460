define("happyfox-bi/components/color-picker/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intlService: Ember.inject.service('intl'),
    classNames: ['color-picker'],
    containerClassName: '',
    replacerClassName: '',
    allowEmpty: false,
    ariaLabel: '',
    showInput: true,
    showAlpha: false,
    showInitial: false,
    showButtons: false,
    color: '#FF9900',
    disabled: false,
    preferredFormat: 'hex',
    onChange: null,
    colorPickerElement: null,
    includeHexInitial: false,
    didInsertElement: function didInsertElement() {
      var $element = this.$().find('.js-color-picker');
      var options = {
        color: this.get('color'),
        containerClassName: this.get('containerClassName'),
        replacerClassName: this.get('replacerClassName'),
        allowEmpty: this.get('allowEmpty'),
        disabled: this.get('disabled'),
        showInput: this.get('showInput'),
        showAlpha: this.get('showAlpha'),
        showInitial: this.get('showInitial'),
        showButtons: this.get('showButtons'),
        preferredFormat: this.get('preferredFormat'),
        appendTo: this.$(),
        change: Ember.run.bind(this, this.updateColor),
        move: Ember.run.bind(this, this.updateColor)
      };
      $element.spectrum(options);
      this.$().find('.sp-input').prop('placeholder', this.get('intlService').lookup('color-picker-input-placeholder'));
      this.$().find('.sp-input').attr('aria-label', this.get('ariaLabel'));
      this.set('colorPickerElement', $element);

      if (!this.get('color')) {
        this.set('color', 'FF9900');
      }
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this.get('colorPickerElement').spectrum('set', this.get('color'));
    },
    updateColor: function updateColor(newColor) {
      if (!this.get('isDestroyed')) {
        var color;

        if (this.get('includeHexInitial')) {
          color = newColor.toString('hex6');
          this.set('color', color);
        } else {
          color = newColor.toString('hex6').split('#');
          this.set('color', color);
        }

        if (this.onChange) {
          this.onChange(color);
        }
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.get('colorPickerElement').spectrum('destroy');
    }
  });

  _exports.default = _default;
});