define("happyfox-bi/components/life-cycle-chart/component", ["exports", "happyfox-bi/components/base-chart/component", "@amcharts/amcharts4/core", "@amcharts/amcharts4/charts"], function (_exports, _component, am4core, am4charts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var chartContainer = this.get('chartContainer');
      var chart = am4core.create(chartContainer, am4charts.SankeyDiagram);
      chart.links.template.fillOpacity = 0.4;
      chart.data = this.get('data');

      var _this$get = this.get('config'),
          xAxisKey = _this$get.xAxis.key,
          valueKey = _this$get.yAxis.value,
          levels = _this$get.levels;

      var isValueTimeType = this.isFieldTimeType(valueKey);
      var isDurationValue = this.canFormatTimeAsDuration(valueKey);

      if (isDurationValue) {
        this.setupDurationFormatter(chart, valueKey);
      }

      if (isValueTimeType) {
        chart.links.template.adapter.add('tooltipText', function (text, target) {
          var tooltipDataItem = target.tooltipDataItem;

          if (tooltipDataItem) {
            return "{fromName}\u2192{toName}: [/]".concat(_this.formatValueByTime(tooltipDataItem.value, valueKey));
          }
        });
      }

      var hoverState = chart.links.template.states.create("hover");
      hoverState.properties.fillOpacity = 0.9;
      chart.dataFields.fromName = xAxisKey;
      chart.dataFields.toName = this.get('config.yAxis.key');
      chart.dataFields.value = valueKey;
      chart.dataFields.levels = levels;
      var isValueCurrencyType = this.isFieldCurrencyType(valueKey);

      if (isValueCurrencyType) {
        this.setElementNumberFormatAsCurrency(chart, valueKey);
      } // for right-most label to fit


      chart.paddingRight = 50;
      chart.nodes.template.nameLabel.label.width = 50;
      chart.nodes.template.nameLabel.label.truncate = false;
      chart.nodes.template.nameLabel.label.wrap = true; // Legend

      chart.legend = new am4charts.Legend();
      chart.legend.useDefaultMarker = true;
      chart.legend.position = 'top';
      var marker = chart.legend.markers.template.children.getIndex(0);
      marker.cornerRadius(10, 10, 10, 10);
      var markerTemplate = chart.legend.markers.template;
      markerTemplate.width = 10;
      markerTemplate.height = 10;
      this.addExportOption(chart);
      this.addAnnotationsOption(chart);
      this.chart = chart;
    }
  });

  _exports.default = _default;
});