define("happyfox-bi/components/add-visualization-field/settings-field/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['field-list_item', 'mod-settings-field'],
    classNameBindings: ['isSelected:is-selected'],
    settingsField: null,
    selectedSettingsField: null,
    isUsedForSort: false,
    isSelected: Ember.computed('settingsField.id', 'selectedSettingsField.id', {
      get: function get() {
        return this.get('selectedSettingsField') && this.get('selectedSettingsField.name') === this.get('settingsField.name');
      }
    }),
    click: function click(e) {
      e.preventDefault();

      if (!this.get('isDatasetTypeField')) {
        var settingsField = this.get('settingsField');
        var fieldType = settingsField.get('type');

        if (this.get('isUsedForSort') || fieldType === 'formula_field' || fieldType === 'aggregation_formula') {
          this.onDoubleClick(settingsField);
        } else {
          this.onClick(settingsField);
        }
      }
    },
    doubleClick: function doubleClick(e) {
      e.preventDefault();

      if (!this.get('isDatasetTypeField')) {
        this.onDoubleClick(this.get('settingsField'));
      }
    },
    actions: {
      onClick: function onClick(field) {
        if (this.get('isDatasetTypeField')) {
          this.onClick(field, this.get('settingsField'));
        } else {
          this.onClick(field);
        }
      },
      onDoubleClick: function onDoubleClick(field) {
        if (this.get('isDatasetTypeField')) {
          this.onDoubleClick(field, this.get('settingsField'));
        } else {
          this.onDoubleClick(field);
        }
      }
    }
  });

  _exports.default = _default;
});