define("happyfox-bi/components/auto-complete/local-and-remote/multiple/before-options/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['auto-complete_local-remote_before-options']
  });

  _exports.default = _default;
});