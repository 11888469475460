define("happyfox-bi/application/route", ["exports", "happyfox-bi/utilities/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var UNAUTHENTICATED_ROUTES = ['reset-password', 'register'];

  var _default = Ember.Route.extend({
    intlService: Ember.inject.service('intl'),
    dataService: Ember.inject.service('data'),
    profileService: Ember.inject.service('profile'),
    applicationService: Ember.inject.service('application'),
    isSidePaneOpen: Ember.computed.reads('applicationService.isSidePaneOpen'),
    accountInfoService: Ember.inject.service('account-info'),
    beforeModel: function beforeModel(transition) {
      var _this = this;

      this.get('intlService').setLocale('en');
      var targetRoute = transition.to.name;

      if (UNAUTHENTICATED_ROUTES.includes(targetRoute)) {
        this.get('profileService').set('isUserAuthenticated', false);
      } else {
        var profileDetailsPromise = this.get('dataService').getProfileDetails();
        var reportsPromise = this.get('dataService').getAllReports();
        var accountInfoPromise = this.get('dataService').fetchAccountInfo();
        var promises = {
          profileDetailsPromise: profileDetailsPromise,
          reportsPromise: reportsPromise,
          accountInfoPromise: accountInfoPromise
        };
        return Ember.RSVP.hash(promises).then(function (hashData) {
          _this.get('profileService').set('isUserAuthenticated', true);

          var profileData = (0, _object.camelizeObject)(hashData.profileDetailsPromise);

          _this.get('profileService').setProfile(profileData);

          _this.get('profileService').setMomentTimezone(profileData);

          _this.get('accountInfoService').set('accountInfo', hashData.accountInfoPromise);

          _this.get('profileService').setUpChartsTheme();
        }).catch(function () {
          _this.get('profileService').set('isUserAuthenticated', false);

          if (targetRoute === 'internal-login') {
            _this.transitionTo('internal-login');
          } else {
            _this.transitionTo('login');
          }
        });
      }
    },
    actions: {
      refreshApplication: function refreshApplication() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});