define("happyfox-bi/components/form-field-input/date-time/component", ["exports", "happyfox-bi/components/form-field-input/base", "happyfox-bi/utilities/time", "happyfox-bi/utilities/date"], function (_exports, _base, _time, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    minDateTime: Ember.computed('field.minDateTime', {
      get: function get() {
        var minDateTime = this.get('field.minDateTime');

        if (minDateTime) {
          // to convert minDateTime to staff timezone.
          var convertedMinDateTime = (0, _time.convertMomentToDateTime)(moment(minDateTime)); // Setting it to Start Of Day. If not, datepicker is recursively trying to set min date

          convertedMinDateTime.setHours(0, 0, 0, 0);
          return convertedMinDateTime;
        }
      }
    }),
    setDefaultValuesForDateAndTime: function setDefaultValuesForDateAndTime() {
      var isTriggeredOnDateSelection = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var field = this.field;
      var hourField = field.get('hourField');
      var minuteField = field.get('minuteField');

      if (Ember.isBlank(field.get('dateValue'))) {
        field.set('dateValue', (0, _date.convertDateToApiFormat)((0, _date.getCurrentDate)()));
      }

      if (Ember.isBlank(hourField.get('value'))) {
        hourField.set('value', hourField.get('choices')[10]); // sets hour to 11
      }

      if (Ember.isBlank(minuteField.get('value'))) {
        /**
         * Set the mins to 59 (End of the day 11:59 PM) only for selectDefaultTimeOnDateSelect.
         * If this is called when just hours value is selected, set the mins to 00.
         * */
        minuteField.set('value', minuteField.get('choices')[isTriggeredOnDateSelection ? 59 : 0]);
      }

      if (Ember.isBlank(field.get('meridiem'))) {
        field.set('meridiem', 'PM');
      }
    },
    actions: {
      onDateSelect: function onDateSelect() {
        var field = this.get('field');

        if (field.get('selectDefaultTimeOnDateSelect')) {
          this.setDefaultValuesForDateAndTime(true);
        }

        this.send('valueChanged');
      },
      setMeridiem: function setMeridiem(value) {
        var field = this.get('field');
        field.set('meridiem', value);
        this.setDefaultValuesForDateAndTime();
        this.send('valueChanged');
      },
      timeFieldChanged: function timeFieldChanged() {
        this.setDefaultValuesForDateAndTime();
        this.send('valueChanged');
      },
      valueChanged: function valueChanged() {
        var _this = this;

        this.field.validate().then(function () {
          if (_this.onChange) {
            _this.onChange(_this.field.getCleanValue());
          }
        });
      }
    }
  });

  _exports.default = _default;
});