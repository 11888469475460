define("happyfox-bi/components/form-field-input/toggle/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    field: null,
    classNames: ['toggle'],
    showTooltip: Ember.computed.and('field.showDisabledMessage', 'field.isDisabled'),
    classNameBindings: ['showTooltip:tooltipped', 'showTooltip:tooltipped-top-right'],
    attributeBindings: ['aria-label'],
    'aria-label': Ember.computed.reads('field.disabledMessage'),
    actions: {
      onChange: function onChange() {
        this.toggleProperty('field.value');

        if (this.onChange) {
          this.onChange(this.get('field.value'));
        }
      }
    }
  });

  _exports.default = _default;
});