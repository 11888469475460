define("happyfox-bi/data-sources/details/data-sets/import/file-meta/edit-field/component", ["exports", "happyfox-bi/components/my-settings/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DATA_TYPES = [{
    id: 'int',
    name: 'Number'
  }, {
    id: 'decimal',
    name: 'Decimal'
  }, {
    id: 'text',
    name: 'Text'
  }, {
    id: 'boolean',
    name: 'Boolean'
  }, {
    id: 'date',
    name: 'Date'
  }, {
    id: 'time',
    name: 'Time'
  }, {
    id: 'timestamp',
    name: 'Timestamp'
  }];
  var TIME_UNITS = [{
    id: 'seconds',
    name: 'Seconds'
  }, {
    id: 'minutes',
    name: 'Minutes'
  }, {
    id: 'hours',
    name: 'Hours'
  }, {
    id: 'days',
    name: 'Days'
  }];

  var _default = Ember.Component.extend({
    tagName: 'tr',
    classNameBindings: ['hasErrors'],
    field: null,
    originalDataType: null,
    dataTypeOptions: DATA_TYPES,
    timeUnitOptions: TIME_UNITS,
    timezoneOptions: _constants.TIMEZONES,
    selectedTimezone: null,
    selectedTimeUnit: null,
    selectedDataType: null,
    isTimestampType: Ember.computed.equal('field.dataType', 'timestamp'),
    isTimeType: Ember.computed.equal('field.dataType', 'time'),
    hasErrors: Ember.computed.notEmpty('field.errors'),
    init: function init() {
      this._super.apply(this, arguments);

      var originalDataType = this.get('field.dataType');
      this.set('originalDataType', originalDataType);
      var dataTypeOptions = this.get('dataTypeOptions');
      var dataType = dataTypeOptions.findBy('id', originalDataType);
      this.set('selectedDataType', dataType);

      if (originalDataType == 'timestamp') {
        var importTimezoneOffset = this.get('field.importTimezoneOffset');
        var timezone = this.get('timezoneOptions').findBy('offset', importTimezoneOffset);
        this.send('onTimezoneChange', timezone);
      } else if (originalDataType == 'time') {
        var importTimeUnit = this.get('field.importTimeUnit');
        var timeUnit = this.get('timeUnitOptions').findBy('id', importTimeUnit);
        this.send('onTimeUnitChange', timeUnit);
      }
    },
    actions: {
      onTimezoneChange: function onTimezoneChange(timezone) {
        this.set('selectedTimezone', timezone);
        var timezoneOffset = timezone ? timezone.offset : null;
        this.get('field').set('importTimezoneOffset', timezoneOffset);
      },
      onTimeUnitChange: function onTimeUnitChange(timeUnit) {
        this.set('selectedTimeUnit', timeUnit);
        timeUnit = timeUnit ? timeUnit.id : null;
        this.get('field').set('importTimeUnit', timeUnit);
      },
      onDataTypeChange: function onDataTypeChange(dataType) {
        this.set('selectedDataType', dataType);
        var field = this.get('field');
        field.set('dataType', dataType.id);

        if (dataType.id === 'timestamp') {
          var utc = this.get('timezoneOptions').findBy('offset', 0);
          this.send('onTimezoneChange', utc);
        } else if (dataType.id === 'time') {
          var secondsOption = this.get('timeUnitOptions').findBy('id', 'seconds');
          this.send('onTimeUnitChange', secondsOption);
        } else {
          this.send('onTimezoneChange', null);
          this.send('onTimeUnitChange', null);
        }
      }
    }
  });

  _exports.default = _default;
});