define("happyfox-bi/components/form-field-input/multi-choice-dropdown/option/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['multi-choice-dropdown_option'],
    option: null,
    'data-test-id': Ember.computed('option', {
      get: function get() {
        return "entity-dropdown_option_".concat(this.get('option').get(this.get('choiceLabelKey')).dasherize());
      }
    }),
    click: function click() {
      this.toggleProperty('option.isSelected');
      this.onOptionSelection(this.get('option'));
    }
  });

  _exports.default = _default;
});