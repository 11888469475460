define("happyfox-bi/services/tab-visibility", ["exports", "happyfox-bi/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    hiddenPropertyName: 'null',
    visibilityChangeEventName: 'null',
    isHidden: false,
    init: function init() {
      this._super.apply(this, arguments); // For locally run test cases not to fail on switching between tabs.


      if (_environment.default.environment !== 'test') {
        var hiddenPropertyName, visibilityChangeEventName;

        if (typeof document.hidden !== 'undefined') {
          // Opera 12.10 and Firefox 18 and later support
          hiddenPropertyName = 'hidden';
          visibilityChangeEventName = 'visibilitychange';
        } else if (typeof document.msHidden !== 'undefined') {
          hiddenPropertyName = 'msHidden';
          visibilityChangeEventName = 'msvisibilitychange';
        } else if (typeof document.webkitHidden !== 'undefined') {
          hiddenPropertyName = 'webkitHidden';
          visibilityChangeEventName = 'webkitvisibilitychange';
        }

        this.setProperties({
          hiddenPropertyName: hiddenPropertyName,
          visibilityChangeEventName: visibilityChangeEventName
        }); // Warn if the browser doesn't support addEventListener or the Page Visibility API

        if (typeof document.addEventListener === 'undefined' || typeof document.hidden === 'undefined') {
          /* eslint-disable no-console */
          console.warn('This browser does not support addEventListener or Page Visibility API');
          /* eslint-enable no-console */
        } else {
          // Handle page visibility change
          document.addEventListener(visibilityChangeEventName, this.handleVisibilityChange.bind(this));
        }
      }
    },
    handleVisibilityChange: function handleVisibilityChange() {
      var hiddenPropertyName = this.get('hiddenPropertyName');
      var isHidden = document[hiddenPropertyName];
      this.set('isHidden', isHidden);
    }
  });

  _exports.default = _default;
});