define("happyfox-bi/utilities/run-loop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.runLater = runLater;

  function runLater(context, method, waitTime) {
    // if (config.environment === 'test') {
    //   run.bind(context, method)(); // binds the context and runs immediately.
    // } else {
    //   run.later(context, method, waitTime);
    // }
    Ember.run.later(context, method, waitTime);
  }
});