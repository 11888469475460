define("happyfox-bi/components/data-table-new/row/component", ["exports", "ember-light-table/components/lt-row"], function (_exports, _ltRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ltRow.default.extend({
    classNameBindings: ['isFrozen:hf-is-frozen'],
    isFrozen: Ember.computed.reads('row.content.isDeleting'),
    'data-test-id': Ember.computed('row.content.id', {
      get: function get() {
        return "table-row-id-".concat(this.get('row.content.id'));
      }
    })
  });

  _exports.default = _default;
});