define("happyfox-bi/business-hours/components/add/form-model", ["exports", "happyfox-bi/form-models/base-form", "happyfox-bi/business-hours/components/add/form-field-models/day-field", "happyfox-bi/business-hours/components/add/form-field-models/week-field", "happyfox-bi/components/my-settings/constants", "happyfox-bi/business-hours/components/add/constants"], function (_exports, _baseForm, _dayField, _weekField, _constants, _constants2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseForm.default.extend({
    dataService: Ember.inject.service('data'),
    intlService: Ember.inject.service('intl'),
    profileService: Ember.inject.service('profile'),
    isAdd: false,
    basicFieldKeys: null,
    scrollableContainerSelector: '.js-business-hours-form_content',
    init: function init() {
      this._super.apply(this, arguments);

      var rawFields = Ember.A();
      var intlService = this.get('intlService');
      var nameField = Ember.Object.create({
        key: 'name',
        isText: true,
        isMandatory: true,
        label: intlService.lookup('name')
      });
      var descriptionField = Ember.Object.create({
        key: 'description',
        isTextarea: true,
        rowsCount: 4,
        isMandatory: false,
        label: intlService.lookup('description')
      });
      var timeZoneField = Ember.Object.create({
        key: 'timezoneOffset',
        isChoice: true,
        canSetDefaultChoiceValue: false,
        isMandatory: true,
        choices: _constants.TIMEZONES,
        choiceValueKey: 'offset',
        choiceLabelKey: 'name',
        placeholder: intlService.lookup('select-timezone'),
        label: intlService.lookup('timezone')
      });
      var dataSourcesField = Ember.Object.create({
        key: 'dataSources',
        isLocalMultiChoiceDropdown: true,
        isMandatory: true,
        choiceLabelKey: 'name',
        choiceValueKey: 'id',
        choices: Ember.A(),
        placeholder: intlService.lookup('select-data-sources'),
        label: intlService.lookup('data-sources')
      });
      var workDaysField = Ember.Object.create({
        key: 'workDays',
        isArray: true,
        isMandatory: true,
        fieldClass: _weekField.default,
        childFieldMetaInfo: Ember.Object.create({
          key: 'day',
          isObject: true,
          fieldClass: _dayField.default,
          childFieldsMetaInfo: Ember.A([Ember.Object.create({
            key: 'active',
            isMandatory: true,
            isCheckBox: true
          }), Ember.Object.create({
            key: 'weekday',
            isNumber: true
          }), Ember.Object.create({
            key: 'startHour',
            isChoice: true,
            choices: this.getWorkHours(),
            choiceLabelKey: null
          }), Ember.Object.create({
            key: 'startMinute',
            isChoice: true,
            choices: this.getWorkMinutes(),
            choiceLabelKey: null
          }), Ember.Object.create({
            key: 'endHour',
            isChoice: true,
            choices: this.getWorkHours(),
            choiceLabelKey: null
          }), Ember.Object.create({
            key: 'endMinute',
            isChoice: true,
            choices: this.getWorkMinutes(),
            choiceLabelKey: null
          })])
        })
      });
      var holidaysField = Ember.Object.create({
        key: 'holidays',
        isArray: true,
        childFieldMetaInfo: Ember.Object.create({
          key: 'day',
          isObject: true,
          childFieldsMetaInfo: Ember.A([Ember.Object.create({
            key: 'name',
            isMandatory: true,
            isText: true,
            maxCharacterLimit: 1024,
            placeholder: intlService.lookup('name')
          }), Ember.Object.create({
            key: 'date',
            isMandatory: true,
            isDate: true,
            placeholder: intlService.lookup('select-date')
          })])
        })
      });
      rawFields.addObjects([nameField, descriptionField, timeZoneField, dataSourcesField, workDaysField, holidaysField]);
      this.setProperties({
        fields: this._createFieldModels(rawFields),
        basicFieldKeys: rawFields.mapBy('key')
      });

      if (!this.isAdd) {
        this._copyFromDataSource();
      } else {
        this.setDefaultValues();
      }

      this._super.apply(this, arguments);
    },
    setDefaultValues: function setDefaultValues() {
      var timezoneOffset = this.model.timezoneOffset;
      var profileTimezone = this.profileService.profile.timezone;

      var defaultTimezone = _constants.TIMEZONES.findBy('offset', profileTimezone.offset);

      timezoneOffset.set('value', defaultTimezone);

      var workDayValues = _.cloneDeep(_constants2.WORK_DAY_DEFAULT_VALUES);

      this.createWorkDaysChildFields(workDayValues);
    },
    createWorkDaysChildFields: function createWorkDaysChildFields(workDaysValues) {
      workDaysValues.forEach(function (workDay, index) {
        var startTime = workDay.start.split(':');
        var endTime = workDay.end.split(':');
        workDay.startHour = startTime[0];
        workDay.startMinute = startTime[1];
        workDay.endHour = endTime[0];
        workDay.endMinute = endTime[1];
        delete workDay.start;
        delete workDay.end;
        workDaysValues[index] = Ember.Object.create(workDay);
      });
      var workDaysField = this.model.workDays;
      workDaysField.set('value', workDaysValues);
      /*
        In case of 'Add', the default workDays model must be dirty. Hence not setting initialValue.
        ArrayField.createChildFields (childFieldValues, isClone, setInitialValue)
      */

      if (this.isAdd) {
        workDaysField.createChildFields(null, false, false);
      } else {
        workDaysField.createChildFields();
      }
    },
    _copyFromDataSource: function _copyFromDataSource() {
      this._super.apply(this, arguments);

      var model = this.model;

      var workDayValues = _.cloneDeep(model.get('workDays.value'));

      this.createWorkDaysChildFields(workDayValues);
      var holidaysField = model.holidays; // convert holiday value to list of ember objects

      var holidaysValue = holidaysField.get('value').map(function (holiday) {
        return Ember.Object.create(holiday);
      });
      holidaysField.set('value', holidaysValue);
      holidaysField.createChildFields(null, false);
    },
    addHolidayField: function addHolidayField() {
      this.model.holidays.addNewChildField(null);
    },
    removeHolidayField: function removeHolidayField(holidayField) {
      this.model.holidays.removeChildField(holidayField);
    },
    getWorkDaysPayload: function getWorkDaysPayload(workDaysFieldValue) {
      workDaysFieldValue.forEach(function (cleanValue, index) {
        cleanValue.start = "".concat(cleanValue.startHour, ":").concat(cleanValue.startMinute);
        cleanValue.end = "".concat(cleanValue.endHour, ":").concat(cleanValue.endMinute);
        cleanValue.weekday = index; // deleting the following attr because its not needed

        delete cleanValue.startHour;
        delete cleanValue.startMinute;
        delete cleanValue.endHour;
        delete cleanValue.endMinute;
      });
      return workDaysFieldValue;
    },
    generatePayload: function generatePayload() {
      var payloadData = this.getModifiedFields();
      var dataSources = payloadData.dataSources,
          workDays = payloadData.workDays;

      if (dataSources) {
        payloadData.dataSources = dataSources.map(function (id) {
          return Number(id);
        });
      }

      if (Ember.isPresent(workDays)) {
        payloadData.workDays = this.getWorkDaysPayload(workDays);
      }

      return payloadData;
    },
    submit: function submit() {
      var _this = this;

      this.set('errors', Ember.A());
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.validate().then(function () {
          if (!_this.isAdd) {
            _this._copyToDataSource();
          }

          var payload = _this.generatePayload();

          resolve(payload);
        }).catch(function () {
          _this.scrollToError();

          reject();
        });
      });
    },
    getWorkHours: function getWorkHours() {
      var startHour = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      var workHours = Ember.A();

      for (var i = parseInt(startHour); i < 24; i++) {
        i = i.toString();

        if (i.length === 1) {
          i = '0'.concat(i);
        }

        workHours.pushObject(i);
      }

      return workHours;
    },
    getWorkMinutes: function getWorkMinutes() {
      var startMinute = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      var workMinutes = Ember.A();

      for (var i = parseInt(startMinute); i < 60; i++) {
        i = i.toString();

        if (i.length === 1) {
          i = '0'.concat(i);
        }

        workMinutes.pushObject(i);
      }

      return workMinutes;
    }
  });

  _exports.default = _default;
});