define("happyfox-bi/application/side-pane/page-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uBnSimy5",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,4],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],null,[[\"class\",\"route\",\"models\"],[\"navigation-pane_item\",\"reports.details.pages.details\",[30,[36,2],[[35,1],[35,0,[\"id\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"a\"],[14,0,\"navigation-pane_item_text\"],[12],[1,[35,0,[\"name\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"page\",\"reportId\",\"array\",\"link-to\",\"if\"]}",
    "meta": {
      "moduleName": "happyfox-bi/application/side-pane/page-item/template.hbs"
    }
  });

  _exports.default = _default;
});