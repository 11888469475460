define("happyfox-bi/visualizations/new/components/top-n-filter/component", ["exports", "happyfox-bi/visualizations/new/components/top-n-filter/constant"], function (_exports, _constant) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    filter: null,
    filterTypes: _constant.TOP_N_FILTER_TYPES,
    settingsFields: null,
    selectedField: null,
    selectedAggregationField: null,
    topNValue: null,
    selectedFilterType: null,
    showAddField: false,
    showAddAggregateField: false,
    canAutoPopulateTopNFields: true,
    // To track if user has interacted with Top N
    isTopNEnabled: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.initForm();
    },
    initForm: function initForm() {
      var settingsFields = this.get('settingsFields');
      var filter = Ember.isPresent(this.get('filter')) ? this.get('filter') : {};
      var selectedFilterType = this.get('filterTypes').findBy('id', filter.type) || this.get('filterTypes').findBy('id', 'top');
      var selectedField = settingsFields.findBy('name', filter.field ? filter.field.name : null);

      if (Ember.isPresent(selectedField)) {
        this.set('isTopNEnabled', true);
      }

      var selectedAggregationField = settingsFields.findBy('name', filter.aggregateField ? filter.aggregateField.name : null);
      this.setProperties({
        selectedField: selectedField,
        selectedAggregationField: selectedAggregationField,
        selectedFilterType: selectedFilterType,
        topNValue: filter.n || 10
      });
    },
    setTopNValue: function setTopNValue(value) {
      this.set('topNValue', value);
      this.set('canAutoPopulateTopNFields', false);
      this.addTopNFilter();
    },
    addTopNFilter: function addTopNFilter() {
      var filter = Ember.Object.create({
        n: this.get('topNValue'),
        filterType: this.get('selectedFilterType.id'),
        field: this.get('selectedField'),
        aggregateField: this.get('selectedAggregationField')
      });
      var filterProperties = Object.keys(filter);
      var canApplyTopN = filterProperties.every(function (property) {
        return Ember.isPresent(filter[property]);
      });

      if (canApplyTopN) {
        this.applyFilter(filter);
      }
    },
    resetTopNFilter: function resetTopNFilter() {
      this.setProperties({
        selectedField: this.get('selectedField'),
        selectedAggregationField: null,
        selectedFilterType: this.get('filterTypes').findBy('id', 'top'),
        topNValue: 10
      });
    },
    actions: {
      onTopNFilterToggle: function onTopNFilterToggle(isChecked) {
        this.set('isTopNEnabled', isChecked);

        if (!isChecked) {
          this.set('selectedField', null);
          this.resetTopNFilter();
          this.applyFilter(null);
        } else {
          var filter = this.populateTopNFilter();

          if (filter.field && filter.aggregateField && this.get('canAutoPopulateTopNFields')) {
            this.set('selectedField', filter.field);
            this.set('selectedAggregationField', filter.aggregateField);
            this.addTopNFilter();
          }
        }
      },
      onFilterTypeChange: function onFilterTypeChange(type) {
        this.set('selectedFilterType', type);
        this.set('canAutoPopulateTopNFields', false);
        this.addTopNFilter();
      },
      onAggregateFieldChange: function onAggregateFieldChange(field) {
        this.get('settingsFields').addObject(field);
        this.set('selectedAggregationField', field);
        this.set('showAddAggregateField', false);
        this.set('canAutoPopulateTopNFields', false);
        this.addTopNFilter();
      },
      onFieldChange: function onFieldChange() {
        var field = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
        this.get('settingsFields').addObject(field);
        this.set('showAddField', false);
        this.set('selectedField', field);
        this.set('canAutoPopulateTopNFields', false);
        this.addTopNFilter();
      },
      onTopNValueChange: function onTopNValueChange(value) {
        Ember.run.debounce(this, this.setTopNValue, value, 600);
      },
      fetchDataSetFields: function fetchDataSetFields() {
        return this.fetchDataSetFields();
      },
      toggleAddField: function toggleAddField() {
        this.toggleProperty('showAddField');
      },
      toggleAddAggregateField: function toggleAddAggregateField() {
        this.toggleProperty('showAddAggregateField');
      },
      removeField: function removeField() {
        this.set('selectedField', null);
        this.set('canAutoPopulateTopNFields', false);
        this.resetTopNFilter();
        this.applyFilter(null);
      },
      removeAggregateField: function removeAggregateField() {
        this.set('selectedAggregationField', null);
        this.set('canAutoPopulateTopNFields', false);
        this.resetTopNFilter();
        this.applyFilter(null);
      }
    }
  });

  _exports.default = _default;
});