define("happyfox-bi/models/report", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    account: (0, _model.attr)('number'),
    dataSources: (0, _model.attr)(),
    pages: (0, _model.attr)(),
    accessTo: (0, _model.attr)('string'),
    users: (0, _model.hasMany)('user', {
      inverse: null
    }),
    // list of ids
    clients: (0, _model.hasMany)('client', {
      inverse: null
    }),
    // list of ids
    orderedPages: Ember.computed.sort('pages', 'order'),
    settings: (0, _model.attr)()
  });

  _exports.default = _default;
});