define("happyfox-bi/reports/details/pages/details/controller", ["exports", "happyfox-bi/mixins/date-range-picker", "happyfox-bi/utilities/object", "happyfox-bi/utilities/date"], function (_exports, _dateRangePicker, _object, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ACTIVE_AUTO_REFRESH_TIME = 1800000; // 30 minutes

  var INACTIVE_AUTO_REFRESH_TIME = 900000; // 15 minutes

  var ADD_VISUALIZATION_OPTIONS = [{
    id: 'template',
    name: 'add-from-template'
  }];

  var _default = Ember.Controller.extend(_dateRangePicker.default, {
    dataService: Ember.inject.service('data'),
    featuresService: Ember.inject.service('features'),
    accountInfoService: Ember.inject.service('account-info'),
    applicationService: Ember.inject.service('application'),
    tabVisibilityService: Ember.inject.service('tab-visibility'),
    hasVisualizations: Ember.computed.bool('model.visualizations.length'),
    canRemovePageMinHeight: Ember.computed.lt('model.visualizations.length', 2),
    isStaticGrid: false,
    addVisualizationOptions: ADD_VISUALIZATION_OPTIONS,
    reportId: null,
    reportsList: null,
    canShowEditPageModal: false,
    seekedDateRange: null,
    // it is used for storing date range whenever user do seek/zoom selection inside a chart.
    selectedDataSetForSampleVisualizations: null,
    canShowSampleVisualizations: false,
    temporaryVisualizations: null,
    saveAllVisualizationsInProgress: false,
    autoRefreshTimer: null,
    pageLastLoadedAt: null,
    canCreateVisualizationFromSamples: Ember.computed.reads('featuresService.sampleVisualizations'),
    disableCreateVisualization: Ember.computed.reads('accountInfoService.isVisualizationsLimitReached'),
    init: function init() {
      this._super.apply(this, arguments); // Initiating the tab visibility service


      this.get('tabVisibilityService');
    },
    showEmptyStateGridItem: Ember.computed('model.visualizations.[]', 'temporaryVisualizations.[]', {
      get: function get() {
        return !(Ember.isPresent(this.get('model.visualizations')) || Ember.isPresent(this.get('temporaryVisualizations')));
      }
    }),
    showDateRangePicker: Ember.computed('model.visualizations.@each.settings', {
      get: function get() {
        return this.get('model.visualizations').mapBy('settings').mapBy('query').isAny('dateRangeField');
      }
    }),
    visualizationsPositions: Ember.computed('model.layout.items.@each.visualizationId', {
      get: function get() {
        var layouts = this.get('model.layout.items');
        var visualizations = this.get('model.visualizations');
        var visualizationsPositions = Ember.Object.create();
        visualizations.forEach(function (visualization) {
          visualizationsPositions[visualization.id] = layouts.find(function (layout) {
            return layout.visualizationId.toString() == visualization.id;
          });
        });
        return visualizationsPositions;
      }
    }),
    createTemporaryVisualization: function createTemporaryVisualization(gridItem, data, originalVisualizationId) {
      var temporaryVisualization = Ember.Object.create();
      var gridTemporaryId = gridItem.attr('data-gs-temporary-id');
      temporaryVisualization.setProperties({
        originalVisualizationId: originalVisualizationId,
        temporaryId: gridTemporaryId,
        model: data,
        options: Ember.Object.create({
          height: gridItem.attr('data-gs-height'),
          width: gridItem.attr('data-gs-width'),
          x: gridItem.attr('data-gs-x'),
          y: gridItem.attr('data-gs-y'),
          isTemporary: true,
          visualizationId: gridTemporaryId
        })
      });
      return temporaryVisualization;
    },
    removeDroppedItem: function removeDroppedItem(item) {
      Ember.$('.grid-stack').data('gridstack').removeWidget(item);
    },
    initializeAutoRefresh: function initializeAutoRefresh() {
      // Adds observer for tab visibility and starts a timer for auto refresh
      this.addObserver('tabVisibilityService.isHidden', this, 'onTabVisibilityChange');
      this.scheduleAutoRefreshTimer();
    },
    removeAutoRefresh: function removeAutoRefresh() {
      // Removes observer for tab visibility and clears auto refresh timer
      this.removeObserver('tabVisibilityService.isHidden', this, 'onTabVisibilityChange');
      this.clearAutoRefreshTimer();
    },
    clearAutoRefreshTimer: function clearAutoRefreshTimer() {
      var autoRefreshTimer = clearInterval(this.get('autoRefreshTimer'));
      this.set('autoRefreshTimer', autoRefreshTimer);
    },
    scheduleAutoRefreshTimer: function scheduleAutoRefreshTimer() {
      this.clearAutoRefreshTimer();
      var autoRefreshTimer = setInterval(this.triggerAutoRefresh.bind(this), ACTIVE_AUTO_REFRESH_TIME);
      this.set('autoRefreshTimer', autoRefreshTimer);
    },
    triggerAutoRefresh: function triggerAutoRefresh() {
      this.send('refresh'); // Not calling scheduleAutoRefreshTimer again as refresh will re-initiate the auto refresh
    },
    onTabVisibilityChange: function onTabVisibilityChange() {
      var isTabInactive = this.get('tabVisibilityService.isHidden');

      if (isTabInactive) {
        // Once tab is inactive, auto refresh timer is cleared
        this.clearAutoRefreshTimer();
      } else {
        var pageLastLoadedAt = this.get('pageLastLoadedAt'); // Once tab gets active, if the difference with page last load time
        // is greater than INACTIVE_AUTO_REFRESH_TIME, reload is triggered.
        // Otherwise a auto refresh timer is scheduled.

        if (pageLastLoadedAt) {
          if (moment().diff(pageLastLoadedAt) >= INACTIVE_AUTO_REFRESH_TIME) {
            this.triggerAutoRefresh();
          } else {
            this.scheduleAutoRefreshTimer();
          }
        } else {
          this.triggerAutoRefresh();
        }
      }
    },
    actions: {
      onAutoRefresh: function onAutoRefresh() {
        this.send('refresh');
      },
      sampleVisualizationsDataSetChange: function sampleVisualizationsDataSetChange(dataSet) {
        this.set('selectedDataSetForSampleVisualizations', dataSet);
      },
      getVisualizationData: function getVisualizationData(data) {
        return this.get('dataService').getVisualizationData(data);
      },
      getTemporaryVisualizationPreviewData: function getTemporaryVisualizationPreviewData(id) {
        var temporaryVisualizations = this.get('temporaryVisualizations');
        var visualization = temporaryVisualizations.findBy('originalVisualizationId', id);
        return this.get('dataService').getVisualizationData({
          settings: visualization.model.settings
        });
      },
      applyDateRange: function applyDateRange(data) {
        this.saveDateRange(data);
      },
      applyCompareDateRange: function applyCompareDateRange(data) {
        var _this = this;

        var compareDateRangePayload = [];
        var dataInApiFormat = {
          dateRange: data.dateRange,
          fromDate: (0, _date.convertDateToApiFormat)(data.startDate),
          toDate: (0, _date.convertDateToApiFormat)(data.endDate)
        };
        compareDateRangePayload.push(dataInApiFormat);
        var profileDateRange = this.get('dateRangePreferences');
        var requestData = {
          'fromDate': profileDateRange.fromDate,
          'toDate': profileDateRange.toDate,
          'dateRange': profileDateRange.dateRange,
          'compare': compareDateRangePayload
        };
        return this.get('dataService').changeProfileDateRangePreferences(requestData).then(function (profile) {
          profile = (0, _object.camelizeObject)(profile);

          _this.get('profileService').setProfile(profile);
        });
      },
      removeCompareDateRange: function removeCompareDateRange() {
        var _this2 = this;

        return new Ember.RSVP.Promise(function (resolve, reject) {
          var profileDateRange = _this2.get('dateRangePreferences');

          var requestData = {
            'fromDate': profileDateRange.fromDate,
            'toDate': profileDateRange.toDate,
            'dateRange': profileDateRange.dateRange,
            'compare': []
          };

          _this2.get('dataService').changeProfileDateRangePreferences(requestData).then(function (profile) {
            profile = (0, _object.camelizeObject)(profile);

            _this2.get('profileService').setProfile(profile);

            resolve();
          }).catch(function () {
            reject();
          });
        });
      },
      triggerEditVisualization: function triggerEditVisualization(id) {
        this.transitionToRoute('visualizations.new.edit', id);
      },
      addVisualization: function addVisualization() {
        this.transitionToRoute('visualizations.new.create', {
          queryParams: {
            report: this.get('reportId'),
            page: this.get('model.id')
          }
        });
      },
      openSampleVisualizationsPane: function openSampleVisualizationsPane() {
        this.get('applicationService').closeSidePane();
        this.set('canShowSampleVisualizations', true);
      },
      closeSampleVisualizationsPane: function closeSampleVisualizationsPane() {
        this.set('canShowSampleVisualizations', false);
      },
      resetSeekedDateRange: function resetSeekedDateRange() {
        this.get('seekedDateRange').setProperties({
          'fromDate': '',
          'toDate': ''
        });
      },
      toggleEditPageModal: function toggleEditPageModal() {
        var reload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        this.toggleProperty('canShowEditPageModal');

        if (reload) {
          this.send('refresh');
        }
      },
      goToPageDetail: function goToPageDetail(pageId) {
        var page = this.get('dataService').peekAllPagesFromReports().findBy('id', pageId);

        if (page) {
          if (pageId.toString() === this.get('model.id')) {
            this.send('refresh');
          } else {
            this.transitionToRoute('reports.details.pages.details', page.report, page.id);
          }
        }
      },
      refreshPageDetail: function refreshPageDetail() {
        this.send('refresh');
      },
      goToReportDetailAction: function goToReportDetailAction(reportId) {
        this.transitionToRoute('reports.details', reportId);
      },

      /*
        Gridstack addWidget event
        - This event gets triggered twice. one for dropped visualization and the other when a temporary visualization (widget tile) is created.
        - We need to bypass this event for the already created temporary one.
      */
      addWidget: function addWidget(event, modifiedItems) {
        var _this3 = this;

        var newGridItem = Ember.$(modifiedItems[0].el);
        var newGridItemData = newGridItem.data();
        var sampleVisualizationId = Number(newGridItem.attr('data-gs-id'));
        var temporaryVisualizations = this.get('temporaryVisualizations');

        if (!newGridItemData.hasOwnProperty('gsIsTemporary')) {
          var isItemAlreadyPresent = temporaryVisualizations.findBy('temporaryId', newGridItem.attr('data-gs-temporary-id'));

          if (!isItemAlreadyPresent) {
            var dataset = Number(this.get('selectedDataSetForSampleVisualizations.id'));
            var requestData = {
              dataset: dataset
            };
            this.get('dataService').getSampleVisualizationPreview(sampleVisualizationId, requestData).then(function (visualization) {
              visualization.settings.page = _this3.get('model.id');

              var page = _this3.get('model');

              var report = _this3.get('dataService').peekReport(page.get('report'));

              var reportFields = report.get('settings.fields') || Ember.A();
              var pageFields = page.get('settings.fields') || Ember.A();
              var optedOutReportFields = reportFields.filter(function (field) {
                var parentDataset = field.get("parentDataset");

                if (parentDataset) {
                  return Number(parentDataset) !== dataset;
                } else {
                  return Number(field.get('dataset') !== dataset);
                }
              }).mapBy('name');
              var optedOutPageFields = pageFields.filter(function (field) {
                var parentDataset = field.get("parentDataset");

                if (parentDataset) {
                  return Number(parentDataset) !== dataset;
                } else {
                  return Number(field.get('dataset') !== dataset);
                }
              }).mapBy('name');
              visualization.settings.query.optedOutReportFields = optedOutReportFields;
              visualization.settings.query.optedOutPageFields = optedOutPageFields;

              var temporaryVisualization = _this3.createTemporaryVisualization(newGridItem, visualization, sampleVisualizationId);

              _this3.get('temporaryVisualizations').addObject(temporaryVisualization);

              _this3.removeDroppedItem(newGridItem);
            });
          } else {
            this.removeDroppedItem(newGridItem);
          }
        }
      },
      saveAllTemporaryVisualizations: function saveAllTemporaryVisualizations() {
        var _this4 = this;

        var page = this.get('model');
        var temporaryVisualizations = this.get('temporaryVisualizations');
        var visualizationPromisesHash = Ember.Object.create();
        temporaryVisualizations.forEach(function (visualization) {
          var model = visualization.model;
          model.page = page.id;

          var visualizationModel = _this4.get('dataService').createVisualizationModel(model);

          visualizationPromisesHash.set(visualization.temporaryId, visualizationModel.save());
        });
        this.set('saveAllVisualizationsInProgress', true);
        return Ember.RSVP.hash(visualizationPromisesHash).then(function (hashData) {
          var gridStack = Ember.$('.grid-stack').data('gridstack');
          var modifiedNodes = Ember.A();
          gridStack.grid.nodes.forEach(function (node) {
            var layoutItem = Ember.Object.create({
              "visualizationId": '',
              "x": node.x,
              "y": node.y,
              "width": node.width,
              "height": node.height
            });

            if (hashData[node.id]) {
              layoutItem.set('visualizationId', Number(hashData[node.id]['id']));
            } else {
              layoutItem.set('visualizationId', parseInt(node.id));
            }

            modifiedNodes.addObject(layoutItem);
          }); // If the current page is new, layout will be null. Hence we set it to expected format. 

          var layout = page.get('layout') || Ember.Object.create({
            'items': Ember.A()
          });
          layout.set('items', modifiedNodes);

          _this4.get('dataService').patchPage(page.get('report'), page.get('id'), {
            layout: layout
          }).then(function () {
            _this4.set('temporaryVisualizations', Ember.A());

            _this4.set('saveAllVisualizationsInProgress', false);

            _this4.send('refresh');
          });
        });
      },
      removeTemporaryVisualization: function removeTemporaryVisualization(id) {
        var visualization = this.get('temporaryVisualizations').findBy('temporaryId', id);
        this.get('temporaryVisualizations').removeObject(visualization);
      },
      resizeStop: function resizeStop(event, element) {
        var resizedElement = Ember.Object.create({
          id: element.element[0].id,
          "width": element.size.width,
          "height": element.size.height
        });
        this.set('resizedWidget', resizedElement);
      },

      /* 
        Gridstack change event
        - Bypass page layout request if there are any unsaved/temporary visualizations.
      */
      change: function change(event, modifiedItems) {
        if (modifiedItems) {
          var isTemporaryNodesPresent = modifiedItems[0]._grid.grid.nodes.some(function (node) {
            return Ember.$(node.el).data().hasOwnProperty('gsIsTemporary') || !node.id;
          });

          if (!isTemporaryNodesPresent) {
            var data = Ember.A();

            modifiedItems[0]._grid.grid.nodes.forEach(function (node) {
              var layoutItem = Ember.Object.create({
                "visualizationId": '',
                "x": node.x,
                "y": node.y,
                "width": node.width,
                "height": node.height
              });

              if (node.id) {
                layoutItem.set('visualizationId', Number(node.id));
                data.addObject(layoutItem);
              }
            });

            var page = this.get('model');
            var layout = page.get('layout');
            layout.set('items', data);
            this.get('dataService').patchPage(page.get('report'), page.get('id'), {
              layout: layout
            });
          }
        }
      },
      openCreateSubscriptionSidePane: function openCreateSubscriptionSidePane() {
        this.set('canShowSubscriptionSidePane', true);
      },
      closeSubscriptionSidePane: function closeSubscriptionSidePane(slideInCloseAction) {
        var _this5 = this;

        slideInCloseAction();
        Ember.run.later(function () {
          _this5.set('canShowSubscriptionSidePane', false);
        }, 200);
      }
    }
  });

  _exports.default = _default;
});