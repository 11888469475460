define("happyfox-bi/components/collapsible-panels/collapsible-panel/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['panel'],
    classNameBindings: ['isOpen:is-open:is-closed', 'disabled:is-disabled'],
    panelActionsService: Ember.inject.service('panel-actions'),
    panelId: null,
    disabled: false,
    panelState: Ember.computed('panelActionsService.panels.[]', 'panelId', {
      get: function get() {
        var panelActionsService = this.get('panelActionsService');
        var currentPanel = panelActionsService.getPanelById(this.get('panelId'));
        return currentPanel;
      }
    }),
    initiallyClosed: false,
    isOpen: Ember.computed('panelState.isOpen', {
      get: function get() {
        var panelState = this.panelState;
        return panelState ? panelState.isOpen : false;
      },
      set: function set(key, value) {
        return value;
      }
    }),
    isClosed: Ember.computed.not('isOpen'),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (!this.get('panelId')) {
        // Set Ember component's elementID as default panelId
        this.set('panelId', this.get('elementId'));
      }

      this.get('panelActionsService').createPanel(this.get('group'), this.get('panelId'), !this.get('initiallyClosed'));
    },
    actions: {
      toggleIsOpen: function toggleIsOpen() {
        if (this.get("disabled")) {
          return;
        }

        if (this.onTogglePanel) {
          this.onTogglePanel();
        }

        this.get('panelActionsService').togglePanel(this.get('panelId'));
      }
    },
    willDestroy: function willDestroy() {
      var panel = this.get('panelActionsService').getPanelById(this.get('panelId'));
      this.get('panelActionsService.panels').removeObject(panel);

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});