define("happyfox-bi/mixins/date-range-picker", ["exports", "happyfox-bi/reports/details/pages/details/utilities", "happyfox-bi/utilities/time", "happyfox-bi/utilities/date", "happyfox-bi/utilities/object"], function (_exports, _utilities, _time, _date, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EXCLUDE_TODAY_FROM_DATE_RANGE = false;

  var _default = Ember.Mixin.create({
    dataService: Ember.inject.service('data'),
    profileService: Ember.inject.service('profile'),
    accountInfoService: Ember.inject.service('account-info'),
    predefinedDateRanges: (0, _utilities.getDateRanges)({
      excludeToday: EXCLUDE_TODAY_FROM_DATE_RANGE
    }),
    dateRangePreferences: Ember.computed.reads('profileService.profile.dateRangePreferences'),
    dateRange: null,
    startDate: null,
    endDate: null,
    maxDate: Ember.computed('accountInfoService.accountInfo.settings.enableFutureDates', {
      get: function get() {
        var accountSettings = this.accountInfoService.accountInfo.settings;
        var defaultMaxDate;

        if (accountSettings && accountSettings.enableFutureDates) {
          defaultMaxDate = moment((0, _time.getCurrentDateTime)()).add(100, 'years');
        } else {
          defaultMaxDate = EXCLUDE_TODAY_FROM_DATE_RANGE ? moment((0, _time.getCurrentDateTime)()).subtract(1, 'days') : moment((0, _time.getCurrentDateTime)());
        }

        return defaultMaxDate;
      }
    }),
    onDateRangePreferencesChange: Ember.observer('dateRangePreferences.{dateRange,fromDate,toDate}', 'seekedDateRange.{fromDate,toDate}', function () {
      this.setDateRangeDetails();
    }).on('init'),
    convertDateStringToObject: function convertDateStringToObject(dateString) {
      var dateObject = {};
      var ranges = dateString.split('-');
      dateObject.year = ranges[0];
      dateObject.month = ranges[1] === '0' ? 0 : parseInt(ranges[1]) - 1;
      dateObject.date = ranges[2];
      return dateObject;
    },
    setDateRangeDetails: function setDateRangeDetails() {
      var dateRange, startDate, endDate;
      var seekedDateRange = this.get('seekedDateRange'); // page level.

      if (seekedDateRange && seekedDateRange.get('fromDate') && seekedDateRange.get('toDate')) {
        dateRange = seekedDateRange.dateRange; // moment().date() is called by date range picker and when it called with direct string( for example: '2019-09-19')
        // it is returning previous date based on the timezone. So we are explicitly setting the year, month and date.

        startDate = moment().set(this.convertDateStringToObject(seekedDateRange.fromDate));
        endDate = moment().set(this.convertDateStringToObject(seekedDateRange.toDate));
      } else {
        var dateRangePreferences = this.get('dateRangePreferences');

        if (dateRangePreferences) {
          dateRange = dateRangePreferences.dateRange;

          if (dateRange === 'custom') {
            startDate = moment(dateRangePreferences.fromDate);
            endDate = moment(dateRangePreferences.toDate);
          } else {
            var dateRangeOption = this.get('predefinedDateRanges').findBy('id', dateRange);

            if (dateRangeOption) {
              startDate = dateRangeOption.value[0];
              endDate = dateRangeOption.value[1];
            }
          }
        }
      }

      this.setProperties({
        dateRange: dateRange,
        startDate: startDate,
        endDate: endDate
      });
    },
    saveDateRange: function saveDateRange(data) {
      var _this = this;

      var dateRange = data.dateRange;
      var startDate, endDate;
      var compareDateRangePreferences = this.get('dateRangePreferences.compare');

      if (dateRange === 'custom') {
        startDate = (0, _date.convertDateToApiFormat)(data.startDate);
        endDate = (0, _date.convertDateToApiFormat)(data.endDate);
      }

      return this.get('dataService').changeProfileDateRangePreferences({
        dateRange: dateRange,
        fromDate: startDate,
        toDate: endDate,
        compare: compareDateRangePreferences
      }).then(function (profile) {
        profile = (0, _object.camelizeObject)(profile);

        _this.get('profileService').setProfile(profile);

        _this.send('resetSeekedDateRange');
      });
    }
  });

  _exports.default = _default;
});