define("happyfox-bi/utilities/array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sortArrayOfObjects = sortArrayOfObjects;

  function sortArrayOfObjects(array, sortParams) {
    var sortFields = sortParams.mapBy('field');
    var sortOrders = sortParams.mapBy('order');
    sortOrders = sortOrders.map(function (order) {
      return order ? order : 'asc'; // setting the default as ascending.
    });
    var nonEmptyItems = array.filter(function (item) {
      return Ember.isPresent(item.get(sortFields[0]));
    });
    var emptyItems = array.filter(function (item) {
      return Ember.isEmpty(item.get(sortFields[0]));
    });
    var sortFunctions = sortFields.map(function (key) {
      // Necessary because lodash orderBy does not do item.get()
      return function (item) {
        var value = item.get(key);

        if (typeof value === 'string') {
          value = value.toLowerCase();
        }

        return value;
      };
    });

    var sortedItems = _.orderBy(nonEmptyItems, sortFunctions, sortOrders);

    sortedItems.addObjects(emptyItems);
    return sortedItems;
  }
});