define("happyfox-bi/form-field-models/condition-field", ["exports", "happyfox-bi/form-field-models/base-field"], function (_exports, _baseField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseField.default.extend({
    isCondition: true,
    conditionFields: null,
    init: function init() {
      this._super();

      this.set('value', this.getValueCopy()); // To make sure value is converted to ember object

      this.set('_initialValue', this.getValueCopy());
    },
    isDirty: Ember.computed('value.{field,predicate,value.[],unit}', '_initialValue.{field,predicate,value.[],unit}', {
      get: function get() {
        return !_.isEqual(this.get('_initialValue'), this.get('value'));
      }
    }),
    getValueCopy: function getValueCopy() {
      var valueObject = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      valueObject = valueObject || this.get('value');

      if (valueObject) {
        valueObject = Ember.Object.create(valueObject); // Converting to ember object
      }

      return _.cloneDeep(valueObject);
    },
    updateInitialValue: function updateInitialValue() {
      var valueCopy = this.getValueCopy();
      this.set('_initialValue', valueCopy);
    },
    reset: function reset() {
      var initialValueCopy = this.getValueCopy(this.get('_initialValue'));
      this.set('value', initialValueCopy);
    },
    validate: function validate() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve) {
        _this._super().then(function () {
          _this.set('_cleanValue', _this.get('value'));

          resolve();
        });
      });
    }
  });

  _exports.default = _default;
});