define("happyfox-bi/visualizations/new/components/sort-options/option/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PS2OZ0bI",
    "block": "{\"symbols\":[\"orderOption\"],\"statements\":[[10,\"div\"],[14,0,\"sort-options_option_name\"],[12],[1,[34,0]],[13],[2,\"\\n\"],[6,[37,5],null,[[\"tagName\",\"class\",\"options\",\"selected\",\"placeholder\",\"onChange\",\"searchEnabled\",\"renderInPlace\"],[\"div\",\"mod-dropdown mod-local-autocomplete sort-options_option_order\",[35,4],[35,3,[\"order\"]],[30,[36,2],[\"search\"],null],[30,[36,1],[[32,0],\"onOrderChange\"],null],false,true]],[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[32,1]],[2,\"\\n\"]],\"parameters\":[1]}]]],[11,\"div\"],[24,0,\"sort-options_option_delete fields-selection_field_icon\"],[24,\"role\",\"button\"],[4,[38,1],[[32,0],\"delete\"],null],[12],[1,[30,[36,6],[\"trash\"],null]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fieldDisplayName\",\"action\",\"t\",\"sortOption\",\"sortOrderOptions\",\"power-select\",\"inline-svg\"]}",
    "meta": {
      "moduleName": "happyfox-bi/visualizations/new/components/sort-options/option/template.hbs"
    }
  });

  _exports.default = _default;
});