define("happyfox-bi/report-templates/components/table-cell/more-actions/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['u-horizontally-centered-container'],
    dataService: Ember.inject.service('data'),
    toastMessageService: Ember.inject.service('toast-message'),
    canShowDeleteConfirmation: false,
    isDeleteInProgress: false,
    actions: {
      editReportTemplate: function editReportTemplate(popOver) {
        this.get('extra').editReportTemplate(this.get('row.content'));
        popOver.closePopOver();
      },
      deleteReportTemplate: function deleteReportTemplate(popOver) {
        var _this = this;

        this.set('isDeleteInProgress', true);
        var template = this.get('row.content');
        var toastMessageService = this.toastMessageService,
            dataService = this.dataService;
        dataService.deleteReportTemplate(template.id).then(function () {
          toastMessageService.showMessage({
            type: 'success',
            text: "report-template-delete-success"
          });

          _this.get('extra').onDeleteReportTemplate();
        }).catch(function () {
          toastMessageService.showMessage({
            type: 'failure',
            text: "report-template-delete-failed"
          });
        }).finally(function () {
          _this.set('isDeleteInProgress', false);

          popOver.closePopOver();
        });
      },
      toggleDeleteReportTemplates: function toggleDeleteReportTemplates() {
        this.toggleProperty('canShowDeleteConfirmation');
      }
    }
  });

  _exports.default = _default;
});