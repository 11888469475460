define("happyfox-bi/visualizations/new/components/display-settings/data-table/component", ["exports", "happyfox-bi/visualizations/new/components/display-settings/base-chart/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MAX_ROWS = 200;

  var _default = _component.default.extend({
    settings: null,
    actions: {
      onMaxRowsChange: function onMaxRowsChange(value) {
        var settings = this.settings;

        if (Ember.isPresent(value)) {
          var rowsValue = Number(value);
          settings.set('maxRowsToDisplay', rowsValue < MAX_ROWS ? rowsValue : MAX_ROWS);
        } else {
          settings.set('maxRowsToDisplay', MAX_ROWS);
        }

        this.send('onUpdateDisplaySettings');
      }
    }
  });

  _exports.default = _default;
});