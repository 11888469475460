define("happyfox-bi/components/hf-copy-to-clipboard", ["exports", "@happyfoxinc/hf-copy-to-clipboard/components/hf-copy-to-clipboard"], function (_exports, _hfCopyToClipboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _hfCopyToClipboard.default;
    }
  });
});