define("happyfox-bi/report-templates/controller", ["exports", "happyfox-bi/mixins/table-new", "happyfox-bi/constants"], function (_exports, _tableNew, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TABLE_COLUMNS = Ember.A([{
    label: 'name',
    valuePath: 'name',
    sortable: true,
    cellClassNames: 'report-template-name-cell',
    cellComponent: 'report-templates/components/table-cell/name'
  }, {
    label: 'times-used',
    valuePath: 'timesUsed',
    sortable: true,
    width: '300px'
  }, {
    valuePath: '',
    label: '',
    sortable: false,
    width: '200px',
    cellClassNames: 'list-body_table-more-actions',
    cellComponent: 'report-templates/components/table-cell/more-actions'
  }]);

  var _default = Ember.Controller.extend(_tableNew.default, {
    dataService: Ember.inject.service('data'),
    featuresService: Ember.inject.service('features'),
    canCreateGlobalReportTemplates: Ember.computed.reads('featuresService.createGlobalReportTemplates'),
    offset: 0,
    limit: 25,
    isLoading: false,
    columns: TABLE_COLUMNS,
    tableProperties: Ember.computed('', function () {
      return Ember.Object.create({
        isLocalSort: true,
        canPersistSort: false,
        editReportTemplate: this.actions.goToReportTemplateEdit.bind(this),
        onDeleteReportTemplate: this.fetchData.bind(this)
      });
    }),
    pageInfo: null,
    currentReportTemplate: null,
    canShowTemplateSidePane: false,
    isPaginationNeeded: Ember.computed.gt('pageInfo.count', 0),
    calculatedOffset: Ember.computed('pageInfo', 'model', 'offset', {
      get: function get() {
        var offset = this.get('offset');

        if (this.get('model.length') === 0 && offset > 0) {
          return this.get('pageInfo.previous');
        }

        return offset;
      }
    }),
    fetchData: function fetchData() {
      var _this = this;

      var offset = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.offset;
      this.set('offset', offset);
      var params = {
        offset: offset,
        limit: this.limit
      };

      if (!this.canCreateGlobalReportTemplates) {
        params.excludeGlobalTemplates = true;
      }

      this.set('isLoading', true);
      this.dataService.fetchReportTemplates(params).then(function (response) {
        _this.setProperties({
          'pageInfo': response.meta,
          'model': response.results
        });
      }).finally(function () {
        _this.set('isLoading', false);
      });
    },
    actions: {
      changePage: function changePage(newOffset) {
        this.fetchData(newOffset);
      },
      goToReportTemplateEdit: function goToReportTemplateEdit(reportTemplate) {
        this.set('currentReportTemplate', reportTemplate);
        this.set('canShowTemplateSidePane', true);
      },
      closeReportTemplatePane: function closeReportTemplatePane(slideInCloseAction) {
        var _this2 = this;

        var refetchData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        slideInCloseAction();
        Ember.run.later(function () {
          _this2.setProperties({
            canShowTemplateSidePane: false,
            currentReportTemplate: null
          });

          if (refetchData) {
            _this2.fetchData(_this2.offset);
          }
        }, _constants.SLIDE_IN_DELAY_DURATION);
      }
    }
  });

  _exports.default = _default;
});