define("happyfox-bi/services/profile", ["exports", "@amcharts/amcharts4/core", "@amcharts/amcharts4/themes/dark", "@amcharts/amcharts4/themes/animated"], function (_exports, am4core, _dark, _animated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    profile: null,
    account: Ember.computed.alias('profile.account'),
    routerService: Ember.inject.service('router'),
    isUserAuthenticated: false,
    setProfile: function setProfile(data) {
      this.set('profile', data);
    },
    isLightMode: Ember.computed('profile.themePreferences.{app}', {
      get: function get() {
        var themePreferences = this.get('profile.themePreferences');
        var lightMode = themePreferences && themePreferences.app == 'light';
        return lightMode;
      }
    }),
    fullName: Ember.computed('profile.{firstName,lastName}', {
      get: function get() {
        var firstName = this.get('profile.firstName');
        var lastName = this.get('profile.lastName');

        if (Ember.isBlank(firstName)) {
          return lastName;
        } else if (Ember.isBlank(lastName)) {
          return firstName;
        } else {
          return "".concat(firstName, " ").concat(lastName);
        }
      }
    }),
    getUserEmail: function getUserEmail() {
      return this.get('profile.email');
    },
    getAccountId: function getAccountId() {
      return this.get('account.id');
    },
    setMomentTimezone: function setMomentTimezone(userInfo) {
      var userTimezone = userInfo.get('timezone');
      var timezoneConstant = moment.tz.names().find(function (zoneName) {
        var offsetInHours = moment.tz(zoneName).format('Z');

        if (offsetInHours.startsWith('+')) {
          offsetInHours = offsetInHours.slice(1);
        }

        return moment.duration(offsetInHours).asMinutes() === Number(userTimezone.get('offset'));
      });
      moment.tz.setDefault(timezoneConstant);
    },
    setUpChartsTheme: function setUpChartsTheme() {
      am4core.options.commercialLicense = true;
      am4core.unuseAllThemes();
      am4core.useTheme(_animated.default.default);
      var body = Ember.$('body');
      var isLightMode = this.isLightMode;

      if (isLightMode) {
        if (!body.hasClass('mod-light-mode')) {
          body.addClass('mod-light-mode');
        }
      } else {
        body.removeClass('mod-light-mode');
        am4core.useTheme(_dark.default.default);
      }
    }
  });

  _exports.default = _default;
});