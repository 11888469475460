define("happyfox-bi/components/edit-report-modal/component", ["exports", "happyfox-bi/utilities/form-models", "happyfox-bi/utilities/object"], function (_exports, _formModels, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ACCESS_TO_OPTIONS = [{
    id: 'all_users',
    name: 'All Users'
  }, {
    id: 'custom_users',
    name: 'Custom Users'
  }];

  var _default = Ember.Component.extend({
    dataService: Ember.inject.service('data'),
    featuresService: Ember.inject.service('features'),
    profileService: Ember.inject.service('profile'),
    toastMessageService: Ember.inject.service('toast-message'),
    intlService: Ember.inject.service('intl'),
    report: null,
    selectedUsers: null,
    selectedClients: null,
    accessToOptions: ACCESS_TO_OPTIONS,
    selectedAccessToOption: null,
    canShowDeleteConfirmation: false,
    canDeleteReport: false,
    fields: null,
    filterFields: null,
    filters: null,
    prefetchedFiltersValueOptions: null,
    saveTemplateInProgress: false,
    reportTemplateName: null,
    reportTemplateDescription: null,
    createAsGlobalReportTemplate: false,
    reportSubscriptionDependants: null,
    isSaveDisabled: Ember.computed.equal('report.name', ''),
    isCustomUsers: Ember.computed.equal('selectedAccessToOption.id', 'custom_users'),
    disableSaveAsTemplate: Ember.computed.empty('reportTemplateName'),
    userDependantMessages: Ember.computed('reportSubscriptionDependants.[]', {
      get: function get() {
        var _this = this;

        var messages = [];
        var userDependants = this.get('reportSubscriptionDependants').filterBy('type', 'user');
        userDependants.forEach(function (dependantData) {
          var userName = dependantData.name;
          var reportSubscriptionNames = dependantData.reportSubscriptions.mapBy('name').join();

          var message = _this.get('intlService').lookupWithParameters('user-report-subscription-dependant-message', {
            user: userName,
            reportSubscriptions: reportSubscriptionNames
          });

          messages.push(message);
        });
        return messages;
      }
    }),
    clientDependantMessages: Ember.computed('reportSubscriptionDependants.[]', {
      get: function get() {
        var _this2 = this;

        var messages = [];
        var clientDependants = this.get('reportSubscriptionDependants').filterBy('type', 'client');
        clientDependants.forEach(function (dependantData) {
          var clientName = dependantData.name;
          var reportSubscriptionNames = dependantData.reportSubscriptions.mapBy('name').join();

          var message = _this2.get('intlService').lookupWithParameters('client-report-subscription-dependant-message', {
            client: clientName,
            reportSubscriptions: reportSubscriptionNames
          });

          messages.push(message);
        });
        return messages;
      }
    }),
    init: function init() {
      var _this3 = this;

      this._super.apply(this, arguments);

      this.set('reportTemplateName', this.get('report.name'));
      var fields = this.get('report.settings.fields');
      fields = fields ? fields.slice() : [];
      var filters = this.get('report.settings.filters.matchAll');
      this.setProperties({
        fields: fields,
        filterFields: fields.filterBy('isEditable').slice(),
        filters: filters ? filters.slice() : [],
        prefetchedFiltersValueOptions: Ember.Object.create()
      });
      var reports = this.get('dataService').peekAllReports();
      this.setProperties({
        isLoading: true,
        canDeleteReport: reports.length > 1
      });
      var dataService = this.dataService,
          featuresService = this.featuresService;
      var usersAndClientsParams = {
        offset: 0,
        limit: 50,
        includeReports: true
      };
      var promises = {
        users: featuresService.inviteUser ? dataService.getAllRecords('user', usersAndClientsParams) : Ember.RSVP.resolve([]),
        clients: featuresService.inviteClient ? dataService.getAllRecords('client', usersAndClientsParams) : Ember.RSVP.resolve([])
      };
      Ember.RSVP.hash(promises).then(function (data) {
        _this3.setProperties({
          userOptions: data.users.filterBy('isActive'),
          clientOptions: data.clients.filterBy('isActive')
        });

        _this3.initializeEditForm();
      }).finally(function () {
        _this3.set('isLoading', false);
      });
    },
    initializeEditForm: function initializeEditForm() {
      var report = this.report,
          featuresService = this.featuresService,
          userOptions = this.userOptions,
          clientOptions = this.clientOptions;

      if (featuresService.inviteUser) {
        var usersAccessRawField = Ember.Object.create({
          key: 'users',
          isMultiChoice: true,
          isMultiChoiceDropdown: true,
          valueProperty: 'id',
          labelProperty: 'name',
          options: userOptions,
          optionsComponent: 'manage/reports/edit-report-pane/user-option',
          value: report.get('users').toArray(),
          placeholder: this.intlService.lookup('select-users'),
          label: this.intlService.lookup('users'),
          pluralizedTranslationKey: 'users'
        });
        this.setProperties({
          'selectedAccessToOption': this.get('accessToOptions').findBy('id', report.get('accessTo')),
          usersAccessField: (0, _formModels.createField)(this, usersAccessRawField)
        });
      }

      if (featuresService.inviteClient) {
        var clientsAccessRawField = Ember.Object.create({
          key: 'clients',
          isMultiChoice: true,
          isMultiChoiceDropdown: true,
          valueProperty: 'id',
          labelProperty: 'name',
          options: clientOptions,
          value: report.get('clients').toArray(),
          placeholder: this.intlService.lookup('select-clients'),
          label: this.intlService.lookup('select-clients'),
          pluralizedTranslationKey: 'clients'
        });
        var clientsAccessField = (0, _formModels.createField)(this, clientsAccessRawField);
        this.set('clientsAccessField', clientsAccessField);
      }
    },
    actions: {
      closeEditReportModal: function closeEditReportModal() {
        var report = this.report;

        if (report.get('hasDirtyAttributes')) {
          report.rollbackAttributes();
        }

        this.get('closeEditReportModalAction')();
      },
      onAccessToOptionChange: function onAccessToOptionChange(option) {
        this.set('selectedAccessToOption', option); // always pre-select the current user

        if (option.id === 'custom_users') {
          var profileService = this.profileService,
              userOptions = this.userOptions,
              usersAccessField = this.usersAccessField;
          var userId = profileService.get('profile.id');
          var currentUser = userOptions.findBy('id', userId.toString());
          var selectedUsers = usersAccessField.value;
          selectedUsers.addObject(currentUser);
          this.usersAccessField.set('value', selectedUsers.uniq());
        }
      },
      onUnselectAllUsers: function onUnselectAllUsers() {
        var usersWithAccessToAllReports = this.userOptions.filterBy('viewAllReports');
        this.usersAccessField.set('value', usersWithAccessToAllReports); // On unselect all, multichoice dropdown component unselects all options and calls validate() which will show field error
        // So, after handling unselectAll, we reset errors

        this.usersAccessField.resetErrors();
      },
      saveReport: function saveReport() {
        var _this4 = this;

        var report = this.report,
            featuresService = this.featuresService;
        this.set('saveInProgress', true);

        if (featuresService.inviteUser) {
          var isCustomUsers = this.isCustomUsers,
              usersAccessField = this.usersAccessField;
          report.set('accessTo', this.get('selectedAccessToOption.id'));

          if (isCustomUsers) {
            var selectedUsers = usersAccessField.value;
            report.set('users', selectedUsers);
          } else {
            report.set('users', []);
          }
        }

        if (featuresService.inviteClient) {
          var selectedClients = this.clientsAccessField.value;
          report.set('clients', selectedClients);
        }

        var settings = {
          fields: this.get('fields'),
          filters: {
            matchAll: this.get('filters')
          }
        };
        report.set('settings', settings); // checking report subscription dependants

        var requestData = {
          'accessTo': report.get('accessTo'),
          'users': report.get('users').map(function (user) {
            return parseInt(user.id);
          }),
          'clients': report.get('clients').map(function (client) {
            return parseInt(client.id);
          })
        };
        this.get('dataService').getReportSubscriptionDependants(report.id, requestData).then(function (dependants) {
          if (dependants.length > 0) {
            dependants = (0, _object.camelizeObject)(dependants);

            _this4.set('reportSubscriptionDependants', dependants);

            _this4.set('saveInProgress', false);
          } else {
            report.save().then(function () {
              _this4.send('closeEditReportModal');

              _this4.goToReportDetailAction(report.get('id'));
            }).finally(function () {
              _this4.set('saveInProgress', false);
            });
          }
        }).catch(function () {
          _this4.get('toastMessageService').showMessage({
            type: 'failure',
            text: 'report-subscription-dependants-failure'
          });

          _this4.set('saveInProgress', false);
        });
      },
      toggleDeleteConfirmation: function toggleDeleteConfirmation() {
        this.toggleProperty('canShowDeleteConfirmation');
      },
      deleteReport: function deleteReport() {
        var _this5 = this;

        this.set('isDeleteInProgress', true);
        this.get('report').destroyRecord().then(function () {
          var reportId = _this5.get('dataService').peekAllReports().rejectBy('id', _this5.get('report.id')).get('firstObject.id');

          _this5.send('toggleDeleteConfirmation');

          _this5.send('closeEditReportModal');

          _this5.goToReportDetailAction(reportId);
        }).finally(function () {
          _this5.set('isDeleteInProgress', false);
        });
      },
      applyFilters: function applyFilters(filters) {
        var _this6 = this;

        this.set('filters', filters);
        var unUsedFields = this.get('fields').filter(function (field) {
          var filter = _this6.get('filters').findBy('field', field.get('name'));

          return !filter && !field.get('isEditable');
        });
        this.get('fields').removeObjects(unUsedFields);
      },
      saveReportTemplate: function saveReportTemplate() {
        var _this7 = this;

        var reportId = this.get('report.id');
        var reportName = this.get('report.name');
        var templateName = this.get('reportTemplateName');
        var templateDescription = this.get('reportTemplateDescription');
        var isGlobalTemplate = false;

        if (this.get('featuresService.createGlobalReportTemplates')) {
          isGlobalTemplate = this.get('createAsGlobalReportTemplate');
        }

        var templateData = {
          reportId: reportId,
          name: templateName,
          description: templateDescription,
          isGlobal: isGlobalTemplate
        };
        this.set('saveTemplateInProgress', true);
        this.get('dataService').saveAsReportTemplate(templateData).then(function () {
          _this7.get('toastMessageService').showMessage({
            type: 'success',
            text: 'report-{name}-saved-as-{template}',
            params: {
              name: reportName,
              template: templateName
            }
          });

          _this7.send('closeEditReportModal');
        }).catch(function () {
          _this7.get('toastMessageService').showMessage({
            type: 'failure',
            text: 'report-{name}-failed-as-template',
            params: {
              name: reportName
            }
          });
        }).finally(function () {
          _this7.set('saveTemplateInProgress', false);
        });
      },
      onAccountScopeChange: function onAccountScopeChange(isChecked) {
        this.set('createAsGlobalReportTemplate', isChecked);
      }
    }
  });

  _exports.default = _default;
});