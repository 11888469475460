define("happyfox-bi/components/form-field-input/files/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N4FHQ9r0",
    "block": "{\"symbols\":[\"file\"],\"statements\":[[6,[37,7],null,[[\"files\",\"hideInput\",\"onChange\",\"dataTestId\"],[[30,[36,6],[[35,5,[\"value\"]]],null],true,[30,[36,1],[[32,0],\"uploadFiles\"],null],[35,5,[\"key\"]]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,4],null,[[\"text\",\"inProgressText\",\"onClick\",\"disabled\",\"inProgress\"],[[30,[36,0],[\"upload-file\"],null],[30,[36,0],[\"uploading\"],null],[30,[36,1],[[32,0],\"onTriggerUpload\"],null],[35,3],[35,3]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"div\"],[14,0,\"u-vertically-centered-container hf-mod-wrap\"],[12],[2,\"\\n\"],[6,[37,9],[[30,[36,8],[[30,[36,8],[[35,5,[\"value\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"hf-attachment-container\"],[12],[2,\"\\n      \"],[1,[32,1,[\"name\"]]],[2,\"\\n      \"],[11,\"span\"],[24,0,\"hf-attachments-close\"],[16,\"data-test-id\",[31,[\"file-remove-\",[32,1,[\"id\"]]]]],[24,\"role\",\"button\"],[16,\"aria-label\",[30,[36,0],[\"remove\"],null]],[4,[38,1],[[32,0],\"removeFile\",[32,1]],null],[12],[1,[30,[36,2],[\"attachment-close\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"inline-svg\",\"isUploadInProgress\",\"async-button\",\"field\",\"readonly\",\"file-upload\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "happyfox-bi/components/form-field-input/files/template.hbs"
    }
  });

  _exports.default = _default;
});