define("happyfox-bi/components/compare-date-range-picker/utilities", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getCompareDateRanges = getCompareDateRanges;

  function getCompareDateRanges(sourceDataRanges) {
    var sourceStartDate = moment(sourceDataRanges.fromDate);
    var sourceEndDate = moment(sourceDataRanges.toDate);
    var dataRanges = [];
    var sourceDiffPeriod = sourceEndDate.diff(sourceStartDate, 'days') + 1;
    dataRanges.push({
      id: 'last_period',
      label: 'date-ranges.last-period',
      value: [sourceStartDate.clone().subtract(sourceDiffPeriod, 'days'), sourceEndDate.clone().subtract(sourceDiffPeriod, 'days')]
    });
    var sourceStartDateLastThreeMonths = sourceStartDate.clone().subtract(3, 'months');
    var sourceEndDateLastThreeMonths = sourceEndDate.clone().subtract(3, 'months');
    dataRanges = dataRanges.concat([{
      id: 'last_three_months',
      label: 'date-ranges.last-three-months',
      value: [sourceStartDateLastThreeMonths, sourceEndDateLastThreeMonths]
    }]);
    var sourceStartDateLastSixMonths = sourceStartDate.clone().subtract(6, 'months');
    var sourceEndDateLastSixMonths = sourceEndDate.clone().subtract(6, 'months');
    dataRanges = dataRanges.concat([{
      id: 'last_six_months',
      label: 'date-ranges.last-six-months',
      value: [sourceStartDateLastSixMonths, sourceEndDateLastSixMonths]
    }]);
    dataRanges = dataRanges.concat([{
      id: 'last_year',
      label: 'date-ranges.last-year',
      value: [sourceStartDate.clone().subtract(1, 'year'), sourceEndDate.clone().subtract(1, 'year')]
    }]);
    return dataRanges;
  }
});