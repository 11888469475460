define("happyfox-bi/data-sources/details/data-sets/details/controller", ["exports", "ember-light-table", "happyfox-bi/mixins/table", "happyfox-bi/utilities/array", "happyfox-bi/constants"], function (_exports, _emberLightTable, _table, _array, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_table.default, {
    dataService: Ember.inject.service('data'),
    dataSource: null,
    dataSetFields: null,
    loadingFields: false,
    data: null,
    canShowFormatForm: false,
    fieldToFormat: null,
    tableProperties: Ember.computed('', function () {
      return Ember.Object.create({
        onFormatClick: this.actions.onFormatClick.bind(this)
      });
    }),
    initiate: function initiate() {
      var _this = this;

      var dataSet = this.get('model');
      var dataSourceId = dataSet.get('dataSource');
      var dataSource = this.get('dataService').peekDataSource(dataSourceId);
      this.set('dataSource', dataSource);
      this.set('loadingFields', true);
      this.get('dataService').getDataSetFields(dataSourceId, dataSet.get('id'), true).then(function (fields) {
        _this.set('data', fields);

        var columns = [{
          valuePath: 'displayNameOrName',
          label: 'Name',
          sortable: true,
          resizable: true,
          minResizeWidth: 100,
          component: 'data-table/column'
        }, {
          valuePath: 'dataType',
          label: 'Type',
          sortable: true,
          resizable: true,
          minResizeWidth: 100,
          component: 'data-table/column',
          cellComponent: 'data-sources/components/table-cell/data-type'
        }, {
          valuePath: 'format',
          label: 'Format',
          sortable: false,
          resizable: false,
          component: 'data-table/column',
          cellComponent: 'data-sources/components/table-cell/data-type-format-action'
        }, {
          valuePath: 'helpText',
          label: 'Description',
          sortable: true,
          resizable: true,
          minResizeWidth: 100,
          component: 'data-table/column'
        }];

        if (_this.get('dataSource.sourceType') !== 'happyfox') {
          var columnToRemove = columns.findBy('valuePath', 'format');
          columns.removeObject(columnToRemove);
        }

        _this.set('columns', columns);

        var table = _emberLightTable.default.create({
          columns: columns,
          rows: _this.get('data')
        });

        var rows = table.rows;

        _this.set('rows', rows);

        _this.set('table', table);
      }).finally(function () {
        _this.set('loadingFields', false);
      });
    },
    actions: {
      onFormatClick: function onFormatClick(field) {
        this.set('canShowFormatForm', true);
        this.set('fieldToFormat', field);
      },
      closeFormatSidePane: function closeFormatSidePane(slideInCloseAction) {
        var _this2 = this;

        slideInCloseAction();
        Ember.run.later(function () {
          _this2.set('canShowFormatForm', false);

          _this2.set('fieldToFormat', null);
        }, _constants.SLIDE_IN_DELAY_DURATION);
      },
      onColumnClick: function onColumnClick(column) {
        if (column.sorted) {
          var sortFields = [];
          var rows = this.get('rows').toArray();
          var sortedRows;

          if (column) {
            sortFields.push({
              field: column.get('valuePath'),
              order: column.ascending ? 'asc' : 'desc'
            });
          }

          sortedRows = (0, _array.sortArrayOfObjects)(rows, sortFields);
          this.get('table').setRows(sortedRows);
        }
      },
      scrollToTop: function scrollToTop() {
        var table = document.querySelector('.js-data-set-fields-table');
        var scrollContainer = table.querySelector('.tse-scroll-content');

        if (scrollContainer) {
          scrollContainer.scrollTop = 0;
        }
      },
      goToImport: function goToImport() {
        var dataSet = this.get('model');
        var dataSource = this.get('dataService').peekDataSource(dataSet.get('dataSource'));
        this.transitionToRoute('data-sources.details.data-sets.import', dataSource, dataSet);
      }
    }
  });

  _exports.default = _default;
});