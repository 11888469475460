define("happyfox-bi/visualizations/create-edit-controller", ["exports", "happyfox-bi/mixins/date-range-picker", "happyfox-bi/visualizations/constant", "happyfox-bi/components/base-chart/constants"], function (_exports, _dateRangePicker, _constant, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VISUALIZATION_OPTIONS = [{
    id: 'data_tile',
    displayValue: 'Data Tile'
  }, {
    id: 'data_table',
    displayValue: 'Table'
  }, {
    id: 'pivot_table',
    displayValue: 'Pivot Table'
  }, {
    id: 'bar_chart',
    displayValue: 'Bar Chart'
  }, {
    id: 'line_chart',
    displayValue: 'Line Chart'
  }, {
    id: 'area_chart',
    displayValue: 'Area Chart'
  }, {
    id: 'doughnut_chart',
    displayValue: 'Doughnut Chart'
  }, {
    id: 'grouped_bar_chart',
    displayValue: 'Grouped Bar Chart'
  }, {
    id: 'stacked_bar_chart',
    displayValue: 'Stacked Bar Chart'
  }, {
    id: 'stacked_area_chart',
    displayValue: 'Stacked Area Chart'
  }, {
    id: 'multi_line_chart',
    displayValue: 'Multi line chart'
  }, {
    id: 'multi_area_chart',
    displayValue: 'Multi Area chart'
  }, {
    id: 'map_chart',
    displayValue: 'Map Chart'
  }, {
    id: 'multi_axis_chart',
    displayValue: 'Multi axis Chart'
  }, {
    id: 'life_cycle_chart',
    displayValue: 'Life cycle Chart'
  }];
  var UNSELECT_FIELD = {
    name: '-------'
  };
  var INCLUDE_PERCENTAGES_OPTIONS = [{
    id: null,
    name: '------'
  }, {
    id: 'by_total',
    name: 'By Total'
  }, {
    id: 'by_row',
    name: 'By Row'
  }, {
    id: 'by_column',
    name: 'By Column'
  }];

  var _default = Ember.Controller.extend(_dateRangePicker.default, {
    dataService: Ember.inject.service('data'),
    query: Ember.computed.reads('model.settings.query'),
    visualizationFields: Ember.computed.reads('model.settings.fields'),
    reportId: null,
    pageId: null,
    userCreatedFields: null,
    dataSources: null,
    dataSets: null,
    dataSetFields: null,
    isLoadingDependencies: false,
    visualizationSettingsMessages: null,
    visualizationData: null,
    visualizationTypeOptions: VISUALIZATION_OPTIONS,
    includePercentagesOptions: INCLUDE_PERCENTAGES_OPTIONS,
    prefetchedFiltersValueOptions: null,
    previewModel: null,
    saveInProgress: false,
    isNew: false,
    relatedDataSetFields: null,
    reportLevelFields: null,
    pageLevelFields: null,
    initiate: function initiate() {
      var _this = this;

      this.setProperties({
        userCreatedFields: Ember.A(),
        dataSetFields: Ember.A(),
        previewModel: Ember.Object.create(),
        prefetchedFiltersValueOptions: Ember.Object.create()
      });

      if (!this.get('isNew')) {
        var pageId = this.get('model.page');
        var reports = this.get('dataService').peekAllReports();
        var report = reports.find(function (report) {
          return report.get('pages').mapBy('id').includes(pageId);
        });
        var reportId = report.get('id');
        var page = report.get('pages').findBy('id', pageId);
        this.setProperties({
          pageId: pageId,
          reportId: reportId,
          reportLevelFields: report.get('settings.fields') || Ember.A(),
          pageLevelFields: page.get('settings.fields') || Ember.A()
        });
      } else {
        var _pageId = this.get('pageId');

        var _reportId = this.get('reportId');

        var _report = this.get('dataService').peekReport(_reportId);

        var _page = _report.get('pages').findBy('id', Number(_pageId));

        this.setProperties({
          reportLevelFields: _report.get('settings.fields') || Ember.A(),
          pageLevelFields: _page.get('settings.fields') || Ember.A()
        });
      }

      this.loadDependencies().then(function () {
        _this.createVisualizationData();

        _this.setupDataSourceForCreateVisualization();

        if (_this.validateVisualizationSettings()) {
          _this.triggerPreviewReload();
        }
      });
    },
    unusedDataSetFields: Ember.computed('dataSetFields.@each.id', 'visualizationFields.@each.{type,dataSetField}', {
      get: function get() {
        var visualizationFields = this.get('visualizationFields').filterBy('type', 'dataset_field');
        var dataSetFields = this.get('dataSetFields');

        if (dataSetFields) {
          dataSetFields = dataSetFields.rejectBy('dataType', 'dataset');
          var unusedDataFields = dataSetFields.filter(function (field) {
            return !visualizationFields.isAny('datasetField', field.get('id'));
          });
          var dataSetId = this.get('visualizationData.dataSet.id');
          return unusedDataFields.map(function (field) {
            var unusedField = Ember.Object.create({
              'name': field.get('name'),
              'type': 'dataset_field',
              'dataset': dataSetId,
              'dataType': field.get('dataType'),
              'datasetField': field.get('id'),
              'isEditable': false
            });

            if (field.get('dataType') === 'timestamp') {
              unusedField.format = 'date';
            }

            return unusedField;
          });
        } else {
          return [];
        }
      }
    }),
    allDerivedFields: Ember.computed('allDataSetFieldOptions.@each.type', {
      get: function get() {
        return this.get('allDataSetFieldOptions').filter(function (field) {
          return !(field.type === 'dataset_field' || field.type === 'dataset');
        });
      }
    }),
    allElementaryFields: Ember.computed('allDataSetFieldOptions.@each.type', {
      get: function get() {
        return this.get('allDataSetFieldOptions').filterBy('type', 'dataset_field');
      }
    }),
    nonFormattedElementaryFields: Ember.computed('dataSetFields.@each.{id,dataType,name}', 'visualizationData.dataSet.id', 'relatedDataSetFields.[]', {
      get: function get() {
        var dataSetFields = this.get('dataSetFields');
        var dataSetId = this.get('visualizationData.dataSet.id');
        dataSetFields = dataSetFields.map(function (field) {
          return Ember.Object.create({
            'name': field.get('name'),
            'type': 'dataset_field',
            'dataset': dataSetId,
            'dataType': field.get('dataType'),
            'datasetField': field.get('id'),
            'isEditable': false
          });
        });
        var fields = Ember.A().addObjects(dataSetFields);
        var relatedDataSetFields = this.get('relatedDataSetFields');

        if (!Ember.isEmpty(relatedDataSetFields)) {
          return fields.addObjects(relatedDataSetFields);
        }

        return fields;
      }
    }),
    allTimestampDatasetFields: Ember.computed('nonFormattedElementaryFields.@each.dataType', {
      get: function get() {
        return this.get('nonFormattedElementaryFields').filterBy('dataType', 'timestamp');
      }
    }),
    allTimeDatasetFields: Ember.computed('nonFormattedElementaryFields.@each.dataType', {
      get: function get() {
        return this.get('nonFormattedElementaryFields').filterBy('dataType', 'time');
      }
    }),
    allFormattedFields: Ember.computed('allTimestampDatasetFields.[]', 'allTimeDatasetFields.[]', 'visualizationFields.@each.{type,format,timeUnit,dataSetField}', {
      get: function get() {
        var _this2 = this;

        var visualizationFields = this.get('visualizationFields');
        var allFormattedFields = Ember.A();
        var timestampFields = this.get('allTimestampDatasetFields');
        timestampFields.forEach(function (timestampField) {
          allFormattedFields.addObjects(_this2.formatTimestampField(timestampField));
        });
        var timeFields = this.get('allTimeDatasetFields');
        timeFields.forEach(function (timeField) {
          allFormattedFields.addObjects(_this2.formatTimeField(timeField));
        }); // do not duplicate the existing formatted-visualization fields

        if (Ember.isPresent(visualizationFields)) {
          allFormattedFields = allFormattedFields.filter(function (field) {
            for (var index = 0; index < visualizationFields.length; index++) {
              var visualizationField = visualizationFields[index];

              if ((field.hasOwnProperty('format') && visualizationField.format == field.format || field.hasOwnProperty('timeUnit') && visualizationField.timeUnit == field.timeUnit) && visualizationField.datasetField == field.datasetField && visualizationField.name == field.name) {
                return false;
              }

              return true;
            }
          });
        }

        return allFormattedFields;
      }
    }),
    formatTimestampField: function formatTimestampField(field) {
      var allFormats = Ember.A();

      var options = _constant.TIMESTAMP_FORMAT_OPTIONS.filter(function (option) {
        return !_constants.TIMESTAMP_BASED_FIELD_FORMATS.includes(option.id);
      });

      options.forEach(function (format) {
        var new_field = Ember.Object.create(field);
        new_field.name = "".concat(field.name, "-").concat(format.id.camelize());
        new_field.format = format.id;
        allFormats.addObject(new_field);
      });
      return allFormats;
    },
    formatTimeField: function formatTimeField(field) {
      var allFormats = Ember.A();

      _constant.TIME_FORMAT_OPTIONS.forEach(function (format) {
        var new_field = Ember.Object.create(field);
        new_field.name = "".concat(field.name, "-").concat(format.id.camelize());
        new_field.timeUnit = format.id;
        allFormats.addObject(new_field);
      });

      return allFormats;
    },
    allDataSetFieldOptions: Ember.computed('visualizationFields.[]', 'unusedDataSetFields.[]', 'userCreatedFields.[]', 'relatedDataSetFields.[]', 'allFormattedFields.[]', {
      get: function get() {
        var visualizationFields = this.get('visualizationFields');
        var unusedDataSetFields = this.get('unusedDataSetFields');
        var userCreatedFields = this.get('userCreatedFields');
        var allFormattedFields = this.get('allFormattedFields');
        var options = Ember.A().addObjects(visualizationFields).addObjects(unusedDataSetFields).addObjects(userCreatedFields).addObjects(allFormattedFields);
        var relatedDataSetFields = this.get('relatedDataSetFields');

        if (!Ember.isEmpty(relatedDataSetFields)) {
          return options.addObjects(relatedDataSetFields);
        }

        return options;
      }
    }),
    fieldsUsedInVisualization: Ember.computed('visualizationData.{rows.[],columns.[]}', {
      get: function get() {
        var visualizationData = this.get('visualizationData');
        var rowFields = visualizationData.get('rows');
        var columnFields = visualizationData.get('columns');
        var fields = Ember.A().addObjects(rowFields).addObjects(columnFields);
        return fields;
      }
    }),
    timestampVisualizationFields: Ember.computed('allDataSetFieldOptions.@each.dataType', {
      get: function get() {
        var fields = Ember.A([UNSELECT_FIELD]);
        var timestampFields = this.get('allDataSetFieldOptions').filterBy('dataType', 'timestamp');
        fields.addObjects(timestampFields);
        return fields;
      }
    }),
    aggregationFieldsOptionsForValues: Ember.computed('allDataSetFieldOptions.@each.type', {
      get: function get() {
        return this.get('allDataSetFieldOptions').filter(function (field) {
          return field.get('type') === 'aggregation' || field.get('type') === 'aggregation_formula';
        });
      }
    }),
    isNameChanged: Ember.computed('visualizationData.name', 'model.name', {
      get: function get() {
        var visualizationData = this.get('visualizationData');
        var model = this.get('model');
        return visualizationData.get('name').trim() !== model.get('name');
      }
    }),
    isDisplayTypeChanged: Ember.computed('visualizationData.displayType', 'model.displayType', {
      get: function get() {
        var visualizationData = this.get('visualizationData');
        var model = this.get('model');
        return visualizationData.get('displayType.id') !== model.get('displayType');
      }
    }),
    hasSortOptionChanges: Ember.computed('visualizationData.sortOptions.@each.{field,order}', 'query.sortOptions.@each.{field,order}', {
      get: function get() {
        var sortOptions = this.get('query.sortBy');
        var changedSortOptions = this.get('visualizationData.sortOptions');
        var hasSortOptionChanges = !(sortOptions.get('length') === changedSortOptions.get('length') && sortOptions.every(function (sortField, index) {
          return sortField.get('field') === changedSortOptions[index].get('name') && sortField.get('order') === changedSortOptions[index].get('order');
        }));
        return hasSortOptionChanges;
      }
    }),
    hasMatchAllQueryChanges: Ember.computed('visualizationData.matchAllQueryFilters.@each.{field,predicate,value}', 'query.filters.matchAll.@each.{field,predicate,value}', {
      get: function get() {
        var _this$get = this.get('query.filters'),
            matchAll = _this$get.matchAll;

        var changedMatchAllQueryFilters = this.get('visualizationData.matchAllQueryFilters');
        var hasMatchAllQueryChanges = !(matchAll.get('length') === changedMatchAllQueryFilters.get('length') && matchAll.every(function (filter, index) {
          return filter.get('field') === changedMatchAllQueryFilters[index].get('name') && filter.get('predicate') === changedMatchAllQueryFilters[index].get('predicate') && filter.get('value') === changedMatchAllQueryFilters[index].get('value');
        }));
        return hasMatchAllQueryChanges;
      }
    }),
    hasQueryRelatedChanges: Ember.computed('visualizationData.{dataSource,dataSet,rows.@each.name,columns.@each.name,values.@each.name,includeTotals,includePercentages}', 'query.{dataSource,dataSet,rows.[],columns.[],values.[],includeTotals,includePercentages}', 'hasSortOptionChanges', 'hasMatchAllQueryChanges', {
      get: function get() {
        var visualizationData = this.get('visualizationData');
        var query = this.get('query');
        return Number(visualizationData.get('dataSource.id')) !== query.get('dataSource') || Number(visualizationData.get('dataSet.id')) !== query.get('dataSet') || JSON.stringify(visualizationData.get('rows').mapBy('name')) !== JSON.stringify(query.get('rows')) || JSON.stringify(visualizationData.get('columns').mapBy('name')) !== JSON.stringify(query.get('columns')) || JSON.stringify(visualizationData.get('values').mapBy('name')) !== JSON.stringify(query.get('values')) || this.get('hasSortOptionChanges') || this.get('hasMatchAllQueryChanges') || visualizationData.get('includeTotals') !== query.get('includeTotals') || visualizationData.get('includePercentages.id') !== query.get('includePercentages');
      }
    }),
    areFilterFieldsChanged: Ember.computed('visualizationData.filterFields.@each.name', 'visualizationFields.@each.isEditable', {
      get: function get() {
        var filterFields = this.get('visualizationData.filterFields');
        var visualizationFields = this.get('visualizationFields').filterBy('isEditable');
        return JSON.stringify(filterFields.mapBy('name').sort()) !== JSON.stringify(visualizationFields.mapBy('name').sort());
      }
    }),
    hasValidDirtyChanges: Ember.computed('visualizationSettingsMessages.length', 'isNameChanged', 'isDisplayTypeChanged', 'hasQueryRelatedChanges', 'areFilterFieldsChanged', {
      get: function get() {
        return !this.get('visualizationSettingsMessages.length') && (this.get('isNameChanged') || this.get('isDisplayTypeChanged') || this.get('hasQueryRelatedChanges') || this.get('areFilterFieldsChanged'));
      }
    }),
    isPivotTableVisualization: Ember.computed('visualizationData.displayType', {
      get: function get() {
        return this.get('visualizationData.displayType.id') === 'pivot_table';
      }
    }),
    reportFieldsForOptIn: Ember.computed('reportLevelFields.@each.dataset', 'query.dataSet', {
      get: function get() {
        var dataSetId = this.get('query.dataSet');
        var reportLevelFields = this.get('reportLevelFields');
        return reportLevelFields.filterBy('dataset', Number(dataSetId));
      }
    }),
    pageFieldsForOptIn: Ember.computed('pageLevelFields.@each.dataset', 'query.dataSet', {
      get: function get() {
        var dataSetId = this.get('query.dataSet');
        var pageLevelFields = this.get('pageLevelFields');
        return pageLevelFields.filterBy('dataset', Number(dataSetId));
      }
    }),
    optedInReportFields: Ember.computed('reportFieldsForOptIn.[]', 'visualizationData.optedOutReportFields.[]', {
      get: function get() {
        var reportFieldsForOptIn = this.get('reportFieldsForOptIn');
        var optedOutReportFields = this.get('visualizationData.optedOutReportFields');
        return reportFieldsForOptIn.slice().removeObjects(optedOutReportFields);
      }
    }),
    optedInPageFields: Ember.computed('pageFieldsForOptIn.[]', 'visualizationData.optedOutPageFields.[]', {
      get: function get() {
        var pageFieldsForOptIn = this.get('pageFieldsForOptIn');
        var optedOutPageFields = this.get('visualizationData.optedOutPageFields');
        return pageFieldsForOptIn.slice().removeObjects(optedOutPageFields);
      }
    }),
    getDataSets: function getDataSets(dataSourceId) {
      return this.get('dataService').getDataSets(dataSourceId);
    },
    getDataSetFields: function getDataSetFields(dataSourceId, dataSetId) {
      return this.get('dataService').getDataSetFields(dataSourceId, dataSetId);
    },
    loadDependencies: function loadDependencies() {
      var _this3 = this;

      this.set('isLoadingDependencies', true);
      var dataSourceId = this.get('query.dataSource');
      var dataSetId = this.get('query.dataSet');
      var dataSources = this.get('dataService').peekAllDataSources();
      this.set('dataSources', dataSources);

      if (this.get('isNew')) {
        return Ember.RSVP.resolve().finally(function () {
          _this3.set('isLoadingDependencies', false);
        });
      }

      return Ember.RSVP.hash({
        dataSets: this.getDataSets(dataSourceId),
        dataSetFields: this.getDataSetFields(dataSourceId, dataSetId)
      }).then(function (data) {
        _this3.setProperties({
          dataSets: data.dataSets,
          dataSetFields: data.dataSetFields
        });

        var datasetTypeFields = data.dataSetFields.filterBy('dataType', 'dataset');

        if (datasetTypeFields.length) {
          _this3.getAllRelatedDataSetFields(datasetTypeFields).then(function (fields) {
            _this3.set('relatedDataSetFields', fields);
          });
        }
      }).finally(function () {
        _this3.set('isLoadingDependencies', false);
      });
    },
    createVisualizationData: function createVisualizationData() {
      var model = this.get('model');
      var query = this.get('query');
      var visualizationFields = this.get('visualizationFields');
      var rows = query.get('rows').map(function (field) {
        return visualizationFields.findBy('name', field);
      });
      var columns = query.get('columns').map(function (field) {
        return visualizationFields.findBy('name', field);
      });
      var values = query.get('values').map(function (field) {
        return visualizationFields.findBy('name', field);
      });
      var dateRangeField = visualizationFields.findBy('name', query.get('dateRangeField'));
      var filterFields = visualizationFields.filterBy('isEditable');
      var sortOptions = query.get('sortBy').map(function (item) {
        return Ember.Object.create({
          field: visualizationFields.findBy('name', item.get('field')),
          order: item.get('order')
        });
      });

      var _query$get = query.get('filters'),
          matchAll = _query$get.matchAll;

      var matchAllQueryFilters = matchAll.map(function (item) {
        return Ember.Object.create({
          field: item.get('field'),
          predicate: item.get('predicate'),
          value: item.get('value')
        });
      });
      var topNFilter = query.get('topNFilter');
      var displayType = model.get('displayType');
      var dataSourceId = query.get('dataSource');
      var dataSetId = query.get('dataSet');
      var reportLevelFields = this.get('reportLevelFields');
      var optedOutReportFields = (query.get('optedOutReportFields') || Ember.A()).map(function (fieldName) {
        return reportLevelFields.findBy('name', fieldName);
      }); // Needed to remove undefined which will be resulted from opted out field names of removed fields in report

      optedOutReportFields = optedOutReportFields.compact();
      optedOutReportFields.addObjects(reportLevelFields.rejectBy('dataset', Number(dataSetId)));
      var pageLevelFields = this.get('pageLevelFields');
      var optedOutPageFields = (query.get('optedOutPageFields') || Ember.A()).map(function (fieldName) {
        return pageLevelFields.findBy('name', fieldName);
      }); // Needed to remove undefined which will be resulted from opted out field names of removed fields in page

      optedOutPageFields = optedOutPageFields.compact();
      optedOutPageFields.addObjects(pageLevelFields.rejectBy('dataset', Number(dataSetId)));
      var visualizationData = Ember.Object.create({
        name: model.get('name'),
        displayType: this.get('visualizationTypeOptions').findBy('id', displayType),
        dataSource: dataSourceId && this.get('dataSources').findBy('id', dataSourceId.toString()),
        dataSet: dataSetId && this.get('dataSets').findBy('id', dataSetId.toString()),
        rows: rows,
        columns: columns,
        values: values,
        filterFields: filterFields,
        sortOptions: sortOptions,
        matchAllQueryFilters: matchAllQueryFilters,
        dateRangeField: dateRangeField,
        topNFilter: topNFilter,
        optedOutReportFields: optedOutReportFields,
        optedOutPageFields: optedOutPageFields
      });

      if (!this.get('isNew') && displayType === 'pivot_table') {
        visualizationData.setProperties({
          includeTotals: query.get('includeTotals'),
          includePercentages: this.get('includePercentagesOptions').findBy('id', query.get('includePercentages'))
        });
      }

      this.set('visualizationData', visualizationData);
    },
    setupDataSourceForCreateVisualization: function setupDataSourceForCreateVisualization() {
      if (this.get('isNew') && this.get('dataSources').length === 1) {
        this.send('onDataSourceChange', this.get('dataSources.firstObject'));
      }
    },
    validateVisualizationSettings: function validateVisualizationSettings() {
      this.set('visualizationSettingsMessages', Ember.A());
      var visualizationData = this.get('visualizationData');
      var visualizationType = visualizationData.get('displayType');
      var rowsCount = visualizationData.get('rows.length');
      var columnsCount = visualizationData.get('columns.length');
      var valuesCount = visualizationData.get('values.length');

      if (Ember.isEmpty(visualizationData.get('name'))) {
        this.get('visualizationSettingsMessages').addObject('Add name for visualization');
      }

      if (!visualizationData.get('dataSource')) {
        this.get('visualizationSettingsMessages').addObject('Select a data source');
      }

      if (!visualizationData.get('dataSet')) {
        this.get('visualizationSettingsMessages').addObject('Select a data set');
      }

      if (visualizationType) {
        switch (visualizationType.id) {
          case 'data_tile':
          case 'bar_chart':
          case 'line_chart':
          case 'area_chart':
          case 'doughnut_chart':
          case 'map_chart':
            if (!(rowsCount === 0 || rowsCount === 1) || columnsCount !== 0 || valuesCount !== 1) {
              this.get('visualizationSettingsMessages').addObjects(['Select 0 or 1 row field', 'Do not select any column fields', 'Select 1 value field']);
            }

            break;

          case 'data_table':
            if (rowsCount !== 0 || columnsCount < 2 || valuesCount !== 0) {
              this.get('visualizationSettingsMessages').addObjects(['Do not select any row fields', 'Select column fields, atleast 2', 'Do not select any value fields']);
            }

            break;

          case 'pivot_table':
            if (rowsCount === 0 || rowsCount > 2 || columnsCount === 0 && columnsCount > 2 || valuesCount === 0) {
              this.get('visualizationSettingsMessages').addObjects(['Select at least 1 row field and maximum of 2', 'Select at least 1 column field and maximum of 2', 'Select atleast 1 value field']);
            }

            break;

          case 'grouped_bar_chart':
          case 'stacked_bar_chart':
          case 'stacked_area_chart':
          case 'life_cycle_chart':
            if (rowsCount !== 1 || columnsCount !== 1 || valuesCount !== 1) {
              this.get('visualizationSettingsMessages').addObjects(['Select 1 row field', 'Select 1 column field', 'Select 1 value field']);
            }

            break;

          case 'multi_area_chart':
          case 'multi_line_chart':
            if (rowsCount !== 1 || columnsCount !== 0 && valuesCount == 0 || columnsCount == 0 && valuesCount < 2) {
              this.get('visualizationSettingsMessages').addObjects(['Select 1 row field', 'Select 1 column and 1 value field or 0 column, and atleast 2 value fields']);
            }

            break;

          case 'multi_axis_chart':
            if (rowsCount !== 1 || columnsCount !== 0 || valuesCount !== 2) {
              this.get('visualizationSettingsMessages').addObjects(['Select 1 row field', 'Do not select any column fields', 'Select 2 value fields']);
            }

            break;
        }
      } else {
        this.get('visualizationSettingsMessages').addObject('Select a visualization');
      }

      return this.get('visualizationSettingsMessages.length') === 0;
    },
    generateDisplaySettings: function generateDisplaySettings() {
      var displaySettings = Ember.Object.create();
      var visualizationData = this.get('visualizationData');
      var visualizationType = visualizationData.get('displayType');
      var rowFields = visualizationData.get('rows');
      var columnFields = visualizationData.get('columns');
      var valueFields = visualizationData.get('values');
      var visualizationSize = this.get('model.displaySettings.size');
      displaySettings.set('size', visualizationSize || 'square-l');

      switch (visualizationType.id) {
        case 'data_tile':
          displaySettings.setProperties({
            label: rowFields.get('length') ? rowFields[0].get('name') : '',
            value: valueFields[0].get('name')
          });
          break;

        case 'bar_chart':
        case 'line_chart':
        case 'area_chart':
          displaySettings.setProperties({
            xAxis: Ember.Object.create({
              key: rowFields[0].get('name'),
              label: rowFields[0].get('name')
            }),
            yAxis: Ember.Object.create({
              key: valueFields[0].get('name'),
              label: valueFields[0].get('name')
            })
          });
          break;

        case 'doughnut_chart':
          displaySettings.setProperties({
            category: rowFields[0].get('name'),
            value: valueFields[0].get('name')
          });
          break;

        case 'map_chart':
          displaySettings.setProperties({
            region: rowFields[0].get('name'),
            value: valueFields[0].get('name')
          });
          break;

        case 'data_table':
          displaySettings.setProperties({
            columns: columnFields.map(function (field) {
              return Ember.Object.create({
                key: field.get('name'),
                label: field.get('name')
              });
            })
          });
          break;

        case 'pivot_table':
          displaySettings.setProperties({
            rows: rowFields.map(function (field) {
              return Ember.Object.create({
                key: field.get('name'),
                label: field.get('name')
              });
            }),
            columns: columnFields.map(function (field) {
              return Ember.Object.create({
                key: field.get('name'),
                label: field.get('name')
              });
            }),
            values: valueFields.map(function (field) {
              return Ember.Object.create({
                key: field.get('name'),
                label: field.get('name')
              });
            }),
            showTotals: this.get('visualizationData.includeTotals'),
            showPercentages: Ember.isPresent(this.get('visualizationData.includePercentages.id'))
          });
          break;

        case 'grouped_bar_chart':
        case 'stacked_bar_chart':
        case 'stacked_area_chart':
        case 'multi_area_chart':
        case 'multi_line_chart':
          displaySettings.set('xAxis', {
            key: rowFields[0].get('name'),
            type: rowFields[0].get('dataType') !== 'timestamp' ? 'category' : 'timestamp'
          });

          if (columnFields.length) {
            displaySettings.setProperties({
              yAxis: {
                label: valueFields[0].get('name'),
                groupBy: columnFields[0].get('name'),
                value: valueFields[0].get('name')
              }
            });
          } else {
            var groups = valueFields.map(function (field) {
              return Ember.Object.create({
                key: field.get('name'),
                label: field.get('name')
              });
            });
            displaySettings.setProperties({
              yAxis: {
                label: valueFields[0].get('name'),
                groupBy: null
              },
              groups: groups
            });
          }

          break;

        case 'multi_axis_chart':
          displaySettings.setProperties({
            xAxis: {
              key: rowFields[0].get('name'),
              label: rowFields[0].get('name')
            },
            yAxis: [{
              key: valueFields[0].get('name'),
              label: valueFields[0].get('name')
            }, {
              key: valueFields[1].get('name'),
              label: valueFields[1].get('name')
            }]
          });
          break;

        case 'life_cycle_chart':
          displaySettings.setProperties({
            xAxis: {
              key: rowFields[0].get('name'),
              label: rowFields[0].get('name')
            },
            yAxis: {
              key: columnFields[0].get('name'),
              label: columnFields[0].get('name'),
              value: valueFields[0].get('name')
            }
          });
      }

      return displaySettings;
    },
    triggerPreviewReload: function triggerPreviewReload() {
      var displaySettings = this.generateDisplaySettings();
      var visualizationData = this.get('visualizationData');
      var previewModel = Ember.Object.create();
      previewModel.setProperties({
        name: visualizationData.get('name'),
        displayType: visualizationData.get('displayType.id'),
        displaySettings: displaySettings,
        settings: this.getSettingsData(true)
      });
      this.set('previewModel', previewModel);
    },
    getSettingsData: function getSettingsData() {
      var isPreview = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var visualizationData = this.get('visualizationData');
      var dateRangeField = visualizationData.get('dateRangeField');
      var rows = visualizationData.get('rows');
      var columns = visualizationData.get('columns');
      var values = visualizationData.get('values');
      var sortOptions = visualizationData.get('sortOptions').map(function (item) {
        return {
          field: item.get('field.name'),
          order: item.get('order')
        };
      });
      var allUsedFieldsArray = Ember.A().addObjects(rows).addObjects(columns).addObjects(values);
      allUsedFieldsArray.addObjects(visualizationData.get('sortOptions').mapBy('field'));
      allUsedFieldsArray.addObjects(visualizationData.get('filterFields'));

      if (dateRangeField) {
        allUsedFieldsArray.addObject(dateRangeField);
      }

      var allDataSetFieldOptions = this.get('allDataSetFieldOptions');
      var matchAllQueryFilters = visualizationData.get('matchAllQueryFilters');
      var matchAllQueryFilterFields = matchAllQueryFilters.map(function (item) {
        return allDataSetFieldOptions.findBy('name', item.field);
      });
      allUsedFieldsArray.addObjects(matchAllQueryFilterFields);
      allUsedFieldsArray.forEach(function (field, index) {
        var fieldCopy = Ember.Object.create(field);
        field.set('isEditable', false);
        allUsedFieldsArray[index] = fieldCopy;
      });
      visualizationData.get('filterFields').forEach(function (filterField) {
        var field = allUsedFieldsArray.findBy('name', filterField.get('name'));
        field.set('isEditable', true);
      });
      var topNFilter = visualizationData.get('topNFilter');
      var optedOutReportFields = visualizationData.get('optedOutReportFields').mapBy('name');
      var optedOutPageFields = visualizationData.get('optedOutPageFields').mapBy('name');
      var settingsData = Ember.Object.create({
        fields: allUsedFieldsArray,
        query: Ember.Object.create({
          dataSource: visualizationData.get('dataSource.id'),
          dataSet: visualizationData.get('dataSet.id'),
          dateRangeField: dateRangeField ? dateRangeField.get('name') : null,
          rows: rows.mapBy('name'),
          columns: columns.mapBy('name'),
          values: values.mapBy('name'),
          sortBy: sortOptions,
          filters: {
            matchAll: matchAllQueryFilters
          },
          'top_n_filter': topNFilter,
          optedOutReportFields: optedOutReportFields,
          optedOutPageFields: optedOutPageFields
        })
      });

      if (this.get('isPivotTableVisualization')) {
        settingsData.get('query').setProperties({
          includeTotals: visualizationData.get('includeTotals'),
          includePercentages: visualizationData.get('includePercentages.id')
        });
      }

      if (isPreview) {
        var pageId = this.get('pageId');
        settingsData.set('page', pageId);
      }

      return settingsData;
    },
    getAllRelatedDataSetFields: function getAllRelatedDataSetFields() {
      var _this4 = this;

      var datasetFields = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : Ember.A();
      var relatedFieldsArray = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Ember.A();
      var datasetField = datasetFields.pop();
      var relatedDatasetId = datasetField.get('relatedDataset').toString();
      var relatedDataSet = this.get('dataSets').findBy('id', relatedDatasetId);
      var parentDataset = datasetField.get('dataset');
      var parentDatasetField = datasetField.get('id');
      return this.getDataSetFields(relatedDataSet.get('dataSource'), relatedDatasetId).then(function (fields) {
        fields.forEach(function (field) {
          if (field.dataType === 'dataset') {// TODO: Remove the comment, once we figure out a way to chain the parent id in backend.
            // datasetFields.push(field);
          } else {
            var relatedField = Ember.Object.create({
              'name': "".concat(relatedDataSet.get('name'), "-").concat(field.get('name')),
              'type': 'dataset_field',
              'dataset': relatedDatasetId,
              'dataType': field.get('dataType'),
              'isEditable': false,
              'datasetField': field.get('id'),
              parentDataset: parentDataset,
              parentDatasetField: parentDatasetField
            });
            relatedFieldsArray.addObject(relatedField);
          }
        });

        if (datasetFields.length) {
          return _this4.getAllRelatedDataSetFields(datasetFields, relatedFieldsArray);
        }

        return relatedFieldsArray;
      });
    },
    actions: {
      getPreviewData: function getPreviewData() {
        var settings = this.getSettingsData(true);
        return this.get('dataService').getVisualizationData({
          settings: settings
        });
      },
      onNameChange: function onNameChange() {
        var changedName = this.get('visualizationData.name');
        this.get('previewModel').set('name', changedName);
      },
      onDisplayTypeChange: function onDisplayTypeChange(displayType) {
        this.set('visualizationData.displayType', displayType);

        if (this.validateVisualizationSettings()) {
          this.triggerPreviewReload();
        }
      },
      onDataSourceChange: function onDataSourceChange(dataSource) {
        var _this5 = this;

        var dataSourceId = dataSource.get('id');
        this.set('visualizationData.dataSource', dataSource);
        this.get('visualizationData').setProperties({
          dataSet: Ember.A(),
          rows: Ember.A(),
          columns: Ember.A(),
          values: Ember.A(),
          filterFields: Ember.A(),
          sortOptions: Ember.A(),
          matchAllQueryFilters: Ember.A(),
          topNFilter: {}
        });
        this.setProperties({
          dataSets: Ember.A(),
          dataSetFields: Ember.A(),
          userCreatedFields: Ember.A(),
          relatedDataSetFields: Ember.A()
        });
        this.getDataSets(dataSourceId).then(function (data) {
          _this5.set('dataSets', data);
        });
        this.set('visualizationSettingsMessages', Ember.A(['Select a Data Set']));
      },
      onDataSetChange: function onDataSetChange(dataSet) {
        var _this6 = this;

        var dataSourceId = this.get('visualizationData.dataSource.id');
        var dataSetId = dataSet.get('id');
        this.set('visualizationData.dataSet', dataSet);
        this.get('visualizationData').setProperties({
          rows: Ember.A(),
          columns: Ember.A(),
          values: Ember.A(),
          filterFields: Ember.A(),
          sortOptions: Ember.A(),
          matchAllQueryFilters: Ember.A(),
          topNFilter: {}
        });
        this.setProperties({
          dataSetFields: Ember.A(),
          userCreatedFields: Ember.A(),
          relatedDataSetFields: Ember.A()
        });
        this.getDataSetFields(dataSourceId, dataSetId).then(function (data) {
          _this6.set('dataSetFields', data);

          var datasetTypeFields = data.filterBy('dataType', 'dataset');

          if (datasetTypeFields.length) {
            _this6.getAllRelatedDataSetFields(datasetTypeFields).then(function (fields) {
              _this6.set('relatedDataSetFields', fields);
            });
          }

          _this6.validateVisualizationSettings();
        });
      },
      onDateRangeFieldChange: function onDateRangeFieldChange(dateRangeField) {
        dateRangeField = dateRangeField === UNSELECT_FIELD ? null : dateRangeField;
        this.set('visualizationData.dateRangeField', dateRangeField);

        if (this.validateVisualizationSettings()) {
          this.triggerPreviewReload();
        }
      },
      onRowsChange: function onRowsChange(rows) {
        this.set('visualizationData.rows', rows);

        if (this.validateVisualizationSettings()) {
          this.triggerPreviewReload();
        }
      },
      onColumnsChange: function onColumnsChange(columns) {
        this.set('visualizationData.columns', columns);

        if (this.validateVisualizationSettings()) {
          this.triggerPreviewReload();
        }
      },
      createDerivedFieldAndAddAsValue: function createDerivedFieldAndAddAsValue(derivedField) {
        var values = this.get('visualizationData.values');
        this.get('userCreatedFields').addObject(derivedField);
        this.send('onValuesChange', values.addObject(derivedField));
      },
      addDerivedField: function addDerivedField(derivedField) {
        this.get('userCreatedFields').addObject(derivedField);
      },
      onUpdatingDerivedField: function onUpdatingDerivedField() {
        if (this.validateVisualizationSettings()) {
          this.triggerPreviewReload();
        }
      },
      onValuesChange: function onValuesChange(values) {
        this.set('visualizationData.values', values);

        if (this.validateVisualizationSettings()) {
          this.triggerPreviewReload();
        }
      },
      onFilterFieldsChange: function onFilterFieldsChange(fields) {
        this.set('visualizationData.filterFields', fields);
      },
      onSortOptionsChange: function onSortOptionsChange() {
        if (this.validateVisualizationSettings()) {
          this.triggerPreviewReload();
        }
      },
      onIncludePrecentagesChange: function onIncludePrecentagesChange(optionSelected) {
        this.set('visualizationData.includePercentages', optionSelected);

        if (this.validateVisualizationSettings()) {
          this.triggerPreviewReload();
        }
      },
      onIncludeTotalsChange: function onIncludeTotalsChange() {
        var isSelected = this.get('visualizationData.includeTotals');
        this.set('visualizationData.includeTotals', !isSelected);

        if (this.validateVisualizationSettings()) {
          this.triggerPreviewReload();
        }
      },
      applyFilters: function applyFilters(filters) {
        this.set('visualizationData.matchAllQueryFilters', filters);

        if (this.validateVisualizationSettings()) {
          this.triggerPreviewReload();
        }
      },
      onTopNFilterChange: function onTopNFilterChange(filter) {
        this.set('visualizationData.topNFilter', filter);

        if (this.validateVisualizationSettings()) {
          this.triggerPreviewReload();
        }
      },
      onReportFieldsOptInChange: function onReportFieldsOptInChange(optInFields) {
        var reportLevelFields = this.get('reportLevelFields');
        var optedOutFields = reportLevelFields.slice().removeObjects(optInFields);
        this.set('visualizationData.optedOutReportFields', optedOutFields);

        if (this.validateVisualizationSettings()) {
          this.triggerPreviewReload();
        }
      },
      onPageFieldsOptInChange: function onPageFieldsOptInChange(optInFields) {
        var pageLevelFields = this.get('pageLevelFields');
        var optedOutFields = pageLevelFields.slice().removeObjects(optInFields);
        this.set('visualizationData.optedOutPageFields', optedOutFields);

        if (this.validateVisualizationSettings()) {
          this.triggerPreviewReload();
        }
      },
      applyDateRange: function applyDateRange(data) {
        // calling the func from mixin
        this.saveDateRange(data);
      },
      triggerPreview: function triggerPreview() {
        this.triggerPreviewReload();
      },
      save: function save() {
        var _this7 = this;

        if (this.validateVisualizationSettings()) {
          var visualizationData = this.get('visualizationData');
          var model = this.get('model');

          if (this.get('isNameChanged')) {
            model.set('name', visualizationData.get('name'));
          }

          var isDisplayTypeChanged = this.get('isDisplayTypeChanged');

          if (this.get('isDisplayTypeChanged')) {
            model.set('displayType', visualizationData.get('displayType.id'));
          }

          var hasQueryRelatedChanges = this.get('hasQueryRelatedChanges');

          if (this.get('hasQueryRelatedChanges')) {
            var settings = this.getSettingsData();
            model.set('settings', settings);
          }

          if (isDisplayTypeChanged || hasQueryRelatedChanges) {
            var displaySettings = this.generateDisplaySettings();
            model.set('displaySettings', displaySettings);
          }

          if (this.get('isNew')) {
            model.set('page', this.get('pageId'));
          }

          model.save().then(function () {
            var pageId = _this7.get('pageId');

            var reportId = _this7.get('reportId');

            _this7.transitionToRoute('reports.details.pages.details', reportId, pageId);
          });
        }
      },
      cancel: function cancel() {
        var pageId = this.get('pageId');
        var reportId = this.get('reportId');
        this.transitionToRoute('reports.details.pages.details', reportId, pageId);
      }
    }
  });

  _exports.default = _default;
});