define("happyfox-bi/components/widget-tile/move-visualization-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3ZU2efTR",
    "block": "{\"symbols\":[\"field\"],\"statements\":[[6,[37,10],null,[[\"onClose\"],[[30,[36,1],[[32,0],\"closeModal\"],null]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"modal_header_title\"],[12],[1,[30,[36,0],[\"move-visualization\"],null]],[13],[2,\"\\n  \"],[11,\"div\"],[24,\"role\",\"button\"],[24,0,\"modal_close\"],[4,[38,1],[[32,0],\"closeModal\"],null],[12],[1,[30,[36,2],[\"modal-close\"],null]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"modal_body mod-modal-small\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"field\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"field_label\"],[12],[1,[30,[36,0],[\"select-page-{visualizationName}\"],[[\"visualizationName\"],[[35,3,[\"name\"]]]]]],[13],[2,\"\\n\"],[6,[37,6],null,[[\"tagName\",\"class\",\"dropdownClass\",\"options\",\"placeholder\",\"selected\",\"onChange\",\"searchEnabled\",\"searchField\",\"renderInPlace\"],[\"div\",\"mod-dropdown mod-local-autocomplete field_value\",\"mod-push-dropdown-up\",[35,5],[30,[36,0],[\"select-page\"],null],[35,4],[30,[36,1],[[32,0],\"onPageChange\"],null],true,\"name\",true]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[32,1,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"invite-user-modal_footer\"],[12],[2,\"\\n      \"],[1,[30,[36,9],null,[[\"class\",\"text\",\"inProgress\",\"inProgressText\",\"disabled\",\"onClick\"],[\"hf-primary-action\",[30,[36,0],[\"move\"],null],[35,8],[30,[36,0],[\"moving\"],null],[35,7],[30,[36,1],[[32,0],\"move\"],null]]]]],[2,\"\\n      \"],[11,\"a\"],[24,0,\"secondary-action\"],[24,\"role\",\"button\"],[4,[38,1],[[32,0],\"closeModal\"],null],[12],[1,[30,[36,0],[\"cancel\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"inline-svg\",\"visualization\",\"selectedPage\",\"pageOptions\",\"power-select\",\"isSaveDisabled\",\"saveInProgress\",\"async-button\",\"modal-dialog\"]}",
    "meta": {
      "moduleName": "happyfox-bi/components/widget-tile/move-visualization-modal/template.hbs"
    }
  });

  _exports.default = _default;
});