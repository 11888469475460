define("happyfox-bi/components/base-chart/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Y_AXIS_EXTRA_MAX = _exports.TIME_UNIT_SHORTHAND = _exports.TIMESTAMP_BASED_FIELD_FORMATS = _exports.NON_DATE_API_RESPONSE_FORMATS = _exports.MONTHS_ORDER = _exports.FORMAT_SWITCHER_TIMESTAMP_BASED_OPTIONS = _exports.FORMAT_SWITCHER_DATE_BASED_OPTIONS = _exports.FIELD_TYPES_FORMATTED_AS_CURRENCY = _exports.DURATION_FORMATTER_UNIT_MAP = _exports.DAY_OF_WEEKS_ORDER = _exports.DATE_BASED_FIELD_FORMATS = void 0;
  var DAY_OF_WEEKS_ORDER = [{
    'order': 1,
    'text': 'Mon'
  }, {
    'order': 2,
    'text': 'Tue'
  }, {
    'order': 3,
    'text': 'Wed'
  }, {
    'order': 4,
    'text': 'Thu'
  }, {
    'order': 5,
    'text': 'Fri'
  }, {
    'order': 6,
    'text': 'Sat'
  }, {
    'order': 7,
    'text': 'Sun'
  }];
  _exports.DAY_OF_WEEKS_ORDER = DAY_OF_WEEKS_ORDER;
  var MONTHS_ORDER = [{
    'order': 1,
    'text': 'Jan'
  }, {
    'order': 2,
    'text': 'Feb'
  }, {
    'order': 3,
    'text': 'Mar'
  }, {
    'order': 4,
    'text': 'Apr'
  }, {
    'order': 5,
    'text': 'May'
  }, {
    'order': 6,
    'text': 'Jun'
  }, {
    'order': 7,
    'text': 'Jul'
  }, {
    'order': 8,
    'text': 'Aug'
  }, {
    'order': 9,
    'text': 'Sep'
  }, {
    'order': 10,
    'text': 'Oct'
  }, {
    'order': 11,
    'text': 'Nov'
  }, {
    'order': 12,
    'text': 'Dec'
  }];
  _exports.MONTHS_ORDER = MONTHS_ORDER;
  var DURATION_FORMATTER_UNIT_MAP = [{
    id: 'seconds',
    baseUnit: 'second'
  }, {
    id: 'hours',
    baseUnit: 'hour'
  }, {
    id: 'minutes',
    baseUnit: 'minute'
  }, {
    id: 'days',
    baseUnit: 'day'
  }];
  _exports.DURATION_FORMATTER_UNIT_MAP = DURATION_FORMATTER_UNIT_MAP;
  var TIME_UNIT_SHORTHAND = [{
    'id': 'seconds',
    'unit': 's'
  }, {
    'id': 'minutes',
    'unit': 'm'
  }, {
    'id': 'hours',
    'unit': 'h'
  }, {
    'id': 'days',
    'unit': 'd'
  }];
  _exports.TIME_UNIT_SHORTHAND = TIME_UNIT_SHORTHAND;
  var FIELD_TYPES_FORMATTED_AS_CURRENCY = ['int', 'decimal'];
  _exports.FIELD_TYPES_FORMATTED_AS_CURRENCY = FIELD_TYPES_FORMATTED_AS_CURRENCY;
  var FORMAT_SWITCHER_TIMESTAMP_BASED_OPTIONS = ['year', 'quarter', 'month', 'date', 'hour_with_date', 'day_of_week', 'week_of_month', 'week_with_month', 'month_with_year', 'quarter_with_year', 'week_with_year'];
  _exports.FORMAT_SWITCHER_TIMESTAMP_BASED_OPTIONS = FORMAT_SWITCHER_TIMESTAMP_BASED_OPTIONS;
  var FORMAT_SWITCHER_DATE_BASED_OPTIONS = ['year', 'quarter', 'month', 'date', 'day_of_week', 'week_of_month', 'week_with_month', 'month_with_year', 'quarter_with_year', 'week_with_year'];
  _exports.FORMAT_SWITCHER_DATE_BASED_OPTIONS = FORMAT_SWITCHER_DATE_BASED_OPTIONS;
  var TIMESTAMP_BASED_FIELD_FORMATS = ['month_with_year', 'date', 'hour_with_date', 'week_with_month', 'year', 'week_with_year'];
  _exports.TIMESTAMP_BASED_FIELD_FORMATS = TIMESTAMP_BASED_FIELD_FORMATS;
  var DATE_BASED_FIELD_FORMATS = ['month_with_year', 'date', 'week_with_month', 'year', 'week_with_year'];
  _exports.DATE_BASED_FIELD_FORMATS = DATE_BASED_FIELD_FORMATS;
  var NON_DATE_API_RESPONSE_FORMATS = ['day_of_week', 'month', 'week_of_month', 'quarter', 'quarter_with_year'];
  _exports.NON_DATE_API_RESPONSE_FORMATS = NON_DATE_API_RESPONSE_FORMATS;
  var Y_AXIS_EXTRA_MAX = 0.1;
  _exports.Y_AXIS_EXTRA_MAX = Y_AXIS_EXTRA_MAX;
});