define("happyfox-bi/components/auto-complete/remote/single/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c69zEmoc",
    "block": "{\"symbols\":[\"result\",\"&default\"],\"statements\":[[6,[37,11],null,[[\"placeholder\",\"searchEnabled\",\"searchPlaceholder\",\"selected\",\"autofocus\",\"onChange\",\"onKeydown\",\"search\",\"triggerClass\",\"dropdownClass\",\"noMatchesMessage\",\"initiallyOpened\",\"renderInPlace\",\"isAddNew\",\"onOpen\",\"onClose\"],[[35,10],true,[35,9],[35,8],[35,7],[30,[36,1],[[32,0],\"onChange\"],null],[30,[36,1],[[32,0],\"createCustomChoice\"],null],[30,[36,1],[[32,0],\"search\"],null],[35,6],[35,5],[35,4],[35,3],true,[35,2],[30,[36,1],[[32,0],\"onOpen\"],null],[30,[36,1],[[32,0],\"onClose\"],null]]],[[\"default\"],[{\"statements\":[[6,[37,0],[[27,[32,2]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,2,[[32,1]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[32,1]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"action\",\"isAddNew\",\"initiallyOpened\",\"noMatchesMessage\",\"dropdownClass\",\"triggerClass\",\"autofocus\",\"selected\",\"searchPlaceholder\",\"placeholder\",\"power-select\"]}",
    "meta": {
      "moduleName": "happyfox-bi/components/auto-complete/remote/single/template.hbs"
    }
  });

  _exports.default = _default;
});