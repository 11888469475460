define("happyfox-bi/components/bar-chart/component", ["exports", "happyfox-bi/components/base-column-chart/component", "@amcharts/amcharts4/core", "@amcharts/amcharts4/charts", "happyfox-bi/constants"], function (_exports, _component, am4core, am4charts, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    dataService: Ember.inject.service('data'),
    settings: Ember.computed.reads('widgetModel.settings'),
    drillCategories: null,
    drillFilters: null,
    selectedDateFormat: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.setProperties({
        drillCategories: [],
        drillFilters: []
      });
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.createChart();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.chartParentContainer.dispose();
    },
    getData: function getData(requestPayload) {
      return this.get('dataService').getVisualizationDrillDownData(requestPayload);
    },
    getRequestPayload: function getRequestPayload(settings) {
      var requestData = {
        settings: settings
      };

      if (!this.get('isPreview')) {
        requestData.visualizationId = this.get('widgetModel.id');
      }

      if (settings) {
        requestData.isDrillDown = true;
      }

      return requestData;
    },
    getSettings: function getSettings() {
      var drillDownFields = this.get('settings.query.drillDownFields');

      if (this.get('settings.query.drillDownFields.length')) {
        var visualization = this.get('widgetModel');
        var drillFilters = this.get('drillFilters');
        var drillCategories = this.get('drillCategories');
        var settings = Ember.Object.create(visualization.get('settings'));
        var query = Ember.Object.create(settings.get('query'));
        var filters = Ember.Object.create(query.get('filters'));
        var matchAllFilters = filters.get('matchAll').slice();
        matchAllFilters.addObjects(drillFilters);
        filters.set('matchAll', matchAllFilters);
        var row = drillCategories.get('length') ? drillDownFields[drillCategories.get('length') - 1] : query.get('rows.firstObject');
        query.setProperties({
          filters: filters,
          rows: [row]
        });
        settings.set('query', query);
        var config = Ember.Object.create(this.get('config'));
        var rowField = settings.fields.findBy('name', row);
        config.xAxis = {
          'key': rowField.name,
          'label': rowField.displayName
        };
        this.set('config', config);
        return settings;
      }
    },
    drillDown: function drillDown(fieldName, filters) {
      var _this = this;

      this.get('drillCategories').push(fieldName);
      this.get('drillFilters').addObjects(filters);
      var settings = this.getSettings();
      var requestData = this.getRequestPayload(settings);
      this.getData(requestData).then(function (data) {
        _this.set('data', data);

        _this.processData();

        _this.createAndReplaceChart(true);
      });
    },
    drillUp: function drillUp() {
      var _this2 = this;

      var removedCategoryName = this.get('drillCategories').pop();
      var drillUpFilters = this.get('drillFilters').rejectBy('field', removedCategoryName);
      this.set('drillFilters', drillUpFilters);
      var settings = this.getSettings();
      var requestData = this.getRequestPayload(settings);
      this.getData(requestData).then(function (data) {
        _this2.set('data', data);

        _this2.processData();

        _this2.createAndReplaceChart(false);
      });
    },
    createAndReplaceChart: function createAndReplaceChart() {
      this.button.hide();
      var oldChartContainer = this.chartParentContainer;
      oldChartContainer.dispose();
      this.createChart();

      if (this.get('drillFilters.length')) {
        this.button.show();
      } else {
        this.button.hide();
      }
    },
    createChart: function createChart() {
      var _this3 = this;

      var chartContainer = this.get('chartContainer'); // A Parent container is needed to position the drilldown button
      // Default layout of container is `absolute` i.e chart / button are absolutely positioned
      // Refer https://www.amcharts.com/docs/v4/concepts/svg-engine/containers/#Layout_types

      var chartParentContainer = am4core.create(chartContainer, am4core.Container);
      chartParentContainer.width = am4core.percent(100);
      chartParentContainer.height = am4core.percent(100);
      var chart = new am4charts.XYChart();
      chart.parent = chartParentContainer;
      var isChartComparison = this.get('isChartComparison');
      var isDateXAxis = this.isXAxisDateType();

      var _this$get = this.get('config'),
          xAxisKey = _this$get.xAxis.key,
          valueKey = _this$get.yAxis.key;

      var isYAxisCurrencyType = this.isFieldCurrencyType(valueKey);
      var isDurationYAxis = this.canFormatTimeAsDuration(valueKey);
      var isYAxisTimeType = this.isFieldTimeType(valueKey);
      var data = this.generateTopNData(this.get('data')); // Create axes

      var xAxis = chart.xAxes.push(isDateXAxis ? new am4charts.DateAxis() : new am4charts.CategoryAxis());

      if (isDateXAxis) {
        this.prepareDateAxis(xAxis);
      } else {
        xAxis.dataFields.category = xAxisKey;
        xAxis.data = data;
      }

      var xAxisTitle = this.get('config.xAxis.label');
      this.setAxisTitle(xAxis, xAxisTitle);
      xAxis.renderer.minGridDistance = 30;
      xAxis.renderer.grid.template.location = 0;
      xAxis.renderer.cellStartLocation = 0.2;
      xAxis.renderer.cellEndLocation = 0.8;
      var label = xAxis.renderer.labels.template;
      label.truncate = true;
      label.maxWidth = 120;
      label.tooltipText = "{category}";
      label.rotation = 315;
      label.verticalCenter = "top";
      label.horizontalCenter = "right";
      var valueAxis = isDurationYAxis ? chart.yAxes.push(new am4charts.DurationAxis()) : chart.yAxes.push(new am4charts.ValueAxis());
      this.setAxisRanges(xAxis, valueAxis);
      var showValueLabels = this.get('showValueLabels');
      var yAxisExtraMax = this.get('yAxisExtraMax');
      var valueAxisTitle = this.get('config.yAxis.label');
      this.setAxisTitle(valueAxis, valueAxisTitle);

      if (isDurationYAxis) {
        this.setupDurationFormatter(valueAxis, valueKey);
      } else if (isYAxisTimeType) {
        this.setUnitsInAxisLabels(valueAxis, this.getTimeFieldBaseUnit(valueKey));
      } // Color Skip must be defined before series creation.
      // Refer https://www.amcharts.com/docs/v4/concepts/colors/#XY_Chart_and_derivative_charts


      if (isChartComparison) {
        chart.colors.step = 3;
      }

      if (showValueLabels) {
        valueAxis.extraMax = yAxisExtraMax; // Expands YAxis Scale by adding 1% to y-axis max value to support value label text
      } // Create series


      var series = this.createSeries(chart);
      series.name = isChartComparison ? this.get('intlService').lookup('current-period') : this.get('config.xAxis.label');

      if (isDateXAxis) {
        series.data = this.convertAndGetDateXAxisData(data, xAxisKey);
      } else {
        series.data = data;
      }

      this.setSeriesTooltipText(series);

      if (isChartComparison) {
        var compareDateRangesList = this.get('compareDateRangesList');
        compareDateRangesList.forEach(function (dateRangeField) {
          var compareSeries = _this3.createSeries(chart);

          if (isDateXAxis) {
            compareSeries.data = _this3.prepareDateAxisCompareData(dateRangeField, xAxisKey);

            _this3.setSeriesTooltipText(compareSeries, 'originalTimeStamp');
          } else {
            compareSeries.data = dateRangeField.data;

            _this3.setSeriesTooltipText(compareSeries);
          }

          compareSeries.name = _this3.getCompareSeriesName(dateRangeField);
          compareSeries.isCompareSeries = true;
        });
      }

      if (isYAxisCurrencyType) {
        this.setElementNumberFormatAsCurrency(chart, valueKey);
      } // Add button


      var button = chartParentContainer.createChild(am4core.Button);
      button.padding(5, 5, 5, 5);
      button.width = 25;
      button.align = 'right';
      button.valign = 'top';
      button.marginRight = 35;
      button.events.on('hit', function () {
        _this3.drillUp();
      });
      button.icon = new am4core.Sprite();
      button.icon.path = 'M16,8 L14,8 L14,16 L10,16 L6,16 L2,16 L2,8 L0,8 L8,0 L16,8 Z M16,8';
      button.hide();
      this.button = button;
      chart.cursor = new am4charts.XYCursor();

      if (isChartComparison) {
        this.createLegend(chart);
      } // Benchmarks


      if (this.get('canManageBenchmarks')) {
        this.setUpBenchmarks(chart, valueAxis);
      }

      this.addExportOption(chart);
      this.addAnnotationsOption(chart);
      this.chart = chart;
      this.chartParentContainer = chartParentContainer;
      return chart;
    },
    createSeries: function createSeries(chart) {
      var series = chart.series.push(new am4charts.ColumnSeries());
      var isDateXAxis = this.isXAxisDateType();
      series.dataFields.valueY = this.get('config.yAxis.key');

      if (isDateXAxis) {
        series.dataFields.dateX = this.get('config.xAxis.key');
      } else {
        series.dataFields.categoryX = this.get('config.xAxis.key');
        series.tooltipText = "{categoryX}: [bold]{valueY}[/]";
      }

      var configColor = this.get('config.color');

      if (configColor) {
        var columnTemplate = series.columns.template;
        columnTemplate.fill = configColor;
        columnTemplate.stroke = configColor;
      }

      this.handleSeriesClick(series);

      if (this.get('showValueLabels')) {
        this.createValueLabels(series);
      }

      return series;
    },
    handleSeriesClick: function handleSeriesClick(series) {
      var _this4 = this;

      series.columns.template.events.on("hit", function (ev) {
        var isCompareSeries = series.isCompareSeries;

        var isDateXAxis = _this4.isXAxisDateType(); // TODO: Raw data and drill down need to be handled for compare series


        if (!isCompareSeries) {
          var categoryKey = _this4.get('config.xAxis.key');

          var filters = [];

          if (isDateXAxis) {
            var categoryValue = ev.target.dataItem.dateX;

            var dateFilters = _this4.getDrillFiltersForDateAxis(series.data, categoryKey, categoryValue);

            filters.addObjects(dateFilters);
          } else {
            var _categoryValue = ev.target.dataItem.categoryX;

            var filtersForCategoryValue = _this4.getFiltersForFieldValue(categoryKey, _categoryValue);

            filters.addObjects(filtersForCategoryValue);
          }

          var query = _this4.get('settings.query');

          if (query.get('drillDownFields.length') && query.get('drillDownFields.lastObject') !== categoryKey) {
            // Filters will be empty won't be there for 'Others' in buckets
            if (Ember.isPresent(filters)) {
              _this4.drillDown(categoryKey, filters);
            }
          } else if (_this4.get('canViewRawData')) {
            // Filters will be empty won't be there for 'Others' in buckets
            if (Ember.isPresent(filters)) {
              if (_this4.get('drillFilters')) {
                filters.addObjects(_this4.get('drillFilters'));
              }

              _this4.filterOnElementHit(filters);
            }
          }
        }
      });
    },
    // FIXME: Remove after verifying if config.topN is still in use
    generateTopNData: function generateTopNData(data) {
      var topN = this.get('config.topN') && this.get('config.topN.n'); // FIXME: Skip if aggregation type is average

      if (topN) {
        if (data.length <= topN) {
          return data;
        }

        var valueKey = this.get('config.yAxis.key');
        var categoryKey = this.get('config.xAxis.key');
        data = data.sortBy(valueKey).reverse();
        var newData = data.slice(0, topN);
        var others = {};
        others[categoryKey] = '# - Others';
        others[valueKey] = 0;

        for (var i = topN; i < data.length; i++) {
          others[valueKey] += data[i][valueKey];
        }

        newData.push(others);
        return newData;
      } else {
        return data;
      }
    },
    replaceNullInRowsAndColumns: function replaceNullInRowsAndColumns() {
      var xAxisKey = this.get('config.xAxis.key');
      var excludeNullValues = this.get('config.excludeNullValues');
      var isDateXAxis = this.isXAxisDateType();
      var processedData = [];
      /* 
        - `NA` values in amcharts date axis causes weird tooltip issues.
        - So for dateaxis, we leave the null values as is so that it is handled gracefully by amcharts
        TO-DO: Explore customer use cases to check if null values are to be shown and represented differently on the chart
      */

      if (!isDateXAxis) {
        this.get('data').forEach(function (item) {
          if (item[xAxisKey] === null) {
            if (!excludeNullValues) {
              item[xAxisKey] = _constants.NO_VALUE_SET;
              processedData.push(item);
            }
          } else {
            processedData.push(item);
          }
        });
        this.set('data', processedData);
      }
    },
    transformValuesInData: function transformValuesInData() {
      var xAxisKey = this.get('config.xAxis.key');
      var isDateXAxis = this.isXAxisDateType();
      var transformedData = [];

      if (!isDateXAxis) {
        this.data.forEach(function (item) {
          var value = item[xAxisKey];

          if (Ember.isPresent(value) && typeof value === 'boolean') {
            item[xAxisKey] = value.toString();
          }

          transformedData.push(item);
        });
        this.set('data', transformedData);
      }
    }
  });

  _exports.default = _default;
});