define("happyfox-bi/report-subscriptions/components/subscribe-to-report/subscription-form-model", ["exports", "happyfox-bi/form-models/base-form", "happyfox-bi/utilities/form-models", "happyfox-bi/report-subscriptions/components/subscribe-to-report/form-field-model/days-field", "happyfox-bi/report-subscriptions/components/subscribe-to-report/constants", "happyfox-bi/components/my-settings/constants", "happyfox-bi/report-subscriptions/components/subscribe-to-report/utilities", "happyfox-bi/utilities/date"], function (_exports, _baseForm, _formModels, _daysField, _constants, _constants2, _utilities, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseForm.default.extend({
    dataService: Ember.inject.service('data'),
    intlService: Ember.inject.service('intl'),
    profileService: Ember.inject.service('profile'),
    isAdd: false,
    dataSource: null,
    reportsList: null,
    currentReportId: null,
    currentPageId: null,
    recipientsList: null,
    basicFieldKeys: null,
    scrollableContainerSelector: '.js-subscription-form_content',
    init: function init() {
      this._super.apply(this, arguments);

      var rawFields = Ember.A();
      var intlService = this.get('intlService');
      var subscriptionActiveField = Ember.Object.create({
        key: 'active',
        isToggle: true
      });
      var includeReportLinkField = Ember.Object.create({
        key: 'includeReportLink',
        isToggle: true
      });
      var nameField = Ember.Object.create({
        key: 'name',
        isText: true,
        isMandatory: true,
        label: intlService.lookup('name')
      });
      var subjectField = Ember.Object.create({
        key: 'subject',
        isText: true,
        isMandatory: true,
        label: intlService.lookup('subject')
      });
      var messageField = Ember.Object.create({
        key: 'message',
        isTextarea: true,
        label: intlService.lookup('message')
      }); // FIXME: To define recipients field as array of objects to support arbitrary emails, subscribed state

      var recipientsField = Ember.Object.create({
        key: 'recipients',
        isLocalMultiChoiceDropdown: true,
        isMandatory: true,
        choiceLabelKey: 'name',
        choiceValueKey: 'id',
        choices: this.recipientsList,
        placeholder: intlService.lookup('select-recipients'),
        label: intlService.lookup('recipients')
      });
      var reportsField = Ember.Object.create({
        key: 'report',
        isChoice: true,
        canSetDefaultChoiceValue: false,
        isMandatory: true,
        choices: this.reportsList,
        choiceValueKey: 'id',
        placeholder: intlService.lookup('select-report'),
        label: intlService.lookup('report')
      });
      var pagesField = Ember.Object.create({
        key: 'pages',
        isLocalMultiChoiceDropdown: true,
        isMandatory: true,
        choices: Ember.A(),
        choiceValueKey: 'id',
        placeholder: intlService.lookup('select-pages'),
        label: intlService.lookup('pages')
      });
      var dateRangeField = Ember.Object.create({
        key: 'dateRange',
        isChoice: true,
        canSetDefaultChoiceValue: false,
        isMandatory: true,
        choices: this.getDateRangeOptions(),
        choiceValueKey: 'id',
        choiceLabelKey: 'label',
        placeholder: intlService.lookup('select-date-range'),
        label: intlService.lookup('date-range-for-report')
      });
      var scheduleField = Ember.Object.create({
        key: 'schedule',
        isObject: true,
        isMandatory: true,
        childFieldsMetaInfo: [Ember.Object.create({
          key: 'frequency',
          isChoice: true,
          canSetDefaultChoiceValue: true,
          isMandatory: true,
          choices: this.getFrequencyOptions(),
          choiceValueKey: 'id',
          choiceLabelKey: 'label',
          placeholder: intlService.lookup('select-frequency'),
          label: intlService.lookup('schedule-frequency')
        }), Ember.Object.create({
          key: 'hourOfDay',
          isChoice: true,
          canSetDefaultChoiceValue: false,
          isMandatory: true,
          choiceValueKey: 'id',
          choiceLabelKey: 'text',
          choices: _utilities.HOUR_OPTIONS,
          placeholder: intlService.lookup('select-hours')
        }), Ember.Object.create({
          key: 'minuteOfHour',
          isChoice: true,
          canSetDefaultChoiceValue: false,
          isMandatory: true,
          choiceValueKey: 'id',
          choiceLabelKey: 'text',
          choices: _utilities.MINUTE_OPTIONS,
          placeholder: intlService.lookup('select-minutes')
        }), Ember.Object.create({
          key: 'timezoneOffset',
          isChoice: true,
          canSetDefaultChoiceValue: false,
          isMandatory: true,
          choices: _constants2.TIMEZONES,
          choiceValueKey: 'offset',
          choiceLabelKey: 'name',
          placeholder: intlService.lookup('select-timezone'),
          label: intlService.lookup('timezone')
        }), Ember.Object.create({
          key: 'startDate',
          isDate: true,
          canSetDefaultChoiceValue: false,
          isMandatory: true,
          placeholder: intlService.lookup('select-date'),
          label: intlService.lookup('start-date')
        })]
      });
      rawFields.addObjects([nameField, subjectField, messageField, recipientsField, reportsField, pagesField, dateRangeField, scheduleField, subscriptionActiveField, includeReportLinkField]);
      this.setProperties({
        fields: this._createFieldModels(rawFields),
        basicFieldKeys: rawFields.mapBy('key')
      });

      if (this.isAdd) {
        this.setDefaultFieldValues();
      } else {
        this._copyFromDataSource();
      }
    },
    isFrequencyWeekly: Ember.computed('model.schedule.model.frequency.value.id', {
      get: function get() {
        var schedule = this.model.schedule;
        var frequency = schedule.model.frequency;
        return Ember.isPresent(frequency.value) && frequency.value.id == 'weekly';
      }
    }),
    isFrequencyMonthly: Ember.computed('model.schedule.model.frequency.value.id', {
      get: function get() {
        var schedule = this.model.schedule;
        var frequency = schedule.model.frequency;
        return Ember.isPresent(frequency.value) && frequency.value.id == 'monthly';
      }
    }),
    _copyFromDataSource: function _copyFromDataSource() {
      var dataSource = this.dataSource,
          model = this.model;
      var recipientIds = dataSource.recipients.mapBy('id');
      dataSource.recipients = recipientIds;
      var report = model.report,
          pages = model.pages,
          recipients = model.recipients;
      var recipientModelValues = recipients.choices.filter(function (recipient) {
        return recipientIds.includes(Number(recipient.id));
      });
      recipients.set('value', recipientModelValues);
      recipients.updateInitialValue();
      report.set('value', this.reportsList.findBy('id', dataSource.report.toString()));
      report.updateInitialValue();
      var pageChoices = report.value && report.value.pages ? report.value.pages : Ember.A();
      var pageModelValues = Ember.A();
      dataSource.pages.forEach(function (pageId) {
        var page = pageChoices.findBy('id', pageId);

        if (page) {
          pageModelValues.addObject(page);
        }
      });
      pages.setProperties({
        'choices': pageChoices,
        'value': pageModelValues
      });
      pages.updateInitialValue();
      var schedule = dataSource.schedule;
      this.createDaysToRun(schedule.frequency, true);

      this._super();
    },
    generatePayload: function generatePayload() {
      var _this = this;

      var payloadData = this.getModifiedFields(); // TO-DO: Define this as a raw field once we support subscriptionType (email, slack, etc)

      payloadData.set('subscriptionType', 'email');
      var recipients = payloadData.recipients,
          report = payloadData.report,
          schedule = payloadData.schedule;

      if (recipients) {
        var recipientIds = payloadData.recipients;
        payloadData.recipients = recipientIds.map(function (id) {
          var recipient = _this.recipientsList.findBy('id', id);

          return {
            id: Number(recipient.id),
            type: recipient.role,
            email: recipient.email
          };
        });
      }

      if (report) {
        payloadData.report = Number(report);
      }

      if (schedule) {
        var frequency = schedule.frequency,
            daysToRun = schedule.daysToRun;
        /* `daysToRun` for monthly frequency supports integer between 1-31. 
        For maintaining standard format, we convert it to array of interger */

        if (frequency == 'monthly') {
          schedule.daysToRun = Ember.A().addObject(Number(daysToRun));
        } else {
          schedule.daysToRun = daysToRun.filterBy('active').mapBy('day');
        }
      }

      return payloadData;
    },
    submit: function submit() {
      var _this2 = this;

      this.set('errors', Ember.A());
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this2.validate().then(function () {
          if (!_this2.isAdd) {
            // In case of edit, we set the datasource with updated fields
            _this2._copyToDataSource();
          }

          var payload = _this2.generatePayload();

          resolve(payload);
        }).catch(function () {
          _this2.scrollToError();

          reject();
        });
      });
    },
    setDefaultFieldValues: function setDefaultFieldValues() {
      var profileService = this.profileService,
          recipientsList = this.recipientsList,
          model = this.model;
      var recipients = model.recipients,
          report = model.report,
          pages = model.pages,
          dateRange = model.dateRange,
          schedule = model.schedule,
          active = model.active,
          includeReportLink = model.includeReportLink;
      var userId = profileService.profile.id;
      var currentUser = recipientsList.findBy('id', userId.toString());
      var selectedRecipients = Ember.A();
      selectedRecipients.addObject(currentUser);
      var currentReport = this.reportsList.findBy('id', this.currentReportId);

      if (currentReport) {
        report.set('value', currentReport);
        pages.set('choices', currentReport.pages);
      }

      if (this.currentPageId) {
        pages.set('value', Ember.A([Number(this.currentPageId)]));
      }

      var defaultDateRange = dateRange.choices.findBy('id', 'yesterday');
      recipients.set('value', selectedRecipients);
      dateRange.set('value', defaultDateRange);
      active.set('value', true);
      includeReportLink.set('value', true); // schedule

      var scheduleModel = schedule.model;
      var frequency = scheduleModel.frequency,
          hourOfDay = scheduleModel.hourOfDay,
          minuteOfHour = scheduleModel.minuteOfHour,
          timezoneOffset = scheduleModel.timezoneOffset,
          startDate = scheduleModel.startDate;
      var profileTimezone = profileService.profile.timezone;

      var defaultTimezone = _constants2.TIMEZONES.findBy('offset', profileTimezone.offset);

      var defaultStartDate = (0, _date.convertDateToApiFormat)(moment());
      frequency.setDefaultChoiceValue(); // Default time is 08:00 AM

      hourOfDay.set('value', hourOfDay.choices.findBy('id', 8));
      minuteOfHour.set('value', minuteOfHour.choices.findBy('id', 0));
      timezoneOffset.set('value', defaultTimezone);
      startDate.set('value', defaultStartDate);
      this.createDaysToRun(frequency.value.id, false);
    },
    updatePagesList: function updatePagesList() {
      var _this$model = this.model,
          pages = _this$model.pages,
          report = _this$model.report;
      var pageChoices = report.value && report.value.pages ? report.value.pages : Ember.A();
      pages.setProperties({
        'choices': pageChoices,
        value: null
      });
    },
    createDaysToRun: function createDaysToRun(frequencyType, isCopyValueFromDataSource) {
      var model = this.model,
          dataSource = this.dataSource;
      var scheduleModel = model.schedule;
      var scheduleFields = scheduleModel.childFields;
      var daysToRunField = scheduleFields.findBy('key', 'daysToRun');
      var newDaysToRunField;

      if (daysToRunField) {
        scheduleFields.removeObject(daysToRunField);
      }

      if (frequencyType !== 'monthly') {
        newDaysToRunField = (0, _formModels.createField)(this, this.createDaysListMetaInfo());

        if (isCopyValueFromDataSource) {
          newDaysToRunField.set('value', this.getWeekDays(dataSource.schedule.daysToRun));
        } else {
          newDaysToRunField.set('value', this.getWeekDays(Ember.A()));
        }

        newDaysToRunField.createChildFields();
      } else {
        newDaysToRunField = (0, _formModels.createField)(this, Ember.Object.create({
          key: 'daysToRun',
          isNumber: true,
          isMandatory: true,
          minValue: 1,
          maxValue: 31,
          label: this.intlService.lookup("monthly-day-label")
        }));

        if (isCopyValueFromDataSource) {
          newDaysToRunField.set('value', dataSource.schedule.daysToRun[0]);
        } else {
          newDaysToRunField.set('value', null);
        }
      }

      newDaysToRunField.updateInitialValue(); // FIXME: Without next(), model is not getting recomputed

      Ember.run.next(function () {
        scheduleFields.addObject(newDaysToRunField);
      });
    },
    createDaysListMetaInfo: function createDaysListMetaInfo() {
      var fieldMetaInfo = Ember.Object.create({
        key: 'daysToRun',
        isArray: true,
        isMandatory: true,
        fieldClass: _daysField.default,
        label: this.intlService.lookup('select-days'),
        childFieldMetaInfo: Ember.Object.create({
          key: 'weekday',
          isObject: true,
          childFields: null,
          childFieldsMetaInfo: [Ember.Object.create({
            key: 'active',
            isCheckBox: true,
            isMandatory: true
          }), Ember.Object.create({
            key: 'day',
            isNumber: true
          })]
        })
      });
      return fieldMetaInfo;
    },
    getDateRangeOptions: function getDateRangeOptions() {
      var _this3 = this;

      return _constants.DATE_RANGES.map(function (range) {
        return Ember.Object.create({
          id: range.id,
          label: _this3.intlService.lookup(range.label)
        });
      });
    },
    getFrequencyOptions: function getFrequencyOptions() {
      var _this4 = this;

      return _constants.FREQUENCY_OPTIONS.map(function (frequency) {
        return Ember.Object.create({
          id: frequency.id,
          label: _this4.intlService.lookup(frequency.label)
        });
      });
    },
    getWeekDays: function getWeekDays() {
      var daysToRun = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : Ember.A();
      return _constants.WEEK_DAYS.map(function (weekDay) {
        return Ember.Object.create({
          day: weekDay.day,
          active: daysToRun.length ? daysToRun.includes(weekDay.day) : weekDay.active
        });
      });
    }
  });

  _exports.default = _default;
});