define("happyfox-bi/switch-account/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      var _this = this;

      this.controllerFor('application').set('canShowAccountSwitcherModal', true); // just to open account switcher before loading reports.

      Ember.run.next(function () {
        _this.transitionTo('reports');
      });
    }
  });

  _exports.default = _default;
});