define("happyfox-bi/data-sources/details/data-sets/import/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    dataService: Ember.inject.service('data'),
    dataSource: null,
    file: null,
    fileMeta: null,
    isInitialUploadInProgress: null,
    initiate: function initiate() {
      this.reset();
      var dataSourceId = this.get('model.dataSource');
      var dataSource = this.get('dataService').peekDataSource(dataSourceId);
      this.set('dataSource', dataSource);
    },
    reset: function reset() {
      this.setProperties({
        dataSource: null,
        file: null,
        fileMeta: null,
        isInitialUploadInProgress: null,
        isFinalUploadInProgress: null
      });
    },
    detectFields: function detectFields(file) {
      var _this = this;

      this.set('file', file);
      var datasetId = this.get('model.id');
      var requestData = {
        file: file,
        datasetId: datasetId
      };
      this.set('isInitialUploadInProgress', true);
      return this.get('dataService').autoDetectFields(requestData).then(function (data) {
        _this.set('fileMeta', data);
      }).finally(function () {
        _this.set('isInitialUploadInProgress', false);
      });
    },
    actions: {
      onFileSelection: function onFileSelection(file) {
        this.set('file', file);
        return this.detectFields(file);
      },
      uploadFile: function uploadFile(fields) {
        var file = this.get('file');
        var datasetId = this.get('model.id');
        var requestData = {
          file: file,
          datasetId: datasetId
        };

        if (fields) {
          requestData.fields = fields;
        }

        return this.get('dataService').importData(requestData);
      },
      goToDataSet: function goToDataSet() {
        var dataSet = this.get('model');
        this.transitionToRoute('data-sources.details.data-sets.details', dataSet.get('dataSource'), dataSet.get('id'));
      },
      removeFile: function removeFile() {
        this.set('file', null);
      }
    }
  });

  _exports.default = _default;
});