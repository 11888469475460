define("happyfox-bi/utilities/math", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getAverage = getAverage;
  _exports.getPercentile = getPercentile;

  function getPercentile() {
    var list = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var percentile = arguments.length > 1 ? arguments[1] : undefined;
    var sortedList = list.sort(function (a, b) {
      return a - b;
    }).compact();
    if (percentile === 0) return sortedList[0];
    var kIndex = Math.ceil(sortedList.length * (percentile / 100));
    kIndex = kIndex > sortedList.length ? sortedList.length : kIndex;
    return list[kIndex - 1];
  }

  function getAverage() {
    var list = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var truthyList = list.compact();
    var total = truthyList.reduce(function (total, current) {
      return total + current;
    });
    return total / list.length;
  }
});