define("happyfox-bi/components/compare-date-range-picker/component", ["exports", "happyfox-bi/utilities/time", "happyfox-bi/components/compare-date-range-picker/utilities", "happyfox-bi/components/date-range-picker/component", "happyfox-bi/mixins/date-range-picker"], function (_exports, _time, _utilities, _component, _dateRangePicker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _component.default.extend(_dateRangePicker.default, {
    dataService: Ember.inject.service('data'),
    profileService: Ember.inject.service('profile'),
    predefinedDateRanges: null,
    compareDateRangePreferences: Ember.computed.reads('profileService.profile.dateRangePreferences.compare'),
    dateRange: '',
    startDate: null,
    endDate: null,
    ranges: null,
    seekedDateRange: null,
    defaultRange: null,
    pickerUpdated: false,
    // 'pickerUpdated' distinguishes when a comparison-picker is updated and when it is just default date
    onDateRangePreferencesChange: null,
    // Removes the extending observer
    onCompareDateRangePreferencesChange: Ember.observer('compareDateRangePreferences.@each.{dateRange,fromDate,toDate}', function () {
      Ember.run.once(this, 'setCompareDateRangeDetails');
    }),
    onSourceDateRangePreferencesChange: Ember.observer('dateRangePreferences.{dateRange,fromDate,toDate}', 'seekedDateRange.{fromDate,toDate}', function () {
      Ember.run.once(this, 'onSourceDateRangeChangeHelper');
    }),
    onSourceDateRangeChangeHelper: function onSourceDateRangeChangeHelper() {
      this.initializeCompareDateRangePicker();
      this.updateDateRangePicker();
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.initializeCompareDateRangePicker();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var triggerElement = this.$(".".concat(this.triggerClass));
      this.$().on('click.daterangepicker', function (event) {
        if (Ember.$(event.target).closest('.compare-date-range_remove').length) {
          triggerElement.data('daterangepicker').hide();
        }
      });
    },
    initializeCompareDateRangePicker: function initializeCompareDateRangePicker() {
      this.setPredefinedDateRanges();
      this.prepareDateRangesList(); // Date-range-picker package method

      this.setDateRangesList();
      this.setCompareDateRangeDetails();
    },
    // Based on profile date range / seeked date range, the ranges for different compare types are updated
    setPredefinedDateRanges: function setPredefinedDateRanges() {
      var profileDateRangePreferences = this.get('dateRangePreferences');
      var predefinedCompareDateRanges;
      var seekedDateRange = this.get('seekedDateRange');

      if (seekedDateRange && (seekedDateRange.fromDate || seekedDateRange.toDate)) {
        predefinedCompareDateRanges = (0, _utilities.getCompareDateRanges)(_objectSpread({}, seekedDateRange));
      } else {
        predefinedCompareDateRanges = (0, _utilities.getCompareDateRanges)(profileDateRangePreferences);
      }

      this.set('ranges', predefinedCompareDateRanges);
    },
    setCompareDateRangeDetails: function setCompareDateRangeDetails() {
      var compareDateRangePreferences = this.compareDateRangePreferences,
          dateRangesList = this.dateRangesList;
      var compareDateRangeValues = Ember.A();
      (compareDateRangePreferences || []).forEach(function (option) {
        var dateRange, startDate, endDate;
        dateRange = option.dateRange;

        if (dateRange === 'custom') {
          startDate = moment(option.fromDate);
          endDate = moment(option.toDate);
        } else {
          var dateRangeOption = dateRangesList.find(function (range) {
            return range.id === option.dateRange;
          });

          if (dateRangeOption) {
            startDate = dateRangeOption.value[0];
            endDate = dateRangeOption.value[1];
          }
        }

        compareDateRangeValues.addObject(Ember.Object.create({
          dateRange: dateRange,
          startDate: startDate,
          endDate: endDate
        }));
      }); // FIXME: Setting default first-time start date and end date as they cannot be null. Figure out better way to show empty state.

      if (compareDateRangeValues.length) {
        var _compareDateRangeValu = _slicedToArray(compareDateRangeValues, 1),
            dateRangeField = _compareDateRangeValu[0];

        this.setProperties({
          'startDate': dateRangeField.startDate,
          'endDate': dateRangeField.endDate,
          'dateRange': dateRangeField.dateRange,
          'pickerUpdated': true
        });
      } else {
        this.setDefaultDateRange();
      }
    },
    setDefaultDateRange: function setDefaultDateRange() {
      var now = (0, _time.getCurrentDateTime)();
      this.setProperties({
        'startDate': moment(now).subtract(1, 'month').startOf('month'),
        'endDate': moment(now).subtract(1, 'month').endOf('month'),
        'dateRange': 'custom',
        'pickerUpdated': false
      });
    },

    /*
      - In Original date range picker component, start & end dates are passed as props and `didUpdateAttrs` updates $picker start & End Dates to reflect in picker UI
      - Here, date range properties (start, end, ranges, etc.) are defined in the component. Hence we need to update $picker manually
      - $picker is updated
        1. to recalculate list of predefined ranges when actual date range change
        2. to reset start & end dates to default when comparison is removed
    */
    updateDateRangePicker: function updateDateRangePicker() {
      var startDate = this.startDate,
          endDate = this.endDate,
          dateRangesList = this.dateRangesList;
      var triggerElement = this.$(".".concat(this.triggerClass));
      var dateRangePicker = triggerElement.data('daterangepicker');
      dateRangePicker.startDate = startDate;
      dateRangePicker.endDate = endDate;
      var ranges = {};
      dateRangesList.forEach(function (range) {
        ranges[range.label] = range.value;
      });
      dateRangePicker.ranges = ranges;
      dateRangePicker.updateView();
      dateRangePicker.updateCalendars();
    },
    actions: {
      remove: function remove() {
        var _this = this;

        // This is defined as a promise to ensure that default picker dates are set only after `remove` action is performed
        this.onRemoveComparisonDateRange().then(function () {
          _this.setDefaultDateRange();

          _this.updateDateRangePicker();
        });
      }
    }
  });

  _exports.default = _default;
});