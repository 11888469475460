define("happyfox-bi/components/form-field-input/text/component", ["exports", "happyfox-bi/components/form-field-input/base", "happyfox-bi/components/form-field-input/mixins/autofocus"], function (_exports, _base, _autofocus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend(_autofocus.default, {
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.get('field.autoFocus')) {
        this.autoFocus();
      }
    },
    actions: {
      onFocusOut: function onFocusOut() {
        this.get('field').validate();
      },
      onEnter: function onEnter() {
        if (this.onEnter) {
          this.onEnter();
        }
      },
      onChange: function onChange(value) {
        if (this.onChange) {
          this.onChange(value);
        }
      }
    }
  });

  _exports.default = _default;
});