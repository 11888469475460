define("happyfox-bi/components/form-field-input/date-range-picker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NnyvVCnj",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,7],null,[[\"direction\",\"alwaysShowCalendars\",\"autoApply\",\"ranges\",\"startDate\",\"endDate\",\"dateRange\",\"maxDate\",\"onDateRangeSet\",\"class\",\"isEmbeddedInForm\",\"data-test-id\"],[\"right\",true,true,[35,0,[\"predefinedDateRanges\"]],[35,6],[35,5],[35,4],[35,0,[\"maxDate\"]],[30,[36,3],[[32,0],\"applyDateRange\"],null],[30,[36,2],[\"form-field_date-range-picker\",[30,[36,1],[[35,0,[\"isEmbeddedInForm\"]],\"hf-mod-wizard-field\"],null]],null],[35,0,[\"isEmbeddedInForm\"]],\"form-field-date-range-picker\"]]]]],\"hasEval\":false,\"upvars\":[\"field\",\"if\",\"concat-strings\",\"action\",\"dateRange\",\"endDate\",\"startDate\",\"date-range-picker\"]}",
    "meta": {
      "moduleName": "happyfox-bi/components/form-field-input/date-range-picker/template.hbs"
    }
  });

  _exports.default = _default;
});