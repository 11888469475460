define("happyfox-bi/manage/users/edit-user-pane/form-model", ["exports", "happyfox-bi/form-models/base-form"], function (_exports, _baseForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseForm.default.extend({
    intlService: Ember.inject.service('intl'),
    dataService: Ember.inject.service('data'),
    isAdd: false,
    dataSource: null,
    basicFieldKeys: null,
    isRoleUser: null,
    reportsList: null,
    scrollableContainerSelector: '.js-edit-user-form_content',
    init: function init() {
      this._super.apply(this, arguments);

      var rawFields = Ember.A();
      var reportsList = this.reportsList,
          intlService = this.intlService,
          dataSource = this.dataSource,
          isRoleUser = this.isRoleUser;
      var firstNameField = Ember.Object.create({
        key: 'firstName',
        isText: true,
        isMandatory: false,
        label: intlService.lookup('first-name')
      });
      var lastNameField = Ember.Object.create({
        key: 'lastName',
        isText: true,
        isMandatory: false,
        label: intlService.lookup('last-name')
      });
      var reportsAccessField = Ember.Object.create({
        key: 'reports',
        isMultiChoice: true,
        isMultiChoiceDropdown: true,
        valueProperty: 'id',
        labelProperty: 'name',
        apiValueKey: 'id',
        isMandatory: false,
        isDisabled: dataSource.viewAllReports,
        options: reportsList,
        placeholder: intlService.lookup('select-reports'),
        label: intlService.lookup('reports'),
        pluralizedTranslationKey: 'reports'
      });
      rawFields.addObjects([firstNameField, lastNameField, reportsAccessField]);

      if (isRoleUser) {
        var canViewAllReportsField = Ember.Object.create({
          key: 'viewAllReports',
          isToggle: true
        });
        rawFields.addObjects([canViewAllReportsField]);
      }

      this.setProperties({
        fields: this._createFieldModels(rawFields),
        basicFieldKeys: rawFields.mapBy('key')
      });

      this._copyFromDataSource();
    },
    _copyFromDataSource: function _copyFromDataSource() {
      this._super();

      var dataSource = this.dataSource,
          model = this.model,
          reportsList = this.reportsList;
      var reportIds = dataSource.reports;
      var selectedReports = reportsList.filter(function (report) {
        return reportIds.includes(report.id);
      });
      model.reports.set('value', selectedReports);
      model.reports.updateInitialValue();
    }
  });

  _exports.default = _default;
});