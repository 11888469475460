define("happyfox-bi/components/form-field-input/number/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4MVuD8as",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"u-vertically-centered-container\"],[12],[2,\"\\n  \"],[1,[30,[36,9],null,[[\"type\",\"value\",\"focus-out\",\"class\",\"data-test-id\",\"disabled\",\"classNames\",\"placeholder\",\"min\",\"max\",\"aria-label\"],[\"number\",[35,0,[\"value\"]],[30,[36,8],[[32,0],\"onFocusOut\"],null],[30,[36,7],[[30,[36,7],[\"form-field-input hf-number-input\",[35,3]],null],\" \",[30,[36,6],[[35,5],\"is-error\"],null],\" \"],null],[35,4],[35,0,[\"isDisabled\"]],[35,3],[35,0,[\"placeholder\"]],[35,0,[\"minValue\"]],[35,0,[\"maxValue\"]],[35,2]]]]],[2,\"\\n\\n\"],[6,[37,6],[[35,0,[\"unitsLabel\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],[[35,0,[\"unitsLabel\"]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[6,[37,10],[[35,0,[\"hideErrors\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[19,\"components/form-field-input/partials/error-message\",[]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":true,\"upvars\":[\"field\",\"t\",\"ariaLabel\",\"inputClass\",\"fieldDataTestId\",\"hasErrors\",\"if\",\"concat\",\"action\",\"input\",\"unless\"]}",
    "meta": {
      "moduleName": "happyfox-bi/components/form-field-input/number/template.hbs"
    }
  });

  _exports.default = _default;
});