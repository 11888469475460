define("happyfox-bi/report-templates/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    featuresService: Ember.inject.service('features'),
    dataService: Ember.inject.service('data'),
    canCreateReportTemplates: Ember.computed.reads('featuresService.createReportTemplates'),
    applicationService: Ember.inject.service('application'),
    beforeModel: function beforeModel() {
      var canCreateReportTemplates = this.canCreateReportTemplates;

      if (!canCreateReportTemplates) {
        this.transitionTo('reports');
      }
    },
    setupController: function setupController(controller) {
      var offset = 0;
      var calculatedOffset = controller.calculatedOffset;
      controller.fetchData(calculatedOffset ? calculatedOffset : offset);
      this.applicationService.closeSidePane();
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('canShowTemplateSidePane', false);
      }
    }
  });

  _exports.default = _default;
});