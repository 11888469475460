define("happyfox-bi/components/widget-filters/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "guRZPbIu",
    "block": "{\"symbols\":[\"filter\"],\"statements\":[[10,\"section\"],[15,0,[31,[\"widget-filters_header \",[30,[36,10],[[35,8],\"u-vertically-centered-container\"],null]]]],[12],[2,\"\\n  \"],[1,[30,[36,11],[\"filter-by\"],null]],[2,\"\\n\"],[6,[37,10],[[35,8]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"u-icon-left-margin tooltipped tooltipped-top-right\"],[15,\"aria-label\",[34,8]],[12],[2,\"\\n      \"],[1,[30,[36,9],[\"info-icon\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[10,\"section\"],[14,0,\"widget-filters_content\"],[12],[2,\"\\n\"],[6,[37,14],[[30,[36,13],[[30,[36,13],[[35,12]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,6],null,[[\"filter\",\"fields\",\"prefetchedFiltersValueOptions\",\"visualizationId\",\"updateFilter\",\"removeFilter\"],[[32,1],[35,7],[35,3],[35,2],[30,[36,0],[[32,0],\"updateFilter\"],null],[30,[36,0],[[32,0],\"removeFilter\"],null]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[6,[37,10],[[30,[36,15],[[35,5],[35,4,[\"length\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,6],null,[[\"isAdd\",\"fieldSelectionComponent\",\"unAppliedFilterFields\",\"prefetchedFiltersValueOptions\",\"visualizationId\",\"settingsFields\",\"addFilter\",\"addSelectedField\",\"fetchDataSetFields\"],[true,[35,5],[35,4],[35,3],[35,2],[35,1],[30,[36,0],[[32,0],\"addFilter\"],null],[30,[36,0],[[32,0],\"addSelectedField\"],null],[30,[36,0],[[32,0],\"fetchDataSetFields\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"settingsFields\",\"visualizationId\",\"prefetchedFiltersValueOptions\",\"unAppliedFilterFields\",\"fieldSelectionComponent\",\"widget-filters/filter\",\"fields\",\"visualizationFilterHelpText\",\"inline-svg\",\"if\",\"t\",\"filters\",\"-track-array\",\"each\",\"or\"]}",
    "meta": {
      "moduleName": "happyfox-bi/components/widget-filters/template.hbs"
    }
  });

  _exports.default = _default;
});