define("happyfox-bi/components/form-field-input/date/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XWTJOgBR",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"u-position-relative\"],[12],[2,\"\\n  \"],[1,[30,[36,9],null,[[\"minDate\",\"class\",\"renderInPlace\",\"isAbsolutelyPositioned\",\"data-test-id\",\"value\",\"placeholder\",\"allowClear\",\"onClose\"],[[35,1,[\"minDate\"]],[30,[36,8],[[30,[36,7],[\"hf-date-input\",\"form-field-input\",[35,6]],null],\" \",[30,[36,5],[[35,4],\"is-error\"],null],\" \"],null],[35,3],true,[35,2],[35,1,[\"value\"]],[35,1,[\"placeholder\"]],[35,1,[\"allowClear\"]],[30,[36,0],[[32,0],\"onClose\"],null]]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[19,\"components/form-field-input/partials/error-message\",[]],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"action\",\"field\",\"fieldDataTestId\",\"renderInPlace\",\"hasErrors\",\"if\",\"inputClass\",\"concat-strings\",\"concat\",\"date-picker\"]}",
    "meta": {
      "moduleName": "happyfox-bi/components/form-field-input/date/template.hbs"
    }
  });

  _exports.default = _default;
});