define("happyfox-bi/utilities/url", ["exports", "happyfox-bi/utilities/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getQueryString = getQueryString;
  _exports.makeURL = makeURL;

  /*
   * Function to join two strings into an url in given order.
   * Prevents consecutive slashes while joining
   */
  function makeURL(str1, str2) {
    if (!Ember.isBlank(str1) && !Ember.isBlank(str2)) {
      var len1 = str1.length;
      var len2 = str2.length;

      if (str1.charAt(len1 - 1) === '/' && str2.charAt(0) === '/') {
        // str1 has trailing slash and str2 has leading slash
        // "http://localhost:4200/" + "/staff" = http://localhost:4200/staff"
        return str1.concat(str2.substring(1, len2));
      } else if (str1.charAt(len1 - 1) !== '/' && str2.charAt(0) !== '/') {
        // str1 has no trailing slash and str2 has no leading slash
        // "http://localhost:4200" + "staff" = http://localhost:4200/staff"
        return [str1, str2].join('/');
      } else {
        // str1 has trailing slash and str2 has no leading slash
        // "http://localhost:4200/" + "staff" = "http://localhost:4200/staff"
        // str1 has no trailing slash and str2 has leading slash
        // "http://localhost:4200" + "/staff" = "http://localhost:4200/staff"
        return str1.concat(str2);
      }
    }
  }

  function getQueryString(data) {
    var queryString;
    var keys = (0, _object.getKeysFromObject)(data);
    var queryStringArray = [];
    keys.forEach(function (key) {
      var value = data[key];
      queryStringArray.push("".concat(encodeURIComponent(key), "=").concat(encodeURIComponent(value)));
    });
    queryString = queryStringArray.join('&');
    return queryString;
  }
});