define("happyfox-bi/components/my-settings/component", ["exports", "happyfox-bi/components/my-settings/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    dataService: Ember.inject.service('data'),
    profileService: Ember.inject.service('profile'),
    featuresService: Ember.inject.service('features'),
    timezoneOptions: _constants.TIMEZONES,
    saveInProgress: false,
    profileData: null,
    fetchingApiKey: false,
    apiKey: null,
    init: function init() {
      this._super.apply(this, arguments);

      var profile = this.get('profileService.profile');
      var timezone = profile.timezone;
      var offset = timezone && timezone.offset ? timezone.offset : '0';

      var selectedTimezone = _constants.TIMEZONES.findBy('offset', parseInt(offset));

      var newProfileData = Ember.Object.create(profile);
      newProfileData.set('timezone', selectedTimezone);
      this.set('profileData', newProfileData);
    },
    actions: {
      toggleMySettingsModal: function toggleMySettingsModal() {
        this.toggleMySettings();
      },
      onTimezoneChange: function onTimezoneChange(selectedTimezone) {
        this.set('profileData.timezone', selectedTimezone);
      },
      getApiKey: function getApiKey() {
        var _this = this;

        this.set('fetchingApiKey', true);
        this.get('dataService').getUserApiKey().then(function (data) {
          _this.set('apiKey', data.apiKey);
        }).catch(function () {
          _this.set('apiKey', null);
        }).finally(function () {
          _this.set('fetchingApiKey', false);
        });
      },
      saveProfile: function saveProfile() {
        var _this2 = this;

        this.set('saveInProgress', true);
        var profileData = this.get('profileData');
        var data = {
          'firstName': profileData.firstName,
          'lastName': profileData.lastName,
          'timezone': profileData.timezone
        };
        this.get('dataService').updateProfile(data).then(function () {
          window.location.reload(true);
        }).catch(function () {
          _this2.set('saveInProgress', false);
        });
      }
    }
  });

  _exports.default = _default;
});