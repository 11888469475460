define("happyfox-bi/models/data-source", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    sourceType: (0, _model.attr)('string'),
    settings: (0, _model.attr)(),
    refreshInterval: (0, _model.attr)(),
    lastSyncAt: Ember.computed('settings.lastSyncAt', {
      get: function get() {
        var lastSyncedAt = this.get('settings.lastSyncAt');
        return lastSyncedAt ? moment(lastSyncedAt).format('MMM DD YYYY, h:mm A') : null;
      }
    })
  });

  _exports.default = _default;
});