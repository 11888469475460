define("happyfox-bi/utilities/text-area", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.insertTextAtCaretPosition = insertTextAtCaretPosition;

  function insertTextAtCaretPosition(newText, elementId) {
    /* To insert text at current caret position in a field
    newText: String desc: the text value wish to insert
    elementId: String desc: id of DOM element
    */
    if (elementId && newText) {
      var el = _.first(Ember.$('#'.concat(elementId))); // $() will return array of dom element


      var start = el.selectionStart;
      var end = el.selectionEnd;
      var text = el.value;
      var before = text.substring(0, start);
      var after = text.substring(end, text.length);
      el.value = before + newText + after;
      el.selectionStart = el.selectionEnd = start + newText.length;
      el.focus();
    }
  }
});