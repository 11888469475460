define("happyfox-bi/visualizations/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    dataService: Ember.inject.service('data'),
    featuresService: Ember.inject.service('features'),
    applicationService: Ember.inject.service('application'),
    beforeModel: function beforeModel() {
      if (!this.get('featuresService.editVisualization')) {
        this.transitionTo('reports');
      } else if (!this.get('featuresService.oldCreateEditVisualization')) {
        this.transitionTo('visualizations.new.edit', this.paramsFor('visualizations.edit').id);
      }
    },
    model: function model(params) {
      var visualizationId = params.id;
      return this.get('dataService').getVisualization(visualizationId);
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      this.get('applicationService').set('isSidePaneOpen', false);
      controller.initiate();
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        this.get('applicationService').openSidePane();
      }
    }
  });

  _exports.default = _default;
});