define("happyfox-bi/components/auto-complete/local/single/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CTvu82xv",
    "block": "{\"symbols\":[\"result\",\"&default\"],\"statements\":[[6,[37,14],null,[[\"ariaLabel\",\"searchEnabled\",\"options\",\"allowClear\",\"placeholder\",\"searchPlaceholder\",\"searchField\",\"selected\",\"onChange\",\"onInput\",\"triggerClass\",\"dropdownClass\",\"noMatchesMessage\",\"initiallyOpened\",\"renderInPlace\",\"onClose\"],[[35,13],true,[35,12],[35,11],[35,10],[35,9],[35,8],[35,7],[30,[36,1],[[32,0],\"onChange\"],null],[30,[36,1],[[32,0],\"onInput\"],null],[35,6],[35,5],[30,[36,0],[[35,3],[30,[36,4],[[35,3]],null]],null],[35,2],true,[30,[36,1],[[32,0],\"onClose\"],null]]],[[\"default\"],[{\"statements\":[[6,[37,0],[[27,[32,2]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,2,[[32,1]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[32,1]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"action\",\"initiallyOpened\",\"noMatchesMessage\",\"t\",\"dropdownClass\",\"triggerClass\",\"selected\",\"searchField\",\"searchPlaceholder\",\"placeholder\",\"allowClear\",\"options\",\"ariaLabel\",\"power-select\"]}",
    "meta": {
      "moduleName": "happyfox-bi/components/auto-complete/local/single/template.hbs"
    }
  });

  _exports.default = _default;
});