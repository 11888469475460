define("happyfox-bi/components/form-field-input/color/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XgORs/wA",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,7],null,[[\"color\",\"onChange\",\"data-test-id\",\"ariaLabel\",\"class\"],[[35,6,[\"value\"]],[30,[36,5],[[32,0],\"onChange\"],null],[35,4],[35,3],[30,[36,2],[[30,[36,1],[[35,0],\"is-error\"],null],\" \"],null]]]]],[2,\"\\n\\n\"],[19,\"components/form-field-input/partials/error-message\",[]],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"hasErrors\",\"if\",\"concat\",\"ariaLabel\",\"fieldDataTestId\",\"action\",\"field\",\"color-picker\"]}",
    "meta": {
      "moduleName": "happyfox-bi/components/form-field-input/color/template.hbs"
    }
  });

  _exports.default = _default;
});