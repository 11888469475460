define("happyfox-bi/report-templates/components/table-cell/name/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bFy9DxhE",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"u-vertically-centered-container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"u-text-overflow\"],[12],[1,[35,2,[\"content\",\"name\"]]],[13],[2,\" \\n\"],[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"u-icon-left-margin tooltipped tooltipped-top report-template-global\"],[15,\"aria-label\",[30,[36,0],[\"available-across-all-accounts\"],null]],[12],[2,\"\\n      \"],[1,[30,[36,1],[\"info-icon\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"inline-svg\",\"row\",\"isGlobalTemplate\",\"if\"]}",
    "meta": {
      "moduleName": "happyfox-bi/report-templates/components/table-cell/name/template.hbs"
    }
  });

  _exports.default = _default;
});