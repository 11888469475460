define("happyfox-bi/internal-login/user-details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2sAfqxhf",
    "block": "{\"symbols\":[],\"statements\":[[10,\"form\"],[14,0,\"login-content\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"login-content_company-name\"],[12],[1,[30,[36,0],[\"login-happyfox-bi\"],[[\"htmlSafe\"],[true]]]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"login-content_text\"],[12],[1,[30,[36,0],[\"api-key\"],null]],[13],[2,\"\\n\"],[2,\"  \"],[1,[30,[36,2],null,[[\"type\",\"value\",\"class\",\"id\"],[\"text\",[35,1],\"login-content_password\",\"internal-login-password\"]]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"login-content_text\"],[12],[1,[30,[36,0],[\"username-email\"],null]],[13],[2,\"\\n  \"],[1,[30,[36,2],null,[[\"type\",\"value\",\"class\",\"id\"],[\"text\",[35,3],\"login-content_username\",\"internal-login-email\"]]]],[2,\"\\n\"],[6,[37,5],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"login-content_error\"],[12],[1,[30,[36,0],[\"login-error\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[1,[30,[36,9],null,[[\"id\",\"class\",\"text\",\"inProgress\",\"inProgressText\",\"disabled\",\"onClick\"],[\"internal-login-button\",\"login-content_button hf-primary-action\",[30,[36,0],[\"login\"],null],[35,8],[30,[36,0],[\"logging-in\"],null],[35,7],[30,[36,6],[[32,0],\"login\"],null]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"secretApiKey\",\"input\",\"email\",\"showError\",\"if\",\"action\",\"disableSubmit\",\"isLoginInProgress\",\"async-button\"]}",
    "meta": {
      "moduleName": "happyfox-bi/internal-login/user-details/template.hbs"
    }
  });

  _exports.default = _default;
});