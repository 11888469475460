define("happyfox-bi/components/table-cell/value-indicator/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['cell-percentage'],
    value: null,
    isPositive: Ember.computed.gt('value', 0),
    formattedValue: Ember.computed('value', {
      get: function get() {
        var absoluteValue = Math.abs(this.get('value'));
        return "".concat(absoluteValue);
      }
    })
  });

  _exports.default = _default;
});