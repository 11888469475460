define("happyfox-bi/visualizations/new/components/display-settings/data-tile/component", ["exports", "happyfox-bi/visualizations/new/components/display-settings/base-chart/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LABEL_POSITIONS = [{
    id: 'top',
    name: 'Top'
  }, {
    id: 'bottom',
    name: 'Bottom'
  }];
  var TARGET_FIELD_TYPES = [{
    id: 'value',
    name: 'Value'
  }, {
    id: 'label',
    name: 'Label'
  }];
  var COMPARE_METRIC_OPTIONS = [{
    id: 'lower_is_better',
    name: 'Lower is better'
  }, {
    id: 'higher_is_better',
    name: 'Higher is better'
  }];

  var _default = _component.default.extend({
    settings: null,
    featuresService: Ember.inject.service('features'),
    canCompareDateRanges: Ember.computed.reads('featuresService.compareDateRanges'),
    labelPositions: LABEL_POSITIONS,
    targetFieldTypes: TARGET_FIELD_TYPES,
    compareMetricOptions: COMPARE_METRIC_OPTIONS,
    selectedLabelPosition: null,
    selectedCompareMetric: null,
    selectedTargetField: null,
    init: function init() {
      this._super.apply(this, arguments);

      var settings = this.get('settings');
      var availableLabelPositions = this.get('labelPositions');
      var labelPosition = availableLabelPositions.findBy('id', settings.get('labelPosition'));
      this.set('selectedLabelPosition', labelPosition);
      var availableTargetFields = this.get('targetFieldTypes');
      var targetField = availableTargetFields.findBy('id', settings.get('focus'));
      this.set('selectedTargetField', targetField);

      if (this.canCompareDateRanges) {
        var compareMetricOptions = this.compareMetricOptions;
        this.set('selectedCompareMetric', compareMetricOptions.findBy('id', settings.get('compareMetric')));
      }
    },
    actions: {
      onLabelPositionChange: function onLabelPositionChange(position) {
        var settings = this.get('settings');
        settings.set('labelPosition', position.id);
        this.set('selectedLabelPosition', position);
        this.onUpdateDisplaySettings();
      },
      onTargetFieldChange: function onTargetFieldChange(field) {
        var settings = this.get('settings');
        settings.set('focus', field.id);
        this.set('selectedTargetField', field);
        this.onUpdateDisplaySettings();
      },
      onComparisonMetricChange: function onComparisonMetricChange(compareMetric) {
        this.settings.set('compareMetric', compareMetric.id);
        this.set('selectedCompareMetric', compareMetric);
      }
    }
  });

  _exports.default = _default;
});