define("happyfox-bi/visualizations/new/components/fields-selection/doughnut-chart/component", ["exports", "happyfox-bi/visualizations/new/components/fields-selection/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MAX_LIMIT = 20;

  var _default = _component.default.extend({
    intlService: Ember.inject.service('intl'),
    classNames: ['new-create-edit-fields-selection'],
    displaySettings: null,
    settingsFields: null,
    categoryFields: null,
    valueFields: null,
    showCategoryFieldModal: false,
    showValueFieldModal: false,
    canAddCategory: Ember.computed.lt('categoryFields.length', 1),
    canAddValues: Ember.computed.lt('valueFields.length', MAX_LIMIT),
    isAddCategoryDisabled: Ember.computed('valueFields.[]', {
      get: function get() {
        var valueFields = this.valueFields;
        return Ember.isPresent(valueFields) && valueFields.length > 1;
      }
    }),
    isMultipleValuesDisabled: Ember.computed('categoryFields.[]', 'valueFields.[]', {
      get: function get() {
        var categoryFields = this.categoryFields,
            valueFields = this.valueFields;
        return Ember.isPresent(categoryFields) && Ember.isPresent(valueFields);
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      var displaySettings = this.displaySettings,
          settingsFields = this.settingsFields;
      var category = displaySettings.category,
          value = displaySettings.value,
          values = displaySettings.values;
      var categoryField = settingsFields.findBy('name', category);
      var valueFields = Ember.A();

      if (Ember.isPresent(values)) {
        valueFields = values.map(function (field) {
          return settingsFields.findBy('name', field.key);
        });
      } else {
        var valueField = settingsFields.findBy('name', value);
        valueFields = valueField ? Ember.A([valueField]) : Ember.A();
      }

      this.setProperties({
        categoryFields: categoryField ? Ember.A([categoryField]) : Ember.A(),
        valueFields: valueFields
      });
    },
    updateVisualizationRowFields: function updateVisualizationRowFields() {
      this.generateDisplaySettings();
      var categoryFields = this.categoryFields;
      this.onRowsChange(categoryFields, false);
    },
    updateVisualizationValueFields: function updateVisualizationValueFields() {
      this.generateDisplaySettings();
      var valueFields = this.valueFields;
      this.onValuesChange(valueFields, false);
    },
    generateDisplaySettings: function generateDisplaySettings() {
      var categoryFields = this.categoryFields,
          valueFields = this.valueFields,
          displaySettings = this.displaySettings;
      displaySettings.set('category', Ember.isPresent(categoryFields) ? categoryFields[0].name : '');

      if (Ember.isPresent(valueFields)) {
        if (valueFields.length > 1) {
          displaySettings.setProperties({
            'value': null,
            'values': valueFields.map(function (field) {
              return Ember.Object.create({
                label: field.displayName || field.name,
                key: field.name
              });
            })
          });
        } else {
          displaySettings.setProperties({
            'value': valueFields[0].name,
            'values': Ember.A()
          });
        }
      } else {
        displaySettings.setProperties({
          'value': null,
          'values': Ember.A()
        });
      }
    },
    actions: {
      removeCategoryField: function removeCategoryField(field) {
        var categoryFields = this.categoryFields;
        categoryFields.removeObject(field);
        this.updateVisualizationRowFields();
      },
      removeValueField: function removeValueField(field) {
        var valueFields = this.valueFields;
        valueFields.removeObject(field);
        this.updateVisualizationValueFields();
      },
      addCategoryField: function addCategoryField(field) {
        var categoryFields = this.categoryFields;
        categoryFields.addObject(field);
        this.toggleProperty('showCategoryFieldModal');
        this.updateVisualizationRowFields();
      },
      addValueField: function addValueField(field) {
        var valueFields = this.valueFields;
        valueFields.addObject(field);
        this.toggleProperty('showValueFieldModal');
        this.updateVisualizationValueFields();
      },
      toggleCategoryFieldModal: function toggleCategoryFieldModal() {
        if (!this.isAddCategoryDisabled) {
          this.toggleProperty('showCategoryFieldModal');
        }
      },
      toggleValueFieldModal: function toggleValueFieldModal() {
        if (!this.isMultipleValuesDisabled) {
          this.toggleProperty('showValueFieldModal');
        }
      },
      editCategoryField: function editCategoryField(newField, field) {
        var categoryFields = this.categoryFields;
        var indexOfOldField = categoryFields.indexOf(field);
        categoryFields.removeObject(field);
        categoryFields.insertAt(indexOfOldField, newField);
        this.updateVisualizationRowFields();
      },
      editValueField: function editValueField(newField, field) {
        var valueFields = this.valueFields;
        var indexOfOldField = valueFields.indexOf(field);
        valueFields.removeObject(field);
        valueFields.insertAt(indexOfOldField, newField);
        this.updateVisualizationValueFields();
      }
    }
  });

  _exports.default = _default;
});