define("happyfox-bi/register/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    routerService: Ember.inject.service('router'),
    token: null,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.get('routerService').on('routeWillChange', function (transition) {
        var toRouteInfo = transition.to;

        if (toRouteInfo.name === 'register') {
          var token = toRouteInfo.queryParams.token;

          _this.set('token', token);
        }
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      var token = this.get('token');
      controller.set('token', token);

      if (Ember.isPresent(token)) {
        controller.validateToken();
      }
    }
  });

  _exports.default = _default;
});