define("happyfox-bi/business-hours/components/confirmation-dialog/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DEPENDANT_VISUALIZATIONS_LIMIT_IN_DIALOG = 5;

  var _default = Ember.Component.extend({
    entity: null,
    isAddOrUpdate: false,
    isLoading: false,
    dialogTrigger: '',
    dialogClass: '',
    dialogHeader: '',
    inProgress: false,
    inProgressText: '',
    primaryActionText: '',
    secondaryActionText: '',
    showConfirmationDialog: false,
    dependantVisualizations: null,
    removedDataSources: null,
    canShowAllVisualizationsModal: false,
    init: function init() {
      this._super.apply(this, arguments);

      if (this.dependantsRequest) {
        this.fetchDependants();
      }
    },
    initialVisualizationsToShow: Ember.computed('dependantVisualizations.[]', {
      get: function get() {
        var dependantVisualizations = this.dependantVisualizations;
        var visualizations = [];

        if (Ember.isPresent(dependantVisualizations)) {
          visualizations = dependantVisualizations.slice(0, DEPENDANT_VISUALIZATIONS_LIMIT_IN_DIALOG);
        }

        return visualizations;
      }
    }),
    canShowAllVisualizationsLink: Ember.computed('dependantVisualizations.[]', {
      get: function get() {
        var dependantVisualizations = this.dependantVisualizations;
        return Ember.isPresent(dependantVisualizations) && dependantVisualizations.length > DEPENDANT_VISUALIZATIONS_LIMIT_IN_DIALOG;
      }
    }),
    remainingVisualizationsToShow: Ember.computed('dependantVisualizations.[]', 'canShowAllVisualizationsLink', {
      get: function get() {
        var dependantVisualizations = this.dependantVisualizations,
            canShowAllVisualizationsLink = this.canShowAllVisualizationsLink;

        if (canShowAllVisualizationsLink) {
          return dependantVisualizations.length - DEPENDANT_VISUALIZATIONS_LIMIT_IN_DIALOG;
        }
      }
    }),
    fetchDependants: function fetchDependants() {
      var _this = this;

      this.set('isLoading', true);
      this.dependantsRequest().then(function (data) {
        _this.set('dependantVisualizations', Ember.isPresent(data.visualizations) ? data.visualizations : null);
      }).finally(function () {
        _this.set('isLoading', false);
      });
    },
    actions: {
      primaryAction: function primaryAction() {
        this.primaryAction();
      },
      secondaryAction: function secondaryAction() {
        this.secondaryAction();
      },
      toggleAllVisualizationsModal: function toggleAllVisualizationsModal() {
        this.toggleProperty('canShowAllVisualizationsModal');
      },
      closeAllVisualizationsModal: function closeAllVisualizationsModal() {
        this.set('canShowAllVisualizationsModal', false);
      }
    }
  });

  _exports.default = _default;
});