define("happyfox-bi/models/data-set-field", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    dataType: (0, _model.attr)('string'),
    type: (0, _model.attr)('string'),
    sql: (0, _model.attr)('string'),
    relationshipFields: (0, _model.attr)(),
    dataset: (0, _model.attr)('number'),
    relatedDataset: (0, _model.attr)('number'),
    helpText: (0, _model.attr)('string'),
    displayName: (0, _model.attr)('string'),
    defaultAggregationType: (0, _model.attr)('string'),
    group: (0, _model.attr)('string'),
    businessHoursEnabled: (0, _model.attr)('boolean'),
    isCalculatedField: (0, _model.attr)('boolean'),
    configuration: (0, _model.attr)(),
    displayNameOrName: Ember.computed('displayName', 'name', {
      get: function get() {
        var displayName = this.get('displayName');
        var name = this.get('name');
        return Ember.isPresent(displayName) ? displayName : name;
      }
    })
  });

  _exports.default = _default;
});