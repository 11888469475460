define("happyfox-bi/apps/details/account-sync-info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zuUJBeNE",
    "block": "{\"symbols\":[],\"statements\":[[10,\"p\"],[14,0,\"manage-app-form_heading\"],[12],[1,[30,[36,0],[\"apps.sync-info\"],null]],[2,\":\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"app-manage-section_box mod-small\"],[12],[2,\"\\n  \"],[10,\"div\"],[12],[10,\"span\"],[14,0,\"font-semi-bold\"],[12],[1,[30,[36,0],[\"apps.sync-status\"],null]],[2,\":\"],[13],[2,\" \"],[1,[30,[36,0],[[35,1,[\"syncStatus\"]]],null]],[13],[2,\"\\n  \"],[10,\"div\"],[12],[10,\"span\"],[14,0,\"font-semi-bold\"],[12],[1,[30,[36,0],[\"apps.last-successful-sync\"],null]],[2,\":\"],[13],[2,\" \"],[1,[30,[36,3],[[35,1,[\"lastSyncAt\"]],[30,[36,2],[[35,1,[\"lastSyncAt\"]]],[[\"format\"],[\"absolute\"]]],[30,[36,0],[\"apps.not-yet-synced\"],null]],null]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"syncInfo\",\"format-date\",\"if\"]}",
    "meta": {
      "moduleName": "happyfox-bi/apps/details/account-sync-info/template.hbs"
    }
  });

  _exports.default = _default;
});