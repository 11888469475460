define("happyfox-bi/components/hf-nav-tab-item/component", ["exports", "@happyfoxinc/hf-nav-tabs/components/hf-nav-tab-item"], function (_exports, _hfNavTabItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _hfNavTabItem.default.extend({
    isDisabled: false,
    click: function click() {
      if (!this.get('isDisabled')) {
        this.actionOnClick(this.get('value'));
      }
    }
  });

  _exports.default = _default;
});