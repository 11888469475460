define("happyfox-bi/utils/intl/missing-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = missingMessage;

  // Missing Translations
  // Refer https://github.com/ember-intl/ember-intl/blob/212bb1302de039059b3215a91a397c7188f86012/docs/missing-translations.md
  function missingMessage(key) {
    return key;
  }
});