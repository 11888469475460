define("happyfox-bi/components/heat-map/component", ["exports", "happyfox-bi/components/base-chart/component", "@amcharts/amcharts4/core", "@amcharts/amcharts4/charts", "happyfox-bi/components/base-chart/constants", "happyfox-bi/constants"], function (_exports, _component, am4core, am4charts, _constants, _constants2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = _component.default.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.createChart();
    },
    createChart: function createChart() {
      var _this = this;

      var _this$$ = this.$(),
          _this$$2 = _slicedToArray(_this$$, 1),
          element = _this$$2[0];

      var chart = am4core.create(element, am4charts.XYChart);
      var data = this.data;
      var _this$config = this.config,
          xAxisKey = _this$config.xAxis.key,
          yAxisKey = _this$config.yAxis.key,
          valueKey = _this$config.value.key,
          minColor = _this$config.minColor,
          maxColor = _this$config.maxColor;
      var isDateXAxis = this.isAxisDateTimeType(xAxisKey);
      var isDateYAxis = this.isAxisDateTimeType(yAxisKey);
      var isValueCurrencyType = this.isFieldCurrencyType(valueKey);
      var isValueTimeType = this.isFieldTimeType(valueKey);
      var isDurationValue = this.canFormatTimeAsDuration(valueKey);

      if (isDurationValue) {
        this.setupDurationFormatter(chart, valueKey);
      } // Create axes


      var xAxis = chart.xAxes.push(isDateXAxis ? new am4charts.DateAxis() : new am4charts.CategoryAxis());
      var yAxis = chart.yAxes.push(isDateYAxis ? new am4charts.DateAxis() : new am4charts.CategoryAxis());

      if (isDateXAxis) {
        this.prepareDateAxis(xAxis);
      } else {
        xAxis.dataFields.category = xAxisKey;
        xAxis.data = data;
      }

      xAxis.renderer.grid.template.disabled = true;
      xAxis.renderer.minGridDistance = 10;
      xAxis.renderer.cellStartLocation = 0.2;
      xAxis.renderer.cellEndLocation = 0.8;
      var label = xAxis.renderer.labels.template;
      label.truncate = true;
      label.maxWidth = 120;
      label.rotation = 315;
      label.verticalCenter = "top";
      label.horizontalCenter = "right";

      if (isDateYAxis) {
        this.prepareDateAxis(yAxis);
      } else {
        yAxis.data = data;
        yAxis.dataFields.category = yAxisKey;
      }

      yAxis.renderer.grid.template.disabled = true;
      yAxis.renderer.minGridDistance = 5;
      var xAxisTitle = this.get('config.xAxis.label');
      this.setAxisTitle(xAxis, xAxisTitle);
      var yAxisTitle = this.get('config.yAxis.label');
      this.setAxisTitle(yAxis, yAxisTitle);
      var series = chart.series.push(new am4charts.ColumnSeries());

      if (isDateXAxis) {
        series.data = this.convertAndGetDateXAxisData(data, xAxisKey); // using `openDate` since chart expects data to have a start and end date information to construct a heat map cell with date axis
        // Refer https://github.com/amcharts/amcharts4/issues/488

        series.dataFields.openDateX = xAxisKey;
        series.dataFields.dateX = xAxisKey;
      } else {
        series.data = data;
        series.dataFields.categoryX = xAxisKey;
      }

      if (isDateYAxis) {
        series.data = this.convertAndGetDateXAxisData(data, yAxisKey);
        series.dataFields.openDateY = yAxisKey;
        series.dataFields.dateY = yAxisKey;
      } else {
        series.data = data;
        series.dataFields.categoryY = yAxisKey;
      }

      series.dataFields.value = valueKey;

      if (this.showValueLabels) {
        var valueLabel = series.bullets.push(new am4charts.LabelBullet());
        valueLabel.label.text = '{value}';
        valueLabel.label.truncate = true;
        valueLabel.label.fontSize = 14;
        valueLabel.label.interactionsEnabled = false;
      }

      var columnTemplate = series.columns.template;
      columnTemplate.strokeWidth = 1;
      columnTemplate.strokeOpacity = 1;
      columnTemplate.stroke = new am4core.InterfaceColorSet().getFor("background");
      columnTemplate.width = am4core.percent(100);
      columnTemplate.height = am4core.percent(100);
      columnTemplate.adapter.add('tooltipText', function (text, target) {
        var dataItem = target.dataItem;
        var valueCellPlaceholder = "{value}";

        if (dataItem) {
          if (isValueTimeType && Ember.isPresent(dataItem.value)) {
            valueCellPlaceholder = _this.formatValueByTime(dataItem.value, valueKey);
          }

          var rowCellPlaceholder, columnCellPlaceholder;

          if (dataItem.dateX) {
            var axisFormat = _this.getXAxisDateFormat(xAxis);

            rowCellPlaceholder = "{dateX.formatDate('".concat(axisFormat, "')}");
          } else {
            rowCellPlaceholder = "{categoryX}";
          }

          if (dataItem.dateY) {
            var _axisFormat = _this.getXAxisDateFormat(yAxis);

            columnCellPlaceholder = "{dateY.formatDate('".concat(_axisFormat, "')}");
          } else {
            columnCellPlaceholder = "{categoryY}";
          }

          return "".concat(rowCellPlaceholder, ", ").concat(columnCellPlaceholder, ": ").concat(valueCellPlaceholder);
        }

        return text;
      });
      series.heatRules.push({
        target: columnTemplate,
        property: 'fill',
        min: am4core.color(minColor),
        max: am4core.color(maxColor)
      }); // heat legend

      var heatLegend = chart.bottomAxesContainer.createChild(am4charts.HeatLegend);
      heatLegend.width = am4core.percent(100);
      heatLegend.marginTop = 10;
      heatLegend.series = series;

      if (isDurationValue) {
        heatLegend.valueAxis = new am4charts.DurationAxis();
        this.setupDurationFormatter(heatLegend.valueAxis, valueKey);
      } else if (isValueTimeType) {
        this.setUnitsInAxisLabels(heatLegend.valueAxis, this.getTimeFieldBaseUnit(valueKey));
      } else {
        // lower the minGridDistance, more legend labels are shown. Incase of duration axis, we use the default minGridDistance so that labels doesnt overlap
        heatLegend.valueAxis.renderer.minGridDistance = 30;
      }

      heatLegend.valueAxis.renderer.labels.template.fontSize = 9; // heat legend behavior

      columnTemplate.events.on("over", function (event) {
        _this.handleHover(event.target, heatLegend);
      });
      columnTemplate.events.on("hit", function (event) {
        if (_this.canViewRawData) {
          _this.handleRawDataDrillDown(series.data, event.target.dataItem);
        }

        _this.handleHover(event.target, heatLegend);
      });
      columnTemplate.events.on("out", function () {
        heatLegend.valueAxis.hideTooltip();
      });

      if (isValueCurrencyType) {
        this.setElementNumberFormatAsCurrency(chart, valueKey);
      } else {
        // Rounding off to integers
        chart.numberFormatter.numberFormat = '#.';
      }

      this.addExportOption(chart);
      this.addAnnotationsOption(chart);
      this.chart = chart;
    },
    handleHover: function handleHover(column, legend) {
      if (!isNaN(column.dataItem.value)) {
        legend.valueAxis.showTooltipAt(column.dataItem.value);
      } else {
        legend.valueAxis.hideTooltip();
      }
    },
    isAxisDateTimeType: function isAxisDateTimeType(axisKey) {
      var fieldName = axisKey;
      var field = this.get('widgetModel.settings.fields').findBy('name', fieldName);
      var fieldType = field.get('dataType');

      if (fieldType === 'timestamp') {
        var format = field.get('format');
        return _constants.TIMESTAMP_BASED_FIELD_FORMATS.includes(format);
      } else if (fieldType === 'date') {
        var _format = field.get('format');

        return _constants.DATE_BASED_FIELD_FORMATS.includes(_format);
      }

      return false;
    },
    handleRawDataDrillDown: function handleRawDataDrillDown(seriesData, seriesDataItem) {
      var xAxisKey = this.get('config.xAxis.key');
      var yAxisKey = this.get('config.yAxis.key');
      var filters = [];
      var isDateXAxis = Ember.isPresent(seriesDataItem.dateX);
      var isDateYAxis = Ember.isPresent(seriesDataItem.dateY);

      if (isDateXAxis) {
        var categoryValue = seriesDataItem.dateX;
        var dateFilters = this.getDrillFiltersForDateAxis(seriesData, xAxisKey, categoryValue);
        filters.addObjects(dateFilters);
      } else {
        var _categoryValue = seriesDataItem.categoryX;
        var filtersForCategoryValue = this.getFiltersForFieldValue(xAxisKey, _categoryValue);
        filters.addObjects(filtersForCategoryValue);
      }

      if (isDateYAxis) {
        var _categoryValue2 = seriesDataItem.dateY;

        var _dateFilters = this.getDrillFiltersForDateAxis(seriesData, xAxisKey, _categoryValue2);

        filters.addObjects(_dateFilters);
      } else {
        var _categoryValue3 = seriesDataItem.categoryY;

        var _filtersForCategoryValue = this.getFiltersForFieldValue(yAxisKey, _categoryValue3);

        filters.addObjects(_filtersForCategoryValue);
      }

      if (Ember.isPresent(filters)) {
        this.filterOnElementHit(filters);
      }
    },
    replaceNullInRowsAndColumns: function replaceNullInRowsAndColumns() {
      var xAxisKey = this.get('config.xAxis.key');
      var yAxisKey = this.get('config.yAxis.key');
      var excludeNullValues = this.get('config.excludeNullValues');
      var processedData = [];
      this.get('data').forEach(function (item) {
        if (item[xAxisKey] === null || item[yAxisKey] == null) {
          if (!excludeNullValues) {
            item[xAxisKey] = !Ember.isPresent(item[xAxisKey]) ? _constants2.NO_VALUE_SET : item[xAxisKey];
            item[yAxisKey] = !Ember.isPresent(item[yAxisKey]) ? _constants2.NO_VALUE_SET : item[yAxisKey];
            processedData.push(item);
          }
        } else {
          processedData.push(item);
        }
      });
      this.set('data', processedData);
    }
  });

  _exports.default = _default;
});