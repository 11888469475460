define("happyfox-bi/apps/details/wrike/account-info/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    account: null,
    isSyncSuspended: Ember.computed.equal('account.syncInfo.syncStatus', 'suspended'),
    actions: {
      // In B.E callback, based on the wrike account id, we select the right account from our db and set auth data.
      reauthorize: function reauthorize() {
        window.location = window.location.origin + '/integrations/wrike/initiate-oauth/';
      }
    }
  });

  _exports.default = _default;
});