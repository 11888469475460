define("happyfox-bi/visualization-examples/mock-data/page-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PAGE_1_VISUALIZATION_DATA = _exports.PAGE_1 = void 0;
  var PAGE_1 = {
    id: 1,
    name: 'MoM Symptom Percentage',
    order: 1,
    report: 1,
    created_by: 1,
    created_at: '2019-06-17T08:08:33Z',
    updated_at: '2019-06-18T11:20:30Z',
    visualizations: [{
      id: 1,
      name: 'TOP SYMPTOMS',
      page: 1,
      display_type: 'pivot_table',
      display_settings: {
        size: 'horizontal-rectangle-l',
        show_axis_labels: false,
        rows: [{
          key: 'symptom',
          label: 'Symptom'
        }],
        columns: [{
          key: 'month',
          label: 'Month'
        }],
        values: [{
          key: 'quantity',
          label: 'Quantity'
        }, {
          key: 'percentage',
          label: 'Percentage'
        }]
      },
      preferences: {
        filters: {
          match_all: [{
            field: 'Status',
            predicate: 'is',
            value: ['Open', 'New']
          }, {
            field: 'Location',
            predicate: 'is',
            value: ['America', 'India']
          }, {
            field: 'DueDate',
            predicate: 'on',
            value: '2019-06-26'
          }, {
            field: 'Tags',
            predicate: 'contains',
            value: ['test']
          }, {
            field: 'AgentReplyCount',
            predicate: 'gt',
            value: 20
          }, {
            field: 'TimeSpent',
            predicate: 'gt',
            value: {
              value: 20,
              unit: 'minutes'
            }
          }, {
            field: 'Unresponded',
            predicate: 'is',
            value: false
          }, {
            field: 'MultipleOptionCF',
            predicate: 'is',
            value: ['Option 1', 'Option 2']
          }, {
            field: 'UnrespondedSince',
            predicate: 'on_or_after',
            value: '2019-06-10'
          }]
        },
        date_range: 'last_month'
      },
      settings: {
        fields: [{
          'name': 'symptom',
          'type': 'dataset_field',
          'dataset': 1,
          'dataset_field': 12,
          'data_type': 'text',
          'is_editable': false
        }, {
          'name': 'month',
          'type': 'dataset_field',
          'dataset': 1,
          'dataset_field': 13,
          'data_type': 'text',
          'is_editable': false
        }, {
          'name': 'quantity',
          'type': 'aggregation',
          'aggregation_type': 'count',
          'dataset': 1,
          'dataset_field': 14,
          'data_type': 'decimal',
          'is_editable': false
        }, {
          'name': 'percentage',
          'type': 'aggregation',
          'aggregation_type': 'count',
          'dataset': 1,
          'dataset_field': 15,
          'data_type': 'decimal',
          'is_editable': false
        }, {
          'name': 'Status',
          'type': 'dataset_field',
          'dataset': 1,
          'dataset_field': 3,
          'data_type': 'text',
          'is_editable': true
        }, {
          'name': 'AgentReplyCount',
          'type': 'dataset_field',
          'dataset': 1,
          'dataset_field': 11,
          'data_type': 'int',
          'is_editable': true
        }, {
          'name': 'TimeSpent',
          'type': 'dataset_field',
          'dataset': 1,
          'dataset_field': 10,
          'data_type': 'time',
          'is_editable': true
        }, {
          'name': 'Unresponded',
          'type': 'dataset_field',
          'dataset': 1,
          'dataset_field': 12,
          'data_type': 'boolean',
          'is_editable': true
        }, {
          'name': 'Location',
          'type': 'dataset_field',
          'dataset': 1,
          'dataset_field': 24,
          'data_type': 'text',
          'is_editable': true
        }, {
          'name': 'MultipleOptionCF',
          'type': 'dataset_field',
          'dataset': 1,
          'dataset_field': 25,
          'data_type': 'list',
          'is_editable': true
        }, {
          'name': 'DueDate',
          'type': 'dataset_field',
          'dataset': 1,
          'dataset_field': 8,
          'data_type': 'date',
          'is_editable': true
        }, {
          'name': 'UnrespondedSince',
          'type': 'dataset_field',
          'dataset': 1,
          'dataset_field': 26,
          'data_type': 'timestamp',
          'is_editable': true
        }, {
          'name': 'Tags',
          'type': 'dataset_field',
          'dataset': 1,
          'dataset_field': 27,
          'data_type': 'list',
          'is_editable': true
        }, {
          'name': 'Conatct',
          'type': 'dataset',
          'dataset': 1,
          'dataset_field': 25,
          'data_type': 'dataset',
          'is_editable': true
        }, {
          'name': 'Priority',
          'type': 'dataset_field',
          'dataset': 1,
          'dataset_field': 4,
          'data_type': 'text',
          'is_editable': true
        }, {
          'name': 'Category',
          'type': 'dataset_field',
          'dataset': 1,
          'dataset_field': 7,
          'data_type': 'text',
          'is_editable': true
        }],
        query: {
          data_source: 1,
          data_set: 1,
          rows: ['symptom'],
          columns: ['month'],
          values: ['quantity', 'percentage'],
          filters: {
            match_all: []
          },
          sort_by: []
        }
      }
    }],
    layout: {
      'items': [{
        'visualization_id': 1,
        'width': 16,
        'height': 8,
        'x': 0,
        'y': 0
      }]
    }
  };
  _exports.PAGE_1 = PAGE_1;
  var PAGE_1_VISUALIZATION_DATA = [{
    id: 1,
    data: [{
      'symptom': 'Pre-sale Availability',
      'month': 'august',
      'quantity': 432,
      'percentage': 24
    }, {
      'symptom': 'Product Feature Questions',
      'month': 'august',
      'quantity': 111,
      'percentage': 32
    }, {
      'symptom': 'App is not working',
      'month': 'august',
      'quantity': 21,
      'percentage': -30
    }, {
      'symptom': 'Where to buy',
      'month': 'august',
      'quantity': 152,
      'percentage': 18
    }, {
      'symptom': 'Product Feature Questions',
      'month': 'august',
      'quantity': 69,
      'percentage': -50
    }, {
      'symptom': 'App is not working',
      'month': 'september',
      'quantity': 311,
      'percentage': 60
    }, {
      'symptom': 'Where to buy',
      'month': 'september',
      'quantity': 200,
      'percentage': -30
    }, {
      'symptom': 'Request for Demo',
      'month': 'august',
      'quantity': 120,
      'percentage': 20
    }, {
      'symptom': 'Request for Demo',
      'month': 'september',
      'quantity': 80,
      'percentage': 10
    }, {
      'symptom': 'In-App Purchases',
      'month': 'august',
      'quantity': 220,
      'percentage': 30
    }, {
      'symptom': 'In-App Purchases',
      'month': 'september',
      'quantity': 180,
      'percentage': 18
    }, {
      'symptom': 'Upgrades',
      'month': 'august',
      'quantity': 130,
      'percentage': 30
    }, {
      'symptom': 'Upgrades',
      'month': 'september',
      'quantity': 70,
      'percentage': 10
    }, {
      'symptom': null,
      'month': 'september',
      'quantity': 89,
      'percentage': 29
    }]
  }];
  _exports.PAGE_1_VISUALIZATION_DATA = PAGE_1_VISUALIZATION_DATA;
});