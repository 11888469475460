define("happyfox-bi/components/pop-over/trigger/component", ["exports", "@happyfoxinc/hf-pop-over/components/pop-over/trigger"], function (_exports, _trigger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _trigger.default.extend({
    classNameBindings: ['isDisabled:is-disabled'],
    isDisabled: false
  });

  _exports.default = _default;
});