define("happyfox-bi/components/doughnut-chart/component", ["exports", "happyfox-bi/components/base-chart/component", "@amcharts/amcharts4/core", "@amcharts/amcharts4/charts", "@amcharts/amcharts4/plugins/sliceGrouper", "happyfox-bi/constants"], function (_exports, _component, am4core, am4charts, am4plugins_sliceGrouper, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var ANIMATION_DURATION = 400;
  var SLICE_LABEL_KEY = 'label';
  var SLICE_VALUE_KEY = 'value';

  var _default = _component.default.extend({
    dataService: Ember.inject.service('data'),
    settings: Ember.computed.reads('widgetModel.settings'),
    drillCategories: null,
    drillFilters: null,
    legend: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.setProperties({
        drillCategories: [],
        drillFilters: []
      });
    },
    isValueCurrencyType: function isValueCurrencyType() {
      var _this = this;

      var _this$get = this.get('config'),
          value = _this$get.value,
          values = _this$get.values;

      if (value) {
        return this.isFieldCurrencyType(value);
      }

      var valueKeys = values.mapBy('key');
      return valueKeys.every(function (valueKey) {
        return _this.isFieldCurrencyType(valueKey);
      });
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.createChart();
    },
    getData: function getData(requestPayload) {
      return this.get('dataService').getVisualizationDrillDownData(requestPayload);
    },
    getRequestPayload: function getRequestPayload(settings) {
      var requestData = {
        settings: settings
      };

      if (!this.get('isPreview')) {
        requestData.visualizationId = this.get('widgetModel.id');
      }

      if (settings) {
        requestData.isDrillDown = true;
      }

      return requestData;
    },
    getSettings: function getSettings() {
      var drillDownFields = this.get('settings.query.drillDownFields');

      if (this.get('settings.query.drillDownFields.length')) {
        var visualization = this.get('widgetModel');
        var drillFilters = this.get('drillFilters');
        var drillCategories = this.get('drillCategories');
        var settings = Ember.Object.create(visualization.get('settings'));
        var query = Ember.Object.create(settings.get('query'));
        var filters = Ember.Object.create(query.get('filters'));
        var matchAllFilters = filters.get('matchAll').slice();
        matchAllFilters.addObjects(drillFilters);
        filters.set('matchAll', matchAllFilters);
        var row = drillCategories.get('length') ? drillDownFields[drillCategories.get('length') - 1] : query.get('rows.firstObject');
        query.setProperties({
          filters: filters,
          rows: [row]
        });
        settings.set('query', query);
        var config = Ember.Object.create(this.get('config'));
        config.category = row;
        this.set('config', config);
        return settings;
      }
    },
    drillDown: function drillDown(fieldName, filters) {
      var _this2 = this;

      this.get('drillCategories').push(fieldName);
      this.get('drillFilters').addObjects(filters);
      var settings = this.getSettings();
      var requestData = this.getRequestPayload(settings);
      this.getData(requestData).then(function (data) {
        _this2.set('data', data);

        _this2.replaceNullInRowsAndColumns();

        _this2.createAndReplaceChart(true);
      });
    },
    drillUp: function drillUp() {
      var _this3 = this;

      var removedCategoryName = this.get('drillCategories').pop();
      var drillUpFilters = this.get('drillFilters').rejectBy('field', removedCategoryName);
      this.set('drillFilters', drillUpFilters);
      var settings = this.getSettings();
      var requestData = this.getRequestPayload(settings);
      this.getData(requestData).then(function (data) {
        _this3.set('data', data);

        _this3.replaceNullInRowsAndColumns();

        _this3.createAndReplaceChart(false);
      });
    },
    createAndReplaceChart: function createAndReplaceChart() {
      var _this4 = this;

      var animateForward = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      this.pieSeries.labelsContainer.hide();
      this.pieSeries.ticksContainer.hide();
      var isLegendPresent = Ember.isPresent(this.legend);

      if (animateForward) {
        this.pieSeries.animate([{
          property: "startAngle",
          to: 270
        }, {
          property: "endAngle",
          to: 270
        }], ANIMATION_DURATION - 100, am4core.ease.linear);
      } else {
        this.pieSeries.animate([{
          property: "startAngle",
          to: -90
        }, {
          property: "endAngle",
          to: -90
        }], ANIMATION_DURATION - 100, am4core.ease.linear);
      }

      this.button.hide();

      if (isLegendPresent) {
        this.legend.hide();
      }

      var oldChart = this.chart;
      Ember.run.later(function () {
        oldChart.dispose(); // Since amcharts legend is a separate container, we need to dispose it manually

        if (isLegendPresent) {
          _this4.legend.dispose();
        }
      }, ANIMATION_DURATION);
      Ember.run.later(function () {
        _this4.createChart(animateForward);

        if (_this4.get('drillFilters.length')) {
          _this4.button.show();
        } else {
          _this4.button.hide();
        }
      }, ANIMATION_DURATION);
    },
    createChart: function createChart() {
      var _this5 = this;

      var animateForward = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      var chartContainer = this.get('chartContainer');
      var is3D = this.get('config.is3D');
      var chart = am4core.create(chartContainer, is3D ? am4charts.PieChart3D : am4charts.PieChart);

      var _this$get2 = this.get('config'),
          categoryKey = _this$get2.category,
          valueKey = _this$get2.value,
          values = _this$get2.values; // Add button


      var button = chart.chartContainer.createChild(am4core.Button);
      button.padding(5, 5, 5, 5);
      button.width = 25;
      button.align = 'right';
      button.marginRight = 35;
      button.events.on('hit', function () {
        _this5.drillUp();
      });
      button.icon = new am4core.Sprite();
      button.icon.path = 'M16,8 L14,8 L14,16 L10,16 L6,16 L2,16 L2,8 L0,8 L8,0 L16,8 Z M16,8';
      button.hide();
      this.button = button; // Inner radius

      var innerRadius = this.getConfigValue('innerRadius', 50);
      chart.innerRadius = am4core.percent(innerRadius);
      chart.data = this.get('data');
      var isValueTimeType = this.isFieldTimeType(valueKey);
      var isDurationValue = this.canFormatTimeAsDuration(valueKey);

      if (isDurationValue) {
        this.setupDurationFormatter(chart, valueKey);
      }

      var pieSeries = chart.series.push(is3D ? new am4charts.PieSeries3D() : new am4charts.PieSeries());
      pieSeries.dataFields.value = valueKey ? valueKey : SLICE_VALUE_KEY;
      pieSeries.dataFields.category = categoryKey ? categoryKey : SLICE_LABEL_KEY;
      pieSeries.defaultState.transitionDuration = ANIMATION_DURATION;
      pieSeries.labels.template.text = "{category}: {value} ({value.percent.formatNumber('#.0')}%)";
      pieSeries.labelsContainer.hide();
      pieSeries.ticksContainer.hide();
      var configColors = this.get('config.colors');

      if (configColors) {
        var colorSet = new am4core.ColorSet();
        colorSet.list = configColors.map(function (color) {
          return new am4core.color(color);
        });
        pieSeries.colors = colorSet;
      }

      var hideLegend = this.getConfigValue('hideLegend', false);
      var hideLabels = this.getConfigValue('hideLabels', false);

      if (!hideLegend) {
        this.createLegend(chart);
      }

      if (hideLabels) {
        pieSeries.ticks.template.disabled = true;
        pieSeries.labels.template.disabled = true;
      } else {
        this.updateSeriesLabels(pieSeries);
      }

      if (isValueTimeType) {
        pieSeries.slices.template.adapter.add('tooltipText', function (text, target) {
          var tooltipDataItem = target.tooltipDataItem;

          if (tooltipDataItem && Ember.isPresent(tooltipDataItem.value)) {
            return "{category}: ".concat(_this5.formatValueByTime(tooltipDataItem.value, valueKey));
          }

          return text;
        });
      } else {
        pieSeries.slices.template.tooltipText = "{category}: {value} ({value.percent.formatNumber('#.0')}%)";
      }

      var threshold = this.get('config.groupThreshold');
      var grouper = pieSeries.plugins.push(new am4plugins_sliceGrouper.SliceGrouper());
      var groupName = this.get('config.groupLabel');

      if (groupName) {
        grouper.groupName = groupName;
      }

      grouper.clickBehavior = "break";
      grouper.threshold = threshold ? threshold : 10;
      grouper.zoomOutButton.marginTop = 45;
      var isValueCurrencyType = this.isValueCurrencyType();

      if (isValueCurrencyType && valueKey) {
        this.setElementNumberFormatAsCurrency(chart, valueKey);
      } else if (isValueCurrencyType) {
        var valueKeys = values.mapBy('key');
        this.setElementNumberFormatAsCurrency(chart, valueKeys[0]);
      }
      /* Initialize Events*/


      var animation = null; // Default start angle : -90, endAngle: 270;

      if (animateForward) {
        animation = pieSeries.animate([{
          from: -90,
          property: "startAngle",
          to: -90
        }, {
          from: -90,
          property: "endAngle",
          to: 270
        }], ANIMATION_DURATION, am4core.ease.linear);
      } else {
        animation = pieSeries.animate([{
          from: 270,
          property: "startAngle",
          to: -90
        }, {
          from: 270,
          property: "endAngle",
          to: 270
        }], ANIMATION_DURATION, am4core.ease.linear);
      }

      animation.events.on("animationended", function () {
        pieSeries.labelsContainer.show();
        pieSeries.ticksContainer.show();
      });

      if (categoryKey) {
        //FIXME:  Slicer plugin uses "hit" event. So we use "down" event to display in raw data
        pieSeries.slices.template.events.on("down", function (ev) {
          _this5.handleSliceHitEvent(ev);
        });
        /* Events end */
      }

      this.addExportOption(chart);
      this.addAnnotationsOption(chart);
      chart.align = "center";
      chart.valign = "middle";
      this.chart = chart;
      this.pieSeries = pieSeries;
      return chart;
    },
    updateSeriesLabels: function updateSeriesLabels(pieSeries) {
      var _this6 = this;

      var _this$get3 = this.get('config'),
          is3D = _this$get3.is3D,
          valueKey = _this$get3.value;

      var isValueTimeType = this.isFieldTimeType(valueKey);
      pieSeries.slices.template.stroke = '#000';
      pieSeries.slices.template.strokeWidth = 0.2;
      pieSeries.slices.template.strokeOpacity = 1;

      if (is3D) {
        pieSeries.alignLabels = false;
        pieSeries.ticks.template.disabled = true;
      } else {
        pieSeries.ticks.template.fill = '#fff';
        pieSeries.ticks.template.fillOpacity = 1;
        pieSeries.ticks.template.strokeWidth = 2;
        pieSeries.ticks.template.strokeOpacity = 1;
      }

      if (isValueTimeType) {
        pieSeries.labels.template.adapter.add('text', function (text, target) {
          var dataItem = target.dataItem;

          if (dataItem && Ember.isPresent(dataItem.value)) {
            return "{category}: ".concat(_this6.formatValueByTime(dataItem.value, valueKey));
          }

          return text;
        });
      }
    },
    createLegend: function createLegend(chart) {
      var legend = new am4charts.Legend();
      legend.position = 'bottom';
      legend.valueLabels.template.adapter.add('text', function (text, target) {
        if (target.dataItem) {
          return "{value.percent.formatNumber('#.0')}% ({value})";
        }

        return text;
      });
      legend.valueLabels.template.align = "right";
      legend.defaultState.transitionDuration = ANIMATION_DURATION;
      legend.maxHeight = 50; // top legend;

      legend.marginBottom = 10;
      var marker = legend.markers.template.children.getIndex(0);
      marker.cornerRadius(10, 10, 10, 10);
      var markerTemplate = legend.markers.template;
      markerTemplate.width = 10;
      markerTemplate.height = 10;
      legend.scrollable = true;
      legend.itemContainers.template.paddingBottom = 5;
      legend.labels.template.maxWidth = 100;
      legend.labels.template.truncate = true;
      chart.legend = legend;
      this.legend = chart.legend;
    },
    replaceNullInRowsAndColumns: function replaceNullInRowsAndColumns() {
      var categoryKey = this.get('config.category');
      var excludeNullValues = this.get('config.excludeNullValues');
      var processedData = [];
      this.get('data').forEach(function (item) {
        if (item[categoryKey] === null) {
          if (!excludeNullValues) {
            item[categoryKey] = _constants.NO_VALUE_SET;
            processedData.push(item);
          }
        } else {
          processedData.push(item);
        }
      });
      this.set('data', processedData);
    },
    handleSliceHitEvent: function handleSliceHitEvent(ev) {
      var _this$get4 = this.get('config'),
          categoryKey = _this$get4.category;

      var othersSlice = ev.target.dataItem.dataContext.sliceGrouperOther;

      if (!othersSlice) {
        var query = this.get('settings.query');

        if (query.get('drillDownFields.length') && query.get('drillDownFields.lastObject') !== categoryKey) {
          var value = ev.target.dataItem.dataContext[categoryKey];
          var filtersForCategoryValue = this.getFiltersForFieldValue(categoryKey, value); // Filters will be empty won't be there for 'Others' in buckets

          if (Ember.isPresent(filtersForCategoryValue)) {
            this.drillDown(categoryKey, filtersForCategoryValue);
          }
        } else if (this.get('canViewRawData')) {
          var categoryValue = ev.target.dataItem.category;
          var filterConditions = [];

          var _filtersForCategoryValue = this.getFiltersForFieldValue(categoryKey, categoryValue);

          filterConditions.addObjects(_filtersForCategoryValue); // Filters will be empty won't be there for 'Others' in buckets

          if (Ember.isPresent(_filtersForCategoryValue)) {
            if (this.get('drillFilters')) {
              filterConditions.addObjects(this.get('drillFilters'));
            }

            this.filterOnElementHit(filterConditions);
          }
        }
      }
    },
    transformValuesInData: function transformValuesInData() {
      var data = this.data,
          config = this.config;
      var category = config.category,
          values = config.values;

      if (category) {
        return;
      }
      /*
        - Doughnut chart also supports multiple values without category
        - So we create individual datum objects for each value i.e representing each slice
      */


      var transformedData = Ember.A();
      values.forEach(function (value) {
        var _Ember$Object$create;

        transformedData.addObject(Ember.Object.create((_Ember$Object$create = {}, _defineProperty(_Ember$Object$create, SLICE_LABEL_KEY, value.label), _defineProperty(_Ember$Object$create, SLICE_VALUE_KEY, data[0][value.key]), _Ember$Object$create)));
      });
      this.set('data', transformedData);
    }
  });

  _exports.default = _default;
});