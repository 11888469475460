define("happyfox-bi/utilities/request", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isInvalid = isInvalid;
  _exports.isOffline = isOffline;
  _exports.isSuccess = isSuccess;
  _exports.isUnauthorized = isUnauthorized;

  function isOffline(status) {
    return status === 0;
  }

  function isUnauthorized(status) {
    return status === 401;
  }

  function isInvalid(status) {
    return status === 400 || status === 422;
  }

  function isSuccess(status) {
    return status >= 200 && status < 300;
  }
});