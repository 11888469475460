define("happyfox-bi/components/pivot-table/axis-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
   * Each non empty visible cell in pivot table is represented by an axis element
   *
   * Below is the column axis of pivot table
   *
   *    |- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -|
   *    |              |                   NDP                  |       Conservative          |
   *    |              | - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -|
   *    |              |  British Columbia  | Quebec  | Ontario | British Columbia  | Ontario |
   *    |- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -|
   *
   * It will be represented by 7 axis element objects with nesting to represent the hirearchy.
   *
   * Axis element with value NDP is represented as below
   *    {
   *      id: <id>,
   *      isRowAxisElement: false,
   *      value: 'NDP',
   *      subAxisElements: [<axisElement>, <axisElement>, <axisElement>], // For British Columbia, Quebec, Ontario
   *      axisValues: [], // Will be populated only for leaves (British Columbia, Quebec, Ontario),
   *      level: 0,
   *      isLeaf: false,
   *      rowSpan: 1,
   *      columnSpan: 3,
   *      totalAxisElementValue: 75
   *    }
   *
   */
  var _default = Ember.Object.extend({
    id: null,
    isRowAxisElement: false,
    axisFieldName: null,
    //One of the model fields that an axis element refers to.
    value: null,
    parentAxisElement: null,
    subAxisElements: null,
    axisValues: null,
    level: null,
    showAxisLabels: false,
    isLeaf: Ember.computed.empty('subAxisElements'),
    rowSpan: Ember.computed('subAxisElements.@each.rowSpan', 'isRowAxisElement', 'isLeaf', 'showAxisLabels', {
      get: function get() {
        if (this.get('isRowAxisElement')) {
          if (this.get('isLeaf')) {
            return 1;
          } else {
            // rowSpan of a row axis element is the the number of leafs under it
            var rowSpan = 0;
            var subAxisElements = this.get('subAxisElements');
            subAxisElements.forEach(function (axisElement) {
              rowSpan += axisElement.get('rowSpan');
            });
            return rowSpan;
          }
        } else {
          // rowSpan for column axis elements is one if axis labels are not shown.
          if (this.get('isLeaf')) {
            return this.get('showAxisLabels') ? 2 : 1;
          } else {
            return 1;
          }
        }
      }
    }),
    columnSpan: Ember.computed('subAxisElements.@each.columnSpan', 'isRowAxisElement', 'isLeaf', 'showAxisLabels', {
      get: function get() {
        if (this.get('isRowAxisElement')) {
          // columnSpan for row axis elements is one if axis labels are not shown.
          if (this.get('isLeaf')) {
            return this.get('showAxisLabels') ? 2 : 1;
          } else {
            return 1;
          }
        } else {
          if (this.get('isLeaf')) {
            return 1;
          } else {
            // columnSpan of a column axis element is the the number of leafs under it
            var columnSpan = 0;
            var subAxisElements = this.get('subAxisElements');
            subAxisElements.forEach(function (axisElement) {
              columnSpan += axisElement.get('columnSpan');
            });
            return columnSpan;
          }
        }
      }
    }),
    // Sum of all the value nested under this axis element
    totalAxisElementValue: Ember.computed('axisValues.@each.value', 'subAxisElements.@each.totalAxisElementValue', {
      get: function get() {
        if (this.get('isLeaf')) {
          var total = 0;
          this.get('axisValues').forEach(function (axisValue) {
            var value = axisValue.get('value');

            if (value) {
              total += value;
            }
          });
          return total;
        } else {
          var _total = 0;
          var subAxisElements = this.get('subAxisElements');
          subAxisElements.forEach(function (axisElement) {
            _total += axisElement.get('totalAxisElementValue');
          });
          return _total;
        }
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.setProperties({
        subAxisElements: Ember.A(),
        axisValues: Ember.A()
      });
    }
  });

  _exports.default = _default;
});