define("happyfox-bi/visualizations/new/components/merge-datasets/join-settings/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var JOIN_TYPE_OPTIONS = [{
    id: 'inner_join',
    name: 'Inner (only matching rows)'
  }, {
    id: 'left_outer_join',
    name: 'Left Outer (all from first, matching from second)'
  }, // { id: 'right_outer_join', name: 'Right Outer (all from second, matching from first)'},
  {
    id: 'full_outer_join',
    name: 'Full Outer (all rows from both)'
  }];

  var _default = Ember.Component.extend({
    dataService: Ember.inject.service('data'),
    joinSettings: null,
    primaryDataSet: null,
    secondaryDataSet: null,
    primaryDataSetField: null,
    secondaryDataSetField: null,
    joinType: null,
    dataSetOptions: null,
    primaryDataSetFieldOptions: null,
    secondaryDataSetFieldOptions: null,
    joinTypeOptions: JOIN_TYPE_OPTIONS,
    loadingDependencies: false,
    fetchingMergedRecordsCount: false,
    mergedRecordsCount: null,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('loadingDependencies', true);
      var joinSettings = this.get('joinSettings');
      var dataSetOptions = this.get('dataSetOptions');
      var primaryDataSet = this.get('primaryDataSet');
      var joinTypeId = joinSettings.joinType;
      this.set('joinType', this.get('joinTypeOptions').findBy('id', joinTypeId));
      dataSetOptions = dataSetOptions.toArray().removeObject(primaryDataSet);
      this.set('dataSetOptions', dataSetOptions);
      var primaryDataSetFieldOptionsPromise = this.get('dataService').getDataSetFields(primaryDataSet.dataSource, primaryDataSet.id, true);
      var promises = {
        primaryDataSetFieldOptions: primaryDataSetFieldOptionsPromise
      };
      var secondaryDataSetFieldOptionsPromise;
      var secondaryDataSet = joinSettings.get('secondaryDataSet');

      if (secondaryDataSet) {
        secondaryDataSet = dataSetOptions.findBy('id', secondaryDataSet.toString());
        secondaryDataSetFieldOptionsPromise = this.get('dataService').getDataSetFields(secondaryDataSet.dataSource, secondaryDataSet.id, true);
        promises.secondaryDataSetFieldOptions = secondaryDataSetFieldOptionsPromise;
        this.setProperties({
          secondaryDataSourceId: secondaryDataSet.get('dataSource'),
          secondaryDataSet: secondaryDataSet
        });
      }

      Ember.RSVP.hash(promises).then(function (data) {
        var primaryDataSetFieldOptions = data.primaryDataSetFieldOptions,
            secondaryDataSetFieldOptions = data.secondaryDataSetFieldOptions;

        _this.setProperties({
          primaryDataSetFieldOptions: primaryDataSetFieldOptions.rejectBy('dataType', 'dataset'),
          secondaryDataSetFieldOptions: secondaryDataSetFieldOptions ? secondaryDataSetFieldOptions.rejectBy('dataType', 'dataset') : Ember.A()
        });

        var primaryDataSetField = joinSettings.get('primaryDataSetField');

        if (primaryDataSetField) {
          _this.set('primaryDataSetField', primaryDataSetFieldOptions.findBy('id', primaryDataSetField.toString()));
        }

        var secondaryDataSetField = joinSettings.get('secondaryDataSetField');

        if (secondaryDataSetField) {
          _this.set('secondaryDataSetField', secondaryDataSetFieldOptions.findBy('id', secondaryDataSetField.toString()));
        }

        _this.updateJoinSettings();
      }).finally(function () {
        _this.set('loadingDependencies', false);
      });
    },
    hasAllRequiredDetails: Ember.computed('primaryDataSet', 'primaryDataSetField', 'secondaryDataSet', 'secondaryDataSetField', 'jointType', {
      get: function get() {
        var primaryDataSet = this.get('primaryDataSet');
        var secondaryDataSet = this.get('secondaryDataSet');
        var primaryDataSetField = this.get('primaryDataSetField');
        var secondaryDataSetField = this.get('secondaryDataSetField');
        var joinType = this.get('joinType');
        return Ember.isPresent(primaryDataSet) && Ember.isPresent(primaryDataSetField) && Ember.isPresent(secondaryDataSet) && Ember.isPresent(secondaryDataSetField) && Ember.isPresent(joinType);
      }
    }),
    onAllDetails: Ember.observer('hasAllRequiredDetails', function () {
      if (this.get('hasAllRequiredDetails')) {
        this.testMerge();
      }
    }).on('init'),
    updateJoinSettings: function updateJoinSettings() {
      var joinType = this.get('joinType.id');
      var primaryDataSetId = this.get('primaryDataSet') ? Number(this.get('primaryDataSet.id')) : null;
      var secondaryDataSourceId = this.get('secondaryDataSourceId') ? Number(this.get('secondaryDataSet.dataSource')) : null;
      var secondaryDataSetId = this.get('secondaryDataSet') ? Number(this.get('secondaryDataSet.id')) : null;
      var primaryDataSetFieldId = this.get('primaryDataSetField') ? Number(this.get('primaryDataSetField.id')) : null;
      var secondaryDataSetFieldId = this.get('secondaryDataSetField') ? Number(this.get('secondaryDataSetField.id')) : null;
      this.get('joinSettings').setProperties({
        joinType: joinType,
        primaryDataSet: primaryDataSetId,
        primaryDataSetField: primaryDataSetFieldId,
        secondaryDataSource: secondaryDataSourceId,
        secondaryDataSet: secondaryDataSetId,
        secondaryDataSetField: secondaryDataSetFieldId
      });
    },
    testMerge: function testMerge() {
      var _this2 = this;

      var primaryDataSetId = this.get('primaryDataSet.id');
      var secondaryDataSetId = this.get('secondaryDataSet.id');
      var primaryDataSetFieldId = this.get('primaryDataSetField.id');
      var secondaryDataSetFieldId = this.get('secondaryDataSetField.id');
      var joinType = this.get('joinType.id');
      this.set('fetchingMergedRecordsCount', true);
      this.get('dataService').getSampleDataMergedCount(primaryDataSetId, primaryDataSetFieldId, secondaryDataSetId, secondaryDataSetFieldId, joinType).then(function (data) {
        _this2.set('mergedRecordsCount', data.joinedDataCount);
      }).catch(function () {// TODO
      }).finally(function () {
        _this2.set('fetchingMergedRecordsCount', false);
      });
    },
    actions: {
      onPrimaryDataSetChange: function onPrimaryDataSetChange(dataSet) {
        var _this3 = this;

        this.setProperties({
          primaryDataSet: dataSet,
          primaryDataSetFieldOptions: null,
          primaryDataSetField: null
        });
        this.get('joinSettings').setProperties({
          primaryDataSet: Number(dataSet.id),
          primaryDataSetField: null
        });
        this.get('dataService').getDataSetFields(dataSet.dataSource, dataSet.id, true).then(function (fields) {
          _this3.set('primaryDataSetFieldOptions', fields);
        });
      },
      onSecondaryDataSetChange: function onSecondaryDataSetChange(dataSet) {
        var _this4 = this;

        this.setProperties({
          secondaryDataSet: dataSet,
          secondaryDataSetFieldOptions: null,
          secondaryDataSetField: null
        });
        this.get('joinSettings').setProperties({
          secondaryDataSource: Number(dataSet.dataSource),
          secondaryDataSet: Number(dataSet.id),
          secondaryDataSetField: null
        });
        this.get('dataService').getDataSetFields(dataSet.dataSource, dataSet.id, true).then(function (fields) {
          _this4.set('secondaryDataSetFieldOptions', fields);
        });
      },
      onPrimaryDataSetFieldChange: function onPrimaryDataSetFieldChange(field) {
        this.set('primaryDataSetField', field);
        this.get('joinSettings').set('primaryDataSetField', Number(field.id));
      },
      onSecondaryDataSetFieldChange: function onSecondaryDataSetFieldChange(field) {
        this.set('secondaryDataSetField', field);
        this.get('joinSettings').set('secondaryDataSetField', Number(field.id));
      },
      onJoinTypeChange: function onJoinTypeChange(joinType) {
        this.set('joinType', joinType);
        this.get('joinSettings').set('joinType', joinType.id);
      }
    }
  });

  _exports.default = _default;
});