define("happyfox-bi/page-screenshot-loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7fMtNKAM",
    "block": "{\"symbols\":[],\"statements\":[[10,\"section\"],[14,0,\"content js-application-content\"],[14,1,\"js-page-screenshot-loading\"],[12],[2,\"\\n  \"],[19,\"templates/partials/loading\",[]],[2,\"\\n\"],[13]],\"hasEval\":true,\"upvars\":[]}",
    "meta": {
      "moduleName": "happyfox-bi/page-screenshot-loading/template.hbs"
    }
  });

  _exports.default = _default;
});