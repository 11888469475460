define("happyfox-bi/visualizations/new/components/display-settings/segmentation-chart/component", ["exports", "happyfox-bi/visualizations/new/components/display-settings/base-chart/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SEGMENTATION_TYPES = [{
    id: 'static_value',
    name: 'Static Value'
  }, {
    id: 'average',
    name: 'Average'
  }, {
    id: 'percentile',
    name: 'Percentile'
  }];

  var _default = _component.default.extend({
    segmentationTypeOptions: SEGMENTATION_TYPES,
    isXAxisSegmentationTypePercentile: Ember.computed.equal('settings.xAxis.segmentationType', 'percentile'),
    isYAxisSegmentationTypePercentile: Ember.computed.equal('settings.yAxis.segmentationType', 'percentile'),
    isXAxisSegmentationTypeAverage: Ember.computed.equal('settings.xAxis.segmentationType', 'average'),
    isYAxisSegmentationTypeAverage: Ember.computed.equal('settings.yAxis.segmentationType', 'average'),
    selectedXAxisSegmentationType: Ember.computed('settings.xAxis.segmentationType', {
      get: function get() {
        var segmentationType = this.get('settings.xAxis.segmentationType');
        return this.get('segmentationTypeOptions').findBy('id', segmentationType);
      }
    }),
    selectedYAxisSegmentationType: Ember.computed('settings.yAxis.segmentationType', {
      get: function get() {
        var segmentationType = this.get('settings.yAxis.segmentationType');
        return this.get('segmentationTypeOptions').findBy('id', segmentationType);
      }
    }),
    actions: {
      onXAxisSegmentationTypeChange: function onXAxisSegmentationTypeChange(option) {
        this.set('settings.xAxis.segmentationType', option.id);

        if (option.id === 'percentile') {
          this.set('settings.xAxis.segmentationValue', 50);
        } else if (option.id === 'static_value') {
          this.set('settings.xAxis.segmentationValue', null);
        }

        this.send('onUpdateDisplaySettings');
      },
      onYAxisSegmentationTypeChange: function onYAxisSegmentationTypeChange(option) {
        this.set('settings.yAxis.segmentationType', option.id);

        if (option.id === 'percentile') {
          this.set('settings.yAxis.segmentationValue', 50);
        } else if (option.id === 'static_value') {
          this.set('settings.xAxis.segmentationValue', null);
        }

        this.send('onUpdateDisplaySettings');
      },
      onQuadrantSettingsChange: function onQuadrantSettingsChange(key, quadrantSettings) {
        this.set("settings.quadrants.".concat(key), quadrantSettings);
        this.send('onUpdateDisplaySettings');
      }
    }
  });

  _exports.default = _default;
});