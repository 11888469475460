define("happyfox-bi/apps/details/salesforce/salesforce-objects/component", ["exports", "happyfox-bi/utilities/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intlService: Ember.inject.service('intl'),
    requestService: Ember.inject.service('request'),
    toastMessageService: Ember.inject.service('toast-message'),
    saveInProgress: false,
    account: null,
    app: null,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('isFetchingInstanceObjects', true);
      this.get('requestService').get("/integrations/salesforce/accounts/".concat(this.account.id, "/instance-objects/")).then(function (instanceObjects) {
        instanceObjects = (0, _object.camelizeObject)(instanceObjects);

        _this.get('requestService').get("/integrations/salesforce/accounts/".concat(_this.account.id, "/objects/")).then(function (objects) {
          var selectedObjects = objects.map(function (obj) {
            return instanceObjects.findBy('name', obj.name);
          });

          _this.set('selectedObjects', selectedObjects);
        });

        var addObjectsField = Ember.Object.create({
          key: 'role',
          isChoice: true,
          choices: instanceObjects,
          choiceLabelKey: 'labelPlural',
          autoFocus: true,
          placeholder: _this.get('intlService').lookup('apps.salesforce.add-additional-objects')
        });

        _this.set('addObjectsField', addObjectsField);
      }).catch(function (_ref) {
        var errors = _ref.errors;

        if (errors && errors.detail) {
          _this.get('toastMessageService').showMessage({
            type: 'failure',
            text: errors.detail
          });
        }
      }).finally(function () {
        _this.set('isFetchingInstanceObjects', false);
      });
    },
    actions: {
      toggleAddObjects: function toggleAddObjects() {
        this.toggleProperty('isShowingAddObjectsField');
      },
      addObject: function addObject(object) {
        this.get('selectedObjects').addObject(object);
        this.get('addObjectsField').get('choices').removeObject(object);
      },
      removeObject: function removeObject(object) {
        this.get('selectedObjects').removeObject(object);
        this.get('addObjectsField').get('choices').addObject(object);
      },
      saveObjectSettings: function saveObjectSettings() {
        var _this2 = this;

        var data = this.get('selectedObjects');
        this.set('isSaving', true);
        this.get('requestService').post("/integrations/salesforce/accounts/".concat(this.account.id, "/objects/"), data).then(function () {
          _this2.get('toastMessageService').showMessage({
            type: 'success',
            text: 'apps.save-settings-success-message'
          });
        }).catch(function (_ref2) {
          var errors = _ref2.errors;

          if (errors) {
            _this2.get('toastMessageService').showMessage({
              type: 'failure',
              text: errors[0]
            });
          }
        }).finally(function () {
          _this2.set('isSaving', false);
        });
      }
    }
  });

  _exports.default = _default;
});