define("happyfox-bi/components/widget-tile/save-as-sample-visualization/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    dataService: Ember.inject.service('data'),
    featuresService: Ember.inject.service('features'),
    createAsGlobalSampleVisualization: false,
    visualization: null,
    sampleVisualizationTitle: '',
    sampleVisualizationDescription: '',
    isSaveDisabled: Ember.computed.equal('sampleVisualizationTitle', ''),
    init: function init() {
      this._super.apply(this, arguments);

      var visualization = this.get('visualization');
      this.set('sampleVisualizationTitle', visualization.name);
    },
    actions: {
      closeModal: function closeModal() {
        this.closeModalAction();
      },
      onAccountScopeChange: function onAccountScopeChange(isChecked) {
        this.set('createAsGlobalSampleVisualization', isChecked);
      },
      save: function save() {
        var _this = this;

        this.set('saveInProgress', true);
        var visualizationId = Number(this.get('visualization.id'));
        var payload = {
          visualizationId: visualizationId,
          name: this.get('sampleVisualizationTitle'),
          description: this.get('sampleVisualizationDescription'),
          isGlobal: this.createAsGlobalSampleVisualization
        };
        this.dataService.createSampleVisualization(payload).then(function () {
          _this.closeModalAction();
        }).finally(function () {
          _this.set('saveInProgress', false);
        });
      }
    }
  });

  _exports.default = _default;
});