define("happyfox-bi/visualizations/new/components/display-settings/partials/labels/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wt9jfHOZ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"field toggle-field\"],[12],[2,\"\\n  \"],[10,\"div\"],[12],[1,[30,[36,0],[\"hide-labels\"],null]],[13],[2,\"\\n  \"],[1,[30,[36,3],[\"toggle-input\"],[[\"tagName\",\"isChecked\",\"onChange\"],[\"div\",[35,2,[\"hideLabels\"]],[30,[36,1],[[32,0],\"onHideLabelsChange\"],null]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"settings\",\"component\"]}",
    "meta": {
      "moduleName": "happyfox-bi/visualizations/new/components/display-settings/partials/labels/template.hbs"
    }
  });

  _exports.default = _default;
});