define("happyfox-bi/components/form-field/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'section',
    classNames: ['form-field'],
    dropdownClass: 'mod-push-dropdown-up',
    classNameBindings: ['isHorizontal:u-vertically-centered-container', 'field.isPhone:hf-mod-phone', 'field.isEditor:hf-mod-editor'],
    field: null,
    editorId: 'editorLight',
    // Only for html editor input
    isHorizontal: false,
    selectClass: '',
    enableEditorAttachments: true,
    enableEditorDropZone: true,
    testIdPrefix: 'form-field-',
    inputClass: '',
    labelClass: '',
    labelPartial: '',
    autoFocus: false,
    usePopOverNewTooltipForHelpText: false,
    // jsTestClass is needed for acceptance tests of dropdowns(power select)
    jsTestClass: null,
    noMatchesMessage: null,
    hideFieldLabel: Ember.computed('field.{label,isCheckBox,labelPartial}', {
      get: function get() {
        return Ember.isBlank(this.get('field.label')) && Ember.isBlank(this.get('field.labelPartial')) || this.get('field.isCheckBox');
      }
    }),
    actions: {
      choiceChanged: function choiceChanged(newChoice, oldChoice) {
        if (this.onChoiceChange) {
          this.onChoiceChange(newChoice, oldChoice, this.get('field'));
        }
      },
      onEnter: function onEnter() {
        if (this.onEnter) {
          this.onEnter();
        }
      },
      onChange: function onChange(value) {
        if (this.onChange) {
          this.onChange(value);
        }
      },
      onMultichoiceUnselectAll: function onMultichoiceUnselectAll() {
        if (this.onMultichoiceUnselectAll) {
          this.onMultichoiceUnselectAll();
        }
      },
      removeCondition: function removeCondition(condition) {
        if (this.removeCondition) {
          this.removeCondition(condition);
        }
      },
      toggleRelativeDate: function toggleRelativeDate() {
        var field = this.get('field');
        field.toggleProperty('isRelative');
        field.set('value', null);
      },
      clearField: function clearField() {
        if (this.clearField) {
          this.clearField();
        }
      },
      onOpen: function onOpen() {
        if (this.onOpen) {
          this.onOpen();
        }
      },
      onClose: function onClose() {
        if (this.onClose) {
          this.onClose();
        }
      }
    }
  });

  _exports.default = _default;
});