define("happyfox-bi/visualizations/new/components/display-settings/segmentation-chart/quadrant/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    key: null,
    settings: null,
    actions: {
      onNameChange: function onNameChange(name) {
        var settings = _objectSpread(_objectSpread({}, this.get('settings')), {}, {
          name: name
        });

        this.onSettingsChange(this.get('key'), settings);
      },
      onColorChange: function onColorChange(color) {
        var settings = _objectSpread(_objectSpread({}, this.get('settings')), {}, {
          color: color
        });

        this.onSettingsChange(this.get('key'), settings);
      }
    }
  });

  _exports.default = _default;
});