define("happyfox-bi/visualizations/new/components/sort-options/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FV5A1dEi",
    "block": "{\"symbols\":[\"sortOption\"],\"statements\":[[10,\"section\"],[14,0,\"field\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"field_label sort-options_header\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"sort-options_header_title\"],[12],[1,[30,[36,3],[\"sort-options\"],null]],[13],[2,\"\\n\"],[6,[37,6],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,4],null,[[\"modalTitle\",\"canShowGroupingOptions\",\"canShowAggregation\",\"fetchDataSetFields\",\"addFieldAction\",\"toggleModalAction\",\"settingsFields\",\"isUsedForSort\"],[[30,[36,3],[\"add-sort-field\"],null],false,false,[30,[36,0],[[32,0],\"fetchDataSetFields\"],null],[30,[36,0],[[32,0],\"addSortOption\"],null],[30,[36,0],[[32,0],\"toggleAddField\"],null],[35,2],true]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[6,[37,9],[[30,[36,8],[[30,[36,8],[[35,7]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],null,[[\"sortOption\",\"removeSortOption\",\"onOptionOrderChange\",\"class\"],[[32,1],[30,[36,0],[[32,0],\"removeSortOption\"],null],[30,[36,0],[[32,0],\"onOptionOrderChange\"],null],\"field_value\"]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[11,\"div\"],[24,0,\"new-create-edit-fields-selection_label_add icon-btn\"],[24,\"role\",\"button\"],[4,[38,0],[[32,0],\"toggleAddField\"],null],[12],[2,\"\\n    \"],[1,[30,[36,10],[\"plus\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"visualizations/new/components/sort-options/option\",\"settingsFields\",\"t\",\"add-visualization-field\",\"showAddField\",\"if\",\"sortList\",\"-track-array\",\"each\",\"inline-svg\"]}",
    "meta": {
      "moduleName": "happyfox-bi/visualizations/new/components/sort-options/template.hbs"
    }
  });

  _exports.default = _default;
});