define("happyfox-bi/components/hundred-percent-stacked-bar-chart/component", ["exports", "happyfox-bi/components/stacked-bar-chart/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    setSeriesTooltipText: function setSeriesTooltipText(series) {
      var _this = this;

      var isDateXAxis = this.isXAxisDateType();

      var _this$get = this.get('config'),
          yAxisTimeType = _this$get.yAxisTimeType,
          yAxisUnit = _this$get.yAxisUnit;

      var seriesValueKey = series.dataFields.valueKey || series.dataFields.valueY;
      var isValueCurrencyType = this.isFieldCurrencyType(seriesValueKey);
      var useSeriesNameAsTooltipLabel = this.useSeriesNameAsTooltipLabel;
      series.adapter.add('tooltipText', function (text, target) {
        var label, value;
        var tooltipDataItem = target.tooltipDataItem;

        if (useSeriesNameAsTooltipLabel) {
          label = series.name;
        } else if (isDateXAxis) {
          var xAxis = target.xAxis;

          var axisFormat = _this.getXAxisDateFormat(xAxis);

          label = "{dateX.formatDate('".concat(axisFormat, "')}");
        } else {
          label = "{categoryX}";
        }

        value = "{valueY.totalPercent.formatNumber('#.0')}% (".concat(tooltipDataItem.valueY, ")");

        if (isValueCurrencyType) {
          var currencyField = _this.get('widgetModel.settings.fields').findBy('name', seriesValueKey);

          var formattedValue = _this.formatValueByCurrencyField(tooltipDataItem.valueY, currencyField);

          value = "{valueY.totalPercent.formatNumber('#.0')}% (".concat(formattedValue, ")");
        }

        if (yAxisTimeType) {
          value = "{valueY.totalPercent.formatNumber('#.0')}% (".concat(tooltipDataItem.valueY, " ").concat(yAxisUnit, ")");
        }

        return "".concat(label, ": ").concat(value);
      });
    },
    updateConfig: function updateConfig() {
      this._super.apply(this, arguments);

      this.set('usePercentageSeries', true);
    }
  });

  _exports.default = _default;
});