define("happyfox-bi/components/add-visualization-field/dataset-field/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['field-list_item'],
    classNameBindings: ['isDatasetTypeField:mod-dataset', 'isSelected:is-selected'],
    isDatasetTypeField: Ember.computed.equal('datasetField.dataType', 'dataset'),
    datasetFieldNameOrDisplayName: Ember.computed.or('datasetField.displayName', 'datasetField.name'),
    datasetField: null,
    currentRelatedDatasetField: null,
    searchText: '',
    selectedDatasetField: null,
    selectedRelatedDatasetField: null,
    isJoinedDataSetField: false,
    groupsState: null,
    relatedDatasetGroupState: null,
    init: function init() {
      this._super.apply(this, arguments);

      var isDatasetTypeField = this.isDatasetTypeField;

      if (isDatasetTypeField) {
        var dataset = this.get('datasetField.relatedDataset');
        this.set('relatedFields', this.get('relatedDataSetFieldsMap').get(dataset));
        this.initializeRelatedDatasetPanel();
      }
    },
    filteredRelatedFields: Ember.computed('relatedFields.[]', 'searchText', {
      get: function get() {
        var relatedFields = this.relatedFields,
            searchText = this.searchText;
        var filteredRelatedFields = relatedFields;

        if (relatedFields.length) {
          if (searchText.length) {
            filteredRelatedFields = relatedFields.filter(function (field) {
              var fieldDisplayName = field.get('displayName') || field.get('name');
              return fieldDisplayName.toLowerCase().includes(searchText.toLowerCase());
            });
          }
        }

        return filteredRelatedFields.sortBy('displayName');
      }
    }),
    isSelected: Ember.computed('datasetField.id', 'currentRelatedDatasetField', 'selectedRelatedDatasetField', 'selectedDatasetField.id', {
      get: function get() {
        var datasetField = this.datasetField,
            selectedDatasetField = this.selectedDatasetField,
            selectedRelatedDatasetField = this.selectedRelatedDatasetField,
            currentRelatedDatasetField = this.currentRelatedDatasetField;

        if (selectedDatasetField) {
          var isCurrentDatasetFieldSelected = selectedDatasetField.id === datasetField.id;

          if (selectedRelatedDatasetField) {
            return isCurrentDatasetFieldSelected && selectedRelatedDatasetField.id === currentRelatedDatasetField.id;
          }

          return isCurrentDatasetFieldSelected;
        }
      }
    }),
    click: function click(e) {
      e.preventDefault();

      if (!this.get('isDatasetTypeField')) {
        if (this.get('isJoinedDataSetField')) {
          this.onClick(this.get('datasetField'), null, true);
        } else {
          this.onClick(this.get('datasetField'));
        }
      }
    },
    doubleClick: function doubleClick(e) {
      e.preventDefault();

      if (!this.get('isDatasetTypeField')) {
        if (this.get('isJoinedDataSetField')) {
          this.onDoubleClick(this.get('datasetField'), null, true);
        } else {
          this.onDoubleClick(this.get('datasetField'));
        }
      }
    },
    initializeRelatedDatasetPanel: function initializeRelatedDatasetPanel() {
      var groupsState = this.groupsState,
          datasetFieldNameOrDisplayName = this.datasetFieldNameOrDisplayName,
          searchText = this.searchText;
      var relatedDatasetGroupState = groupsState.findBy('name', datasetFieldNameOrDisplayName);

      if (searchText || relatedDatasetGroupState.isActive) {
        relatedDatasetGroupState.set('isOpen', true);
      } else if (!searchText) {
        relatedDatasetGroupState.set('isOpen', relatedDatasetGroupState.isLastInteractedStateOpen);
      }

      this.set('relatedDatasetGroupState', relatedDatasetGroupState);
    },
    actions: {
      toggleRelatedDatasetPanel: function toggleRelatedDatasetPanel() {
        var relatedDatasetGroupState = this.relatedDatasetGroupState,
            searchText = this.searchText;
        var state = !relatedDatasetGroupState.isOpen;
        relatedDatasetGroupState.set('isOpen', state);

        if (!searchText) {
          relatedDatasetGroupState.set('isLastInteractedStateOpen', state);
        }
      },
      onClick: function onClick(field) {
        if (this.get('isDatasetTypeField')) {
          this.onClick(field, this.get('datasetField'));
        } else {
          this.onClick(field);
        }
      },
      onDoubleClick: function onDoubleClick(field) {
        if (this.get('isDatasetTypeField')) {
          this.onDoubleClick(field, this.get('datasetField'));
        } else {
          this.onDoubleClick(field);
        }
      }
    }
  });

  _exports.default = _default;
});