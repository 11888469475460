define("happyfox-bi/components/word-cloud/component", ["exports", "happyfox-bi/components/base-chart/component", "@amcharts/amcharts4/core", "@amcharts/amcharts4/plugins/wordCloud", "happyfox-bi/constants"], function (_exports, _component, am4core, am4plugins_wordCloud, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    dataService: Ember.inject.service('data'),
    settings: Ember.computed.reads('widgetModel.settings'),
    drillFilters: null,
    drillCategories: null,
    drillUpButton: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.setProperties({
        'drillFilters': Ember.A(),
        'drillCategories': Ember.A()
      });
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.createChart();
    },
    getData: function getData(requestPayload) {
      return this.get('dataService').getVisualizationDrillDownData(requestPayload);
    },
    getRequestPayload: function getRequestPayload(settings) {
      var requestData = {
        settings: settings
      };

      if (!this.get('isPreview')) {
        requestData.visualizationId = this.get('widgetModel.id');
      }

      if (settings) {
        requestData.isDrillDown = true;
      }

      return requestData;
    },
    getSettings: function getSettings() {
      var drillDownFields = this.get('settings.query.drillDownFields');

      if (this.get('settings.query.drillDownFields.length')) {
        var visualization = this.get('widgetModel');
        var drillFilters = this.get('drillFilters');
        var drillCategories = this.get('drillCategories');
        var settings = Ember.Object.create(visualization.get('settings'));
        var query = Ember.Object.create(settings.get('query'));
        var filters = Ember.Object.create(query.get('filters'));
        var matchAllFilters = filters.get('matchAll').slice();
        matchAllFilters.addObjects(drillFilters);
        filters.set('matchAll', matchAllFilters);
        var row = drillCategories.get('length') ? drillDownFields[drillCategories.get('length') - 1] : query.get('rows.firstObject');
        query.setProperties({
          filters: filters,
          rows: [row]
        });
        settings.set('query', query);
        var config = Ember.Object.create(this.get('config'));
        config.category = row;
        this.set('config', config);
        return settings;
      }
    },
    drillUp: function drillUp() {
      var _this = this;

      this.drillFilters.popObject();
      this.drillCategories.popObject();
      var settings = this.getSettings();
      var requestData = this.getRequestPayload(settings);
      this.getData(requestData).then(function (data) {
        _this.set('data', data);

        _this.replaceNullInRowsAndColumns();

        _this.createChart();
      });
    },
    drillDown: function drillDown(fieldName, filters) {
      var _this2 = this;

      var drillFilters = this.drillFilters.addObjects(filters);
      this.set('drillFilters', drillFilters);
      this.drillCategories.push(fieldName);
      var settings = this.getSettings();
      var requestPayload = this.getRequestPayload(settings);
      this.getData(requestPayload).then(function (data) {
        _this2.set('data', data);

        _this2.replaceNullInRowsAndColumns();

        _this2.createChart();
      });
    },
    createChart: function createChart() {
      var _this3 = this;

      var chartContainer = this.get('chartContainer');
      var chart = am4core.create(chartContainer, am4plugins_wordCloud.WordCloud);

      var _this$get = this.get('config'),
          categoryKey = _this$get.category,
          valueKey = _this$get.value;

      var isValueCurrencyType = this.isFieldCurrencyType(valueKey);

      if (isValueCurrencyType) {
        this.setElementNumberFormatAsCurrency(chart, valueKey);
      }

      var series = chart.series.push(new am4plugins_wordCloud.WordCloudSeries());
      var isValueTimeType = this.isFieldTimeType(valueKey);
      var isDurationValue = this.canFormatTimeAsDuration(valueKey);

      if (isDurationValue) {
        this.setupDurationFormatter(chart, valueKey);
      }

      series.data = this.get('data');
      series.dataFields.word = categoryKey;
      series.dataFields.value = valueKey; // Add button

      var button = chart.chartContainer.createChild(am4core.Button);
      button.padding(5, 5, 5, 5);
      button.align = 'right';
      button.marginRight = 5;
      button.y = 25;
      button.events.on('hit', function () {
        _this3.drillUp();
      });
      button.icon = new am4core.Sprite();
      button.icon.path = 'M16,8 L14,8 L14,16 L10,16 L6,16 L2,16 L2,8 L0,8 L8,0 L16,8 Z M16,8';
      this.drillUpButton = button;

      if (this.drillFilters.length) {
        button.show();
      } else {
        button.hide();
      }

      series.labels.template.tooltip = new am4core.Tooltip();

      if (isValueCurrencyType) {
        // Since new instance of tooltip is created, chart's currency formatting is not inherited by tooltip
        // Hence manually format value in tooltip using adapter
        var currencyField = this.get('widgetModel.settings.fields').findBy('name', valueKey);
        series.labels.template.adapter.add('tooltipText', function (text, target) {
          var tooltipDataItem = target.tooltipDataItem;

          if (tooltipDataItem) {
            return "".concat(tooltipDataItem.word, ":\n").concat(_this3.formatValueByCurrencyField(tooltipDataItem.value, currencyField));
          }
        });
      }

      if (isValueTimeType) {
        series.labels.template.adapter.add('tooltipText', function (text, target) {
          var tooltipDataItem = target.tooltipDataItem;

          if (tooltipDataItem && Ember.isPresent(tooltipDataItem.value)) {
            return "[bold]{word}: \n[bold]".concat(_this3.formatValueByTime(tooltipDataItem.value, valueKey), "[/]");
          }

          return text;
        });
      } else {
        series.labels.template.tooltipText = "[bold]{word}:\n[bold]{value}[/]";
      }

      series.labels.template.tooltip.label.textAlign = 'middle';
      series.colors = new am4core.ColorSet();

      var _this$get2 = this.get('widgetModel.settings'),
          query = _this$get2.query;

      series.labels.template.events.on("hit", function (ev) {
        var dataItem = ev.target.dataItem;

        if (dataItem && Ember.isPresent(query.get('drillDownFields')) && query.get('drillDownFields.lastObject') !== categoryKey) {
          var filterValue = dataItem.dataContext[categoryKey];

          var filtersForCategoryValue = _this3.getFiltersForFieldValue(categoryKey, filterValue); // Filters will be empty won't be there for 'Others' in buckets


          if (Ember.isPresent(filtersForCategoryValue)) {
            _this3.drillDown(categoryKey, filtersForCategoryValue);
          }
        } else if (dataItem && _this3.get('canViewRawData')) {
          var categoryValue = dataItem.word;
          var filterConditions = [];

          var _filtersForCategoryValue = _this3.getFiltersForFieldValue(categoryKey, categoryValue);

          filterConditions.addObjects(_filtersForCategoryValue); // Filters will be empty won't be there for 'Others' in buckets

          if (Ember.isPresent(_filtersForCategoryValue)) {
            if (_this3.get('drillFilters')) {
              filterConditions.addObjects(_this3.get('drillFilters'));
            }

            _this3.filterOnElementHit(filterConditions);
          }
        }
      });
      this.addExportOption(chart);
      this.addAnnotationsOption(chart);
      chart.align = 'center';
      chart.valign = 'middle';
      this.chart = chart;
    },
    replaceNullInRowsAndColumns: function replaceNullInRowsAndColumns() {
      var categoryKey = this.get('config.category');
      var excludeNullValues = this.get('config.excludeNullValues');
      var processedData = [];
      this.get('data').forEach(function (item) {
        if (item[categoryKey] === null) {
          if (!excludeNullValues) {
            item[categoryKey] = _constants.NO_VALUE_SET;
            processedData.push(item);
          }
        } else {
          processedData.push(item);
        }
      });
      this.set('data', processedData);
    }
  });

  _exports.default = _default;
});