define("happyfox-bi/visualizations/components/sort-options/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['sort-options'],
    fields: null,
    sortList: null,
    unusedFields: Ember.computed('fields.@each.name', 'sortList.@each.field', {
      get: function get() {
        var unusedFields = this.get('fields').slice();
        this.get('sortList').forEach(function (sortOption) {
          var field = unusedFields.findBy('name', sortOption.get('field.name'));
          unusedFields.removeObject(field);
        });
        return unusedFields;
      }
    }),
    onFieldsChange: Ember.observer('fields.@each.id', function () {
      for (var i = 0; i < this.get('sortList.length'); i++) {
        var sortOption = this.get('sortList')[i];

        if (!this.get('fields').findBy('name', sortOption.get('field.name'))) {
          this.get('sortList').removeObject(sortOption);
          --i;
        }
      }
    }).on('init'),
    actions: {
      onChange: function onChange() {
        this.onChange();
      },
      onOptionOrderChange: function onOptionOrderChange() {
        this.send('onChange');
      },
      removeSortOption: function removeSortOption(item) {
        this.get('sortList').removeObject(item);
        this.send('onChange');
      },
      addSortOption: function addSortOption(field) {
        var sortOption = Ember.Object.create({
          field: field,
          order: 'ascending'
        });
        this.get('sortList').addObject(sortOption);
        this.send('onChange');
      }
    }
  });

  _exports.default = _default;
});