define("happyfox-bi/components/table-cell/percentage/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jkpz9kNW",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[30,[36,1],[[35,0],\"cell-percentage_positive\",\"cell-percentage_negative\"],null]],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[30,[36,1],[[35,0],\"cell-percentage_positive_arrow-up\",\"cell-percentage_negative_arrow-down\"],null]],[12],[13],[2,\"\\n  \"],[10,\"div\"],[12],[1,[34,2]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"isPositive\",\"if\",\"formattedValue\"]}",
    "meta": {
      "moduleName": "happyfox-bi/components/table-cell/percentage/template.hbs"
    }
  });

  _exports.default = _default;
});