define("happyfox-bi/report-subscriptions/components/subscribe-to-report/utilities", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MINUTE_OPTIONS = _exports.HOUR_OPTIONS = void 0;

  function getHours() {
    var startHour = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    var hours = Ember.A();

    for (var i = parseInt(startHour); i < 24; i++) {
      var value = i;
      var label = i.toString();

      if (label.length === 1) {
        label = '0'.concat(label);
      }

      var hour = Ember.Object.create({
        id: value,
        text: label
      });
      hours.pushObject(hour);
    }

    return hours;
  }

  function getMinutes() {
    var startMinute = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    var minutes = Ember.A();

    for (var i = parseInt(startMinute); i < 60; i++) {
      var value = i;
      var label = i.toString();

      if (label.length === 1) {
        label = '0'.concat(label);
      }

      var minute = Ember.Object.create({
        id: value,
        text: label
      });
      minutes.pushObject(minute);
    }

    return minutes;
  }

  var HOUR_OPTIONS = getHours();
  _exports.HOUR_OPTIONS = HOUR_OPTIONS;
  var MINUTE_OPTIONS = getMinutes();
  _exports.MINUTE_OPTIONS = MINUTE_OPTIONS;
});