define("happyfox-bi/components/check-box/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    checked: false,
    value: null,
    isDisabled: false,
    classNameBindings: [':check-box', 'isDisabled:is-disabled'],
    'data-test-id': 'check-box',
    attributeBindings: ['data-checked'],
    'data-checked': Ember.computed.reads('checked'),
    click: function click(e) {
      e.stopPropagation();
      this.toggleProperty('checked');

      if (this.onChange) {
        this.onChange(this.get('value'), this.get('checked'));
      }
    }
  });

  _exports.default = _default;
});