define("happyfox-bi/models/page", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    layout: (0, _model.attr)(),
    visualizations: (0, _model.hasMany)('visualization'),
    report: (0, _model.attr)('number'),
    settings: (0, _model.attr)(),
    lastSyncAt: (0, _model.attr)('string')
  });

  _exports.default = _default;
});