define("happyfox-bi/serializers/page", ["exports", "happyfox-bi/serializers/base", "@ember-data/serializer/rest", "happyfox-bi/utilities/object"], function (_exports, _base, _rest, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend(_rest.EmbeddedRecordsMixin, {
    attrs: {
      visualizations: {
        embedded: 'always'
      }
    },
    normalize: function normalize(modelClass, hash, prop) {
      var propertiesToCamelize = ['layout', 'settings'];
      propertiesToCamelize.forEach(function (key) {
        var value = hash[key];

        if (value) {
          hash[key] = (0, _object.camelizeObject)(value);
        }
      });
      return this._super(modelClass, hash, prop);
    }
  });

  _exports.default = _default;
});