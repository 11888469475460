define("happyfox-bi/visualizations/new/components/display-settings/partials/y-axis-ranges/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mG7IaUid",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"u-flex\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"field mod-y-axis-min-field\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"field_label\"],[12],[1,[30,[36,0],[\"min\"],null]],[13],[2,\"\\n      \"],[10,\"div\"],[12],[2,\"\\n        \"],[10,\"input\"],[14,0,\"field_value\"],[15,\"placeholder\",[31,[[30,[36,0],[\"auto\"],null]]]],[15,2,[31,[[34,1,[\"yAxisMin\"]]]]],[15,\"onblur\",[30,[36,2],[[32,0],\"onYAxisMinValueChange\"],[[\"value\"],[\"target.value\"]]]],[14,4,\"number\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"field mod-y-axis-max-field\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"field_label\"],[12],[1,[30,[36,0],[\"max\"],null]],[13],[2,\"\\n      \"],[10,\"div\"],[12],[2,\"\\n        \"],[10,\"input\"],[14,0,\"field_value\"],[15,\"placeholder\",[31,[[30,[36,0],[\"auto\"],null]]]],[15,2,[31,[[34,1,[\"yAxisMax\"]]]]],[15,\"onblur\",[30,[36,2],[[32,0],\"onYAxisMaxValueChange\"],[[\"value\"],[\"target.value\"]]]],[14,4,\"number\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"settings\",\"action\",\"isChartAxisType\",\"if\"]}",
    "meta": {
      "moduleName": "happyfox-bi/visualizations/new/components/display-settings/partials/y-axis-ranges/template.hbs"
    }
  });

  _exports.default = _default;
});