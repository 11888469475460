define("happyfox-bi/visualizations/new/components/benchmark-panel/benchmark-item/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Y_AXIS_TYPES = _exports.BENCHMARK_VALUE_TYPES = _exports.BENCHMARK_TYPES = void 0;
  var BENCHMARK_TYPES = [{
    id: 'range',
    label: 'edit-visualization.range'
  }, {
    id: 'line',
    label: 'edit-visualization.line'
  }];
  _exports.BENCHMARK_TYPES = BENCHMARK_TYPES;
  var BENCHMARK_VALUE_TYPES = [{
    id: 'average',
    label: 'edit-visualization.average'
  }, {
    id: 'percentile',
    label: 'edit-visualization.percentile'
  }, {
    id: 'custom',
    label: 'edit-visualization.custom'
  }];
  _exports.BENCHMARK_VALUE_TYPES = BENCHMARK_VALUE_TYPES;
  var Y_AXIS_TYPES = [{
    id: 'right',
    label: 'Right'
  }, {
    id: 'left',
    label: 'Left'
  }];
  _exports.Y_AXIS_TYPES = Y_AXIS_TYPES;
});