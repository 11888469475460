define("happyfox-bi/components/grid-item/component", ["exports", "ember-gridstack/components/grid-stack-item"], function (_exports, _gridStackItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var GS_PREFIX = 'data-gs-'; // prefix used by gridstack.js

  var MIN_WIDTH = 4;
  var MIN_HEIGHT = 4;

  var _default = _gridStackItem.default.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      var options = Ember.get(this, 'options');

      if (Ember.isPresent(options)) {
        options.setProperties({
          id: options.visualizationId,
          autoPosition: Ember.isPresent(options.autoPosition) || true,
          minWidth: options.minWidth || MIN_WIDTH,
          minHeight: options.minHeight || MIN_HEIGHT,
          x: options.x || 0,
          y: options.y || 0
        });
        this.set('attributeBindings', Object.keys(options).map(function (key) {
          var dataKey = GS_PREFIX + Ember.String.dasherize(key);
          return "options.".concat(key, ":").concat(dataKey);
        }));
      }

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});