define("happyfox-bi/manage/reports/edit-report-pane/form-model", ["exports", "happyfox-bi/form-models/base-form"], function (_exports, _baseForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var ACCESS_TO_OPTIONS = [{
    id: 'all_users',
    name: 'All Users'
  }, {
    id: 'custom_users',
    name: 'Custom Users'
  }];
  var CUSTOM_USERS_ACCESS_TYPE = 'custom_users';

  var _default = _baseForm.default.extend({
    intlService: Ember.inject.service('intl'),
    isAdd: false,
    dataSource: null,
    basicFieldKeys: null,
    usersList: null,
    clientsList: null,
    scrollableContainerSelector: '.js-edit-report-form_content',
    init: function init() {
      this._super.apply(this, arguments);

      var rawFields = Ember.A();
      var intlService = this.intlService,
          dataSource = this.dataSource;
      var nameField = Ember.Object.create({
        key: 'name',
        isText: true,
        isMandatory: true,
        label: intlService.lookup('name')
      });

      var _ACCESS_TO_OPTIONS$fi = ACCESS_TO_OPTIONS.filter(function (option) {
        return option.id === dataSource.accessTo;
      }),
          _ACCESS_TO_OPTIONS$fi2 = _slicedToArray(_ACCESS_TO_OPTIONS$fi, 1),
          userAccessType = _ACCESS_TO_OPTIONS$fi2[0];

      var accessToField = Ember.Object.create({
        key: 'accessTo',
        isChoice: true,
        isMandatory: true,
        choices: ACCESS_TO_OPTIONS,
        choiceValueKey: 'id',
        choiceLabelKey: 'name',
        value: userAccessType || null,
        placeholder: intlService.lookup('select-user-access'),
        label: intlService.lookup('access-to')
      });
      var customUsersAccessField = Ember.Object.create({
        key: 'users',
        isMultiChoice: true,
        isMultiChoiceDropdown: true,
        valueProperty: 'id',
        labelProperty: 'name',
        isMandatory: dataSource.accessTo === CUSTOM_USERS_ACCESS_TYPE,
        options: Ember.A(),
        optionsComponent: 'manage/reports/edit-report-pane/user-option',
        placeholder: intlService.lookup('select-users'),
        label: intlService.lookup('users'),
        pluralizedTranslationKey: 'users'
      });
      var clientAccessField = Ember.Object.create({
        key: 'clients',
        isMultiChoice: true,
        isMultiChoiceDropdown: true,
        valueProperty: 'id',
        labelProperty: 'name',
        isMandatory: false,
        options: this.clientsList,
        placeholder: intlService.lookup('select-clients'),
        label: intlService.lookup('clients'),
        pluralizedTranslationKey: 'clients'
      });
      rawFields.addObjects([nameField, accessToField, customUsersAccessField, clientAccessField]);
      this.setProperties({
        fields: this._createFieldModels(rawFields),
        basicFieldKeys: rawFields.mapBy('key')
      });

      this._copyFromDataSource();
    },
    _copyFromDataSource: function _copyFromDataSource() {
      this._super.apply(this, arguments);

      if (this.dataSource.accessTo === CUSTOM_USERS_ACCESS_TYPE) {
        this.manageCustomUsers();
        this.model.users.updateInitialValue();
      }
    },
    manageCustomUsers: function manageCustomUsers() {
      var model = this.model;
      var userOptions = this.usersList;
      var usersModel = model.users; // Users with access to all reports will be autoselected and disabled(to remove)

      var usersWithAccessToAllReports = userOptions.filterBy('viewAllReports');
      var selectedUsers = usersModel.value;
      var values = Ember.A().addObjects(usersWithAccessToAllReports).addObjects(selectedUsers);
      usersModel.set('options', userOptions);
      usersModel.set('value', values.uniq());
    }
  });

  _exports.default = _default;
});