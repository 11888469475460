define("happyfox-bi/components/grouped-bar-chart/component", ["exports", "happyfox-bi/components/stacked-bar-chart/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    updateConfig: function updateConfig() {
      this._super.apply(this, arguments);

      this.set('config.isGrouped', true);
      this.set('isStacked', false);
    }
  });

  _exports.default = _default;
});