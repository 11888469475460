define("happyfox-bi/initializers/modify-power-select-multiple-trigger", ["exports", "ember-power-select/components/power-select-multiple/trigger"], function (_exports, _trigger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'modify-power-select-multiple-trigger',
    initialize: function initialize() {
      _trigger.default.reopen({
        didReceiveAttrs: function didReceiveAttrs() {
          return; // To prevent power select default behaviour of clearing search text on close
        },
        actions: {
          onKeydown: function onKeydown(e) {
            var _this$getProperties = this.getProperties('onKeydown', 'select'),
                onKeydown = _this$getProperties.onKeydown,
                select = _this$getProperties.select;

            if (onKeydown && onKeydown(e) === false) {
              e.stopPropagation();
              return false;
            }

            if (e.keyCode === 8) {
              e.stopPropagation();

              if (Ember.isBlank(e.target.value)) {
                var lastSelection = select.selected[select.selected.length - 1];

                if (lastSelection) {
                  select.actions.select(this.get('buildSelection')(lastSelection, select), e);
                  select.actions.open(e);
                }
              }
            } else if (e.keyCode >= 48 && e.keyCode <= 90 || e.keyCode === 32) {
              // Keys 0-9, a-z or SPACE
              e.stopPropagation();
            } else if (e.keyCode === 13) {
              e.preventDefault();
            }
          }
        }
      });
    }
  };
  _exports.default = _default;
});