define("happyfox-bi/services/features", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    profileService: Ember.inject.service('profile'),
    features: Ember.computed.reads('profileService.profile.features'),
    visualizationFilters: true,
    editVisualization: Ember.computed.reads('features.editVisualization'),
    createVisualization: Ember.computed.reads('features.createVisualization'),
    createReports: Ember.computed.reads('features.createReports'),
    createPages: Ember.computed.reads('features.createPages'),
    exportVisualizations: Ember.computed.reads('features.exportVisualizations'),
    exportRawData: Ember.computed.reads('features.exportRawData'),
    viewRawData: Ember.computed.reads('features.viewRawData'),
    editFormulaField: Ember.computed.reads('features.editFormulaField'),
    inviteUser: Ember.computed.reads('features.inviteUser'),
    inviteClient: Ember.computed.reads('features.inviteClient'),
    visualizationExamples: Ember.computed.reads('features.visualizationExamples'),
    manageVisualization: Ember.computed.or('createVisualization', 'editVisualization'),
    oldCreateEditVisualization: Ember.computed.reads('features.oldCreateEditVisualization'),
    compareDateRanges: Ember.computed.reads('features.compareDateRanges'),
    manageDataSources: Ember.computed.reads('features.manageDataSources'),
    businessHours: Ember.computed.reads('features.businessHours'),
    benchmarks: Ember.computed.reads('features.benchmarks'),
    annotations: Ember.computed.reads('features.annotations'),
    sampleVisualizations: Ember.computed.reads('features.sampleVisualizations'),
    createSampleVisualizations: Ember.computed.reads('features.createSampleVisualizations'),
    createGlobalSampleVisualizations: Ember.computed.reads('features.createGlobalSampleVisualizations'),
    combineDataSets: Ember.computed.reads('features.combineDatasets'),
    userApiToken: Ember.computed.reads('features.userApiToken'),
    manageApps: Ember.computed.reads('features.manageApps'),
    themes: Ember.computed.reads('features.themes'),
    reportTemplates: Ember.computed.reads('features.reportTemplates'),
    createReportTemplates: Ember.computed.reads('features.createReportTemplates'),
    createGlobalReportTemplates: Ember.computed.reads('features.createGlobalReportTemplates'),
    reportSubscriptions: Ember.computed.reads('features.reportSubscriptions'),
    manageReports: Ember.computed.reads('features.manageReports'),
    manageUsers: Ember.computed.reads('features.manageUsers')
  });

  _exports.default = _default;
});