define("happyfox-bi/components/slide-in/component", ["exports", "ember-wormhole/components/ember-wormhole", "happyfox-bi/utilities/run-loop"], function (_exports, _emberWormhole, _runLoop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import config from 'happyfox-web/config/environment';
  var _default = _emberWormhole.default.extend({
    init: function init() {
      this._super.apply(this, arguments); // this.set('to', config.environment === 'test' ? 'ember-testing' : 'body');


      this.set('to', 'body');
    },
    isOpen: false,
    canHide: true,
    isHidden: false,
    hideOverlay: false,
    onClose: null,
    afterClose: null,
    modifierClass: '',
    actions: {
      toggleVisibility: function toggleVisibility() {
        this.toggleProperty('isHidden');
        this.toggleProperty('hideOverlay');
      },
      close: function close() {
        var _this = this;

        this.set('isHidden', true);
        (0, _runLoop.runLater)(this, function () {
          if (!_this.get('isDestroyed')) {
            _this.set('isOpen', false);

            if (_this.afterClose) {
              _this.afterClose();
            }
          }
        }, 200);

        if (this.onClose) {
          this.onClose();
        }
      }
    }
  });

  _exports.default = _default;
});