define("happyfox-bi/business-hours/components/add/form-field-models/day-field", ["exports", "happyfox-bi/form-field-models/object-field"], function (_exports, _objectField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _objectField.default.extend({
    timeFields: null,
    init: function init() {
      this._super.apply(this, arguments);

      var timeFields = this.get('childFields').rejectBy('key', 'active');
      this.set('timeFields', timeFields);
    },
    onTimeChange: Ember.observer('timeFields.@each.value.value', function () {
      this.validate();
    }),
    validate: function validate() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this._super().then(function () {
          var childFields = _this.get('childFields');

          var startHour = parseInt(childFields.findBy('key', 'startHour').get('value'));
          var startMinute = parseInt(childFields.findBy('key', 'startMinute').get('value'));
          var endHour = parseInt(childFields.findBy('key', 'endHour').get('value'));
          var endMinute = parseInt(childFields.findBy('key', 'endMinute').get('value'));

          if (endHour < startHour || endHour === startHour && endMinute <= startMinute) {
            _this.addError(_this.get('intlService').lookup('business-hours-validation.invalid-end-time'));

            reject();
          }

          resolve();
        }).catch(function () {
          reject();
        });
      });
    }
  });

  _exports.default = _default;
});