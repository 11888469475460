define("happyfox-bi/components/gauge-chart/component", ["exports", "happyfox-bi/components/base-chart/component", "@amcharts/amcharts4/core", "@amcharts/amcharts4/charts"], function (_exports, _component, am4core, am4charts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.createChart();
    },
    createChart: function createChart() {
      var _this$get = this.get('config'),
          bands = _this$get.bands,
          valueKey = _this$get.value,
          min = _this$get.min,
          max = _this$get.max;

      var chartContainer = this.get('chartContainer');
      var chart = am4core.create(chartContainer, am4charts.GaugeChart);
      var data = this.data;
      var targetValue = data[0][valueKey];
      var isValueCurrencyType = this.isFieldCurrencyType(valueKey);
      var gaugeMin = Ember.isPresent(min) ? min : 0;
      var gaugeMax;

      if (targetValue) {
        gaugeMax = Ember.isPresent(max) ? max : 2 * targetValue;
      } else {
        gaugeMax = Ember.isPresent(max) ? max : 100;
      }

      var isValueTimeType = this.isFieldTimeType(valueKey);
      var isDurationValue = this.canFormatTimeAsDuration(valueKey);

      if (isDurationValue) {
        this.setupDurationFormatter(chart, valueKey);
      }

      var hasBands = Ember.isPresent(bands);
      chart.innerRadius = am4core.percent(80); //Create Inner axis

      var innerXAxis = chart.xAxes.push(new am4charts.ValueAxis());
      innerXAxis.min = gaugeMin;
      innerXAxis.max = gaugeMax;
      innerXAxis.strictMinMax = true;
      innerXAxis.renderer.inside = hasBands ? true : false;
      innerXAxis.renderer.radius = am4core.percent(80);
      innerXAxis.renderer.grid.template.disabled = true;
      innerXAxis.renderer.labels.template.disabled = true;
      innerXAxis.renderer.ticks.template.disabled = true; // Create outer axis to show gauge ranges

      var outerXAxis = chart.xAxes.push(new am4charts.ValueAxis());
      outerXAxis.min = gaugeMin;
      outerXAxis.max = gaugeMax;
      outerXAxis.strictMinMax = true;
      var outerRenderer = outerXAxis.renderer;
      outerRenderer.radius = am4core.percent(80); // Show only first and last labels for the gauge axis ( i.e inner axis)

      var gaugeAxisStart = innerXAxis.axisRanges.create();
      gaugeAxisStart.value = innerXAxis.min;
      gaugeAxisStart.label.text = innerXAxis.min;
      var gaugeAxisEnd = innerXAxis.axisRanges.create();
      gaugeAxisEnd.value = innerXAxis.max;
      gaugeAxisEnd.label.text = innerXAxis.max;

      if (isValueCurrencyType) {
        var currencyField = this.get('widgetModel.settings.fields').findBy('name', valueKey);
        gaugeAxisStart.label.text = this.formatValueByCurrencyField(innerXAxis.min, currencyField);
        gaugeAxisEnd.label.text = this.formatValueByCurrencyField(innerXAxis.max, currencyField);
      }

      if (hasBands) {
        gaugeAxisEnd.label.horizontalCenter = 'right';
        gaugeAxisEnd.label.radius = 30;
        innerXAxis.renderer.radius = am4core.percent(60);
      }

      if (hasBands) {
        // We need to show only band labels. Hence axis labels are disabled.
        outerRenderer.labels.template.disabled = true;
        this.setUpBandsConfiguration(outerXAxis, bands);
      } else {
        outerRenderer.grid.template.disabled = true;
        outerRenderer.labels.template.disabled = true;
        outerRenderer.ticks.template.disabled = true;
      } // This is a default gauge range which is stacked beneath all other ranges. 


      var outerAxisRange = outerXAxis.axisRanges.create();
      outerAxisRange.value = gaugeMin;
      outerAxisRange.endValue = gaugeMax; // If band ranges are too less, we show the remaining area in a darker shade

      outerAxisRange.axisFill.fillOpacity = hasBands ? 0.2 : 1;
      outerAxisRange.axisFill.fill = hasBands ? am4core.color('#666') : am4core.color("#626acd");
      outerAxisRange.axisFill.zIndex = -1;

      if (targetValue) {
        var targetHand;
        targetHand = chart.hands.push(new am4charts.ClockHand());
        targetHand.axis = hasBands ? innerXAxis : outerXAxis;
        targetHand.value = targetValue > gaugeMax ? gaugeMax : targetValue;
        targetHand.startWidth = 5;
        targetHand.strokeWidth = 0;
        targetHand.pin.disabled = false;
        targetHand.pin.radius = 5;
      } // Create label that displays target value at center of chart


      var label = chart.radarContainer.createChild(am4core.Label);
      label.isMeasured = false;
      label.fontSize = "2em";
      label.x = am4core.percent(50);
      label.paddingTop = 5;
      label.horizontalCenter = "middle";

      if (targetValue) {
        if (isValueCurrencyType) {
          var _currencyField = this.get('widgetModel.settings.fields').findBy('name', valueKey);

          label.text = this.formatValueByCurrencyField(targetValue, _currencyField);
        } else if (isDurationValue) {
          var durationFormatter = chart.durationFormatter;
          var baseUnit = durationFormatter.baseUnit;
          /* Identify format of target value using formatter's built-in method */

          var durationFormat = durationFormatter.getFormat(targetValue, null, baseUnit);
          /*
            - Formatter's built-in `format` method: fn(value, format, baseUnit)
            - Using Format of target value for formatting both min & max labels 
          */

          if (innerXAxis.min > 0) {
            gaugeAxisStart.label.text = durationFormatter.format(innerXAxis.min, durationFormat, baseUnit);
          }

          if (innerXAxis.max > 0) {
            gaugeAxisEnd.label.text = durationFormatter.format(innerXAxis.max, durationFormat, baseUnit);
          }

          label.text = this.formatValueByTime(targetValue, valueKey);
        } else if (isValueTimeType) {
          gaugeAxisStart.label.text = this.formatValueByTime(innerXAxis.min, valueKey);
          gaugeAxisEnd.label.text = this.formatValueByTime(innerXAxis.max, valueKey);
          label.text = this.formatValueByTime(targetValue, valueKey);
        } else {
          label.text = targetValue;
        }
      }

      this.addExportOption(chart);
      this.addAnnotationsOption(chart);
      this.chart = chart;
    },
    setUpBandsConfiguration: function setUpBandsConfiguration(axis, bands) {
      //Create Axes Ranges for bands
      bands.forEach(function (band) {
        var range = axis.axisRanges.create();
        range.axisFill.fill = am4core.color(band.color);
        range.axisFill.fillOpacity = 0.8;
        range.axisFill.zIndex = -1;
        range.value = band.min;
        range.endValue = band.max;
        range.grid.strokeOpacity = 0;
        range.stroke = am4core.color(band.color).lighten(-0.1);
        range.label.text = band.name;
        range.label.fontWeight = "bold";
        range.label.inside = true;
        range.label.bent = true;
        range.label.location = 0.5;
        range.label.radius = am4core.percent(10);
        range.label.fill = am4core.color('#000');
        range.label.valign = "middle"; // Axis renderer labels do not include band labels. Hence axis ranges are used
        // Refer https://www.amcharts.com/docs/v4/tutorials/using-axis-ranges-to-place-labels-at-arbitrary-values-or-dates/

        if (Ember.isPresent(band.min)) {
          var bandLabelRange = axis.axisRanges.create();
          bandLabelRange.value = band.min;
          bandLabelRange.label.text = band.min;
        }

        if (Ember.isPresent(band.max)) {
          var _bandLabelRange = axis.axisRanges.create();

          _bandLabelRange.value = band.max;
          _bandLabelRange.label.text = band.max;
        }
      });
    }
  });

  _exports.default = _default;
});