define("happyfox-bi/components/text-tile/component", ["exports", "happyfox-bi/components/base-chart/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    textAsHtml: Ember.computed('widgetModel.displaySettings.text', {
      get: function get() {
        var text = this.get('widgetModel.displaySettings.text');
        var html = text.replace(/\n/g, '<br />');
        return html;
      }
    })
  });

  _exports.default = _default;
});