define("happyfox-bi/reports/details/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    dataService: Ember.inject.service('data'),
    toastMessageService: Ember.inject.service('toast-message'),
    redirect: function redirect() {
      var _this = this;

      var reportId = this.paramsFor('reports.details').id;
      return this.get('dataService').getReport(reportId).then(function (report) {
        var pageId = report.get('pages.firstObject.id');

        _this.transitionTo('reports.details.pages.details', pageId);
      }).catch(function (_ref) {
        var status = _ref.status;

        if (status === 404) {
          _this.transitionTo('reports');

          _this.toastMessageService.showMessage({
            type: 'failure',
            text: 'report-not-found'
          });
        } else {
          _this.transitionTo('error');
        }
      });
    }
  });

  _exports.default = _default;
});