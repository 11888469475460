define("happyfox-bi/visualizations/create/controller", ["exports", "happyfox-bi/visualizations/create-edit-controller"], function (_exports, _createEditController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _createEditController.default.extend({
    isNew: true
  });

  _exports.default = _default;
});