define("happyfox-bi/visualizations/new/components/display-settings/doughnut-chart/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CZCqK9Z1",
    "block": "{\"symbols\":[],\"statements\":[[19,\"visualizations/new/components/display-settings/partials/labels\",[]],[2,\"\\n\"],[19,\"visualizations/new/components/display-settings/partials/legend\",[]],[2,\"\\n\"],[10,\"div\"],[14,0,\"field\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"field_label new-create-edit-fields-info_container\"],[12],[2,\"\\n    \"],[10,\"div\"],[12],[1,[30,[36,0],[\"group-threshold\"],null]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"u-icon-left-margin tooltipped tooltipped-top-right\"],[15,\"aria-label\",[30,[36,0],[\"group-threshold-help-text\"],null]],[12],[2,\"\\n      \"],[1,[30,[36,1],[\"info-icon\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[1,[30,[36,4],null,[[\"class\",\"value\",\"focus-out\"],[\"field_value\",[35,3,[\"groupThreshold\"]],[30,[36,2],[[32,0],\"onUpdateDisplaySettings\"],null]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"field\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"field_label\"],[12],[1,[30,[36,0],[\"group-label\"],null]],[13],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[1,[30,[36,4],null,[[\"class\",\"value\",\"focus-out\"],[\"field_value\",[35,3,[\"groupLabel\"]],[30,[36,2],[[32,0],\"onUpdateDisplaySettings\"],null]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":true,\"upvars\":[\"t\",\"inline-svg\",\"action\",\"settings\",\"input\"]}",
    "meta": {
      "moduleName": "happyfox-bi/visualizations/new/components/display-settings/doughnut-chart/template.hbs"
    }
  });

  _exports.default = _default;
});