define("happyfox-bi/components/form-field-input/multi-choice/component", ["exports", "happyfox-bi/components/form-field-input/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    selectedValues: Ember.computed('field.value.[]', {
      get: function get() {
        var value = this.get('field.value');

        if (value) {
          value = value.filter(function (item) {
            return Ember.isPresent(item);
          });
          return value.mapBy(this.get('field.valueProperty'));
        }
      }
    }),
    actions: {
      updateSelection: function updateSelection(valueIds) {
        var _this = this;

        var value = valueIds.map(function (id) {
          return _this.get('field.options').findBy(_this.get('field.valueProperty'), id);
        });
        this.set('field.value', value);
        this.get('field').validate();

        if (this.onChange) {
          this.onChange();
        }
      }
    }
  });

  _exports.default = _default;
});