define("happyfox-bi/form-models/base-form", ["exports", "@ember-data/model", "happyfox-bi/utilities/object", "happyfox-bi/utilities/form-models", "happyfox-bi/utilities/request", "happyfox-bi/utilities/scroll"], function (_exports, _model, _object, _formModels, _request, _scroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    fields: null,
    errors: null,
    isHorizontalForm: false,
    dataSource: null,
    basicFieldKeys: null,
    intlService: Ember.inject.service('intl'),
    dynamicFieldsMetaInfo: null,
    isNew: false,
    scrollableContainerSelector: '',
    init: function init() {
      this._super.apply(this, arguments);

      this.set('errors', Ember.A());
    },
    model: Ember.computed('fields.[]', {
      get: function get() {
        var fieldModels = Ember.Object.create();
        this.get('fields').forEach(function (fieldItem) {
          fieldModels.set(fieldItem.get('key'), fieldItem);
        });
        return fieldModels;
      }
    }),
    dynamicFields: Ember.computed('fields.[]', {
      get: function get() {
        return this.get('fields').filterBy('isDynamic');
      }
    }),
    isDirty: Ember.computed('fields.@each.isDirty', {
      get: function get() {
        return this.get('fields').isAny('isDirty');
      }
    }),
    // rawFields is an array of fields data
    _createFieldModels: function _createFieldModels(rawFields) {
      var _this = this;

      var fieldModels = Ember.A(); // check type of each field and create field model

      rawFields.forEach(function (rawField) {
        fieldModels.pushObject((0, _formModels.createField)(_this, rawField));
      });
      return fieldModels;
    },
    validate: function validate() {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var validationPromises = Ember.A();

        var fields = _this2.get('fields');

        fields.forEach(function (field) {
          validationPromises.pushObject(field.validate());
        });
        Ember.RSVP.allSettled(validationPromises).then(function (result) {
          var isValid = result.isEvery('state', 'fulfilled');

          _this2.set('isValid', isValid);

          if (isValid) {
            resolve();
          } else {
            reject();
          }
        });
      });
    },
    _populateErrors: function _populateErrors(errors) {
      var _this3 = this;

      var fields = this.get('fields');

      if ((0, _request.isInvalid)(errors.status)) {
        var errorsPayload = errors.errors;
        (0, _object.getKeysFromObject)(errorsPayload).forEach(function (errorKey) {
          if (errorKey === 'nonFieldErrors') {
            _this3.set('errors', errorsPayload[errorKey]);
          } else {
            var field = fields.findBy('key', errorKey);

            if (Ember.isPresent(field)) {
              field.setErrors(errorsPayload[errorKey]);
            }
          }
        });
      }
    },
    scrollToError: function scrollToError() {
      var _this4 = this;

      Ember.run.next(this, function () {
        var scrollableContainerSelector = _this4.get('scrollableContainerSelector') || '.hf-entity-content';
        var errorFieldsSelector = '.form-field .is-error';
        var nonFieldErrorSelector = '.js-form-content_non-field-errors';

        if (Ember.$(scrollableContainerSelector).length) {
          if (Ember.$(errorFieldsSelector).length) {
            (0, _scroll.scrollToElement)(scrollableContainerSelector, errorFieldsSelector);
          } else if (Ember.$(nonFieldErrorSelector).length) {
            (0, _scroll.scrollToElement)(scrollableContainerSelector, nonFieldErrorSelector);
          } else {
            (0, _scroll.scrollToTop)(scrollableContainerSelector);
          }
        }
      });
    },
    _copyFromDataSource: function _copyFromDataSource() {
      var _this5 = this;

      var source = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var basicFieldKeys = this.get('basicFieldKeys');
      var dataSource = source || this.get('dataSource'); // relationshipNames hold the property names which are relationships to datasource model

      var relationshipNames = Ember.A();

      if (dataSource instanceof _model.default) {
        dataSource.eachRelationship(function (name) {
          relationshipNames.addObject(name);
        });
      }

      if (basicFieldKeys) {
        basicFieldKeys.forEach(function (key) {
          var field = _this5.get('model').get(key); // To skip dynamic fields if not present


          if (field) {
            var value = dataSource.get(key);

            if (relationshipNames.includes(key)) {
              // Properties of datasource holds the fulfilled promise instead of resolved value
              // If the resolved value is array, fullfilled promise will have toArray function
              // Otherwise resolved value is present in content of fullfilled promise
              if (value.toArray) {
                value = value.toArray();
              } else {
                value = value.content;
              }

              field.set('value', value);
            } else if (field.isChoice && field.choiceValueKey) {
              var choices = field.choices;
              var selectedChoice = choices.findBy(field.choiceValueKey, dataSource.get(field.get('key')));

              if (selectedChoice) {
                field.set('value', selectedChoice);
              }
            } else if (field.isMultiChoice) {
              var values = dataSource.get(key).map(function (value) {
                return field.get('options').findBy(field.apiValueKey, value);
              }); // avoid app breakage incase BE send values not present in options

              values = values.compact();
              field.set('value', values);
            } else if (field.isObject) {
              var objectSource = dataSource.get(field.key);
              field.get('childFields').forEach(function (childField) {
                if (objectSource) {
                  // objectSource will not be present in case of new
                  var _value = objectSource.get(childField.get('key'));

                  if (Ember.isPresent(_value) && childField.isChoice && childField.choiceValueKey) {
                    var _choices = childField.choices; // FIX ME: Commented out due to conversion of value to value.toString() doesnt work for integer value.
                    // let selectedChoice = choices.findBy(childField.choiceValueKey, typeof(value) === 'boolean' ? value : value.toString());

                    var _selectedChoice = _choices.findBy(childField.choiceValueKey, _value);

                    childField.set('value', _selectedChoice);
                  } else if (Ember.isPresent(_value)) {
                    childField.set('value', objectSource.get(childField.get('key')));
                  }
                }

                if (!_this5.get('isClone')) {
                  childField.updateInitialValue();
                }

                _this5.setDefaultChoiceValue(childField);
              });
            } else if (field.isDateRange) {
              field.set('value', Ember.Object.create({
                dateRange: value,
                startDate: moment(dataSource.get('fromDate')),
                endDate: moment(dataSource.get('toDate'))
              }));
            } else if (!Ember.isNone(value)) {
              field.set('value', dataSource.get(key));
            } else {
              // In edit, initialValue shouldn't be defaultValue
              if (_this5.get('isEdit')) {
                field.clear();
              }
            }

            if (!_this5.get('isClone')) {
              field.updateInitialValue();
            }

            _this5.setDefaultChoiceValue(field);
          }
        });
      }
    },
    setDefaultChoiceValue: function setDefaultChoiceValue(field) {
      if (field.isChoice && (field.isMandatory || field.makeEmptyChoiceAsDefault) && Ember.isBlank(field.get('value'))) {
        field.setDefaultChoiceValue();
      }
    },
    _copyToDataSource: function _copyToDataSource() {
      var _this6 = this;

      var dataSource = this.get('dataSource');
      var basicFieldKeys = this.get('basicFieldKeys');

      if (basicFieldKeys) {
        basicFieldKeys.forEach(function (key) {
          var field = _this6.get('model').get(key); // Dynamic field might not be present for this key


          if (field) {
            if (_this6.get('isEdit') && !field.get('isDirty')) {
              return; // because we will be sending a PATCH request during edit.
            }

            dataSource.set(key, field.getCleanValue());
          }
        });
      }
    },
    getModifiedFields: function getModifiedFields() {
      var formModel = this.get('model');
      var basicFieldKeys = this.get('basicFieldKeys');
      var source = Ember.Object.create({});
      basicFieldKeys.forEach(function (key) {
        var field = formModel.get(key);

        if (field && field.get('isDirty')) {
          source.set(key, field.getCleanValue());
        }
      });
      return source;
    },
    submit: function submit() {
      var _this7 = this;

      var dataSource = this.get('dataSource');
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this7.validate().then(function () {
          _this7._copyToDataSource();

          dataSource.save().then(function (data) {
            resolve(data);
          }).catch(function (errors) {
            _this7._populateErrors(errors);

            if (!_this7.get('isNew')) {
              dataSource.rollbackAttributes();
              dataSource.rollbackRelationships();
            }

            _this7.scrollToError();

            reject(errors);
          });
        }).catch(function () {
          _this7.scrollToError();

          reject();
        });
      });
    },
    reset: function reset() {
      this.set('errors', Ember.A());
      this.get('fields').forEach(function (field) {
        field.reset();
      });
    },
    reinitialize: function reinitialize() {
      this.get('fields').forEach(function (field) {
        field.updateInitialValue();
      });
    }
  });

  _exports.default = _default;
});