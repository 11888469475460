define("happyfox-bi/visualizations/new/components/sort-options/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['sort-options'],
    sortList: null,
    showAddField: false,
    settingsFields: null,
    actions: {
      fetchDataSetFields: function fetchDataSetFields() {
        return this.fetchDataSetFields();
      },
      toggleAddField: function toggleAddField() {
        this.toggleProperty('showAddField');
      },
      onChange: function onChange() {
        this.onChange();
      },
      onOptionOrderChange: function onOptionOrderChange() {
        this.send('onChange');
      },
      removeSortOption: function removeSortOption(item) {
        this.get('sortList').removeObject(item);
        this.send('onChange');
      },
      addSortOption: function addSortOption(field) {
        var sortOption = Ember.Object.create({
          field: field,
          order: 'ascending'
        });
        this.get('sortList').addObject(sortOption);
        this.send('onChange');
        this.toggleProperty('showAddField');
      }
    }
  });

  _exports.default = _default;
});