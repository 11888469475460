define("happyfox-bi/services/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    isSidePaneOpen: true,
    isIndependentWebPage: false,
    toggleSidePane: function toggleSidePane() {
      this.toggleProperty('isSidePaneOpen');
    },
    openSidePane: function openSidePane() {
      this.set('isSidePaneOpen', true);
    },
    closeSidePane: function closeSidePane() {
      this.set('isSidePaneOpen', false);
    }
  });

  _exports.default = _default;
});