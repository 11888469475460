define("happyfox-bi/components/widget-tile/move-visualization-modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    dataService: Ember.inject.service('data'),
    visualization: null,
    pageOptions: null,
    selectedPage: null,
    isSaveDisabled: Ember.computed.equal('selectedPage', null),
    init: function init() {
      this._super.apply(this, arguments);

      var pages = this.get('dataService').peekAllPagesFromReports();
      this.set('pageOptions', this.groupPagesByReports(pages));
    },
    groupPagesByReports: function groupPagesByReports(pages) {
      var reports = this.get('dataService').peekAllReports();
      var groupedPages = Ember.A();
      reports.forEach(function (report) {
        var pageOptions = pages.filterBy('report', Number(report.id));
        var groupedOption = Ember.Object.create({
          groupName: report.name,
          options: pageOptions
        });
        groupedPages.addObject(groupedOption);
      });
      return groupedPages;
    },
    actions: {
      closeModal: function closeModal() {
        var refresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        var pageId = arguments.length > 1 ? arguments[1] : undefined;
        this.closeModalAction(refresh, pageId);
      },
      onPageChange: function onPageChange(page) {
        this.set('selectedPage', page);
      },
      move: function move() {
        var _this = this;

        this.set('saveInProgress', true);
        var visualization = this.get('visualization');
        visualization.set('page', this.get('selectedPage.id'));
        visualization.save().then(function (response) {
          _this.send('closeModal', true, response.page);
        }).finally(function () {
          _this.set('saveInProgress', false);
        });
      }
    }
  });

  _exports.default = _default;
});