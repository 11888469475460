define("happyfox-bi/initializers/model", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var cachingRestrictedTypes = [// Some relationships aren't meant to come back
  'update', 'task'];

  function canCache(relationshipType) {
    return cachingRestrictedTypes.indexOf(relationshipType) !== -1;
  }

  var _default = {
    name: 'model',
    initialize: function initialize() {
      _model.default.reopen({
        ready: function ready() {
          var _this = this;

          Ember.run.next(this, function () {
            if (!_this.isDestroyed) {
              _this.cacheOriginalRelations();
            }
          });
        },
        didCreate: function didCreate() {
          // Check if super needs to be called
          this.cacheOriginalRelations();
        },
        didUpdate: function didUpdate() {
          this.cacheOriginalRelations();
        },
        reload: function reload() {
          var _this2 = this;

          // didLoad doesn't get called on model.reload() https://github.com/emberjs/data/issues/3748
          return this._super().then(function (model) {
            _this2.cacheOriginalRelations();

            return model;
          });
        },
        hasChanges: function hasChanges() {
          return !Ember.$.isEmptyObject(this.changedRelationships()) || !Ember.$.isEmptyObject(this.changedAttributes());
        },
        cacheOriginalRelations: function cacheOriginalRelations() {
          var originalRelations = {};
          var record = this;

          if (record) {
            record.eachRelationship(function (key, relationship) {
              if (canCache(relationship.type)) {
                return;
              }

              if (relationship.kind === 'belongsTo') {
                originalRelations[key] = record.belongsTo(key).id();
              } else if (relationship.kind === 'hasMany') {
                originalRelations[key] = record.hasMany(key).ids();
              }
            });
          }

          this.set('originalRelations', originalRelations);
        },
        changedRelationships: function changedRelationships() {
          var originalRelations = this.originalRelations;

          if (Ember.isBlank(originalRelations)) {
            return {};
          }

          var changedRelationships = {};
          var record = this;

          if (record) {
            record.eachRelationship(function (key, relationship) {
              var isHasMany = relationship.kind === 'hasMany';
              var isBelongsTo = relationship.kind === 'belongsTo';
              var currentValue;
              var oldValue = originalRelations[key];

              if (canCache(relationship.type)) {
                return;
              }

              if (isBelongsTo) {
                currentValue = record.belongsTo(key).id();
              } else if (isHasMany) {
                currentValue = record.hasMany(key).ids();

                if (!_.isEqual(currentValue, oldValue)) {
                  changedRelationships[key] = [oldValue, currentValue];
                }

                return;
              }

              if (Ember.isBlank(currentValue) && Ember.isBlank(oldValue)) {
                return;
              }

              if (Ember.isBlank(currentValue) && !Ember.isBlank(oldValue) || !Ember.isBlank(currentValue) && Ember.isBlank(oldValue)) {
                changedRelationships[key] = [oldValue, currentValue];
                return;
              }

              if (currentValue !== oldValue) {
                changedRelationships[key] = [oldValue, currentValue];
              }
            });
          }

          return changedRelationships;
        },
        rollbackRelationships: function rollbackRelationships() {
          var _this3 = this;

          // TODO: Support rollback for newly created records (with out ids. Present only in store)
          var originalRelations = this.originalRelations;

          if (Ember.isBlank(originalRelations)) {
            return {};
          }

          var record = this;
          record.eachRelationship(function (key, relationship) {
            if (canCache(relationship.type)) {
              return;
            }

            var isHasMany = relationship.kind === 'hasMany';
            var isBelongsTo = relationship.kind === 'belongsTo';
            var oldValue = originalRelations[key];

            if (isBelongsTo) {
              var relatedRecord = Ember.isPresent(oldValue) ? _this3.store.peekRecord(relationship.type, oldValue) : null;
              record.set(key, relatedRecord);
            } else if (isHasMany && oldValue) {
              var relatedRecords = oldValue.map(function (id) {
                return _this3.store.peekRecord(relationship.type, id);
              });
              record.set(key, relatedRecords);
            }
          });
        }
      });
    }
  };
  _exports.default = _default;
});