define("happyfox-bi/apps/details/aircall/account-info/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    account: null,
    isSyncSuspended: Ember.computed.equal('account.syncInfo.syncStatus', 'suspended'),
    actions: {
      reauthorize: function reauthorize(account) {
        window.location = "".concat(window.location.origin, "/integrations/aircall/initiate-oauth/?account=").concat(account.id);
      }
    }
  });

  _exports.default = _default;
});