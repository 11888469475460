define("happyfox-bi/visualizations/new/edit/controller", ["exports", "happyfox-bi/visualizations/new/create-edit-controller"], function (_exports, _createEditController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _createEditController.default.extend({
    dataService: Ember.inject.service('data'),
    initiate: function initiate() {
      var pageId = this.get('model.page');
      var reports = this.get('dataService').peekAllReports();
      var report = reports.find(function (report) {
        return report.get('pages').mapBy('id').includes(pageId);
      });
      var reportId = report.get('id');
      this.setProperties({
        pageId: pageId,
        reportId: reportId
      });

      this._super.apply(this, arguments);
    },
    actions: {
      goToOldEditVisualization: function goToOldEditVisualization() {
        // TODO: Remove this temporary routing action, once new ui is done.
        this.transitionToRoute('visualizations.edit', this.get('model.id'));
      }
    }
  });

  _exports.default = _default;
});