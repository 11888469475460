define("happyfox-bi/components/account-switcher/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    dataService: Ember.inject.service('data'),
    profileService: Ember.inject.service('profile'),
    profile: Ember.computed.reads('profileService.profile'),
    userAccounts: Ember.computed.reads('profile.userAccounts'),
    userAccountOptions: null,
    saveInProgress: false,
    currentUserAccount: null,
    selectedUserAccount: null,
    errors: null,
    init: function init() {
      this._super.apply(this, arguments);

      var profile = this.profile,
          userAccounts = this.userAccounts;
      var currentUserAccount = userAccounts.findBy('id', profile.id);
      var userAccountOptions = userAccounts.rejectBy('id', currentUserAccount.id);
      this.setProperties({
        currentUserAccount: currentUserAccount,
        userAccountOptions: userAccountOptions
      });
    },
    actions: {
      closeModal: function closeModal() {
        this.toggleAccountSwitcher();
      },
      onUserAccountChange: function onUserAccountChange(userAccount) {
        this.setProperties({
          'selectedUserAccount': userAccount,
          'errors': null
        });
      },
      save: function save() {
        var _this = this;

        this.set('saveInProgress', true);
        var payload = {
          user_account: this.selectedUserAccount.id
        };
        this.dataService.switchAccount(payload).then(function () {
          window.location.href = '/reports';
        }).catch(function (_ref) {
          var errors = _ref.errors;

          if (errors && errors.userAccount) {
            _this.set('errors', errors.userAccount);
          }
        }).finally(function () {
          _this.set('saveInProgress', false);
        });
      }
    }
  });

  _exports.default = _default;
});