define("happyfox-bi/components/single-file-upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R3qq29Ie",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[15,0,[30,[36,1],[[35,0],\"u-hide\"],null]],[12],[10,\"input\"],[15,\"accept\",[34,2]],[14,4,\"file\"],[12],[13],[13],[2,\"\\n\"],[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hideInput\",\"if\",\"acceptedFormats\"]}",
    "meta": {
      "moduleName": "happyfox-bi/components/single-file-upload/template.hbs"
    }
  });

  _exports.default = _default;
});