define("happyfox-bi/utilities/number", ["exports", "happyfox-bi/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getCurrencyValue = getCurrencyValue;

  function getCurrencyValue(value, unit, locale) {
    if (!isNaN(value)) {
      var options = {
        minimumFractionDigits: 0
      };

      if (unit) {
        var currencyDetails = _constants.CURRENCY_UNIT_CODE_MAP.findBy('unit', unit);

        options.style = 'currency';
        options.currency = currencyDetails.code;
      }

      var formatter = new Intl.NumberFormat(locale, options);
      var localizedValue = formatter.format(value);
      return localizedValue;
    }

    return;
  }
});