define("happyfox-bi/initializers/modify-power-select", ["exports", "ember-power-select/components/power-select"], function (_exports, _powerSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  // to autofocus the power select
  function initialize() {
    _powerSelect.default.reopen({
      tagName: 'div'
    });
  }

  var _default = {
    name: 'modify-power-select',
    initialize: initialize
  };
  _exports.default = _default;
});