define("happyfox-bi/services/error-handling", ["exports", "@ember-data/adapter/error", "happyfox-bi/utilities/request", "happyfox-bi/utilities/object"], function (_exports, _error, _request, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Service.extend({
    toastMessageService: Ember.inject.service('toast-message'),
    routerService: Ember.inject.service('router'),
    intlService: Ember.inject.service('intl'),
    goToLogin: function goToLogin() {
      this.get('routerService').transitionTo('login');
    },
    handleErrors: function handleErrors(status, payload) {
      var _this = this;

      if ((0, _request.isOffline)(status)) {
        this.get('toastMessageService').showMessage({
          type: 'failure',
          text: 'user-offline'
        });
        return new _error.default(Ember.Object.create({
          detail: 'user-offline'
        }));
      }

      if (_typeof(payload) !== 'object' || _.isEmpty(payload)) {
        // Handles 403 with no detail in payload and all internal server errors - 500 and above
        var toastMessageText = 'internal-server-error';
        payload = Ember.Object.create({
          detail: payload
        }); // handling 403 when no detail is sent as payload

        if (status === 403) {
          toastMessageText = 'no-permission-error';
          payload = Ember.Object.create({
            detail: this.get('intlService').lookup(toastMessageText)
          });
        } else if (status === 413) {
          // for 413, showing general message of 413 status code RFC
          toastMessageText = 'entity-too-large-error';
          this.get('toastMessageService').showMessage({
            type: 'failure',
            text: toastMessageText
          });
          payload = Ember.Object.create({
            detail: this.get('intlService').lookup(toastMessageText)
          });
        } else if (status >= 500) {
          // TODO: Handle each 500 series cases
          this.get('toastMessageService').showMessage({
            type: 'failure',
            text: toastMessageText
          }); // for 500, error response will be of content-type: text/html. So adding our own detail payload.

          payload = Ember.Object.create({
            detail: this.get('intlService').lookup(toastMessageText)
          });
        }

        var _adapterError = new _error.default(Ember.Object.create(payload));

        _adapterError.status = status;
        return _adapterError;
      }

      payload = (0, _object.camelizeObject)(payload);
      var adapterError = new _error.default(Ember.Object.create(payload));
      adapterError.status = status;

      if ((0, _request.isUnauthorized)(status)) {
        Ember.run(function () {
          if (_this.get('routerService').currentRouteName !== 'login') {
            _this.get('routerService').transitionTo('login');
          }
        });
        return adapterError;
      }

      if (payload.detail) {
        // Handles 403, 404
        // TODO: Handle 403, 404
        if (status !== 404 && status !== 403) {
          this.get('toastMessageService').showMessage({
            type: 'failure',
            text: payload.detail
          });
        }

        return adapterError;
      }

      if ((0, _request.isInvalid)(status) || status === 403) {
        // This should ideally be InvalidError but InvalidError is expecting a valid json api payload
        return adapterError;
      }
    }
  });

  _exports.default = _default;
});