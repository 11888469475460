define("happyfox-bi/business-hours/components/add/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.WORK_DAY_DEFAULT_VALUES = void 0;
  var WORK_DAY_DEFAULT_VALUES = [{
    'active': true,
    'start': '09:00',
    'end': '17:00',
    'weekday': 0
  }, {
    'active': true,
    'start': '09:00',
    'end': '17:00',
    'weekday': 1
  }, {
    'active': true,
    'start': '09:00',
    'end': '17:00',
    'weekday': 2
  }, {
    'active': true,
    'start': '09:00',
    'end': '17:00',
    'weekday': 3
  }, {
    'active': true,
    'start': '09:00',
    'end': '17:00',
    'weekday': 4
  }, {
    'active': false,
    'start': '09:00',
    'end': '17:00',
    'weekday': 5
  }, {
    'active': false,
    'start': '09:00',
    'end': '17:00',
    'weekday': 6
  }];
  _exports.WORK_DAY_DEFAULT_VALUES = WORK_DAY_DEFAULT_VALUES;
});