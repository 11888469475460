define("happyfox-bi/components/render-html/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    html: '',
    domPurifyConfig: function () {
      return {};
    }(),
    didInsertElement: function didInsertElement() {
      this.$('a').attr('target', '_blank');
    }
  });

  _exports.default = _default;
});