define("happyfox-bi/components/form-field-input/choice/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Yitb4ZWY",
    "block": "{\"symbols\":[\"choice\",\"&default\"],\"statements\":[[6,[37,14],null,[[\"tagName\",\"ariaLabel\",\"options\",\"selected\",\"class\",\"placeholder\",\"onChange\",\"onClose\",\"onOpen\",\"renderInPlace\",\"disabled\",\"searchEnabled\",\"searchField\",\"allowClear\",\"data-test-id\",\"dropdownClass\",\"initiallyOpened\",\"selectedItemComponent\",\"noMatchesMessage\"],[\"div\",[35,13],[35,0,[\"choices\"]],[35,0,[\"value\"]],[30,[36,12],[[35,11],[35,10],[30,[36,2],[[35,9],\"is-error\"],null],[30,[36,2],[[35,0,[\"isHighlighted\"]],\"hf-is-highlighted\"],null],[30,[36,2],[[35,0,[\"isDisabled\"]],\"is-disabled\"],null]],null],[35,0,[\"placeholder\"]],[30,[36,8],[[32,0],\"valueChanged\"],null],[30,[36,8],[[32,0],\"onClose\"],null],[30,[36,8],[[32,0],\"onOpen\"],null],true,[35,0,[\"isDisabled\"]],true,[35,0,[\"choiceLabelKey\"]],[35,7],[35,6],[35,5],[35,0,[\"autoFocus\"]],[30,[36,2],[[35,0,[\"selectedItemComponent\"]],[35,0,[\"selectedItemComponent\"]],null],null],[30,[36,2],[[35,3],[30,[36,4],[[35,3]],null]],null]]],[[\"default\"],[{\"statements\":[[6,[37,2],[[27,[32,2]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,2,[[32,1]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,0,[\"choiceLabelKey\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],[[32,1],[35,0,[\"choiceLabelKey\"]]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[32,1]],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"],[19,\"components/form-field-input/partials/error-message\",[]]],\"hasEval\":true,\"upvars\":[\"field\",\"get\",\"if\",\"noMatchesMessage\",\"t\",\"dropdownClass\",\"fieldDataTestId\",\"allowClear\",\"action\",\"hasErrors\",\"jsTestClass\",\"selectClass\",\"concat-strings\",\"ariaLabel\",\"power-select\"]}",
    "meta": {
      "moduleName": "happyfox-bi/components/form-field-input/choice/template.hbs"
    }
  });

  _exports.default = _default;
});