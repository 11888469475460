define("happyfox-bi/visualizations/new/components/benchmark-panel/benchmark-item/component", ["exports", "happyfox-bi/visualizations/new/components/benchmark-panel/benchmark-item/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['benchmark-item', 'selected-item-container'],
    classNameBindings: ['isAdd:mod-add-benchmark-item'],
    tagName: 'section',
    isAdd: false,
    displayType: null,
    benchmark: null,
    benchmarkAggregationFields: null,
    selectedBenchmark: null,
    benchmarkOptions: _constants.BENCHMARK_TYPES,
    benchmarkValueOptions: _constants.BENCHMARK_VALUE_TYPES,
    yAxisOptions: _constants.Y_AXIS_TYPES,
    colorModeOptions: null,
    canShowYAxisTypes: Ember.computed.equal('displayType.id', 'multi_axis_chart'),
    // Shows field options based on axis
    filteredBenchmarkAggregationFields: Ember.computed('benchmarkAggregationFields', 'canShowYAxisTypes', 'selectedBenchmark.yAxisOption.id', {
      get: function get() {
        var canShowYAxisTypes = this.canShowYAxisTypes,
            benchmarkAggregationFields = this.benchmarkAggregationFields,
            selectedBenchmark = this.selectedBenchmark;

        if (canShowYAxisTypes && selectedBenchmark && selectedBenchmark.yAxisOption) {
          return benchmarkAggregationFields.filterBy('yAxis', selectedBenchmark.yAxisOption.id);
        }

        return benchmarkAggregationFields || Ember.A();
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.initializeBenchmark();
    },
    initializeBenchmark: function initializeBenchmark() {
      var benchmark = this.benchmark;

      if (benchmark) {
        this.set('selectedBenchmark', Ember.Object.create(benchmark));
      } else {
        this.set('selectedBenchmark', Ember.Object.create());
      }

      this.setDefaultBenchmarkItem();
    },
    canShowFieldDropdown: Ember.computed('canShowYAxisTypes', 'filteredBenchmarkAggregationFields.[]', {
      get: function get() {
        return Ember.isPresent(this.filteredBenchmarkAggregationFields) && (this.canShowYAxisTypes || this.filteredBenchmarkAggregationFields.length > 1);
      }
    }),
    benchmarkPreviewColor: Ember.computed('benchmark.color', {
      get: function get() {
        var benchmark = this.benchmark;
        return benchmark ? "background-color: ".concat(benchmark.color, ";") : 'none';
      }
    }),
    isSelectedBenchmarkRange: Ember.computed.equal('selectedBenchmark.type.id', 'range'),
    isSelectedBenchmarkLine: Ember.computed.equal('selectedBenchmark.type.id', 'line'),
    canShowValue: Ember.computed('selectedBenchmark.valueType.id', {
      get: function get() {
        var selectedBenchmark = this.selectedBenchmark;
        return selectedBenchmark.valueType && selectedBenchmark.valueType.id !== 'average';
      }
    }),
    isCustomColorMode: Ember.computed.equal('selectedBenchmark.colorMode.id', 'custom'),
    hasRequiredInformation: Ember.computed('selectedBenchmark.{label,field.name,type.id,valueType.id,value,from}', {
      get: function get() {
        var selectedBenchmark = this.selectedBenchmark;
        var isFieldSelected = selectedBenchmark.field;

        if (isFieldSelected && selectedBenchmark.label) {
          var selectedType = selectedBenchmark.type;
          var selectedValueType = selectedBenchmark.valueType;
          var selectedValue = selectedBenchmark.value;

          if (selectedType && selectedType.id == 'range') {
            return Ember.isPresent(selectedBenchmark.from);
          } else if (Ember.isPresent(selectedValueType)) {
            return selectedValueType.id == 'average' || Ember.isPresent(selectedValue);
          }
        }

        return;
      }
    }),
    isDirty: Ember.computed('selectedBenchmark.{label,field.name,type.id,valueType.id,value,from,to,colorMode.color}', 'benchmark', {
      get: function get() {
        var expectedBenchmark = this.getExpectedBenchmarkItem();
        return !_.isEqual(expectedBenchmark, this.benchmark);
      }
    }),
    getDefaultField: function getDefaultField() {
      var aggregationFields = this.benchmarkAggregationFields;
      var defaultField = null;

      if (Ember.isPresent(aggregationFields)) {
        if (!this.canShowYAxisTypes) {
          defaultField = aggregationFields.length < 2 ? aggregationFields[0] : null;
        }
      }

      return defaultField;
    },
    getColorModeOptions: function getColorModeOptions() {
      var benchmark = this.benchmark;
      return Ember.A([Ember.Object.create({
        id: 'critical',
        name: 'Critical',
        color: '#ed4134'
      }), Ember.Object.create({
        id: 'warning',
        name: 'Warning',
        color: '#fbd74a'
      }), Ember.Object.create({
        id: 'ok',
        name: 'Ok',
        color: '#50af50'
      }), Ember.Object.create({
        id: 'custom',
        name: 'Custom',
        color: benchmark && benchmark.colorMode == 'custom' ? benchmark.color : '#ff9900'
      })]);
    },
    getExpectedBenchmarkItem: function getExpectedBenchmarkItem() {
      var _this$selectedBenchma = this.selectedBenchmark,
          label = _this$selectedBenchma.label,
          type = _this$selectedBenchma.type,
          valueType = _this$selectedBenchma.valueType,
          value = _this$selectedBenchma.value,
          colorMode = _this$selectedBenchma.colorMode,
          from = _this$selectedBenchma.from,
          to = _this$selectedBenchma.to,
          yAxisOption = _this$selectedBenchma.yAxisOption,
          field = _this$selectedBenchma.field;
      return Ember.Object.create({
        label: label ? label : null,
        type: type ? type.id : null,
        valueType: valueType ? valueType.id : null,
        value: value ? Number(value) : null,
        from: Ember.isPresent(from) ? Number(from) : null,
        to: Ember.isPresent(to) ? Number(to) : null,
        color: colorMode.color,
        colorMode: colorMode.id,
        yAxis: yAxisOption ? yAxisOption.id : null,
        field: field ? Ember.Object.create({
          name: field.name,
          displayName: field.displayName
        }) : null
      });
    },
    resetValues: function resetValues() {
      this.selectedBenchmark.setProperties({
        'from': null,
        'to': null,
        'value': null
      });
    },
    setDefaultBenchmarkItem: function setDefaultBenchmarkItem() {
      var benchmark = this.benchmark,
          selectedBenchmark = this.selectedBenchmark;
      this.set('colorModeOptions', this.getColorModeOptions());

      if (this.isAdd) {
        var defaultColorMode = this.colorModeOptions.findBy('id', 'critical');
        var defaultYAxisOption = this.yAxisOptions.findBy('id', 'left');
        this.selectedBenchmark.setProperties({
          label: null,
          type: null,
          valueType: null,
          value: null,
          from: null,
          to: null,
          colorMode: defaultColorMode,
          yAxisOption: defaultYAxisOption,
          field: null
        });
      } else {
        var label = benchmark.label,
            type = benchmark.type,
            valueType = benchmark.valueType,
            value = benchmark.value,
            from = benchmark.from,
            to = benchmark.to,
            colorMode = benchmark.colorMode,
            yAxis = benchmark.yAxis,
            field = benchmark.field;
        var selectedType = this.benchmarkOptions.findBy('id', type);
        var selectedValueType = this.benchmarkValueOptions.findBy('id', valueType);
        var selectedColorMode = this.colorModeOptions.findBy('id', colorMode);
        var selectedYAxisOption = this.yAxisOptions.findBy('id', yAxis);
        selectedBenchmark.setProperties({
          label: label,
          type: selectedType,
          valueType: selectedValueType,
          colorMode: selectedColorMode,
          value: value,
          from: from,
          to: to,
          yAxisOption: selectedYAxisOption,
          field: field
        });
      }
    },
    actions: {
      onOpenPopOver: function onOpenPopOver() {
        // As agg fields list is bound to change, we set initial benchmark field on popover open.  
        var benchmark = this.benchmark,
            benchmarkAggregationFields = this.benchmarkAggregationFields;
        var benchmarkField = benchmark ? benchmark.field : '';

        if (!benchmarkField) {
          this.set('selectedBenchmark.field', Ember.isPresent(benchmarkAggregationFields) ? this.getDefaultField() : null);
        } else {
          this.set('selectedBenchmark.field', benchmarkField);
        }
      },
      onAxisTypeChange: function onAxisTypeChange(axis) {
        this.selectedBenchmark.set('yAxisOption', axis);
        this.send('onBenchmarkFieldChange', null);
      },
      onBenchmarkNameChange: function onBenchmarkNameChange(event) {
        this.selectedBenchmark.set('label', event.target.value);
      },
      onBenchmarkFieldChange: function onBenchmarkFieldChange(field) {
        this.selectedBenchmark.set('field', field);

        if (!field) {
          this.resetValues();
        }
      },
      onBenchmarkTypeChange: function onBenchmarkTypeChange(type) {
        this.selectedBenchmark.setProperties({
          type: type,
          valueType: null
        });
        this.resetValues();
      },
      onValueTypeChange: function onValueTypeChange(type) {
        this.selectedBenchmark.set('valueType', type);
        this.resetValues();
      },
      selectedRangeFromChange: function selectedRangeFromChange(event) {
        this.selectedBenchmark.set('from', event.target.value);
      },
      selectedRangeToChange: function selectedRangeToChange(event) {
        this.selectedBenchmark.set('to', event.target.value);
      },
      selectedValueChange: function selectedValueChange(event) {
        this.selectedBenchmark.set('value', event.target.value);
      },
      onColorModeChange: function onColorModeChange(mode) {
        this.selectedBenchmark.set('colorMode', mode);
      },
      onCustomColorChange: function onCustomColorChange(color) {
        this.selectedBenchmark.set('colorMode.color', color);
      },
      onClosePopOver: function onClosePopOver() {
        this.setDefaultBenchmarkItem();
      },
      add: function add(popOver) {
        var benchmarkItem = this.getExpectedBenchmarkItem();
        this.onAddBenchmarkItem(benchmarkItem);

        if (!this.isDestroyed) {
          popOver.closePopOver();
        }
      },
      update: function update(popOver) {
        var editedBenchmarkItem = this.getExpectedBenchmarkItem();
        this.onUpdateBenchmarkItem(editedBenchmarkItem);

        if (!this.isDestroyed) {
          popOver.closePopOver();
        }
      },
      removeBenchmark: function removeBenchmark() {
        var benchmark = this.benchmark;
        this.onRemoveBenchmarkItem(benchmark);
      },
      cancel: function cancel(popOver) {
        popOver.closePopOver();
      }
    }
  });

  _exports.default = _default;
});