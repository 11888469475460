define("happyfox-bi/reports/details/pages/details/components/sample-visualizations-pane/component", ["exports", "happyfox-bi/mixins/data-set", "happyfox-bi/reports/details/pages/details/utilities"], function (_exports, _dataSet, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dataSet.default, {
    dataService: Ember.inject.service('data'),
    featuresService: Ember.inject.service('features'),
    allDataSets: null,
    isLoadingDataSets: false,
    selectedDataSet: null,
    isLoadingSampleVisualizations: false,
    sampleVisualizations: null,
    offset: 0,
    createFromSampleVisualizationDisabled: false,
    canShowVisualizationsLimitMessage: Ember.computed('createFromSampleVisualizationDisabled', 'sampleVisualizations.[]', {
      get: function get() {
        var createFromSampleVisualizationDisabled = this.createFromSampleVisualizationDisabled,
            sampleVisualizations = this.sampleVisualizations;
        return Ember.isPresent(sampleVisualizations) && createFromSampleVisualizationDisabled;
      }
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('isLoadingDataSets', true);
      return this.get('dataService').getAllDataSources(true).then(function () {
        return _this.getAllDatasets().then(function (dataSets) {
          _this.set('allDataSets', dataSets);
        });
      }).finally(function () {
        _this.set('isLoadingDataSets', false);
      });
    },
    sampleVisualizationsPositions: Ember.computed('sampleVisualizations.@each.id', {
      get: function get() {
        var sampleVisualizations = this.get('sampleVisualizations');
        var sampleVisualizationsPositions = Ember.Object.create();
        sampleVisualizations.forEach(function (visualization) {
          sampleVisualizationsPositions[visualization.id] = Ember.Object.create({
            visualizationId: visualization.id,
            temporaryId: (0, _utilities.getTemporaryId)(visualization.id),
            width: visualization.metaData.width,
            height: visualization.metaData.height
          });
        });
        return sampleVisualizationsPositions;
      }
    }),
    fetchSampleVisualizations: function fetchSampleVisualizations(dataSet) {
      var _this2 = this;

      var params = {
        dataset: dataSet.id,
        offset: this.offset
      };
      this.set('isLoadingSampleVisualizations', true);
      return this.get('dataService').getSampleVisualizations(params).then(function (visualizations) {
        _this2.set('sampleVisualizations', visualizations);
      }).finally(function () {
        _this2.set('isLoadingSampleVisualizations', false);
      });
    },
    actions: {
      closeSampleVisualizationsPane: function closeSampleVisualizationsPane() {
        this.onCloseSampleVisualizationsPane();
      },
      onReloadTemplates: function onReloadTemplates() {
        if (this.get('selectedDataSet')) {
          return this.fetchSampleVisualizations(this.get('selectedDataSet'));
        }
      },
      onDataSetChange: function onDataSetChange(dataSet) {
        var _this3 = this;

        this.set('selectedDataSet', dataSet);
        return this.fetchSampleVisualizations(this.get('selectedDataSet')).then(function () {
          _this3.onSampleVisualizationsDataSetChange(dataSet);
        });
      },
      updateSampleVisualization: function updateSampleVisualization(visualization) {
        var sampleVisualizations = this.get('sampleVisualizations');
        var visualizationToUpdate = sampleVisualizations.findBy('id', visualization.id);
        Object.keys(visualizationToUpdate).forEach(function (key) {
          visualizationToUpdate.set(key, visualization[key]);
        });
      },
      removeSampleVisualization: function removeSampleVisualization(id) {
        var sampleVisualizations = this.get('sampleVisualizations');
        var visualizationToRemove = sampleVisualizations.findBy('id', id);
        sampleVisualizations.removeObject(visualizationToRemove);
      }
    }
  });

  _exports.default = _default;
});