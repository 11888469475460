define("happyfox-bi/visualization-examples/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    featuresService: Ember.inject.service('features'),
    beforeModel: function beforeModel() {
      if (!this.get('featuresService.visualizationExamples')) {
        this.transitionTo('reports');
      }
    }
  });

  _exports.default = _default;
});