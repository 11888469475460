define("happyfox-bi/services/local-storage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    getItem: function getItem(key) {
      return localStorage.getItem(key);
    },
    setItem: function setItem(key, value) {
      try {
        return localStorage.setItem(key, value);
      } catch (err) {
        Ember.Logger.error(err);
      }
    },
    clear: function clear() {
      localStorage.clear();
    }
  });

  _exports.default = _default;
});