define("happyfox-bi/components/add-visualization-field/combine-values-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    dataService: Ember.inject.service('data'),
    classNames: ['combine-values_item'],
    classNameBindings: ['isDefault:mod-default', 'isSelected:is-selected'],
    datasetField: null,
    selectedField: null,
    combineValue: null,
    selectedDatasetField: null,
    canShowCombineValues: true,
    canAddUserTextAsValue: true,
    isDefault: Ember.computed.equal('combineValue.name', 'default'),
    init: function init() {
      this._super.apply(this, arguments);

      this.setValueFieldDetails();
    },
    setValueFieldDetails: function setValueFieldDetails() {
      var valueFieldDetails = Ember.Object.create({
        areOptionsLoading: false,
        useAutoComplete: false,
        options: null
      });
      this.set('valueFieldDetails', valueFieldDetails);
    },
    canShowAdd: Ember.computed('combineValue', 'combineValuesList.[]', {
      get: function get() {
        var hasDefaultValue = this.get('combineValuesList') && this.combineValuesList.findBy('name', 'default');
        var index = hasDefaultValue ? 2 : 1;
        return this.combineValuesList.length == 1 && this.combineValuesList.indexOf(this.combineValue) == 0 || this.combineValuesList.indexOf(this.combineValue) == this.combineValuesList.length - index;
      }
    }),
    canShowDelete: Ember.computed('combineValuesList.[]', {
      get: function get() {
        var hasDefaultValue = this.get('combineValuesList') && this.combineValuesList.findBy('name', 'default');
        var index = hasDefaultValue ? 2 : 1;
        return this.combineValuesList.length > index;
      }
    }),
    actions: {
      onClick: function onClick(field) {
        if (this.get('isDatasetTypeField')) {
          this.onClick(field, this.get('datasetField'));
        } else {
          this.onClick(field);
        }
      },
      searchAutocomplete: function searchAutocomplete(term) {
        var fieldId = this.get('selectedField.id') || this.get('selectedField.datasetField');
        return this.get('dataService').newGetFieldOptions(fieldId, term).then(function (response) {
          return response.results;
        });
      },
      onDelete: function onDelete() {
        this.onDelete(this.combineValue);
      },
      onAdd: function onAdd() {
        this.onAdd();
      }
    }
  });

  _exports.default = _default;
});