define("happyfox-bi/business-hours/components/table-cell/work-days/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    workDays: Ember.computed('row.content.workDays.[]', {
      get: function get() {
        return this.row.content.workDays;
      }
    })
  });

  _exports.default = _default;
});