define("happyfox-bi/form-field-models/choice-field", ["exports", "happyfox-bi/form-field-models/base-field", "happyfox-bi/constants"], function (_exports, _baseField, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _baseField.default.extend({
    // default is 'name'
    choiceLabelKey: 'name',
    choiceValueKey: null,
    isChoice: true,
    choices: null,
    hasGroupedChoices: false,
    canSetDefaultChoiceValue: true,
    canIncludeEmptyChoice: false,
    makeEmptyChoiceAsDefault: false,
    init: function init() {
      var _this = this;

      var selectedChoice = null;
      var fieldValue = this.get('value');
      var choices = this.get('choices');

      if (this.get('canIncludeEmptyChoice')) {
        var _Ember$Object$create;

        choices = choices.toArray();
        var choiceLabelKey = this.get('choiceLabelKey');
        var choiceValueKey = this.get('choiceValueKey');
        var emptyOptionField = Ember.Object.create((_Ember$Object$create = {}, _defineProperty(_Ember$Object$create, choiceLabelKey, _constants.EMPTY_DROPDOWN_VALUE), _defineProperty(_Ember$Object$create, choiceValueKey, null), _Ember$Object$create));

        if (this.get('makeEmptyChoiceAsDefault')) {
          selectedChoice = emptyOptionField;
        }

        choices.unshiftObject(emptyOptionField);
        this.set('choices', choices);
      }

      if (fieldValue) {
        if (this.get('hasGroupedChoices')) {
          var ungroupedChoices = choices.rejectBy('groupName');
          var choice = this.findChoice(ungroupedChoices, fieldValue);

          if (!choice) {
            var choiceGroups = choices.filterBy('groupName');
            choiceGroups.forEach(function (group) {
              var matchedChoice = _this.findChoice(group.options, fieldValue);

              if (matchedChoice) {
                choice = matchedChoice;
              }
            });
          }

          selectedChoice = choice;
        } else {
          selectedChoice = this.findChoice(choices, fieldValue);
        }
      }

      this.setProperties({
        _initialValue: selectedChoice,
        value: selectedChoice
      });
    },
    findChoice: function findChoice(choices, choiceOption) {
      var choice = null;
      choices.forEach(function (item) {
        if (_.isEqual(item, choiceOption)) {
          choice = item;
        }
      });
      return choice;
    },
    reset: function reset() {
      this.set('errors', Ember.A());
      var initialValue = this.get('_initialValue');
      this.set('value', initialValue);

      if (Ember.isBlank(initialValue) && this.get('isMandatory')) {
        this.setDefaultChoiceValue();
      }
    },
    setDefaultChoiceValue: function setDefaultChoiceValue() {
      if (this.get('canSetDefaultChoiceValue')) {
        var _this$get = this.get('choices'),
            _this$get2 = _slicedToArray(_this$get, 1),
            defaultChoice = _this$get2[0];

        defaultChoice = this.get('choices').findBy('isDefault') || defaultChoice;
        this.set('value', defaultChoice);
      }
    },
    validate: function validate() {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this2._super().then(function () {
          var value = _this2.get('value');

          if (value) {
            var choiceValueKey = _this2.get('choiceValueKey');

            _this2.set('_cleanValue', choiceValueKey ? value[choiceValueKey] : value);
          } else {
            _this2.set('_cleanValue', null);
          }

          resolve();
        }).catch(function () {
          reject();
        });
      });
    }
  });

  _exports.default = _default;
});