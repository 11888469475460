define("happyfox-bi/components/form-field-input/base", ["exports", "happyfox-bi/mixins/validation", "happyfox-bi/components/form-field-input/mixins/autofocus"], function (_exports, _validation, _autofocus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_validation.default, _autofocus.default, {
    field: null,
    inputClass: '',
    delayedErrors: null,
    fieldDataTestId: null,
    errorDataTestId: Ember.computed('fieldDataTestId', {
      get: function get() {
        return "".concat(this.get('fieldDataTestId'), "-error");
      }
    }),
    hasErrors: Ember.computed('field.errors.[]', {
      get: function get() {
        return Ember.isPresent(this.get('field.errors'));
      }
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.get('field.autoFocus')) {
        this.autoFocus();
      }
    },
    delayErrors: Ember.observer('field.errors.[]', function () {
      Ember.run.later(this, function () {
        if (!this.isDestroyed) {
          this.set('delayedErrors', this.get('field.errors'));
        }
      }, 100);
    }).on('init')
  });

  _exports.default = _default;
});