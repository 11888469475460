define("happyfox-bi/manage/users/user-status-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kB2XmWHE",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[32,0,[\"userModel\",\"isActive\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,0],[\"active\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,0],[\"deactivated\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "meta": {
      "moduleName": "happyfox-bi/manage/users/user-status-cell/template.hbs"
    }
  });

  _exports.default = _default;
});