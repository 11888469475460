define("happyfox-bi/components/form/non-field-errors/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Zl2zgYbk",
    "block": "{\"symbols\":[\"error\"],\"statements\":[[6,[37,4],[[30,[36,3],[[35,0]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"ul\"],[14,0,\"form-content_non-field-errors js-form-content_non-field-errors\"],[14,\"data-test-id\",\"non-field-errors\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"li\"],[12],[2,\" \"],[1,[32,1]],[2,\" \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"errors\",\"-track-array\",\"each\",\"is-present\",\"if\"]}",
    "meta": {
      "moduleName": "happyfox-bi/components/form/non-field-errors/template.hbs"
    }
  });

  _exports.default = _default;
});