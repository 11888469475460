define("happyfox-bi/data-sources/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    dataService: Ember.inject.service('data'),
    featuresService: Ember.inject.service('features'),
    applicationService: Ember.inject.service('application'),
    beforeModel: function beforeModel() {
      if (!this.get('featuresService.manageDataSources')) {
        this.transitionTo('reports');
      }
    },
    model: function model() {
      return this.get('dataService').getAllDataSources(true);
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      this.get('applicationService').closeSidePane();
      controller.set('dataSources', model);
    }
  });

  _exports.default = _default;
});