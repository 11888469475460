define("happyfox-bi/components/auto-complete/remote/multiple/component", ["exports", "happyfox-workflows/mixins/register-waiter", "happyfox-workflows/utilities/monitor-async-action", "happyfox-workflows/utilities/object", "happyfox-bi/components/auto-complete/remote/multiple/template"], function (_exports, _registerWaiter, _monitorAsyncAction, _object, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AUTOCOMPLETE_MIN_CHARACTER_LENGTH = 2;

  var _default = Ember.Component.extend(_registerWaiter.default, {
    layout: _template.default,
    dataService: Ember.inject.service('data'),
    isInputDisabled: false,
    selected: null,
    url: null,
    searchParam: null,
    searchField: null,
    placeholder: null,
    optionValuePath: null,
    optionLabelPath: null,
    autofocus: false,
    hasErrors: false,
    isAddNew: true,
    characterLimit: null,
    noMatchesMessage: '',
    itemsToBeIgnored: null,
    isAddSearchHelpOption: false,
    searchText: null,
    itemsToBeExcluded: Ember.computed('selected.[]', {
      get: function get() {
        var selectedItems = Ember.isPresent(this.get('selected')) ? this.get('selected') : Ember.A();
        var itemsToBeIgnored = Ember.isPresent(this.get('itemsToBeIgnored')) ? this.get('itemsToBeIgnored') : Ember.A();
        return selectedItems.mapBy('id').addObjects(itemsToBeIgnored).map(function (item) {
          return Number(item);
        });
      }
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      if (Ember.isBlank(this.get('selected'))) {
        this.set('selected', Ember.A());
      }

      if (this.get('isInputDisabled')) {
        this.$('.ember-power-select-trigger-multiple-input').prop('disabled', true);
        this.$().addClass('remove-only');
      } else if (this.get('autofocus')) {
        Ember.run.next(function () {
          _this.$('input').focus();
        });
      }
    },
    createCustomChoice: function createCustomChoice(select) {
      var _this2 = this;

      if (Ember.isPresent(select.searchText)) {
        var searchTextArray = select.searchText.split(',');
        searchTextArray = searchTextArray.filter(function (searchText) {
          return !Ember.isEmpty(searchText.trim());
        });
        searchTextArray.forEach(function (searchText, index) {
          var selectedArray = _this2.get('selected');

          if (!selectedArray.includes(searchText)) {
            if (index < searchTextArray.length - 1) {
              _this2.get('selected').pushObject(searchText);
            } else {
              // clearing the power-select input value which doesn't happen by default if we don't use select.actions.choose to set selected
              select.actions.choose(searchText);
            }
          } else {
            Ember.run.later(function () {
              _this2.$('input').val('');
            }, 100);
          }
        });
      }
    },
    actions: {
      search: function search(term) {
        var _this3 = this;

        var intlService = this.get('intlService');
        var noMatchesMessage;

        if (term.length >= AUTOCOMPLETE_MIN_CHARACTER_LENGTH) {
          this.set('isLoading', true); // wait for min two character to be typed by the user before sending request

          var url = this.get('url');
          var searchParam = this.get('searchParam');

          if (this.get('isAddNew')) {
            noMatchesMessage = 'no-matches-message';
          } else {
            noMatchesMessage = 'no-results-found';
          }

          this.set('noMatchesMessage', noMatchesMessage);
          var promise = new Ember.RSVP.Promise(function (resolve, reject) {
            Ember.run.debounce(_this3, _this3._performSearch, term, resolve, reject, url, searchParam, 600);
          });

          if (Ember.testing) {
            (0, _monitorAsyncAction.default)(this, promise);
          }

          return promise;
        } else {
          noMatchesMessage = intlService.lookup('start-search-help-text');
          this.set('noMatchesMessage', noMatchesMessage);
        }
      },
      onChange: function onChange(result, select) {
        if (result.get('lastObject.isSearchHelpOption') || this.get('isLoading')) {
          result.popObject();
          result.addObject(select.searchText);
        }

        if (this.get('onChange')) {
          this.get('onChange')(result);
        }

        if (this.get('transformResult')) {
          result = this.get('transformResult')(result);
        }

        this.set('selected', result);
        select.actions.search();
        this.$('input').val('');
      },
      onKeyDown: function onKeyDown(select, e) {
        if (this.get('isAddNew') && (e.keyCode === 13 || e.keyCode === 188) && select.isOpen && !select.highlighted && !Ember.isBlank(select.searchText)) {
          this.createCustomChoice(select);
        } else if (e.keyCode === 188 && !select.isOpen || e.keyCode === 188 && !Ember.isBlank(select.searchText.split(',')) || Ember.isPresent(this.get('characterLimit')) && select.searchText.length >= this.get('characterLimit')) {
          e.preventDefault();
        }
      },
      onClose: function onClose(select) {
        if (this.get('isAddNew') && !select.highlighted) {
          // Allowing creation of custom choices only when isAddNew is true and there are no suggestions for the typed text
          this.createCustomChoice(select);
        }
      }
    },
    _performSearch: function _performSearch(term, resolve, reject, url, searchParam) {
      var _this4 = this;

      if (Ember.isBlank(term)) {
        return resolve([]);
      }

      var optionValuePath = this.get('optionValuePath');
      this.get('dataService').autoCompleteSearch(searchParam, term, url).then(function (results) {
        if (_this4.get('isAddSearchHelpOption')) {
          if (Ember.isPresent(results) && results.get('firstObject') !== term) {
            results.unshiftObject(Ember.Object.create({
              isSearchHelpOption: true
            }));

            _this4.set('searchText', term);
          }
        }

        if (Ember.isPresent(_this4.get('itemsToBeExcluded'))) {
          results = results.reject(function (result) {
            var itemsToBeExcluded = _this4.get('itemsToBeExcluded');

            return itemsToBeExcluded.includes(result.id);
          });
        }

        if (!Ember.isBlank(optionValuePath)) {
          results = results.map(_object.camelizeObject).mapBy(optionValuePath).reject(function (result) {
            return result === null;
          });
          resolve(results);
        } else {
          resolve(results.map(_object.camelizeObject));
        }

        _this4.set('isLoading', false);
      }, reject);
    }
  });

  _exports.default = _default;
});