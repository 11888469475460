define("happyfox-bi/data-sources/components/table-cell/data-type-format-action/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4WK6nZYv",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[11,\"a\"],[24,\"role\",\"button\"],[4,[38,0],[[32,0],\"onFormatClick\"],null],[12],[2,\"\\n    \"],[1,[34,4]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[11,\"a\"],[24,\"role\",\"button\"],[4,[38,0],[[32,0],\"onFormatClick\"],null],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"choose-format\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  -\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"canShowFormatLink\",\"if\",\"selectedFormat\"]}",
    "meta": {
      "moduleName": "happyfox-bi/data-sources/components/table-cell/data-type-format-action/template.hbs"
    }
  });

  _exports.default = _default;
});