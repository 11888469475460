define("happyfox-bi/apps/details/partials/wrike/setup-info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5T0vZyEm",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"app-description.wrike.basic-info\"],null]],[2,\"\\n\"],[10,\"div\"],[14,0,\"apps-details_right_sub-heading\"],[12],[1,[30,[36,0],[\"app-description.wrike.more-info-heading\"],null]],[13],[2,\"\\n\"],[10,\"ul\"],[14,0,\"app-description_how-to-setup\"],[12],[2,\"\\n  \"],[10,\"li\"],[14,0,\"app-description_how-to-setup_text\"],[12],[1,[30,[36,0],[\"app-description.wrike.more-info.one\"],null]],[13],[2,\"\\n  \"],[10,\"li\"],[14,0,\"app-description_how-to-setup_text\"],[12],[1,[30,[36,0],[\"app-description.wrike.more-info.two\"],null]],[13],[2,\"\\n  \"],[10,\"li\"],[14,0,\"app-description_how-to-setup_text\"],[12],[1,[30,[36,0],[\"app-description.wrike.more-info.three\"],null]],[13],[2,\"\\n  \"],[10,\"li\"],[14,0,\"app-description_how-to-setup_text\"],[12],[1,[30,[36,0],[\"app-description.wrike.more-info.four\"],null]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "happyfox-bi/apps/details/partials/wrike/setup-info/template.hbs"
    }
  });

  _exports.default = _default;
});