define("happyfox-bi/adapters/page", ["exports", "happyfox-bi/adapters/base", "happyfox-bi/utilities/object"], function (_exports, _base, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    getRequestData: function getRequestData(record) {
      var data = this._super(record);

      var settings = data.settings;

      if (settings) {
        settings = (0, _object.underscoreObject)(settings);
        data.settings = settings;
      }

      return data;
    }
  });

  _exports.default = _default;
});