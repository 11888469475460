define("happyfox-bi/components/map-chart/component", ["exports", "happyfox-bi/components/base-chart/component", "@amcharts/amcharts4/core", "@amcharts/amcharts4/charts", "@amcharts/amcharts4/maps", "@amcharts/amcharts4-geodata/worldLow", "@amcharts/amcharts4-geodata/region/world/worldRegionsLow", "happyfox-bi/components/map-chart/constants"], function (_exports, _component, am4core, am4charts, am4maps, _worldLow, _worldRegionsLow, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    excludedRegions: Ember.computed('config.{excludedRegions,groupIntoRegions}', {
      get: function get() {
        var excludedRegions = this.get('config.excludedRegions');
        var isGroupedIntoRegions = this.get('config.groupIntoRegions');

        if (Ember.isEmpty(excludedRegions)) {
          return ['AQ'];
        } else if (isGroupedIntoRegions) {
          return excludedRegions.map(function (region) {
            return Ember.A(_constants.REGION_ID_MAP).findBy('name', region).id;
          });
        } else {
          return excludedRegions.map(function (region) {
            return Ember.A(_constants.COUNTRY_ID_MAP).findBy('name', region).id;
          });
        }
      }
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var chartContainer = this.get('chartContainer');
      var map = am4core.create(chartContainer, am4maps.MapChart);
      var minHeatRuleColor = '#2b57bc';
      var maxHeatRuleColor = '#111930'; // Set map definition

      if (this.get('config.groupIntoRegions')) {
        map.geodata = _worldRegionsLow.default;
        minHeatRuleColor = '#55af7f';
        maxHeatRuleColor = '#2b5a43';
      } else {
        map.geodata = _worldLow.default;
      }

      var valueKey = this.get('config.value');
      var isValueTimeType = this.isFieldTimeType(valueKey);
      var isDurationValue = this.canFormatTimeAsDuration(valueKey);

      if (isDurationValue) {
        this.setupDurationFormatter(map, valueKey);
      } // Set projection


      map.projection = new am4maps.projections.Miller(); // Create map polygon series

      var polygonSeries = map.series.push(new am4maps.MapPolygonSeries()); // Make map load polygon (like country names) data from GeoJSON

      polygonSeries.useGeodata = true; // Configure series

      var polygonTemplate = polygonSeries.mapPolygons.template;

      if (isValueTimeType) {
        polygonTemplate.adapter.add('tooltipText', function (text, target) {
          var tooltipDataItem = target.tooltipDataItem;

          if (tooltipDataItem && Ember.isPresent(tooltipDataItem.value)) {
            return "{name} ".concat(_this.formatValueByTime(tooltipDataItem.value, valueKey));
          }

          return text;
        });
      } else {
        polygonTemplate.tooltipText = '{name}: {value}';
      }

      var defaultPolygonColor = this.getConfigValue('defaultPolygonColor', '#999');
      polygonTemplate.fill = am4core.color(defaultPolygonColor); // Create hover state and set alternative fill color

      var hs = polygonTemplate.states.create('hover');
      var onHoverPolygonColor = this.getConfigValue('onHoverPolygonColor', '#888');
      hs.properties.fill = am4core.color(onHoverPolygonColor); // Add heat rule

      minHeatRuleColor = this.getConfigValue('minHeatRuleColor', minHeatRuleColor);
      maxHeatRuleColor = this.getConfigValue('maxHeatRuleColor', maxHeatRuleColor);
      polygonSeries.heatRules.push({
        'property': 'fill',
        'target': polygonSeries.mapPolygons.template,
        'min': am4core.color(minHeatRuleColor),
        'max': am4core.color(maxHeatRuleColor)
      });
      var showHeatLegend = this.getConfigValue('showHeatLegend', true);

      if (showHeatLegend) {
        var heatLegend = map.chartContainer.createChild(am4maps.HeatLegend);
        heatLegend.valign = "bottom";
        heatLegend.align = "left";
        heatLegend.width = am4core.percent(100);
        heatLegend.series = polygonSeries;
        heatLegend.orientation = "horizontal";
        heatLegend.padding(20, 50, 0, 20);
        heatLegend.valueAxis.renderer.labels.template.fontSize = 10;
        heatLegend.valueAxis.renderer.minGridDistance = 40;

        if (isDurationValue) {
          heatLegend.valueAxis = new am4charts.DurationAxis();
          this.setupDurationFormatter(heatLegend.valueAxis, valueKey);
        } else if (isValueTimeType) {
          this.setUnitsInAxisLabels(heatLegend.valueAxis, this.getTimeFieldBaseUnit(valueKey));
        }
      }

      polygonSeries.exclude = this.get('excludedRegions');
      polygonSeries.data = this.getTransformedData(); // Add button

      if (!this.get('isNonInteractiveMode')) {
        var button = map.chartContainer.createChild(am4core.Button);
        button.padding(5, 5, 5, 5);
        button.align = 'right';
        button.marginRight = 5;
        button.y = 25;
        button.events.on('hit', function () {
          map.goHome();
        });
        button.icon = new am4core.Sprite();
        button.icon.path = 'M16,8 L14,8 L14,16 L10,16 L10,10 L6,10 L6,16 L2,16 L2,8 L0,8 L8,0 L16,8 Z M16,8'; // Add zoom control

        map.zoomControl = new am4maps.ZoomControl();
      }

      var isValueCurrencyType = this.isFieldCurrencyType(valueKey);

      if (isValueCurrencyType) {
        this.setElementNumberFormatAsCurrency(map, valueKey);
      }

      this._setZoomOnScrollSettings(map, chartContainer);

      this.addExportOption(map);
      this.addAnnotationsOption(map);
      this.chart = map;
    },
    // enable zoom on scroll only when the chart is in focus
    _setZoomOnScrollSettings: function _setZoomOnScrollSettings(map, chartContainer) {
      map.chartContainer.wheelable = false;
      chartContainer.addEventListener('click', function () {
        map.chartContainer.wheelable = true;
      });
      document.addEventListener('click', function (event) {
        var isInsideChart = chartContainer.contains(event.target);

        if (!isInsideChart) {
          map.chartContainer.wheelable = false;
        }
      });
    },
    getTransformedData: function getTransformedData() {
      var isGroupedIntoRegions = this.get('config.groupIntoRegions');
      var regionKey = this.get('config.region');
      var valueKey = this.get('config.value');
      var useIso = this.getConfigValue('useIso', false);
      var transformedData = Ember.A();
      this.get('data').forEach(function (datum) {
        var datumObject = {};
        var regionIdMap = isGroupedIntoRegions ? _constants.REGION_ID_MAP : _constants.COUNTRY_ID_MAP;
        var region = datum[regionKey];
        var regionId;

        if (useIso) {
          var mappedRegionById = Ember.A(regionIdMap).findBy('id', region);

          if (mappedRegionById) {
            regionId = mappedRegionById.id;
          }
        } else {
          var mappedRegionByName = Ember.A(regionIdMap).findBy('name', region);

          if (mappedRegionByName) {
            regionId = mappedRegionByName.id;
          }
        }

        if (regionId) {
          datumObject.id = regionId;
          datumObject.value = datum[valueKey];
          transformedData.addObject(datumObject);
        }
      });
      return transformedData.length ? transformedData : this.get('data');
    }
  });

  _exports.default = _default;
});