define("happyfox-bi/components/single-file-upload/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['single-file-upload'],
    hideInput: false,
    isDisabled: false,
    acceptedFormats: null,
    click: function click(event) {
      // setting up to null to clear the last selected input-file value cache.
      // Usecase Fix: File Attachment works only once - When adding an update if the attached file is removed and again trying to add an attachment.
      event.target.value = null;

      if (this.get('hideInput')) {
        // No point in having click event if the file input is visible anyway
        var input = this.$('input[type="file"]').eq(0);

        if (this.$(event.target).closest(input).length === 0) {
          // Avoid triggering file input again if that was the original target of the click
          input.click();
        }
      }
    },
    change: function change(event) {
      var attachedFiles = event.target.files;

      if (this.onChange) {
        this.onChange(attachedFiles);
      }
    }
  });

  _exports.default = _default;
});