define("happyfox-bi/mixins/resize-observer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Mixin.create({
    targetElementClass: '',
    resizeObserver: null,
    canUseResizeObserver: function canUseResizeObserver() {
      if (typeof window.ResizeObserver === 'function') {
        return true;
      }

      return;
    },
    setupResizeObserver: function setupResizeObserver(targetElementClass) {
      var _this = this;

      this.set('targetElementClass', targetElementClass); //resize observer expects native DOM element. Hence we get first element from jquery object.

      var _this$$ = this.$("".concat(targetElementClass)),
          _this$$2 = _slicedToArray(_this$$, 1),
          targetNativeElement = _this$$2[0];

      var resizeObserver = new ResizeObserver(function () {
        _this.onElementResize();
      });
      resizeObserver.observe(targetNativeElement);
      this.set('resizeObserver', resizeObserver);
    },
    onElementResize: function onElementResize() {// overide this for handling in consuming place
    },
    removeResizeObserver: function removeResizeObserver() {
      //resize observer expects native DOM element. Hence we get first element from jquery object.
      var _this$$3 = this.$("".concat(this.get('targetElementClass'))),
          _this$$4 = _slicedToArray(_this$$3, 1),
          targetNativeElement = _this$$4[0];

      this.get('resizeObserver').unobserve(targetNativeElement);
      this.set('resizeObserver', null);
    }
  });

  _exports.default = _default;
});