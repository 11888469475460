define("happyfox-bi/report-subscriptions/controller", ["exports", "happyfox-bi/mixins/table-new", "happyfox-bi/constants"], function (_exports, _tableNew, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TABLE_COLUMNS = Ember.A([{
    label: 'name',
    valuePath: 'name',
    sortable: false,
    minResizeWidth: '100px'
  }, {
    label: 'type',
    valuePath: 'subscriptionType',
    cellComponent: 'data-table-new/i18ned-cell',
    sortable: false,
    minResizeWidth: '100px'
  }, {
    label: 'active',
    valuePath: 'active',
    cellComponent: 'data-table-new/boolean-cell',
    sortable: false,
    minResizeWidth: '100px'
  }]);

  var _default = Ember.Controller.extend(_tableNew.default, {
    dataService: Ember.inject.service('data'),
    queryParams: {
      reportId: 'report'
    },
    reportId: null,
    offset: 0,
    limit: 25,
    isLoading: false,
    columns: TABLE_COLUMNS,
    reportsList: null,
    tableProperties: Ember.computed('', function () {
      return Ember.Object.create({
        isLocalSort: false,
        canPersistSort: false
      });
    }),
    pageInfo: null,
    isPaginationNeeded: Ember.computed.gt('pageInfo.count', 0),
    calculatedOffset: Ember.computed('pageInfo', 'model', 'offset', {
      get: function get() {
        var offset = this.get('offset');

        if (this.get('model.length') === 0 && offset > 0) {
          return this.get('pageInfo.previous');
        }

        return offset;
      }
    }),
    canShowSubscriptionSidePane: false,
    currentSubscription: null,
    fetchData: function fetchData(offset) {
      var _this = this;

      this.set('offset', offset);
      var params = {
        offset: offset,
        limit: this.limit
      };

      if (this.reportId) {
        params.reportId = this.reportId;
      }

      this.set('isLoading', true);
      this.dataService.getReportSubscriptions(params).then(function (response) {
        _this.set('pageInfo', response.meta);

        _this.set('model', response.results);
      }).finally(function () {
        _this.set('isLoading', false);
      });
    },
    actions: {
      changePage: function changePage(newOffset) {
        this.fetchData(newOffset);
      },
      goToCreateSubscription: function goToCreateSubscription() {
        this.set('currentSubscription', null);
        this.set('canShowSubscriptionSidePane', true);
      },
      goToDetails: function goToDetails(subscription) {
        this.set('currentSubscription', subscription);
        this.set('canShowSubscriptionSidePane', true);
      },
      closeSubscriptionPane: function closeSubscriptionPane(slideInCloseAction) {
        var _this2 = this;

        var refetchData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        slideInCloseAction();
        Ember.run.later(function () {
          _this2.setProperties({
            canShowSubscriptionSidePane: false,
            currentSubscription: null
          });

          if (refetchData) {
            _this2.fetchData(_this2.offset);
          }
        }, _constants.SLIDE_IN_DELAY_DURATION);
      }
    }
  });

  _exports.default = _default;
});