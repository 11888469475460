define("happyfox-bi/components/form-field-input/local-multi-choice-dropdown/component", ["exports", "happyfox-bi/components/form-field-input/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    selectClass: '',
    dropdownClass: '',
    jsTestClass: Ember.computed('fieldDataTestId', {
      get: function get() {
        var fieldDataTestId = this.get('fieldDataTestId');

        if (fieldDataTestId) {
          return "hf-js-test-".concat(fieldDataTestId);
        }

        return '';
      }
    }),
    actions: {
      valueChanged: function valueChanged(newSelection) {
        var oldSelection = this.get('field.value');
        this.set('field.value', newSelection);

        if (this.onChange) {
          this.onChange(newSelection, oldSelection);
        }
      },
      onClose: function onClose() {
        this.get('field').validate();
      }
    }
  });

  _exports.default = _default;
});