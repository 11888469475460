define("happyfox-bi/data-sources/index/controller", ["exports", "ember-light-table", "happyfox-bi/mixins/table"], function (_exports, _emberLightTable, _table) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_table.default, {
    initiate: function initiate() {
      var dataSources = this.get('model');
      var columns = [{
        valuePath: 'name',
        label: 'Name',
        sortable: true,
        resizable: true,
        minResizeWidth: 100,
        component: 'data-table/column'
      }, {
        valuePath: 'sourceType',
        label: 'Source Type',
        sortable: true,
        resizable: true,
        minResizeWidth: 100,
        component: 'data-table/column',
        cellComponent: 'data-sources/components/table-cell/source-type'
      }, {
        valuePath: 'lastSyncAt',
        label: 'Last Data Refresh',
        sortable: true,
        resizable: true,
        minResizeWidth: 100,
        component: 'data-table/column'
      }];
      this.set('columns', columns);

      var table = _emberLightTable.default.create({
        columns: columns,
        rows: dataSources
      });

      var rows = table.rows;
      this.setProperties({
        rows: rows,
        table: table
      });
    }
  });

  _exports.default = _default;
});