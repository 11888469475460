define("happyfox-bi/utilities/fullscreen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.exitFullscreen = exitFullscreen;
  _exports.getFullscreenElement = getFullscreenElement;
  _exports.requestFullscreen = requestFullscreen;

  function requestFullscreen(selector) {
    var methodNames = ['requestFullscreen', 'webkitRequestFullScreen', 'mozRequestFullScreen', 'msRequestFullscreen'];
    executeMethod(selector, methodNames);
  }

  function exitFullscreen() {
    var methodNames = ['exitFullscreen', 'webkitExitFullscreen', 'mozCancelFullScreen', 'msExitFullscreen'];
    executeMethod(document, methodNames);
  }

  function executeMethod(selector, methodNames) {
    methodNames.forEach(function (method) {
      if (selector[method]) {
        selector[method]();
      }
    });
  }

  function getFullscreenElement() {
    var elementNames = ['fullscreenElement', 'webkitFullscreenElement', 'mozFullScreenElement', 'msFullscreenElement'];
    var elementName;

    for (var _i = 0, _elementNames = elementNames; _i < _elementNames.length; _i++) {
      var name = _elementNames[_i];

      if (document[name]) {
        elementName = name;
        break;
      }
    }

    return document[elementName];
  }
});