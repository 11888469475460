define("happyfox-bi/components/data-table-new/column/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fm8JfBUV",
    "block": "{\"symbols\":[],\"statements\":[[1,[35,1,[\"label\"]]],[2,\"\\n\"],[6,[37,2],[[35,1,[\"sorted\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"lt-column_content_sort-indicator\"],[15,\"data-test-id\",[31,[[30,[36,2],[[35,1,[\"ascending\"]],\"sort-indicator-ascending\",\"sort-indicator-descending\"],null]]]],[12],[2,\"\\n\"],[6,[37,2],[[35,1,[\"ascending\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,0],[\"up-sort-arrow\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[30,[36,0],[\"down-sort-arrow\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"inline-svg\",\"column\",\"if\"]}",
    "meta": {
      "moduleName": "happyfox-bi/components/data-table-new/column/template.hbs"
    }
  });

  _exports.default = _default;
});