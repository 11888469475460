define("happyfox-bi/components/data-table-new/html-cell/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['hf-u-text-overflow'],
    click: function click(event) {
      // Prevent row action when user clicks on a link
      if (Ember.$(event.target).closest('a').length) {
        event.stopPropagation();
      }
    }
  });

  _exports.default = _default;
});