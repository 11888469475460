define("happyfox-bi/components/pareto-chart/component", ["exports", "happyfox-bi/components/multi-axis-chart/component", "@amcharts/amcharts4/core"], function (_exports, _component, am4core) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PARETO_HIGHLIGHT_COLOR = '#eb584c';

  var _default = _component.default.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var chart = this.chart;
      var paretoSeriesColor = am4core.color('#f3c445');
      var columnSeriesColor = am4core.color('#626acd');
      var paretoValueAxis;
      chart.yAxes.values.forEach(function (axis) {
        if (axis.renderer.opposite) {
          paretoValueAxis = axis;
        }
      }); // Make y-axis right as percentage based values;

      paretoValueAxis.min = 0;
      paretoValueAxis.max = 110;
      paretoValueAxis.strictMinMax = true;
      paretoValueAxis.numberFormatter = new am4core.NumberFormatter();
      paretoValueAxis.numberFormatter.numberFormat = "#'%'";
      var columnSeries, paretoSeries; // Using amcharts `each` to iterate since series is of type LIST

      chart.series.each(function (series) {
        if (series.className == 'ColumnSeries') {
          columnSeries = series;
        } else if (series.className == 'LineSeries') {
          paretoSeries = series;
        }
      });
      paretoSeries.fill = paretoSeriesColor;
      paretoSeries.stroke = paretoSeriesColor;
      columnSeries.fill = columnSeriesColor;
      columnSeries.stroke = columnSeriesColor; // Events

      chart.events.on('inited', function () {
        var seriesDataItems = columnSeries.dataItems.values;
        var highlightColor = am4core.color(PARETO_HIGHLIGHT_COLOR); // Top 20% of contributions are generally vital

        var topContributionsCount = Math.round(seriesDataItems.length * 0.2);
        var lastTopContributionItem = seriesDataItems[topContributionsCount - 1];
        var cumulativePercentage = lastTopContributionItem.dataContext.paretoValue;

        if (cumulativePercentage >= 80) {
          // Highlight all top contributions if cumulative % is >= 80
          for (var i = 0; i < topContributionsCount; i++) {
            var seriesItem = seriesDataItems[i];
            seriesItem.column.fill = highlightColor;
            seriesItem.column.stroke = highlightColor;
          }
        } else {
          // Highlight contributions untill 80% is reached
          var _i = 0;

          while (_i < seriesDataItems.length) {
            var _seriesItem = seriesDataItems[_i];
            var seriesItemData = seriesDataItems[_i].dataContext;
            _seriesItem.column.fill = highlightColor;
            _seriesItem.column.stroke = highlightColor;

            if (seriesItemData.paretoValue >= 80) {
              break;
            }

            _i++;
          }
        }
      });
    },
    transformValuesInData: function transformValuesInData() {
      var data = this.data;
      var valueKey = this.get('config.yAxis.key');

      if (!this.isXAxisDateType()) {
        data = data.sort(function (a, b) {
          return b[valueKey] - a[valueKey];
        });
      }

      var total = 0;
      data.forEach(function (datum) {
        total += datum[valueKey];
      });
      var cumulativeValue = 0;
      data.forEach(function (datum) {
        cumulativeValue += datum[valueKey];
        var cumulativePercentage = cumulativeValue / total * 100;
        datum['paretoValue'] = Math.round(cumulativePercentage * 100) / 100;
      });
    },
    // Convert config to support multi axis chart
    updateConfig: function updateConfig() {
      var config = Object.assign({}, this.get('config'));
      var yAxisLeft = Ember.A();
      var yAxisRight = Ember.A();
      yAxisLeft.addObject(config.yAxis);
      yAxisRight.addObject(Ember.Object.create({
        'key': 'paretoValue',
        'label': this.get('intlService').lookup('total-percent')
      }));
      config.yAxisRight = yAxisRight;
      config.yAxisLeft = yAxisLeft;
      this.set('config', config);
    }
  });

  _exports.default = _default;
});