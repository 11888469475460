define("happyfox-bi/visualizations/new/components/display-settings/funnel-chart/component", ["exports", "happyfox-bi/visualizations/new/components/display-settings/base-chart/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALUE_FORMAT_OPTIONS = [{
    id: 'percentage',
    name: 'Percentage'
  }, {
    id: 'absolute',
    name: 'Absolute'
  }];
  var ORIENTATION_POSITIONS = [{
    id: 'vertical',
    name: 'Vertical'
  }, {
    id: 'horizontal',
    name: 'Horizontal'
  }];

  var _default = _component.default.extend({
    settings: null,
    orientationOptions: ORIENTATION_POSITIONS,
    valueFormatOptions: VALUE_FORMAT_OPTIONS,
    selectedOrientation: null,
    selectedValueFormat: null,
    init: function init() {
      this._super.apply(this, arguments);

      var _this$get = this.get('settings'),
          orientation = _this$get.orientation,
          valueFormat = _this$get.valueFormat;

      this.set('selectedOrientation', orientation ? this.orientationOptions.findBy('id', orientation) : this.orientationOptions.findBy('id', 'vertical'));
      this.set('selectedValueFormat', valueFormat ? this.valueFormatOptions.findBy('id', valueFormat) : this.valueFormatOptions.findBy('id', 'percentage'));
    },
    actions: {
      onValueFormatChange: function onValueFormatChange(format) {
        this.set('selectedValueFormat', format);
        this.set('settings.valueFormat', format.id);
        this.onUpdateDisplaySettings();
      },
      onOrientationChange: function onOrientationChange(type) {
        this.set('selectedOrientation', type);
        this.set('settings.orientation', type.id);
        this.onUpdateDisplaySettings();
      }
    }
  });

  _exports.default = _default;
});