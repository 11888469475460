define("happyfox-bi/visualizations/components/display-fields-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WvWbaWrT",
    "block": "{\"symbols\":[\"field\"],\"statements\":[[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,1],null,[[\"field\",\"onNameChange\"],[[32,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"onNameChange\",\"visualizations/components/display-fields-list/edit-field\",\"displayFields\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "happyfox-bi/visualizations/components/display-fields-list/template.hbs"
    }
  });

  _exports.default = _default;
});