define("happyfox-bi/report-templates/components/edit/report-template-form-model", ["exports", "happyfox-bi/form-models/base-form"], function (_exports, _baseForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseForm.default.extend({
    intlService: Ember.inject.service('intl'),
    isAdd: false,
    dataSource: null,
    basicFieldKeys: null,
    scrollableContainerSelector: '.js-report-template-form_content',
    init: function init() {
      this._super.apply(this, arguments);

      var rawFields = Ember.A();
      var intlService = this.get('intlService');
      var nameField = Ember.Object.create({
        key: 'name',
        isText: true,
        isMandatory: true,
        label: intlService.lookup('name')
      });
      var descriptionField = Ember.Object.create({
        key: 'description',
        isTextarea: true,
        label: intlService.lookup('description')
      });
      var templateGlobalField = Ember.Object.create({
        key: 'isGlobal',
        isToggle: true
      });
      rawFields.addObjects([nameField, descriptionField, templateGlobalField]);
      this.setProperties({
        fields: this._createFieldModels(rawFields),
        basicFieldKeys: rawFields.mapBy('key')
      });

      if (!this.isAdd) {
        this._copyFromDataSource();
      }
    },
    submit: function submit() {
      var _this = this;

      this.set('errors', Ember.A());
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.validate().then(function () {
          if (!_this.isAdd) {
            // In case of edit, we set the datasource with updated fields
            _this._copyToDataSource();
          }

          var payload = _this.getModifiedFields();

          resolve(payload);
        }).catch(function () {
          _this.scrollToError();

          reject();
        });
      });
    }
  });

  _exports.default = _default;
});