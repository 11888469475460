define("happyfox-bi/components/data-table-new/relative-time-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3gp+v5LY",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"hf-tooltipped hf-tooltipped-left\"],[15,\"aria-label\",[30,[36,1],[[35,0]],[[\"format\"],[\"absolute\"]]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"hf-u-text-overflow\"],[12],[2,\"\\n    \"],[1,[30,[36,2],[[35,0]],[[\"showCustomRelativeTime\"],[true]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"value\",\"format-date\",\"format-timestamp-by-preference\"]}",
    "meta": {
      "moduleName": "happyfox-bi/components/data-table-new/relative-time-cell/template.hbs"
    }
  });

  _exports.default = _default;
});