define("happyfox-bi/components/auto-complete/local/multiple/component", ["exports", "happyfox-bi/components/auto-complete/local/multiple/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    closeDropdownOnMouseLeave: false,
    init: function init() {
      this._super.apply(this, arguments);
    },
    mouseLeave: function mouseLeave() {
      if (this.get('closeDropdownOnMouseLeave')) {
        var select = this.get('select');

        if (Ember.isPresent(select)) {
          this.get('select').actions.close();
          this.$('input').focus();
        }
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      if (this.get('autofocus')) {
        Ember.run.next(function () {
          _this.$('input').focus();
        });
      }
    },
    didUpdateAttrs: function didUpdateAttrs() {
      var _this2 = this;

      if (this.get('autofocus')) {
        Ember.run.next(function () {
          _this2.$('input').focus();
        });
      }
    },
    actions: {
      onChange: function onChange(result, select) {
        if (this.get('onChange')) {
          this.get('onChange')(result);
        }

        this.set('selected', result);
        select.actions.search();
        this.$('input').val('');
      },
      onFocus: function onFocus(select) {
        this.set('select', select);
      },
      onClose: function onClose() {
        if (this.get('onClose')) {
          this.get('onClose')();
        }
      }
    }
  });

  _exports.default = _default;
});