define("happyfox-bi/reset-password/controller", ["exports", "happyfox-bi/utilities/email-validation"], function (_exports, _emailValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    dataService: Ember.inject.service('data'),
    token: null,
    isValidatingToken: null,
    isValidToken: false,
    email: null,
    password: null,
    confirmPassword: null,
    passwordsMismatched: false,
    isSubmitInProgress: false,
    isEmailSubmitInProgress: false,
    isEmailSubmitSuccess: false,
    isPasswordResetSuccess: false,
    isForgotPassword: Ember.computed.not('token'),
    error: null,
    emailError: null,
    passwordErrors: null,
    hasErrors: Ember.computed.or('error', 'passwordsMismatched'),
    showingPasswordPolicy: Ember.computed('isForgotPassword', 'isValidToken', 'isPasswordResetSuccess', {
      get: function get() {
        return !this.isForgotPassword && this.isValidToken && !this.isPasswordResetSuccess;
      }
    }),
    validateToken: function validateToken() {
      var _this = this;

      var token = this.get('token');
      this.setProperties({
        isValidToken: false,
        isValidatingToken: true,
        error: null
      });
      return this.get('dataService').validateForgotPasswordToken(token).then(function () {
        _this.setProperties({
          'isValidToken': true
        });
      }).catch(function (_ref) {
        var errors = _ref.errors;

        _this.setProperties({
          error: errors.error,
          isValidToken: false
        });
      }).finally(function () {
        _this.set('isValidatingToken', false);
      });
    },
    actions: {
      emailSubmit: function emailSubmit() {
        var _this2 = this;

        var email = this.get('email');
        this.set('emailError', null);

        if ((0, _emailValidation.validateEmail)(email.trim())) {
          this.setProperties({
            isEmailSubmitSuccess: false,
            isEmailSubmitInProgress: true
          });
          this.get('dataService').sendEmailForForgotPassword(email).then(function () {
            _this2.set('isEmailSubmitSuccess', true);
          }).catch(function (_ref2) {
            var errors = _ref2.errors;

            _this2.set('error', errors.error);
          }).finally(function () {
            _this2.set('isEmailSubmitInProgress', false);
          });
        } else {
          this.set('emailError', 'Invalid email');
        }
      },
      submitPassword: function submitPassword() {
        var _this3 = this;

        var password = this.get('password');
        var confirmPassword = this.get('confirmPassword');
        var passwordsMatched = password === confirmPassword;
        this.set('passwordsMismatched', !passwordsMatched);
        this.set('passwordErrors', null);

        if (passwordsMatched) {
          var token = this.get('token');
          this.setProperties({
            isPasswordResetSuccess: false,
            isSubmitInProgress: true
          });
          this.get('dataService').resetPassword({
            password: password,
            confirmPassword: confirmPassword,
            token: token
          }).then(function () {
            _this3.set('isPasswordResetSuccess', true);
          }).catch(function (_ref3) {
            var errors = _ref3.errors;

            _this3.set('error', errors.error);

            if (errors.password) {
              _this3.set('passwordErrors', errors.password);
            }
          }).finally(function () {
            _this3.set('isSubmitInProgress', false);
          });
        }
      },
      goToLogin: function goToLogin() {
        this.transitionToRoute('login');
      },
      removeToken: function removeToken() {
        this.set('token', null);
      }
    }
  });

  _exports.default = _default;
});