define("happyfox-bi/visualizations/new/components/table-formatting/component", ["exports", "happyfox-bi/visualizations/constant"], function (_exports, _constant) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    formats: null,
    displaySettings: null,
    columnFields: null,
    canShowTableFormatModal: false,
    currentFormat: null,
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.formats) {
        this.set('formats', Ember.A());
      }
    },
    cloneTableFormats: function cloneTableFormats() {
      return this.formats.slice();
    },
    numericFields: Ember.computed('columnFields.@each.{name,displayName}', {
      get: function get() {
        return (this.columnFields || Ember.A()).filter(function (field) {
          return _constant.NUMERIC_FIELD_TYPES.includes(field.dataType);
        });
      }
    }),
    actions: {
      openTableFormatModal: function openTableFormatModal() {
        this.set('canShowTableFormatModal', true);
      },
      closeTableFormatModal: function closeTableFormatModal() {
        this.setProperties({
          'canShowTableFormatModal': false,
          'currentFormat': null
        });
      },
      editFormat: function editFormat(format) {
        this.set('currentFormat', format);
        this.send('openTableFormatModal');
      },
      addFormat: function addFormat(format) {
        var formats = this.cloneTableFormats();
        formats.push(format);
        this.onApplyTableFormats(formats);
        this.send('closeTableFormatModal');
      },
      updateFormat: function updateFormat(newItem) {
        var formats = this.cloneTableFormats();
        var oldItemIndex = formats.indexOf(this.currentFormat);
        formats.splice(oldItemIndex, 1, newItem);
        this.onApplyTableFormats(formats);
        this.send('closeTableFormatModal');
      },
      removeFormat: function removeFormat(format) {
        var formats = this.cloneTableFormats();
        formats.splice(formats.indexOf(format), 1);
        this.onApplyTableFormats(formats);
      }
    }
  });

  _exports.default = _default;
});