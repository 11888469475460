define("happyfox-bi/visualization-examples/mock-data/page-15", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PAGE_15_VISUALIZATION_DATA = _exports.PAGE_15 = void 0;
  var PAGE_15 = {
    id: 15,
    name: 'Agent information',
    description: '',
    order: 15,
    report: 3,
    created_by: 1,
    created_at: '2019-06-17T08:08:33Z',
    updated_at: '2019-06-18T11:20:30Z',
    visualizations: [{
      id: 43,
      name: 'Agent ticket volume and time spent',
      page: 15,
      display_type: 'multi_axis_chart',
      display_settings: {
        size: 'horizontal-rectangle-l',
        x_axis: {
          key: 'agent',
          label: null
        },
        y_axis_left: [{
          key: 'tickets',
          label: 'Tickets count',
          color: ''
        }],
        y_axis_right: [{
          key: 'duration',
          label: 'Duration (minutes)',
          color: ''
        }]
      },
      preferences: {
        filters: {
          match_all: []
        },
        date_range: 'last_month'
      },
      settings: {
        fields: [{
          'name': 'agent',
          'type': 'dataset_field',
          'dataset': 1,
          'dataset_field': 12,
          'data_type': 'text',
          'is_editable': false
        }, {
          'name': 'tickets',
          'type': 'aggregation',
          'aggregation_type': 'count',
          'dataset': 1,
          'dataset_field': 14,
          'data_type': 'decimal',
          'is_editable': false
        }, {
          'name': 'duration',
          'type': 'aggregation',
          'aggregation_type': 'average',
          'dataset': 1,
          'dataset_field': 66,
          'data_type': 'decimal',
          'is_editable': false
        }],
        query: {
          rows: [],
          columns: [],
          values: [],
          filters: {
            match_all: []
          },
          sort_by: []
        }
      }
    }, {
      id: 44,
      name: 'Tickets count per Category',
      page: 15,
      display_type: 'stacked_area_chart',
      display_settings: {
        size: 'square-m',
        x_axis: {
          key: 'date',
          label: null
        },
        y_axis: {
          label: 'Tickets count',
          group_by: 'category',
          value: 'tickets'
        },
        colors: ['#EE5535', '#80D8FA']
      },
      preferences: {
        filters: {
          match_all: []
        },
        date_range: 'last_date'
      },
      settings: {
        fields: [{
          'name': 'date',
          'type': 'dataset_field',
          'dataset': 1,
          'dataset_field': 12,
          'data_type': 'timestamp',
          'format': 'date',
          'is_editable': false
        }, {
          'name': 'category',
          'type': 'dataset_field',
          'dataset': 1,
          'dataset_field': 13,
          'data_type': 'text',
          'is_editable': false
        }, {
          'name': 'tickets',
          'type': 'aggregation',
          'aggregation_type': 'count',
          'dataset': 1,
          'dataset_field': 14,
          'data_type': 'decimal',
          'is_editable': false
        }],
        query: {
          rows: [],
          columns: [],
          values: [],
          filters: {
            match_all: []
          },
          sort_by: []
        }
      }
    }, {
      id: 45,
      name: 'Tickets count per Day',
      page: 15,
      display_type: 'area_chart',
      display_settings: {
        size: 'square-m',
        x_axis: {
          key: 'date',
          label: null
        },
        y_axis: {
          key: 'tickets',
          label: 'Tickets Count'
        },
        colors: ['#EE5535', '#80D8FA']
      },
      preferences: {
        filters: {
          match_all: []
        },
        date_range: 'last_date'
      },
      settings: {
        fields: [{
          'name': 'date',
          'type': 'dataset_field',
          'dataset': 1,
          'dataset_field': 12,
          'data_type': 'timestamp',
          'format': 'date',
          'is_editable': false
        }, {
          'name': 'value',
          'type': 'aggregation',
          'aggregation_type': 'count',
          'dataset': 1,
          'dataset_field': 14,
          'data_type': 'decimal',
          'is_editable': false
        }],
        query: {
          rows: [],
          columns: [],
          values: [],
          filters: {
            match_all: []
          },
          sort_by: []
        }
      }
    }],
    layout: {
      'items': [{
        'visualization_id': 43,
        'width': 16,
        'height': 8,
        'x': 0,
        'y': 0
      }, {
        'visualization_id': 44,
        'width': 8,
        'height': 8,
        'x': 0,
        'y': 8
      }, {
        'visualization_id': 45,
        'width': 8,
        'height': 8,
        'x': 8,
        'y': 0
      }]
    }
  };
  _exports.PAGE_15 = PAGE_15;
  var PAGE_15_VISUALIZATION_DATA = [{
    id: 43,
    data: [{
      'agent': 'John',
      'tickets': 20,
      'duration': 100
    }, {
      'agent': 'Arya',
      'tickets': 15,
      'duration': 290
    }, {
      'agent': 'Sansa',
      'tickets': 25,
      'duration': 120
    }, {
      'agent': 'Bran',
      'tickets': 5,
      'duration': 300
    }, {
      'agent': 'Dany',
      'tickets': 20,
      'duration': 450
    }, {
      'agent': 'Cersei',
      'tickets': 10,
      'duration': 100
    }, {
      'agent': null,
      'tickets': 13,
      'duration': 200
    }, {
      'agent': 'Tyrion',
      'tickets': 13,
      'duration': 200
    }, {
      'agent': 'Brienne',
      'tickets': 15,
      'duration': 250
    }, {
      'agent': 'Gendry',
      'tickets': 9,
      'duration': 210
    }, {
      'agent': 'Khal',
      'tickets': 6,
      'duration': 190
    }, {
      'agent': 'Jorah',
      'tickets': 30,
      'duration': 170
    }, {
      'agent': 'Samwell',
      'tickets': 12,
      'duration': 280
    }, {
      'agent': 'Eddard',
      'tickets': 18,
      'duration': 100
    }, {
      'agent': 'Podrick',
      'tickets': 2,
      'duration': 360
    }]
  }, {
    id: 44,
    data: [{
      'date': '2018-11-01T00:00:00Z',
      'category': 'Support',
      'tickets': 300
    }, {
      'date': '2018-11-01T00:00:00Z',
      'category': 'HR',
      'tickets': 500
    }, {
      'date': '2018-11-02T00:00:00Z',
      'category': 'Support',
      'tickets': 400
    }, {
      'date': '2018-11-02T00:00:00Z',
      'category': 'HR',
      'tickets': 800
    }, {
      'date': '2018-11-03T00:00:00Z',
      'category': 'Support',
      'tickets': 500
    }, {
      'date': '2018-11-03T00:00:00Z',
      'category': 'HR',
      'tickets': 200
    }, {
      'date': '2018-11-04T00:00:00Z',
      'category': 'Support',
      'tickets': 250
    }, {
      'date': '2018-11-04T00:00:00Z',
      'category': 'HR',
      'tickets': 450
    }, {
      'date': '2018-11-05T00:00:00Z',
      'category': 'Support',
      'tickets': 360
    }, {
      'date': '2018-11-05T00:00:00Z',
      'category': 'HR',
      'tickets': 220
    }, {
      'date': '2018-11-06T00:00:00Z',
      'category': 'Support',
      'tickets': 230
    }, {
      'date': '2018-11-06T00:00:00Z',
      'category': 'HR',
      'tickets': 310
    }, {
      'date': '2018-11-07T00:00:00Z',
      'category': 'Support',
      'tickets': 600
    }, {
      'date': '2018-11-07T00:00:00Z',
      'category': 'HR',
      'tickets': 600
    }, {
      'date': '2018-11-08T00:00:00Z',
      'category': 'Support',
      'tickets': 380
    }, {
      'date': '2018-11-08T00:00:00Z',
      'category': 'HR',
      'tickets': 260
    }, {
      'date': '2018-11-09T00:00:00Z',
      'category': 'Support',
      'tickets': 520
    }, {
      'date': '2018-11-09T00:00:00Z',
      'category': 'HR',
      'tickets': 690
    }]
  }, {
    id: 45,
    data: [{
      'date': '2018-11-01T00:00:00Z',
      'tickets': 300
    }, {
      'date': '2018-11-02T00:00:00Z',
      'tickets': 400
    }, {
      'date': '2018-11-03T00:00:00Z',
      'tickets': 500
    }, {
      'date': '2018-11-04T00:00:00Z',
      'tickets': 450
    }, {
      'date': '2018-11-05T00:00:00Z',
      'tickets': 100
    }, {
      'date': '2018-11-06T00:00:00Z',
      'tickets': 230
    }, {
      'date': '2018-11-07T00:00:00Z',
      'tickets': 600
    }, {
      'date': '2018-11-08T00:00:00Z',
      'tickets': 380
    }, {
      'date': '2018-11-09T00:00:00Z',
      'tickets': 690
    }, {
      'date': '2018-11-10T00:00:00Z',
      'tickets': 390
    }, {
      'date': '2018-11-11T00:00:00Z',
      'tickets': 590
    }]
  }];
  _exports.PAGE_15_VISUALIZATION_DATA = PAGE_15_VISUALIZATION_DATA;
});