define("happyfox-bi/visualization-examples/mock-data/page-17", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PAGE_17_VISUALIZATION_DATA = _exports.PAGE_17 = void 0;
  var PAGE_17 = {
    id: 17,
    name: 'ACME: Product Report',
    order: 17,
    report: 3,
    created_by: 1,
    created_at: '2019-06-17T08:08:33Z',
    updated_at: '2019-06-18T11:20:30Z',
    visualizations: [{
      id: 57,
      name: 'Simple Flow',
      page: 17,
      display_type: 'life_cycle_chart',
      display_settings: {
        size: 'horizontal-rectangle-l',
        xAxis: {
          key: 'from',
          label: 'From'
        },
        yAxis: {
          key: 'to',
          label: 'To',
          value: 'value'
        }
      },
      preferences: {
        filters: {
          match_all: []
        },
        date_range: 'last_month'
      },
      settings: {
        fields: [{
          'name': 'from',
          'type': 'dataset_field',
          'dataset': 1,
          'dataset_field': 12,
          'data_type': 'text',
          'is_editable': true
        }, {
          'name': 'to',
          'type': 'dataset_field',
          'dataset': 1,
          'dataset_field': 12,
          'data_type': 'text',
          'is_editable': true
        }, {
          'name': 'value',
          'type': 'aggregation',
          'aggregation_type': 'count',
          'dataset': 1,
          'dataset_field': 14,
          'data_type': 'decimal',
          'is_editable': false
        }],
        query: {
          rows: [],
          columns: [],
          values: [],
          filters: {
            match_all: []
          },
          sort_by: []
        }
      }
    }, {
      id: 62,
      name: 'Lead Generation',
      page: 17,
      display_type: 'funnel_chart',
      display_settings: {
        category: 'stage',
        value: 'count',
        'orientation': 'Vertical',
        'value_format': 'percentage'
      },
      preferences: {
        filters: {
          match_all: []
        },
        date_range: 'last_month'
      },
      settings: {
        fields: [{
          'name': 'stage',
          'type': 'dataset_field',
          'dataset': 1,
          'dataset_field': 12,
          'data_type': 'text',
          'is_editable': false
        }, {
          'name': 'count',
          'type': 'aggregation',
          'aggregation_type': 'count',
          'dataset': 1,
          'dataset_field': 14,
          'data_type': 'decimal',
          'is_editable': false
        }],
        query: {
          rows: [],
          columns: [],
          values: [],
          filters: {
            match_all: []
          },
          sort_by: []
        }
      }
    }, {
      id: 63,
      name: 'Tickets vs Average Response Time',
      page: 17,
      "display_type": "segmentation_chart",
      "display_settings": {
        "size": "square-l",
        "x_axis": {
          "key": "count",
          "label": "Tickets Count",
          "segmentation_type": "average",
          "segmentation_value": "null"
        },
        "y_axis": {
          "key": "responseTime",
          "label": "Response Time",
          "segmentation_type": "average",
          "segmentation_value": "null"
        },
        "category": {
          "key": "category",
          "label": "Category"
        },
        "quadrants": {
          "top_left": {
            "name": "Low Tickets Count, High Response Time",
            "color": "#7f66dc"
          },
          "top_right": {
            "name": "High Tickets Count & Response Time",
            "color": "#dc6688"
          },
          "bottom_left": {
            "name": "Low Tickets Count & Response Time",
            "color": "#67b7dc"
          },
          "bottom_right": {
            "name": "High Tickets Count, Low Response Time",
            "color": "#ddaf66"
          }
        },
        "hide_legend": false
      },
      preferences: {
        filters: {
          match_all: []
        },
        date_range: 'last_month'
      },
      settings: {
        fields: [{
          'name': 'responseTime',
          'type': 'dataset_field',
          'dataset': 1,
          'dataset_field': 12,
          'data_type': 'time',
          'format': 'minutes',
          'is_editable': false
        }, {
          'name': 'count',
          'type': 'aggregation',
          'aggregation_type': 'count',
          'dataset': 1,
          'dataset_field': 14,
          'data_type': 'decimal',
          'is_editable': false
        }, {
          'name': 'category',
          'type': 'dataset_field',
          'dataset': 1,
          'dataset_field': 12,
          'data_type': 'text',
          'is_editable': false
        }],
        query: {
          rows: [],
          columns: [],
          values: [],
          filters: {
            match_all: []
          },
          sort_by: []
        }
      }
    }],
    layout: {
      "items": [{
        'visualization_id': 57,
        'width': 16,
        'height': 8,
        'x': 0,
        'y': 16
      }, {
        'visualization_id': 62,
        'width': 16,
        'height': 8,
        'x': 0,
        'y': 48
      }, {
        'visualization_id': 63,
        'width': 8,
        'height': 8,
        'x': 8,
        'y': 56
      }]
    }
  };
  _exports.PAGE_17 = PAGE_17;
  var PAGE_17_VISUALIZATION_DATA = [{
    id: 57,
    data: [// Simple flow
    {
      'from': 'Search',
      'to': 'Blog',
      'value': 20
    }, {
      'from': 'Search',
      'to': 'Home',
      'value': 30
    }, {
      'from': 'Blog',
      'to': 'Demo',
      'value': 40
    }, {
      'from': 'Home',
      'to': 'Demo',
      'value': 10
    }, {
      'from': 'Demo',
      'to': 'Download',
      'value': 10
    }, {
      'from': 'Home',
      'to': 'Signup',
      'value': 20
    }, {
      'from': 'Demo',
      'to': 'Signup',
      'value': 15
    }]
  }, {
    id: 62,
    data: [{
      'stage': 'Visitor',
      'count': 50
    }, {
      'stage': 'Registered',
      'count': 30
    }, {
      'stage': 'Lead',
      'count': 25
    }, {
      'stage': 'Qualified Lead',
      'count': 10
    }, {
      'stage': 'Customer',
      'count': 8
    }]
  }, {
    id: 63,
    data: [{
      'category': 'ACME Super',
      count: 301,
      responseTime: 52.62
    }, {
      'category': 'ACME DESK',
      count: 111,
      responseTime: 76.60
    }, {
      'category': 'ACME CONNECT',
      count: 200,
      responseTime: 83.65
    }, {
      'category': 'ACME ADVANCE',
      count: 51,
      responseTime: 12.5
    }]
  }];
  _exports.PAGE_17_VISUALIZATION_DATA = PAGE_17_VISUALIZATION_DATA;
});