define("happyfox-bi/components/form-field-input/radio/component", ["exports", "happyfox-bi/components/form-field-input/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    field: null,
    actions: {
      onChange: function onChange() {
        this.toggleProperty('field.value');

        if (this.onChange) {
          this.onChange(this.get('field.value'));
        }
      }
    }
  });

  _exports.default = _default;
});