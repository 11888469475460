define("happyfox-bi/visualizations/new/components/table-formatting/format-item/component", ["exports", "happyfox-bi/utilities/string"], function (_exports, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    format: null,
    formatPreviewColor: Ember.computed('format.barColor', {
      get: function get() {
        var currentFormat = this.format;
        return currentFormat ? (0, _string.getSafeHtml)("background-color: ".concat(currentFormat.barColor, ";")) : '';
      }
    }),
    actions: {
      editFormat: function editFormat() {
        this.onEditFormat(this.format);
      },
      removeFormat: function removeFormat() {
        this.onRemoveFormat(this.format);
      }
    }
  });

  _exports.default = _default;
});