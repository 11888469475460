define("happyfox-bi/visualizations/new/components/display-settings/segmentation-chart/quadrant/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SmiDYMmv",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"field_label\"],[12],[1,[30,[36,1],[[35,0]],null]],[13],[2,\"\\n\"],[10,\"section\"],[14,0,\"quadrant-item u-vertically-centered-container\"],[12],[2,\"\\n  \"],[1,[30,[36,4],null,[[\"class\",\"value\",\"focus-out\"],[\"field_value u-flex-max-width\",[35,3,[\"name\"]],[30,[36,2],[[32,0],\"onNameChange\"],null]]]]],[2,\"\\n  \"],[1,[30,[36,5],null,[[\"class\",\"color\",\"includeHexInitial\",\"onChange\"],[\"mod-small\",[35,3,[\"color\"]],true,[30,[36,2],[[32,0],\"onColorChange\"],null]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"key\",\"t\",\"action\",\"settings\",\"input\",\"color-picker\"]}",
    "meta": {
      "moduleName": "happyfox-bi/visualizations/new/components/display-settings/segmentation-chart/quadrant/template.hbs"
    }
  });

  _exports.default = _default;
});