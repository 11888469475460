define("happyfox-bi/components/form-field-input/editor/component", ["exports", "happyfox-bi/components/form-field-input/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    enableAttachments: true,
    enableDropZone: true,
    enableInlineImages: false,
    enableInlineAttachments: false,
    disableEditorInlineAttachment: true,
    isEditorMaximized: false,
    inlineImagesModifierClass: '',
    inlineImageAttachmentContainerClass: '',
    actions: {
      onHtmlFocusOut: function onHtmlFocusOut() {
        this.get('field').validate();
      }
    }
  });

  _exports.default = _default;
});