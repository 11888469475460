define("happyfox-bi/components/form-field-input/array/component", ["exports", "happyfox-bi/components/form-field-input/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({});

  _exports.default = _default;
});