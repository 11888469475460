define("happyfox-bi/components/toggle-input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isChecked: false,
    isDisabled: false,
    classNames: ['toggle'],
    actions: {
      onChange: function onChange(isChecked) {
        this.set('isChecked', isChecked);

        if (!this.get('isDisabled')) {
          if (this.onChange) {
            this.onChange(isChecked);
          }
        }
      }
    }
  });

  _exports.default = _default;
});