define("happyfox-bi/components/form-field-input/multi-choice-dropdown/option/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c7ClD506",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,3],null,[[\"type\",\"class\",\"checked\",\"data-test-id\",\"aria-label\"],[\"checkbox\",\"mod-no-outline\",[35,1,[\"isSelected\"]],\"entity-dropdown-option-check-box\",[30,[36,2],[[35,1],[35,0]],null]]]]],[2,\"\\n\"],[1,[30,[36,2],[[35,1],[35,0]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"choiceLabelKey\",\"option\",\"get\",\"input\"]}",
    "meta": {
      "moduleName": "happyfox-bi/components/form-field-input/multi-choice-dropdown/option/template.hbs"
    }
  });

  _exports.default = _default;
});