define("happyfox-bi/mixins/bulk-selection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    selectedItems: null,
    isTable: false,
    init: function init() {
      this._super(arguments);

      this.set('selectedItems', Ember.A());
    },
    isAnySelected: Ember.computed.notEmpty('selectedItems'),
    selectableItems: Ember.computed('items.[]', {
      get: function get() {
        var items = this.get('items');

        if (Ember.isArray(items)) {
          return items;
        } else {
          return Ember.A();
        }
      }
    }),
    isAllSelected: Ember.computed('selectableItems.[]', 'selectedItems.[]', {
      get: function get() {
        var items = this.get('selectableItems').toArray().mapBy('id');
        var selectedItems = this.get('selectedItems');
        return Ember.isPresent(items) && selectedItems.length === items.length;
      },
      set: function set(key, value) {
        return value;
      }
    }),
    selectAll: function selectAll() {
      var _this = this;

      this.get('selectableItems').forEach(function (item) {
        _this.get('selectedItems').addObject(item.get('id'));
      });

      if (this.get('isTable') && this.get('table.rows')) {
        this.get('table.rows').setEach('selected', true);
      }
    },
    // Manually triggered or called in fetchData
    clearAll: function clearAll() {
      this.set('selectedItems', Ember.A());
      this.set('criteriaForBulkSelection', null);

      if (this.get('isTable') && this.get('table.rows')) {
        this.get('table.rows').setEach('selected', false);
      }
    },
    // TODO: Remove the observer once we write a common mechanism for bulk selection for both card view and compact view
    clearBulkActionCriteriaOnDeselection: Ember.observer('table.selectedRows.[]', 'table.rows.@each.selected', function () {
      var _this2 = this;

      Ember.run.next(function () {
        if (_this2.get('table.rows')) {
          var areAllItemsSelected = _this2.get('table.selectedRows.length') === _this2.get('table.rows').get('length');

          if (!areAllItemsSelected) {
            _this2.set('criteriaForBulkSelection', null);
          }
        }
      });
    }),
    actions: {
      // When a single item is checked or unchecked
      updateSelectedItems: function updateSelectedItems(item, checked) {
        var selectedItems = this.get('selectedItems');

        if (checked) {
          selectedItems.addObject(item);
        } else {
          selectedItems.removeObject(item);
          this.set('criteriaForBulkSelection', null);
        }
      },
      handleAllSelection: function handleAllSelection(value, checked) {
        // Selects or unselects items in that particular page
        if (checked) {
          this.selectAll();
        } else {
          this.clearAll();
        }
      },
      clearSelectedItems: function clearSelectedItems() {
        // Unselects all items
        this.clearAll();
      }
    }
  });

  _exports.default = _default;
});