define("happyfox-bi/apps/details/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    app: Ember.computed.reads('model')
  });

  _exports.default = _default;
});