define("happyfox-bi/visualizations/new/components/display-settings/heat-map/component", ["exports", "happyfox-bi/visualizations/new/components/display-settings/base-chart/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    actions: {
      onMinColorChange: function onMinColorChange(color) {
        this.set('settings.minColor', color);
        Ember.run.debounce(this, this.actions.onUpdateDisplaySettings, 200);
      },
      onMaxColorChange: function onMaxColorChange(color) {
        this.set('settings.maxColor', color);
        Ember.run.debounce(this, this.actions.onUpdateDisplaySettings, 200);
      }
    }
  });

  _exports.default = _default;
});