define("happyfox-bi/visualizations/new/components/display-settings/gauge-chart/band-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['band-item'],
    intlService: Ember.inject.service('intl'),
    band: null,
    selectedBand: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('selectedBand', Ember.Object.create(this.band));
    },
    updateBandItem: function updateBandItem() {
      var oldBandItem = this.band;
      var editedBandItem = this.selectedBand;
      this.onUpdateBandItem(oldBandItem, editedBandItem);
    },
    actions: {
      onBandNameChange: function onBandNameChange(event) {
        this.selectedBand.set('name', event.target.value);
        Ember.run.debounce(this, this.updateBandItem, 600);
      },
      selectedBandMinChange: function selectedBandMinChange(event) {
        var value = event.target.value;

        if (value) {
          this.selectedBand.set('min', Number(value));
        } else {
          this.selectedBand.set('min', value);
        }

        Ember.run.debounce(this, this.updateBandItem, 600);
      },
      selectedBandMaxChange: function selectedBandMaxChange(event) {
        var value = event.target.value;

        if (value) {
          this.selectedBand.set('max', Number(value));
        } else {
          this.selectedBand.set('max', value);
        }

        Ember.run.debounce(this, this.updateBandItem, 600);
      },
      onBandColorChange: function onBandColorChange(color) {
        this.selectedBand.set('color', color);
        Ember.run.debounce(this, this.updateBandItem, 600);
      },
      removeBand: function removeBand() {
        var band = this.band;
        this.onRemoveBandItem(band);
      }
    }
  });

  _exports.default = _default;
});