define("happyfox-bi/components/form-field-input/toggle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0cxADChc",
    "block": "{\"symbols\":[],\"statements\":[[11,\"label\"],[16,0,[31,[\"toggle_button \",[30,[36,1],[[35,0,[\"value\"]],\"is-checked\"],null],\" \",[30,[36,1],[[35,0,[\"isDisabled\"]],\"is-disabled\"],null]]]],[16,\"data-test-id\",[34,2]],[16,\"aria-label\",[30,[36,1],[[35,3],[35,3]],null]],[24,\"role\",\"checkbox\"],[4,[38,4],[[32,0],\"onChange\"],null],[12],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"field\",\"if\",\"fieldDataTestId\",\"ariaLabel\",\"action\"]}",
    "meta": {
      "moduleName": "happyfox-bi/components/form-field-input/toggle/template.hbs"
    }
  });

  _exports.default = _default;
});