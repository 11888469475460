define("happyfox-bi/models/visualization", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    displayType: (0, _model.attr)('string'),
    displaySettings: (0, _model.attr)(),
    preferences: (0, _model.attr)(),
    settings: (0, _model.attr)({
      defaultValue: settingsDefaultValue
    }),
    page: (0, _model.attr)('number')
  });

  _exports.default = _default;

  function settingsDefaultValue() {
    return Ember.Object.create({
      'fields': [],
      'query': Ember.Object.create({
        'rows': [],
        'columns': [],
        'values': [],
        'filters': {
          'matchAll': [],
          'matchAny': []
        },
        'sortBy': [],
        'drillDownFields': []
      })
    });
  }
});