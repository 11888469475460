define("happyfox-bi/visualizations/new/components/merge-datasets/join-settings/sample-data/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BwX9G/eP",
    "block": "{\"symbols\":[\"t\"],\"statements\":[[6,[37,9],[[35,11]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"section\"],[14,0,\"join-sample-data-table u-hv-centered-container\"],[12],[2,\"\\n    \"],[1,[30,[36,10],[\"select-dataset-and-field\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,9],[[35,8]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"join-sample-data-loader\"],[12],[2,\"\\n      \"],[1,[30,[36,7],[\"ball-triangle-loader\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,6],[[35,5]],[[\"class\"],[\"mod-dark-mode join-sample-data-table\"]],[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,1],[[30,[36,0],[[32,1,[\"head\"]],\"expected `t.head` to be a contextual component but found a string. Did you mean `(component t.head)`? ('happyfox-bi/visualizations/new/components/merge-datasets/join-settings/sample-data/template.hbs' @ L12:C8) \"],null]],[[\"fixed\"],[true]]]],[2,\"\\n      \"],[1,[30,[36,1],[[30,[36,0],[[32,1,[\"body\"]],\"expected `t.body` to be a contextual component but found a string. Did you mean `(component t.body)`? ('happyfox-bi/visualizations/new/components/merge-datasets/join-settings/sample-data/template.hbs' @ L15:C8) \"],null]],[[\"canSelect\",\"onScroll\"],[false,[30,[36,4],[[32,0],[30,[36,3],[[35,2]],null]],null]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-assert-implicit-component-helper-argument\",\"component\",\"currentScrollOffset\",\"mut\",\"action\",\"table\",\"light-table\",\"inline-svg\",\"loadingSampleData\",\"if\",\"t\",\"showEmptyState\"]}",
    "meta": {
      "moduleName": "happyfox-bi/visualizations/new/components/merge-datasets/join-settings/sample-data/template.hbs"
    }
  });

  _exports.default = _default;
});