define("happyfox-bi/visualizations/new/components/fields-selection/gantt-chart/component", ["exports", "happyfox-bi/visualizations/new/components/fields-selection/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['new-create-edit-fields-selection'],
    rows: null,
    columns: null,
    values: null,
    categories: null,
    tasks: null,
    start: null,
    end: null,
    showCategoriesFieldModal: false,
    showTasksFieldModal: false,
    showStartFieldModal: false,
    showEndFieldModal: false,
    canAddCategories: Ember.computed.lt('categories.length', 1),
    canAddTasks: Ember.computed.lt('tasks.length', 1),
    canAddStart: Ember.computed.lt('start.length', 1),
    canAddEnd: Ember.computed.lt('end.length', 1),
    init: function init() {
      this._super.apply(this, arguments);

      var rows = this.rows.slice(); //Since row fields are mainted as [], handling gantt chart fields also as array

      var categories = Ember.isPresent(rows) ? Ember.A([rows[0]]) : Ember.A();
      var tasks = Ember.isPresent(rows) && rows[1] ? Ember.A([rows[1]]) : Ember.A();
      var start = Ember.isPresent(rows) && rows[2] ? Ember.A([rows[2]]) : Ember.A();
      var end = Ember.isPresent(rows) && rows[3] ? Ember.A([rows[3]]) : Ember.A();
      this.setProperties({
        categories: categories,
        tasks: tasks,
        start: start,
        end: end
      });
    },
    updateVisualizationRowsField: function updateVisualizationRowsField() {
      var categories = this.categories,
          tasks = this.tasks,
          start = this.start,
          end = this.end;
      var rows = Ember.A().addObjects(categories).addObjects(tasks).addObjects(start).addObjects(end);
      this.onRowsChange(rows);
    },
    actions: {
      removeCategoriesField: function removeCategoriesField(field) {
        var categories = this.categories;
        categories.removeObject(field);
        this.updateVisualizationRowsField();
      },
      removeTasksField: function removeTasksField(field) {
        var tasks = this.tasks;
        tasks.removeObject(field);
        this.updateVisualizationRowsField();
      },
      removeStartField: function removeStartField(field) {
        var startValues = this.start;
        startValues.removeObject(field);
        this.updateVisualizationRowsField();
      },
      removeEndField: function removeEndField(field) {
        var endValues = this.end;
        endValues.removeObject(field);
        this.updateVisualizationRowsField();
      },
      addCategoriesField: function addCategoriesField(field) {
        var categories = this.categories;
        categories.addObject(field);
        this.toggleProperty('showCategoriesFieldModal');
        this.updateVisualizationRowsField();
      },
      addTasksField: function addTasksField(field) {
        var tasks = this.tasks;
        tasks.addObject(field);
        this.toggleProperty('showTasksFieldModal');
        this.updateVisualizationRowsField();
      },
      addStartField: function addStartField(field) {
        var startValues = this.start;
        startValues.addObject(field);
        this.toggleProperty('showStartFieldModal');
        this.updateVisualizationRowsField();
      },
      addEndField: function addEndField(field) {
        var endValues = this.end;
        endValues.addObject(field);
        this.toggleProperty('showEndFieldModal');
        this.updateVisualizationRowsField();
      },
      toggleCategoriesFieldModal: function toggleCategoriesFieldModal() {
        this.toggleProperty('showCategoriesFieldModal');
      },
      toggleTasksFieldModal: function toggleTasksFieldModal() {
        this.toggleProperty('showTasksFieldModal');
      },
      toggleStartFieldModal: function toggleStartFieldModal() {
        this.toggleProperty('showStartFieldModal');
      },
      toggleEndFieldModal: function toggleEndFieldModal() {
        this.toggleProperty('showEndFieldModal');
      },
      editCategoriesField: function editCategoriesField(newField, field) {
        var categories = this.categories;
        var indexOfOldField = categories.indexOf(field);
        categories.removeObject(field);
        categories.insertAt(indexOfOldField, newField);
        this.updateVisualizationRowsField();
      },
      editTasksField: function editTasksField(newField, field) {
        var tasks = this.tasks;
        var indexOfOldField = tasks.indexOf(field);
        tasks.removeObject(field);
        tasks.insertAt(indexOfOldField, newField);
        this.updateVisualizationRowsField();
      },
      editStartField: function editStartField(newField, field) {
        var start = this.start;
        var indexOfOldField = start.indexOf(field);
        start.removeObject(field);
        start.insertAt(indexOfOldField, newField);
        this.updateVisualizationRowsField();
      },
      editEndField: function editEndField(newField, field) {
        var end = this.end;
        var indexOfOldField = end.indexOf(field);
        end.removeObject(field);
        end.insertAt(indexOfOldField, newField);
        this.updateVisualizationRowsField();
      }
    }
  });

  _exports.default = _default;
});