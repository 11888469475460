define("happyfox-bi/visualization-examples/mock-data/page-9", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PAGE_9_VISUALIZATION_DATA = _exports.PAGE_9 = void 0;
  var PAGE_9 = {
    id: 9,
    name: 'DELTA - Ticket Priority Distribution',
    order: 9,
    report: 2,
    created_by: 1,
    created_at: '2019-06-17T08:08:33Z',
    updated_at: '2019-06-18T11:20:30Z',
    visualizations: [{
      id: 35,
      name: 'QUICK ISSUE PRORITY BY ASSIGNEE',
      page: 9,
      display_type: 'data_table',
      display_settings: {
        size: 'square-m',
        columns: [{
          key: 'assignee',
          label: 'ASSIGNEE'
        }, {
          key: 'tickets',
          label: 'TICKETS COUNT'
        }]
      },
      preferences: {
        filters: {
          match_all: []
        },
        date_range: 'last_month'
      },
      settings: {
        fields: [{
          'name': 'assignee',
          'type': 'dataset_field',
          'dataset': 1,
          'dataset_field': 12,
          'data_type': 'text',
          'is_editable': false
        }, {
          'name': 'tickets',
          'type': 'aggregation',
          'aggregation_type': 'count',
          'dataset': 1,
          'dataset_field': 14,
          'data_type': 'decimal',
          'is_editable': false
        }],
        query: {
          rows: [],
          columns: ['assignee', 'tickets'],
          values: [],
          filters: {
            match_all: []
          },
          sort_by: []
        }
      }
    }],
    layout: {
      'items': [{
        'visualization_id': 35,
        'width': 8,
        'height': 8,
        'x': 8,
        'y': 8
      }]
    }
  };
  _exports.PAGE_9 = PAGE_9;
  var PAGE_9_VISUALIZATION_DATA = [{
    id: 35,
    data: [{
      'assignee': 'Randee Guynn',
      'tickets': 123
    }, {
      'assignee': 'Alisia Mcree',
      'tickets': 231
    }, {
      'assignee': 'Jackson Kiddy',
      'tickets': 299
    }, {
      'assignee': 'Krystal Magno',
      'tickets': 145
    }, {
      'assignee': 'Dorsey Conlee',
      'tickets': 99
    }, {
      'assignee': 'Jeffrey Wright',
      'tickets': 100
    }, {
      'assignee': 'Aaron Ramsay',
      'tickets': 45
    }, {
      'assignee': 'Theirry Henry',
      'tickets': 190
    }]
  }];
  _exports.PAGE_9_VISUALIZATION_DATA = PAGE_9_VISUALIZATION_DATA;
});