define("happyfox-bi/visualization-examples/mock-data/page-16", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PAGE_16_VISUALIZATION_DATA = _exports.PAGE_16 = void 0;
  var PAGE_16 = {
    id: 16,
    name: 'Tickets information',
    description: '',
    order: 16,
    report: 3,
    created_by: 1,
    created_at: '2019-06-17T08:08:33Z',
    updated_at: '2019-06-18T11:20:30Z',
    visualizations: [{
      id: 50,
      name: '',
      page: 16,
      display_type: 'data_tile',
      display_settings: {
        static_label: 'Best AVG Response Time',
        label: 'agent',
        label_position: 'top',
        focus: 'value',
        value: 'duration',
        size: 'square-s',
        value_postfix: 'hrs'
      },
      preferences: {
        filters: {
          match_all: []
        },
        date_range: 'last_month'
      },
      settings: {
        fields: [{
          'name': 'agent',
          'type': 'dataset_field',
          'dataset': 1,
          'dataset_field': 12,
          'data_type': 'text',
          'is_editable': false
        }, {
          'name': 'duration',
          'type': 'aggregation',
          'aggregation_type': 'count',
          'dataset': 1,
          'dataset_field': 14,
          'data_type': 'decimal',
          'is_editable': false
        }],
        query: {
          rows: ['agent'],
          columns: [],
          values: ['duration'],
          filters: {
            match_all: []
          },
          sort_by: []
        }
      }
    }, {
      id: 51,
      name: '',
      page: 16,
      display_type: 'data_tile',
      display_settings: {
        static_label: 'Most Tickets Raised',
        label: 'tickets',
        focus: 'label',
        value: 'agent',
        size: 'square-s',
        label_position: 'bottom'
      },
      preferences: {
        filters: {
          match_all: []
        },
        date_range: 'last_month'
      },
      settings: {
        fields: [{
          'name': 'agent',
          'type': 'dataset_field',
          'dataset': 1,
          'dataset_field': 12,
          'data_type': 'text',
          'is_editable': false
        }, {
          'name': 'tickets',
          'type': 'aggregation',
          'aggregation_type': 'count',
          'dataset': 1,
          'dataset_field': 14,
          'data_type': 'decimal',
          'is_editable': false
        }],
        query: {
          rows: ['agent'],
          columns: [],
          values: ['tickets'],
          filters: {
            match_all: []
          },
          sort_by: []
        }
      }
    }, {
      id: 52,
      name: '',
      page: 16,
      display_type: 'data_tile',
      display_settings: {
        static_label: 'Busiest Hour',
        label: 'tickets',
        value: 'hourOfDay',
        focus: 'value',
        size: 'square-s',
        value_postfix: 'PM',
        label_position: 'bottom'
      },
      preferences: {
        filters: {
          match_all: []
        },
        date_range: 'last_month'
      },
      settings: {
        fields: [{
          'name': 'hourOfDay',
          'type': 'dataset_field',
          'dataset': 1,
          'dataset_field': 12,
          'data_type': 'time',
          'is_editable': false
        }, {
          'name': 'tickets',
          'type': 'aggregation',
          'aggregation_type': 'count',
          'dataset': 1,
          'dataset_field': 14,
          'data_type': 'decimal',
          'is_editable': false
        }],
        query: {
          rows: ['hourOfDay'],
          columns: [],
          values: ['tickets'],
          filters: {
            match_all: []
          }
        }
      }
    }, {
      id: 53,
      name: 'Tickets status by Assignee ',
      page: 16,
      display_type: 'multi_area_chart',
      display_settings: {
        size: 'horizontal-rectangle-l',
        x_axis: {
          key: 'assignee',
          label: 'Assignee'
        },
        y_axis: {
          label: 'Tickets count',
          group_by: null
        },
        groups: [{
          key: 'ticketsCreated',
          label: 'Tickets Created'
        }, {
          key: 'ticketsClosed',
          label: 'Tickets Closed'
        }]
      },
      preferences: {
        filters: {
          match_all: []
        },
        date_range: 'last_month'
      },
      settings: {
        fields: [{
          'name': 'assignee',
          'type': 'dataset_field',
          'dataset': 1,
          'dataset_field': 12,
          'data_type': 'text',
          'is_editable': false
        }, {
          'name': 'ticketsCreated',
          'type': 'aggregation',
          'aggregation_type': 'count',
          'dataset': 1,
          'dataset_field': 13,
          'data_type': 'decimal',
          'is_editable': false
        }, {
          'name': 'ticketsClosed',
          'type': 'aggregation',
          'aggregation_type': 'count',
          'dataset': 1,
          'dataset_field': 14,
          'data_type': 'decimal',
          'is_editable': false
        }],
        query: {
          rows: [],
          columns: [],
          values: [],
          filters: {
            match_all: []
          },
          sort_by: []
        }
      }
    }],
    layout: {
      'items': [{
        x: 0,
        y: 4,
        width: 4,
        height: 4,
        visualization_id: 50
      }, {
        x: 4,
        y: 4,
        width: 4,
        height: 4,
        visualization_id: 51
      }, {
        x: 8,
        y: 4,
        width: 4,
        height: 4,
        visualization_id: 52
      }, {
        x: 0,
        y: 8,
        width: 16,
        height: 8,
        visualization_id: 53
      }]
    }
  };
  _exports.PAGE_16 = PAGE_16;
  var PAGE_16_VISUALIZATION_DATA = [{
    id: 50,
    data: [{
      'agent': 'John',
      'duration': 0.35
    }]
  }, {
    id: 51,
    data: [{
      'agent': 'Kevin',
      'tickets': 35
    }]
  }, {
    id: 52,
    data: [{
      'hour_of_day': '09',
      'tickets': 25
    }]
  }, {
    id: 53,
    data: [{
      'assignee': 'John',
      'ticketsCreated': 300,
      'ticketsClosed': 100
    }, {
      'assignee': 'Arya',
      'ticketsCreated': 200,
      'ticketsClosed': 150
    }, {
      'assignee': 'Dev',
      'ticketsCreated': 450,
      'ticketsClosed': 250
    }, {
      'assignee': 'Kevin',
      'ticketsCreated': 280,
      'ticketsClosed': 120
    }, {
      'assignee': 'Dylan',
      'ticketsCreated': 250,
      'ticketsClosed': 180
    }, {
      'assignee': 'Bob',
      'ticketsCreated': 700,
      'ticketsClosed': 450
    }, {
      'assignee': 'Bing',
      'ticketsCreated': 400,
      'ticketsClosed': 190
    }, {
      'assignee': 'Buffay',
      'ticketsCreated': 100,
      'ticketsClosed': 70
    }, {
      'assignee': 'Adams',
      'ticketsCreated': 660,
      'ticketsClosed': 200
    }, {
      'assignee': 'Joe',
      'ticketsCreated': 330,
      'ticketsClosed': 130
    }]
  }];
  _exports.PAGE_16_VISUALIZATION_DATA = PAGE_16_VISUALIZATION_DATA;
});