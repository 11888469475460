define("happyfox-bi/components/collapsible-panels/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GgoyO2r7",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,2],null,[[\"panel\"],[[30,[36,1],[\"collapsible-panels/collapsible-panel\"],[[\"group\"],[[35,0]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"group\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "happyfox-bi/components/collapsible-panels/template.hbs"
    }
  });

  _exports.default = _default;
});