define("happyfox-bi/reports/details/pages/details/utilities", ["exports", "happyfox-bi/utilities/time"], function (_exports, _time) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getDateRanges = getDateRanges;
  _exports.getTemporaryId = getTemporaryId;

  // FIXME: Not used. Date range is removed temporarily
  function getDateRanges() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
      excludeToday: false
    };
    var now = (0, _time.getCurrentDateTime)();
    var excludeToday = options.excludeToday;
    var dataRanges = [];

    if (!excludeToday) {
      dataRanges.push({
        id: 'today',
        label: 'date-ranges.today',
        value: [moment(now), moment(now)]
      });
    }

    dataRanges = dataRanges.concat([{
      id: 'yesterday',
      label: 'date-ranges.yesterday',
      value: [moment(now).subtract(1, 'days'), moment(now).subtract(1, 'days')]
    }, {
      id: 'last_seven_days',
      label: 'date-ranges.last-7-days',
      value: excludeToday ? [moment(now).subtract(7, 'days'), moment(now).subtract(1, 'days')] : [moment(now).subtract(6, 'days'), moment(now)]
    }, {
      id: 'last_fourteen_days',
      label: 'date-ranges.last-14-days',
      value: excludeToday ? [moment(now).subtract(14, 'days'), moment(now).subtract(1, 'days')] : [moment(now).subtract(13, 'days'), moment(now)]
    }, {
      id: 'last_thirty_days',
      label: 'date-ranges.last-30-days',
      value: excludeToday ? [moment(now).subtract(30, 'days'), moment(now).subtract(1, 'days')] : [moment(now).subtract(29, 'days'), moment(now)]
    }, {
      id: 'last_three_sixty_five_days',
      label: 'date-ranges.last-365-days',
      value: excludeToday ? [moment(now).subtract(365, 'days'), moment(now).subtract(1, 'days')] : [moment(now).subtract(364, 'days'), moment(now)]
    }]);
    var endDateTime = excludeToday ? moment(now).subtract(1, 'days') : moment(now);
    var thisWeekStartTime = moment(now).startOf('isoweek');

    if (endDateTime >= thisWeekStartTime) {
      dataRanges.push({
        id: 'this_week',
        label: 'date-ranges.this-week',
        value: [thisWeekStartTime, endDateTime]
      });
    }

    var thisMonthStartTime = moment(now).startOf('month');

    if (endDateTime >= thisMonthStartTime) {
      dataRanges.push({
        id: 'this_month',
        label: 'date-ranges.this-month',
        value: [thisMonthStartTime, endDateTime]
      });
    }

    var thisYearStartTime = moment(now).startOf('year');

    if (endDateTime >= thisYearStartTime) {
      dataRanges.push({
        id: 'this_year',
        label: 'date-ranges.this-year',
        value: [thisYearStartTime, endDateTime]
      });
    }

    return dataRanges.concat([{
      id: 'last_week',
      label: 'date-ranges.last-week',
      value: [moment(now).subtract(1, 'week').startOf('isoweek'), moment(now).subtract(1, 'week').endOf('isoweek')]
    }, {
      id: 'last_month',
      label: 'date-ranges.last-month',
      value: [moment(now).subtract(1, 'month').startOf('month'), moment(now).subtract(1, 'month').endOf('month')]
    }, {
      id: 'last_year',
      label: 'date-ranges.last-year',
      value: [moment(now).subtract(1, 'year').startOf('year'), moment(now).subtract(1, 'year').endOf('year')]
    }]);
  }

  function getTemporaryId(id) {
    var date = new Date();
    return "".concat(id, "-").concat(date.valueOf());
  }
});