define("happyfox-bi/utilities/object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.camelizeObject = camelizeObject;
  _exports.cloneArray = cloneArray;
  _exports.getKeysFromObject = getKeysFromObject;
  _exports.hasProperty = hasProperty;
  _exports.isArrayOrObject = isArrayOrObject;
  _exports.isPromise = isPromise;
  _exports.underscoreObject = underscoreObject;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function isArrayOrObject(object) {
    return _typeof(object) === 'object';
  }

  function isPromise(object) {
    return !!object && (_typeof(object) === 'object' || typeof object === 'function') && typeof object.then === 'function';
  }

  function recursivelyConvert(object, type) {
    var convertedObject, actualKey, convertedKey, value;

    if (Ember.isArray(object) && Ember.isPresent(object)) {
      if (type === 'camelize') {
        convertedObject = Ember.A();
      } else {
        convertedObject = [];
      }

      for (actualKey in object) {
        value = object[actualKey];

        if (object.includes(value)) {
          if (isArrayOrObject(value) && !_.isEmpty(value)) {
            value = recursivelyConvert(value, type);
          }

          if (type === 'camelize') {
            convertedObject.addObject(value);
          } else {
            convertedObject.push(value);
          }
        }
      }
    } else if (_typeof(object) === 'object' && !_.isEmpty(object) && !Ember.String.isHTMLSafe(object)) {
      if (type === 'camelize') {
        convertedObject = Ember.Object.create();
      } else {
        convertedObject = {};
      }

      for (actualKey in object) {
        if (Object.prototype.hasOwnProperty.call(object, actualKey)) {
          if (type === 'camelize') {
            convertedKey = actualKey.camelize();
          } else {
            if (actualKey.indexOf('-') === -1) {
              convertedKey = actualKey.underscore();
            } else {
              convertedKey = actualKey;
            }
          }

          value = object[actualKey];

          if (!_.isEmpty(value) && isArrayOrObject(value)) {
            value = recursivelyConvert(value, type);
          }

          if (type === 'camelize') {
            convertedObject.set(convertedKey, value);
          } else {
            convertedObject[convertedKey] = value;
          }
        }
      }
    } else {
      return object;
    }

    return convertedObject;
  }

  function camelizeObject(object) {
    return recursivelyConvert(object, 'camelize');
  }

  function underscoreObject(object) {
    return recursivelyConvert(object, 'underscore');
  }

  function hasProperty(object, property) {
    return Ember.Object.create(object).hasOwnProperty(property);
  }

  function getKeysFromObject(object) {
    var keys = Object.keys(object);
    return keys.removeObject('toString');
  }

  function cloneArray(array) {
    if (Ember.isArray(array)) {
      return array.map(function (object) {
        if (isArrayOrObject(object)) {
          var newObject = Object.assign(Ember.Object.create(), object);
          return newObject;
        } else {
          return object;
        }
      });
    }
  }
});