define("happyfox-bi/application/top-bar/theme-selection/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var THEMES = [{
    app: 'light',
    name: 'theme-light',
    charts: 'light'
  }, {
    app: 'dark',
    name: 'theme-dark',
    charts: 'dark'
  }];
  var DEFAULT_THEME = {
    app: 'dark',
    name: 'theme-dark',
    charts: 'dark'
  };

  var _default = Ember.Component.extend({
    classNames: ['theme-selection'],
    isOpen: false,
    theme: null,
    themeOptions: THEMES,
    currentTheme: Ember.computed('theme.{app,charts}', {
      get: function get() {
        var theme = Ember.isPresent(this.get('theme.app')) ? this.get('theme') : DEFAULT_THEME;
        return this.get('themeOptions').findBy('app', theme.app);
      }
    }),
    actions: {
      selectTheme: function selectTheme(selectedTheme) {
        if (!_.isEqual(this.get('currentTheme'), selectedTheme)) {
          this.onChangeTheme(selectedTheme);
        }
      }
    }
  });

  _exports.default = _default;
});