define("happyfox-bi/data-sources/components/table-cell/data-type/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    value: null,
    translationKey: Ember.computed('value', {
      get: function get() {
        var translationKey = "".concat(this.get('value'), "-data-type");
        return translationKey;
      }
    })
  });

  _exports.default = _default;
});