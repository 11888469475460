define("happyfox-bi/apps/details/happyfox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YY8E7f2e",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],null,[[\"app\",\"fields\",\"hideButtons\"],[[35,1],[35,0],true]]]]],\"hasEval\":false,\"upvars\":[\"fields\",\"app\",\"apps/details/form-content\"]}",
    "meta": {
      "moduleName": "happyfox-bi/apps/details/happyfox/template.hbs"
    }
  });

  _exports.default = _default;
});