define("happyfox-bi/data-sources/details/data-sets/import/file-meta/component", ["exports", "ember-light-table", "happyfox-bi/mixins/table", "happyfox-bi/utilities/object"], function (_exports, _emberLightTable, _table, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var IMPORT_NEW = 'import-new';
  var IMPORT_SUCCESS = 'import-success';
  var IMPORT_FAILURE = 'import-failure';

  var _default = Ember.Component.extend(_table.default, {
    fileMeta: null,
    dataSetId: null,
    dataSourceId: null,
    fields: Ember.computed.reads('fileMeta.fields'),
    sampleData: Ember.computed.reads('fileMeta.sample_data'),
    existingDataCount: Ember.computed.reads('fileMeta.existing_data_count'),
    importDataCount: Ember.computed.reads('fileMeta.import_data_count'),
    showImportConfirmation: false,
    isImportInProgress: false,
    state: IMPORT_NEW,
    errors: null,
    isNewImport: Ember.computed.equal('state', IMPORT_NEW),
    isImportSuccess: Ember.computed.equal('state', IMPORT_SUCCESS),
    isImportFailed: Ember.computed.equal('state', IMPORT_FAILURE),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.createSampleDataTable();
    },
    createSampleDataTable: function createSampleDataTable() {
      var columns = Ember.A();
      this.get('fields').forEach(function (field) {
        var column = {
          valuePath: field.get('displayName'),
          label: field.get('displayName'),
          component: 'data-table/column',
          minResizeWidth: 100
        };
        columns.addObject(column);
      });

      if (this.$('.js-sample-data-table').width() / columns.length < 100) {
        columns.setEach('width', '100px');
      }

      this.set('sampleDataColumns', columns);

      var table = _emberLightTable.default.create({
        columns: columns,
        rows: this.get('sampleData')
      });

      this.set('sampleDataTable', table);
    },
    handleFileUploadErrors: function handleFileUploadErrors(errors) {
      var _this = this;

      errors = (0, _object.camelizeObject)(errors);
      var uploadErrors = Ember.A();

      if (errors.file) {
        uploadErrors.addObject(errors.file);
      }

      if (errors.datasetId) {
        uploadErrors.addObject(errors.datasetId);
      }

      if (errors.errors) {
        if (errors.errors.fields) {
          var fieldsErrors = errors.errors.fields;
          fieldsErrors.forEach(function (errorObject) {
            _this.get('fields').findBy('displayName', errorObject.displayName).set('errors', errorObject.errors);
          });
        } else {
          this.get('fields').setEach('errors', null);
          uploadErrors.addObject(errors.errors);
        }
      }

      this.setProperties({
        errors: uploadErrors,
        state: IMPORT_FAILURE
      });
    },
    actions: {
      showImportConfirmationDialog: function showImportConfirmationDialog() {
        this.set('showImportConfirmation', true);
      },
      hideImportConfirmationDialog: function hideImportConfirmationDialog() {
        this.set('showImportConfirmation', false);
      },
      startImport: function startImport() {
        var _this2 = this;

        var fields = this.get('fields');
        this.set('isImportInProgress', true);
        this.uploadFile(fields).then(function () {
          _this2.setProperties({
            state: IMPORT_SUCCESS,
            errors: null
          });
        }).catch(function (_ref) {
          var errors = _ref.errors;

          _this2.handleFileUploadErrors(errors);
        }).finally(function () {
          _this2.set('isImportInProgress', false);
        });
      },
      removeFile: function removeFile() {
        this.removeFile();
      }
    }
  });

  _exports.default = _default;
});