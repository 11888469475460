define("happyfox-bi/serializers/data-set-field", ["exports", "happyfox-bi/serializers/base", "happyfox-bi/components/base-chart/constants"], function (_exports, _base, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    normalize: function normalize(modelClass, hash, prop) {
      var dataType = hash['data_type'];
      var isCurrencyType = dataType == 'currency';
      var isCurrencyFormat = _constants.FIELD_TYPES_FORMATTED_AS_CURRENCY.includes(dataType) && hash['format'] == 'currency';

      if (isCurrencyType || isCurrencyFormat) {
        hash['configuration'] = {};
        hash['configuration']['format'] = hash['format'];
        hash['configuration']['currencySign'] = hash['currency_sign'];
        hash['configuration']['currencyFormat'] = hash['currency_format'];
        hash['configuration']['currencyUnit'] = hash['currency_unit'];
      }

      if (hash['can_format_as_currency']) {
        // Whether field can be allowed to format as currency
        hash['configuration'] = hash['configuration'] || {};
        hash['configuration']['canFormatAsCurrency'] = hash['can_format_as_currency'];
      }

      return this._super(modelClass, hash, prop);
    }
  });

  _exports.default = _default;
});