define("happyfox-bi/visualizations/new/components/display-settings/pivot-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QlV9lHdp",
    "block": "{\"symbols\":[\"type\"],\"statements\":[[6,[37,9],[[30,[36,8],[[35,3,[\"tableAsPivotTable\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"field toggle-field\"],[12],[2,\"\\n    \"],[10,\"div\"],[12],[1,[30,[36,1],[\"include-totals\"],null]],[13],[2,\"\\n    \"],[1,[30,[36,4],[\"toggle-input\"],[[\"tagName\",\"isChecked\",\"onChange\"],[\"div\",[35,3,[\"showTotals\"]],[30,[36,2],[[32,0],\"onShowTotalsChange\"],null]]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"field toggle-field\"],[12],[2,\"\\n    \"],[10,\"div\"],[12],[1,[30,[36,1],[\"include-averages\"],null]],[13],[2,\"\\n    \"],[1,[30,[36,4],[\"toggle-input\"],[[\"tagName\",\"isChecked\",\"onChange\"],[\"div\",[35,3,[\"showAverages\"]],[30,[36,2],[[32,0],\"onShowAveragesChange\"],null]]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"field\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"field_label\"],[12],[1,[30,[36,1],[\"include-percentages\"],null]],[13],[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n\"],[6,[37,7],null,[[\"tagName\",\"class\",\"dropdownClass\",\"options\",\"selected\",\"onChange\",\"searchEnabled\",\"searchField\",\"renderInPlace\"],[\"div\",\"mod-dropdown mod-local-autocomplete field_value\",\"mod-push-dropdown-up\",[35,6],[35,5],[30,[36,2],[[32,0],\"onPercentageTypeChange\"],null],true,\"name\",true]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[32,1,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"visualizations/new/components/display-settings/empty-state\",\"t\",\"action\",\"settings\",\"component\",\"selectedPercentageOption\",\"includePercentagesOptions\",\"power-select\",\"not\",\"if\"]}",
    "meta": {
      "moduleName": "happyfox-bi/visualizations/new/components/display-settings/pivot-table/template.hbs"
    }
  });

  _exports.default = _default;
});