define("happyfox-bi/internal-login/user-details/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    dataService: Ember.inject.service('data'),
    email: null,
    secretApiKey: null,
    isLoginInProgress: false,
    showError: false,
    disableSubmit: Ember.computed('isLoginInProgress', 'email', 'secretApiKey', {
      get: function get() {
        var email = this.get('email');
        var secretApiKey = this.get('secretApiKey');
        var hasRequiredDetails = Ember.isPresent(email) && Ember.isPresent(secretApiKey);
        return this.get('isLoginInProgress') || !hasRequiredDetails;
      }
    }),
    actions: {
      login: function login() {
        var _this = this;

        this.setProperties({
          isLoginInProgress: true,
          showError: false
        });
        var email = this.get('email');
        var secretApiKey = this.get('secretApiKey');
        var data = {
          secretApiKey: secretApiKey,
          email: email
        };
        this.get('dataService').internalLogin(data).then(function () {
          _this.bootApp();
        }).catch(function () {
          _this.set('showError', true);
        }).finally(function () {
          _this.set('isLoginInProgress', false);
        });
      }
    }
  });

  _exports.default = _default;
});