define("happyfox-bi/manage/reports/users-access-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AVMeH2NZ",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"data-table-new/table-pop-over\",[],[[\"@values\"],[[32,0,[\"reportModel\",\"users\"]]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,0],[\"all-users\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"onlyCustomUsersHasAccess\",\"if\"]}",
    "meta": {
      "moduleName": "happyfox-bi/manage/reports/users-access-cell/template.hbs"
    }
  });

  _exports.default = _default;
});