define("happyfox-bi/components/add-visualization-field/bucket-values-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tOm0Kwjx",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[6,[37,5],[[35,9]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"td\"],[12],[1,[30,[36,3],null,[[\"class\",\"value\"],[\"field_value mod-bucket-values\",[35,2]]]]],[13],[2,\"\\n  \"],[10,\"td\"],[14,\"colspan\",\"2\"],[12],[1,[34,8]],[13],[2,\"\\n  \"],[10,\"td\"],[12],[13],[2,\"\\n  \"],[10,\"td\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"td\"],[12],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"class\",\"value\"],[\"field_value mod-bucket-values\",[35,2,[\"label\"]]]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"td\"],[12],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"class\",\"value\"],[\"field_value mod-bucket-values\",[35,2,[\"min\"]]]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"td\"],[12],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"class\",\"value\"],[\"field_value mod-bucket-values\",[35,2,[\"max\"]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,5],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"td\"],[12],[2,\"\\n      \"],[11,\"div\"],[24,\"role\",\"button\"],[24,0,\"bucket-values_item-remove icon-btn\"],[4,[38,0],[[32,0],\"onDelete\"],null],[12],[1,[30,[36,1],[\"trash\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"td\"],[15,\"colspan\",[30,[36,6],[[35,4],\"2\"],null]],[12],[2,\"\\n\"],[6,[37,5],[[35,7]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"div\"],[24,\"role\",\"button\"],[24,0,\"bucket-values_item-add icon-btn\"],[4,[38,0],[[32,0],\"onAdd\"],null],[12],[1,[30,[36,1],[\"plus\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"inline-svg\",\"bucketValue\",\"input\",\"canShowDelete\",\"if\",\"unless\",\"canShowAdd\",\"defaultText\",\"isDefault\"]}",
    "meta": {
      "moduleName": "happyfox-bi/components/add-visualization-field/bucket-values-item/template.hbs"
    }
  });

  _exports.default = _default;
});