define("happyfox-bi/visualization-examples/mock-data/page-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PAGE_4_VISUALIZATION_DATA = _exports.PAGE_4 = void 0;
  var PAGE_4 = {
    id: 4,
    name: 'EMEA: MoM Tickets by Product',
    order: 4,
    report: 1,
    created_by: 1,
    created_at: '2019-06-17T08:08:33Z',
    updated_at: '2019-06-18T11:20:30Z',
    visualizations: [{
      id: 1111,
      name: '',
      page: 4,
      display_type: 'line_chart',
      display_settings: {
        size: 'square-s',
        x_axis: {
          key: 'date',
          label: null
        },
        y_axis: {
          key: 'value',
          label: null
        },
        showAsMinimalistic: false
      },
      preferences: {
        filters: {
          match_all: []
        },
        date_range: 'last_month'
      },
      settings: {
        fields: [{
          'name': 'date',
          'type': 'dataset_field',
          'dataset': 1,
          'dataset_field': 12,
          'data_type': 'timestamp',
          'format': 'date',
          'is_editable': false
        }, {
          'name': 'value',
          'type': 'aggregation',
          'aggregation_type': 'count',
          'dataset': 1,
          'dataset_field': 14,
          'data_type': 'decimal',
          'is_editable': false
        }],
        query: {
          rows: ['date'],
          columns: [],
          values: ['value'],
          filters: {
            match_all: []
          },
          sort_by: []
        }
      }
    }],
    layout: {
      'items': [{
        'visualization_id': 1111,
        'width': 4,
        'height': 4,
        'x': 0,
        'y': 4
      }]
    }
  };
  _exports.PAGE_4 = PAGE_4;
  var PAGE_4_VISUALIZATION_DATA = [{
    id: 1111,
    data: [{
      'date': '2018-07-01T00:00:00Z',
      'value': 100
    }, {
      'date': '2018-07-02T00:00:00Z',
      'value': 150
    }, {
      'date': '2018-07-03T00:00:00Z',
      'value': 220
    }, {
      'date': '2018-07-04T00:00:00Z',
      'value': 180
    }, {
      'date': '2018-07-05T00:00:00Z',
      'value': 220
    }, {
      'date': '2018-07-06T00:00:00Z',
      'value': 280
    }, {
      'date': '2018-07-07T00:00:00Z',
      'value': 320
    }]
  }];
  _exports.PAGE_4_VISUALIZATION_DATA = PAGE_4_VISUALIZATION_DATA;
});