define("happyfox-bi/page-screenshot/grid-layout/component", ["exports", "happyfox-bi/utilities/run-loop"], function (_exports, _runLoop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    visualizationsToResize: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('visualizationsToResize', Ember.A());
    },
    actions: {
      getVisualizationData: function getVisualizationData(data) {
        return this.getVisualizationData(data);
      },
      onDataLoad: function onDataLoad(visualizationId) {
        var _this = this;

        // Once data is loaded, for data table visualizations, the height of the visualization tile needs to be
        // increased so that there is no scroll for table content to take screenshots for report subscriptions.
        var visualization = this.get('visualizations').findBy('id', visualizationId);

        if (visualization.displayType === 'data_table') {
          this.get('visualizationsToResize').pushObject(visualizationId); // Used run later to make sure the table data is rendered

          (0, _runLoop.runLater)(this, function () {
            try {
              var tileSelector = "[data-gs-visualization-id=".concat(visualizationId, "]");

              var currentScrollViewHeight = _this.$("".concat(tileSelector, " .ember-light-table .lt-body-wrap .tse-scrollable")).outerHeight();

              var totalScrollHeight = _this.$("".concat(tileSelector, " .ember-light-table .lt-body-wrap .tse-scrollable .tse-scroll-content"))[0].scrollHeight;

              var heightToIncrease = totalScrollHeight - currentScrollViewHeight;

              if (heightToIncrease > 0) {
                var currentTileHeight = _this.$(tileSelector).innerHeight();

                var gridCellHeight = _this.$('.grid-stack').data('gridstack').cellHeight();

                var gridCellVerticalMargin = _this.$('.grid-stack').data('gridstack').verticalMargin();

                var targetTileHeight = currentTileHeight + heightToIncrease; // When a tile is spread across multiple grid rows, it also occupies the margin in between those rows, but not for
                // the last row. Added 1 extra grid row for safety when we show message if we are not showing all table rows.

                var targetRows = Math.ceil((targetTileHeight + gridCellVerticalMargin) / (gridCellHeight + gridCellVerticalMargin)) + 1;

                _this.$('.grid-stack').data('gridstack').resize(_this.$(tileSelector)[0], null, targetRows);
              }

              _this.get('visualizationsToResize').removeObject(visualizationId);
            } catch (error) {
              _this.get('visualizationsToResize').removeObject(visualizationId);
              /* eslint-disable no-console */


              console.error(error);
              /* eslint-enable no-console */
            }
          }, 100);
        }
      }
    }
  });

  _exports.default = _default;
});