define("happyfox-bi/apps/details/aircall/component", ["exports", "happyfox-bi/mixins/manage-apps"], function (_exports, _manageApps) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_manageApps.default, {
    intlService: Ember.inject.service('intl'),
    app: null,
    actions: {
      addAircallAccount: function addAircallAccount() {
        window.location = window.location.origin + '/integrations/aircall/initiate-oauth/';
      }
    }
  });

  _exports.default = _default;
});