define("happyfox-bi/components/pop-over/content/component", ["exports", "@happyfoxinc/hf-pop-over/components/pop-over/content", "happyfox-bi/config/environment"], function (_exports, _content, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _content.default.extend({
    intlService: Ember.inject.service('intl'),
    closeLabelText: '',
    isTestEnvironment: _environment.default.environment === 'test',
    destination: Ember.computed('isTestEnvironment', {
      get: function get() {
        if (!this.isTestEnvironment) {
          return 'body';
        } else {
          return 'ember-testing';
        }
      }
    }),
    init: function init() {
      this._super();

      this.closeLabelText = this.intlService.lookup('close');
    }
  });

  _exports.default = _default;
});