define("happyfox-bi/components/multi-area-chart/component", ["exports", "happyfox-bi/components/multi-line-chart/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    updateConfig: function updateConfig() {
      this._super.apply(this, arguments);

      this.set('isArea', true);
    }
  });

  _exports.default = _default;
});