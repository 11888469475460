define("happyfox-bi/application/top-bar/global-search/component", ["exports", "fuse.js"], function (_exports, _fuse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    dataService: Ember.inject.service('data'),
    routerService: Ember.inject.service('router'),
    reports: null,
    searchText: null,
    fuzzySearchResults: null,
    noResultsFound: false,
    init: function init() {
      this._super.apply(this, arguments);

      var reports = this.get('dataService').peekAllReports();
      this.set('reports', reports);
    },
    click: function click() {
      this.$('input.js-global-search-text-input').focus();
    },
    keyDown: function keyDown(event) {
      if (event.keyCode === 27) {
        this.send('clear');
      }
    },
    hasContentToDisplay: Ember.computed('fuzzySearchResults', 'noResultsFound', {
      get: function get() {
        return Ember.isPresent(this.get('fuzzySearchResults')) || this.get('noResultsFound');
      }
    }),
    performFuzzySearch: function performFuzzySearch() {
      this.set('noResultsFound', false);
      var searchText = this.get('searchText');

      if (Ember.isPresent(searchText)) {
        searchText = searchText.toLowerCase();
        var searchList = Ember.A();
        this.get('reports').forEach(function (report) {
          report.pages.forEach(function (page) {
            var searchListItem = {
              reportId: report.id,
              reportName: report.name,
              pageId: page.id,
              pageName: page.name
            };
            searchList.pushObject(searchListItem);
          });
        });
        var options = {
          includeScore: true,
          keys: ['reportName', 'pageName']
        };
        var fuse = new _fuse.default(searchList, options);
        var result = fuse.search(searchText);
        var searchResults = result.sortBy('score').mapBy('item');
        var fuzzySearchResults = [];
        searchResults.forEach(function (result) {
          var reportGroup = fuzzySearchResults.findBy('id', result.reportId);

          if (reportGroup) {
            reportGroup.pages.push({
              id: result.pageId,
              name: result.pageName
            });
          } else {
            reportGroup = {
              id: result.reportId,
              name: result.reportName,
              pages: [{
                id: result.pageId,
                name: result.pageName
              }]
            };
            fuzzySearchResults.push(reportGroup);
          }
        });
        this.set('noResultsFound', Ember.isEmpty(fuzzySearchResults));
        this.set('fuzzySearchResults', fuzzySearchResults);
      } else {
        this.set('fuzzySearchResults', null);
      }
    },
    actions: {
      onSearchTextChange: function onSearchTextChange() {
        Ember.run.debounce(this, this.performFuzzySearch, 400);
      },
      goToReport: function goToReport(id) {
        this.get('routerService').transitionTo('reports.details', id);
        this.send('clear');
      },
      goToPage: function goToPage(reportId, pageId) {
        this.get('routerService').transitionTo('reports.details.pages.details', reportId, pageId);
        this.send('clear');
      },
      clear: function clear() {
        this.setProperties({
          fuzzySearchResults: null,
          searchText: null,
          noResultsFound: false
        });
      }
    }
  });

  _exports.default = _default;
});