define("happyfox-bi/components/form-field-input/email/component", ["exports", "happyfox-bi/components/form-field-input/text/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend();

  _exports.default = _default;
});