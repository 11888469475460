define("happyfox-bi/components/table-bulk-selection-header/component", ["exports", "happyfox-bi/mixins/bulk-selection-item"], function (_exports, _bulkSelectionItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_bulkSelectionItem.default, {
    table: null,
    'data-test-id': 'bulk-selection-header',
    isAllSelected: Ember.computed('table.{selectedRows.[],rows.@each.selected}', {
      get: function get() {
        if (this.get('table.rows')) {
          return this.get('table.selectedRows.length') === this.get('table.rows').get('length');
        }
      },
      // TODO: Can be moved to normal action
      set: function set(key, value) {
        this.get('table.rows').setEach('selected', value);
        return value;
      }
    }),
    isAnySelected: Ember.computed.notEmpty('table.selectedRows')
  });

  _exports.default = _default;
});