define("happyfox-bi/components/data-table/component", ["exports", "happyfox-bi/components/base-chart/component", "ember-light-table", "happyfox-bi/mixins/table", "happyfox-bi/utilities/array", "happyfox-bi/utilities/time", "happyfox-bi/constants"], function (_exports, _component, _emberLightTable, _table, _array, _time, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var DEFAULT_RECORDS_LIMIT = 100;
  var DEFAULT_RECORDS_LIMIT_IN_NON_INTERACTIVE_MODE = 2000;

  var _default = _component.default.extend(_table.default, {
    classNames: ['chart', 'data-table'],
    table: null,
    isScrolledToBottom: false,
    maxRowsToDisplay: Ember.computed('config.maxRowsToDisplay', {
      get: function get() {
        var maxRowsToDisplay = this.getConfigValue('maxRowsToDisplay', DEFAULT_RECORDS_LIMIT);

        if (this.get('isNonInteractiveMode')) {
          maxRowsToDisplay = DEFAULT_RECORDS_LIMIT_IN_NON_INTERACTIVE_MODE;
        }

        return maxRowsToDisplay;
      }
    }),
    isRecordsLimited: Ember.computed('maxRowsToDisplay', 'data', {
      get: function get() {
        return this.data.length > this.maxRowsToDisplay;
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      var data = this.data,
          maxRowsToDisplay = this.maxRowsToDisplay;
      var tableData = data.length > maxRowsToDisplay ? data.slice(0, maxRowsToDisplay) : data;
      this.set('tableData', tableData);
      var columns = this.getColumnsConfiguration();

      var table = _emberLightTable.default.create({
        columns: columns,
        rows: tableData
      });

      var rows = table.rows;
      this.setProperties({
        rows: rows,
        columns: columns,
        table: table
      });
    },

    /* Overriding sortDataByDateAndTimestampFields() from Base chart as we want to sort by date/timestamp fields only when there are no sorting options */
    sortDataByDateAndTimestampFields: function sortDataByDateAndTimestampFields(data, fields) {
      var visualization = this.get('widgetModel');
      var query = visualization.get('settings.query');

      if (!Ember.isPresent(query.sortBy)) {
        this._super(data, fields);
      }
    },
    transformValuesInData: function transformValuesInData() {
      var data = this.get('data');
      this.formatDateAndTimeStampDataFields(data);
    },
    getColumnsConfiguration: function getColumnsConfiguration() {
      var _this = this;

      var tableColumns = [];
      var visualization = this.get('widgetModel');
      var columns = this.config.columns;
      var conditionalFormatting = this.config.conditionalFormatting || [];
      (columns || []).forEach(function (column) {
        var columnField = visualization.get('settings.fields').findBy('name', column.key);

        var _conditionalFormattin = conditionalFormatting.filter(function (format) {
          return format && format.field.name === column.key;
        }),
            _conditionalFormattin2 = _slicedToArray(_conditionalFormattin, 1),
            conditionalFormatForColumn = _conditionalFormattin2[0];

        var columnProperties = {
          valuePath: column.key,
          resizable: true,
          sortable: true,
          minResizeWidth: 100,
          label: column.label,
          component: 'data-table/column',
          cellComponent: conditionalFormatForColumn ? 'data-table/data-bar-cell' : 'data-table/cell',
          extra: {
            columnField: columnField
          }
        };

        if (conditionalFormatForColumn) {
          columnProperties.extra.dataBar = Ember.Object.create(_objectSpread({}, conditionalFormatForColumn));
        }

        if (_constants.NUMBER_TYPE_FIELDS.includes(columnField.dataType)) {
          columnProperties.cellClassNames = 'align-right';
        }

        _this.setColumnFormat(columnField, columnProperties.extra);

        tableColumns.push(columnProperties);
      });
      return tableColumns;
    },
    setColumnFormat: function setColumnFormat(columnField, columnConfig) {
      if (columnField.dataType == 'time') {
        columnConfig.format = this.formatValueByTimeField.bind(this);
      } else if (this.isFieldCurrencyType(columnField.name)) {
        columnConfig.format = this.formatValueByCurrencyField.bind(this);
      }
    },
    formatValueByTimeField: function formatValueByTimeField(value, columnField) {
      var timeUnit = columnField.timeUnit || 'hours';
      var options = {};

      if (timeUnit !== 'seconds') {
        options.format = (0, _time.getLongHandDurationFormatByUnit)(timeUnit);
        options.precision = 2;
      }

      return (0, _time.humanizeDuration)(value, timeUnit, options);
    },
    actions: {
      onColumnClick: function onColumnClick(column) {
        if (column.sorted) {
          var sortFields = [];
          var rows = this.get('rows').toArray();
          var sortedRows;

          if (column) {
            sortFields.push({
              field: column.get('valuePath'),
              order: column.ascending ? 'asc' : 'desc'
            });
          }

          sortedRows = (0, _array.sortArrayOfObjects)(rows, sortFields);
          this.get('table').setRows(sortedRows);
        }
      },
      onScrolledToBottom: function onScrolledToBottom() {
        this.set('isScrolledToBottom', true);
      }
    }
  });

  _exports.default = _default;
});