define("happyfox-bi/services/request", ["exports", "happyfox-bi/utilities/string", "happyfox-bi/utilities/time", "fetch", "happyfox-bi/utilities/object", "happyfox-bi/utilities/url", "happyfox-bi/utilities/request", "happyfox-bi/config/environment"], function (_exports, _string, _time, _fetch, _object, _url, _request, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  // WARNING: Whatever you do, don't use this.get('') anywhere in this service.
  var NAMESPACE = '/api/1.0';

  var _default = Ember.Service.extend({
    errorHandlingService: Ember.inject.service('error-handling'),
    // Necessary because fetch always returns a resolved promise whether or not the request is a successful one
    checkStatus: function checkStatus(response) {
      var _this = this;

      if (_typeof(response) === 'object') {
        var status = response.status;

        if ((0, _request.isSuccess)(status)) {
          if (response.headers.get('Content-Type') === 'application/json') {
            return response.json();
          } else {
            return response.text();
          }
        } else {
          var responsePromise = response.headers.get('Content-Type') === 'application/json' ? response.json() : response.text();
          return responsePromise.then(function (payload) {
            return Ember.RSVP.Promise.reject(Ember.get(_this, 'errorHandlingService').handleErrors(status, payload));
          });
        }
      }
    },
    // Any PATCH or POST request involving files has to use this
    sendFormData: function sendFormData(url, data, type) {
      var attachmentKeys = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
      data = (0, _object.underscoreObject)(data);
      var formData = new FormData();
      Object.keys(data).forEach(function (key) {
        if (!attachmentKeys.includes(key) && key !== 'toString') {
          var value = data[key] === null ? '' : data[key];
          /* Used in custom_fields case
             Sample input: { custom_fields: { '5': 1, '25': [3, 4] } }
             Sample output: { custom_fields[5]: 1, custom_fields[25][]: 3, custom_fields[25][]: 4 }
          */

          if (Ember.isArray(value) || value instanceof Object) {
            formData.append(key, JSON.stringify(value));
          } else if (Ember.isArray(value)) {
            value.forEach(function (keyValue) {
              formData.append("".concat(key, "[]"), keyValue);
            });
          } else if (value instanceof Object) {
            Object.keys(value).forEach(function (nestedKey) {
              if (nestedKey !== 'toString') {
                var dynamicKey = "".concat(key, "[").concat(nestedKey, "]");
                var parentValue = data[key];
                var nestedKeyValue = parentValue[nestedKey] === null ? '' : parentValue[nestedKey];

                if (Ember.isArray(nestedKeyValue)) {
                  for (var i = 0; i < nestedKeyValue.length; i++) {
                    formData.append("".concat(dynamicKey, "[]"), nestedKeyValue[i]);
                  }
                } else {
                  formData.append(dynamicKey, nestedKeyValue);
                }
              }
            });
          } else {
            formData.append(key, value);
          }
        }
      });
      attachmentKeys.forEach(function (attachmentKey) {
        if (data[attachmentKey] instanceof Array) {
          data[attachmentKey].forEach(function (file) {
            formData.append(attachmentKey, file);
          });
        } else {
          formData.append(attachmentKey, data[attachmentKey]);
        }
      });
      url = (0, _url.makeURL)("".concat(NAMESPACE), url);
      var headers = {
        'X-CSRFTOKEN': (0, _string.getCookieValue)('csrftoken'),
        'X-TIMEZONE': (0, _time.getTimeZoneOffset)()
      };
      var requestBody = {
        method: type,
        headers: headers,
        cache: false,
        contentType: false,
        processData: false,
        body: formData
      };
      return (0, _fetch.default)(url, requestBody).then(Ember.run.bind(this, this.checkStatus));
    },
    sendRequest: function sendRequest(url, type, data) {
      var headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFTOKEN': (0, _string.getCookieValue)('csrftoken'),
        'X-TIMEZONE': (0, _time.getTimeZoneOffset)()
      };
      var requestBody = {
        method: type,
        headers: headers,
        credentials: 'include'
      };

      if (data) {
        requestBody.body = JSON.stringify((0, _object.underscoreObject)(data));
      }

      url = (0, _url.makeURL)("".concat(NAMESPACE), url);
      return (0, _fetch.default)(url, requestBody).then(Ember.run.bind(this, this.checkStatus));
    },
    // Reason for the warning at the top
    get: function get(url, data) {
      if (!Ember.isEmpty(data)) {
        url = "".concat(url, "?").concat((0, _url.getQueryString)((0, _object.underscoreObject)(data)));
      }

      return this.sendRequest(url, 'get', null);
    },
    put: function put(url) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return this.sendRequest(url, 'put', data);
    },
    post: function post(url) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return this.sendRequest(url, 'post', data);
    },
    patch: function patch(url) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return this.sendRequest(url, 'PATCH', data);
    },
    delete: function _delete(url) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return this.sendRequest(url, 'delete', data);
    },
    downloadFile: function downloadFile(url) {
      if (_environment.default.environment === 'development') {
        window.open((0, _url.makeURL)((0, _url.makeURL)('http://localhost:8000', NAMESPACE), url), '_blank');
      } else {
        window.open((0, _url.makeURL)((0, _url.makeURL)(window.location.origin, NAMESPACE), url), '_blank');
      }
    }
  });

  _exports.default = _default;
});