define("happyfox-bi/utilities/scroll", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.scrollToBottom = scrollToBottom;
  _exports.scrollToElement = scrollToElement;
  _exports.scrollToTop = scrollToTop;
  _exports.scrollToView = scrollToView;
  var SCROLL_DURATION = 800;

  function scrollToElement(containerSelectorString, elementSelectorString) {
    var scrollDuration = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : SCROLL_DURATION;
    var $element = Ember.$(elementSelectorString);

    if ($element.length) {
      var $scrollableContainer = Ember.$(containerSelectorString);
      var customFieldOffsetTop = $element.offset().top;
      var scrollableContainerOffsetTop = $scrollableContainer.offset().top;
      var scrollableContainerScrollTop = $scrollableContainer.scrollTop();
      $scrollableContainer.animate({
        scrollTop: customFieldOffsetTop - 2 * scrollableContainerOffsetTop + scrollableContainerScrollTop
      }, scrollDuration);
    } else {
      Ember.Logger.error('scrollToElement: No element present for passed selector string: ', elementSelectorString);
    }
  }

  function scrollToTop(elementSelectorString) {
    var element = Ember.$(elementSelectorString);

    if (Ember.isPresent(element)) {
      element.scrollTop(0);
    }
  }

  function scrollToBottom(elementSelectorString) {
    var element = Ember.$(elementSelectorString);

    if (Ember.isPresent(element)) {
      element.scrollTop(element[0].scrollHeight);
    }
  }

  function scrollToView(element, container) {
    var offset = Ember.$(element).offset();
    var containerElement = Ember.$(container);

    if (offset.top < 0 && Ember.isPresent(containerElement)) {
      containerElement.animate({
        scrollTop: offset.top
      }, 1000);
    }
  }
});