define("happyfox-bi/models/data-set", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    displayName: (0, _model.attr)('string'),
    dataSource: (0, _model.attr)('number'),
    fields: (0, _model.attr)(),
    settings: (0, _model.attr)(),
    displayNameOrName: Ember.computed('displayName', 'name', {
      get: function get() {
        var displayName = this.get('displayName');
        var name = this.get('name');
        return Ember.isPresent(displayName) ? displayName : name;
      }
    }),
    lastSyncAt: Ember.computed('settings.lastSyncAt', {
      get: function get() {
        var lastSyncedAt = this.get('settings.lastSyncAt');
        return lastSyncedAt ? moment(lastSyncedAt).format('MMM DD YYYY, h:mm A') : null;
      }
    })
  });

  _exports.default = _default;
});