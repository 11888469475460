define("happyfox-bi/visualizations/components/top-n-filter/component", ["exports", "happyfox-bi/visualizations/components/top-n-filter/constant"], function (_exports, _constant) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    featuresService: Ember.inject.service('features'),
    visualizationData: null,
    filterTypes: _constant.TOP_N_FILTER_TYPES,
    allElementaryFields: null,
    allAggregationFields: null,
    selectedElementaryField: null,
    selectedAggregationField: null,
    topNValue: '',
    actions: {
      initForm: function initForm() {
        var filter = Ember.isPresent(this.get('filter')) ? this.get('filter') : {};
        var selectedFilterType = this.get('filterTypes').findBy('id', filter.type);
        this.send('onFilterTypeChange', selectedFilterType ? selectedFilterType : this.get('filterTypes')[0]);
        var selectedElementaryField = this.get('allElementaryFields').findBy('name', filter.field);
        this.send('onElementaryFieldChange', selectedElementaryField);
        var selectedAggregationField = this.get('allAggregationFields').findBy('name', filter.aggregateField);
        this.send('onAggregationFieldChange', selectedAggregationField);
        this.set('topNValue', filter.n || 10);
      },
      onFilterTypeChange: function onFilterTypeChange(type) {
        this.set('selectedFilterType', type);
      },
      onAggregationFieldChange: function onAggregationFieldChange() {
        var field = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
        this.set('selectedAggregationField', field);
      },
      onElementaryFieldChange: function onElementaryFieldChange() {
        var field = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
        this.set('selectedElementaryField', field);
      },
      addTopNFilter: function addTopNFilter(popOver) {
        var filter = {
          n: this.get('topNValue'),
          filterType: this.get('selectedFilterType.id'),
          field: this.get('selectedElementaryField.name'),
          aggregateField: this.get('selectedAggregationField.name')
        };
        this.applyFilter(filter);
        popOver.closePopOver();
      },
      removeTopNFilter: function removeTopNFilter(popOver) {
        this.applyFilter(null);
        popOver.closePopOver();
      },
      cancelTopNFilter: function cancelTopNFilter(popOver) {
        popOver.closePopOver();
      }
    }
  });

  _exports.default = _default;
});