define("happyfox-bi/reports/details/pages/details/components/sample-visualization-item/edit-modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    dataService: Ember.inject.service('data'),
    intlService: Ember.inject.service('intl'),
    profileService: Ember.inject.service('profile'),
    featuresService: Ember.inject.service('features'),
    visualization: null,
    errors: null,
    currentVisualization: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('currentVisualization', Ember.Object.create(this.get('visualization')));
      this.set('errors', Ember.A());
    },
    isGlobal: Ember.computed('currentVisualization.account', {
      get: function get() {
        var currentVisualization = this.get('currentVisualization'); // account -> null is treated as global, i.e the corresponding sample viz is available for all accounts;

        return currentVisualization.account ? false : true;
      },
      set: function set(key, value) {
        return value;
      }
    }),
    isSaveDisabled: Ember.computed('currentVisualization.{name,description,account}', {
      get: function get() {
        var currentVisualization = this.get('currentVisualization');
        return !currentVisualization.name || _.isEqual(this.get('visualization'), currentVisualization);
      }
    }),
    actions: {
      closeModal: function closeModal() {
        this.closeModalAction();
      },
      onAccountScopeChange: function onAccountScopeChange(isChecked) {
        var account = isChecked ? null : this.get('profileService').getAccountId();
        this.set('currentVisualization.account', account);
      },
      validateTitle: function validateTitle() {
        if (!this.get('currentVisualization.name')) {
          var errorMessage = this.intlService.lookup('template-title-error-message');
          this.get('errors').addObject(errorMessage);
        } else {
          this.set('errors', Ember.A());
        }
      },
      save: function save() {
        var _this = this;

        this.set('errors', Ember.A());
        this.set('saveInProgress', true);
        var visualizationId = Number(this.get('visualization.id'));
        var currentVisualization = this.get('currentVisualization');
        var payload = {
          name: currentVisualization.name,
          description: currentVisualization.description,
          isGlobal: this.isGlobal
        };
        this.dataService.patchSampleVisualization(visualizationId, payload).then(function (visualization) {
          _this.closeModalAction(visualization);
        }).finally(function () {
          _this.set('saveInProgress', false);
        });
      }
    }
  });

  _exports.default = _default;
});