define("happyfox-bi/mixins/validation", ["exports", "happyfox-bi/utilities/email-validation"], function (_exports, _emailValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    intlService: Ember.inject.service('intl'),
    checkIfMandatory: function checkIfMandatory() {
      if (Ember.isBlank(this.get('value')) && this.get('isMandatory')) {
        this.addError(this.get('intlService').lookup('validation.mandatory-error-message'));
      }
    },
    checkForCharacterLimit: function checkForCharacterLimit() {
      var characterLimit = this.get('characterLimit');
      var value = this.get('value');

      if (!Ember.isBlank(value)) {
        if (value.length > characterLimit) {
          this.addError(this.get('intlService').lookupWithParameters('validation.text-{max}-characters-limit', {
            max: characterLimit
          }));
        }
      }
    },
    checkForNumber: function checkForNumber() {
      var $input = this.$('input');
      var inputVal = $input.val();

      if (!this.$('input')[0].checkValidity()) {
        this.addError(this.get('intlService').lookup('validation.invalid-number-message'));
      } else if (inputVal) {
        var splittedInputValue = inputVal.toString().split('.');
        var decimalLength = splittedInputValue[1] ? splittedInputValue[1].length : 0;

        if (decimalLength > 2) {
          this.addError(this.get('intlService').lookup('validation.invalid-decimal-limit'));
        }
      }
    },
    checkForEmail: function checkForEmail() {
      var value = this.get('value');

      if (!Ember.isBlank(value)) {
        if (!(0, _emailValidation.validateEmail)(value)) {
          this.addError(this.get('intlService').lookup('validation.invalid-email-message'));
        }
      }
    },
    checkForDate: function checkForDate() {
      var value = this.get('value');

      if (!Ember.isBlank(value) && !moment(value).isValid()) {
        this.addError(this.get('intlService').lookup('validation.invalid-date-message'));
      }
    }
  });

  _exports.default = _default;
});