define("happyfox-bi/mixins/table", ["exports", "happyfox-bi/utilities/array"], function (_exports, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    currentScrollOffset: 0,
    scrollTo: 0,
    showScrollToTop: Ember.computed.gte('currentScrollOffset', 100),
    actions: {
      scrollToTop: function scrollToTop() {
        var scrollableContainer = this.$().find('.tse-scroll-content');

        if (Ember.isPresent(scrollableContainer)) {
          scrollableContainer.scrollTop(0);
        }
      },
      onColumnClick: function onColumnClick(column) {
        if (column.sorted) {
          var sortFields = [];
          var rows = this.get('rows').toArray();
          var sortedRows;

          if (column) {
            sortFields.push({
              field: column.get('valuePath'),
              order: column.ascending ? 'asc' : 'desc'
            });
          }

          sortedRows = (0, _array.sortArrayOfObjects)(rows, sortFields);
          this.get('table').setRows(sortedRows);
        }
      }
    }
  });

  _exports.default = _default;
});