define("happyfox-bi/components/data-table-new/table-pop-over/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8h/zmfG0",
    "block": "{\"symbols\":[\"popOver\",\"item\"],\"statements\":[[10,\"div\"],[14,0,\"u-hv-centered-container\"],[12],[2,\"\\n  \"],[8,\"pop-over\",[],[[\"@isMini\",\"@renderInPlace\",\"@openOnHover\",\"@stopClickPropagation\"],[true,false,true,true]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"trigger\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"span\"],[14,0,\"table-cell_pop-over_trigger\"],[14,\"data-test-id\",\"table-cell-pop-over-trigger\"],[12],[1,[32,0,[\"args\",\"values\",\"length\"]]],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[32,1,[\"isOpen\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,[32,1,[\"content\"]],[[24,0,\"mod-table-cell_pop-over-content u-cursor-default\"]],[[\"@popOverMaxHeight\"],[300]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"orderedValues\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"div\"],[14,0,\"table-cell_pop-over_value\"],[12],[1,[32,2,[\"name\"]]],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "happyfox-bi/components/data-table-new/table-pop-over/template.hbs"
    }
  });

  _exports.default = _default;
});