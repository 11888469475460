define("happyfox-bi/lost/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HJj/tbqI",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"content_outlet_main error-state-message\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"error-state-message_header\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"lost-header\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"error-state-message_content\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"lost-content\"],[[\"htmlSafe\"],[true]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[6,[37,1],null,[[\"tagName\",\"class\",\"route\"],[\"button\",\"hf-primary-action\",\"reports\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"go-to-reports\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"link-to\"]}",
    "meta": {
      "moduleName": "happyfox-bi/lost/template.hbs"
    }
  });

  _exports.default = _default;
});