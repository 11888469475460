define("happyfox-bi/visualizations/new/mixins/visualization-display-settings", ["exports", "happyfox-bi/visualizations/constant"], function (_exports, _constant) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var DEFAULT_COMPARE_METRIC = 'higher_is_better';
  var HEAT_MAP_MIN_COLOR = '#ddf0ff';
  var HEAT_MAP_MAX_COLOR = '#0066ff';

  var _default = Ember.Mixin.create({
    isDisplayTypeChanged: false,
    canConnectLinesOverEmptyData: function canConnectLinesOverEmptyData(displaySettings) {
      var connectLinesOverEmptyData = displaySettings.connectLinesOverEmptyData; // Will be enabled by default for first time in Create visualization view.

      if (this.get('isCreate')) {
        return Ember.isPresent(connectLinesOverEmptyData) ? connectLinesOverEmptyData : true;
      } else {
        return connectLinesOverEmptyData;
      }
    },
    canShowValueLabels: function canShowValueLabels(displaySettings) {
      return displaySettings.showValueLabels;
    },
    initializeDisplaySettings: function initializeDisplaySettings(visualizationDisplaySettings) {
      var displaySettings = Ember.Object.create();
      var visualizationDisplayId = Ember.isPresent(this.get('visualizationData.displayType')) ? this.get('visualizationData.displayType').id : '';
      var visualizationSize = visualizationDisplaySettings.size;
      displaySettings.set('size', visualizationSize || 'square-l');
      displaySettings.set('stopWords', visualizationDisplaySettings.stopWords);

      switch (visualizationDisplayId) {
        case 'data_tile':
          displaySettings.setProperties({
            label: _.isEmpty(visualizationDisplaySettings.label) ? '' : visualizationDisplaySettings.label,
            value: _.isEmpty(visualizationDisplaySettings.value) ? '' : visualizationDisplaySettings.value,
            staticLabel: _.isEmpty(visualizationDisplaySettings.staticLabel) ? '' : visualizationDisplaySettings.staticLabel,
            labelPosition: _.isEmpty(visualizationDisplaySettings.labelPosition) ? 'top' : visualizationDisplaySettings.labelPosition,
            focus: _.isEmpty(visualizationDisplaySettings.focus) ? 'value' : visualizationDisplaySettings.focus,
            valuePrefix: _.isEmpty(visualizationDisplaySettings.valuePrefix) ? '' : visualizationDisplaySettings.valuePrefix,
            valuePostfix: _.isEmpty(visualizationDisplaySettings.valuePostfix) ? '' : visualizationDisplaySettings.valuePostfix,
            compareMetric: visualizationDisplaySettings.compareMetric ? visualizationDisplaySettings.compareMetric : DEFAULT_COMPARE_METRIC
          });
          break;

        case 'text_tile':
          displaySettings.set('text', Ember.isEmpty(visualizationDisplaySettings.text) ? '' : visualizationDisplaySettings.text);
          break;

        case 'doughnut_chart':
          displaySettings.setProperties({
            category: _.isEmpty(visualizationDisplaySettings.category) ? '' : visualizationDisplaySettings.category,
            value: _.isEmpty(visualizationDisplaySettings.value) ? '' : visualizationDisplaySettings.value,
            values: _.isEmpty(visualizationDisplaySettings.values) ? Ember.A() : visualizationDisplaySettings.values,
            groupThreshold: _.isEmpty(visualizationDisplaySettings.groupThreshold) ? 10 : visualizationDisplaySettings.groupThreshold,
            groupLabel: _.isEmpty(visualizationDisplaySettings.groupLabel) ? 'Others' : visualizationDisplaySettings.groupLabel,
            hideLabels: Ember.isPresent(visualizationDisplaySettings.hideLabels) ? visualizationDisplaySettings.hideLabels : false,
            hideLegend: Ember.isPresent(visualizationDisplaySettings.hideLegend) ? visualizationDisplaySettings.hideLegend : true
          });
          break;

        case 'funnel_chart':
          displaySettings.setProperties({
            category: _.isEmpty(visualizationDisplaySettings.category) ? '' : visualizationDisplaySettings.category,
            value: _.isEmpty(visualizationDisplaySettings.value) ? '' : visualizationDisplaySettings.value,
            orientation: _.isEmpty(visualizationDisplaySettings.orientation) ? '' : visualizationDisplaySettings.orientation,
            valueFormat: _.isEmpty(visualizationDisplaySettings.valueFormat) ? '' : visualizationDisplaySettings.valueFormat,
            hideLabels: Ember.isPresent(visualizationDisplaySettings.hideLabels) ? visualizationDisplaySettings.hideLabels : true,
            hideLegend: Ember.isPresent(visualizationDisplaySettings.hideLegend) ? visualizationDisplaySettings.hideLegend : false
          });
          break;

        case 'data_table':
          displaySettings.setProperties({
            'columns': _.isEmpty(visualizationDisplaySettings) ? Ember.A() : visualizationDisplaySettings.columns,
            'conditionalFormatting': _.isEmpty(visualizationDisplaySettings.conditionalFormatting) ? Ember.A() : visualizationDisplaySettings.conditionalFormatting,
            'maxRowsToDisplay': Ember.isPresent(visualizationDisplaySettings.maxRowsToDisplay) ? visualizationDisplaySettings.maxRowsToDisplay : 100
          });
          break;

        case 'map_chart':
        case 'map_chart_usa':
          displaySettings.setProperties({
            region: _.isEmpty(visualizationDisplaySettings.region) ? null : visualizationDisplaySettings.region,
            value: _.isEmpty(visualizationDisplaySettings.value) ? null : visualizationDisplaySettings.value,
            useIso: Ember.isPresent(visualizationDisplaySettings.useIso) ? visualizationDisplaySettings.useIso : false
          });
          break;

        case 'bar_chart':
        case 'pareto_chart':
          this.initializeDisplaySettingsForXYChart(displaySettings, visualizationDisplaySettings);
          break;

        case 'line_chart':
          this.initializeDisplaySettingsForXYChart(displaySettings, visualizationDisplaySettings);
          break;

        case 'area_chart':
          this.initializeDisplaySettingsForXYChart(displaySettings, visualizationDisplaySettings);
          break;

        case 'pivot_table':
          displaySettings.setProperties({
            rows: _.isEmpty(visualizationDisplaySettings.rows) ? Ember.A() : visualizationDisplaySettings.rows,
            columns: _.isEmpty(visualizationDisplaySettings.columns) ? Ember.A() : visualizationDisplaySettings.columns,
            values: _.isEmpty(visualizationDisplaySettings.values) ? Ember.A() : visualizationDisplaySettings.values,
            showTotals: visualizationDisplaySettings.showTotals,
            showAverages: visualizationDisplaySettings.showAverages,
            showPercentages: visualizationDisplaySettings.showPercentages,
            tableAsPivotTable: visualizationDisplaySettings.tableAsPivotTable
          });
          break;

        case 'stacked_bar_chart':
        case 'hundred_percent_stacked_bar_chart':
          this.initializeDisplaySettingsForGroupedChart(displaySettings, visualizationDisplaySettings);
          break;

        case 'grouped_bar_chart':
          this.initializeDisplaySettingsForGroupedChart(displaySettings, visualizationDisplaySettings);
          break;

        case 'stacked_area_chart':
          this.initializeDisplaySettingsForGroupedChart(displaySettings, visualizationDisplaySettings);
          break;

        case 'multi_area_chart':
          this.initializeDisplaySettingsForGroupedChart(displaySettings, visualizationDisplaySettings);
          break;

        case 'multi_line_chart':
          this.initializeDisplaySettingsForGroupedChart(displaySettings, visualizationDisplaySettings);
          break;

        case 'multi_axis_chart':
          displaySettings.setProperties({
            'xAxis': _.isEmpty(visualizationDisplaySettings.xAxis) ? null : visualizationDisplaySettings.xAxis,
            'yAxisLeft': _.isEmpty(visualizationDisplaySettings.yAxisLeft) ? null : visualizationDisplaySettings.yAxisLeft,
            'yAxisRight': _.isEmpty(visualizationDisplaySettings.yAxisRight) ? null : visualizationDisplaySettings.yAxisRight,
            hideLegend: Ember.isPresent(visualizationDisplaySettings.hideLegend) ? visualizationDisplaySettings.hideLegend : false
          });
          break;

        case 'life_cycle_chart':
          displaySettings.setProperties({
            xAxis: _.isEmpty(visualizationDisplaySettings.xAxis) ? null : visualizationDisplaySettings.xAxis,
            yAxis: _.isEmpty(visualizationDisplaySettings.yAxis) ? null : visualizationDisplaySettings.yAxis
          });
          break;

        case 'segmentation_chart':
          displaySettings.setProperties({
            xAxis: _.isEmpty(visualizationDisplaySettings.xAxis) ? null : visualizationDisplaySettings.xAxis,
            yAxis: _.isEmpty(visualizationDisplaySettings.yAxis) ? null : visualizationDisplaySettings.yAxis,
            category: _.isEmpty(visualizationDisplaySettings.category) ? null : visualizationDisplaySettings.category,
            quadrants: _.isEmpty(visualizationDisplaySettings.quadrants) ? null : visualizationDisplaySettings.quadrants,
            hideLegend: Ember.isPresent(visualizationDisplaySettings.hideLegend) ? visualizationDisplaySettings.hideLegend : false
          });

          if (!displaySettings.get('quadrants')) {
            displaySettings.set('quadrants', {
              topLeft: {
                name: '',
                color: '#7f66dc'
              },
              topRight: {
                name: '',
                color: '#dc6688'
              },
              bottomLeft: {
                name: '',
                color: '#67b7dc'
              },
              bottomRight: {
                name: '',
                color: '#ddaf66'
              }
            });
          }

          break;

        case 'word_cloud':
          displaySettings.setProperties({
            category: _.isEmpty(visualizationDisplaySettings.category) ? null : visualizationDisplaySettings.category,
            value: _.isEmpty(visualizationDisplaySettings.value) ? null : visualizationDisplaySettings.value
          });
          break;

        case 'gantt_chart':
          displaySettings.setProperties({
            category: _.isEmpty(visualizationDisplaySettings.category) ? '' : visualizationDisplaySettings.category,
            task: _.isEmpty(visualizationDisplaySettings.task) ? '' : visualizationDisplaySettings.task,
            start: _.isEmpty(visualizationDisplaySettings.start) ? '' : visualizationDisplaySettings.start,
            end: _.isEmpty(visualizationDisplaySettings.end) ? '' : visualizationDisplaySettings.end
          });
          break;

        case 'gauge_chart':
          displaySettings.setProperties({
            'useBands': _.isEmpty(visualizationDisplaySettings.useBands) ? false : visualizationDisplaySettings.useBands,
            'bands': _.isEmpty(visualizationDisplaySettings.bands) ? Ember.A() : visualizationDisplaySettings.bands,
            'value': _.isEmpty(visualizationDisplaySettings.value) ? '' : visualizationDisplaySettings.value,
            'min': Ember.isPresent(visualizationDisplaySettings.min) ? visualizationDisplaySettings.min : null,
            'max': Ember.isPresent(visualizationDisplaySettings.max) ? visualizationDisplaySettings.max : null
          });
          break;

        case 'heat_map':
          displaySettings.setProperties({
            xAxis: _.isEmpty(visualizationDisplaySettings.xAxis) ? null : visualizationDisplaySettings.xAxis,
            yAxis: _.isEmpty(visualizationDisplaySettings.yAxis) ? null : visualizationDisplaySettings.yAxis,
            value: _.isEmpty(visualizationDisplaySettings.value) ? null : visualizationDisplaySettings.value,
            minColor: _.isEmpty(visualizationDisplaySettings.minColor) ? HEAT_MAP_MIN_COLOR : visualizationDisplaySettings.minColor,
            maxColor: _.isEmpty(visualizationDisplaySettings.maxColor) ? HEAT_MAP_MAX_COLOR : visualizationDisplaySettings.maxColor
          });
          break;

        case 'tree_map':
          displaySettings.setProperties({
            groups: _.isEmpty(visualizationDisplaySettings.groups) ? Ember.A() : visualizationDisplaySettings.groups,
            value: _.isEmpty(visualizationDisplaySettings.value) ? null : visualizationDisplaySettings.value
          });
          break;
      }

      if (visualizationDisplayId) {
        this.setAdditionalChartSettings(visualizationDisplayId, displaySettings, visualizationDisplaySettings);
      }

      return displaySettings;
    },
    createDisplaySettingsOnDisplayTypeChange: function createDisplaySettingsOnDisplayTypeChange() {
      var displaySettings = Ember.Object.create();
      var visualizationData = this.get('visualizationData');
      var visualizationDisplayId = Ember.isPresent(this.get('visualizationData.displayType')) ? this.get('visualizationData.displayType').id : '';
      var rowFields = visualizationData.get('rows');
      var columnFields = visualizationData.get('columns');
      var valueFields = visualizationData.get('values');
      var existingDisplaySettings = this.get('visualizationData.displaySettings');
      this.set('isDisplayTypeChanged', true);

      switch (visualizationDisplayId) {
        case 'data_tile':
          displaySettings.setProperties({
            label: Ember.isPresent(rowFields) ? rowFields[0].name : null,
            value: Ember.isPresent(valueFields) ? valueFields[0].name : null,
            staticLabel: '',
            labelPosition: 'top',
            focus: 'value',
            valuePrefix: '',
            valuePostfix: '',
            compareMetric: Ember.isPresent(valueFields) && Ember.isPresent(valueFields[0].compareMetric) ? valueFields[0].compareMetric : DEFAULT_COMPARE_METRIC
          });
          break;

        case 'text_tile':
          displaySettings.set('text', '');
          break;

        case 'doughnut_chart':
          displaySettings.setProperties({
            category: Ember.isPresent(rowFields) ? rowFields[0].name : null,
            groupThreshold: 10,
            groupLabel: 'Others',
            hideLabels: false,
            hideLegend: true
          });

          if (Ember.isPresent(valueFields)) {
            if (valueFields.length > 1) {
              displaySettings.setProperties({
                'value': null,
                'values': valueFields.map(function (field) {
                  return Ember.Object.create({
                    label: field.displayName || field.name,
                    key: field.name
                  });
                })
              });
            } else {
              displaySettings.setProperties({
                'value': valueFields[0].name,
                'values': Ember.A()
              });
            }
          } else {
            displaySettings.setProperties({
              'value': null,
              'values': Ember.A()
            });
          }

          break;

        case 'funnel_chart':
          displaySettings.setProperties({
            category: Ember.isPresent(rowFields) ? rowFields[0].name : null,
            value: Ember.isPresent(valueFields) ? valueFields[0].name : null,
            valueFormat: 'percentage',
            orientation: 'vertical',
            hideLabels: true,
            hideLegend: false
          });
          break;

        case 'word_cloud':
          displaySettings.setProperties({
            category: Ember.isPresent(rowFields) ? rowFields[0].name : null,
            value: Ember.isPresent(valueFields) ? valueFields[0].name : null
          });
          break;

        case 'bar_chart':
        case 'pareto_chart':
          displaySettings.setProperties({
            xAxis: this.getXYChartSettings('xAxis', rowFields),
            yAxis: this.getXYChartSettings('yAxis', valueFields)
          });
          break;

        case 'line_chart':
        case 'area_chart':
          displaySettings.setProperties({
            xAxis: this.getXYChartSettings('xAxis', rowFields),
            yAxis: this.getXYChartSettings('yAxis', valueFields)
          });
          break;

        case 'data_table':
          displaySettings.setProperties({
            'columns': Ember.isPresent(columnFields) ? columnFields.map(function (field) {
              return Ember.Object.create({
                key: field.get('name'),
                label: field.get('displayName') || field.get('name')
              });
            }) : Ember.A(),
            'conditionalFormatting': Ember.A(),
            'maxRowsToDisplay': 100
          });
          break;

        case 'map_chart':
        case 'map_chart_usa':
          displaySettings.setProperties({
            region: Ember.isPresent(rowFields) ? rowFields[0].name : null,
            value: Ember.isPresent(valueFields) ? valueFields[0].name : null,
            useIso: false
          });
          break;

        case 'pivot_table':
          displaySettings.setProperties({
            rows: this.getPivotTableSettings('rows', rowFields),
            columns: this.getPivotTableSettings('columns', columnFields),
            values: this.getPivotTableSettings('values', valueFields)
          });
          displaySettings.setProperties({
            showTotals: false,
            showAverages: false,
            showPercentages: false,
            tableAsPivotTable: false
          });
          break;

        case 'multi_area_chart':
        case 'stacked_area_chart':
        case 'multi_line_chart':
        case 'grouped_bar_chart':
        case 'stacked_bar_chart':
        case 'hundred_percent_stacked_bar_chart':
          displaySettings.setProperties({
            'xAxis': this.getGroupedChartXAxisSettings(rowFields),
            'yAxis': this.getGroupedChartYAxisSettings(columnFields, valueFields),
            'groups': this.getGroupedChartColumnSettings(columnFields, valueFields),
            hideLegend: false
          });
          break;

        case 'multi_axis_chart':
          displaySettings.setProperties({
            xAxis: this.getMultiAxisChartXAxisSettings(rowFields),
            yAxisLeft: this.getMultiAxisChartYAxisLeftSettings('yAxisLeft'),
            yAxisRight: this.getMultiAxisChartYAxisRightSettings('yAxisRight'),
            hideLegend: false
          });
          break;

        case 'life_cycle_chart':
          Object.assign(displaySettings, this.getLifeCycleChartDisplaySettings(rowFields, columnFields, valueFields));
          break;

        case 'segmentation_chart':
          Object.assign(displaySettings, this.getSegmentationChartDisplaySettings(rowFields, columnFields, valueFields));
          displaySettings.set('hideLegend', false);
          break;

        case 'gantt_chart':
          Object.assign(displaySettings, this.getGanttChartDisplaySettings(rowFields));
          break;

        case 'heat_map':
          displaySettings.setProperties({
            xAxis: Ember.isPresent(rowFields) ? Ember.Object.create({
              key: rowFields[0].name,
              label: rowFields[0].displayName
            }) : null,
            yAxis: Ember.isPresent(columnFields) ? Ember.Object.create({
              key: columnFields[0].name,
              label: columnFields[0].displayName
            }) : null,
            value: Ember.isPresent(valueFields) ? Ember.Object.create({
              key: valueFields[0].name,
              label: valueFields[0].displayName
            }) : null,
            minColor: HEAT_MAP_MIN_COLOR,
            maxColor: HEAT_MAP_MAX_COLOR
          });
          break;

        case 'tree_map':
          displaySettings.setProperties({
            groups: Ember.isPresent(rowFields) ? rowFields.mapBy('name') : null,
            value: Ember.isPresent(valueFields) ? valueFields[0].name : null
          });
          break;

        case 'gauge_chart':
          displaySettings.setProperties({
            'useBands': false,
            'bands': Ember.A(),
            'value': Ember.isPresent(valueFields) ? valueFields[0].name : '',
            'min': null,
            'max': null
          });
          break;
      }

      this.set('isDisplayTypeChanged', false);

      if (visualizationDisplayId) {
        this.setAdditionalChartSettings(visualizationDisplayId, displaySettings, existingDisplaySettings);
      }

      this.set('visualizationData.displaySettings', displaySettings);
    },
    setAdditionalChartSettings: function setAdditionalChartSettings(visualizationDisplayId, currentDisplaySettings, existingDisplaySettings) {
      if (_constant.WIDGETS_WITH_AXIS.includes(visualizationDisplayId)) {
        this.initializeYAxisSettings(visualizationDisplayId, currentDisplaySettings, existingDisplaySettings);
      }

      if (_constant.WIDGETS_WITH_LINE_SERIES.includes(visualizationDisplayId)) {
        currentDisplaySettings.set('connectLinesOverEmptyData', this.canConnectLinesOverEmptyData(existingDisplaySettings));
      }

      if (_constant.WIDGETS_WITH_VALUE_LABELS.includes(visualizationDisplayId)) {
        currentDisplaySettings.set('showValueLabels', this.canShowValueLabels(existingDisplaySettings));
      }
    },
    initializeDisplaySettingsForXYChart: function initializeDisplaySettingsForXYChart(currentDisplaySettings, existingDisplaySettings) {
      currentDisplaySettings.setProperties({
        xAxis: _.isEmpty(existingDisplaySettings.xAxis) ? null : existingDisplaySettings.xAxis,
        yAxis: _.isEmpty(existingDisplaySettings.yAxis) ? null : existingDisplaySettings.yAxis
      });
    },
    initializeDisplaySettingsForGroupedChart: function initializeDisplaySettingsForGroupedChart(currentDisplaySettings, existingDisplaySettings) {
      currentDisplaySettings.setProperties({
        xAxis: _.isEmpty(existingDisplaySettings.xAxis) ? null : existingDisplaySettings.xAxis,
        yAxis: _.isEmpty(existingDisplaySettings.yAxis) ? null : existingDisplaySettings.yAxis,
        groups: _.isEmpty(existingDisplaySettings.groups) ? null : existingDisplaySettings.groups,
        hideLegend: Ember.isPresent(existingDisplaySettings.hideLegend) ? existingDisplaySettings.hideLegend : false
      });
    },
    initializeYAxisSettings: function initializeYAxisSettings(visualizationDisplayId, currentDisplaySettings, existingDisplaySettings) {
      if (visualizationDisplayId == 'multi_axis_chart') {
        currentDisplaySettings.setProperties({
          yAxisLeftMin: Ember.isPresent(existingDisplaySettings.yAxisLeftMin) ? existingDisplaySettings.yAxisLeftMin : null,
          yAxisLeftMax: Ember.isPresent(existingDisplaySettings.yAxisLeftMax) ? existingDisplaySettings.yAxisLeftMax : null,
          yAxisRightMin: Ember.isPresent(existingDisplaySettings.yAxisRightMin) ? existingDisplaySettings.yAxisRightMin : null,
          yAxisRightMax: Ember.isPresent(existingDisplaySettings.yAxisRightMax) ? existingDisplaySettings.yAxisRightMax : null
        });
      } else {
        currentDisplaySettings.setProperties({
          yAxisMin: Ember.isPresent(existingDisplaySettings.yAxisMin) ? existingDisplaySettings.yAxisMin : null,
          yAxisMax: Ember.isPresent(existingDisplaySettings.yAxisMax) ? existingDisplaySettings.yAxisMax : null
        });
      }
    },
    generateDisplaySettings: function generateDisplaySettings(displaySettingsKey) {
      var visualizationData = this.get('visualizationData');
      var displaySettings = visualizationData.get('displaySettings');
      var visualizationDisplayId = Ember.isPresent(this.get('visualizationData.displayType')) ? this.get('visualizationData.displayType').id : '';
      var rowFields = visualizationData.get('rows');
      var columnFields = visualizationData.get('columns');
      var valueFields = visualizationData.get('values');

      switch (visualizationDisplayId) {
        case 'data_tile':
          if (displaySettingsKey == 'rows') {
            displaySettings.set('label', rowFields.get('length') ? rowFields[0].name : '');
          } else {
            displaySettings.set('value', valueFields.get('length') ? valueFields[0].name : '');
            displaySettings.set('compareMetric', Ember.isPresent(valueFields) && Ember.isPresent(valueFields[0].compareMetric) ? valueFields[0].compareMetric : DEFAULT_COMPARE_METRIC);
          }

          break;

        case 'funnel_chart':
        case 'word_cloud':
          if (displaySettingsKey == 'rows') {
            displaySettings.set('category', rowFields.get('length') ? rowFields[0].name : '');
          } else {
            displaySettings.set('value', valueFields.get('length') ? valueFields[0].name : '');
          }

          break;

        case 'bar_chart':
        case 'line_chart':
        case 'area_chart':
        case 'pareto_chart':
          if (displaySettingsKey == 'rows') {
            displaySettings.set('xAxis', this.getXYChartSettings('xAxis', rowFields));
          } else if (displaySettingsKey == 'values') {
            displaySettings.set('yAxis', this.getXYChartSettings('yAxis', valueFields));
          }

          break;

        case 'pivot_table':
          if (displaySettingsKey == 'rows') {
            displaySettings.set('rows', this.getPivotTableSettings('rows', rowFields));
          } else if (displaySettingsKey == 'columns') {
            displaySettings.set('columns', this.getPivotTableSettings('columns', columnFields));
          } else if (displaySettingsKey == 'values') {
            displaySettings.set('values', this.getPivotTableSettings('values', valueFields));
          }

          break;

        case 'grouped_bar_chart':
        case 'stacked_bar_chart':
        case 'stacked_area_chart':
        case 'multi_line_chart':
        case 'multi_area_chart':
        case 'hundred_percent_stacked_bar_chart':
          if (displaySettingsKey == 'rows') {
            displaySettings.set('xAxis', this.getGroupedChartXAxisSettings(rowFields));
          } else {
            displaySettings.set('yAxis', this.getGroupedChartYAxisSettings(columnFields, valueFields));
            displaySettings.set('groups', this.getGroupedChartColumnSettings(columnFields, valueFields));
          }

          break;

        case 'multi_axis_chart':
          if (displaySettingsKey == 'rows') {
            displaySettings.set('xAxis', this.getMultiAxisChartXAxisSettings(rowFields));
          } else {
            displaySettings.set('yAxisLeft', this.getMultiAxisChartYAxisLeftSettings('yAxisLeft'));
            displaySettings.set('yAxisRight', this.getMultiAxisChartYAxisRightSettings('yAxisRight'));
          }

          break;

        case 'life_cycle_chart':
          if (displaySettingsKey == 'rows') {
            if (Ember.isPresent(rowFields)) {
              displaySettings.set('xAxis', Ember.Object.create({
                key: rowFields.length ? rowFields[0].name : null,
                label: rowFields.length ? rowFields[0].displayName || rowFields[0].name : null
              }));
            } else {
              displaySettings.set('xAxis', null);
            }
          } else {
            if (!(columnFields.length || valueFields.length)) {
              displaySettings.set('yAxis', null);
            } else {
              displaySettings.setProperties({
                yAxis: Ember.Object.create({
                  key: columnFields.length ? columnFields[0].name : null,
                  label: columnFields.length ? columnFields[0].displayName || columnFields[0].name : null,
                  value: valueFields.length ? valueFields[0].name : null
                })
              });
            }
          }

          break;

        case 'segmentation_chart':
          if (displaySettingsKey == 'rows') {
            if (rowFields.length) {
              displaySettings.set('category', Ember.Object.create({
                key: rowFields[0].name,
                label: rowFields[0].displayName || rowFields[0].name
              }));
            } else {
              displaySettings.set('category', null);
            }
          } else {
            var xAxisDisplayName;

            if (valueFields.length > 0) {
              xAxisDisplayName = valueFields[0].displayName || valueFields[0].name;
              var xAxisSettings = Ember.Object.create({
                key: valueFields[0].name,
                label: xAxisDisplayName,
                segmentationType: 'average',
                segmentationValue: 'null'
              });
              displaySettings.set('xAxis', xAxisSettings);
            } else {
              displaySettings.set('xAxis', null);
            }

            var yAxisDisplayName;

            if (valueFields.length > 1) {
              yAxisDisplayName = valueFields[1].displayName || valueFields[1].name;
              var yAxisSettings = Ember.Object.create({
                key: valueFields[1].name,
                label: yAxisDisplayName,
                segmentationType: 'average',
                segmentationValue: 'null'
              });
              displaySettings.set('yAxis', yAxisSettings); // Set quadrants names

              displaySettings.set('quadrants.topLeft.name', "Low ".concat(xAxisDisplayName, ", High ").concat(yAxisDisplayName));
              displaySettings.set('quadrants.topRight.name', "High ".concat(xAxisDisplayName, " & ").concat(yAxisDisplayName));
              displaySettings.set('quadrants.bottomLeft.name', "Low ".concat(xAxisDisplayName, " & ").concat(yAxisDisplayName));
              displaySettings.set('quadrants.bottomRight.name', "High ".concat(xAxisDisplayName, ", Low ").concat(yAxisDisplayName));
            } else {
              displaySettings.set('yAxis', null);
            }
          }

          break;

        case 'gantt_chart':
          if (displaySettingsKey == 'rows') {
            var ganttDisplaySettings = this.getGanttChartDisplaySettings(rowFields);
            displaySettings.setProperties(_objectSpread({}, ganttDisplaySettings));
          }

          break;

        case 'heat_map':
          displaySettings.setProperties({
            xAxis: Ember.isPresent(rowFields) ? Ember.Object.create({
              key: rowFields[0].name,
              label: rowFields[0].displayName
            }) : null,
            yAxis: Ember.isPresent(columnFields) ? Ember.Object.create({
              key: columnFields[0].name,
              label: columnFields[0].displayName
            }) : null,
            value: Ember.isPresent(valueFields) ? Ember.Object.create({
              key: valueFields[0].name,
              label: valueFields[0].displayName
            }) : null
          });
          break;

        case 'tree_map':
          if (displaySettingsKey == 'rows') {
            displaySettings.set('groups', Ember.isPresent(rowFields) ? rowFields.mapBy('name') : Ember.A());
          } else if (displaySettingsKey == 'values') {
            displaySettings.set('value', Ember.isPresent(valueFields) ? valueFields[0].name : null);
          }

          break;

        case 'gauge_chart':
          if (displaySettingsKey == 'values') {
            displaySettings.set('value', valueFields.get('length') ? valueFields[0].name : '');
          }

          break;
      }
    },
    getXYChartSettings: function getXYChartSettings(displaySettingsKey, fields) {
      if (Ember.isPresent(fields)) {
        var axis = this.get('visualizationData.displaySettings').get(displaySettingsKey);
        var axisSettings = Ember.Object.create();
        axisSettings.set('key', fields[0].name);
        var axisLabel;

        if (Ember.isPresent(axis)) {
          axisLabel = axis.get('key') == fields[0].name ? axis.get('label') : fields[0].displayName || fields[0].name;
        } else {
          axisLabel = fields[0].displayName || fields[0].name;
        }

        axisSettings.set('label', axisLabel);
        return axisSettings;
      }

      return null;
    },
    getPivotTableSettings: function getPivotTableSettings(displaySettingsKey, fields) {
      if (Ember.isPresent(fields)) {
        var tableFieldSettings = this.get('visualizationData.displaySettings').get(displaySettingsKey);
        var fieldSettings = Ember.A();

        if (Ember.isPresent(tableFieldSettings)) {
          fields.forEach(function (field) {
            var setting = Ember.Object.create({
              key: '',
              label: ''
            });
            var existingField = tableFieldSettings.findBy('key', field.name);

            if (existingField) {
              setting.setProperties({
                key: existingField.key,
                label: existingField.label
              });
            } else {
              setting.setProperties({
                key: field.name,
                label: field.displayName || field.name
              });
            }

            fieldSettings.pushObject(setting);
          });
        } else {
          fields.forEach(function (field) {
            var setting = Ember.Object.create({
              key: field.name,
              label: field.displayName || field.name
            });
            fieldSettings.pushObject(setting);
          });
        }

        return fieldSettings;
      }

      return Ember.A();
    },
    getGroupedChartXAxisSettings: function getGroupedChartXAxisSettings(rowFields) {
      if (Ember.isPresent(rowFields)) {
        var xAxis = this.get('visualizationData').get('displaySettings.xAxis');
        var xAxisSettings = Ember.Object.create();
        xAxisSettings.set('key', rowFields[0].name);
        var xAxisLabel;

        if (Ember.isPresent(xAxis)) {
          xAxisLabel = xAxis.get('key') == rowFields[0].name ? xAxis.get('label') : rowFields[0].displayName || rowFields[0].name;
        } else {
          xAxisLabel = rowFields[0].displayName || rowFields[0].name;
        }

        xAxisSettings.set('label', xAxisLabel);
        return xAxisSettings;
      }

      return null;
    },
    getGroupedChartYAxisSettings: function getGroupedChartYAxisSettings(columnFields, valueFields) {
      var yAxis = this.get('visualizationData').get('displaySettings.yAxis');
      var yAxisSettings = Ember.Object.create();

      if (!(columnFields.length || valueFields.length)) {
        return null;
      }

      if (Ember.isPresent(columnFields)) {
        yAxisSettings.setProperties({
          groupBy: columnFields[0].name
        });
      } else {
        yAxisSettings.setProperties({
          groupBy: null
        });
      }

      if (Ember.isPresent(valueFields)) {
        yAxisSettings.setProperties({
          groupBy: Ember.isPresent(columnFields) ? columnFields[0].name : null,
          value: Ember.isPresent(columnFields) ? valueFields[0].name : null
        });
        var yAxisLabel;

        if (!this.get('isDisplayTypeChanged') && Ember.isPresent(yAxis)) {
          yAxisLabel = yAxis.get('value') == valueFields[0].name ? yAxis.get('label') : valueFields[0].displayName || valueFields[0].name;
        } else {
          yAxisLabel = valueFields[0].displayName || valueFields[0].name;
        }

        yAxisSettings.set('label', yAxisLabel);
      }

      return yAxisSettings;
    },
    getGroupedChartColumnSettings: function getGroupedChartColumnSettings(columnFields, valueFields) {
      if (columnFields.length) {
        return null;
      } else {
        if (valueFields.length > 1) {
          return valueFields.map(function (field) {
            return Ember.Object.create({
              key: field.get('name'),
              label: field.get('displayName') || field.get('name')
            });
          });
        }

        return null;
      }
    },
    getLifeCycleChartDisplaySettings: function getLifeCycleChartDisplaySettings(rowFields, columnFields, valueFields) {
      var lifeCycleDisplaySettings = Ember.Object.create();

      if (rowFields.length) {
        lifeCycleDisplaySettings.set('xAxis', Ember.Object.create({
          key: rowFields[0].name,
          label: rowFields[0].displayName || rowFields[0].name
        }));
      } else {
        lifeCycleDisplaySettings.set('xAxis', null);
      }

      var yAxisSettings = Ember.Object.create();

      if (!(columnFields.length || valueFields.length)) {
        lifeCycleDisplaySettings.set('yAxis', null);
      } else {
        yAxisSettings.setProperties({
          key: columnFields.length ? columnFields[0].name : null,
          label: columnFields.length ? columnFields[0].displayName || columnFields[0].name : null,
          value: valueFields.length ? valueFields[0].name : null
        });
        lifeCycleDisplaySettings.set('yAxis', yAxisSettings);
      }

      return lifeCycleDisplaySettings;
    },
    getGanttChartDisplaySettings: function getGanttChartDisplaySettings(rowFields) {
      var ganttDisplaySettings = Ember.Object.create();

      if (rowFields.length) {
        var categoryFieldName = rowFields[0] ? rowFields[0].name || rowFields[0].displayName : '';
        var taskFieldName = rowFields[1] ? rowFields[1].name || rowFields[1].displayName : '';
        var startFieldName = rowFields[2] ? rowFields[2].name || rowFields[2].displayName : '';
        var endFieldName = rowFields[3] ? rowFields[3].name || rowFields[3].displayName : '';
        ganttDisplaySettings.setProperties({
          category: categoryFieldName,
          task: taskFieldName,
          start: startFieldName,
          end: endFieldName
        });
      }

      return ganttDisplaySettings;
    },
    getSegmentationChartDisplaySettings: function getSegmentationChartDisplaySettings(rowFields, columnFields, valueFields) {
      var displaySettings = Ember.Object.create();

      if (rowFields.length) {
        displaySettings.set('category', Ember.Object.create({
          key: rowFields[0].name,
          label: rowFields[0].displayName || rowFields[0].name
        }));
      } else {
        displaySettings.set('category', null);
      }

      if (valueFields.length > 0) {
        var xAxisSettings = Ember.Object.create({
          key: valueFields[0].name,
          label: valueFields[0].displayName || valueFields[0].name,
          segmentationType: 'average',
          segmentationValue: 'null'
        });
        displaySettings.set('xAxis', xAxisSettings);
      } else {
        displaySettings.set('xAxis', null);
      }

      if (valueFields.length > 1) {
        var yAxisSettings = Ember.Object.create({
          key: valueFields[1].name,
          label: valueFields[1].displayName || valueFields[1].name,
          segmentationType: 'average',
          segmentationValue: 'null'
        });
        displaySettings.set('yAxis', yAxisSettings);
      } else {
        displaySettings.set('yAxis', null);
      }

      var quadrants = {
        topLeft: {
          name: '',
          color: '#7f66dc'
        },
        topRight: {
          name: '',
          color: '#dc6688'
        },
        bottomLeft: {
          name: '',
          color: '#67b7dc'
        },
        bottomRight: {
          name: '',
          color: '#ddaf66'
        }
      };
      displaySettings.set('quadrants', quadrants);
      return displaySettings;
    },
    getMultiAxisChartXAxisSettings: function getMultiAxisChartXAxisSettings(rowFields) {
      if (Ember.isPresent(rowFields)) {
        var xAxis = this.get('visualizationData.displaySettings').get('xAxis');
        var xAxisSettings = Ember.Object.create();
        xAxisSettings.set('key', rowFields[0].name);
        var xAxisLabel;

        if (Ember.isPresent(xAxis)) {
          xAxisLabel = xAxis.get('key') == rowFields[0].name ? xAxis.get('label') : rowFields[0].displayName || rowFields[0].name;
        } else {
          xAxisLabel = rowFields[0].displayName || rowFields[0].name;
        }

        xAxisSettings.set('label', xAxisLabel);
        return xAxisSettings;
      }

      return null;
    },
    getMultiAxisChartYAxisLeftSettings: function getMultiAxisChartYAxisLeftSettings(axisKey) {
      var valueFields = this.get('visualizationData.values');

      if (Ember.isPresent(valueFields)) {
        var yAxisLeftSettings = this.get('visualizationData.displaySettings').get(axisKey);
        var axisSettings = Ember.A();
        var yAxisLeftFields = Ember.A().addObject(valueFields[0]);

        if (Ember.isPresent(yAxisLeftSettings)) {
          yAxisLeftFields.forEach(function (field) {
            var existingField = yAxisLeftSettings.findBy('key', field.name);

            if (existingField) {
              axisSettings.pushObject(Ember.Object.create({
                key: existingField.key,
                label: existingField.label
              }));
            } else {
              axisSettings.pushObject(Ember.Object.create({
                key: field.name,
                label: field.displayName || field.name
              }));
            }
          });
        } else {
          yAxisLeftFields.forEach(function (field) {
            var setting = Ember.Object.create({
              key: field.name,
              label: field.displayName || field.name
            });
            axisSettings.pushObject(setting);
          });
        }

        return axisSettings;
      }

      return Ember.A();
    },
    getMultiAxisChartYAxisRightSettings: function getMultiAxisChartYAxisRightSettings(axisKey) {
      var valueFields = this.get('visualizationData.values');

      if (Ember.isPresent(valueFields) && valueFields.length > 1) {
        var yAxisRightSettings = this.get('visualizationData.displaySettings').get(axisKey);
        var axisSettings = Ember.A();
        var yAxisRightFields = valueFields.slice().removeAt(0);

        if (Ember.isPresent(yAxisRightSettings)) {
          yAxisRightFields.forEach(function (field) {
            var existingField = yAxisRightSettings.findBy('key', field.name);

            if (existingField) {
              axisSettings.pushObject(Ember.Object.create({
                key: existingField.key,
                label: existingField.label
              }));
            } else {
              axisSettings.pushObject(Ember.Object.create({
                key: field.name,
                label: field.displayName || field.name
              }));
            }
          });
        } else {
          yAxisRightFields.forEach(function (field) {
            var setting = Ember.Object.create({
              key: field.name,
              label: field.displayName || field.name
            });
            axisSettings.pushObject(setting);
          });
        }

        return axisSettings;
      }

      return Ember.A();
    }
  });

  _exports.default = _default;
});