define("happyfox-bi/visualizations/new/utilities/field-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getUniqueFieldName = getUniqueFieldName;

  function getUniqueFieldName(fieldName) {
    var date = new Date();
    return "".concat(fieldName).concat(date.valueOf());
  }
});