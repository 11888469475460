define("happyfox-bi/components/data-table-new/component", ["exports", "happyfox-bi/utilities/array"], function (_exports, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    columns: null,
    canBulkSelect: false,
    isCard: false,
    canPersistColumnSpecs: false,
    localStorageService: Ember.inject.service('local-storage'),
    isHeaderFixed: false,
    canSetColumnWidth: true,
    makeOverflowVisible: false,
    didInsertElement: function didInsertElement() {
      if (this.get('canSetColumnWidth')) {
        this.renderTable();
      }
    },
    // Gets column widths from the DOM and stores them in the table object.
    // When the table renders for the first time, we let the table fit in the available width (100%)
    renderTable: function renderTable() {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', function () {
        var table = _this.get('table');

        var columns = table.get('columns').toArray();

        _this.$('.ember-light-table th').each(function (index, th) {
          var columnWidth = th.offsetWidth;
          columns[index].set('width', "".concat(columnWidth, "px"));
        });

        table.setColumns(columns);
      });
    },
    rerenderTable: Ember.observer('table', function () {
      // When change view of a table, go to another queue, etc, createTableInstance is called but this component is not destroyed and rerendered. Hence this observer.
      if (this.get('table')) {
        this.renderTable();
      }
    }),
    rowComponent: Ember.computed('canBulkSelect', {
      get: function get() {
        return this.get('canBulkSelect') ? 'table-bulk-selection-row' : 'data-table-new/row';
      }
    }),
    isRowClickable: Ember.computed('rowAction', {
      get: function get() {
        return Ember.isPresent(this.rowAction);
      }
    }),
    sortTable: function sortTable(column) {
      var sortFields = [];
      var table = this.get('table');
      var rows = table.get('rows').toArray();
      var isDeletingRows = rows.filterBy('isDeleting');
      var sortedRows;

      if (column) {
        var primarySortFields = column.get('primaryColumnSortKeys');

        if (Ember.isPresent(primarySortFields)) {
          sortFields.addObjects(primarySortFields);
        }

        sortFields.push({
          field: column.get('id'),
          order: column.ascending ? 'asc' : 'desc'
        }); // Currently, it is understood the current column's sort field will always be taken into account. If a conflicting use case arises, please add a boolean isColumnSortKeyInvalid or similar
      }

      var defaultSortFields = table.get('tableProperties.defaultSortKeys');

      if (Ember.isPresent(defaultSortFields)) {
        sortFields.addObjects(defaultSortFields);
      }

      sortedRows = (0, _array.sortArrayOfObjects)(rows, sortFields);
      sortedRows.removeObjects(isDeletingRows).addObjects(isDeletingRows);
      table.setRows(sortedRows);
    },
    actions: {
      rowAction: function rowAction(row, event) {
        if (this.rowAction && !(event.ctrlKey || event.metaKey)) {
          // Cmd or Ctrl + Click can be used for bulk selection
          this.rowAction(row.content);
        }
      },
      onColumnResized: function onColumnResized() {
        // In the future, it is possible that column widths will be stored in the server
        if (this.get('canPersistColumnSpecs')) {
          var table = this.get('table');
          var columns = table.get('columns').toArray();
          var columnSpecs = columns.filterBy('id').map(function (column) {
            return {
              'id': column.id,
              'width': column.width
            };
          });
          var localStorageService = this.get('localStorageService');
          var selectedView = table.get('preferences.selectedView');

          if (Ember.isPresent(selectedView)) {
            localStorageService.setItem("hf-".concat(this.get('tableId'), "-").concat(selectedView, "-column-specs"), JSON.stringify(columnSpecs));
          } else {
            localStorageService.setItem("hf-".concat(this.get('tableId'), "-column-specs"), JSON.stringify(columnSpecs));
          }
        }
      },
      onColumnClick: function onColumnClick(column) {
        if (column.sorted) {
          var table = this.get('table');
          var field = column.id;
          var order = column.ascending ? 'asc' : 'desc';

          if (table.get('tableProperties.isLocalSort')) {
            this.sortTable(column);
          } else {
            this.changeSort({
              field: field,
              order: order
            });
          }

          if (table.get('tableProperties.canPersistSort')) {
            this.persistSort({
              field: field,
              order: order
            });
          }
        }
      },
      // Following 3 functions will be called from 'table-settings' component
      // To switch between default and custom
      selectView: function selectView(view) {
        if (this.selectView) {
          this.selectView(view);
        }
      },
      updateColumnSelection: function updateColumnSelection(columnData) {
        if (this.updateColumnSelection) {
          this.updateColumnSelection(columnData);
        }
      },
      updateColumnPosition: function updateColumnPosition(columnData) {
        if (this.updateColumnPosition) {
          this.updateColumnPosition(columnData);
        }
      },
      fetchData: function fetchData(params) {
        if (this.fetchData) {
          this.fetchData(params);
        }
      }
    }
  });

  _exports.default = _default;
});