define("happyfox-bi/visualizations/new/components/display-settings/partials/legend/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IqRRCETW",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"field toggle-field\"],[12],[2,\"\\n    \"],[10,\"div\"],[12],[1,[30,[36,0],[\"hide-legend\"],null]],[13],[2,\"\\n    \"],[1,[30,[36,3],[\"toggle-input\"],[[\"tagName\",\"isChecked\",\"onChange\"],[\"div\",[35,2,[\"hideLegend\"]],[30,[36,1],[[32,0],\"onHideLegendChange\"],null]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"settings\",\"component\",\"canConfigureLegend\",\"if\"]}",
    "meta": {
      "moduleName": "happyfox-bi/visualizations/new/components/display-settings/partials/legend/template.hbs"
    }
  });

  _exports.default = _default;
});