define("happyfox-bi/visualizations/new/components/table-formatting/format-options-modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DEFAULT_BAR_COLOR = '#ff9900';

  var _default = Ember.Component.extend({
    intlService: Ember.inject.service('intl'),
    allFormats: null,
    format: null,
    editedFormat: null,
    fieldOptions: null,
    invalidMinMaxError: null,
    init: function init() {
      this._super.apply(this, arguments);

      var format = this.format;
      var fieldOptions = this.fieldOptions ? this.getFieldOptions() : Ember.A();
      var editedFormat;

      if (format) {
        editedFormat = Ember.Object.create({
          showDataBar: format.showDataBar,
          field: fieldOptions.findBy('name', format.field.name),
          barColor: format.barColor,
          minimumValue: format.minimumValue,
          maximumValue: format.maximumValue,
          showOnlyBars: format.showOnlyBars
        });
      } else {
        editedFormat = this.getDefaultFormat();
      }

      this.setProperties({
        editedFormat: editedFormat,
        fieldOptions: fieldOptions
      });
    },
    isDisabled: Ember.computed('invalidMinMaxError', 'editedFormat.{field,minimumValue,maximumValue}', {
      get: function get() {
        var editedFormat = this.editedFormat,
            invalidMinMaxError = this.invalidMinMaxError;

        if (!Ember.isPresent(editedFormat.field)) {
          return true;
        } else if (!Ember.isPresent(editedFormat.minimumValue)) {
          return true;
        } else if (!Ember.isPresent(editedFormat.maximumValue)) {
          return true;
        }

        return Ember.isPresent(invalidMinMaxError);
      }
    }),
    getFieldOptions: function getFieldOptions() {
      var fieldOptions = this.fieldOptions,
          allFormats = this.allFormats,
          currentFormat = this.format;

      if (Ember.isPresent(allFormats)) {
        var fieldsUsedForFormat = allFormats.mapBy('field');

        if (currentFormat) {
          fieldsUsedForFormat = fieldsUsedForFormat.filter(function (field) {
            return field.name !== currentFormat.field.name;
          });
        }

        return fieldOptions.map(function (option) {
          var doesFormattingExistsForField = Ember.isPresent(fieldsUsedForFormat.findBy('name', option.name));
          return Ember.Object.create({
            name: option.name,
            displayName: option.displayName,
            disabled: doesFormattingExistsForField
          });
        });
      }

      return fieldOptions.map(function (option) {
        return Ember.Object.create({
          name: option.name,
          displayName: option.displayName,
          disabled: false
        });
      });
    },
    getDefaultFormat: function getDefaultFormat() {
      return Ember.Object.create({
        field: null,
        barColor: DEFAULT_BAR_COLOR,
        showDataBar: true,
        minimumValue: null,
        maximumValue: null,
        showOnlyBars: false
      });
    },
    getEditedFormat: function getEditedFormat() {
      var editedFormat = this.editedFormat;
      var barColor = editedFormat.barColor,
          minimumValue = editedFormat.minimumValue,
          maximumValue = editedFormat.maximumValue,
          showOnlyBars = editedFormat.showOnlyBars,
          field = editedFormat.field;
      var format = Ember.Object.create({
        field: editedFormat.field ? Ember.Object.create({
          name: field.name,
          displayName: field.displayName
        }) : null,
        barColor: barColor,
        showDataBar: true,
        minimumValue: minimumValue,
        maximumValue: maximumValue,
        showOnlyBars: showOnlyBars
      });
      return format;
    },
    actions: {
      closeModal: function closeModal() {
        this.onCloseModal();
      },
      onFieldChange: function onFieldChange(field) {
        this.editedFormat.set('field', field);
      },
      onMinimumValueChange: function onMinimumValueChange(value) {
        this.editedFormat.set('minimumValue', value ? Number(value) : null);
      },
      onMaximumValueChange: function onMaximumValueChange(value) {
        this.editedFormat.set('maximumValue', value ? Number(value) : null);
      },
      validateMinMax: function validateMinMax() {
        var _this$editedFormat = this.editedFormat,
            minimumValue = _this$editedFormat.minimumValue,
            maximumValue = _this$editedFormat.maximumValue;

        if (Ember.isPresent(minimumValue) && Ember.isPresent(maximumValue) && minimumValue >= maximumValue) {
          this.set('invalidMinMaxError', this.intlService.lookup('validation.invalid-number-min-max-message'));
        } else {
          this.set('invalidMinMaxError', null);
        }
      },
      onShowOnlyBarsChange: function onShowOnlyBarsChange(isChecked) {
        this.editedFormat.set('showOnlyBars', isChecked);
      },
      onBarColorChange: function onBarColorChange(color) {
        this.editedFormat.set('barColor', color);
      },
      updateFormat: function updateFormat() {
        var format = this.getEditedFormat();
        this.onUpdateFormat(format);
      },
      addFormat: function addFormat() {
        var format = this.getEditedFormat();
        this.onAddFormat(format);
      }
    }
  });

  _exports.default = _default;
});