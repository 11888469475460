define("happyfox-bi/components/stacked-bar-chart/component", ["exports", "happyfox-bi/components/base-column-chart/component", "@amcharts/amcharts4/core", "@amcharts/amcharts4/charts", "happyfox-bi/constants"], function (_exports, _component, am4core, am4charts, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _component.default.extend({
    useSeriesNameAsTooltipLabel: true,
    selectedDateFormat: null,
    isStacked: true,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.createGraph();
    },
    isYAxisCurrencyType: function isYAxisCurrencyType() {
      var _this = this;

      var _this$get = this.get('config'),
          yAxis = _this$get.yAxis,
          groups = _this$get.groups;

      if (yAxis.value) {
        return this.isFieldCurrencyType(yAxis.value);
      }

      var valueKeys = groups.mapBy('key');
      return valueKeys.every(function (valueKey) {
        return _this.isFieldCurrencyType(valueKey);
      });
    },
    isYAxisTimeType: function isYAxisTimeType() {
      var _this2 = this;

      var _this$get2 = this.get('config'),
          yAxis = _this$get2.yAxis,
          groups = _this$get2.groups;

      if (yAxis.value) {
        return this.isFieldTimeType(yAxis.value);
      }

      var valueKeys = groups.mapBy('key');
      return valueKeys.every(function (valueKey) {
        return _this2.isFieldTimeType(valueKey);
      });
    },
    isDurationYAxis: function isDurationYAxis() {
      var _this3 = this;

      var _this$get3 = this.get('config'),
          yAxis = _this$get3.yAxis,
          groups = _this$get3.groups;

      if (yAxis.value) {
        return this.canFormatTimeAsDuration(yAxis.value);
      }

      var valueKeys = groups.mapBy('key');
      return valueKeys.every(function (valueKey) {
        return _this3.canFormatTimeAsDuration(valueKey);
      });
    },
    getDateFormat: function getDateFormat(fromDate, toDate) {
      var dateFormat = 'date';
      var difference = moment(toDate).diff(fromDate, 'days');

      if (difference > 700) {
        dateFormat = 'year';
      } else if (difference > 30) {
        dateFormat = 'month_with_year';
      } else if (difference < 2) {
        dateFormat = 'hour_with_date';
      }

      return dateFormat;
    },
    createGraph: function createGraph() {
      var _this4 = this;

      var chartContainer = this.get('chartContainer');
      var chart = am4core.create(chartContainer, am4charts.XYChart);

      var _this$get4 = this.get('config'),
          xAxisKey = _this$get4.xAxis.key,
          _this$get4$yAxis = _this$get4.yAxis,
          groupBy = _this$get4$yAxis.groupBy,
          valueKey = _this$get4$yAxis.value,
          groups = _this$get4.groups,
          areAxesInverted = _this$get4.areAxesInverted;

      var data = Ember.isPresent(groups) ? this.data : this.getTransformedData();

      if (areAxesInverted) {
        var chartHeight = this.$('.chart_container').height();
        var neededHeight = data.length * 50;

        if (neededHeight > chartHeight) {
          this.$('.chart_container').height(neededHeight);
          this.$().css('overflow-y', 'auto');
        }
      } // Create axes


      var isDateAxis = this.isXAxisDateType();
      var axis = isDateAxis ? new am4charts.DateAxis() : new am4charts.CategoryAxis();
      axis = areAxesInverted ? chart.yAxes.push(axis) : chart.xAxes.push(axis);

      if (isDateAxis) {
        this.prepareDateAxis(axis);
        data = this.convertAndGetDateXAxisData(data, xAxisKey);
        chart.data = data;
      } else {
        axis.dataFields.category = xAxisKey;
        chart.data = data;
      }

      var axisTitle = this.get('config.xAxis.label');
      this.setAxisTitle(axis, axisTitle);
      axis.renderer.minGridDistance = 30;
      axis.renderer.grid.template.location = 0;
      axis.renderer.cellStartLocation = 0.2;
      axis.renderer.cellEndLocation = 0.8;

      if (!areAxesInverted) {
        var label = axis.renderer.labels.template;
        label.truncate = true;
        label.maxWidth = 120;
        label.tooltipText = "{category}";
        label.rotation = 315;
        label.verticalCenter = "top";
        label.horizontalCenter = "right";
      }

      var isYAxisTimeType = this.isYAxisTimeType();
      var isDurationYAxis = this.isDurationYAxis();
      var valueAxisType = isDurationYAxis ? new am4charts.DurationAxis() : new am4charts.ValueAxis();
      var valueAxis = areAxesInverted ? chart.xAxes.push(valueAxisType) : chart.yAxes.push(valueAxisType);
      this.setAxisRanges(axis, valueAxis);
      var valueAxisTitle = this.get('config.yAxis.label');
      this.setAxisTitle(valueAxis, valueAxisTitle);

      if (areAxesInverted) {
        valueAxis.renderer.opposite = true;
      }

      var showValueLabels = this.get('showValueLabels');
      var yAxisExtraMax = this.get('yAxisExtraMax');

      if (showValueLabels) {
        valueAxis.extraMax = yAxisExtraMax; // Expands YAxis Scale by adding 1% to y-axis max value to support value label text
      }

      var isValueAxisCurrencyType = this.isYAxisCurrencyType();

      if (isValueAxisCurrencyType && groupBy) {
        this.setElementNumberFormatAsCurrency(chart, valueKey);
      } else if (isValueAxisCurrencyType) {
        this.renderCurrencyInAxisLabels(valueAxis);
      }

      if (isYAxisTimeType) {
        this.formatValueAxisAsTime(valueAxis);
      }

      if (this.usePercentageSeries) {
        valueAxis.min = 0;
        valueAxis.max = 100;
        valueAxis.strictMinMax = true;
        valueAxis.calculateTotals = true;
        valueAxis.numberFormatter.numberFormat = "#'%'";
      } // Create series


      this.createColumnSeries(chart); // Legend

      var isLegendHidden = this.getConfigValue('hideLegend', false);

      if (!isLegendHidden) {
        this.createLegend(chart);
        var textLink = chart.legend.createChild(am4core.TextLink);
        textLink.text = "Toggle All";
        textLink.valign = 'top';
        textLink.marginTop = 10;
        textLink.marginRight = 10;
        var areSeriesHidden = false;
        textLink.events.on("hit", function () {
          areSeriesHidden = !areSeriesHidden;
          var seriesCollection = _this4.seriesCollection;

          if (areSeriesHidden) {
            seriesCollection.forEach(function (series) {
              series.hide();
            });
          } else {
            seriesCollection.forEach(function (series) {
              series.show();
            });
          }
        });
      }

      if (this.canManageBenchmarks) {
        this.setUpBenchmarks(chart, valueAxis);
      }

      chart.cursor = new am4charts.XYCursor();
      this.addExportOption(chart);
      this.addAnnotationsOption(chart);
      this.chart = chart;
    },
    createColumnSeries: function createColumnSeries(chart) {
      var _this5 = this;

      var _this$get5 = this.get('config'),
          xAxisKey = _this$get5.xAxis.key,
          _this$get5$yAxis = _this$get5.yAxis,
          groupBy = _this$get5$yAxis.groupBy,
          valueKey = _this$get5$yAxis.value,
          columnSeries = _this$get5.columnSeries,
          configColors = _this$get5.colors,
          areAxesInverted = _this$get5.areAxesInverted,
          isGrouped = _this$get5.isGrouped,
          groups = _this$get5.groups;

      var showValueLabels = this.get('showValueLabels');
      var isDateAxis = this.isXAxisDateType();
      var isPercentSeries = this.usePercentageSeries;
      var seriesCollection = Ember.A();
      columnSeries.forEach(function (item, index) {
        var series = chart.series.push(new am4charts.ColumnSeries());

        if (areAxesInverted) {
          series.dataFields.valueX = item;

          if (isDateAxis) {
            series.dataFields.dateY = xAxisKey;
          } else {
            series.dataFields.categoryY = xAxisKey;
          }
        } else {
          series.dataFields.valueY = item;

          if (isDateAxis) {
            series.dataFields.dateX = xAxisKey;
          } else {
            series.dataFields.categoryX = xAxisKey;
          }
        } // Handling group by (or) multiple values


        if (groupBy) {
          series.name = item; // For `groupBy`, charts has no info on what value field is being used
          // This info will be handy for any formatting based on type (time/currency/etc)

          series.dataFields.valueKey = _this5.get('config.yAxis.value');
        } else {
          var groupItem = groups.findBy('key', item);
          series.name = groupItem.label;

          if (_this5.isFieldCurrencyType(groupItem.key)) {
            _this5.setElementNumberFormatAsCurrency(series, groupItem.key);
          }
        } // If stacked percent chart is used


        if (isPercentSeries) {
          series.dataFields.valueYShow = "totalPercent";
          /* 
            When groupBy exists, series doesnt know what is the aggregation field key used.
            Aggregation field key in series will be helpful in determining what type of field is used thereby deciding what formatting to be done
          */

          if (groupBy) {
            series.dataFields.valueKey = valueKey;
          }
        }

        _this5.setSeriesTooltipText(series);

        if (_this5.isStacked) {
          series.stacked = true;
        } // FIXME: Verify how to limit width to 40px
        // let columnWidth = 40;
        // series.columns.template.maxWidth = columnWidth;
        // if (index === columnSeries.length - 1) {
        //   series.columns.template.column.cornerRadiusTopLeft = 6;
        //   series.columns.template.column.cornerRadiusTopRight = 6;
        // }


        if (configColors) {
          var seriesColor = configColors[index];

          if (seriesColor) {
            series.fill = seriesColor;
            series.stroke = seriesColor;
          }
        }

        if (_this5.get('canViewRawData') && !areAxesInverted) {
          series.columns.template.events.on("hit", function (ev) {
            _this5.handleColumnHit(ev);
          });
        }

        if (isGrouped && showValueLabels) {
          _this5.createValueLabels(series);
        }

        seriesCollection.addObject(series);
      });
      this.set('seriesCollection', seriesCollection);
    },
    handleColumnHit: function handleColumnHit(event) {
      var _this$get6 = this.get('config'),
          xAxisKey = _this$get6.xAxis.key,
          groupBy = _this$get6.yAxis.groupBy;

      var series = event.target;
      var isDateAxis = this.isXAxisDateType();
      var dataFields = series.dataItem.component.dataFields;
      var categoryKey = xAxisKey;
      var groupByKey = groupBy;
      var filterConditions = [];

      if (groupByKey) {
        var groupByValue = dataFields.valueY;
        var condition = {
          field: groupByKey,
          value: groupByValue,
          predicate: this.getFieldType(groupByKey) === 'list' ? 'contains' : 'is'
        };

        if (groupByValue === _constants.NO_VALUE_SET) {
          condition.value = null;
          condition.predicate = 'is not set';
        }

        filterConditions.push(condition);
      }

      if (isDateAxis) {
        var categoryValue = series.dataItem.dateX;
        var dateField = this.get('fields').findBy('name', categoryKey);
        var xAxisFilterCondition = {
          field: categoryKey,
          value: {
            from: null,
            to: null
          },
          predicate: 'between'
        };
        var format = this.get('selectedDateFormat') || dateField.get('format');

        if (format === 'auto') {
          var actualDateRange = this.get('actualDateRange');
          format = this.getDateFormat(actualDateRange.fromDate, actualDateRange.toDate);
        }

        var startTime = this.chart.data.findBy(categoryKey, categoryValue).hfFeDateinUserTz;
        var endTime;

        if (format === 'hour_with_date') {
          endTime = moment(startTime).endOf('hour');
        } else if (format === 'date') {
          endTime = moment(startTime).endOf('day');
        } else if (format === 'month_with_year') {
          endTime = moment(startTime).endOf('month');
        } else if (format === 'year') {
          endTime = moment(startTime).endOf('year');
        } else if (format === 'week_with_month' || format === 'week_with_year') {
          endTime = moment(startTime).endOf('week');
        }

        xAxisFilterCondition.value.from = startTime.toISOString();
        xAxisFilterCondition.value.to = endTime.toISOString();
        filterConditions.push(xAxisFilterCondition);
      } else {
        var _categoryValue = series.dataItem.categoryX;
        filterConditions = filterConditions.concat(this.getFiltersForFieldValue(categoryKey, _categoryValue));
      }

      this.filterOnElementHit(filterConditions);
    },
    updateConfig: function updateConfig() {
      var config = Object.assign({}, this.get('config'));
      var groupByKey = config.yAxis.groupBy;
      var columnSeries = Ember.A();

      if (groupByKey) {
        this.get('data').forEach(function (datum) {
          var columnValue = datum[groupByKey];

          if (Ember.isPresent(columnValue) && typeof columnValue === 'boolean') {
            columnValue = columnValue.toString();
          }

          columnSeries.addObject(columnValue);
        });
      } else {
        var groups = this.get('config.groups');
        (groups || []).forEach(function (group) {
          var columnValue = group['key'];

          if (Ember.isPresent(columnValue) && typeof columnValue === 'boolean') {
            columnValue = columnValue.toString();
          }

          columnSeries.addObject(columnValue);
        });
      }

      config.columnSeries = columnSeries;
      this.set('config', config);
    },
    replaceNullInRowsAndColumns: function replaceNullInRowsAndColumns() {
      var isDateXAxis = this.isXAxisDateType();
      var groupByKey = this.get('config.yAxis.groupBy');

      if (!isDateXAxis) {
        this.set('data', this.setNullValueByKey(this.get('config.xAxis.key')));
      }

      if (groupByKey) {
        this.set('data', this.setNullValueByKey(groupByKey));
      }
    },
    setNullValueByKey: function setNullValueByKey(key) {
      var excludeNullValues = this.get('config.excludeNullValues');
      this.get('data').forEach(function (item) {
        if (item[key] === null) {
          if (!excludeNullValues) {
            item[key] = _constants.NO_VALUE_SET;
          }
        }
      });
      return this.get('data');
    },
    renderCurrencyInAxisLabels: function renderCurrencyInAxisLabels(axis) {
      var _this$get7 = this.get('config'),
          groups = _this$get7.groups;

      var valueKeys = groups.mapBy('key');

      for (var i = 0; i < valueKeys.length; i++) {
        if (this.isFieldCurrencyType(valueKeys[i])) {
          this.setElementNumberFormatAsCurrency(axis, valueKeys[i]);
          break;
        }
      }
    },
    formatValueAxisAsTime: function formatValueAxisAsTime(axis) {
      var _this$get8 = this.get('config.yAxis'),
          valueKey = _this$get8.value;

      var _this$get9 = this.get('config'),
          groups = _this$get9.groups;

      var valueKeys = Ember.A().addObject(valueKey).compact();
      (groups || []).forEach(function (item) {
        valueKeys.addObject(item.key);
      });

      for (var i = 0; i < valueKeys.length; i++) {
        var isYAxisTimeType = this.isFieldTimeType(valueKeys[i]);
        var isDurationYAxis = this.canFormatTimeAsDuration(valueKeys[i]);

        if (isDurationYAxis) {
          this.setupDurationFormatter(axis, valueKeys[i]);
          return;
        } else if (isYAxisTimeType) {
          this.setUnitsInAxisLabels(axis, this.getTimeFieldBaseUnit(valueKeys[i]));
          return;
        }
      }
    },
    getTransformedData: function getTransformedData() {
      var originalData = this.get('data');
      var groupByKey = this.get('config.yAxis.groupBy');
      var valueKey = this.get('config.yAxis.value');
      var categoryAxisKey = this.get('config.xAxis.key');
      var topNInGroups = this.get('config.topNInGroups') && this.get('config.topNInGroups.n');

      if (topNInGroups && this.get('config.columnSeries.length') > topNInGroups) {
        var list = Ember.A();
        originalData.forEach(function (datum) {
          var datumObject = list.findBy(groupByKey, datum[groupByKey]);

          if (datumObject) {
            datumObject[valueKey] += datum[valueKey];
          } else {
            var _list$addObject;

            list.addObject((_list$addObject = {}, _defineProperty(_list$addObject, groupByKey, datum[groupByKey]), _defineProperty(_list$addObject, valueKey, datum[valueKey]), _list$addObject));
          }
        });
        list = list.sortBy(valueKey).reverse();
        var topNGroupValues = list.slice(0, topNInGroups).mapBy(groupByKey);
        var dataWithOthers = Ember.A();
        this.get('data').forEach(function (item) {
          if (topNGroupValues.includes(item[groupByKey])) {
            dataWithOthers.addObject(item);
          } else {
            var itemCategoryValue = item[categoryAxisKey];
            var itemValue = item[valueKey];
            var othersData = dataWithOthers.filterBy(categoryAxisKey, itemCategoryValue).findBy(groupByKey, '# - Others');

            if (othersData) {
              othersData[valueKey] += itemValue;
            } else {
              var othersItem = Object.assign({}, item);
              othersItem[groupByKey] = '# - Others';
              dataWithOthers.addObject(othersItem);
            }
          }
        });
        originalData = dataWithOthers;
        var config = Object.assign({}, this.get('config'));
        topNGroupValues.push('# - Others');
        config.columnSeries = topNGroupValues;
        this.set('config', config);
      }

      var transformedData = Ember.A();
      originalData.forEach(function (datum) {
        var categoryValue = datum[categoryAxisKey];

        var datumObject = transformedData.findBy(categoryAxisKey, categoryValue) || _defineProperty({}, categoryAxisKey, categoryValue);

        var groupValue = datum[groupByKey];
        var value = datum[valueKey];
        datumObject[groupValue] = value;
        transformedData.addObject(datumObject);
      });
      return transformedData;
    }
  });

  _exports.default = _default;
});