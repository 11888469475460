define("happyfox-bi/components/widget-tile/raw-data/link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WfvBhtHM",
    "block": "{\"symbols\":[],\"statements\":[[10,\"a\"],[15,6,[34,0]],[14,0,\"hf-primary-action\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener\"],[12],[1,[34,1]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"link\",\"linkText\"]}",
    "meta": {
      "moduleName": "happyfox-bi/components/widget-tile/raw-data/link/template.hbs"
    }
  });

  _exports.default = _default;
});