define("happyfox-bi/reports/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mVtIspcp",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"u-hv-centered\"],[12],[2,\"\\n\"],[6,[37,2],[[32,0,[\"isReportsLoading\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],[\"ball-triangle-loader\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,0],[\"no-reports-available\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"inline-svg\",\"if\"]}",
    "meta": {
      "moduleName": "happyfox-bi/reports/index/template.hbs"
    }
  });

  _exports.default = _default;
});