define("happyfox-bi/data-sources/components/data-source/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    dataService: Ember.inject.service('data'),
    dataSource: null,
    dataSets: null,
    loadingDataSets: false,
    showCreateDataSet: false,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var dataSourceId = this.get('dataSource.id');
      this.set('loadingDataSets', true);
      this.get('dataService').getDataSets(dataSourceId, true).then(function (dataSets) {
        _this.set('dataSets', dataSets);
      }).finally(function () {
        _this.set('loadingDataSets', false);
      });
    },
    actions: {
      toggleCreateDataSet: function toggleCreateDataSet() {
        this.toggleProperty('showCreateDataSet');
      },
      reloadDataSets: function reloadDataSets() {
        var dataSourceId = this.get('dataSource.id');
        var dataSets = this.get('dataService').peekDataSetsForDataSource(dataSourceId);
        this.set('dataSets', dataSets);
      },
      goToDataSets: function goToDataSets() {
        var dataSourceId = this.get('dataSource.id');
        this.goToDataSets(dataSourceId);
      }
    }
  });

  _exports.default = _default;
});