define("happyfox-bi/visualizations/components/create-edit-derived-field/component", ["exports", "happyfox-bi/utilities/object", "happyfox-bi/visualizations/components/create-edit-derived-field/constant"], function (_exports, _object, _constant) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    featuresService: Ember.inject.service('features'),
    visualizationData: null,
    allElementaryFields: null,
    selectedElementaryField: null,
    selectedAggregationType: null,
    formulaReturnTypes: _constant.FORMULA_FIELD_DATA_TYPES,
    fieldTypes: Ember.computed('featuresService.editFormulaField', {
      get: function get() {
        if (this.get('featuresService.editFormulaField')) {
          return _constant.FIELD_TYPES;
        }

        return _constant.FIELD_TYPES.slice(0, 1);
      }
    }),
    isSaveDisabled: Ember.computed('selectedAggregationType', 'selectedElementaryField', 'selectedFieldType', 'selectedFormulaDataType', 'formula', 'fieldName', {
      get: function get() {
        if (this.get('selectedFieldType.id') == 'aggregation') {
          return !this.get('selectedElementaryField') || !this.get('selectedAggregationType');
        }

        return !this.get('selectedFieldType') || !this.get('formula') || !this.get('selectedFormulaDataType') || !this.get('fieldName');
      }
    }),
    resetForm: function resetForm() {
      this.setProperties({
        selectedAggregationType: null,
        selectedElementaryField: null,
        selectedFieldType: null,
        selectedFormulaDataType: null,
        fieldName: '',
        formula: ''
      });
    },
    actions: {
      initForm: function initForm() {
        if (this.get('isEdit')) {
          var selectedField = this.get('selectedDerivedField');
          selectedField = (0, _object.camelizeObject)(selectedField);

          var selectedFieldType = _constant.FIELD_TYPES.findBy('id', selectedField.get('type'));

          var showFormulaArea = false;
          var formula,
              selectedFormulaDataType,
              selectedElementaryField = null;

          if (selectedFieldType.id === 'aggregation') {
            selectedElementaryField = this.get('allElementaryFields').findBy('datasetField', selectedField.get('datasetField'));
            this.send('onElementaryFieldChange', selectedElementaryField);
            this.set('selectedAggregationType', _constant.AGGREGATION_TYPES.findBy('id', selectedField.get('aggregationType')));
          } else {
            formula = selectedField.get('sql');
            selectedFormulaDataType = _constant.FORMULA_FIELD_DATA_TYPES.findBy('id', selectedField.get('dataType'));
            showFormulaArea = true;
          }

          this.setProperties({
            fieldName: selectedField.get('name'),
            selectedFieldType: selectedFieldType,
            formula: formula,
            selectedFormulaDataType: selectedFormulaDataType,
            showFormulaArea: showFormulaArea
          });
        }
      },
      addDerivedField: function addDerivedField(popOver) {
        var fieldType = this.get('selectedFieldType.id');
        var derivedField = Ember.Object.create({});
        var isEdit = this.get('isEdit');

        if (isEdit) {
          derivedField = this.get('selectedDerivedField');
        }

        derivedField.set('type', fieldType);
        derivedField.set('isEditable', false);
        derivedField.set('dataset', this.get('visualizationData.dataSet.id'));
        derivedField.set('name', this.get('fieldName'));

        if (fieldType == 'aggregation') {
          var elementaryField = this.get('selectedElementaryField');
          var aggregationType = this.get('selectedAggregationType.id');
          var isCountTypeAggregationType = aggregationType === 'count' || aggregationType === 'distinct_count';
          var dataType = elementaryField.get('dataType') === 'time' && !isCountTypeAggregationType ? 'time' : 'decimal';
          derivedField.setProperties({
            'dataset': elementaryField.get('dataset'),
            dataType: dataType,
            aggregationType: aggregationType,
            'datasetField': elementaryField.get('datasetField')
          });

          if (elementaryField.hasOwnProperty('format')) {
            derivedField.format = elementaryField.format;
          }

          if (elementaryField.hasOwnProperty('timeUnit')) {
            derivedField.timeUnit = elementaryField.timeUnit;
          }

          if (elementaryField.get('parentDataset') && elementaryField.get('parentDatasetField')) {
            derivedField.setProperties({
              'parentDataset': elementaryField.get('parentDataset'),
              'parentDatasetField': elementaryField.get('parentDatasetField')
            });
          }
        } else {
          derivedField.set('dataType', this.get('selectedFormulaDataType.id'));
          derivedField.set('sql', this.get('formula'));
        }

        if (isEdit) {
          this.onAdd();
        } else {
          this.onAdd(derivedField);
        }

        this.resetForm();
        popOver.closePopOver();
      },
      cancelDerivedField: function cancelDerivedField(popOver) {
        this.resetForm();
        popOver.closePopOver();
      },
      onFieldTypeChange: function onFieldTypeChange(type) {
        this.set('selectedFieldType', type);

        if (type.id === 'aggregation') {
          this.set('showFormulaArea', false);
        } else {
          this.set('showFormulaArea', true);
        }
      },
      onElementaryFieldChange: function onElementaryFieldChange(selectedField) {
        this.set('selectedElementaryField', selectedField);
        var type = selectedField.dataType;

        if (['time', 'int', 'decimal'].includes(type)) {
          this.setProperties({
            aggregationTypes: _constant.AGGREGATION_TYPES,
            selectedAggregationType: null
          });
        } else {
          var countType = _constant.AGGREGATION_TYPES.filter(function (type) {
            return type.id === 'count' || type.id === 'distinct_count';
          });

          this.setProperties({
            aggregationTypes: countType,
            selectedAggregationType: countType[0]
          });
        }

        this.set('showFormulaArea', false);
      },
      onAggregationTypeChange: function onAggregationTypeChange(selectedType) {
        this.set('selectedAggregationType', selectedType);

        if (!this.get('fieldName')) {
          this.set('fieldName', "".concat(this.get('selectedElementaryField.name')).concat(this.get('selectedAggregationType.name')));
        }
      },
      onFormulaTypeChange: function onFormulaTypeChange(type) {
        this.set('selectedFormulaDataType', type);
      }
    }
  });

  _exports.default = _default;
});