define("happyfox-bi/utilities/date", ["exports", "happyfox-bi/utilities/time", "happyfox-bi/constants"], function (_exports, _time, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.convertDateToApiFormat = convertDateToApiFormat;
  _exports.createDate = createDate;
  _exports.getFormattedDate = getFormattedDate;

  function getCurrentDate() {
    var now = moment((0, _time.getCurrentDateTime)()); // Applying timezone offset

    return new Date(now.year(), now.month(), now.date()); // Not using now.toDate() since it removes the timezone offset
  } // WARNING: Not a replacement for new Date()
  // If input is datetimestring, feel free to use new Date()
  // If there is no input and if you need current time as a JS date object, use getCurrentDateTime() in utilities/time
  // If input is datestring and if you need a JS date object from it, use this function
  // If there is no input and if you need today's date (without time component) as a date object, use this function
  // Input: Date string YYYY-MM-DD
  // Output: JS DateTime set to the beginning of the day (timezone agnostic)


  function createDate() {
    var dateString = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

    if (dateString) {
      if (dateString instanceof Date) {
        return dateString;
      }

      if (typeof dateString === 'number') {
        return new Date(dateString);
      }

      if (dateString.includes('T')) {
        // Removing the time component
        dateString = dateString.split('T')[0];
      }

      var dateStringArray = dateString.split('-');
      return new Date(dateStringArray[0], dateStringArray[1] - 1, dateStringArray[2]);
    } else {
      return getCurrentDate();
    }
  } // Input: JS DateTime
  // Output: String specifying the date contained in the input agnostic of timezone


  function convertDateToApiFormat(date) {
    if (typeof date === 'string') {
      date = createDate(date);
    }

    if (date instanceof moment) {
      return date.format(_constants.API_DATE_FORMAT);
    }

    if (Ember.isPresent(date)) {
      var month = date.getMonth() + 1;
      var monthString = "0".concat(month.toString()).slice(-2);
      var dateString = "0".concat(date.getDate().toString()).slice(-2);
      return "".concat(date.getFullYear(), "-").concat(monthString, "-").concat(dateString); // Not using moment.format() since moment will first apply the staff timezone
    } else {
      return null;
    }
  } // Inputs: '2018-11-27' or '2018-11-27T10:51:18Z' or date object
  // format="absolute" - Display date time
  // isTimeInput=true - Display date of a date time
  // Outputs: if format == absolute then "Nov 26, 2018, 9:30 AM" else "Nov 26, 2018"


  function getFormattedDate(date) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    if (date) {
      if (options.format === 'absolute') {
        return moment(date).format(_constants.DEFAULT_TIME_FORMAT);
      } else {
        if (typeof date === 'string') {
          date = createDate(date);
        }

        if (date instanceof Date && !options.isTimeInput) {
          // Converting to string in order to pass to moment as string. If we pass the date object, there's chance the date might change when moment applies offset.
          date = convertDateToApiFormat(date);
        }

        return moment(date).format(_constants.DEFAULT_DATE_FORMAT);
      }
    } else {
      return '';
    }
  }
});