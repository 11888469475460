define("happyfox-bi/components/hf-toast-message", ["exports", "@happyfoxinc/hf-toast-message/components/hf-toast-message"], function (_exports, _hfToastMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _hfToastMessage.default;
    }
  });
});