define("happyfox-bi/data-sources/details/data-sets/import/file-upload/component", ["exports", "happyfox-bi/utilities/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    acceptedFormats: '.csv',
    file: null,
    errors: null,
    handleFileUploadErrors: function handleFileUploadErrors(errors) {
      errors = (0, _object.camelizeObject)(errors);
      var uploadErrors = Ember.A();

      if (errors.file) {
        uploadErrors.addObject(errors.file);
      }

      if (errors.datasetId) {
        uploadErrors.addObject(errors.datasetId);
      }

      if (errors.errors) {
        uploadErrors.addObject(errors.errors);
      }

      this.set('errors', uploadErrors);
    },
    actions: {
      removeFile: function removeFile() {
        this.setProperties({
          file: null,
          errors: null
        });
        this.removeFile();
      },
      onFileSelection: function onFileSelection(files) {
        var _this = this;

        var file = files.length ? files[0] : null;
        this.set('file', file);
        this.onFileSelection(file).catch(function (_ref) {
          var errors = _ref.errors;

          _this.handleFileUploadErrors(errors);
        });
      }
    }
  });

  _exports.default = _default;
});