define("happyfox-bi/components/funnel-chart/component", ["exports", "happyfox-bi/components/base-chart/component", "@amcharts/amcharts4/core", "@amcharts/amcharts4/charts", "happyfox-bi/constants"], function (_exports, _component, am4core, am4charts, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.createChart();
    },
    createChart: function createChart() {
      var _this = this;

      var chartContainer = this.get('chartContainer');
      var chart = am4core.create(chartContainer, am4charts.SlicedChart);
      chart.data = this.get('data');
      var series = chart.series.push(new am4charts.FunnelSeries());

      var _this$get = this.get('config'),
          valueKey = _this$get.value,
          categoryKey = _this$get.category,
          orientation = _this$get.orientation,
          hideLabels = _this$get.hideLabels,
          hideLegend = _this$get.hideLegend;

      var isValueTimeType = this.isFieldTimeType(valueKey);
      var isDurationValue = this.canFormatTimeAsDuration(valueKey);

      if (isDurationValue) {
        this.setupDurationFormatter(chart, valueKey);
      }

      series.dataFields.value = valueKey;
      series.dataFields.category = categoryKey;

      if (!hideLabels) {
        this.updateSeriesLabels(series);
      } else {
        series.labels.template.disabled = true;
        series.ticks.template.disabled = true;
      }

      if (isValueTimeType) {
        series.slices.template.adapter.add('tooltipText', function (text, target) {
          var tooltipDataItem = target.tooltipDataItem;

          if (tooltipDataItem && tooltipDataItem.value) {
            return "{category}: {value.percent.formatNumber('#.0')}% (".concat(_this.formatValueByTime(tooltipDataItem.value, valueKey), ")");
          }

          return text;
        });
      }

      if (orientation == 'horizontal') {
        series.orientation = "horizontal";
      }

      if (!hideLegend) {
        var legend = this.createLegend(chart);
        chart.legend = legend;
      }

      var isValueCurrencyType = this.isFieldCurrencyType(valueKey);

      if (isValueCurrencyType) {
        this.setElementNumberFormatAsCurrency(chart, valueKey);
      }

      this.addExportOption(chart);
      this.addAnnotationsOption(chart);
      chart.align = 'center';
      chart.valign = 'middle';
      this.chart = chart;
    },
    updateSeriesLabels: function updateSeriesLabels(series) {
      var _this2 = this;

      var _this$get2 = this.get('config'),
          valueKey = _this$get2.value,
          valueFormat = _this$get2.valueFormat;

      var isValueTimeType = this.isFieldTimeType(valueKey);
      series.alignLabels = true;
      series.ticks.template.locationX = 0.5;
      series.labels.template.adapter.add('text', function (text, target) {
        var dataItem = target.dataItem;

        if (dataItem && Ember.isPresent(dataItem.value)) {
          if (valueFormat == 'absolute') {
            if (isValueTimeType) return "{category}: ".concat(_this2.formatValueByTime(dataItem.value, valueKey));
            return "{category}: {value}";
          }

          var percentValue = dataItem.values.value.percent.toFixed(2) + "%";
          return "{category}: ".concat(percentValue);
        }

        return "{category}: {value}";
      });
    },
    createLegend: function createLegend(chart) {
      var _this3 = this;

      var _this$get3 = this.get('config'),
          valueKey = _this$get3.value,
          valueFormat = _this$get3.valueFormat,
          orientation = _this$get3.orientation;

      var isValueTimeType = this.isFieldTimeType(valueKey);
      var isHorizontalChart = orientation == 'horizontal';
      var legend = new am4charts.Legend();
      legend.parent = chart.chartContainer;
      legend.useDefaultMarker = true;
      legend.position = isHorizontalChart ? 'top' : 'left';

      if (isHorizontalChart) {
        legend.maxHeight = 50; // top legend;

        legend.marginBottom = 10;
      } else {
        legend.marginTop = 0; // left legend

        legend.valign = "middle";
      }

      var marker = legend.markers.template.children.getIndex(0);
      marker.cornerRadius(10, 10, 10, 10);
      var markerTemplate = legend.markers.template;
      markerTemplate.width = 10;
      markerTemplate.height = 10;
      legend.scrollable = true;
      legend.itemContainers.template.paddingBottom = 5;
      legend.labels.template.maxWidth = 100;
      legend.labels.template.truncate = true;
      legend.valueLabels.template.align = "right";
      legend.valueLabels.template.adapter.add('text', function (text, target) {
        var dataItem = target.dataItem;

        if (dataItem && Ember.isPresent(dataItem.value)) {
          if (valueFormat == 'absolute') {
            return isValueTimeType ? "".concat(_this3.formatValueByTime(dataItem.value, valueKey)) : '{value}';
          }

          var percentValue = dataItem.values.value.percent.toFixed(2) + "%";
          return percentValue;
        }

        return '{value}';
      });
      return legend;
    },
    replaceNullInRowsAndColumns: function replaceNullInRowsAndColumns() {
      var categoryKey = this.get('config.category');
      var excludeNullValues = this.get('config.excludeNullValues');
      var processedData = [];
      this.get('data').forEach(function (item) {
        if (item[categoryKey] === null) {
          if (!excludeNullValues) {
            item[categoryKey] = _constants.NO_VALUE_SET;
            processedData.push(item);
          }
        } else {
          processedData.push(item);
        }
      });
      this.set('data', processedData);
    }
  });

  _exports.default = _default;
});