define("happyfox-bi/components/module-switcher/products/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var HF_LANDING_PAGE_URL = 'https://happyfox.com';
  var HFC_LANDING_PAGE_URL = 'https://happyfoxchat.com/';
  var HF_CHATBOT_LANDING_PAGE_URL = 'https://www.happyfox.com/chatbot/';
  var HF_WORKFLOWS_LANDING_PAGE_URL = 'https://www.happyfox.com/workflows/';
  var HF_IN_APP_CHAT_LANDING_PAGE_URL = 'https://www.happyfox.com/in-app-chat/';

  var _default = Ember.Component.extend({
    accountInfoService: Ember.inject.service('account-info'),
    accountName: Ember.computed.reads('accountInfoService.accountInfo.name'),
    hfLink: HF_LANDING_PAGE_URL,
    hfcLink: HFC_LANDING_PAGE_URL,
    chatbotLink: HF_CHATBOT_LANDING_PAGE_URL,
    workflowsLink: HF_WORKFLOWS_LANDING_PAGE_URL,
    chatsdkLink: HF_IN_APP_CHAT_LANDING_PAGE_URL
  });

  _exports.default = _default;
});