define("happyfox-bi/components/line-chart/component", ["exports", "happyfox-bi/components/base-line-chart/component", "@amcharts/amcharts4/core", "@amcharts/amcharts4/charts"], function (_exports, _component, am4core, am4charts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = _component.default.extend({
    isArea: false,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var _this$$ = this.$(),
          _this$$2 = _slicedToArray(_this$$, 1),
          element = _this$$2[0];

      var chart = am4core.create(element, am4charts.XYChart);
      var isChartComparison = this.get('isChartComparison');
      var showAsMinimalistic = this.get('config.showAsMinimalistic');

      if (showAsMinimalistic) {
        chart.paddingTop = 0;
        chart.paddingRight = 0;
        chart.paddingBottom = 0;
        chart.paddingLeft = 0;
      }

      var _this$get = this.get('config'),
          xAxisKey = _this$get.xAxis.key,
          valueKey = _this$get.yAxis.key;

      var isDateXAxis = this.isXAxisDateType();
      var isYAxisCurrencyType = this.isFieldCurrencyType(valueKey);
      var isDurationYAxis = this.canFormatTimeAsDuration(valueKey);
      var isYAxisTimeType = this.isFieldTimeType(valueKey);
      var xAxis = chart.xAxes.push(isDateXAxis ? new am4charts.DateAxis() : new am4charts.CategoryAxis());
      var data = this.get('data');

      if (isDateXAxis) {
        this.prepareDateAxis(xAxis);
      } else {
        xAxis.dataFields.category = xAxisKey;
        xAxis.data = data;
      }

      var valueAxis = isDurationYAxis ? chart.yAxes.push(new am4charts.DurationAxis()) : chart.yAxes.push(new am4charts.ValueAxis());
      this.setAxisRanges(xAxis, valueAxis);
      var showValueLabels = this.get('showValueLabels');
      var yAxisExtraMax = this.get('yAxisExtraMax');

      if (showValueLabels) {
        valueAxis.extraMax = yAxisExtraMax; // Expands YAxis Scale by adding 1% to y-axis max value to support value label text
      }

      if (isDurationYAxis) {
        this.setupDurationFormatter(valueAxis, valueKey);
      } else if (isYAxisTimeType) {
        this.setUnitsInAxisLabels(valueAxis, this.getTimeFieldBaseUnit(valueKey));
      }

      if (showAsMinimalistic) {
        xAxis.renderer.grid.template.disabled = true;
        xAxis.renderer.disabled = true;
        valueAxis.tooltip.disabled = true;
        valueAxis.renderer.grid.template.disabled = true;
        valueAxis.renderer.disabled = true;
      } else {
        var xAxisTitle = this.get('config.xAxis.label');
        this.setAxisTitle(xAxis, xAxisTitle);
        var yAxisTitle = this.get('config.yAxis.label');
        this.setAxisTitle(valueAxis, yAxisTitle);
      }

      xAxis.renderer.minGridDistance = 30;
      xAxis.renderer.grid.template.location = 0;
      xAxis.renderer.cellStartLocation = 0.2;
      xAxis.renderer.cellEndLocation = 0.8;
      var label = xAxis.renderer.labels.template;
      label.truncate = true;
      label.maxWidth = 120;
      label.rotation = 315;
      label.verticalCenter = "top";
      label.horizontalCenter = "right"; // Color Skip must be defined before series creation.
      // Refer https://www.amcharts.com/docs/v4/concepts/colors/#XY_Chart_and_derivative_charts

      if (isChartComparison) {
        chart.colors.step = 3;
      }

      var series = this.createSeries(chart, showAsMinimalistic);
      series.name = isChartComparison ? this.get('intlService').lookup('current-period') : this.get('config.xAxis.label');

      if (isDateXAxis) {
        series.data = this.convertAndGetDateXAxisData(data, xAxisKey);
      } else {
        series.data = data;
      }

      this.setSeriesTooltipText(series);

      if (isChartComparison) {
        var compareDateRangesList = this.get('compareDateRangesList');
        compareDateRangesList.forEach(function (dateRangeField) {
          var compareSeries = _this.createSeries(chart, showAsMinimalistic);

          if (isDateXAxis) {
            compareSeries.data = _this.prepareDateAxisCompareData(dateRangeField, xAxisKey);

            _this.setSeriesTooltipText(compareSeries, 'originalTimeStamp');
          } else {
            compareSeries.data = dateRangeField.data;

            _this.setSeriesTooltipText(compareSeries);
          }

          compareSeries.name = _this.getCompareSeriesName(dateRangeField);
        });
      }

      if (isYAxisCurrencyType) {
        this.setElementNumberFormatAsCurrency(chart, valueKey);
      }

      if (!showAsMinimalistic) {
        chart.cursor = new am4charts.XYCursor();
      }

      if (isChartComparison) {
        this.createLegend(chart);
      } // Benchmarks


      if (this.get('canManageBenchmarks')) {
        this.setUpBenchmarks(chart, valueAxis);
      }

      this.addExportOption(chart);
      this.addAnnotationsOption(chart);
      this.chart = chart;
    },
    createSeries: function createSeries(chart, showAsMinimalistic) {
      var series = chart.series.push(new am4charts.LineSeries());
      var isDateXAxis = this.isXAxisDateType();

      if (isDateXAxis) {
        series.dataFields.dateX = this.get('config.xAxis.key');
      } else {
        series.dataFields.categoryX = this.get('config.xAxis.key');
        series.tooltipText = '{valueY.value}';
      }

      series.dataFields.valueY = this.get('config.yAxis.key');
      series.dataFields.openValueY = 0;
      series.defaultState.transitionDuration = 500;

      if (showAsMinimalistic) {
        var strokeColor = this.getConfigValue('strokeColor', '#fff');
        series.stroke = strokeColor;
        var gradient = new am4core.LinearGradient();
        var gradientStartColor = this.getConfigValue('gradientStartColor', '#373737');
        gradient.addColor(am4core.color(gradientStartColor));
        var gradientEndColor = this.getConfigValue('gradientEndColor', '#0b0b0d');
        gradient.addColor(am4core.color(gradientEndColor));
        gradient.rotation = 90;
        series.fill = gradient;
        series.fillOpacity = 1;
      } else {
        series.strokeWidth = 2;
      }

      if (this.get('isArea')) {
        this.setAreaConfiguration(series);
      }

      if (!this.get('config.connectLinesOverEmptyData')) {
        series.connect = false; // For breaking gracefully between empty data points
      }

      var bullet = series.bullets.push(new am4charts.CircleBullet());
      bullet.circle.radius = 3;

      if (this.get('showValueLabels')) {
        this.createValueLabels(series);
      }

      return series;
    }
  });

  _exports.default = _default;
});