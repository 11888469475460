define("happyfox-bi/serializers/base", ["exports", "happyfox-bi/serializers/drf"], function (_exports, _drf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _drf.default.extend({
    store: Ember.inject.service(),
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.meta) {
        // Object.assign(payload, payload.meta);
        // delete payload.meta;
        var meta = payload.meta;

        for (var property in meta) {
          payload[property] = meta[property];
        }

        delete payload.meta;
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    extractPageNumber: function extractPageNumber(url) {
      /* eslint-disable no-useless-escape */
      var match = /.*?[\?&]offset=(\d+).*?/.exec(url);
      /* eslint-enable no-useless-escape */

      if (match) {
        return Number(match[1]).valueOf();
      }

      return 0;
    }
  });

  _exports.default = _default;
});