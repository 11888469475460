define("happyfox-bi/components/form-field-input/condition/component", ["exports", "happyfox-bi/components/form-field-input/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    field: null,
    actions: {
      removeCondition: function removeCondition() {
        if (this.removeCondition) {
          this.removeCondition(this.get('field'));
        }
      }
    }
  });

  _exports.default = _default;
});