define("happyfox-bi/services/toast-message", ["exports", "@happyfoxinc/hf-toast-message/services/toast-message", "happyfox-bi/config/environment"], function (_exports, _toastMessage, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _toastMessage.default.extend({
    isTestEnvironment: _environment.default.environment === 'test'
  });

  _exports.default = _default;
});