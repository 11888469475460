define("happyfox-bi/components/widget-tile/clone-visualization-modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    dataService: Ember.inject.service('data'),
    visualization: null,
    sourcePageId: null,
    sourceVisualizationId: null,
    pageOptions: null,
    selectedPage: null,
    isCloneAndEdit: false,
    saveInProgress: false,
    isSaveDisabled: Ember.computed('visualization.name', 'saveInProgress', {
      get: function get() {
        return this.get('visualization.name') == '' || this.saveInProgress;
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      var pages = this.get('dataService').peekAllPagesFromReports();
      var visualization = this.get('visualization');
      var sourcePageId = visualization.get('page'); // Making a copy of the visualization & page id's to be cloned, as it is being replaced.

      this.set('sourcePageId', sourcePageId);
      this.set('sourceVisualizationId', visualization.id);
      this.set('pageOptions', this.groupPagesByReports(pages));
      this.set('selectedPage', pages.findBy('id', sourcePageId)); // We have a tracking mechanism for the no of visualizations created from sample/templates based on presence of `sample_visualization_id`.
      // Cloning a visualization created from template should have no effect on this tracking ^, since it is directly created/duplicated from a visualization and not a template.
      // For such cases,  we remove the sample_visualization_id thereby no effect on tracking.

      var _visualization = visualization,
          settings = _visualization.settings;

      if (settings.sampleVisualizationId) {
        delete settings.sampleVisualizationId;
      }

      visualization = this.get('dataService').createVisualizationModel(visualization.toJSON());
      this.set('visualization', visualization);
    },
    groupPagesByReports: function groupPagesByReports(pages) {
      var reports = this.get('dataService').peekAllReports();
      var groupedPages = Ember.A();
      reports.forEach(function (report) {
        var pageOptions = pages.filterBy('report', Number(report.id));
        var groupedOption = Ember.Object.create({
          groupName: report.name,
          options: pageOptions
        });
        groupedPages.addObject(groupedOption);
      });
      return groupedPages;
    },
    actions: {
      closeModal: function closeModal() {
        this.closeModalAction();
      },
      onPageChange: function onPageChange(page) {
        this.set('selectedPage', page);
      },
      cloneAndEdit: function cloneAndEdit() {
        this.set('isCloneAndEdit', true);
        this.send('clone');
      },
      clone: function clone() {
        var _this = this;

        this.set('saveInProgress', true);
        var visualization = this.get('visualization');
        var sourcePageId = this.get('sourcePageId'); // peekPage is used since pageOptions(set using peekAllPagesFromReports) doesnt has the page details with updated layout items.

        var pageLayout = this.get('dataService').peekPage(sourcePageId).layout;
        var sourceVisualizationId = this.get('sourceVisualizationId');
        var visualizationLayoutItem = pageLayout.items.findBy('visualizationId', Number(sourceVisualizationId));

        if (visualizationLayoutItem) {
          var visualizationSize = Ember.Object.create({
            'width': visualizationLayoutItem.width,
            'height': visualizationLayoutItem.height
          });
          visualization.set('size', visualizationSize);
        }

        visualization.set('page', this.get('selectedPage.id'));
        visualization.save().then(function (response) {
          if (_this.isCloneAndEdit) {
            _this.onCloneAndEditVisualization(response.id);
          } else {
            _this.onCloneAndViewPage(response.page);
          }
        }).finally(function () {
          _this.set('saveInProgress', false);

          _this.set('isCloneAndEdit', false);
        });
      }
    }
  });

  _exports.default = _default;
});