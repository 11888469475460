define("happyfox-bi/visualizations/new/components/display-settings/base-chart/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    settings: null,
    actions: {
      onHideLabelsChange: function onHideLabelsChange(isChecked) {
        this.set('settings.hideLabels', isChecked);
        this.send('onUpdateDisplaySettings');
      },
      onShowValueLabelsChange: function onShowValueLabelsChange(isChecked) {
        this.set('settings.showValueLabels', isChecked);
        this.send('onUpdateDisplaySettings');
      },
      onConnectLinesOverEmptyDataChange: function onConnectLinesOverEmptyDataChange(isChecked) {
        this.set('settings.connectLinesOverEmptyData', isChecked);
        this.send('onUpdateDisplaySettings');
      },
      onHideLegendChange: function onHideLegendChange(isChecked) {
        this.set('settings.hideLegend', isChecked);
        this.send('onUpdateDisplaySettings');
      },
      onYAxisMinValueChange: function onYAxisMinValueChange(value) {
        if (Ember.isPresent(value)) {
          this.set('settings.yAxisMin', Number(value));
        } else {
          this.set('settings.yAxisMin', null);
        }

        this.send('onUpdateDisplaySettings');
      },
      onYAxisMaxValueChange: function onYAxisMaxValueChange(value) {
        if (Ember.isPresent(value)) {
          this.set('settings.yAxisMax', Number(value));
        } else {
          this.set('settings.yAxisMax', null);
        }

        this.send('onUpdateDisplaySettings');
      },
      onUpdateDisplaySettings: function onUpdateDisplaySettings() {
        this.onUpdateDisplaySettings();
      }
    }
  });

  _exports.default = _default;
});