define("happyfox-bi/components/text-search/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dwE7pG7d",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"u-position-relative\"],[12],[2,\"\\n  \"],[11,\"input\"],[16,\"aria-label\",[32,0,[\"ariaLabel\"]]],[16,2,[32,0,[\"args\",\"searchText\"]]],[16,0,[30,[36,6],[\"text-search\",[30,[36,5],[[35,4],\"is-error\"],null]],null]],[16,\"disabled\",[32,0,[\"args\",\"isDisabled\"]]],[16,\"placeholder\",[32,0,[\"args\",\"placeholder\"]]],[24,4,\"text\"],[4,[38,7],[\"input\",[32,0,[\"updateSearchText\"]]],null],[4,[38,7],[\"keyup\",[32,0,[\"onKeyUp\"]]],null],[12],[13],[2,\"\\n\"],[6,[37,5],[[30,[36,8],[[32,0,[\"args\",\"searchText\"]],[32,0,[\"showClear\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"div\"],[24,0,\"text-search_clear\"],[24,\"data-test-id\",\"clear-search\"],[24,\"role\",\"button\"],[4,[38,2],[[32,0],[32,0,[\"clearSearch\"]]],null],[12],[2,\"\\n      \"],[1,[30,[36,3],[\"clear-icon\"],[[\"class\"],[\"global-search_text_svg\"]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,5],[[32,0,[\"showMinLengthError\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"validation-error-message\"],[14,\"data-test-id\",\"search-error\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[\"validation.search-min-characters-error-message\"],[[\"characters\",\"htmlSafe\"],[[35,0],true]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"minimumSearchTextCharacters\",\"t\",\"action\",\"inline-svg\",\"showMinLengthError\",\"if\",\"concat-strings\",\"on\",\"or\"]}",
    "meta": {
      "moduleName": "happyfox-bi/components/text-search/template.hbs"
    }
  });

  _exports.default = _default;
});