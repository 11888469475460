define("happyfox-bi/components/form-field-input/time/component", ["exports", "happyfox-bi/components/form-field-input/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    actions: {
      validate: function validate() {
        this.get('field').validate();
      },
      setMeridiem: function setMeridiem(value) {
        var field = this.get('field');
        field.set('meridiem', value);
        field.validate();
      },
      setDefaultMinute: function setDefaultMinute() {
        var minuteField = this.get('field.minuteField');

        if (!minuteField.get('value')) {
          minuteField.set('value', minuteField.get('choices')[0]);
        }
      }
    }
  });

  _exports.default = _default;
});