define("happyfox-bi/adapters/visualization", ["exports", "happyfox-bi/adapters/base", "happyfox-bi/utilities/object"], function (_exports, _base, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    getRequestData: function getRequestData(record) {
      var data = this._super(record);
      /* eslint-disable camelcase */


      var settings = data.settings,
          display_settings = data.display_settings,
          preferences = data.preferences;

      if (settings) {
        settings = (0, _object.underscoreObject)(settings);
        data.settings = settings;
      }

      if (display_settings) {
        data.display_settings = (0, _object.underscoreObject)(display_settings);
      }

      if (preferences) {
        data.preferences = (0, _object.underscoreObject)(preferences);
      }
      /* eslint-enable camelcase */


      return data;
    }
  });

  _exports.default = _default;
});