define("happyfox-bi/visualizations/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    dataService: Ember.inject.service('data'),
    featuresService: Ember.inject.service('features'),
    beforeModel: function beforeModel() {
      if (!this.get('featuresService.editVisualization') || !this.get('featuresService.createVisualization')) {
        this.transitionTo('reports');
      }
    },
    afterModel: function afterModel() {
      return this.get('dataService').getAllDataSources(true);
    }
  });

  _exports.default = _default;
});