define("happyfox-bi/report-templates/components/edit/component", ["exports", "happyfox-bi/utilities/form-models", "happyfox-bi/report-templates/components/edit/report-template-form-model"], function (_exports, _formModels, _reportTemplateFormModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    dataService: Ember.inject.service('data'),
    featuresService: Ember.inject.service('features'),
    toastMessageService: Ember.inject.service('toast-message'),
    reportTemplate: null,
    // incoming template for edit
    reportTemplateForm: null,
    inProgress: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.renderForm();
    },
    renderForm: function renderForm() {
      var reportTemplate = this.reportTemplate,
          isAdd = this.isAdd;
      var dataSource = isAdd ? null : Ember.Object.create(reportTemplate);
      var reportTemplateForm = (0, _formModels.createObjectInContainer)(this, _reportTemplateFormModel.default, {
        dataSource: dataSource,
        isAdd: isAdd
      });
      this.set('reportTemplateForm', reportTemplateForm);
    },
    isAdd: Ember.computed('reportTemplate', {
      get: function get() {
        var currentReportTemplate = this.reportTemplate;
        return !Ember.isPresent(currentReportTemplate);
      }
    }),
    actions: {
      closePane: function closePane() {
        this.onCloseSidePane();
      },
      updateReportTemplate: function updateReportTemplate() {
        var _this = this;

        var toastMessageService = this.toastMessageService,
            dataService = this.dataService,
            reportTemplate = this.reportTemplate,
            reportTemplateForm = this.reportTemplateForm;
        reportTemplateForm.submit().then(function (validatedPayload) {
          _this.set('inProgress', true);

          return dataService.patchReportTemplate(reportTemplate.id, validatedPayload).then(function () {
            toastMessageService.showMessage({
              type: 'success',
              text: "report-template-update-success"
            });

            _this.onCloseSidePane(true);
          }).catch(function (errorResponse) {
            var status = errorResponse.status;

            if (status == 422) {
              reportTemplateForm._populateErrors(errorResponse);

              reportTemplateForm.scrollToError();
            } else {
              toastMessageService.showMessage({
                type: 'failure',
                text: "report-template-update-failed"
              });
            }
          }).finally(function () {
            _this.set('inProgress', false);
          });
        });
      }
    }
  });

  _exports.default = _default;
});