define("happyfox-bi/visualizations/new/components/fields-selection/data-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kTe9+Oz3",
    "block": "{\"symbols\":[\"field\"],\"statements\":[[10,\"div\"],[14,0,\"field\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"field_label new-create-edit-fields-selection_label\"],[12],[1,[30,[36,2],[\"columns\"],null]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"field_value new-create-edit-fields-selection_list\"],[12],[2,\"\\n\"],[6,[37,8],[[30,[36,7],[[30,[36,7],[[35,6]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,5],null,[[\"fieldTitle\",\"field\",\"settingsFields\",\"canShowGroupingOptions\",\"canShowAggregation\",\"removeField\",\"fetchDataSetFields\",\"onEdit\"],[[30,[36,2],[\"edit-columns-field\"],null],[32,1],[35,1],true,false,[30,[36,0],[[32,0],\"removeColumnField\"],null],[30,[36,0],[[32,0],\"fetchDataSetFields\"],null],[30,[36,0],[[32,0],\"editColumnField\"],null]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[6,[37,10],[[35,9]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"div\"],[24,0,\"new-create-edit-fields-selection_label_add icon-btn\"],[24,\"role\",\"button\"],[4,[38,0],[[32,0],\"toggleColumnFieldModal\"],null],[12],[2,\"\\n      \"],[1,[30,[36,4],[\"plus\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[6,[37,10],[[35,11]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,3],null,[[\"modalTitle\",\"canShowGroupingOptions\",\"canShowAggregation\",\"settingsFields\",\"fetchDataSetFields\",\"addFieldAction\",\"toggleModalAction\"],[[30,[36,2],[\"add-columns-field\"],null],true,false,[35,1],[30,[36,0],[[32,0],\"fetchDataSetFields\"],null],[30,[36,0],[[32,0],\"addColumnField\"],null],[30,[36,0],[[32,0],\"toggleColumnFieldModal\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"settingsFields\",\"t\",\"add-visualization-field\",\"inline-svg\",\"visualizations/new/components/fields-selection/field\",\"columnFields\",\"-track-array\",\"each\",\"canAddColumn\",\"if\",\"showColumnFieldModal\"]}",
    "meta": {
      "moduleName": "happyfox-bi/visualizations/new/components/fields-selection/data-table/template.hbs"
    }
  });

  _exports.default = _default;
});