define("happyfox-bi/components/tree-map/component", ["exports", "happyfox-bi/components/base-chart/component", "@amcharts/amcharts4/core", "@amcharts/amcharts4/charts", "happyfox-bi/constants"], function (_exports, _component, am4core, am4charts, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = _component.default.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.createGraph();
    },
    createGraph: function createGraph() {
      var _this = this;

      var chartContainer = this.get('chartContainer');
      var chart = am4core.create(chartContainer, am4charts.TreeMap);
      chart.maxLevels = 1;
      var data = this.getTransformedData();
      chart.data = data;
      chart.dataFields.value = "value";
      chart.dataFields.name = "name";
      chart.dataFields.children = "children";

      var _this$get = this.get('config'),
          drillDownKeys = _this$get.groups,
          valueKey = _this$get.value;

      var isValueTimeType = this.isFieldTimeType(valueKey);
      var isDurationValue = this.canFormatTimeAsDuration(valueKey);

      if (isDurationValue) {
        this.setupDurationFormatter(chart, valueKey);
      }

      drillDownKeys.forEach(function (key, index) {
        var drillDownSeries = chart.seriesTemplates.create("".concat(index));
        var drillDownTemplate = drillDownSeries.columns.template;
        drillDownTemplate.fillOpacity = 0.8;
        drillDownTemplate.strokeWidth = 5; // Squares gap width

        drillDownTemplate.strokeOpacity = 1;
        /* Add bullet labels */

        var drillDownBullet = drillDownSeries.bullets.push(new am4charts.LabelBullet());
        drillDownBullet.label.text = "{name}";

        if (isValueTimeType) {
          drillDownTemplate.adapter.add('tooltipText', function (text, target) {
            var tooltipDataItem = target.tooltipDataItem;

            if (tooltipDataItem && Ember.isPresent(tooltipDataItem.value)) {
              return "{name}: ".concat(_this.formatValueByTime(tooltipDataItem.value, valueKey));
            }
          });
        } else {
          drillDownTemplate.tooltipText = "{name}: {value}";
        }

        drillDownBullet.locationY = 0.5;
        drillDownBullet.locationX = 0.5;
        /* Hover state */

        var hoverState = drillDownTemplate.states.create("hover");
        hoverState.adapter.add("fill", function (fill) {
          // Darken
          return am4core.color(am4core.colors.brighten(fill.rgb, -0.15));
        });
        var isLastLevel = index == drillDownKeys.length - 1;

        if (isLastLevel) {
          drillDownTemplate.events.on("hit", function (event) {
            _this.handleRawDataDrillDown(event.target.dataItem);
          });
        }
      });
      var isValueCurrencyType = this.isFieldCurrencyType(valueKey);

      if (isValueCurrencyType) {
        this.setElementNumberFormatAsCurrency(chart, valueKey);
      }

      chart.homeText = "Home";
      chart.navigationBar = new am4charts.NavigationBar();
      this.addExportOption(chart);
      this.addAnnotationsOption(chart);
      this.chart = chart;
    },
    handleRawDataDrillDown: function handleRawDataDrillDown(seriesDataItem) {
      var treeMapDataItem = seriesDataItem.treeMapDataItem;
      var drillFilters = this.createDrillFiltersFromTreeMapItem(treeMapDataItem, Ember.A());

      if (Ember.isPresent(drillFilters)) {
        this.filterOnElementHit(drillFilters);
      }
    },
    createDrillFiltersFromTreeMapItem: function createDrillFiltersFromTreeMapItem(treeMapDataItem, filters) {
      var homeLevel = 0;
      var currentTreeDepth = treeMapDataItem.level;

      if (currentTreeDepth !== homeLevel) {
        var treeMapDataItemKey = treeMapDataItem.dataContext.field;
        var treeMapDataItemValue = treeMapDataItem.dataContext.name;
        filters.addObjects(this.getFiltersForFieldValue(treeMapDataItemKey, treeMapDataItemValue));
        this.createDrillFiltersFromTreeMapItem(treeMapDataItem.parent, filters);
      }

      return filters;
    },
    getTransformedData: function getTransformedData() {
      var originalData = this.get('data');

      var _this$get2 = this.get('config'),
          groups = _this$get2.groups;

      var drillDownKeys = groups;

      var _drillDownKeys = _slicedToArray(drillDownKeys, 1),
          topDrillDownKey = _drillDownKeys[0];

      var treeData = this.createChildrenFor(topDrillDownKey, originalData);
      return treeData;
    },

    /*
      We transform data in the form of Tree
      Eg: data = [{
        name: 'Tata',
        children: [{
          name: 'Indica',
          children: [{...Sub models}]
        }, {
          name: 'Sumo',
          value: 90
        }]
      }]
    */
    createChildrenFor: function createChildrenFor(parentKey, parentData) {
      var _this2 = this;

      var _this$get3 = this.get('config'),
          groups = _this$get3.groups;

      var treeValueKey = this.get('config.value');
      var childrenData = Ember.A();
      var drillDownKeys = groups;
      var currentLevel = drillDownKeys.indexOf(parentKey);
      var canCreateChildren = currentLevel < drillDownKeys.length - 1;
      var drillDownValues = this.getDrillDownValuesList(parentKey);

      if (canCreateChildren) {
        var nextDrillDownKey = drillDownKeys[currentLevel + 1];
        drillDownValues.forEach(function (value) {
          var filteredDrillDownData = parentData.filterBy(parentKey, value).filter(function (dataItem) {
            return Ember.isPresent(dataItem[treeValueKey]);
          });

          if (filteredDrillDownData.length) {
            childrenData.addObject({
              name: value,
              children: _this2.createChildrenFor(nextDrillDownKey, filteredDrillDownData),
              field: parentKey
            });
          }
        });
      } else {
        drillDownValues.forEach(function (value) {
          var filteredDrillDownData = parentData.filterBy(parentKey, value).mapBy(treeValueKey).compact();

          if (filteredDrillDownData.length) {
            var totalValue = filteredDrillDownData.reduce(function (total, current) {
              return total + current;
            }, 0);
            childrenData.addObject({
              name: value,
              value: totalValue,
              field: parentKey
            });
          }
        });
      }

      return childrenData;
    },
    getDrillDownValuesList: function getDrillDownValuesList(key) {
      var values = Ember.A();
      this.get('data').forEach(function (datum) {
        var drillDownValue = datum[key];
        values.addObject(drillDownValue);
      });
      return values;
    },
    replaceNullInRowsAndColumns: function replaceNullInRowsAndColumns() {
      var data = this.get('data');
      var drillDownKeys = this.get('config.groups');
      data.forEach(function (item) {
        if (drillDownKeys) {
          drillDownKeys.forEach(function (key) {
            if (item[key] === null) {
              item[key] = _constants.NO_VALUE_SET;
            }
          });
        }
      });
    }
  });

  _exports.default = _default;
});