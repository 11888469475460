define("happyfox-bi/visualizations/new/components/merge-datasets/join-settings/sample-data/component", ["exports", "ember-light-table", "happyfox-bi/mixins/table"], function (_exports, _emberLightTable, _table) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_table.default, {
    classNames: ['join-settings_sample-data'],
    dataService: Ember.inject.service('data'),
    dataSet: null,
    dataSetField: null,
    sampleData: null,
    loadingSampleData: false,
    table: null,
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('dataSet')) {
        this.createTable();
      }
    },
    onDataSetFieldChange: Ember.observer('dataSetField.id', function () {
      this.createTable();
    }),
    showEmptyState: Ember.computed('dataSet', 'dataSetField', {
      get: function get() {
        return !this.get('dataSet') || !this.get('dataSetField');
      }
    }),
    createTable: function createTable() {
      var _this = this;

      var dataSet = this.get('dataSet');
      var dataSetId = dataSet.get('id');
      var dataSetField = this.get('dataSetField');
      var sampleDataFields = dataSet.get('settings.rawDataFields');
      this.set('loadingSampleData', true);
      var promises = {
        sampleData: this.get('dataService').getDataSetSampleData(dataSetId, dataSetField ? [dataSetField.get('id')] : [])
      };

      if (!sampleDataFields) {
        promises.fields = this.get('dataService').getDataSetFields(dataSet.get('dataSource'), dataSetId, true);
      }

      Ember.RSVP.hash(promises).then(function (result) {
        var sampleData = result.sampleData,
            fields = result.fields;

        if (fields) {
          fields = fields.rejectBy('dataType', 'dataset');
        } else {
          fields = sampleDataFields.rejectBy('parentDataset').map(function (rawDataField) {
            return _this.get('dataService').peekDataSetField(rawDataField.datasetField);
          });
        }

        if (dataSetField) {
          sampleData = sampleData.sortBy(dataSetField.get('name'));
        }

        _this.set('sampleData', sampleData);

        var columns = Ember.A();
        fields.forEach(function (field) {
          columns.addObject({
            valuePath: field.name,
            label: field.displayName || field.name,
            sortable: false,
            resizable: true,
            minResizeWidth: 100,
            component: 'data-table/column'
          });
        });
        var columnForDataSetField;

        if (dataSetField) {
          columnForDataSetField = columns.findBy('valuePath', dataSetField.get('name'));

          if (!columnForDataSetField) {
            columnForDataSetField = {
              valuePath: dataSetField.name,
              label: dataSetField.displayName || dataSetField.name,
              sortable: false,
              resizable: true,
              minResizeWidth: 100,
              component: 'data-table/column'
            };
            columns.addObject(columnForDataSetField);
          }

          columnForDataSetField.classNames = 'mod-highlight';
          columnForDataSetField.cellClassNames = 'mod-highlight';
        }

        if (_this.$().width() / columns.length < 100) {
          columns.setEach('width', '100px');
        }

        _this.set('columns', columns);

        var table = _emberLightTable.default.create({
          columns: columns,
          rows: _this.get('sampleData')
        });

        var rows = table.rows;

        _this.set('rows', rows);

        _this.set('table', table);
      }).finally(function () {
        _this.set('loadingSampleData', false);
      });
    }
  });

  _exports.default = _default;
});