define("happyfox-bi/components/form-field-input/date-range-picker/component", ["exports", "happyfox-bi/components/form-field-input/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _base.default.extend({
    renderInPlace: true,
    inputClass: '',
    dateRange: Ember.computed.reads('field.value.dateRange'),
    startDate: Ember.computed.reads('field.value.startDate'),
    endDate: Ember.computed.reads('field.value.endDate'),
    actions: {
      applyDateRange: function applyDateRange(selectedDateRange) {
        this.set('field.value', Ember.Object.create(_objectSpread({}, selectedDateRange)));
        this.get('field').validate();
      }
    }
  });

  _exports.default = _default;
});