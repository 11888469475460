define("happyfox-bi/components/table-bulk-selection-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d96bfrlE",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[12],[2,\"\\n\"],[6,[37,2],[[35,0,[\"content\",\"canShow\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],null,[[\"checked\"],[[35,0,[\"selected\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"row\",\"check-box\",\"if\"]}",
    "meta": {
      "moduleName": "happyfox-bi/components/table-bulk-selection-cell/template.hbs"
    }
  });

  _exports.default = _default;
});