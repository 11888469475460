define("happyfox-bi/components/collapsible-panels/collapsible-panel-body/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['panel-body'],
    classNameBindings: ['isOpen:is-open']
  });

  _exports.default = _default;
});