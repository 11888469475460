define("happyfox-bi/visualizations/new/create/controller", ["exports", "happyfox-bi/visualizations/new/create-edit-controller"], function (_exports, _createEditController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _createEditController.default.extend({
    isCreate: true,
    actions: {
      goToOldCreateVisualization: function goToOldCreateVisualization() {
        // TODO: Remove this temporary routing action, once new ui is done.
        this.transitionToRoute('visualizations.create', {
          queryParams: {
            report: this.get('reportId'),
            page: this.get('pageId')
          }
        });
      }
    }
  });

  _exports.default = _default;
});