define("happyfox-bi/components/form-field-input/radio/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XyS069VW",
    "block": "{\"symbols\":[\"radioOption\",\"index\"],\"statements\":[[10,\"ul\"],[15,0,[31,[\"hf-radio \",[30,[36,4],[[35,0,[\"isInline\"]],\"hf-mod-inline\"],null]]]],[15,\"data-test-id\",[34,5]],[15,\"aria-label\",[30,[36,4],[[35,6],[35,6]],null]],[14,\"role\",\"radiogroup\"],[12],[2,\"\\n\"],[6,[37,8],[[30,[36,7],[[30,[36,7],[[35,0,[\"options\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[14,0,\"hf-radio_button\"],[12],[2,\"\\n      \"],[10,\"label\"],[14,0,\"u-cursor-pointer\"],[15,\"data-test-id\",[30,[36,1],[[35,0,[\"key\"]],\"-radio-option-\",[32,2]],null]],[14,\"role\",\"radio\"],[12],[2,\"\\n        \"],[1,[30,[36,2],null,[[\"value\",\"groupValue\"],[[32,1,[\"value\"]],[35,0,[\"value\"]]]]]],[2,\"\\n        \"],[1,[30,[36,3],[[32,1,[\"label\"]]],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"  \"],[19,\"components/form-field-input/partials/error-message\",[]],[2,\"\\n\"],[13]],\"hasEval\":true,\"upvars\":[\"field\",\"concat\",\"radio-button\",\"t\",\"if\",\"fieldDataTestId\",\"ariaLabel\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "happyfox-bi/components/form-field-input/radio/template.hbs"
    }
  });

  _exports.default = _default;
});