define("happyfox-bi/components/map-chart-usa/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.USA_STATE_ID_MAP = _exports.REGION_WEST = _exports.REGION_SOUTH = _exports.REGION_NORTH_EAST = _exports.REGION_MID_WEST = void 0;
  var USA_STATE_ID_MAP = [{
    "name": "Alabama",
    "id": "US-AL"
  }, {
    "name": "Alaska",
    "id": "US-AK"
  }, {
    "name": "American Samoa",
    "id": "US-AS"
  }, {
    "name": "Arizona",
    "id": "US-AZ"
  }, {
    "name": "Arkansas",
    "id": "US-AR"
  }, {
    "name": "California",
    "id": "US-CA"
  }, {
    "name": "Colorado",
    "id": "US-CO"
  }, {
    "name": "Connecticut",
    "id": "US-CT"
  }, {
    "name": "Delaware",
    "id": "US-DE"
  }, {
    "name": "District Of Columbia",
    "id": "US-DC"
  }, {
    "name": "Federated States Of Micronesia",
    "id": "US-FM"
  }, {
    "name": "Florida",
    "id": "US-FL"
  }, {
    "name": "Georgia",
    "id": "US-GA"
  }, {
    "name": "Guam",
    "id": "US-GU"
  }, {
    "name": "Hawaii",
    "id": "US-HI"
  }, {
    "name": "Idaho",
    "id": "US-ID"
  }, {
    "name": "Illinois",
    "id": "US-IL"
  }, {
    "name": "Indiana",
    "id": "US-IN"
  }, {
    "name": "Iowa",
    "id": "US-IA"
  }, {
    "name": "Kansas",
    "id": "US-KS"
  }, {
    "name": "Kentucky",
    "id": "US-KY"
  }, {
    "name": "Louisiana",
    "id": "US-LA"
  }, {
    "name": "Maine",
    "id": "US-ME"
  }, {
    "name": "Marshall Islands",
    "id": "US-MH"
  }, {
    "name": "Maryland",
    "id": "US-MD"
  }, {
    "name": "Massachusetts",
    "id": "US-MA"
  }, {
    "name": "Michigan",
    "id": "US-MI"
  }, {
    "name": "Minnesota",
    "id": "US-MN"
  }, {
    "name": "Mississippi",
    "id": "US-MS"
  }, {
    "name": "Missouri",
    "id": "US-MO"
  }, {
    "name": "Montana",
    "id": "US-MT"
  }, {
    "name": "Nebraska",
    "id": "US-NE"
  }, {
    "name": "Nevada",
    "id": "US-NV"
  }, {
    "name": "New Hampshire",
    "id": "US-NH"
  }, {
    "name": "New Jersey",
    "id": "US-NJ"
  }, {
    "name": "New Mexico",
    "id": "US-NM"
  }, {
    "name": "New York",
    "id": "US-NY"
  }, {
    "name": "North Carolina",
    "id": "US-NC"
  }, {
    "name": "North Dakota",
    "id": "US-ND"
  }, {
    "name": "Northern Mariana Islands",
    "id": "US-MP"
  }, {
    "name": "Ohio",
    "id": "US-OH"
  }, {
    "name": "Oklahoma",
    "id": "US-OK"
  }, {
    "name": "Oregon",
    "id": "US-OR"
  }, {
    "name": "Palau",
    "id": "US-PW"
  }, {
    "name": "Pennsylvania",
    "id": "US-PA"
  }, {
    "name": "Puerto Rico",
    "id": "US-PR"
  }, {
    "name": "Rhode Island",
    "id": "US-RI"
  }, {
    "name": "South Carolina",
    "id": "US-SC"
  }, {
    "name": "South Dakota",
    "id": "US-SD"
  }, {
    "name": "Tennessee",
    "id": "US-TN"
  }, {
    "name": "Texas",
    "id": "US-TX"
  }, {
    "name": "Utah",
    "id": "US-UT"
  }, {
    "name": "Vermont",
    "id": "US-VT"
  }, {
    "name": "Virgin Islands",
    "id": "US-VI"
  }, {
    "name": "Virginia",
    "id": "US-VA"
  }, {
    "name": "Washington",
    "id": "US-WA"
  }, {
    "name": "West Virginia",
    "id": "US-WV"
  }, {
    "name": "Wisconsin",
    "id": "US-WI"
  }, {
    "name": "Wyoming",
    "id": "US-WY"
  }];
  _exports.USA_STATE_ID_MAP = USA_STATE_ID_MAP;
  var REGION_WEST = ['US-WA', 'US-OR', 'US-CA', 'US-MT', 'US-ID', 'US-WY', 'US-NV', 'US-UT', 'US-CO', 'US-AZ', 'US-NM'];
  _exports.REGION_WEST = REGION_WEST;
  var REGION_MID_WEST = ['US-ND', 'US-SD', 'US-NE', 'US-KS', 'US-MN', 'US-IA', 'US-MO', 'US-WI', 'US-IL', 'US-IN', 'US-MI', 'US-OH'];
  _exports.REGION_MID_WEST = REGION_MID_WEST;
  var REGION_SOUTH = ['US-OK', 'US-TX', 'US-AR', 'US-LA', 'US-MS', 'US-AL', 'US-FL', 'US-GA', 'US-TN', 'US-KY', 'US-SC', 'US-NC', 'US-VA', 'US-WV', 'US-MD', 'US-DE'];
  _exports.REGION_SOUTH = REGION_SOUTH;
  var REGION_NORTH_EAST = ['US-PA', 'US-NY', 'US-NJ', 'US-CT', 'US-RI', 'US-MA', 'US-VT', 'US-NH', 'US-ME'];
  _exports.REGION_NORTH_EAST = REGION_NORTH_EAST;
});