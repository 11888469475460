define("happyfox-bi/components/multi-line-chart/component", ["exports", "happyfox-bi/components/base-line-chart/component", "@amcharts/amcharts4/core", "@amcharts/amcharts4/charts", "happyfox-bi/constants"], function (_exports, _component, am4core, am4charts, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _component.default.extend({
    isStacked: false,
    isArea: false,
    useSeriesNameAsTooltipLabel: true,
    isYAxisCurrencyType: function isYAxisCurrencyType() {
      var _this = this;

      var _this$get = this.get('config'),
          yAxis = _this$get.yAxis,
          groups = _this$get.groups;

      if (yAxis.value) {
        return this.isFieldCurrencyType(yAxis.value);
      }

      var valueKeys = groups.mapBy('key');
      return valueKeys.every(function (valueKey) {
        return _this.isFieldCurrencyType(valueKey);
      });
    },
    isYAxisTimeType: function isYAxisTimeType() {
      var _this2 = this;

      var _this$get2 = this.get('config'),
          yAxis = _this$get2.yAxis,
          groups = _this$get2.groups;

      if (yAxis.value) {
        return this.isFieldTimeType(yAxis.value);
      }

      var valueKeys = groups.mapBy('key');
      return valueKeys.every(function (valueKey) {
        return _this2.isFieldTimeType(valueKey);
      });
    },
    isDurationYAxis: function isDurationYAxis() {
      var _this3 = this;

      var _this$get3 = this.get('config'),
          yAxis = _this$get3.yAxis,
          groups = _this$get3.groups;

      if (yAxis.value) {
        return this.canFormatTimeAsDuration(yAxis.value);
      }

      var valueKeys = groups.mapBy('key');
      return valueKeys.every(function (valueKey) {
        return _this3.canFormatTimeAsDuration(valueKey);
      });
    },
    didInsertElement: function didInsertElement() {
      var _this4 = this;

      this._super.apply(this, arguments);

      var chartContainer = this.get('chartContainer');
      var chart = am4core.create(chartContainer, am4charts.XYChart);
      var data = this.getTransformedData();
      var isDateXAxis = this.isXAxisDateType();

      var _this$get4 = this.get('config'),
          xAxisKey = _this$get4.xAxis.key,
          _this$get4$yAxis = _this$get4.yAxis,
          groupBy = _this$get4$yAxis.groupBy,
          valueKey = _this$get4$yAxis.value,
          groups = _this$get4.groups;

      var xAxis = chart.xAxes.push(isDateXAxis ? new am4charts.DateAxis() : new am4charts.CategoryAxis());

      if (isDateXAxis) {
        this.prepareDateAxis(xAxis);
        chart.data = this.convertAndGetDateXAxisData(data, xAxisKey);
      } else {
        xAxis.dataFields.category = xAxisKey;
        chart.data = data;
      }

      var xAxisTitle = this.get('config.xAxis.label');
      this.setAxisTitle(xAxis, xAxisTitle);
      var isYAxisTimeType = this.isYAxisTimeType();
      var isDurationYAxis = this.isDurationYAxis();
      var valueAxisType = isDurationYAxis ? new am4charts.DurationAxis() : new am4charts.ValueAxis();
      var valueAxis = chart.yAxes.push(valueAxisType);
      this.setAxisRanges(xAxis, valueAxis);
      var showValueLabels = this.get('showValueLabels');
      var yAxisExtraMax = this.get('yAxisExtraMax');
      var valueAxisTitle = this.get('config.yAxis.label');
      this.setAxisTitle(valueAxis, valueAxisTitle);
      var isValueAxisCurrencyType = this.isYAxisCurrencyType();

      if (isValueAxisCurrencyType && groupBy) {
        this.setElementNumberFormatAsCurrency(chart, valueKey);
      } else if (isValueAxisCurrencyType) {
        this.renderCurrencyInAxisLabels(valueAxis);
      }

      if (showValueLabels) {
        valueAxis.extraMax = yAxisExtraMax;
      }

      if (isYAxisTimeType) {
        this.formatValueAxisAsTime(valueAxis);
      }

      xAxis.renderer.minGridDistance = 30;
      xAxis.renderer.grid.template.location = 0;
      xAxis.renderer.cellStartLocation = 0.2;
      xAxis.renderer.cellEndLocation = 0.8;
      var label = xAxis.renderer.labels.template;
      label.truncate = true;
      label.maxWidth = 120;
      label.rotation = 315;
      label.verticalCenter = "top";
      label.horizontalCenter = "right"; // Example for line with gradient shade
      // let yAxisKey1GradientStartColor = this.getConfigValue('yAxisKey1GradientStartColor', '#373737');
      // let yAxisKey1GradientEndColor = this.getConfigValue('yAxisKey1GradientEndColor', '#0b0b0d');
      // series1.stroke = yAxisKey1GradientStartColor;
      // let gradient = new am4core.LinearGradient();
      // gradient.addColor(am4core.color(yAxisKey1GradientStartColor));
      // gradient.addColor(am4core.color(yAxisKey1GradientEndColor));
      // gradient.rotation = 90;
      // series1.fill = gradient;
      // series1.fillOpacity = 1;

      var configColors = this.get('config.colors');
      var lineSeries = this.get('config.columnSeries');
      var seriesCollection = Ember.A();
      lineSeries.forEach(function (item, index) {
        var series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = item;

        if (groupBy) {
          // For `groupBy`, charts has no info on what value field is being used
          // This info will be handy for any formatting based on type (time/currency/etc)
          series.dataFields.valueKey = _this4.get('config.yAxis.value');
          series.name = item;
        } else {
          var groupItem = groups.findBy('key', item);
          series.name = groupItem.label;

          if (_this4.isFieldCurrencyType(groupItem.key)) {
            _this4.setElementNumberFormatAsCurrency(series, groupItem.key);
          }
        }

        if (isDateXAxis) {
          series.dataFields.dateX = xAxisKey;
        } else {
          series.dataFields.categoryX = xAxisKey;
        }

        _this4.setSeriesTooltipText(series);

        series.defaultState.transitionDuration = 500;

        if (configColors && configColors[index]) {
          series.fill = configColors[index];
          series.stroke = configColors[index];
        }

        series.strokeWidth = 2;

        if (_this4.get('isStacked')) {
          series.stacked = true;
        }

        if (_this4.get('isArea')) {
          _this4.setAreaConfiguration(series);
        }

        if (!_this4.get('config.connectLinesOverEmptyData')) {
          series.connect = false; // For breaking gracefully between empty data points
        }

        var bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.circle.radius = 3;

        if (showValueLabels) {
          _this4.createValueLabels(series);
        }

        seriesCollection.addObject(series);
      }); // Legend

      var isLegendHidden = this.getConfigValue('hideLegend', false);

      if (!isLegendHidden) {
        this.createLegend(chart);
        var textLink = chart.legend.createChild(am4core.TextLink);
        textLink.text = "Toggle All";
        textLink.valign = 'top';
        textLink.marginTop = 10;
        textLink.marginRight = 10;
        var areSeriesHidden = false;
        textLink.events.on("hit", function () {
          areSeriesHidden = !areSeriesHidden;

          if (areSeriesHidden) {
            seriesCollection.forEach(function (series) {
              series.hide();
            });
          } else {
            seriesCollection.forEach(function (series) {
              series.show();
            });
          }
        });
      } // Benchmarks


      if (this.get('canManageBenchmarks')) {
        this.setUpBenchmarks(chart, valueAxis);
      }

      chart.cursor = new am4charts.XYCursor();
      this.addExportOption(chart);
      this.addAnnotationsOption(chart);
      this.chart = chart;
    },
    updateConfig: function updateConfig() {
      var config = Object.assign({}, this.get('config'));
      var groupByKey = config.yAxis.groupBy;
      var columnSeries = Ember.A();

      if (groupByKey) {
        this.get('data').forEach(function (datum) {
          var columnValue = datum[groupByKey];

          if (Ember.isPresent(columnValue) && typeof columnValue === 'boolean') {
            columnValue = columnValue.toString();
          }

          columnSeries.addObject(columnValue);
        });
      } else {
        var groups = this.get('config.groups');
        (groups || []).forEach(function (group) {
          var columnValue = group['key'];

          if (Ember.isPresent(columnValue) && typeof columnValue === 'boolean') {
            columnValue = columnValue.toString();
          }

          columnSeries.addObject(columnValue);
        });
      }

      config.columnSeries = columnSeries;
      this.set('config', config);
    },
    getTransformedData: function getTransformedData() {
      var groupByKey = this.get('config.yAxis.groupBy');

      if (groupByKey) {
        var categoryAxisKey = this.get('config.xAxis.key');
        var data = Ember.A();
        var valueKey = this.get('config.yAxis.value');
        this.get('data').forEach(function (datum) {
          var categoryValue = datum[categoryAxisKey];

          var datumObject = data.findBy(categoryAxisKey, categoryValue) || _defineProperty({}, categoryAxisKey, categoryValue);

          var groupValue = datum[groupByKey];
          var value = datum[valueKey];
          datumObject[groupValue] = value;
          data.addObject(datumObject);
        });
        return data;
      }

      return this.get('data');
    },
    renderCurrencyInAxisLabels: function renderCurrencyInAxisLabels(axis) {
      var _this$get5 = this.get('config'),
          groups = _this$get5.groups;

      var valueKeys = groups.mapBy('key');

      for (var i = 0; i < valueKeys.length; i++) {
        if (this.isFieldCurrencyType(valueKeys[i])) {
          this.setElementNumberFormatAsCurrency(axis, valueKeys[i]);
          break;
        }
      }
    },
    formatValueAxisAsTime: function formatValueAxisAsTime(axis) {
      var _this$get6 = this.get('config.yAxis'),
          valueKey = _this$get6.value;

      var _this$get7 = this.get('config'),
          groups = _this$get7.groups;

      var valueKeys = Ember.A().addObject(valueKey).compact();
      (groups || []).forEach(function (item) {
        valueKeys.addObject(item.key);
      });

      for (var i = 0; i < valueKeys.length; i++) {
        var isYAxisTimeType = this.isFieldTimeType(valueKeys[i]);
        var isDurationYAxis = this.canFormatTimeAsDuration(valueKeys[i]);

        if (isDurationYAxis) {
          this.setupDurationFormatter(axis, valueKeys[i]);
          return;
        } else if (isYAxisTimeType) {
          this.setUnitsInAxisLabels(axis, this.getTimeFieldBaseUnit(valueKeys[i]));
          return;
        }
      }
    },
    replaceNullInRowsAndColumns: function replaceNullInRowsAndColumns() {
      var isDateXAxis = this.isXAxisDateType();
      var groupByKey = this.get('config.yAxis.groupBy');

      if (!isDateXAxis) {
        this.set('data', this.setNullValueByKey(this.get('config.xAxis.key')));
      }

      if (groupByKey) {
        this.set('data', this.setNullValueByKey(groupByKey));
      }
    },
    setNullValueByKey: function setNullValueByKey(key) {
      var excludeNullValues = this.get('config.excludeNullValues');
      this.get('data').forEach(function (item) {
        if (item[key] === null) {
          if (!excludeNullValues) {
            item[key] = _constants.NO_VALUE_SET;
          }
        }
      });
      return this.get('data');
    }
  });

  _exports.default = _default;
});