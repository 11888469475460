define("happyfox-bi/apps/details/happyfox/component", ["exports", "happyfox-bi/mixins/manage-apps"], function (_exports, _manageApps) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_manageApps.default, {
    intlService: Ember.inject.service('intl'),
    app: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.renderForm(this.getFieldsInfo());
    },
    getFieldsInfo: function getFieldsInfo() {
      return [Ember.Object.create({
        label: this.intlService.lookup('apps.form-fields.account-url'),
        key: 'accountUrl',
        isText: true,
        isDisabled: true
      })];
    }
  });

  _exports.default = _default;
});