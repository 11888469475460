define("happyfox-bi/form-field-models/password-field", ["exports", "happyfox-bi/form-field-models/text-field"], function (_exports, _textField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _textField.default.extend({
    isText: false,
    isPassword: true,
    allowWhiteSpace: true
  });

  _exports.default = _default;
});