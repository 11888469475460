define("happyfox-bi/visualizations/components/create-edit-derived-field/constant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FORMULA_FIELD_DATA_TYPES = _exports.FIELD_TYPES = _exports.AGGREGATION_TYPES = void 0;
  var FIELD_TYPES = [{
    id: 'aggregation',
    name: 'Aggregation'
  }, {
    id: 'formula_field',
    name: 'Formula'
  }, {
    id: 'aggregation_formula',
    name: 'Aggregation Formula'
  }];
  _exports.FIELD_TYPES = FIELD_TYPES;
  var FORMULA_FIELD_DATA_TYPES = [{
    id: 'text',
    name: 'Text'
  }, {
    id: 'decimal',
    name: 'Decimal'
  }, {
    id: 'time',
    name: 'Time'
  }, {
    id: 'timestamp',
    name: 'Timestamp'
  }];
  _exports.FORMULA_FIELD_DATA_TYPES = FORMULA_FIELD_DATA_TYPES;
  var AGGREGATION_TYPES = [{
    id: 'count',
    name: 'Count'
  }, {
    id: 'distinct_count',
    name: 'Distinct Count'
  }, {
    id: 'avg',
    name: 'Average'
  }, {
    id: 'sum',
    name: 'Sum'
  }];
  _exports.AGGREGATION_TYPES = AGGREGATION_TYPES;
});