define("happyfox-bi/report-templates/components/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uUEhn9wx",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"form-header\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"form-header_title\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"edit-report-template\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"form-content js-report-template-form_content\"],[12],[2,\"\\n  \"],[1,[30,[36,2],null,[[\"class\",\"field\"],[\"mod-field-half\",[35,1,[\"model\",\"name\"]]]]]],[2,\"\\n  \"],[1,[30,[36,2],null,[[\"class\",\"field\"],[\"mod-field-full\",[35,1,[\"model\",\"description\"]]]]]],[2,\"\\n\"],[6,[37,4],[[35,3,[\"createGlobalReportTemplates\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"toggle-field form-field\"],[12],[2,\"\\n      \"],[10,\"div\"],[12],[1,[30,[36,0],[\"available-across-all-accounts\"],null]],[13],[2,\"\\n      \"],[1,[30,[36,2],null,[[\"field\"],[[35,1,[\"model\",\"isGlobal\"]]]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"form-footer\"],[12],[2,\"\\n  \"],[1,[30,[36,8],null,[[\"class\",\"text\",\"inProgress\",\"isDirty\",\"disabled\",\"inProgressText\",\"onClick\"],[\"hf-primary-action\",[30,[36,0],[\"update-report-template\"],null],[35,7],[35,1,[\"isDirty\"]],[30,[36,6],[[35,1,[\"isDirty\"]]],null],[30,[36,0],[\"updating\"],null],[30,[36,5],[[32,0],\"updateReportTemplate\"],null]]]]],[2,\"\\n  \"],[11,\"a\"],[24,0,\"secondary-action\"],[24,\"role\",\"button\"],[4,[38,5],[[32,0],\"closePane\"],null],[12],[1,[30,[36,0],[\"cancel\"],null]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"reportTemplateForm\",\"form-field\",\"featuresService\",\"if\",\"action\",\"not\",\"inProgress\",\"async-button\"]}",
    "meta": {
      "moduleName": "happyfox-bi/report-templates/components/edit/template.hbs"
    }
  });

  _exports.default = _default;
});