define("happyfox-bi/report-subscriptions/components/subscribe-to-report/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.WEEK_DAYS = _exports.FREQUENCY_OPTIONS = _exports.DATE_RANGES = void 0;
  var DATE_RANGES = [{
    id: 'today',
    label: 'date-ranges.today'
  }, {
    id: 'yesterday',
    label: 'date-ranges.yesterday'
  }, {
    id: 'last_seven_days',
    label: 'date-ranges.last-7-days'
  }, {
    id: 'last_fourteen_days',
    label: 'date-ranges.last-14-days'
  }, {
    id: 'last_thirty_days',
    label: 'date-ranges.last-30-days'
  }, {
    id: 'this_week',
    label: 'date-ranges.this-week'
  }, {
    id: 'this_month',
    label: 'date-ranges.this-month'
  }, {
    id: 'last_week',
    label: 'date-ranges.last-week'
  }, {
    id: 'last_month',
    label: 'date-ranges.last-month'
  }];
  /*
  TO-DO: Support `After Data Refresh` frequency
  {
    id: 'after_data_refresh',
    label: 'after-data-refresh'
  }
  */

  _exports.DATE_RANGES = DATE_RANGES;
  var FREQUENCY_OPTIONS = [{
    id: 'daily',
    label: 'daily'
  }, {
    id: 'weekly',
    label: 'weekly'
  }, {
    id: 'monthly',
    label: 'monthly'
  }];
  _exports.FREQUENCY_OPTIONS = FREQUENCY_OPTIONS;
  var WEEK_DAYS = [{
    day: 0,
    active: true
  }, {
    day: 1,
    active: true
  }, {
    day: 2,
    active: true
  }, {
    day: 3,
    active: true
  }, {
    day: 4,
    active: true
  }, {
    day: 5,
    active: true
  }, {
    day: 6,
    active: true
  }];
  _exports.WEEK_DAYS = WEEK_DAYS;
});