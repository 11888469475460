define("happyfox-bi/business-hours/components/add/component", ["exports", "happyfox-bi/utilities/form-models", "happyfox-bi/business-hours/components/add/form-model"], function (_exports, _formModels, _formModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    dataService: Ember.inject.service('data'),
    intlService: Ember.inject.service('intl'),
    profileService: Ember.inject.service('profile'),
    featuresService: Ember.inject.service('features'),
    toastMessageService: Ember.inject.service('toast-message'),
    businessHoursToEdit: null,
    form: null,
    isAdd: true,
    isDataSourcesLoading: false,
    isCreateInProgress: false,
    canShowDeleteConfirmation: false,
    canShowUpdateConfirmation: false,
    isDeleteInProgress: false,
    isUpdateInProgress: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.renderForm();
      this.fetchDataSources();
    },
    removedDataSources: Ember.computed('form.model.dataSources.value.[]', 'isAdd', {
      get: function get() {
        var form = this.form,
            isAdd = this.isAdd;

        if (isAdd) {
          return [];
        }

        var dataSourcesModel = form.model.dataSources;
        var currentValue = dataSourcesModel.value;
        var initialValue = dataSourcesModel._initialValue;
        var dataSourcesList = dataSourcesModel.choices;
        return initialValue.filter(function (dataSource) {
          return !currentValue.includes(dataSource);
        }).map(function (item) {
          return dataSourcesList.findBy('id', item.id.toString());
        });
      }
    }),
    updateBusinessHoursConfirmationDescription: Ember.computed('removedDataSources.[]', {
      get: function get() {
        var removedDataSources = this.removedDataSources,
            intlService = this.intlService;

        if (Ember.isPresent(removedDataSources)) {
          var quotedDataSources = removedDataSources.map(function (dataSource) {
            return "'".concat(dataSource.name, "'");
          }).join(',');
          return intlService.lookupWithParameters('update-business-hours-confirmation-description-{dataSources}', {
            dataSources: quotedDataSources
          });
        }
      }
    }),
    fetchDataSources: function fetchDataSources() {
      var _this = this;

      var dataService = this.dataService;
      this.set('isDataSourcesLoading', true);
      dataService.getAllDataSources(true).then(function (data) {
        var form = _this.form,
            businessHoursToEdit = _this.businessHoursToEdit,
            isAdd = _this.isAdd;
        var dataSourcesList = data.filterBy('sourceType', 'happyfox');
        var dataSourcesModel = form.model.dataSources;
        dataSourcesModel.set('choices', dataSourcesList);

        if (!isAdd) {
          dataSourcesModel.set('value', businessHoursToEdit.dataSources);
          dataSourcesModel.updateInitialValue();
        }
      }).finally(function () {
        _this.set('isDataSourcesLoading', false);
      });
    },
    renderForm: function renderForm() {
      var businessHoursToEdit = this.businessHoursToEdit,
          isAdd = this.isAdd;
      var businessHours = isAdd ? null : Ember.Object.create(businessHoursToEdit);
      var form = (0, _formModels.createObjectInContainer)(this, _formModel.default, {
        dataSource: businessHours,
        isAdd: isAdd
      });
      this.set('form', form);
    },
    actions: {
      closePane: function closePane() {
        this.onCloseSidePane();
      },
      getVisualizationDependants: function getVisualizationDependants() {
        var businessHoursToEdit = this.businessHoursToEdit,
            dataService = this.dataService,
            removedDataSources = this.removedDataSources;
        var removedDataSourceIds = removedDataSources.map(function (dataSource) {
          return Number(dataSource.id);
        });
        return dataService.getDependantVisualizationsForBusinessHours(businessHoursToEdit.id, removedDataSourceIds);
      },
      addHolidayField: function addHolidayField() {
        this.form.addHolidayField();
      },
      removeHolidayField: function removeHolidayField(holidayField) {
        this.form.removeHolidayField(holidayField);
      },
      createBusinessHours: function createBusinessHours() {
        var _this2 = this;

        var toastMessageService = this.toastMessageService,
            dataService = this.dataService,
            form = this.form;
        form.submit().then(function (validatedPayload) {
          _this2.set('isCreateInProgress', true);

          return dataService.createBusinessHours(validatedPayload).then(function () {
            toastMessageService.showMessage({
              type: 'success',
              text: 'business-hours-{name}-create-success',
              params: {
                name: validatedPayload.name
              }
            });

            _this2.onCloseSidePane(true);
          }).catch(function (errorResponse) {
            var status = errorResponse.status;

            if (status == 422) {
              form._populateErrors(errorResponse);

              form.scrollToError();
            } else {
              toastMessageService.showMessage({
                type: 'failure',
                text: 'business-hours-{name}-create-failed',
                params: {
                  name: validatedPayload.name
                }
              });
            }
          }).finally(function () {
            _this2.setProperties({
              'isCreateInProgress': false,
              'canShowCreateConfirmation': false
            });
          });
        });
      },
      triggerBusinessHoursConfirmationDialog: function triggerBusinessHoursConfirmationDialog() {
        var _this3 = this;

        var form = this.form,
            isAdd = this.isAdd;
        form.validate().then(function () {
          if (isAdd) {
            _this3.send('toggleCreateConfirmation');
          } else {
            _this3.send('toggleUpdateConfirmation');
          }
        }).catch(function () {
          form.scrollToError();
        });
      },
      updateBusinessHours: function updateBusinessHours() {
        var _this4 = this;

        var toastMessageService = this.toastMessageService,
            dataService = this.dataService,
            businessHoursToEdit = this.businessHoursToEdit,
            form = this.form;
        form.submit().then(function (validatedPayload) {
          var businessHoursId = businessHoursToEdit.id;

          _this4.set('isUpdateInProgress', true);

          return dataService.patchBusinessHours(businessHoursId, validatedPayload).then(function () {
            toastMessageService.showMessage({
              type: 'success',
              text: 'business-hours-update-success'
            });

            _this4.onCloseSidePane(true);
          }).catch(function (errorResponse) {
            var status = errorResponse.status;

            if (status == 422) {
              form._populateErrors(errorResponse);

              form.scrollToError();
            } else {
              toastMessageService.showMessage({
                type: 'failure',
                text: 'business-hours-update-failed'
              });
            }
          }).finally(function () {
            _this4.setProperties({
              'isUpdateInProgress': false,
              'canShowUpdateConfirmation': false
            });
          });
        });
      },
      toggleCreateConfirmation: function toggleCreateConfirmation() {
        this.toggleProperty('canShowCreateConfirmation');
      },
      toggleUpdateConfirmation: function toggleUpdateConfirmation() {
        this.toggleProperty('canShowUpdateConfirmation');
      },
      toggleDeleteConfirmation: function toggleDeleteConfirmation() {
        this.toggleProperty('canShowDeleteConfirmation');
      },
      deleteBusinessHours: function deleteBusinessHours() {
        var _this5 = this;

        var _this$businessHoursTo = this.businessHoursToEdit,
            businessHoursId = _this$businessHoursTo.id,
            name = _this$businessHoursTo.name;
        var toastMessageService = this.toastMessageService,
            dataService = this.dataService;
        this.set('isDeleteInProgress', true);
        dataService.deleteBusinessHours(businessHoursId).then(function () {
          toastMessageService.showMessage({
            type: 'success',
            text: 'business-hours-{name}-delete-success',
            params: {
              name: name
            }
          });

          _this5.onCloseSidePane(true);
        }).catch(function () {
          toastMessageService.showMessage({
            type: 'failure',
            text: 'business-hours-{name}-delete-failed',
            params: {
              name: name
            }
          });
        }).finally(function () {
          _this5.setProperties({
            'isDeleteInProgress': false,
            'canShowDeleteConfirmation': false
          });
        });
      }
    }
  });

  _exports.default = _default;
});