define("happyfox-bi/initializers/intl", ["exports", "ember-intl/services/intl", "happyfox-bi/utilities/date"], function (_exports, _intl, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'modify-intl-service-translations',
    initialize: function initialize() {
      this.modifyIntlService(_intl.default);
    },
    modifyIntlService: function modifyIntlService(IntlService) {
      IntlService.reopen({
        lookupWithParameters: function lookupWithParameters(key, parameters) {
          var localeName = this.get('locale');

          if (this.exists(key)) {
            var translation = this.lookup(key, localeName);
            var formattedMessage = this.formatMessage(translation, parameters);
            return formattedMessage;
          } else {
            return this.lookup(key, localeName);
          }
        },
        lookup: function lookup(key, locales) {
          var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

          var localeNames = this._localeWithDefault(locales ? locales : this.get('locale'));

          var translation;

          for (var i = 0; i < localeNames.length; i++) {
            translation = this._translationContainer.lookup(localeNames[i], key); //In case of Missing translations, t-helper uses app/utils/intl/missing-message.js

            if (translation !== undefined) {
              break;
            }
          }

          if (!options.resilient && translation === undefined) {
            return key;
          }

          return translation;
        },
        formatDate: function formatDate(value, options) {
          if (value) {
            return (0, _date.getFormattedDate)(value, options);
          }
        }
      });
    }
  };
  _exports.default = _default;
});