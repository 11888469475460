define("happyfox-bi/components/form-field-input/multi-choice-dropdown/component", ["exports", "happyfox-bi/components/form-field-input/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    field: null,
    totalOptions: Ember.computed.reads('field.options'),
    selectedOptions: null,
    classNames: ['multi-choice-dropdown', 'mod-form-field'],
    choiceLabelKey: Ember.computed.reads('field.labelProperty'),
    searchText: null,
    scrollContainer: null,
    selectedOptionsText: Ember.computed('selectedOptions.[]', 'totalOptions.[]', {
      get: function get() {
        var totalNumberOfOptions = this.get('totalOptions.length');
        var selectedOptions = this.get('selectedOptions').toArray();
        var numberOfSelectedOptions = selectedOptions.length;

        if (numberOfSelectedOptions === 1) {
          return selectedOptions.get('firstObject').get(this.get('choiceLabelKey'));
        } else if (numberOfSelectedOptions === totalNumberOfOptions) {
          return this.get('intlService').lookupWithParameters('all-options-selected', {
            entity: this.get('field.pluralizedTranslationKey')
          });
        } else {
          return this.get('intlService').lookupWithParameters('options-selected', {
            pluralizedEntity: this.get('field.pluralizedTranslationKey'),
            selectedOptionsCount: numberOfSelectedOptions
          });
        }
      }
    }),
    areAllOptionsSelected: Ember.computed('selectedOptions.length', 'totalOptions.length', function () {
      return this.get('selectedOptions.length') === this.get('totalOptions.length');
    }),
    namesOfSelectedOptions: Ember.computed('selectedOptions.[]', {
      get: function get() {
        var selectedOptions = this.get('selectedOptions').toArray();
        var numberOfSelectedOptions = selectedOptions.length;

        if (numberOfSelectedOptions <= 5) {
          return this.get('selectedOptions').mapBy(this.get('choiceLabelKey')).join(',');
        } else {
          return "".concat(this.get('selectedOptions').slice(0, 5).mapBy(this.get('choiceLabelKey')).join(', '), " ").concat(this.get('intlService').lookupWithParameters('n-more', {
            n: numberOfSelectedOptions - 5
          }));
        }
      }
    }),
    options: Ember.computed('selectedOptions.@each.isSelected', 'unselectedOptions.@each.isSelected', {
      get: function get() {
        var options = Ember.A();
        var selectedOptions = this.get('selectedOptions').toArray();
        selectedOptions.forEach(function (selectedOption) {
          selectedOption.setProperties({
            'isSelected': true
          });
        });
        options.addObjects(selectedOptions);
        var unselectedOptions = this.get('unselectedOptions');
        unselectedOptions.forEach(function (unselectedOption) {
          unselectedOption.setProperties({
            'isSelected': false
          });
        });

        if (unselectedOptions && unselectedOptions.get('length')) {
          if (Ember.isEmpty(options)) {
            options.addObjects(unselectedOptions);
          } else {
            options.addObject({
              groupName: this.get('intlService').lookup("choose-more-".concat(this.get('field.pluralizedTranslationKey'))),
              options: unselectedOptions
            });
          }
        }

        return options;
      }
    }),
    unselectedOptions: Ember.computed('totalOptions.[]', 'selectedOptions.@each.isSelected', {
      get: function get() {
        var listOfOptions = this.get('totalOptions').toArray();
        var selectedOptions = this.get('selectedOptions').toArray();
        return listOfOptions.reject(function (option) {
          return selectedOptions.includes(option);
        });
      }
    }),
    updateSelection: function updateSelection() {
      this.set('field.value', this.get('selectedOptions'));
      this.get('field').validate();

      if (this.onChange) {
        this.onChange(this.get('selectedOptions'));
      }
    },
    init: function init() {
      this._super.apply(this, arguments);

      if (Ember.isEmpty(this.get('selectedOptions'))) {
        this.set('selectedOptions', Ember.A());
      }
    },
    actions: {
      dummy: function dummy() {
        // Dummy function as onChange for powerselect is mandatory
        // and this component has no logic to implement
        return;
      },
      onOptionSelection: function onOptionSelection(option) {
        if (option.isSelected) {
          this.get('selectedOptions').pushObject(option);
        } else {
          this.get('selectedOptions').removeObject(option);
        }

        this.updateSelection();
      },
      selectAll: function selectAll() {
        var unselectedOptions = this.get('unselectedOptions');
        unselectedOptions.setEach('isSelected', true);
        this.get('selectedOptions').addObjects(unselectedOptions);
        this.updateSelection();
      },
      unselectAll: function unselectAll() {
        this.set('selectedOptions', Ember.A());
        this.updateSelection();

        if (this.onMultichoiceUnselectAll) {
          this.onMultichoiceUnselectAll();
        }
      },
      setNoneValue: function setNoneValue(popOver) {
        this.send('unselectAll');
        this.set('field.isValueNone', true);
        popOver.closePopOver();
      },
      onMultiChoiceDropdownClose: function onMultiChoiceDropdownClose() {
        this.set('searchText', null);
      },
      onSearch: function onSearch(searchText) {
        this.set('searchText', searchText);
      }
    }
  });

  _exports.default = _default;
});