define("happyfox-bi/components/pop-over/component", ["exports", "@happyfoxinc/hf-pop-over/components/hf-pop-over", "happyfox-bi/config/environment"], function (_exports, _hfPopOver, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _hfPopOver.default.extend({
    isTestEnvironment: _environment.default.environment === 'test'
  });

  _exports.default = _default;
});