define("happyfox-bi/visualizations/new/components/display-settings/life-cycle-chart/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cN+HL6eV",
    "block": "{\"symbols\":[],\"statements\":[[1,[34,0]]],\"hasEval\":false,\"upvars\":[\"visualizations/new/components/display-settings/empty-state\"]}",
    "meta": {
      "moduleName": "happyfox-bi/visualizations/new/components/display-settings/life-cycle-chart/template.hbs"
    }
  });

  _exports.default = _default;
});