define("happyfox-bi/components/modal-dialog/component", ["exports", "@happyfoxinc/hf-modal-dialog/components/hf-modal-dialog"], function (_exports, _hfModalDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _hfModalDialog.default.extend({
    closeOnEsc: true,
    handleKeyDown: function handleKeyDown(event) {
      if (event.keyCode === 27 && this.closeOnEsc) {
        // Escape
        this.send('onClose');
      } else if (this.onKeyDown) {
        this.onKeyDown(event);
      }
    }
  });

  _exports.default = _default;
});