define("happyfox-bi/visualizations/new/edit/route", ["exports", "happyfox-bi/visualizations/new/create-edit-route"], function (_exports, _createEditRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _createEditRoute.default.extend({
    dataService: Ember.inject.service('data'),
    toastMessageService: Ember.inject.service('toast-message'),
    featuresService: Ember.inject.service('features'),
    applicationService: Ember.inject.service('application'),
    beforeModel: function beforeModel() {
      if (!this.get('featuresService.editVisualization')) {
        this.transitionTo('reports');
      }
    },
    model: function model(params) {
      var _this = this;

      var visualizationId = params.id;
      return Ember.RSVP.hash({
        visualization: this.get('dataService').getVisualization(visualizationId),
        dataSets: this.getAllDatasets()
      }).catch(function (_ref) {
        var status = _ref.status;

        if (status === 404) {
          _this.toastMessageService.showMessage({
            type: 'failure',
            text: 'visuzalization-not-found'
          });

          _this.transitionTo('reports');
        }
      });
    },
    afterModel: function afterModel(model) {
      var disableEdit = model.visualization.get('settings.disableEdit');

      if (disableEdit) {
        this.transitionTo('reports');
      }
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.setProperties({
        model: model.visualization,
        dataSets: model.dataSets
      });
      this.get('applicationService').closeSidePane();
      controller.initiate();
    }
  });

  _exports.default = _default;
});