define("happyfox-bi/components/data-table-new/column/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['lt-column_content']
  });

  _exports.default = _default;
});