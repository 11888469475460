define("happyfox-bi/components/data-table-new/html-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "q31hGu1p",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],[[35,0]],null]]],\"hasEval\":false,\"upvars\":[\"value\",\"html-safe\"]}",
    "meta": {
      "moduleName": "happyfox-bi/components/data-table-new/html-cell/template.hbs"
    }
  });

  _exports.default = _default;
});