define("happyfox-bi/visualizations/new/create/route", ["exports", "happyfox-bi/visualizations/new/create-edit-route"], function (_exports, _createEditRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _createEditRoute.default.extend({
    routerService: Ember.inject.service('router'),
    dataService: Ember.inject.service('data'),
    applicationService: Ember.inject.service('application'),
    featuresService: Ember.inject.service('features'),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.routerService.on('routeWillChange', function (transition) {
        var toRouteInfo = transition.to;

        if (toRouteInfo.name === 'visualizations.new.create') {
          var _toRouteInfo$queryPar = toRouteInfo.queryParams,
              report = _toRouteInfo$queryPar.report,
              page = _toRouteInfo$queryPar.page;

          if (Ember.isEmpty(report) || Ember.isEmpty(page)) {
            _this.transitionTo('reports');
          }

          _this.setProperties({
            reportId: report,
            pageId: page
          });
        }
      });
    },
    beforeModel: function beforeModel() {
      if (!this.get('featuresService.createVisualization')) {
        this.transitionTo('reports');
      }
    },
    model: function model() {
      return Ember.RSVP.hash({
        visualization: this.get('dataService').createVisualizationModel(),
        dataSets: this.getAllDatasets()
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.setProperties({
        model: model.visualization,
        dataSets: model.dataSets,
        reportId: this.get('reportId'),
        pageId: this.get('pageId')
      });
      this.get('applicationService').closeSidePane();
      controller.initiate();
    }
  });

  _exports.default = _default;
});