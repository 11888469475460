define("happyfox-bi/application/side-pane/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    dataService: Ember.inject.service('data'),
    featuresService: Ember.inject.service('features'),
    routerService: Ember.inject.service('router'),
    panelActionsService: Ember.inject.service('panel-actions'),
    applicationService: Ember.inject.service('application'),
    toastMessageService: Ember.inject.service('toast-message'),
    panelsGroupName: 'reports-side-bar',
    reports: null,
    newReportName: null,
    newReportSaveInProgress: false,
    newPageName: null,
    newPageSaveInProgress: false,
    isOpen: Ember.computed.reads('applicationService.isSidePaneOpen'),
    classNames: ['navigation-pane'],
    classNameBindings: ['isOpen'],
    canShowEditReportModal: false,
    reportTemplates: null,
    selectedReportTemplate: null,
    fetchingReportTemplates: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.loadReports();
    },
    isAllPanelsOpen: Ember.computed('panelActionsService.panels.@each.isOpen', {
      get: function get() {
        var panels = this.get('panelActionsService').getPanelsForGroup(this.get('panelsGroupName'));

        if (panels) {
          return panels.isEvery('isOpen', true);
        }
      }
    }),
    orderedReports: Ember.computed('reports.@each.name', {
      get: function get() {
        var reports = this.get('reports');
        return Ember.isPresent(reports) ? reports.sortBy('name') : [];
      }
    }),
    reportTemplateOptions: Ember.computed('reportTemplates.[]', {
      get: function get() {
        var reportTemplatesOptions = [{
          id: null,
          name: '-------'
        }];
        var reportTemplates = this.get('reportTemplates');

        if (Ember.isPresent(reportTemplates)) {
          reportTemplatesOptions.addObjects(reportTemplates);
        }

        return reportTemplatesOptions;
      }
    }),
    loadReports: function loadReports() {
      var reports = this.get('dataService').peekAllReports();
      this.set('reports', reports);
    },
    pageLastSyncTime: Ember.computed('routerService.{currentRouteName,currentRoute.params.page_id}', {
      get: function get() {
        var currentRouteName = this.get('routerService.currentRouteName');

        if (currentRouteName === 'reports.details.pages.details') {
          var pageId = this.get('routerService.currentRoute.params.page_id');
          var page = this.get('dataService').peekPage(pageId);
          var lastSyncAt = page.get('lastSyncAt');

          if (lastSyncAt) {
            return moment(lastSyncAt).format('MMM DD YYYY, h:mm A');
          }
        }
      }
    }),
    actions: {
      expandAllPanels: function expandAllPanels() {
        this.get('panelActionsService').openAllPanels(this.get('panelsGroupName'));
      },
      collapseAllPanels: function collapseAllPanels() {
        this.get('panelActionsService').closeAllPanels(this.get('panelsGroupName'));
      },
      togglePane: function togglePane() {
        this.get('applicationService').toggleSidePane();
      },
      cancelNewReport: function cancelNewReport(popOver) {
        popOver.closePopOver();
      },
      addNewReport: function addNewReport(popOver) {
        var _this = this;

        var newReportName = this.get('newReportName');
        this.set('newReportSaveInProgress', true);
        var reportData = {
          name: newReportName
        };

        if (this.get('featuresService.reportTemplates') && this.get('selectedReportTemplate')) {
          reportData.templateId = this.get('selectedReportTemplate.id');
        }

        this.get('dataService').createReport(reportData).then(function (report) {
          popOver.closePopOver();

          _this.get('routerService').transitionTo('reports.details', report.id);

          _this.get('toastMessageService').showMessage({
            type: 'success',
            text: 'report-{name}-created',
            params: {
              name: report.name
            }
          });
        }).catch(function () {
          _this.get('toastMessageService').showMessage({
            type: 'failure',
            text: 'report-{name}-failed',
            params: {
              name: newReportName
            }
          });
        }).finally(function () {
          _this.set('newReportSaveInProgress', false);
        });
      },
      cancelNewPage: function cancelNewPage(popOver) {
        popOver.closePopOver();
      },
      addNewPage: function addNewPage(reportId, popOver) {
        var _this2 = this;

        var newPageName = this.get('newPageName');
        this.set('newPageSaveInProgress', true);
        this.get('dataService').createPage(reportId, newPageName).then(function () {
          _this2.get('dataService').getReport(reportId).then(function () {
            popOver.closePopOver();

            _this2.loadReports();
          }).finally(function () {
            _this2.set('newPageSaveInProgress', false);
          });
        });
      },
      onClosePopOver: function onClosePopOver() {
        this.setProperties({
          'newReportName': null,
          'newPageName': null
        });
      },
      openEditReportModal: function openEditReportModal(report) {
        this.setProperties({
          canShowEditReportModal: true,
          selectedReport: report
        });
      },
      closeEditReportModal: function closeEditReportModal() {
        this.setProperties({
          canShowEditReportModal: false,
          selectedReport: null
        });
      },
      goToReportDetailAction: function goToReportDetailAction(reportId) {
        this.goToReportDetailAction(reportId);
      },
      initiateReportTemplatesState: function initiateReportTemplatesState() {
        var _this3 = this;

        this.setProperties({
          'selectedReportTemplate': null,
          'fetchingReportTemplates': true
        });
        this.get('dataService').fetchAllReportTemplates().then(function (templates) {
          _this3.set('reportTemplates', templates);
        }).catch(function () {}).finally(function () {
          _this3.set('fetchingReportTemplates', false);
        });
      },
      onReportTemplateChange: function onReportTemplateChange(template) {
        template = template.id ? template : null;
        this.set('selectedReportTemplate', template);
      }
    }
  });

  _exports.default = _default;
});