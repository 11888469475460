define("happyfox-bi/mixins/register-waiter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (Ember.testing) {
        this._loading = false;
        Ember.Test.registerWaiter(function () {
          return _this._loading === false;
        });
      }
    }
  });

  _exports.default = _default;
});