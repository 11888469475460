define("happyfox-bi/components/form-field-input/remote-choice/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5b9YJJG6",
    "block": "{\"symbols\":[\"value\",\"&default\"],\"statements\":[[6,[37,11],null,[[\"options\",\"allowClear\",\"class\",\"autoFocus\",\"placeholder\",\"searchPlaceholder\",\"renderInPlace\",\"selected\",\"noMatchesMessage\",\"url\",\"itemsToBeExcluded\",\"searchParam\",\"isAddNew\",\"onChange\",\"onClose\",\"disabled\",\"remoteSearchAdditionalParams\",\"data-test-id\",\"dropdownClass\"],[[35,0,[\"choices\"]],[35,0,[\"allowClear\"]],[30,[36,10],[[35,9],[35,8],[30,[36,2],[[35,7],\"is-error\"],null],[30,[36,2],[[35,0,[\"isHighlighted\"]],\"hf-is-highlighted\"],null],[30,[36,2],[[35,0,[\"isDisabled\"]],\"is-disabled\"],null]],null],[35,0,[\"autoFocus\"]],[35,0,[\"placeholder\"]],[35,0,[\"searchPlaceholder\"]],true,[35,0,[\"value\"]],\"no-results-found\",[35,0,[\"lookupUrl\"]],[35,6],[35,0,[\"searchParam\"]],[35,0,[\"isAddNew\"]],[30,[36,5],[[32,0],\"valueChanged\"],null],[30,[36,5],[[32,0],\"onClose\"],null],[35,0,[\"isDisabled\"]],[35,0,[\"remoteSearchAdditionalParams\"]],[35,4],[35,3]]],[[\"default\"],[{\"statements\":[[6,[37,2],[[27,[32,2]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"hf-elseblock\"],[12],[13],[2,\"\\n    \"],[18,2,[[32,1]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,0,[\"choiceLabelKey\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"hf-elseblock2\"],[12],[13],[2,\"\\n    \"],[1,[30,[36,1],[[32,1],[35,0,[\"choiceLabelKey\"]]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"hf-elseblock3\"],[12],[13],[2,\"\\n    \"],[1,[32,1]],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"],[19,\"components/form-field-input/partials/error-message\",[]]],\"hasEval\":true,\"upvars\":[\"field\",\"get\",\"if\",\"dropdownClass\",\"fieldDataTestId\",\"action\",\"itemsToBeExcluded\",\"hasErrors\",\"jsTestClass\",\"selectClass\",\"concat-strings\",\"auto-complete/remote/single\"]}",
    "meta": {
      "moduleName": "happyfox-bi/components/form-field-input/remote-choice/template.hbs"
    }
  });

  _exports.default = _default;
});