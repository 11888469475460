define("happyfox-bi/components/auto-complete/local/single/component", ["exports", "happyfox-bi/utilities/run-loop"], function (_exports, _runLoop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    initiallyOpened: true,
    renderInPlace: true,
    triggerClass: 'u-hide',
    dropdownClass: 'mod-auto-complete-dropdown',
    onSelectFunction: null,
    closeOnSelect: false,
    searchField: '',
    canClose: false,
    closePopOverOnSelect: true,
    tagName: 'div',
    searchText: '',
    didInsertElement: function didInsertElement() {
      var _this = this;

      (0, _runLoop.runLater)(this, function () {
        if (!_this.isDestroyed) {
          _this.$('input.ember-power-select-search-input').focus();
        }
      }, 200);
    },
    actions: {
      onKeyDown: function onKeyDown(select, e) {
        /* - When typing enter(13), highlighted option should be selected */
        if (e.keyCode === 13) {
          e.preventDefault();
        }

        var selected = Ember.A(this.get('selected') || []);

        if ((e.keyCode === 13 || e.keyCode === 188) && select.isOpen) {
          // To prevent default actions like form submit on enter in new ticket form
          e.stopPropagation();

          if (select.highlighted !== undefined) {
            if (selected.indexOf(select.highlighted) === -1) {
              select.actions.choose(select.highlighted, e);
            } else {
              select.actions.close(e);
            }
          } else {
            select.actions.close(e);
          }
        }

        return false;
      },
      onInput: function onInput(searchText) {
        this.set('searchText', searchText);
      },
      onChange: function onChange(result) {
        if (this.onSelectFunction) {
          this.onSelectFunction();
        }

        if (this.onChange) {
          this.onChange(result);
        } else {
          this.set('selected', result);
        }

        if (this.get('closePopOverOnSelect')) {
          var popOver = this.get('popOver');

          if (popOver) {
            popOver.closePopOver();
          }
        }
      },
      onClose: function onClose() {
        if (!this.get('canClose')) {
          return false;
        }

        if (this.onClose) {
          this.onClose();
        }
      }
    }
  });

  _exports.default = _default;
});