define("happyfox-bi/components/form-field-input/files/component", ["exports", "happyfox-bi/utilities/file"], function (_exports, _file) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    dataService: Ember.inject.service('data'),
    isUploadInProgress: false,
    actions: {
      onTriggerUpload: function onTriggerUpload() {
        // Required because async-button stops propagation of the click by default
        var input = this.$('input[type="file"]').eq(0);
        input.click();
      },
      uploadFiles: function uploadFiles(files) {
        var _this = this;

        if (this.get('field.onFileUpload')) {
          this.set('isUploadInProgress', true);
          this.get('field.onFileUpload')((0, _file.getFilesArrayFromFileList)(files)).then(function () {
            _this.set('isUploadInProgress', false);
          });
        }
      },
      removeFile: function removeFile(file) {
        var files = this.get('field.value');

        if (Ember.isPresent(files)) {
          files.removeObject(file);
        }
      }
    }
  });

  _exports.default = _default;
});