define("happyfox-bi/adapters/base", ["exports", "happyfox-bi/adapters/drf", "happyfox-bi/utilities/string", "happyfox-bi/utilities/time"], function (_exports, _drf, _string, _time) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _drf.default.extend({
    errorHandlingService: Ember.inject.service('error-handling'),
    namespace: 'api/1.0',
    host: null,
    headers: Ember.computed('', {
      get: function get() {
        var headers = {
          'X-CSRFTOKEN': (0, _string.getCookieValue)('csrftoken'),
          'X-TIMEZONE': (0, _time.getTimeZoneOffset)()
        };
        return headers;
      }
    }).volatile(),
    // To calculate the headers every time since we depend on the csrf cookie value
    ajax: function ajax(url, method) {
      var hash = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      hash.xhrFields = {
        withCredentials: true
      };
      return this._super(url, method, hash);
    },
    handleResponse: function handleResponse(status, headers, payload) {
      if (this.isSuccess(status, headers, payload)) {
        return payload;
      }

      return this.get('errorHandlingService').handleErrors(status, payload);
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var record = snapshot.record,
          id = snapshot.id;
      var data = this.getRequestData(record);
      var url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');
      return this.ajax(url, 'PATCH', {
        data: data
      });
    },
    createRecord: function createRecord(store, type, snapshot) {
      var record = snapshot.record;
      var data = this.getRequestData(record);
      var url = this.buildURL(type.modelName, null, snapshot, 'createRecord');
      return this.ajax(url, 'POST', {
        data: data
      });
    },
    getRequestData: function getRequestData(record) {
      var changedAttributes = record.changedAttributes(); // FIXME: figure out why model initializer is not working

      var changedRelationships = record.changedRelationships(); // const changedRelationships = [];

      var recordJSON = record.toJSON();
      var data = {};

      for (var attribute in changedAttributes) {
        var value = record.get(attribute);

        if (value instanceof Blob || value instanceof Date) {
          // toJSON will not work for file type
          data[attribute.underscore()] = value;
        } else {
          data[attribute.underscore()] = recordJSON[attribute];
        }
      }

      for (var relation in changedRelationships) {
        data[relation.underscore()] = recordJSON[relation];
      } // size is not part of the visualization model, hence added here


      if (record.size) {
        data['size'] = record.size;
      }

      return data;
    }
  });

  _exports.default = _default;
});