define("happyfox-bi/components/collapsible-panels/collapsible-panel-toggle/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['panel-toggle', 'u-cursor-pointer'],
    classNameBindings: ['isOpen:is-open'],
    click: function click(e) {
      e.preventDefault();
      this.get('onClick')();
    }
  });

  _exports.default = _default;
});