define("happyfox-bi/visualizations/new/components/values-aggregation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7LPk6VVX",
    "block": "{\"symbols\":[\"type\"],\"statements\":[[10,\"div\"],[14,0,\"field\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"field_label\"],[12],[1,[30,[36,0],[\"value-aggregation\"],null]],[13],[2,\"\\n\"],[6,[37,8],null,[[\"tagName\",\"class\",\"dropdownClass\",\"options\",\"selected\",\"placeholder\",\"onChange\",\"searchEnabled\",\"searchField\",\"renderInPlace\"],[\"div\",\"mod-dropdown mod-local-autocomplete field_value\",\"mod-push-dropdown-up\",[35,7],[35,6],[30,[36,0],[\"search\"],null],[30,[36,1],[[32,0],\"onTypeChange\"],null],true,\"name\",true]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[32,1,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"],[6,[37,5],[[35,9]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"field\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"field_label\"],[12],[1,[30,[36,0],[\"edit-visualization.enter-percentile-value\"],null]],[13],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"type\",\"value\",\"focus-out\",\"class\"],[\"number\",[35,2],[30,[36,1],[[32,0],\"onPercentileValueChange\"],null],\"field_value\"]]]],[2,\"\\n\"],[6,[37,5],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"new-create-edit-values-aggregation_message\"],[12],[1,[30,[36,0],[\"percentile-correction-message\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"percentileValue\",\"input\",\"showCorrectionMessage\",\"if\",\"aggregationType\",\"aggregationTypeOptions\",\"power-select\",\"isPercentileType\"]}",
    "meta": {
      "moduleName": "happyfox-bi/visualizations/new/components/values-aggregation/template.hbs"
    }
  });

  _exports.default = _default;
});