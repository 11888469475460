define("happyfox-bi/utilities/time", ["exports", "happyfox-bi/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.convert12HourTo24HourFormat = convert12HourTo24HourFormat;
  _exports.convert24HourTo12HourFormat = convert24HourTo12HourFormat;
  _exports.convertDateToMoment = convertDateToMoment;
  _exports.convertMomentToDateTime = convertMomentToDateTime;
  _exports.getCurrentDateTime = getCurrentDateTime;
  _exports.getLongHandDurationFormatByUnit = getLongHandDurationFormatByUnit;
  _exports.getTimeZoneOffset = getTimeZoneOffset;
  _exports.humanizeDuration = humanizeDuration;

  // Replacement new Date() if you need the current date time
  // You can use new Date() if you provide an input to it
  // Input: Nothing
  // Output: JS DateTime having the corrected system time
  function getCurrentDateTime() {
    // FIXME: CHeck if TIME_DEVIATION_LOCAL_STORAGE_KEY is needed
    // let timeDeviation = localStorage.getItem(TIME_DEVIATION_LOCAL_STORAGE_KEY);
    var timeDeviation = 0;

    if (timeDeviation) {
      timeDeviation = Number(timeDeviation);
      var now = new Date() - timeDeviation;
      return new Date(now);
    } else {
      return new Date();
    }
  } // WARNING: Use only to convert dates, not datetimes, as the function strips of the time component


  function convertDateToMoment(date) {
    return moment("".concat(date.getFullYear(), "-").concat(date.getMonth() + 1, "-").concat(date.getDate()), 'YYYY-M-D');
  } // Output: Integer specifying the current staff's timezone offset from GMT


  function getTimeZoneOffset() {
    return moment().utcOffset();
  } // Input: moment
  // Output: JS DateTime set to the same date and time as in the moment object (staff timezone dependent)


  function convertMomentToDateTime(dateTime) {
    return new Date(dateTime.year(), dateTime.month(), dateTime.date(), dateTime.hour(), dateTime.minute(), dateTime.seconds());
  }

  function convert12HourTo24HourFormat(hour, meridiem) {
    if (meridiem === 'PM' && hour !== 12) {
      return hour + 12;
    } else if (meridiem === 'AM' && hour === 12) {
      return 0;
    } else {
      return hour;
    }
  }

  function convert24HourTo12HourFormat(hour) {
    if (hour > 12) {
      return {
        hour: hour % 12,
        meridiem: 'PM'
      };
    } else if (hour === 0) {
      return {
        hour: 12,
        meridiem: 'AM'
      };
    } else if (hour === 12) {
      return {
        hour: 12,
        meridiem: 'PM'
      };
    } else {
      return {
        hour: hour,
        meridiem: 'AM'
      };
    }
  }

  function getLongHandDurationFormatByUnit(unit) {
    switch (unit) {
      case 'seconds':
        return 's [seconds]';

      case 'minutes':
        return 'm [minutes]';

      case 'hours':
        return 'h [hours]';

      case 'days':
        return 'd [days]';
    }
  }

  function humanizeDuration(value, baseUnit) {
    var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var defaultPrecision = 0;
    var defaultTrim = "both";
    var settings = {
      template: options.format ? options.format : _constants.DURATION_FORMAT_LONG,
      precision: Ember.isPresent(options.precision) ? options.precision : defaultPrecision,
      trim: options.trim ? options.trim : defaultTrim
    };
    Object.keys(options).forEach(function (key) {
      if (!Ember.isPresent(settings[key])) {
        settings[key] = options[key];
      }
    });
    return moment.duration(value, baseUnit).format(settings);
  }
});