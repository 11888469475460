define("happyfox-bi/components/add-visualization-field/settings-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TKNP+1L3",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,0,[\"displayName\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[35,0,[\"displayName\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[35,0,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"settingsField\",\"if\"]}",
    "meta": {
      "moduleName": "happyfox-bi/components/add-visualization-field/settings-field/template.hbs"
    }
  });

  _exports.default = _default;
});