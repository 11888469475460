define("happyfox-bi/report-subscriptions/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    featuresService: Ember.inject.service('features'),
    dataService: Ember.inject.service('data'),
    beforeModel: function beforeModel() {
      if (!this.get('featuresService.reportSubscriptions')) {
        this.transitionTo('reports');
      }
    },
    setupController: function setupController(controller) {
      var reportsList = this.dataService.peekAllReports();

      if (!Ember.isPresent(reportsList)) {
        this.transitionTo('reports');
      }

      controller.set('reportsList', reportsList);
      var offset = 0;
      var calculatedOffset = controller.calculatedOffset;
      controller.fetchData(calculatedOffset ? calculatedOffset : offset);
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('canShowSubscriptionSidePane', false);
      }
    }
  });

  _exports.default = _default;
});