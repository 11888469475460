define("happyfox-bi/components/base-column-chart/component", ["exports", "happyfox-bi/components/base-chart/component", "@amcharts/amcharts4/charts"], function (_exports, _component, am4charts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    createValueLabels: function createValueLabels(series) {
      var valueLabel = series.bullets.push(new am4charts.LabelBullet());
      valueLabel.label.verticalCenter = 'top';
      valueLabel.label.text = '{valueY}';
      valueLabel.label.truncate = false;
      valueLabel.label.dy = -15;
      valueLabel.label.fontSize = 14;
    }
  });

  _exports.default = _default;
});