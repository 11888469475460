define("happyfox-bi/apps/details/aircall/account-info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g+NBiH9g",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"app-manage-section_box mod-small\"],[12],[2,\"\\n\"],[6,[37,5],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"button\"],[24,0,\"hf-primary-action manage-app-form_reauthorize-btn\"],[4,[38,2],[[32,0],\"reauthorize\",[35,0]],null],[12],[1,[30,[36,3],[\"reauthorize\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"div\"],[12],[10,\"span\"],[14,0,\"font-semi-bold\"],[12],[1,[30,[36,3],[\"aircall.company-name\"],null]],[2,\":\"],[13],[2,\" \"],[1,[35,0,[\"companyInfo\",\"name\"]]],[13],[2,\"\\n  \"],[10,\"div\"],[12],[10,\"span\"],[14,0,\"font-semi-bold\"],[12],[1,[30,[36,3],[\"aircall.users\"],null]],[2,\":\"],[13],[2,\" \"],[1,[35,0,[\"companyInfo\",\"usersCount\"]]],[13],[2,\"\\n  \"],[10,\"div\"],[12],[10,\"span\"],[14,0,\"font-semi-bold\"],[12],[1,[30,[36,3],[\"aircall.numbers\"],null]],[2,\":\"],[13],[2,\" \"],[1,[35,0,[\"companyInfo\",\"numbersCount\"]]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,5],[[35,0,[\"syncInfo\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,1],null,[[\"syncInfo\"],[[35,0,[\"syncInfo\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"account\",\"apps/details/account-sync-info\",\"action\",\"t\",\"isSyncSuspended\",\"if\"]}",
    "meta": {
      "moduleName": "happyfox-bi/apps/details/aircall/account-info/template.hbs"
    }
  });

  _exports.default = _default;
});