define("happyfox-bi/data-sources/details/data-sets/import/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    dataService: Ember.inject.service('data'),
    model: function model(params) {
      var dataSourceId = this.paramsFor('data-sources.details').id;
      return this.get('dataService').getDataSet(dataSourceId, params.data_set_id);
    },
    afterModel: function afterModel(model) {
      if (!model.get('settings.allowCsvImport')) {
        this.transitionTo('data-sources');
      }
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.initiate();
    }
  });

  _exports.default = _default;
});