define("happyfox-bi/visualizations/new/components/fields-selection/map-chart/component", ["exports", "happyfox-bi/visualizations/new/components/fields-selection/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['new-create-edit-fields-selection'],
    displaySettings: null,
    regionFields: null,
    valueFields: null,
    showRegionFieldModal: false,
    showValueFieldModal: false,
    canAddRegion: Ember.computed.lt('regionFields.length', 1),
    canAddValues: Ember.computed.lt('valueFields.length', 1),
    init: function init() {
      this._super.apply(this, arguments);

      var displaySettings = this.displaySettings,
          settingsFields = this.settingsFields;
      var region = displaySettings.region,
          value = displaySettings.value;
      var regionField = settingsFields.findBy('name', region);
      var valueField = settingsFields.findBy('name', value);
      this.setProperties({
        regionFields: regionField ? Ember.A().addObject(regionField) : Ember.A(),
        valueFields: valueField ? Ember.A().addObject(valueField) : Ember.A()
      });
    },
    updateVisualizationRowsField: function updateVisualizationRowsField() {
      this.generateDisplaySettings();
      var regionFields = this.regionFields; // false -> dont have to generate disp settings on row change unlike other charts

      this.onRowsChange(regionFields, false);
    },
    updateVisualizationValuesField: function updateVisualizationValuesField() {
      this.generateDisplaySettings();
      var valueFields = this.valueFields;
      this.onValuesChange(valueFields, false);
    },
    generateDisplaySettings: function generateDisplaySettings() {
      var regionFields = this.regionFields,
          valueFields = this.valueFields,
          displaySettings = this.displaySettings;
      displaySettings.setProperties({
        region: Ember.isPresent(regionFields) ? regionFields[0].name : null,
        value: Ember.isPresent(valueFields) ? valueFields[0].name : null
      });
    },
    actions: {
      removeRegionField: function removeRegionField(field) {
        var regionFields = this.regionFields,
            displaySettings = this.displaySettings;
        regionFields.removeObject(field);
        displaySettings.set('useIso', false);
        this.updateVisualizationRowsField();
      },
      removeValueField: function removeValueField(field) {
        var valueFields = this.valueFields;
        valueFields.removeObject(field);
        this.updateVisualizationValuesField();
      },
      addRegionField: function addRegionField(field) {
        var regionFields = this.regionFields;
        regionFields.addObject(field);
        this.toggleProperty('showRegionFieldModal');
        this.updateVisualizationRowsField();
      },
      addValueField: function addValueField(field) {
        var valueFields = this.valueFields;
        valueFields.addObject(field);
        this.toggleProperty('showValueFieldModal');
        this.updateVisualizationValuesField();
      },
      toggleRegionFieldModal: function toggleRegionFieldModal() {
        this.toggleProperty('showRegionFieldModal');
      },
      toggleValueFieldModal: function toggleValueFieldModal() {
        this.toggleProperty('showValueFieldModal');
      },
      editRegionField: function editRegionField(newField, field) {
        var regionFields = this.regionFields;
        var indexOfOldField = regionFields.indexOf(field);
        regionFields.removeObject(field);
        regionFields.insertAt(indexOfOldField, newField);
        this.updateVisualizationRowsField();
      },
      editValueField: function editValueField(newField, field) {
        var valueFields = this.valueFields;
        var indexOfOldField = valueFields.indexOf(field);
        valueFields.removeObject(field);
        valueFields.insertAt(indexOfOldField, newField);
        this.updateVisualizationValuesField();
      },
      onRegionFormatChange: function onRegionFormatChange(isChecked) {
        this.set('displaySettings.useIso', isChecked);
        this.onUpdateDisplaySettings();
      }
    }
  });

  _exports.default = _default;
});