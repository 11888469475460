define("happyfox-bi/mixins/bulk-selection-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    isAnySelected: false,
    canShowCheckbox: Ember.computed.and('canShow', 'canBulkSelect'),
    canShow: Ember.computed('isAnySelected', {
      get: function get() {
        return this.get('isAnySelected');
      },
      set: function set(key, value) {
        return value;
      }
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.element.addEventListener('mouseenter', this.handleMouseEnter.bind(this));
      this.element.addEventListener('mouseleave', this.handleMouseLeave.bind(this));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.element.removeEventListener('mouseenter', this.handleMouseEnter);
      this.element.removeEventListener('mouseleave', this.handleMouseLeave);
    },
    handleMouseEnter: function handleMouseEnter() {
      this.set('canShow', true);
    },
    handleMouseLeave: function handleMouseLeave() {
      if (!this.get('isAnySelected')) {
        this.set('canShow', false);
      }
    }
  });

  _exports.default = _default;
});