define("happyfox-bi/reports/details/pages/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    dataService: Ember.inject.service('data'),
    redirect: function redirect() {
      var reportId = this.paramsFor('reports.details').id;
      var report = this.get('dataService').peekReport(reportId);

      if (!report) {
        this.transitionTo('reports');
      }

      var pageId = report.get('pages.firstObject.id');
      this.transitionTo('reports.details.pages.details', pageId);
    }
  });

  _exports.default = _default;
});