define("happyfox-bi/components/compare-date-range-picker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SX1rThXG",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[30,[36,9],[\"date-range-picker_trigger compare-date-range-picker_trigger\",[35,8]],null]],[12],[2,\"\\n\"],[6,[37,6],[[35,10]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,\"data-test-id\",\"selected-compare-date-range\"],[12],[2,\"\\n      \"],[1,[30,[36,2],[\"comparing-with\"],null]],[2,\"\\n      \"],[10,\"span\"],[12],[2,\"\\n\"],[6,[37,6],[[35,7]],null,[[\"default\"],[{\"statements\":[[6,[37,6],[[35,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[1,[34,3]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[1,[34,3]],[2,\" - \"],[1,[34,4]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"span\"],[12],[1,[30,[36,2],[\"compare-dates\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[1,[30,[36,1],[\"disclosure-indicator\"],[[\"class\"],[\"u-icon-left-margin\"]]]],[2,\"\\n\"],[6,[37,6],[[30,[36,13],[[30,[36,12],[[35,11]],null],[35,10]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"div\"],[24,0,\"compare-date-range_remove u-icon-left-margin\"],[24,\"role\",\"button\"],[4,[38,0],[[32,0],\"remove\"],null],[12],[2,\"\\n      \"],[1,[30,[36,1],[\"x-circle\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"inline-svg\",\"t\",\"startDateFormatted\",\"endDateFormatted\",\"areStartAndEndDatesSame\",\"if\",\"startDate\",\"triggerClass\",\"concat-strings\",\"pickerUpdated\",\"compareDateRangePreferences\",\"is-present\",\"and\"]}",
    "meta": {
      "moduleName": "happyfox-bi/components/compare-date-range-picker/template.hbs"
    }
  });

  _exports.default = _default;
});