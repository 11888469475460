define("happyfox-bi/components/render-html/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KYFChLAI",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],[[35,1]],[[\"domPurifyConfig\"],[[35,0]]]]]],\"hasEval\":false,\"upvars\":[\"domPurifyConfig\",\"html\",\"html-safe\"]}",
    "meta": {
      "moduleName": "happyfox-bi/components/render-html/template.hbs"
    }
  });

  _exports.default = _default;
});