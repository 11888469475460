define("happyfox-bi/visualizations/new/components/display-settings/data-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ooiWoRCX",
    "block": "{\"symbols\":[\"column\"],\"statements\":[[10,\"div\"],[14,0,\"field mod-table-rows-field\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"field_label u-vertically-centered-container\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"limit-no-of-records-to-display\"],null]],[2,\"\\n    \"],[10,\"div\"],[14,0,\"tooltipped tooltipped-top data-table-records-limit\"],[15,\"aria-label\",[30,[36,0],[\"max-records-tooltip-text\"],null]],[12],[2,\"\\n      \"],[1,[30,[36,6],[\"info-icon\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"input\"],[14,0,\"field_value\"],[15,2,[34,3,[\"maxRowsToDisplay\"]]],[15,\"onblur\",[30,[36,1],[[32,0],\"onMaxRowsChange\"],[[\"value\"],[\"target.value\"]]]],[14,4,\"number\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"field\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"field_label\"],[12],[1,[30,[36,0],[\"columns-label\"],null]],[13],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n\"],[6,[37,8],[[30,[36,7],[[35,3,[\"columns\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3,[\"columns\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,2],null,[[\"class\",\"value\",\"focus-out\"],[\"field_value\",[32,1,[\"label\"]],[30,[36,1],[[32,0],\"onUpdateDisplaySettings\"],null]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[30,[36,0],[\"columns-empty-label\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"input\",\"settings\",\"-track-array\",\"each\",\"inline-svg\",\"is-present\",\"if\"]}",
    "meta": {
      "moduleName": "happyfox-bi/visualizations/new/components/display-settings/data-table/template.hbs"
    }
  });

  _exports.default = _default;
});