define("happyfox-bi/visualizations/new/components/values-aggregation/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AGGREGATION_TYPE_OPTIONS = [{
    id: null,
    name: '-------'
  }, {
    id: 'average',
    name: 'Average'
  }, {
    id: 'percentile',
    name: 'Percentile'
  }];
  var DEFAULT_PERCENTILE_VALUE = 90;

  var _default = Ember.Component.extend({
    classNames: ['new-create-edit-values-aggregation'],
    aggregationTypeOptions: AGGREGATION_TYPE_OPTIONS,
    aggregationDetails: null,
    aggregationType: null,
    percentileValue: null,
    showCorrectionMessage: false,
    isPercentileType: Ember.computed.equal('aggregationType.id', 'percentile'),
    init: function init() {
      this._super.apply(this, arguments);

      var aggregationType = this.get('aggregationDetails.type') || null;
      var aggregationTypeOption = this.get('aggregationTypeOptions').findBy('id', aggregationType);
      this.set('aggregationType', aggregationTypeOption);

      if (aggregationType === 'percentile') {
        var percentileValue = this.get('aggregationDetails.percentile') || DEFAULT_PERCENTILE_VALUE;
        this.set('percentileValue', percentileValue);
      }
    },
    triggerOnChange: function triggerOnChange() {
      var aggregationType = this.get('aggregationType.id');
      var aggregationDetails = null;

      if (aggregationType) {
        aggregationDetails = {
          type: aggregationType
        };

        if (aggregationType === 'percentile') {
          var percentileValue = this.get('percentileValue');
          aggregationDetails.percentile = percentileValue;
        }
      }

      this.onChange(aggregationDetails);
    },
    actions: {
      onTypeChange: function onTypeChange(typeOption) {
        if (typeOption.id === 'percentile') {
          this.set('percentileValue', DEFAULT_PERCENTILE_VALUE);
        }

        this.set('aggregationType', typeOption);
        this.triggerOnChange();
      },
      onPercentileValueChange: function onPercentileValueChange() {
        var value = this.get('percentileValue');
        value = Ember.isBlank(value) ? null : Number(this.get('percentileValue'));

        if (Ember.isEmpty(value) || value < 0 || value > 100) {
          this.setProperties({
            percentileValue: DEFAULT_PERCENTILE_VALUE,
            showCorrectionMessage: true
          });
        } else {
          this.setProperties({
            percentileValue: value,
            showCorrectionMessage: false
          });
        }

        this.triggerOnChange();
      }
    }
  });

  _exports.default = _default;
});