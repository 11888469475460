define("happyfox-bi/visualizations/new/components/fields-selection/data-table/component", ["exports", "happyfox-bi/visualizations/new/components/fields-selection/component", "happyfox-bi/visualizations/constant"], function (_exports, _component, _constant) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var MAX_LIMIT = 20;

  var _default = _component.default.extend({
    intlService: Ember.inject.service('intl'),
    classNames: ['new-create-edit-fields-selection'],
    displaySettings: null,
    settingsFields: null,
    columnFields: null,
    showColumnFieldModal: false,
    canAddColumn: Ember.computed.lt('columnFields.length', MAX_LIMIT),
    init: function init() {
      this._super.apply(this, arguments);

      var displaySettings = this.displaySettings,
          settingsFields = this.settingsFields;
      var columns = displaySettings.columns;
      var columnFields = Ember.A();

      if (Ember.isPresent(columns)) {
        columnFields = columns.map(function (field) {
          return settingsFields.findBy('name', field.key);
        });
      }

      this.set('columnFields', columnFields);
    },
    updateVisualizationColumnFields: function updateVisualizationColumnFields() {
      this.displaySettings.set('columns', this.generateColumnSettings());
      var columnFields = this.columnFields;
      this.onColumnsChange(columnFields, false);
    },
    generateColumnSettings: function generateColumnSettings() {
      var columnFields = this.columnFields,
          displaySettings = this.displaySettings;

      if (Ember.isPresent(columnFields)) {
        var columnSettings = displaySettings.columns;
        var fieldSettings = Ember.A();

        if (Ember.isPresent(columnSettings)) {
          columnFields.forEach(function (field) {
            var setting = Ember.Object.create({
              key: '',
              label: ''
            });
            var existingField = columnSettings.findBy('key', field.name);

            if (existingField) {
              setting.setProperties({
                key: existingField.key,
                label: existingField.label
              });
            } else {
              setting.setProperties({
                key: field.name,
                label: field.displayName || field.name
              });
            }

            fieldSettings.pushObject(setting);
          });
        } else {
          columnFields.forEach(function (field) {
            var setting = Ember.Object.create({
              key: field.name,
              label: field.displayName || field.name
            });
            fieldSettings.pushObject(setting);
          });
        }

        return fieldSettings;
      }

      return Ember.A();
    },
    actions: {
      toggleColumnFieldModal: function toggleColumnFieldModal() {
        this.toggleProperty('showColumnFieldModal');
      },
      addColumnField: function addColumnField(field) {
        var columnFields = this.columnFields;
        columnFields.addObject(field);
        this.toggleProperty('showColumnFieldModal');
        this.updateVisualizationColumnFields();
      },
      editColumnField: function editColumnField(newField, field) {
        var columnFields = this.columnFields,
            conditionalFormatting = this.displaySettings.conditionalFormatting;
        var indexOfOldField = columnFields.indexOf(field);
        columnFields.removeObject(field);
        columnFields.insertAt(indexOfOldField, newField);

        var _conditionalFormattin = conditionalFormatting.filter(function (format) {
          return format.field && format.field.name === field.name;
        }),
            _conditionalFormattin2 = _slicedToArray(_conditionalFormattin, 1),
            fieldFormat = _conditionalFormattin2[0];

        if (fieldFormat) {
          var isNumericField = _constant.NUMERIC_FIELD_TYPES.includes(newField.dataType);
          /* 
            Conditional formatting (data bar) is applicable only on numeric fields.
            If old and new fields are of type numeric, we retain the formatting or else we remove the formatting
          */


          if (isNumericField) {
            fieldFormat.field.setProperties({
              name: newField.name,
              displayName: newField.displayName
            });
          } else {
            conditionalFormatting.removeObject(fieldFormat);
          }
        }

        this.updateVisualizationColumnFields();
      },
      removeColumnField: function removeColumnField(field) {
        var columnFields = this.columnFields,
            conditionalFormatting = this.displaySettings.conditionalFormatting;
        columnFields.removeObject(field);

        var _conditionalFormattin3 = conditionalFormatting.filter(function (format) {
          return format.field && format.field.name === field.name;
        }),
            _conditionalFormattin4 = _slicedToArray(_conditionalFormattin3, 1),
            fieldFormat = _conditionalFormattin4[0];

        if (fieldFormat) {
          conditionalFormatting.removeObject(fieldFormat);
        }

        this.updateVisualizationColumnFields();
      }
    }
  });

  _exports.default = _default;
});