define("happyfox-bi/visualizations/create/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    routerService: Ember.inject.service('router'),
    dataService: Ember.inject.service('data'),
    featuresService: Ember.inject.service('features'),
    applicationService: Ember.inject.service('application'),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.routerService.on('routeWillChange', function (transition) {
        var toRouteInfo = transition.to;

        if (toRouteInfo.name === 'visualizations.create') {
          var _toRouteInfo$queryPar = toRouteInfo.queryParams,
              report = _toRouteInfo$queryPar.report,
              page = _toRouteInfo$queryPar.page;

          if (Ember.isEmpty(report) || Ember.isEmpty(page)) {
            _this.transitionTo('reports');
          }

          _this.setProperties({
            reportId: report,
            pageId: page
          });
        }
      });
    },
    beforeModel: function beforeModel() {
      if (!this.get('featuresService.createVisualization')) {
        this.transitionTo('reports');
      } else if (!this.get('featuresService.oldCreateEditVisualization')) {
        this.transitionTo('visualizations.new.create', {
          queryParams: {
            report: this.get('reportId'),
            page: this.get('pageId')
          }
        });
      }
    },
    model: function model() {
      return this.get('dataService').createVisualizationModel();
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.setProperties({
        reportId: this.get('reportId'),
        pageId: this.get('pageId')
      });
      this.get('applicationService').closeSidePane();
      controller.initiate();
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        this.get('applicationService').openSidePane();
      }
    }
  });

  _exports.default = _default;
});