define("happyfox-bi/helpers/html-safe", ["exports", "happyfox-bi/utilities/string"], function (_exports, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.htmlSafe = htmlSafe;

  function htmlSafe(params, _ref) {
    var _ref$domPurifyConfig = _ref.domPurifyConfig,
        domPurifyConfig = _ref$domPurifyConfig === void 0 ? {} : _ref$domPurifyConfig;
    return (0, _string.getSafeHtml)(params[0], domPurifyConfig);
  }

  var _default = Ember.Helper.helper(htmlSafe);

  _exports.default = _default;
});