define("happyfox-bi/visualizations/new/components/table-formatting/format-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DOOycRHa",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"table-format-item selected-item-container\"],[12],[2,\"\\n  \"],[11,\"div\"],[24,0,\"table-format-item_content\"],[24,\"role\",\"button\"],[4,[38,1],[[32,0],\"editFormat\",[35,0]],null],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"table-format-item_content_color_preview\"],[15,5,[34,2]],[12],[13],[2,\"\\n    \"],[10,\"div\"],[12],[1,[35,0,[\"field\",\"displayName\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[11,\"div\"],[24,0,\"table-format-item_remove_svg fields-selection_field_icon\"],[24,\"role\",\"button\"],[4,[38,1],[[32,0],\"removeFormat\"],null],[12],[2,\"\\n    \"],[1,[30,[36,3],[\"trash\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"format\",\"action\",\"formatPreviewColor\",\"inline-svg\"]}",
    "meta": {
      "moduleName": "happyfox-bi/visualizations/new/components/table-formatting/format-item/template.hbs"
    }
  });

  _exports.default = _default;
});