define("happyfox-bi/components/auto-complete/local/multiple/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "faT64zme",
    "block": "{\"symbols\":[\"result\",\"result\",\"&default\"],\"statements\":[[6,[37,0],[[35,9]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,8],null,[[\"renderInPlace\",\"searchEnabled\",\"searchField\",\"options\",\"selected\",\"placeholder\",\"initiallyOpened\",\"closeOnSelect\",\"onChange\",\"onClose\",\"onFocus\"],[[35,7],true,[35,6],[35,5],[35,4],[35,2],true,[35,3],[30,[36,1],[[32,0],\"onChange\"],null],[30,[36,1],[[32,0],\"onClose\"],null],[30,[36,1],[[32,0],\"onFocus\"],null]]],[[\"default\"],[{\"statements\":[[6,[37,0],[[27,[32,3]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,3,[[32,2]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[32,2]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[2]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,8],null,[[\"renderInPlace\",\"searchEnabled\",\"searchField\",\"options\",\"selected\",\"closeOnSelect\",\"placeholder\",\"onChange\",\"onClose\",\"onFocus\"],[[35,7],true,[35,6],[35,5],[35,4],[35,3],[35,2],[30,[36,1],[[32,0],\"onChange\"],null],[30,[36,1],[[32,0],\"onClose\"],null],[30,[36,1],[[32,0],\"onFocus\"],null]]],[[\"default\"],[{\"statements\":[[6,[37,0],[[27,[32,3]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,3,[[32,1]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[32,1]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"action\",\"placeholder\",\"closeOnSelect\",\"selected\",\"options\",\"searchField\",\"renderInPlace\",\"power-select-multiple\",\"autofocus\"]}",
    "meta": {
      "moduleName": "happyfox-bi/components/auto-complete/local/multiple/template.hbs"
    }
  });

  _exports.default = _default;
});