define("happyfox-bi/components/form-field-input/textarea/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bgSx3Q4Q",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,9],null,[[\"readonly\",\"value\",\"focus-out\",\"class\",\"classNames\",\"disabled\",\"placeholder\",\"data-test-id\",\"rows\",\"enter\",\"key-up\",\"aria-label\"],[[35,2,[\"readonly\"]],[35,2,[\"value\"]],[30,[36,1],[[32,0],\"onFocusOut\"],null],[30,[36,8],[[30,[36,7],[\"form-field-input hf-text-input\",[30,[36,6],[[35,2,[\"isResizable\"]],\"hf-is-resizable\"],null]],null],\" \",[30,[36,6],[[35,5],\"is-error\"],null],\" \"],null],[35,4],[35,2,[\"isDisabled\"]],[35,2,[\"placeholder\"]],[35,3],[35,2,[\"rowsCount\"]],[30,[36,1],[[32,0],\"onEnter\"],null],[30,[36,1],[[32,0],\"onChange\"],null],[35,0]]]]],[2,\"\\n\"],[19,\"components/form-field-input/partials/error-message\",[]],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"ariaLabel\",\"action\",\"field\",\"fieldDataTestId\",\"inputClass\",\"hasErrors\",\"if\",\"concat-strings\",\"concat\",\"textarea\"]}",
    "meta": {
      "moduleName": "happyfox-bi/components/form-field-input/textarea/template.hbs"
    }
  });

  _exports.default = _default;
});