define("happyfox-bi/services/panel-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    panels: Ember.A(),
    createPanel: function createPanel(group, id, isOpen) {
      var panels = this.get('panels');
      var panel = Ember.Object.create({
        group: group,
        id: id,
        isOpen: isOpen
      });
      panels.addObject(panel);
    },
    getPanelById: function getPanelById(panelId) {
      return this.get('panels').findBy('id', panelId);
    },
    getPanelsForGroup: function getPanelsForGroup(group) {
      return this.get('panels').filterBy('group', group);
    },
    togglePanel: function togglePanel(panelId) {
      var panel = this.getPanelById(panelId);
      panel.toggleProperty('isOpen');
    },
    openAllPanels: function openAllPanels(group) {
      var panels = this.getPanelsForGroup(group);
      panels.forEach(function (panel) {
        panel.set('isOpen', true);
      });
    },
    closeAllPanels: function closeAllPanels(group) {
      var panels = this.getPanelsForGroup(group);
      panels.forEach(function (panel) {
        panel.set('isOpen', false);
      });
    }
  });

  _exports.default = _default;
});